import { ReactElement, ReactNode } from 'react'
import { Listbox } from '@headlessui/react'

type Props = {
  value: unknown
  disabled?: boolean
  children: ReactElement | ReactElement[] | (({ selected, active, disabled }: Record<string, boolean>) => ReactNode)
  className?: string
}

const Option = (props: Props): ReactElement => {
  const { value, disabled = false, className = '', children } = props

  return (
    <Listbox.Option
      disabled={disabled}
      value={value}
      className={({ active, selected }) =>
        `${active ? 'text-basic-100 bg-basic-600' : ''} ${
          disabled
            ? active
              ? 'text-basic'
              : 'text-basic-300'
            : `py-2 cursor-pointer ${selected ? 'bg-basic-300' : ''}`
        } relative pl-10 pr-4 ${className}`
      }
    >
      {children}
    </Listbox.Option>
  )
}

export default Option

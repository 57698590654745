import { useAuth } from 'core/auth/useAuth'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { TokenResponse } from 'types/Auth'
import { PATHS } from 'utils/constants/routes/Paths'
import { authService } from '../services/authService'

const Oidc = (): JSX.Element => {
  const location = useLocation()
  const { t } = useTranslation()
  const history = useHistory()
  const authContext = useAuth()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const codeInParams = queryParams.get('code')
    const stateInParams = queryParams.get('state')

    if (codeInParams && stateInParams) {
      authService
        .getAccessTokenFromSSO({ code: codeInParams, state: stateInParams })
        .then((data: TokenResponse) => {
          const { access_token, refresh_token } = data

          if (!authContext) {
            return
          }

          const { setAccessToken, setRefreshToken } = authContext

          setAccessToken(access_token)
          setRefreshToken(refresh_token)
        })
        .finally(() => history.push(PATHS.HOME))
    }
  }, [location.search])

  return <span>{t('auth.you-are-being-authenticated-please-wait-few-seconds')}</span>
}

export default Oidc

import { FC } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'

const PageNoRight: FC = () => {
  const { t } = useTranslation()

  return <PageLayout>{t('page.noRight')}</PageLayout>
}

export default PageNoRight

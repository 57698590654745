export type UrlParameter = 'apiKey' | 'caption' | 'client' | 'path' | 'screenMode' | 'hideLogo'

const CASE_SENSITIVE: UrlParameter[] = ['apiKey', 'client', 'path', 'hideLogo']

export const getUrlParameter = (parameter: UrlParameter): string | null => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const param = urlParams.get(parameter)

  return !!param && CASE_SENSITIVE.indexOf(parameter) < 0 ? param.toLowerCase() : param
}

export const isTvMode = (): boolean => getUrlParameter('screenMode') === 'tv'

export const isTabletMode = (): boolean => getUrlParameter('screenMode') === 'tablet'

export const getCaptionType = (): string | null => getUrlParameter('caption')

export const getClient = (): string | null => getUrlParameter('client')

export const getApiKey = (): string | null => getUrlParameter('apiKey')

export const getPath = (): string | null => getUrlParameter('path')

export const getHideLogo = (): boolean => {
  const param = getUrlParameter('hideLogo')
  if (param) {
    return param === 'true'
  }
  return false
}

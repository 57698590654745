import { cloneElement, FC, MouseEvent } from 'react'
import { ICON_SVG_MAP } from './IconSvgMap'

export const iconColors = {
  WHITE: 'text-basic-100',
  GREY: 'text-basic-400',
  BLACK: 'text-basic-700',
  GREY50: 'grey50',
  GREY30: 'grey30',
  GREY100: 'grey100',
  GREY80: 'grey80',
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
}

type Props = {
  name: keyof typeof ICON_SVG_MAP
  title?: string
  color?: keyof typeof iconColors
  className?: string
  iconClassName?: string
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void
}

const IconSvg: FC<Props> = (props) => {
  const { name, title, color = 'BLACK', className = '', iconClassName = '', onClick } = props

  if (!ICON_SVG_MAP[name]) {
    return null
  }

  return (
    <div className={`${onClick ? 'cursor-pointer' : ''} ${className}`}>
      <span onClick={onClick} title={title}>
        {cloneElement(
          ICON_SVG_MAP[name],
          {
            className: `mx-auto fill-current ${iconColors[color]} ${iconClassName}`,
          },
          null,
        )}
      </span>
    </div>
  )
}

export default IconSvg

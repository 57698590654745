import { FC } from 'react'
import IconSvg from '../icons/IconSvg'
import useFocusedRef from 'utils/hooks/useFocusedRef'
import { FILTER_ARROW_ICONS, FILTER_STYLE } from 'styles/Filters'
import { FilterContext } from './FilterContext'

type Props = {
  className?: string
  type?: keyof typeof FILTER_STYLE
  label?: string
  placeholder: string
  selected?: string
  disabled?: boolean
  invisible?: boolean
  isBenchmark?: boolean
  onSelect: (value?: string) => void
}

const Filter: FC<Props> = (props) => {
  const {
    className = '',
    type = 'PRIMARY',
    label,
    placeholder,
    selected,
    disabled = false,
    onSelect,
    children,
    isBenchmark,
    invisible,
  } = props
  const { open, refButton, refContainer } = useFocusedRef(disabled, true)
  const { openIcon, closeIcon, ...iconColors } = FILTER_ARROW_ICONS
  const filterType = open ? 'BLACK' : type
  return (
    <div className={invisible ? 'invisible w-0 mx-0' : 'relative mx-1 max-h-filter'}>
      <div
        ref={refButton}
        className={`inline-flex min-h-13 items-center px-2 pb-1 rounded-lg border-2 font-bold
         text-b2 ${FILTER_STYLE[filterType]} ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } select-none ${className}`}
        style={{ borderColor: isBenchmark && type == 'TRANSPARENT' ? '#575762' : '' }}
      >
        <div
          className={`${disabled ? 'w-32' : 'w-28'} overflow-hidden overflow-ellipsis whitespace-nowrap`}
          title={label || placeholder}
        >
          {label || placeholder}
        </div>
        {!disabled && <IconSvg name={open ? openIcon : closeIcon} color={iconColors[type]} className="ml-2" />}
      </div>
      <FilterContext.Provider value={{ selected, onSelect }}>
        {open && (
          <div
            style={{ minWidth: '12rem', width: 'auto', maxHeight: '30rem' }}
            ref={refContainer}
            className={`absolute top-100 left-0 z-10 mt-1 border-2 border-basic-300 bg-basic-100 rounded-xl shadow-xl overflow-y-auto`}
          >
            {children}
          </div>
        )}
      </FilterContext.Provider>
    </div>
  )
}

export default Filter

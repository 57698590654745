import { RestaurantAvailableInfo } from 'types/RieView'
import { bpConvert } from 'utils/breakPointsUtils'
import { useTranslation } from 'react-i18next'
import { getRateOccupancyPercentage } from 'utils/LocalizationUtils'

const width = window.innerWidth

type RieInfoCellProps = {
  infos: RestaurantAvailableInfo
  size: 'sm' | 'lg'
}

const RieInfoCell = ({ infos, size }: RieInfoCellProps): JSX.Element => {
  const { t } = useTranslation()

  const isOffPeriod = infos.available === 0 && infos.dirty === 0 && infos.unavailable === 0 && infos.offPeriod > 0

  if (isOffPeriod) {
    return (
      <div
        style={{
          fontSize: bpConvert(width, 25),
          lineHeight: `${bpConvert(width, 39)}px`,
          marginLeft: bpConvert(width, 8),
        }}
      >
        {t('realTime.rieView.offPeriod')}
      </div>
    )
  }

  const isInfoAvailable = infos.available > 0 || infos.dirty > 0 || infos.unavailable > 0

  if (!isInfoAvailable) {
    return (
      <div
        style={{
          fontSize: bpConvert(width, 25),
          lineHeight: `${bpConvert(width, 39)}px`,
        }}
      >
        {t('realTime.rieView.infosNotAvailable')}
      </div>
    )
  }

  return (
    <div
      className="flex flex-1 h-full items-center justify-center -mx-2 text-black"
      style={{
        fontSize: bpConvert(width, size === 'sm' ? 25 : 46),
        lineHeight: `${bpConvert(width, 54)}px`,
        backgroundColor: infos.color,
      }}
    >
      {getRateOccupancyPercentage(infos.unavailable, infos.capacity)}%
    </div>
  )
}

export default RieInfoCell

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/atoms/button/Button'
import IconSvg from 'components/atoms/icons/IconSvg'
import { useHistory } from 'react-router-dom'
import LoginPageLayout from 'components/layouts/LoginPageLayout'
import { COLORS } from 'assets/colors/colors'

const ResetPasswordConfirmation: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <LoginPageLayout showLogo={false}>
      <h1 className="text-h3 text-basic-100 px-8 text-center font-medium">{t('auth.resetPassword.success')}</h1>
      <div className="flex justify-center my-10">
        <Button style={{ backgroundColor: COLORS.deepCerise }} type="submit" onClick={() => history.push('/')}>
          {t('auth.resetPassword.validate')}
        </Button>
      </div>
    </LoginPageLayout>
  )
}

export default ResetPasswordConfirmation

import { FC } from 'react'
import PageLayout from './PageLayout'
import { useTranslation } from 'react-i18next'
import IconSvg from 'components/atoms/icons/IconSvg'
import { externalLinks } from 'utils/constants/routes/ApiRoutes'

type Props = {
  showLogo?: boolean
}

const LoginPageLayout: FC<Props> = (props) => {
  const { showLogo = true, children } = props
  const { t } = useTranslation()

  return (
    <PageLayout className="bg-basic flex-grow h-screen bg-right-top bg-no-repeat">
      <div
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${'/images/background.jpg'})`,
          backgroundSize: 'cover',
        }}
        className=" h-full  bg-no-repeat"
      >
        <div className="flex justify-center m-auto p-4 w-full h-full items-center">
          {showLogo && (
            <div className="m-auto w-1/3" style={{ color: 'white' }}>
              <img src="/images/logo_white.png" alt={t('auth.logo')} style={{ width: '100px' }} />
              <h1 style={{ fontSize: '48px', fontWeight: 'bold' }} className="font-face-gm">
                {t('auth.login.title')}
              </h1>
              <div className="deepCeriseSeparator"></div>
              <p style={{ fontSize: '20px' }}>{t('auth.login.description')}</p>
            </div>
          )}
          <div className="m-auto w-1/3">{children}</div>
          <div className="text-basic-100 flex  justify-center mt-8 text-center absolute bottom-8 login-links">
            <div className="rounded-lg colored-link" style={{ backgroundColor: '#F74A6E' }}>
              <a href={`${externalLinks.PORTAL}/login`} target="_blank" rel="noopener noreferrer">
                {t('auth.login.administration')}
              </a>

              <IconSvg name="CHEVRON_RIGHT" color="WHITE" />
            </div>
            <div className="rounded-lg colored-link" style={{ backgroundColor: '#FF7254' }}>
              <a href={`${externalLinks.OPTIMUS}/login.xhtml`} target="_blank" rel="noopener noreferrer">
                {t('auth.login.supervision')}
              </a>
              <IconSvg name="CHEVRON_RIGHT" color="WHITE" />
            </div>
            <div className="rounded-lg colored-link" style={{ backgroundColor: '#FF9D3F' }}>
              <a href={`${externalLinks.SPACEX}/login`} target="_blank" rel="noopener noreferrer">
                {t('auth.login.realTime')}
              </a>
              <IconSvg name="CHEVRON_RIGHT" color="WHITE" />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default LoginPageLayout

import { COLORS } from 'assets/colors/colors'
import { toHoursAndMinutes } from 'utils/dateUtils'
export const fakeTopFlopData = {
  totalRoomsCount: 34,
  truncatedResult: true,
  result: [
    {
      name: 'SdR U4R2',
      capacity: 10,
      occupancyRate: 87,
    },
    {
      name: 'SdR U4R1',
      capacity: 5,
      occupancyRate: 72,
    },
    {
      name: 'SdR U4R3',
      capacity: 4,
      occupancyRate: 70,
    },
    {
      name: 'Espace informel U4R0',
      capacity: 9,
      occupancyRate: 44,
    },
    {
      name: 'SdR U4R4',
      capacity: 6,
      occupancyRate: 30,
    },
    {
      name: 'Espace informel U4R5',
      capacity: 8,
      occupancyRate: 21,
    },
  ],
}

type OptionsType = {
  id: string | undefined
  categories: (string | string[])[]
  title: string
  subtitle: string
  truncatedIndex?: number
  onRightClickCallback?: (index: number) => void
  tooltip?: boolean
  colors: string[]
}

export const horizontalBarChartOptions = (options: OptionsType): Record<string, unknown> => {
  const { categories, title, subtitle, truncatedIndex, onRightClickCallback, minutes, colors } = options
  const customTooltip = ({ series, seriesIndex, dataPointIndex, w: config }: any) => {
    if (Math.round(series[seriesIndex][dataPointIndex]) < 10)
      return `<div class="py-1 px-3">
   <p><span class="font-bold">${Math.round(series[seriesIndex][dataPointIndex])}% (${
        toHoursAndMinutes(minutes[dataPointIndex]).hours
      }h${toHoursAndMinutes(minutes[dataPointIndex]).minutes}min)</span></p></div>`
    return `<p><span class="font-bold">${Math.round(series[seriesIndex][dataPointIndex])}% (${
      toHoursAndMinutes(minutes[dataPointIndex]).hours
    }h${toHoursAndMinutes(minutes[dataPointIndex]).minutes}min)</span></p></div>`
  }

  return {
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: options.id ? options.id.toString() : 'topflop-chart',
      animations: {
        enabled: true,
        dynamicAnimation: {
          enabled: false,
        },
      },
      type: 'bar',
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
      events: {
        // eslint-disable-next-line
        dataPointSelection: (event: MouseEvent, chartContext: never, config: any) => {
          if (onRightClickCallback && event.button === 2) {
            onRightClickCallback(config.dataPointIndex)
          }
        },
      },
    },
    plotOptions: {
      bar: {
        barHeight: '70%',
        borderRadius: 12,
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      textAnchor: 'end',
      // eslint-disable-next-line
      formatter: (val: number, config: any): string => {
        // When data are truncated
        if (truncatedIndex && config.dataPointIndex === truncatedIndex) return '...'

        return `${val}%`
      },
      offsetX: 0,
    },
    yaxis: { labels: { maxWidth: 200, align: 'left' } },
    xaxis: {
      categories,
      labels: {
        show: true,
        style: {
          colors: ['#9C9CB5'],
        },
        formatter: (value: number): string => {
          return value % 20 === 0 ? `${value}%` : ''
        },
      },
      tickAmount: 10,
      max: 100,
      axisTicks: {
        show: true,
      },
    },
    title: {
      text: title,
      align: 'left',
      offsetY: 10,
      style: {
        fontWeight: 700,
        fontSize: 14,
      },
    },
    subtitle: {
      text: subtitle,
      align: 'left',
    },
    colors: colors?.length > 0 ? colors.find((obj) => obj.type == 'SINGLE_COLOR')?.color : [COLORS.deepCerise],
    tooltip: {
      enabled: true,
      custom: customTooltip,
    },
    fill: {
      opacity: 1,
    },
  }
}

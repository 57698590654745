import {
  LocalizationInformationDto,
  LocalizationInformationDtoRoomClassificationEnum,
  LocalizationInformationDtoTypeEnum,
} from 'core/api/models/localizationInformationDto'
import { Bench, Compartment, Desk, Domain, Group, Level, Localization, Room, RoomType, Zone } from './api/types'
import { AvailableInfo, FloorInfo, LocalizationInfo, RestaurantAvailableInfo } from 'types/RieView'
import { COLORS } from 'assets/colors/colors'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'

export const getRateOccupancyPercentage = (value: number, capacity: number): number | string => {
  const percentage = (value / capacity) * 100
  switch (true) {
    case percentage < 10:
      return '<10'
    case percentage >= 90 && percentage < 100:
      return '>90'
    case percentage >= 100:
      return 100
    default:
      return Math.round(percentage / 10) * 10
  }
}

export const getBuildingsNames = (domain?: Domain): string[] =>
  domain?.sub_localizations?.length
    ? (domain.sub_localizations as LocalizationInformationDto[])
        .filter((s) => s.type === LocalizationInformationDtoTypeEnum.Building)
        .map((s) => s.name)
        .filter((name): name is string => !!name)
    : []

export const filterBuildings = (localizations: Localization[]): Localization[] =>
  localizations.filter((s) => s.type === LocalizationInformationDtoTypeEnum.Building)
export const findBuildingByName = (localizations: Localization[], buildingName: string): Localization | undefined =>
  filterBuildings(localizations).find((s) => s.name === buildingName)
export const findBuildingByPath = (localizations: Localization[], buildingPath: string): Localization | undefined =>
  filterBuildings(localizations).find((s) => s.path?.replaceAll('$', '/') === buildingPath?.replaceAll('$', '/'))

export const getBuildingSensor = (building: Localization, sensors: SensorStatusDto[]): SensorStatusDto | undefined =>
  sensors.find((sensor) => sensor.path === building?.path)

export const getZones = (domain?: Domain, buildingName?: string): Zone[] => {
  if (!domain?.sub_localizations) {
    return []
  }

  if (buildingName) {
    const building: Localization | undefined = getLocalizations(
      domain.sub_localizations as Localization[],
      LocalizationInformationDtoTypeEnum.Building,
    ).find((b) => b.name === buildingName)
    const zones =
      building && building.sub_localizations
        ? getLocalizations(building.sub_localizations as Localization[], LocalizationInformationDtoTypeEnum.Zone)
        : []
    return zones as Zone[]
  }
  return getLocalizations(domain.sub_localizations as Localization[], LocalizationInformationDtoTypeEnum.Zone) as Zone[]
}

export const getOverviewInfo = (
  localizations: Localization[],
  desks: SensorStatusDto[],
  building?: string,
  zone?: string,
): FloorInfo[] => {
  const levels = getLevels(localizations, building, zone)

  return (levels as Level[]).map((floor: Level) => {
    const rooms: Room[] = getRooms(floor.sub_localizations)
    const floorDesks = desks.filter((desk) => desk.path?.includes(floor.code ?? ''))
    return {
      buildingName: building,
      floor: getFloor(floor) as unknown as string,
      floorName: getFloorName(floor) as unknown as string,
      floorId: floor.path?.replace(/\//g, '-').replace(/_/g, '') as unknown as string,
      workstation: getWorkstationAvailabilityInfo(rooms, floorDesks),
      meetingRoom: getRoomAvailabilityInfo(
        rooms,
        floorDesks,
        LocalizationInformationDtoRoomClassificationEnum.MeetingRoom,
      ),
      focusRoom: getRoomAvailabilityInfo(rooms, floorDesks, LocalizationInformationDtoRoomClassificationEnum.FocusRoom),
      sortValue: getSortValue(floor) as unknown as string,
      restaurants: getRestaurantAvailabilityInfo(
        rooms,
        floorDesks,
        LocalizationInformationDtoRoomClassificationEnum.Restaurant,
      ),
    }
  })
}

export const getSortValue = (floor: Level): string | undefined => {
  if (floor.attributes) {
    return floor.attributes.CSM_DISPLAY_ORDER || getFloor(floor)
  }
  return getFloor(floor)
}

export const getLevels = (subLocalizations: Localization[], building?: string, zone?: string): Level[] => {
  return subLocalizations
    ? subLocalizations
        .map((s) => {
          if (
            (s.type === LocalizationInformationDtoTypeEnum.Building && (s.name === building || !building)) ||
            (s.type === LocalizationInformationDtoTypeEnum.Zone && (s.name === zone || !zone))
          ) {
            return [...getLevels(s.sub_localizations as Localization[], building, zone)]
          }
          if (s.type === LocalizationInformationDtoTypeEnum.Level) {
            return [s]
          }
          return []
        })
        .reduce((levels: Level[], currentLevels: Array<Level | Localization>) => {
          if (currentLevels.length > 0 && currentLevels[0].type === LocalizationInformationDtoTypeEnum.Level) {
            return levels.concat(currentLevels as Level[])
          }
          return levels
        }, [])
    : []
}

export const getLevel = (floorName: string, domain?: Domain, building?: string, zone?: string): Level | undefined => {
  if (domain?.sub_localizations?.length) {
    const levels = getLevels(domain.sub_localizations as Localization[], building, zone)
    return levels.find((l) => l.name === floorName)
  }
}

export const getFloor = (floor: Level): string | undefined => {
  if (floor.attributes) {
    return floor.attributes.CSM_DISPLAY_NAME || floor.code
  }
  return floor.code
}

export const getFloorName = (floor: Level): string | undefined => {
  if (floor.attributes) {
    return floor.attributes.CSM_DISPLAY_NAME || floor.name
  }
  return floor.name
}

// @ts-ignore
export const getRooms = (subLocalizations: Array<Compartment | Room>): Room[] =>
  // @ts-ignore
  subLocalizations
    ? subLocalizations
        .map((subLocalization) => {
          if (subLocalization.type === LocalizationInformationDtoTypeEnum.Room) {
            return [subLocalization]
          } else if (subLocalization.sub_localizations) {
            return getRooms(subLocalization.sub_localizations)
          }
          return []
        })
        .reduce((rooms: Room[], currentRooms: Room[]) => {
          return rooms.concat(currentRooms)
        }, [])
    : []

// @ts-ignore
export const getDesks = (subLocalizations: Array<Bench | Group | Desk>): Desk[] =>
  // @ts-ignore
  subLocalizations
    ? subLocalizations
        .map((subLocalization) => {
          if (subLocalization.type === LocalizationInformationDtoTypeEnum.Desk) {
            return [subLocalization]
          } else if (subLocalization.sub_localizations) {
            return getDesks(subLocalization.sub_localizations)
          }
          return []
        })
        .reduce((desks: Desk[], currentDesks: Desk[]) => {
          return desks.concat(currentDesks)
        }, [])
    : []
/* @ts-ignore */

const getWorkstationAvailabilityInfo = (rooms: Room[], desks: SensorStatusDto[]): AvailableInfo => ({
  available: getWorkstationAvailabilityInfoForStatus(rooms, desks, availableFilter),
  dirty: getWorkstationAvailabilityInfoForStatus(rooms, desks, dirtyFilter),
  unavailable: getWorkstationAvailabilityInfoForStatus(rooms, desks, unavailableFilter),
  offPeriod: getWorkstationAvailabilityInfoForStatus(rooms, desks, offPeriodFilter),
})

const getRoomAvailabilityInfo = (rooms: Room[], desks: SensorStatusDto[], roomType: RoomType): AvailableInfo => ({
  available: getRoomAvailabilityInfoForStatus(rooms, desks, roomType, availableFilter),
  dirty: getRoomAvailabilityInfoForStatus(rooms, desks, roomType, dirtyFilter),
  unavailable: getRoomAvailabilityInfoForStatus(rooms, desks, roomType, unavailableFilter),
  offPeriod: getRoomAvailabilityInfoForStatus(rooms, desks, roomType, offPeriodFilter),
})

const getRestaurantAvailabilityInfo = (
  rooms: Room[],
  desks: SensorStatusDto[],
  roomType: RoomType,
): RestaurantAvailableInfo[] => {
  const restaurants = rooms.filter((room) => room.room_classification === roomType)

  return restaurants
    .filter((r) => r.realtime_display === undefined || r.realtime_display)
    .map((restaurant) => ({
      name: restaurant.name as unknown as string,
      displayName: restaurant.attributes?.CSM_DISPLAY_NAME,
      displayOrder: restaurant.attributes?.CSM_DISPLAY_ORDER,
      capacity: getCapacity(restaurant) || 0,
      available: getRestaurantAvailabilityInfoForStatus(restaurant, desks, availableRestaurantFilter),
      unavailable: getRestaurantAvailabilityInfoForStatus(restaurant, desks, unavailableRestaurantFilter),
      dirty: 0,
      offPeriod: getRestaurantAvailabilityInfoForStatus(restaurant, desks, offPeriodRestaurantFilter),
      path: restaurant.path as unknown as string,
      color: getRestaurantAvailabilityColor(restaurant, desks),
      counting: countingRestaurantFilter(desks),
    }))
}

export const getRestaurantAvailabilityColor = (restaurant: Room, desksInfos: SensorStatusDto[] = []): string => {
  const peopleCount: number | undefined = getRestaurantPeopleCount(restaurant, desksInfos)
  if (peopleCount === undefined) {
    return COLORS.green
  }
  if (restaurant.attributes && !!restaurant.attributes.CSM_CAPNOR) {
    const capacityPercentage = peopleCount / restaurant.attributes.CSM_CAPNOR
    return getRestaurantAvailabilityColorForAccordingCapacity(capacityPercentage, 0.5, 0.7)
  }
  if (restaurant.attributes && !!restaurant.attributes.CSM_CAPSAN) {
    const capacityPercentage = peopleCount / restaurant.attributes.CSM_CAPSAN
    return getRestaurantAvailabilityColorForAccordingCapacity(capacityPercentage, 0.6, 0.8)
  }
  return 'transparent'
}

export const getCapacity = (place: Room | Desk | Localization): number | undefined => {
  if (place.attributes) {
    const rawCapacity = place.attributes.CSM_CAPSAN || place.attributes.CSM_CAPNOR

    if (!rawCapacity) {
      return
    }

    return typeof rawCapacity === 'number' ? rawCapacity : parseInt(rawCapacity, 10)
  }
  return undefined
}

export const getFloorCapacity = (places: Room[]): number | undefined => {
  return places.map((place) => getCapacity(place)).reduce((acc: number, capacity) => acc + Math.floor(capacity || 0), 0)
}

const getRestaurantAvailabilityColorForAccordingCapacity = (
  capacityPercentage: number,
  availableMax: number,
  partiallyAvailableMax: number,
): string => {
  const { green, red, orange } = COLORS
  if (capacityPercentage <= availableMax) {
    return green
  }
  if (availableMax < capacityPercentage && capacityPercentage <= partiallyAvailableMax) {
    return orange
  }
  return red
}

const getRestaurantAvailabilityInfoForStatus = (
  restaurant: any,
  desks: SensorStatusDto[],
  statusFilterFunction: (deskInfos?: SensorStatusDto[], capacity?: number) => number,
): number => {
  const capacity: number | undefined = getCapacity(restaurant)
  const deskInfos = desks.filter((d) => d.path === restaurant.path || d.path?.includes(restaurant.path.concat('/')))
  return (
    (!capacity || capacity >= 0) &&
    (restaurant.realtime_display === undefined || restaurant.realtime_display) &&
    statusFilterFunction(deskInfos, capacity)
  )
}

export const getWorkstationAvailabilityInfoForStatus = (
  rooms: Room[],
  floorDesks: SensorStatusDto[],
  statusFilterFunction: (roomDesks: SensorStatusDto[]) => boolean,
): number =>
  rooms
    .filter((room) => room.room_classification === 'WORKSTATION')
    .filter((room) => room.realtime_display === undefined || room.realtime_display)
    .map((room: Room): SensorStatusDto[][] => {
      if (!room.sub_localizations || room.sub_localizations.length === 0) {
        return [
          floorDesks.filter((desk) => desk.path === room.path || desk.path?.includes(room.path?.concat('/') ?? '')),
        ]
      }
      return getDesks(room.sub_localizations)
        .filter((desk) => desk.realtime_display === undefined || desk.realtime_display)
        .map((desk) => floorDesks.filter((floorDesk) => floorDesk.path?.includes(desk.path ?? '')))
    })
    .reduce((acc: SensorStatusDto[][], roomInfos: SensorStatusDto[][]) => acc.concat(roomInfos), [])
    .filter(statusFilterFunction).length

export const getRoomAvailabilityInfoForStatus = (
  rooms: Room[],
  desks: SensorStatusDto[],
  roomType: RoomType,
  statusFilterFunction: (roomDesks: SensorStatusDto[]) => boolean,
): number =>
  rooms
    .filter((room) => room.room_classification === roomType)
    .filter((room) => room.realtime_display === undefined || room.realtime_display)
    .map((room) => desks.filter((desk) => desk.path === room.path || desk.path?.includes(room.path?.concat('/') ?? '')))
    .filter(statusFilterFunction).length

export const availableFilter = (roomDesks: SensorStatusDto[]): boolean =>
  roomDesks.length > 0 && roomDesks.filter((desk) => desk.status === 'FREE' && desk.clean).length === roomDesks.length

export const dirtyFilter = (roomDesks: SensorStatusDto[]): boolean => {
  const counts: { free: number; dirty: number } = roomDesks.reduce(
    (acc: { free: number; dirty: number }, desk: SensorStatusDto) => ({
      free: acc.free + (desk.status === 'FREE' ? 1 : 0),
      dirty: acc.dirty + (!desk.clean ? 1 : 0),
    }),
    {
      free: 0,
      dirty: 0,
    },
  )
  return roomDesks.length > 0 && counts.free === roomDesks.length && counts.dirty > 0
}

export const unavailableFilter = (roomDesks: SensorStatusDto[]): boolean =>
  roomDesks.length > 0 &&
  roomDesks.filter(
    (desk) =>
      desk.status === 'BUSY' ||
      desk.status === 'TRANSIENT' ||
      desk.status === 'UNKNOWN' ||
      (desk.status === 'OFF_PERIOD' &&
        roomDesks.filter((room) => room.status === 'OFF_PERIOD').length !== roomDesks.length),
  ).length > 0

export const offPeriodFilter = (roomDesks: SensorStatusDto[]): boolean =>
  roomDesks.length > 0 && roomDesks.filter((desk) => desk.status === 'OFF_PERIOD').length === roomDesks.length

export const hasSpaces = (space: AvailableInfo): boolean =>
  space.available + space.unavailable + space.dirty + space.offPeriod !== 0

export const countSpaces = (space: AvailableInfo): number =>
  space.available + space.unavailable + space.dirty + space.offPeriod

export const hasRealtimeDisplay = (floorInfos: FloorInfo, domain?: Domain): boolean => {
  const levelInfos = getLevel(floorInfos.floorName, domain)
  const isGaugeActivated =
    !!levelInfos && !!levelInfos.realtime_display && levelInfos.type === LocalizationInformationDtoTypeEnum.Level
  return isGaugeActivated
}

const getPlaces = (domain?: Domain): Array<Room | Desk> =>
  domain && domain.sub_localizations
    ? getRooms(domain.sub_localizations)
        .map((subLocalization) => {
          if (
            (subLocalization.room_classification === 'WORKSTATION' ||
              subLocalization.room_classification === 'RESTAURANT') &&
            subLocalization.sub_localizations
          ) {
            return [subLocalization, ...getDesks(subLocalization.sub_localizations)]
          }
          return [subLocalization]
        })
        .reduce((places: Array<Room | Desk>, currentPlaces: Array<Room | Desk>) => {
          return places.concat(currentPlaces)
        }, [])
    : []

export const filterPlaces = (floor: string, typeDesk: RoomType, domain: Domain) =>
  getPlaces(domain).filter(
    (place) =>
      !!place.realtime_display &&
      !!place.room_classification &&
      place.room_classification === typeDesk &&
      place.path?.replace(/-/g, '/').replace(/_/g, '').startsWith(floor),
  )

const availableRestaurantFilter = (deskInfos?: SensorStatusDto[], capacity?: number): number => {
  if (deskInfos && deskInfos?.filter((desk) => desk.tally).length > 0) {
    const freeDesks = deskInfos ? deskInfos.filter((infos) => infos.status === 'FREE' || infos.status === 'BUSY') : []
    return freeDesks.length === 0
      ? 0
      : capacity
      ? Math.max(0, capacity - freeDesks.map((roomDesk) => roomDesk.tally || 0).reduce((acc, curr) => (acc += curr), 0))
      : 0
  }
  return deskInfos ? deskInfos?.filter((infos) => infos.status === 'FREE').length : 0
}
const countingRestaurantFilter = (deskInfos?: SensorStatusDto[]): boolean => {
  return (deskInfos && deskInfos?.filter((desk) => desk.tally).length > 0) || false
}

const unavailableRestaurantFilter = (deskInfos?: SensorStatusDto[]): number => {
  if (deskInfos && deskInfos?.filter((desk) => desk.tally).length > 0) {
    return deskInfos
      ? deskInfos
          .filter((infos) => infos.status !== 'OFF_PERIOD')
          .map((infos) => infos.tally || 0)
          .reduce((acc, curr) => (acc += curr), 0)
      : 0
  }
  return deskInfos
    ? deskInfos?.filter(
        (infos) => infos.status === 'BUSY' || infos.status === 'UNKNOWN' || infos.status === 'TRANSIENT',
      ).length
    : 0
}

const offPeriodRestaurantFilter = (deskInfos?: SensorStatusDto[], capacity?: number): number => {
  return deskInfos &&
    capacity &&
    deskInfos.length > 0 &&
    deskInfos.length === deskInfos.filter((infos) => infos.status === 'OFF_PERIOD').length
    ? capacity
    : 0
}

const getLocalizations = (subLocalizations: Localization[], type: LocalizationInformationDtoTypeEnum): Localization[] =>
  subLocalizations
    ? subLocalizations
        .map((subLocalization) => {
          if (subLocalization.type === type) {
            return [subLocalization]
          } else if (subLocalization.sub_localizations) {
            return getLocalizations(subLocalization.sub_localizations as Localization[], type)
          }
          return []
        })
        .reduce((localizations: Localization[], currentLocalizations: Localization[]) => {
          return localizations.concat(currentLocalizations)
        }, [])
    : []

const getRestaurantPeopleCount = (restaurant: Room, desksInfos: SensorStatusDto[] = []): number | undefined => {
  const sensors = desksInfos.filter(
    (deskInfo) =>
      deskInfo.path === restaurant.path ||
      (deskInfo.path?.includes(restaurant.path?.concat('/') ?? '') && !!deskInfo.tally),
  )
  if (sensors.length === 0) {
    return
  }
  return sensors
    .map((s) => s.tally || 0)
    .reduce((acc, value) => {
      acc += value || 0
      return acc
    }, 0)
}

export const buildLocalizationInfo = (
  localizations: Localization[],
  desks: SensorStatusDto[],
  building?: string,
  zone?: string,
): LocalizationInfo => ({
  building: building || '',
  zone: zone || '',
  floorInfos: getOverviewInfo(localizations, desks, building, zone),
})

export const getCapacityLabel = (place: Room | Desk, sensors: SensorStatusDto[]): string | undefined => {
  const capacity = getCapacity(place)
  if (isWorkstationWithSensors(place, sensors)) {
    const counts: { free: number; unknown: number } = sensors.reduce(
      (acc: { free: number; unknown: number }, sensor: SensorStatusDto) => ({
        free: acc.free + (sensor.status === 'FREE' || sensor.status === 'OFF_PERIOD' ? 1 : 0),
        unknown: acc.unknown + (sensor.status === 'UNKNOWN' ? 1 : 0),
      }),
      { free: 0, unknown: 0 },
    )
    return `${counts.free} / ${sensors.length}`
  }
  return capacity ? capacity.toString() : undefined
}

export const isWorkstationWithSensors = (place: Room | Desk, sensors: SensorStatusDto[]): boolean => {
  return (
    place.type === LocalizationInformationDtoTypeEnum.Room &&
    place.room_classification === LocalizationInformationDtoRoomClassificationEnum.Workstation &&
    sensors.length > 0
  )
}

import { FC, Fragment } from 'react'
import { Dialog as HUIDialog, Transition } from '@headlessui/react'

type Props = {
  isOpen: boolean
  onClose: (value?: boolean) => void
  className?: string
}

const Dialog: FC<Props> = (props) => {
  const { isOpen, onClose, className = '', children } = props

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HUIDialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HUIDialog.Overlay className="fixed inset-0 bg-basic bg-opacity-80" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block w-full max-w-lg my-8 text-left align-middle transition-all transform bg-basic-100 shadow-xl rounded-2xl ${className}`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </HUIDialog>
    </Transition>
  )
}

export default Dialog

import { isTvMode } from './urlUtils'

// Big screens breakpoint
const largeBP = 2500

// To keep style proportions in tv mode for wide screens
const bpConvert = (width: number, value: number): number => {
  if (!isTvMode() || width <= 1920) {
    return value
  }
  const growthFactor = width > largeBP ? 1 : 1
  return Math.floor((width * value * growthFactor) / 1920)
}

export { bpConvert }

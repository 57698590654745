import { FC } from 'react'
import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'

type Props = {
  title: string
  content: string
  button?: {
    title: string
    onClick?: string
  }
}

const NoDashboardPanel: FC<Props> = (props) => {
  const { title, content, button } = props
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-center h-full">
      <div className="relative mx-auto w-1/3 h-auto">
        <img
          className="z-2 -rotate-8 absolute left-0 top-0 -ml-4 mt-8 w-full max-w-full h-auto rounded-xl shadow-2xl transform"
          src="/images/no_dashboard_panel_1.png"
          alt={t('home.noDashboard.alt')}
        />
        <img
          className="z-1 rotate-4 absolute left-0 top-0 ml-6 mt-2 w-full max-w-full h-auto rounded-xl shadow-2xl transform"
          src="/images/no_dashboard_panel_2.png"
          alt={t('home.noDashboard.alt')}
        />
        <div className="mb-4">
          <img
            className="-rotate-8 mb-10 w-full max-w-full h-auto rounded-xl shadow-2xl transform"
            src="/images/no_dashboard_panel_3.png"
            alt={t('home.noDashboard.alt')}
          />
        </div>
      </div>
      <div className="mt-12 mx-auto w-1/3">
        <div className="w-5/6 mx-auto">
          <p className="text-primary text-h4 my-4 text-center font-bold leading-normal">{title}</p>
          <p className="text-center">{content}</p>
          {button && (
            <div className="mt-4 mx-auto w-1/3 flex justify-center">
              <Link to={PATHS.ANALYSIS.HOME}>
                <Button className="flex flex-row items-center" type="button">
                  <IconSvg name="DASHBOARD" color="WHITE" className="mr-2" />
                  {button.title}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NoDashboardPanel

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import {
  spaceAttendanceValue,
  graphsStructure,
  detailedOccupancyValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
  calibratedUtilizationValue,
  benchmarkValue,
  frequentationValue,
} from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType, isMonthlyGraph } from 'utils/filtersUtils'
import { workstationNatures } from './monitoringSpaceTypeFilter'

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.KPI.value === value) return state

  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isDetailedOccupancyTable = state.GRAPH.value === detailedOccupancyTableValue
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const isFrequentationRestaurant = state.GRAPH.value === frequentationValue

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    FLOOR: state.FLOOR,
    DETAILED_OCCUPANCY: state.DETAILED_OCCUPANCY,
    SPACE_TYPE: state.SPACE_TYPE,
    MONITORING_ROOM_TYPE: state.MONITORING_ROOM_TYPE,
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ENTITY: state.MONITORING_ENTITY,
    COMPARTMENT: state.COMPARTMENT,
    LEVEL: state.LEVEL,
    CUSTOM_ATTRIBUT_COLLAB: state.CUSTOM_ATTRIBUT_COLLAB,
    CUSTOM_ATTRIBUT_COLLAB_VALUE: state.CUSTOM_ATTRIBUT_COLLAB_VALUE,
    CUSTOM_ATTRIBUT_WKS: state.CUSTOM_ATTRIBUT_WKS,
    CUSTOM_ATTRIBUT_WKS_VALUE: state.CUSTOM_ATTRIBUT_WKS_VALUE,
    RESTAURANT: state.RESTAURANT,
    ROOM_TYPE: {
      ...state.ROOM_TYPE,
      required:
        isMonthlyGraph(state.GRAPH) || (!isSpaceAttendance && !isDetailedOccupancyTable && !isFrequentationRestaurant),
    },
    KPI: {
      ...state.KPI,
      value,
      active: !value,
    },
    QUARTER: {
      value: isBenchmark || isFrequentationRestaurant ? state.QUARTER.value : initialFilterState.QUARTER.value,
      active: !!value,
      required: isDetailedOccupancy || isSpaceAttendance,
    },
  }
}

const KpiFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const { placeholder, ...structure2 } =
    state.GRAPH.value === monitoringValue
      ? graphsStructure.kpiMonitoring
      : state.ROOM_TYPE.value === 'WORKSTATION'
      ? state.GRAPH.value === detailedOccupancyValue
        ? state.DETAILED_OCCUPANCY.value == 'FLOOR' || state.DETAILED_OCCUPANCY.value == 'BUSINESS_UNIT'
          ? {
              ...graphsStructure.kpiWorkstation,
              NINTH_DECILE: 'graph.filters.kpi.ninthDecile',
              PERCEIVED_NINTH_DECILE: 'graph.filters.kpi.perceivedNinthDecile',
            }
          : graphsStructure.kpiWorkstation
        : state.GRAPH.value !== calibratedUtilizationValue
        ? graphsStructure.kpiWorkstation
        : {
            placeholder: 'graph.filters.kpi.placeholder',
            NB_OCCUPIED_WORKSTATION: 'graph.filters.kpi.numberOccupiedWorkstation',
            AVG_CONSECUTIVE_UTILIZATION: 'graph.filters.kpi.averageConsecutiveUtilization',
            AVG_OCCUPANCY_DURATION: 'graph.filters.kpi.averageOccupancyDuration',
            DISTRIBUTION_UTILIZATION_DURATION: 'graph.filters.kpi.distribution',
            DISTRIBUTION_UTILIZATION_PERCENTAGE: 'graph.filters.kpi.distributionPercentage',
          }
      : state.GRAPH.value === detailedOccupancyValue
      ? state.DETAILED_OCCUPANCY.value == 'FLOOR'
        ? {
            ...graphsStructure.kpi,
            NINTH_DECILE: 'graph.filters.kpi.realNinthDecile',
          }
        : graphsStructure.kpi
      : state.GRAPH.value === spaceAttendanceValue
      ? { ...graphsStructure.kpi, NINTH_DECILE: 'graph.filters.kpi.ninthDecile' }
      : state.GRAPH.value === detailedOccupancyTableValue
      ? {
          placeholder: 'graph.filters.kpi.placeholder',
          AVERAGE: 'graph.filters.kpi.average',
        }
      : state.GRAPH.value === heatmapValue
      ? {
          placeholder: 'graph.filters.kpi.placeholder',
          AVERAGE: 'graph.filters.kpi.realAverage',
          PERCEIVED_AVERAGE: 'graph.filters.kpi.occupationPerceivedAverage',
        }
      : state.GRAPH.value === calibratedUtilizationValue
      ? {
          placeholder: 'graph.filters.kpi.placeholder',
          NB_OCCUPIED_WORKSTATION: 'graph.filters.kpi.numberOccupiedWorkstation',
          AVG_CONSECUTIVE_UTILIZATION: 'graph.filters.kpi.averageConsecutiveUtilization',
          AVG_OCCUPANCY_DURATION: 'graph.filters.kpi.averageOccupancyDuration',
          DISTRIBUTION_UTILIZATION_DURATION: 'graph.filters.kpi.distribution',
          DISTRIBUTION_UTILIZATION_PERCENTAGE: 'graph.filters.kpi.distributionPercentage',
        }
      : state.GRAPH.value === benchmarkValue
      ? {
          placeholder: 'graph.filters.kpi.placeholder',
          MAX: 'graph.filters.kpi.actualOccupancy',
          PERCEIVED_AVERAGE: 'graph.filters.kpi.perceivedOccupancy',
        }
      : state.GRAPH.value === frequentationValue
      ? {
          placeholder: 'graph.filters.kpi.placeholder',
          MAX: 'graph.filters.kpi.attendanceRate',
          ENTRIES: 'graph.filters.kpi.entries',
        }
      : graphsStructure.kpi

  const values = Object.entries(structure2).map(([value, label]) => ({ label, value }))
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const kpiValue = state.GRAPH.value === detailedOccupancyTableValue ? values[0].value : state.KPI.value
  if (state.GRAPH.value === detailedOccupancyTableValue) state.QUARTER.active = true

  const options = values.map((value) => ({
    ...value,
    label: t(value.label),
  }))
  const kpiData = options.find((value) => value.value === kpiValue)
  const type = getFilterType(state.GRAPH.value, state.KPI.active, kpiData)

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))
  return (
    <Filter
      isBenchmark={isBenchmark}
      type={type}
      placeholder={t(placeholder)}
      label={kpiData?.label}
      disabled={!state.KPI.active && !state.KPI.value}
      selected={kpiValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <>
          {state.GRAPH.value === monitoringValue &&
          (option.value == 'PERCEIVED_AVERAGE' || option.value === 'PERCEIVED_MAX') ? (
            <FilterOption
              key={option.value}
              value={option.value}
              disabled={
                state.MONITORING_ROOM_TYPE.value &&
                !state.MONITORING_ROOM_TYPE.value.every((v: string) => workstationNatures.includes(v))
              }
            >
              {option.label}
            </FilterOption>
          ) : (
            <>
              {state.GRAPH.value === monitoringValue && (
                <FilterOption key={option.value} value={option.value}>
                  {option.label}
                </FilterOption>
              )}
            </>
          )}
          {state.GRAPH.value !== monitoringValue && (
            <FilterOption key={option.value} value={option.value}>
              {option.label}
            </FilterOption>
          )}
        </>
      ))}
    </Filter>
  )
}

export default KpiFilter

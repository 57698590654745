import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/fr'

const lang = navigator.language

/**
 * @example `2024-01-01` is a day
 */
export const dateIsDay = (date: string): boolean => {
  const regexDay = /^\d{4}-\d{2}-\d{2}$/
  return regexDay.test(date)
}

/**
 * @example `2024-01-01 / 2024-01-05` is a week
 */
export const dateIsWeek = (date: string): boolean => {
  const regexWeek = /^\d{4}-\d{2}-\d{2} \/ \d{4}-\d{2}-\d{2}$/
  return regexWeek.test(date)
}

/**
 * @example `2024M01` is a month
 */
export const dateIsMonth = (date: string): boolean => {
  const regexMonth = /^\d{4}M\d{2}$/
  return regexMonth.test(date)
}

/**
 * @example `2024M01-2025M02` is a year
 */
export const dateIsYear = (date: string): boolean => {
  const regexYear = /^\d{4}M\d{2}-\d{4}M\d{2}$/
  return regexYear.test(date)
}

export const dayName = (date: Date): string => {
  switch (lang) {
    case 'fr':
    case 'fr-FR':
      return date.toLocaleString('fr-FR', { weekday: 'long', timeZone: 'UTC' })
    default:
      return date.toLocaleString('en-us', { weekday: 'long', timeZone: 'UTC' })
  }
}

/**
 * @param date: a string in one of the following formats:
 *  - /^\d{4}-\d{2}-\d{2}$/ (day)
 *  - /^\d{4}-\d{2}-\d{2} \/ \d{4}-\d{2}-\d{2}$/ (week)
 *  - /^\d{4}M\d{2}$/ (month)
 *  - /^\d{4}M\d{2}-\d{4}M\d{2}$/ (year)
 * @description Format date
 */
export const formatDate = (date: string): string => {
  if (dateIsWeek(date)) {
    const startDate = new Date(date.substring(0, date.indexOf('/')).replace(/\s/g, ''))
    return moment(startDate).locale(lang).format('YYYY-ww')
  } else if (dateIsYear(date)) {
    const startDate = date.substring(0, date.indexOf('-'))
    const endDate = date.substring(date.indexOf('-') + 1, date.length)
    return `${moment(startDate, 'YYYY[M]MM').locale(lang).format('MMM-YY')} - ${moment(endDate, 'YYYY[M]MM')
      .locale(lang)
      .format('MMM-YY')}`
  } else if (dateIsMonth(date)) {
    return moment(date, 'YYYY[M]MM').locale(lang).format('MMM-YY')
  } else if (dateIsDay(date)) {
    return moment(date).locale(lang).format('L')
  } else {
    return date
  }
}
export const toHoursAndMinutes = (totalMinutes: number): { hours: number; minutes: number } => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return { hours, minutes }
}
export const toHours = (totalMinutes: number): number => {
  return Math.round((totalMinutes / 60) * 100) / 100
}
export const formatHourForCsv = (hour: string): string | undefined => {
  switch (hour) {
    case '07:00':
      return '7h-8h'
    case '08:00':
      return '8h-9h'
    case '09:00':
      return '9h-10h'
    case '10:00':
      return '10h-11h'
    case '11:00':
      return '11h-12h'
    case '12:00':
      return '12h-13h'
    case '13:00':
      return '13h-14h'
    case '14:00':
      return '14h-15h'
    case '15:00':
      return '15h-16h'
    case '16:00':
      return '16h-17h'
    case '17:00':
      return '17h-18h'
    case '18:00':
      return '18h-19h'
    case '19:00':
      return '19h-20h'
    case '20:00':
      return '20h-21h'
    case '21:00':
      return '21h-22h'
    case '22:00':
      return '22h-23h'
  }
}

export const formatHour = (hour: string): string | undefined => {
  switch (hour) {
    case '07:00':
      return '7h'
    case '08:00':
      return '8h'
    case '09:00':
      return '9h'
    case '10:00':
      return '10h'
    case '11:00':
      return '11h'
    case '12:00':
      return '12h'
    case '13:00':
      return '13h'
    case '14:00':
      return '14h'
    case '15:00':
      return '15h'
    case '16:00':
      return '16h'
    case '17:00':
      return '17h'
    case '18:00':
      return '18h'
    case '19:00':
      return '19h'
    case '20:00':
      return '20h'
    case '21:00':
      return '21h'
    case '22:00':
      return '22h'
  }
}

export const displayDate = (date: string): string => {
  const hour = date.substring(0, date.indexOf(':'))
  const minutes = date.substring(date.indexOf(':') + 1, date.length)
  return `${parseInt(hour, 10)}${minutes !== '00' ? `h${minutes}` : 'h'}`
}

export const getMatch = (a, b) => {
  const matches = []

  for (let i = 0; i < a.length; i++) {
    for (let e = 0; e < b.length; e++) {
      if (
        b[e] !== ' ' &&
        b[e] !== i18next.t('graph.detailedOccupancy.xaxis.max') &&
        b[e] !== i18next.t('graph.filters.kpi.average')
      ) {
        if (displayDate(a[i].x) === b[e]) matches.push(e)
      }
    }
  }
  return matches
}

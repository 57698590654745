import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, ...structure } = graphsStructure.capacityType
export const values = Object.entries(structure).map(([value, label]) => ({ label, value }))

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.ROOM_SIZE_TYPE.value === value) return state

  return {
    ...state,
    ROOM_SIZE_TYPE: {
      ...initialFilterState.ROOM_SIZE_TYPE,
      value,
    },
    ROOM_SIZE_DETAILED: {
      ...initialFilterState.ROOM_SIZE_DETAILED,
    },
    ROOM_SIZE_GROUPED: {
      ...initialFilterState.ROOM_SIZE_GROUPED,
    },
    CAPACITY_SIZE: {
      ...initialFilterState.CAPACITY_SIZE,
    },
  }
}

const RoomSizeTypeFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const roomSizeValue = state.ROOM_SIZE_TYPE.value

  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const roomSizeData = options.find((option) => option.value === roomSizeValue)
  const type = getFilterType(state.GRAPH.value, state.ROOM_SIZE_TYPE.active, roomSizeValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={roomSizeData?.label}
      disabled={!state.ROOM_SIZE_TYPE.active && !state.ROOM_SIZE_TYPE.value}
      selected={roomSizeValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default RoomSizeTypeFilter

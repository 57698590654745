import { FC, useContext } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { heatmapConfig } from 'utils/constants/graphs/heatmap'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { DetailedOccupancyDataType } from 'types/GraphDataType'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'

type Props = {
  title?: string
  id?: string | undefined
  subtitle: string[]
  series: HeatmapSeries
  categories: string[]
  minifiedCategories: string[]
  capacity?: number
  colors: string[]
}

const FrequentationChart: FC<Props> = (props) => {
  const { title, subtitle, series, categories, minifiedCategories, id, capacity, colors } = props
  const { state: filters } = useContext(GraphFiltersContext)
  const { t } = useTranslation()
  const kpiValue = filters.KPI.value
  const options = heatmapConfig({
    id,
    subtitle,
    xAxisTitle: t('graph.detailedOccupancy.xaxis.hours'),
    categories,
    minifiedCategories,
    kpiValue,
    capacity,
    colors,
    tooltipFormatter: (seriesName, seriesValue, categories, dataPointIndex, seriesIndex, w) => {
      const strSeriesValue = seriesValue as unknown as number

      if (strSeriesValue === 0) {
        return '<div />'
      }

      const valueToDisplay = strSeriesValue === 1 ? 0 : Math.round(strSeriesValue / 10)
      const kpiValue = filters.KPI.value

      return kpiValue !== 'ENTRIES'
        ? `
            <div class="px-3 py-2">
              <p class="font-bold">${seriesName} - ${categories[dataPointIndex]}</p>
              <p>${
                kpiValue === 'AVERAGE'
                  ? `${t('graph.detailedOccupancy.tooltip.averageOccupancy', { seriesValue: valueToDisplay })} (${
                      w.globals.initialSeries[seriesIndex].data[dataPointIndex].count
                    } ${t('graph.stackedColumn.people')})`
                  : `${t('graph.detailedOccupancy.tooltip.maxOccupancy', { seriesValue: valueToDisplay })} (${
                      w.globals.initialSeries[seriesIndex].data[dataPointIndex].count
                    } ${t('graph.stackedColumn.people')})`
              }</p>
            </div>
      `
        : `
      <div class="px-3 py-2">
        <p class="font-bold">${seriesName} - ${categories[dataPointIndex]}</p>
        <p>${`${t('graph.detailedOccupancy.tooltip.numberOfEntries', {
          seriesValue: w.globals.initialSeries[seriesIndex].data[dataPointIndex].count,
        })} (${valueToDisplay}% ${t('graph.detailedOccupancy.tooltip.ofCapacity')})`}</p>
      </div>
`
    },
  })

  // The key property is a quick and dirty fix to solve a weird problem : when this graph replaces the "loading" one,
  // it appears messed up : no label, dashed lanes and the xAxis header are missplaced, no tooltip on hover, and so on.
  // By specifying the key, we tell React that they are completely different components, and thus it rerenders them
  // completely instead of doing some weird trick.
  return (
    <CustomHeatmapChart key={`heatmap_${JSON.stringify(series)}`} series={series} type="heatmap" options={options} />
  )
}

const CustomHeatmapChart = styled(({ series, options, ...rest }: ChartProps<DetailedOccupancyDataType>) => {
  return (
    <>
      <ReactApexChart
        key={`heatmap_${JSON.stringify(series)}`}
        series={series}
        type="heatmap"
        options={options}
        {...rest}
      />
    </>
  )
})`
  & [class='apexcharts-legend apexcharts-align-right position-top'] > [seriesname='0x'] {
    display: none !important;
  }
`

export default FrequentationChart

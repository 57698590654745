import { FC, useContext, useEffect, useState } from 'react'
import { DetailedOccupancyDataType, DetailedOccupancyDataWSType } from 'types/GraphDataType'
import DetailedOccupancyChart from './DetailedOccupancyChart'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'
import { CALENDAR_DAY, WEEK_DAY, WORKSTATION } from 'utils/constants/graphs/graphsStructure'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { FiltersType } from 'types/SavedReportsTypes'
import { dayName, displayDate } from 'utils/dateUtils'

type DetailedOccupancyFormattedDataType = {
  series: DetailedOccupancyDataType
  categories: string[]
  minifiedCategories: string[]
}

type Props = {
  loading: boolean
  data: DetailedOccupancyDataWSType
  title: string
  id?: string | undefined
  savedReportFilters?: FiltersType
  colors: string[]
}

const DetailedOccupancyController: FC<Props> = (props) => {
  const { loading, data, title, id, savedReportFilters, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<DetailedOccupancyFormattedDataType>()
  const { state: filters } = useContext(GraphFiltersContext)
  useEffect(() => {
    if (loading) {
      return
    }

    // Transform the data from the server's format to the library's format
    // Explanation why we multiplied all values by 10 :
    // As we want to inject false values to show empty columns to separate Avg/Max to the rest, we need to differentiate
    // the real zeros (0%) from the false ones (separators). We couldn't use floats (like 0.1) because the library rounds
    // the values in the legend and fucks everything up.
    // Therefore, we don't work in "per cents" but in "per thousands". (in clear, not values *100, but *1000) so
    // that the values at 0 are false values and values at 1 are real 0s. Because the previous "real 1s" are now "10".
    // Then, in the options of the graph, we can look for those 1 values and show 0 instead, and divide all other values
    // by 10.
    const formattedSeries = data.result.map((el) => {
      const formattedData = el.valuesByHour.map((subEl) => {
        return {
          x: subEl.hour,
          y: Math.max(subEl.valueInPercentage * 10, 1),
          count: subEl.count,
        }
      })
      const lang = navigator.language
      const currentDate = new Date(el.label)
      const startDate = new Date(currentDate.getFullYear(), 0, 1)
      //@ts-ignore
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
      const weekNumber = Math.ceil(days / 7)

      return {
        name: !savedReportFilters
          ? filters.DETAILED_OCCUPANCY.value !== CALENDAR_DAY.value
            ? filters.DETAILED_OCCUPANCY.value === WEEK_DAY.value
              ? t(`graph.detailedOccupancy.yaxisDays.${el.label}`)
              : el.label == '[NO_BUSINESS_UNIT]'
              ? `${t(`graph.detailedOccupancy.yaxisNoBusinessUnit`)} (${el.roomsCount}
                ${t(`graph.detailedOccupancy.wsSubtitle`)}) `
              : `${el.label} 
                (${el.roomsCount} ${
                  filters.ROOM_TYPE.value === WORKSTATION.value
                    ? t(`graph.detailedOccupancy.wsSubtitle`)
                    : t(`graph.detailedOccupancy.roomsSubtitle`)
                }) `
            : new Date(el.label).getUTCDay() == 1
            ? t('graph.common.week') +
              weekNumber +
              ' ' +
              '-' +
              ' ' +
              `${dayName(new Date(el.label))}` +
              ' ' +
              String(new Date(el.label).getUTCDate()).padStart(2, '0')
            : dayName(new Date(el.label)) + ' ' + String(new Date(el.label).getUTCDate()).padStart(2, '0')
          : savedReportFilters.ordinateAxis !== CALENDAR_DAY.value
          ? savedReportFilters.ordinateAxis === WEEK_DAY.value
            ? t(`graph.detailedOccupancy.yaxisDays.${el.label}`)
            : el.label == '[NO_BUSINESS_UNIT]'
            ? `${t(`graph.detailedOccupancy.yaxisNoBusinessUnit`)} (${el.roomsCount}
              ${t(`graph.detailedOccupancy.wsSubtitle`)}) `
            : `${el.label} 
              (${el.roomsCount} ${
                savedReportFilters.roomType === WORKSTATION.value
                  ? t(`graph.detailedOccupancy.wsSubtitle`)
                  : t(`graph.detailedOccupancy.roomsSubtitle`)
              }) `
          : lang === 'fr-FR' || lang === 'fr'
          ? new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(new Date(el.label))
          : new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date(el.label)),

        data: [
          ...formattedData,
          { x: ' ', y: 0 },
          { x: 'avg', y: Math.max(el.average * 10, 1), count: el.averageCount },
          { x: 'max', y: Math.max(el.max * 10, 1), count: el.maxCount },
        ],
      }
    })
    const formattedMax = data.max.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const formattedAvg = data.average.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const emptyLine = data.average.map((el, index) => ({
      x: index.toString(),
      y: 0,
    }))
    const baseCategories = data.average.map((el) => displayDate(el.hour))
    const categories = [
      ...baseCategories,
      ' ',
      'graph.detailedOccupancy.xaxis.avg',
      'graph.detailedOccupancy.xaxis.max',
    ].map((category) => t(category))
    const minifiedCategories = [
      ...baseCategories.map((el, index) => (index % 2 === 0 ? el : '')),
      'graph.detailedOccupancy.xaxis.avg',
      'graph.detailedOccupancy.xaxis.max',
    ].map((category) => t(category))

    setFormattedData({
      series: [
        { name: t('graph.filters.kpi.max'), data: formattedMax },
        { name: t('graph.filters.kpi.average'), data: formattedAvg },
        { name: ' ', data: emptyLine },
        ...formattedSeries.reverse(),
      ],
      categories,
      minifiedCategories,
    })
  }, [loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="heatmap" />
  }
  const sub =
    filters.ROOM_TYPE.value === WORKSTATION.value ||
    (savedReportFilters && savedReportFilters.roomType === WORKSTATION.value)
      ? [
          t('graph.detailedOccupancy.workstationsSubtitle', { roomsCount: data.totalRoomsCount }),
          t('graph.detailedOccupancy.workstationLegend'),
        ]
      : [
          t('graph.detailedOccupancy.roomsSubtitle', { roomsCount: data.totalRoomsCount }),
          t('graph.detailedOccupancy.legend'),
        ]
  return (
    <DetailedOccupancyChart
      id={id}
      title={title}
      subtitle={sub}
      series={formattedData.series}
      categories={formattedData.categories}
      minifiedCategories={formattedData.minifiedCategories}
      colors={colors}
    />
  )
}

export default DetailedOccupancyController

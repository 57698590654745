import { FC } from 'react'

import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onNewDashboardClick: () => void
  onExistingDashboardClick: () => void
}

const AddToDashboardDialog: FC<Props> = ({ onClose, open, onNewDashboardClick, onExistingDashboardClick }: Props) => {
  const { t } = useTranslation()

  const onCancelClick = () => onClose()

  return (
    <>
      <Dialog isOpen={open} onClose={onClose}>
        <DialogTitle onClose={onClose}>{t('savedReports.singleReport.dashboard.dashboardModalTitle')}</DialogTitle>
        <DialogBody>
          <div className="flex items-center justify-center m-12">
            <p className="text-center">{t('savedReports.singleReport.dashboard.dashboardModalSubtitle')}</p>
          </div>
          <div className="flex items-center justify-center mb-8">
            <Button
              onClick={onNewDashboardClick}
              className="m-2 xl:mb-0 flex items-center justify-center border-transparent"
            >
              {t('savedReports.singleReport.dashboard.dashboardModalNewDashboard')}
            </Button>
            <Button
              onClick={onExistingDashboardClick}
              className="m-2 xl:mb-0 flex items-center justify-center border-transparent"
            >
              {t('savedReports.singleReport.dashboard.dashboardModalExistingDashboard')}
            </Button>
          </div>
          {/* <Button className="m-5" color="red" onClick={onCancelClick}>
            {t('common.cancel')}
          </Button> */}
        </DialogBody>
      </Dialog>
    </>
  )
}

export default AddToDashboardDialog

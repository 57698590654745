import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure, detailedOccupancyValue } from 'utils/constants/graphs/graphsStructure'
import { optionAllValue } from 'utils/constants/graphs/global'
import { getFilterType } from 'utils/filtersUtils'

const optionAll = {
  label: graphsStructure.businessUnit.all,
  value: optionAllValue,
}

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.BUSINESS_UNITS.value === value) return state
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    FLOOR: state.FLOOR,
    COMPARTMENT: state.COMPARTMENT,
    DETAILED_OCCUPANCY: state.DETAILED_OCCUPANCY,
    ROOM_TYPE: state.ROOM_TYPE,
    CUSTOM_ATTRIBUT_WKS_VALUE: state.CUSTOM_ATTRIBUT_WKS_VALUE,
    BUSINESS_UNITS: {
      ...state.BUSINESS_UNITS,
      value,
      active: !value,
    },
    QUARTER: {
      ...initialFilterState.QUARTER,
      active: !isDetailedOccupancy && !!value,
    },
  }
}

type BusinessUnitsFilterType = {
  businessUnits: string[]
}

const BusinessUnitsFilter: FC<BusinessUnitsFilterType> = (props) => {
  const { businessUnits } = props
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const selectedBusinessUnitValue = state.BUSINESS_UNITS.value
  const label = selectedBusinessUnitValue === optionAll.value ? t(optionAll.label) : selectedBusinessUnitValue

  const type = getFilterType(state.GRAPH.value, state.BUSINESS_UNITS.active, selectedBusinessUnitValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t(graphsStructure.businessUnit.placeholder)}
      label={label}
      disabled={!state.BUSINESS_UNITS.active && !state.BUSINESS_UNITS.value}
      selected={selectedBusinessUnitValue}
      onSelect={onChange}
    >
      <FilterOption value={optionAll.value}>{t(optionAll.label)}</FilterOption>
      {businessUnits.map((option) => (
        <FilterOption key={option} value={option}>
          {option}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default BusinessUnitsFilter

import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { categories } from './barChart'
import { COLORS } from 'assets/colors/colors'

export const fakeMonthlyData = {
  totalRoomsCount: 62,
  result: [
    {
      month: '2021M001',
      real: {
        average: 44,
        max: 1,
        ninthDecile: 70,
      },
      perceived: {
        average: 41,
        max: 2,
        ninthDecile: 61,
      },
    },
    {
      month: '2021M011',
      real: {
        average: 55,
        max: 5,
        ninthDecile: 70,
      },
      perceived: {
        average: 50,
        max: 0,
        ninthDecile: 69,
      },
    },
    {
      month: '2021M021',
      real: {
        average: 41,
        max: 4,
        ninthDecile: 70,
      },
      perceived: {
        average: 35,
        max: 1,
        ninthDecile: 72,
      },
    },
    {
      month: '2021M031',
      real: {
        average: 67,
        max: 8,
        ninthDecile: 70,
      },
      perceived: {
        average: 63,
        max: 15,
        ninthDecile: 71,
      },
    },
    {
      month: '2021M041',
      real: {
        average: 22,
        max: 13,
        ninthDecile: 70,
      },
      perceived: {
        average: 24,
        max: 8,
        ninthDecile: 70,
      },
    },
    {
      month: '2021M051',
      real: {
        average: 43,
        max: 27,
        ninthDecile: 70,
      },
      perceived: {
        average: 38,
        max: 18,
        ninthDecile: 69,
      },
    },
    {
      month: '2021M061',
      real: {
        average: 44,
        max: 13,
        ninthDecile: 70,
      },
      perceived: {
        average: 43,
        max: 13,
        ninthDecile: 79,
      },
    },
    {
      month: '2021M071',
      real: {
        average: 55,
        max: 23,
        ninthDecile: 70,
      },
      perceived: {
        average: 49,
        max: 29,
        ninthDecile: 69,
      },
    },
    {
      month: '2021M081',
      real: {
        average: 41,
        max: 20,
        ninthDecile: 70,
      },
      perceived: {
        average: 32,
        max: 23,
        ninthDecile: 63,
      },
    },
    {
      month: '2021M091',
      real: {
        average: 67,
        max: 8,
        ninthDecile: 70,
      },
      perceived: {
        average: 76,
        max: 16,
        ninthDecile: 70,
      },
    },
    {
      month: '2021M0101',
      real: {
        average: 22,
        max: 13,
        ninthDecile: 70,
      },
      perceived: {
        average: 25,
        max: 5,
        ninthDecile: 73,
      },
    },
    {
      month: '2021M0111',
      real: {
        average: 43,
        max: 27,
        ninthDecile: 70,
      },
      perceived: {
        average: 34,
        max: 27,
        ninthDecile: 66,
      },
    },
  ],
}

// dataPointIndex is the hovered value's xaxis index.
const customTooltip = ({ series, seriesIndex, dataPointIndex, w: { config } }: any) => {
  const value = config.series[seriesIndex].name.split(',')[dataPointIndex]
  const percentage = (config.subtitle.text.match(/\d/g).join('') * value) / 100
  const labelPercentage = config.subtitle.text.substring(config.subtitle.text.match(/\d/g).length + 2)

  return `
      <div class="py-1 px-3">
        <div class="flex justify-center items-center">
          <div class="rounded-full mb-1 h-4 w-4" style="background-color: ${config.colors[seriesIndex]}"></div>
          <p class="font-bold ml-2 mb-2 my-1">
          ${config.series[seriesIndex].description} - ${config.xaxis.categories[dataPointIndex]}
          </p>
        </div>
        <div>
           ${Math.round(value)}% - ${Math.round(percentage)} ${labelPercentage}
        </div>
      </div>
    `
}

type OptionsType = {
  id: string | undefined
  title: string
  subtitle: string
  categories: string[]
  colors: string[]
}
export const getMonthlyOptions = (options: OptionsType): ChartProps<HeatmapSeries>['options'] => ({
  title: {
    text: options.title,
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    text: options.subtitle,
    style: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: COLORS.darkGrey50,
    },
  },
  colors:
    options.colors?.length > 0
      ? [
          options.colors.find((obj) => obj.type == 'MOY')?.color,
          options.colors.find((obj) => obj.type == 'NINETH_DECILE')?.color,
          options.colors.find((obj) => obj.type == 'MAX')?.color,
        ]
      : [COLORS.deepCerise, COLORS.blue, COLORS.lightGrey],
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '16px',
    offsetY: -30,
    fontWeight: 500,
    markers: {
      radius: 12,
    },
    // @ts-ignore: Unreachable code error
    formatter: function (seriesName, opts) {
      return [opts.w.config.series[opts.seriesIndex].description]
    },
  },
  chart: {
    id: options.id ? options.id.toString() : 'monthly-chart',
    background: 'transparent',
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
    custom: customTooltip,
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 12,
      horizontal: false,
    },
  },
  xaxis: {
    type: 'category',
    categories: options.categories,
    labels: {
      rotate: -55,
      style: {
        // If there are less color values given to the lib than there are categories, the rest will be printed in black.
        // So we must give as many colors as there are categories on the axis x.
        colors: options.categories.map(() => '#9C9CB5'),
      },
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    labels: {
      formatter: function (val) {
        return val.toFixed(0) + '%'
      },
      style: {
        colors: ['#9C9CB5'],
      },
    },
  },
  fill: {
    opacity: 1,
  },
})

import { FC } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'utils/routeUtils'

type Props = {
  items: {
    label: string
    path: string
  }[]
}

const SubNavbar: FC<Props> = (props: Props) => {
  const { items } = props
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  return (
    <div className="border-basic-300 border-b-1 flex flex-row w-full border-solid">
      {items.map((item) => (
        <NavLink
          key={item.path}
          to={generatePath(item.path, { url })}
          className="px-3 py-3 font-medium border-b-2 border-transparent"
          activeClassName="text-primary active-border"
        >
          {t(item.label)}
        </NavLink>
      ))}
    </div>
  )
}

export default SubNavbar

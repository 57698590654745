import { FC } from 'react'
import { Menu } from '@headlessui/react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import Avatar from '../../../../atoms/navbar/Avatar'
import IconSvg from '../../../../atoms/icons/IconSvg'
import NavbarMenuItem from './NavbarMenuItem'
import { PATHS } from 'utils/constants/routes/Paths'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useTranslation } from 'react-i18next'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { authService } from 'services/authService'
import { useDeleteStorageAndCookies } from 'utils/hooks/useDeleteStorageAndCookies'
import { removeHeaderToken } from 'core/http/client'

const NavbarMenu: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { clear } = useDeleteStorageAndCookies()

  const { data: user, clientCode } = useCurrentUser()

  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.AUTH.CLIENT,
    mutationFn: (currentClientCode: string) => authService.patchCurrentClient({ currentClientCode }),
    onSuccess: async () => {
      toast.success(t('nav.menu.success'))
      await queryClient.invalidateQueries(QUERY_KEYS.AUTH.USER_CURRENT)
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onLogoutIconClick = () => {
    removeHeaderToken()
    clear()
    window.jimoKill()
    // We invalidate queries because if the user logs out and logs in onto another account,
    // React Query will provide him the cached values for the former account
    queryClient.removeQueries(QUERY_KEYS.AUTH.LOGOUT)
    history.push(PATHS.HOME)
  }

  const currentClient = user?.clients.find((client) => client.code === clientCode)
  localStorage.setItem('firstDomain', JSON.stringify(currentClient?.domains[0]?.url_path))

  const fullName = user ? `${user.firstname} ${user.lastname}` : ''

  return (
    <Menu as="div" className="flex flex-col justify-center z-50">
      {({ open }) => (
        <div>
          <Menu.Button className="flex inline-flex justify-center px-1.5 w-full text-basic-100 text-sm font-medium bg-black bg-opacity-20 hover:bg-opacity-30 rounded-md focus:outline-none focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-2">
            <Avatar
              firstname={user?.firstname}
              lastname={user?.lastname}
              className={open ? 'border-primary' : 'border-basic navAvatar'}
            />
            <IconSvg name={open ? 'ARROW_UP' : 'ARROW_DOWN'} color="WHITE" className="my-auto px-3" />
          </Menu.Button>
          <Menu.Items className="z-1 divide-basic-200 absolute right-0 mr-1 mt-2 w-72 max-h-96 overflow-y-auto bg-basic-100 rounded-md focus:outline-none shadow-2xl divide-y overflow-hidden origin-top-right">
            <div className="flex my-1 w-full h-12">
              <div className="inline-block px-1">
                <Avatar firstname={user?.firstname} lastname={user?.lastname} />
              </div>
              <div className="flex flex-col justify-center px-1 w-full overflow-hidden">
                <p className="text-b2 font-bold capitalize truncate" title={fullName}>
                  {fullName.toLowerCase()}
                </p>
                <span className="text-c1 text-basic-400">Administrator</span>
              </div>
              <div className="flex flex-col items-center justify-center px-4">
                <IconSvg
                  name="LOGOUT"
                  title={t('nav.menu.logout')}
                  className="cursor-pointer"
                  onClick={onLogoutIconClick}
                />
              </div>
            </div>
            <NavbarMenuItem
              disabled
              className="cursor-pointer"
              icon="EDIT"
              onClick={() => history.push(PATHS.AUTH.CHANGE_PASSWORD)}
            >
              {t('nav.menu.password')}
            </NavbarMenuItem>
            {user && user?.clients.length > 1 && (
              <>
                <NavbarMenuItem disabled icon="UPDATE">
                  {t('nav.menu.switch')}
                </NavbarMenuItem>

                {currentClient && <NavbarMenuItem selected>{currentClient.name.toLowerCase()}</NavbarMenuItem>}
                {user?.clients
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(
                    (client) =>
                      client.code !== user?.currentClient.code && (
                        <Menu.Item key={client.code}>
                          {({ active }) => (
                            <div>
                              <NavbarMenuItem hovered={active} onClick={() => mutate(client.code)}>
                                {client.name.toLowerCase()}
                              </NavbarMenuItem>
                            </div>
                          )}
                        </Menu.Item>
                      ),
                  )}
              </>
            )}
          </Menu.Items>
        </div>
      )}
    </Menu>
  )
}

export default NavbarMenu

export const BUTTON_SIZE = {
  sm: 'px-2.5 py-2',
  md: 'px-3 py-2.5',
  lg: 'px-3.5 py-3.5',
  xl: 'px-4 py-3.5',
}

export const BUTTON_BACKGROUND_COLORS = {
  disabled: 'disabled-bg',
  transparent: 'bg-transparent',
  default: 'bg-basic',
  light: 'bg-basic-600',
  blue: 'bg-blue',
  red: 'bg-primary-400',
}

export const BUTTON_BORDER_COLORS = {
  disabled: 'border-basic-300',
  transparent: 'border-transparent',
  default: 'border-basic',
  light: 'border-basic-600',
  blue: 'border-blue',
  red: 'border-primary-400',
}

export const BUTTON_TEXT_COLORS = {
  disabled: 'text-basic-300',
  transparent: 'text-basic-100',
  default: 'text-basic',
  light: 'text-basic-600',
  blue: 'text-blue',
  red: 'text-primary-400',
}

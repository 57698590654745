import { ReactNode } from 'react'
import { bpConvert } from 'utils/breakPointsUtils'

const width = window.innerWidth

type RieCellProps = { children: ReactNode; size?: 'sm' | 'lg' }

const RieCell = ({ size = 'sm', children }: RieCellProps): JSX.Element => {
  const styles =
    size === 'sm'
      ? {}
      : {
          fontSize: bpConvert(width, 40),
          lineHeight: `${bpConvert(width, 48)}px`,
        }

  return (
    <div
      className="flex items-center bg-basic-100 dark:bg-basic justify-center text-basic dark:text-basic-100 px-2"
      style={styles}
    >
      {children}
    </div>
  )
}

export default RieCell

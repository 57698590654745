import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { getFilterType } from 'utils/filtersUtils'

const values = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
]

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.ROOM_SIZE_DETAILED.value === value) return state

  return {
    ...state,
    ROOM_SIZE_DETAILED: {
      ...initialFilterState.ROOM_SIZE_DETAILED,
      value,
    },
    ROOM_SIZE_GROUPED: {
      ...initialFilterState.ROOM_SIZE_GROUPED,
    },
    CAPACITY_SIZE: {
      ...initialFilterState.CAPACITY_SIZE,
    },
  }
}

const RoomSizeDetailedFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const roomSizeValue = state.ROOM_SIZE_DETAILED.value

  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const roomSizeData = options.find((option) => option.value === roomSizeValue)
  const type = getFilterType(state.GRAPH.value, state.ROOM_SIZE_DETAILED.active, roomSizeValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t('graph.filters.detailedRoomSize')}
      label={roomSizeData?.label}
      disabled={!state.ROOM_SIZE_DETAILED.active && !state.ROOM_SIZE_DETAILED.value}
      selected={roomSizeValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default RoomSizeDetailedFilter

import { FC, useEffect, useRef } from 'react'
import { FilterState, GraphFiltersContext } from 'components/molecules/filters/required/GraphFiltersContext'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import useBreakpoint from 'utils/hooks/useBreakpoint'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import AnalysisFilters from 'components/molecules/filters/AnalysisFilters'
import AnalysisButtons from 'components/molecules/filters/AnalysisButtons'
import ChartLayout from 'components/layouts/ChartLayout'
import FilterSkeletons from 'components/molecules/skeletons/FilterSkeletons'
import { chartLayoutSizes } from 'utils/constants/graphs/global'
import AnalysisGraphs from 'components/molecules/filters/AnalysisGraphs'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from 'components/atoms/input/FormInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { editSavedReportResolver } from 'utils/constants/forms/savedReports'
import { useMutation } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { SavedReport } from 'types/SavedReportsTypes'
import { savedReportsService } from 'services/savedReportsService'
import { getFiltersWSFromFilterState } from 'utils/filtersUtils'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useHistory } from 'react-router-dom'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'

type Props = {
  savedReport?: SavedReport
  filterState?: FilterState
  isFilterStateDirty: boolean
  graphData: any
  generateGraph: () => void
  generateHeatmapGraph: () => void
  onFilterChange: (state: FilterState) => void
  isGenerateGraphSuccess: boolean
}

const SavedReportsEditContentView: FC<Props> = (props) => {
  const {
    savedReport,
    filterState,
    isFilterStateDirty,
    graphData,
    generateGraph,
    generateHeatmapGraph,
    onFilterChange,
    isGenerateGraphSuccess,
  } = props
  const { t } = useTranslation()
  const { structure } = useFetchStructure()
  const colorsConfiguration = structure?.configuration?.graphColors
  const { breakpoint } = useBreakpoint()
  const { clientCode } = useCurrentUser()
  const didMount = useRef(false)
  const history = useHistory()
  // If the user changes his client, he has no longer access to this saved report. Therefore, we redirect him to the
  // SavedReports list.
  useEffect(() => {
    if (didMount.current) {
      history.push(generatePath(PATHS.SAVED_REPORTS.SINGLE_REPORT, { url: PATHS.SAVED_REPORTS.HOME }))
    } else if (clientCode) {
      didMount.current = true
    }
  }, [clientCode])

  const form = useForm({
    defaultValues: {
      name: savedReport?.name || '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(editSavedReportResolver),
  })

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.SAVED_REPORTS.UPDATE(savedReport?.id.toString() || '', filterState),
    mutationFn: ({
      clientCode,
      savedReportId,
      filterState,
      name,
    }: {
      clientCode: string
      savedReportId: number
      filterState: FilterState
      name: string
    }) => {
      const body = {
        id: savedReportId.toString(),
        name,
        filters: getFiltersWSFromFilterState(filterState),
      }

      return savedReportsService.update(clientCode, savedReportId.toString(), body)
    },
    onSuccess: () => {
      toast.success('Rapport édité avec succès')
      setTimeout(
        () =>
          history.push(
            generatePath(PATHS.SAVED_REPORTS.SINGLE_REPORT_ID, {
              url: PATHS.SAVED_REPORTS.HOME,
              id: savedReport?.id.toString() || '',
            }),
          ),
        2000,
      )
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const { isDirty } = form.formState

  useEffect(() => {
    // Once the value is fetched from the server, we want to set it
    form.setValue('name', savedReport?.name || '')
  }, [savedReport])

  if (!structure || !filterState || !savedReport) {
    return (
      <div className="flex flex-wrap mt-1 mb-4 mx-2">
        <FilterSkeletons />
      </div>
    )
  }

  // We consider that filters are completed only when the required ones are.
  const missingRequiredFilters = Object.values(filterState).some((filter) => filter.required && !filter.value)
  // We disable the generate button when the filters aren't fully filled. And when they are, it must be disabled while
  // the data fetched hasn't changed.
  const generateDisabled = missingRequiredFilters || (isGenerateGraphSuccess && graphData)
  const saveDisabled = missingRequiredFilters || !graphData || (!isDirty && !isFilterStateDirty)
  const graphTypeValue = filterState.GRAPH.value as GraphType
  const graphLayoutWidth = !graphTypeValue || !graphData ? 'big' : chartLayoutSizes[graphTypeValue](breakpoint)

  const onSaveButtonClick = () => {
    const { name } = form.watch()
    if (!name) {
      form.setError('name', t('forms.errors.required'))
    } else if (clientCode && savedReport && filterState) {
      mutate({ clientCode, savedReportId: savedReport.id, name, filterState })
    }
  }

  return (
    <>
      {filterState.GRAPH.value !== undefined && (
        <div className="px-2 pt-3">
          <div className="w-full md:w-3/4 pl-1">
            <FormProvider {...form}>
              <label className="text-basic-700">Name</label>
              <FormInput white name="name" />
            </FormProvider>
          </div>
          <GraphFiltersContext.Provider
            value={{
              structure,
              state: filterState,
              onFilterChange,
            }}
          >
            <div className="flex pt-3">
              <AnalysisFilters
                filterState={filterState}
                missingRequiredFilters={missingRequiredFilters}
                graphType={graphTypeValue}
              />
              <AnalysisButtons
                onSave={onSaveButtonClick}
                onGenerateClick={generateGraph}
                onGenerateHeatmapClick={generateHeatmapGraph}
                isGeneratedDisabled={generateDisabled}
                isSaveDisabled={saveDisabled}
                graphType={graphTypeValue}
              />
            </div>
            <div className="flex flex-1 mx-2">
              <ChartLayout fullH width={graphLayoutWidth}>
                <AnalysisGraphs
                  filterState={filterState}
                  graphData={graphData}
                  colorsConfiguration={colorsConfiguration}
                />
              </ChartLayout>
            </div>
          </GraphFiltersContext.Provider>
        </div>
      )}
    </>
  )
}

export default SavedReportsEditContentView

import { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { usePermissions } from 'utils/hooks/usePermissions'

export const useAuthentication = (): { isAuthenticated: boolean; accessToken?: string } & ReturnType<
  typeof usePermissions
> => {
  const [cookies] = useCookies(['access_token', 'refresh_token'])
  const accessToken: string | undefined = cookies['access_token']
  const refreshToken: string | undefined = cookies['refresh_token']
  const { data: user } = useCurrentUser(!!(refreshToken || accessToken))
  const permissionProps = usePermissions()
  const isAuthenticated = useMemo(
    () => Boolean(user && (accessToken || refreshToken)),
    [user, accessToken, refreshToken],
  )

  return { isAuthenticated, ...permissionProps, accessToken }
}

import { FC, useEffect, useState } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { horizontalBarChartOptions } from 'utils/constants/graphs/horizontalStackedBarChart'
import { useTranslation } from 'react-i18next'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'

type Props = {
  data: ColumnChartSeries
  title: string
  id?: string
  totalRoomsCount: number
  categories: (string | string[])[]
  minutes: any
  colors: string[]
}

const UtilizationQualityChart: FC<Props> = (props) => {
  const { data, title, categories, id, totalRoomsCount, minutes, colors } = props
  const { t } = useTranslation()

  const [chartData, setChartData] = useState({ series: data, categories })

  useEffect(() => {
    setChartData({
      series: data,
      categories,
    })
  }, [data, categories])

  const filterOnDeleteClick = (indexToRemove: number, elementIndex: number, arrayLength: number) => {
    return elementIndex !== indexToRemove
  }

  const options = horizontalBarChartOptions({
    categories: chartData.categories.length === 0 ? [' '] : chartData.categories,
    id,
    title,
    graphType: 'UTILIZATION_QUALITY',
    minutes,
    colors,
    subtitle: [
      t('graph.common.roomsSubtitle', { roomsCount: totalRoomsCount }),
      t('graph.utilizationQuality.legend'),
      t('graph.utilizationQuality.capacitySubtitle'),
    ],
    onRightClickCallback: (indexToRemove) => {
      setChartData((prevState) => {
        return {
          series: prevState.series.filter((el, index) =>
            filterOnDeleteClick(indexToRemove, index, prevState.series.length),
          ),
          categories: prevState.categories.filter((el, index) =>
            filterOnDeleteClick(indexToRemove, index, prevState.categories.length),
          ),
        }
      })
    },
  })
  return (
    <>
      <ReactApexChart<ColumnChartSeries>
        type="bar"
        height={chartData.categories.length > 10 ? 50 * chartData.categories.length : undefined}
        options={options}
        series={data}
      />
    </>
  )
}

export default UtilizationQualityChart

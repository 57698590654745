import { FC, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../../../atoms/button/Button'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'

import * as Yup from 'yup'
import FormInput from '../../../atoms/input/FormInput'
import Select from '../../../atoms/select/Select'
import Option from '../../../atoms/select/Option'

import useCurrentUser from 'utils/hooks/useCurrentUser'
import { sitesService } from 'services/sitesService'
import Input from 'components/atoms/input/Input'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('forms.errors.required'),
})

type Props = {
  open: boolean
  onClose: () => void
  domainPath: string
  siteData: any
}

const EditIdCardDialog: FC<Props> = (props) => {
  const { open, onClose, domainPath, siteData } = props
  const { t } = useTranslation()
  const [idCardData, setIdCardData] = useState(siteData)
  const [fileExtensionNotValid, setFileExtensionNotValid] = useState(false)
  const [selected, setSelected] = useState(siteData.areaUnit)
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false)

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: 'defaultName',
    },
  })

  const { clientCode = '' } = useCurrentUser()
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL = ''
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        //@ts-ignore
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const { isLoading, mutate } = useMutation({
    mutationFn: (modifiedSite) => {
      //@ts-ignore
      return sitesService.editSite(clientCode, domainPath, modifiedSite)
    },
    onSuccess: async () => {
      toast.success(t('idCard.success'))
      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  const picture = useMutation({
    mutationFn: (image) => {
      //@ts-ignore
      return sitesService.editImage(clientCode, domainPath, image)
    },
    onSuccess: async () => {
      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  const onSubmit = ({
    population,
    seatNumber,
    totalArea,
    workstationsArea,
    workstationsCount,
    price,
    bubblesArea,
    bubblesCount,
    meetingRoomArea,
    meetingRoomCount,
    areaCost,
  }: {
    population: number
    seatNumber: number
    totalArea: string
    workstationsArea: string
    price: number
    bubblesArea: string
    meetingRoomArea: string
    workstationsCount: number
    bubblesCount: number
    meetingRoomCount: number
    areaCost: number
  }) => {
    const body = {
      population: population,
      totalArea: totalArea,
      areaUnit: selected,
      areaPrice: price,
      areaCost: areaCost,
      desks: {
        count: workstationsCount,
        area: workstationsArea,
      },
      focusRooms: {
        count: bubblesCount,
        area: bubblesArea,
      },
      meetingRooms: {
        count: meetingRoomCount,
        area: meetingRoomArea,
      },
      seatsCount: seatNumber,
    }
    if (selectedFile)
      getBase64(selectedFile)
        .then((result) => {
          selectedFile['base64'] = result
          //@ts-ignore
          picture.mutate({ picture: selectedFile.base64.split(',')[1] })
        })
        .catch((err) => {
          console.log(err)
        })
    //@ts-ignore
    mutate(body)
  }
  const onModalClose = () => {
    if (!isLoading) {
      onClose()
    }
  }

  const { name } = form.watch()
  const disableConfirm = !name
  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    const fileExtension = file.name.split('.').pop().toLowerCase()
    const imageExtensions = /(jpg|jpeg|png|gif)$/
    const maxSizeInBytes = 50 * 1024 * 1024

    if (file.size > maxSizeInBytes) {
      fileInputRef.current.value = null
      setFileSizeExceeded(true)
      return
    }

    if (imageExtensions.test(fileExtension)) {
      setSelectedFile(file)
      setFileExtensionNotValid(false)
    } else {
      fileInputRef.current.value = null
      setSelectedFile(null)
      setFileExtensionNotValid(true)
    }
  }

  return (
    <Dialog isOpen={open} onClose={onModalClose} className={'max-w-3xl rounded-none'}>
      <DialogTitle>{t('idCard.title')}</DialogTitle>
      <DialogBody>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-wrap">
            <div className="space-y-4 w-1/3 my-2 mr-28">
              <p>{t('idCard.unit')}</p>
              <Select
                label={selected}
                placeholder={t('idCard.unit')}
                value={selected}
                onChange={setSelected}
                className="bg-transparent"
              >
                <Option value={'m²'}>
                  <span>m²</span>
                </Option>
                <Option value={'sq ft.'}>
                  <span>sq ft.</span>
                </Option>
              </Select>
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.image')}</p>
              <Input
                style={{ height: '4rem' }}
                white
                type="file"
                placeholder={t('idCard.image')}
                onChange={handleFileChange}
                accept="image/x-png,image/jpg,image/jpeg"
                ref={fileInputRef}
                name="image"
              />
              {fileSizeExceeded && <small style={{ color: 'red' }}>{t('idCard.fileSizeExceeded')}</small>}
              {fileExtensionNotValid && <small style={{ color: 'red' }}>{t('idCard.fileExtensionNotValid')}</small>}
            </div>
            <div className="space-y-4 w-1/2">
              <p>
                {t('idCard.totalCost')}
                {siteData.areaUnit ?? 'm²'}
              </p>
              <FormInput white placeholder={t('idCard.totalCost')} name="areaCost" defaultValue={siteData.areaCost} />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.price')}</p>
              <FormInput white placeholder={t('idCard.price')} name="price" defaultValue={siteData.areaPrice} />
            </div>

            <div className="space-y-4 w-1/2">
              <p>{t('idCard.population')}</p>
              <FormInput
                white
                placeholder={t('idCard.population')}
                name="population"
                defaultValue={siteData.population}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.totalArea')}</p>
              <FormInput white placeholder={t('idCard.totalArea')} name="totalArea" defaultValue={siteData.totalArea} />
            </div>

            <div className="space-y-4 w-1/2">
              <p>{t('idCard.workstationsCount')}</p>
              <FormInput
                white
                placeholder={t('idCard.workstationsCount')}
                name="workstationsCount"
                defaultValue={idCardData.desks ? idCardData.desks.count : null}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.workstationsArea')}</p>
              <FormInput
                white
                placeholder={t('idCard.workstationsArea')}
                name="workstationsArea"
                defaultValue={idCardData.desks ? idCardData.desks.area : null}
              />
            </div>

            <div className="space-y-4 w-1/2">
              <p>{t('idCard.bubblesCount')}</p>
              <FormInput
                white
                placeholder={t('idCard.bubblesCount')}
                name="bubblesCount"
                defaultValue={idCardData.focusRooms ? idCardData.focusRooms.count : null}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.bubblesArea')}</p>
              <FormInput
                white
                placeholder={t('idCard.bubblesArea')}
                name="bubblesArea"
                defaultValue={idCardData.focusRooms ? idCardData.focusRooms.area : null}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.meetingRoomCount')}</p>
              <FormInput
                white
                placeholder={t('idCard.meetingRoomCount')}
                name="meetingRoomCount"
                defaultValue={idCardData.meetingRooms ? idCardData.meetingRooms.count : null}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.meetingRoomArea')}</p>
              <FormInput
                white
                placeholder={t('idCard.meetingRoomArea')}
                name="meetingRoomArea"
                defaultValue={idCardData.meetingRooms ? idCardData.meetingRooms.area : null}
              />
            </div>
            <div className="space-y-4 w-1/2">
              <p>{t('idCard.seatNumber')}</p>
              <FormInput
                white
                placeholder={t('idCard.seatNumber')}
                name="seatNumber"
                defaultValue={idCardData.seatsCount}
              />
            </div>
            <div className="space-y-4 w-1/2"></div>
            <div className="mt-8 flex justify-center space-x-4">
              <Button color="red" onClick={onModalClose} disabled={isLoading}>
                {t('common.cancel')}
              </Button>
              <Button type="submit" disabled={disableConfirm || isLoading}>
                {t('common.confirm')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </DialogBody>
    </Dialog>
  )
}

export default EditIdCardDialog

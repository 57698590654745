import { FC, useEffect, useState } from 'react'
import LoadingChart from '../LoadingChart'
import StackedColumnChart from './StackedColumnChart'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'
import { useTranslation } from 'react-i18next'
import { ColumnChartDataWSType } from 'types/GraphDataType'

type FormattedData = {
  data: ColumnChartSeries
  categories: string[]
}

type Props = {
  loading?: boolean
  data: ColumnChartDataWSType
  // These props are temporary, while graph 4 data aren't available
  monthlyData?: ColumnChartSeries
  monthlyCategories?: string[]
  title: string
  id?: string
}

const UtilizationOfSpaceController: FC<Props> = (props) => {
  const { loading = false, data, monthlyData, monthlyCategories, title, id } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<FormattedData>()

  useEffect(() => {
    if (loading) {
      return
    }

    const categories: string[] = []
    // Regular expression
    const regex = /\d/
    data.result.forEach((roomGroup) =>
      regex.test(roomGroup.capacityGroup)
        ? roomGroup.roomsCount == '1'
          ? categories.push(
              t('graph.stackedColumn.oneXaxisLabels', {
                capacity: t(`graph.stackedColumn.xAxisCapacity.${roomGroup.capacityGroup}`, {
                  defaultValue: `${roomGroup.capacityGroup}`,
                }),
                amount: roomGroup.roomsCount,
              }),
            )
          : categories.push(
              t('graph.stackedColumn.xAxisLabels', {
                capacity: t(`graph.stackedColumn.xAxisCapacity.${roomGroup.capacityGroup}`, {
                  defaultValue: `${roomGroup.capacityGroup}`,
                }),
                amount: roomGroup.roomsCount,
              }),
            )
        : categories.push(
            t(
              roomGroup.roomsCount == '1'
                ? 'graph.stackedColumn.xAxisLabelswithoutpSingle'
                : 'graph.stackedColumn.xAxisLabelswithoutp',
              {
                capacity: t(`graph.stackedColumn.xAxisCapacity.${roomGroup.capacityGroup}`, {
                  defaultValue: `${roomGroup.capacityGroup}`,
                }),
                amount: roomGroup.roomsCount,
              },
            ),
          ),
    )

    const occupanciesByCount: Record<string, string, number[]> = {}

    // Loop for each room size
    data.result.forEach((roomGroup, index) => {
      // Loop for each number of people occupying this room (by percentage of the time)
      roomGroup.occupancies.forEach((occupancy) => {
        // We regroup the people count from 12 to 25 together
        const key = (
          occupancy.peopleCount <= 11 ? occupancy.peopleCount.toString() : '11+'
        ) as keyof typeof occupanciesByCount

        if (!occupanciesByCount[key]) {
          occupanciesByCount[key] = []
        }

        if (occupanciesByCount[key][index] === undefined) {
          occupanciesByCount[key].push(occupancy.rate)
        } else {
          const oldValue = occupanciesByCount[key][index]
          occupanciesByCount[key][index] = oldValue + occupancy.rate
        }
      })
    })
    const ratesByMinutes: Record<string, string, number[]> = {}

    data.result.forEach((roomGroup, index) => {
      roomGroup.occupancies.forEach((occupancy) => {
        const key = (
          occupancy.peopleCount <= 11 ? occupancy.peopleCount.toString() : '11+'
        ) as keyof typeof ratesByMinutes

        if (!ratesByMinutes[key]) {
          ratesByMinutes[key] = []
        }

        if (ratesByMinutes[key][index] === undefined) {
          ratesByMinutes[key].push(occupancy.durationMinutes)
        } else {
          const oldValue = ratesByMinutes[key][index]
          ratesByMinutes[key][index] = oldValue + occupancy.durationMinutes
        }
      })
    })

    const occupanciesAsSeries = Object.entries(occupanciesByCount).map(([name, data]) => ({
      name,
      data,
    }))
    const minutesAsSeries = Object.entries(ratesByMinutes).map(([name, data]) => ({
      name,
      data,
    }))
    setFormattedData({
      data: occupanciesAsSeries,
      minutes: minutesAsSeries,
      categories,
    })
  }, [loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="bar" stacked />
  }

  return (
    <StackedColumnChart
      id={id}
      title={title}
      data={monthlyData || formattedData.data}
      categories={monthlyCategories || formattedData.categories}
      roomsCount={data.totalRoomsCount}
      minutes={formattedData.minutes}
    />
  )
}

export default UtilizationOfSpaceController

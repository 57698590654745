import { FC } from 'react'

type Props = {
  className?: string
}

const DialogBody: FC<Props> = (props) => {
  const { className = '', children } = props

  return <div className={`p-4 ${className}`}>{children}</div>
}

export default DialogBody

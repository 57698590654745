import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { getFilterType, getLabel, sortArrayByLanguage } from 'utils/filtersUtils'
import { detailedOccupancyValue } from 'utils/constants/graphs/graphsStructure'

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.BUSINESS_UNITS.value === value) return state
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    FLOOR: state.FLOOR,
    COMPARTMENT: state.COMPARTMENT,
    ROOM_TYPE: state.ROOM_TYPE,
    BUSINESS_UNITS: {
      ...state.BUSINESS_UNITS,
      value,
      active: !value,
    },
    QUARTER: {
      ...initialFilterState.QUARTER,
      active: !isDetailedOccupancy && !!value,
    },
    DETAILED_OCCUPANCY: {
      ...initialFilterState.DETAILED_OCCUPANCY,
      active: isDetailedOccupancy,
    },
    CUSTOM_ATTRIBUT_COLLAB_VALUE: {
      ...state.CUSTOM_ATTRIBUT_COLLAB_VALUE,
      value,
      active: !value,
    },
  }
}

type customAttributsCollabFilterType = {
  customAttributsCollab: string[]
}

const CustomAttributCollabValuesFilter: FC<customAttributsCollabFilterType> = (props) => {
  const { customAttributsCollab } = props
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const selectedCollabCustomAttribut = state.CUSTOM_ATTRIBUT_COLLAB_VALUE.value
  const label = selectedCollabCustomAttribut
  const options = sortArrayByLanguage(customAttributsCollab?.values).map((customAttribut) => ({
    label: getLabel(customAttribut),
    value: customAttribut.code,
  }))

  const type = getFilterType(state.GRAPH.value, state.CUSTOM_ATTRIBUT_COLLAB_VALUE.active, selectedCollabCustomAttribut)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={getLabel(customAttributsCollab)}
      label={label}
      disabled={!state.CUSTOM_ATTRIBUT_COLLAB_VALUE.active && !state.CUSTOM_ATTRIBUT_COLLAB_VALUE.value}
      selected={selectedCollabCustomAttribut}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default CustomAttributCollabValuesFilter

import { FC } from 'react'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  WORKSTATION,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  heatmapValue,
  roomBookingValue,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'
import HorizontalBarChartController from '../charts/topFlop/HorizontalBarChartController'
import {
  ColumnChartDataWSType,
  DetailedOccupancyDataWSType,
  MonthlyDataWSType,
  RestaurantDataWSType,
  TopFlopDataWSType,
  FrequentationDataWSType,
  UtilizationQualityDataWSType,
  HeatmapV2DataWSType,
  RoomBookingDataWSType,
  BenchmarkDataWSType,
} from 'types/GraphDataType'
import UtilizationOfSpaceController from '../charts/utilizationOfSpace/UtilizationOfSpaceController'
import DetailedOccupancyController from '../charts/detailedOccupancy/DetailedOccupancyController'
import HeatmapController from '../charts/heatmap/HeatmapController'
import RestaurantController from '../charts/RIE/RestaurantController'
import FrequentationController from '../charts/RIE/FrequentationController'
import SpaceAttendanceController from '../charts/spaceAttendance/SpaceAttendanceController'
import UtilizationQualityController from '../charts/utilizationQuality/utilizationQualityController'
import { FilterState } from './required/GraphFiltersContext'
import MonthlyController from '../charts/monthly/MonthlyController'
import { useTranslation } from 'react-i18next'
import RoomBookingController from '../charts/roomBooking/roomBookingController'
import BenchmarkController from '../charts/benchmark/BenchmarkController'
import NoDataComponent from '../charts/NoDataComponent'

type Props = {
  filterState: FilterState
  graphData?: unknown
  zoom?: number
  colorsConfiguration?: unknown
}

const AnalysisGraphs: FC<Props> = (props) => {
  const { filterState, graphData, zoom, colorsConfiguration } = props
  const { t } = useTranslation()
  const graphTypeValue = filterState.GRAPH.value
  // We show a loading graph when there are no data. As we reset the mutation on every filter change, it will also
  // reset the data, and it's what we want.
  const loadingGraph = !graphData
  const isWorkStation = filterState.ROOM_TYPE.value === WORKSTATION.value
  const oneGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'ONE_COLOR')
  const twoGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'TWO_COLORS')
  const threeGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'THREE_COLORS')
  const fourOccupancyGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'FOUR_COLORS_OCCUPANCY')
  const fourBenchmarkGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'FOUR_COLORS_BENCHMARK')
  const fourBookingGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'FOUR_COLORS_BOOKING')
  const elevenGraphColors = colorsConfiguration?.filter((elt) => elt.graph === 'ELEVEN_COLORS')
  return (
    <>
      {graphData?.result?.length > 0 || graphData?.results?.length > 0 ? (
        <>
          {graphTypeValue === topFlopValue && (
            <HorizontalBarChartController
              loading={loadingGraph}
              data={graphData as TopFlopDataWSType}
              title={t('graph.topFlop.title')}
              colors={oneGraphColors}
            />
          )}
          {graphTypeValue === stackedColumnValue && (
            <UtilizationOfSpaceController
              loading={loadingGraph}
              data={graphData as ColumnChartDataWSType}
              title={t('graph.stackedColumn.title')}
            />
          )}
          {graphTypeValue === detailedOccupancyValue && (
            <DetailedOccupancyController
              loading={loadingGraph}
              data={graphData as DetailedOccupancyDataWSType}
              colors={elevenGraphColors}
              title={
                isWorkStation
                  ? t('graph.detailedOccupancy.workstationTitle')
                  : t('graph.detailedOccupancy.defaultTitle')
              }
            />
          )}
          {graphTypeValue === monthlyValue && (
            <MonthlyController
              title={isWorkStation ? t('graph.monthly.titleWorkstation') : t('graph.monthly.title')}
              isWorkStation={filterState.ROOM_TYPE.value === WORKSTATION.value}
              loading={loadingGraph}
              data={graphData as MonthlyDataWSType}
              colors={threeGraphColors}
            />
          )}
          {graphTypeValue === RIE && (
            <RestaurantController
              title={t('graph.restaurant.title')}
              loading={loadingGraph}
              data={graphData as RestaurantDataWSType}
              colors={twoGraphColors}
            />
          )}
          {graphTypeValue === frequentationValue && (
            <FrequentationController
              loading={loadingGraph}
              data={graphData as FrequentationDataWSType}
              colors={elevenGraphColors}
            />
          )}
          {graphTypeValue === spaceAttendanceValue && (
            <SpaceAttendanceController
              loading={loadingGraph}
              data={graphData as FrequentationDataWSType}
              colors={elevenGraphColors}
            />
          )}
          {graphTypeValue === utilizationQualityValue && (
            <UtilizationQualityController
              loading={loadingGraph}
              data={graphData as UtilizationQualityDataWSType}
              title={t('graph.utilizationQuality.title')}
              colors={fourOccupancyGraphColors}
            />
          )}
          {graphTypeValue === roomBookingValue && (
            <RoomBookingController
              loading={loadingGraph}
              data={graphData as RoomBookingDataWSType}
              title={t('graph.roomBooking.title')}
              colors={fourBookingGraphColors}
            />
          )}
          {graphTypeValue === heatmapValue && graphData && graphData.result && (
            <HeatmapController
              loading={loadingGraph}
              data={graphData as HeatmapV2DataWSType}
              zoom={zoom}
              colors={elevenGraphColors}
            />
          )}
          {graphTypeValue === benchmarkValue && graphData && (
            <BenchmarkController
              title={t('graph.benchmark.title', { capacity: graphData.buildingsCount })}
              loading={loadingGraph}
              data={graphData as BenchmarkDataWSType}
              colors={fourBenchmarkGraphColors}
            />
          )}
        </>
      ) : (
        (graphData?.result?.length == 0 || graphData?.results?.length == 0) && (
          <NoDataComponent
            roomType={filterState.ROOM_TYPE?.value || filterState.MONITORING_ROOM_TYPE?.value}
          ></NoDataComponent>
        )
      )}
    </>
  )
}

export default AnalysisGraphs

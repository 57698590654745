import { FC, useEffect, useState } from 'react'
import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import SaveFiltersDialog from '../dialogs/filters/SaveFiltersDialog'
import { GraphType } from './required/GraphFilter'
import { getExportFormattedData } from 'utils/exportUtils'
import { ExportDataType } from 'types/ExportType'
import ExportationDialog from '../dialogs/exportation/ExportationDialog'
import {
  calibratedUtilizationValue,
  countingMaxValue,
  detailedOccupancyTableValue,
  heatmapValue,
  monitoringValue,
} from 'utils/constants/graphs/graphsStructure'

type Props = {
  onGenerateClick: () => void
  onGenerateHeatmapClick: () => void
  isGeneratedDisabled: boolean
  isSaveDisabled: boolean
  // If this prop is given to this component, it means we're on edit mode
  onSave?: () => void
  setMapZoom?: () => void
  graphType?: GraphType
  exportData?: any
  filters?: any
  zoom?: number
}

const AnalysisButtons: FC<Props> = (props) => {
  const {
    onGenerateClick,
    isGeneratedDisabled,
    isSaveDisabled,
    onSave,
    graphType,
    exportData,
    onGenerateHeatmapClick,
    filters,
    setMapZoom,
    zoom,
  } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [csvData, setCSVData] = useState<ExportDataType>([])
  const [exportModal, setExportModal] = useState(false)
  const isDetailedOccupancyTable = graphType === detailedOccupancyTableValue
  const isMonitoring = graphType === monitoringValue
  const isHeatmap = graphType === heatmapValue
  const isCalibratedUtilization = graphType === calibratedUtilizationValue
  const isCountingMax = graphType === countingMaxValue
  let exportFormattedData
  let translatedHeaders
  const onExportClick = () => {
    setMapZoom()
    if (isHeatmap && filters.SELECTED_PERIOD) {
      exportFormattedData = getExportFormattedData(
        filters.ROOM_TYPE.value,
        graphType,
        exportData,
        '',
        filters.SELECTED_PERIOD.value,
      )
      translatedHeaders = exportFormattedData.headers.map((header) => t(header))

      setCSVData([translatedHeaders, ...exportFormattedData.data])
    }
    setExportModal(!exportModal)
  }

  useEffect(() => {
    if (graphType && exportData && !isHeatmap) {
      exportFormattedData = getExportFormattedData(filters.ROOM_TYPE.value, graphType, exportData)
      translatedHeaders = exportFormattedData.headers.map((header) => t(header))

      setCSVData([translatedHeaders, ...exportFormattedData.data])
    }
  }, [exportData])

  const onClose = () => {
    setOpen(false)
    setExportModal(false)
  }
  const onSaveButtonClick = () => {
    if (onSave) {
      onSave()
    } else {
      setOpen(true)
    }
  }

  return (
    <div className="w-2/10 2xl:w-3/10 flex flex-wrap items-start px-4 text-b2 border-l-1 border-basic-300">
      <Button
        className={isDetailedOccupancyTable || isMonitoring || isCalibratedUtilization ? `hidden` : `w-full 2xl:w-auto`}
        size="md"
        rounded="xl"
        disabled={isGeneratedDisabled}
        onClick={onGenerateClick}
        style={{
          display:
            isDetailedOccupancyTable || isMonitoring || isCalibratedUtilization || isCountingMax ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        title={t('analysis.report.generate')}
      >
        <IconSvg name="GENERATE" color={'WHITE'} />
      </Button>
      <Button
        className={
          !isDetailedOccupancyTable && !isMonitoring && !isCalibratedUtilization && !isCountingMax
            ? 'hidden'
            : 'items-center justify-center w-full 2xl:w-auto'
        }
        rounded="xl"
        disabled={isGeneratedDisabled}
        onClick={onGenerateHeatmapClick}
        style={{ width: '100px' }}
        title={t('analysis.report.export')}
      >
        <IconSvg name="EXPORT" color={'WHITE'} />
      </Button>
      <div className="2xl:mt-0 flex flex-col xl:flex-row flex-wrap justify-around 2xl:justify-evenly flex-grow">
        {/* We show the export button only on the AnalysisView, not on the edit SavedReport view */}
        {!onSave && (
          <Button
            disabled={isSaveDisabled}
            onClick={onExportClick}
            className={
              isDetailedOccupancyTable || isMonitoring || isCalibratedUtilization || isCountingMax
                ? `hidden`
                : `` + `justify-center ${isSaveDisabled ? 'pointer-events-none cursor-not-allowed' : ''}`
            }
            rounded="xl"
            style={{
              width: '100px',
              display:
                isDetailedOccupancyTable || isMonitoring || isCalibratedUtilization || isCountingMax ? 'none' : 'flex',
              alignItems: 'center',
            }}
            title={t('analysis.report.export')}
          >
            <IconSvg name="EXPORT" color={'WHITE'} />
          </Button>
        )}
        <Button
          className="text-center"
          size="md"
          rounded="xl"
          disabled={isSaveDisabled}
          onClick={onSaveButtonClick}
          style={{ width: '100px' }}
          title={t('analysis.report.save')}
        >
          <IconSvg name="SAVE" color={'WHITE'} />
        </Button>
        {!onSave && open && <SaveFiltersDialog open={open} onClose={onClose} />}
        {!onSave && exportModal && (
          <ExportationDialog
            data={csvData}
            open={exportModal}
            onClose={onClose}
            graphType={graphType}
            filters={filters}
            zoom={zoom}
          />
        )}
      </div>
    </div>
  )
}

export default AnalysisButtons

import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { graphService } from 'services/graphService'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import { FILTER_ARROW_ICONS } from 'styles/Filters'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import AnalysisFilters from 'components/molecules/filters/AnalysisFilters'
import { GraphFiltersContext, initialFilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import FilterSkeletons from 'components/molecules/skeletons/FilterSkeletons'
import { toast } from 'react-toastify'
import AnalysisButtons from 'components/molecules/filters/AnalysisButtons'
import InformativeSection from 'components/molecules/informativeSection/informativeSection'
import { getLabelsfilter } from 'utils/filtersUtils'
import ChartLayout from 'components/layouts/ChartLayout'
import AnalysisGraphs from 'components/molecules/filters/AnalysisGraphs'
import { chartLayoutSizes } from 'utils/constants/graphs/global'
import useBreakpoint from 'utils/hooks/useBreakpoint'

const BenchmarkView: FC = () => {
  const { t } = useTranslation()
  const [displayInformativeSection, setDisplayInformativeSection] = useState(false)
  const [filterState, setFilterState] = useState(initialFilterState)
  const [configuration, setConfiguration] = useState()
  const [selectedSite, setSelectedSite] = useState()
  const graphTypeValue = filterState.GRAPH.value as GraphType
  const { clientCode = '' } = useCurrentUser()
  useFetchStructure()
  const { isLoading: isStructureLoading, isError, structure } = useFetchStructure()
  const colorsConfiguration = structure?.configuration?.graphColors
  const { mutate, data, isLoading, isSuccess, reset } = useMutation({
    mutationKey: QUERY_KEYS.ANALYSIS.GRAPH(filterState),
    mutationFn: (clientCode: string) => graphService.generateGraph(clientCode, filterState),
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  useEffect(() => {
    setSelectedSite(structure?.domains.filter((elt) => elt.path == filterState.SITE.value))
  }, [filterState.SITE.value])
  // Whenever the user changes his client, we must reset the filters as the data changes
  useEffect(() => {
    setFilterState(initialFilterState)
  }, [clientCode])
  useEffect(() => {
    if (selectedSite && selectedSite.length > 0) setConfiguration(selectedSite[0].configuration)
  }, [selectedSite])
  const { breakpoint } = useBreakpoint()
  const graphLayoutWidth = !graphTypeValue || !data ? 'big' : chartLayoutSizes[graphTypeValue](breakpoint)
  const missingRequiredFilters = Object.values(filterState).some((filter) => filter.required && !filter.value)
  const generateDisabled = missingRequiredFilters || (isSuccess && data)
  const saveDisabled = missingRequiredFilters || !data
  const formattedFilters = getLabelsfilter(filterState, structure)

  if (isStructureLoading || !structure) {
    return (
      <div className="flex flex-wrap mt-1 mb-4 mx-2">
        <FilterSkeletons />
      </div>
    )
  }
  const generateGraph = async () => {
    if (clientCode) {
      mutate(clientCode)
      setDisplayInformativeSection(true)
    }
  }
  return (
    <div>
      <GraphFiltersContext.Provider
        value={{
          structure,
          state: filterState,
          onFilterChange: (state: FilterState) => {
            reset()
            setFilterState(state)
          },
        }}
      >
        <div className="flex px-2 pt-3">
          <AnalysisFilters
            filterState={filterState}
            missingRequiredFilters={missingRequiredFilters}
            graphType={'BENCHMARK'}
          />
          {isLoading && <LoaderComponent />}

          <AnalysisButtons
            exportData={data}
            graphType={filterState.GRAPH.value as GraphType}
            onGenerateClick={generateGraph}
            isGeneratedDisabled={generateDisabled}
            isSaveDisabled={saveDisabled}
            filters={filterState}
          />
        </div>
        <div
          className={`flex flex-1`}
          style={{
            overflowY: 'hidden',
          }}
        >
          <ChartLayout fullH width={graphLayoutWidth}>
            <AnalysisGraphs filterState={filterState} graphData={data} colorsConfiguration={colorsConfiguration} />
          </ChartLayout>

          {displayInformativeSection && data && (
            <InformativeSection
              //@ts-ignore
              graph={'BENCHMARK'}
              period={formattedFilters.rangeType ? formattedFilters.rangeType : ''}
              capacity={
                data.capacity
                  ? `${data.capacity} ${t('informativeSection.people')}`
                  : data.totalRoomsCount !== undefined
                  ? filterState.ROOM_TYPE.value == 'WORKSTATION'
                    ? `${data.totalRoomsCount} ${t('informativeSection.workstation')}`
                    : `${data.totalRoomsCount} ${t('informativeSection.room')}`
                  : undefined
              }
              site={formattedFilters.siteFilter}
              building={formattedFilters.buildingCode ? formattedFilters.buildingCode : ''}
              floor={formattedFilters.floorName ? formattedFilters.floorName : ''}
              spaceType={formattedFilters.roomType ? formattedFilters.roomType : ''}
              kpi={formattedFilters.kpi}
              analysisHours={configuration}
            />
          )}
        </div>
      </GraphFiltersContext.Provider>
    </div>
  )
}

export default BenchmarkView

import { ColumnChartDataWSType } from 'types/GraphDataType'
import { COLORS } from 'assets/colors/colors'
import i18next from 'i18next'
import { toHoursAndMinutes } from 'utils/dateUtils'

type OptionsType = {
  id: string | undefined
  title: string
  subtitle: string[]
  categories: string[]
  yaxisTitle: string
  xaxisTitle: string
  minutes?: any
}

// eslint-disable-next-line

export const getStackedColumnOptions = (options: OptionsType): Record<string, unknown> => ({
  chart: {
    id: options.id ? options.id.toString() : 'utilization-chart',
    type: 'bar',
    stacked: true,
    stackType: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  tooltip: {
    enabled: true,
    custom: ({ series, seriesIndex, dataPointIndex, w: config }: any) => {
      return `<div class="py-1 px-3"><p class="font-bold mb-2">${config.config.series[seriesIndex].name} ${
        config.config.series[seriesIndex].name === '1'
          ? i18next.t('graph.stackedColumn.person')
          : i18next.t('graph.stackedColumn.people')
      }</p><p><span class="font-bold">${Math.round(series[seriesIndex][dataPointIndex])}%</span>
      ${i18next.t('graph.stackedColumn.time')} (${
        toHoursAndMinutes(options.minutes[seriesIndex].data[dataPointIndex]).hours
      }h${toHoursAndMinutes(options.minutes[seriesIndex].data[dataPointIndex]).minutes}min)</p></div>`
    },
    x: {
      show: false,
    },
    y: {
      formatter: (value: number) => `${value}%`,
    },
  },
  title: {
    text: options.title,
    align: 'left',
    offsetY: 10,
  },
  subtitle: {
    text: options.subtitle,
  },
  legend: {
    show: true,
    position: 'top',
    horizontalAlign: 'right',
    offsetY: -45,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      radius: 12,
    },
  },
  colors: [
    COLORS.blue,
    COLORS.blue70,
    COLORS.blue40,
    COLORS.blue25,
    COLORS.deepCerise30,
    COLORS.deepCerise50,
    COLORS.deepCerise80,
    COLORS.deepCerise,
    COLORS.lightGrey,
    COLORS.darkGrey30,
    COLORS.darkGrey50,
    COLORS.darkGrey80,
    COLORS.darkGrey,
  ],
  grid: {
    position: 'back',
  },
  xaxis: {
    type: 'category',
    categories: options.categories,
    title: {
      text: options.xaxisTitle,
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: ['#9C9CB5'],
      },
      formatter: (value: string): string => {
        return `${value}%`
      },
    },
    title: {
      text: options.yaxisTitle,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
})

export const fakeStackedColumnChartData: ColumnChartDataWSType = {
  capacityType: 'DETAILED',
  totalRoomsCount: 872,
  result: [
    {
      capacityGroup: '6',
      roomsCount: '6',
      occupancies: [
        {
          peopleCount: 0,
          rate: 0,
        },
        {
          peopleCount: 1,
          rate: 36,
        },
        {
          peopleCount: 2,
          rate: 32,
        },
        {
          peopleCount: 3,
          rate: 9,
        },
        {
          peopleCount: 4,
          rate: 18,
        },
        {
          peopleCount: 5,
          rate: 5,
        },
        {
          peopleCount: 6,
          rate: 0,
        },
        {
          peopleCount: 7,
          rate: 0,
        },
        {
          peopleCount: 8,
          rate: 0,
        },
        {
          peopleCount: 9,
          rate: 0,
        },
        {
          peopleCount: 10,
          rate: 0,
        },
        {
          peopleCount: 11,
          rate: 0,
        },
      ],
    },
    {
      capacityGroup: '11',
      roomsCount: '6',
      occupancies: [
        {
          peopleCount: 0,
          rate: 0,
        },
        {
          peopleCount: 1,
          rate: 18,
        },
        {
          peopleCount: 2,
          rate: 32,
        },
        {
          peopleCount: 3,
          rate: 36,
        },
        {
          peopleCount: 4,
          rate: 9,
        },
        {
          peopleCount: 5,
          rate: 5,
        },
        {
          peopleCount: 6,
          rate: 0,
        },
        {
          peopleCount: 7,
          rate: 0,
        },
        {
          peopleCount: 8,
          rate: 0,
        },
        {
          peopleCount: 9,
          rate: 0,
        },
        {
          peopleCount: 10,
          rate: 0,
        },
        {
          peopleCount: 11,
          rate: 0,
        },
      ],
    },
    {
      capacityGroup: '16',
      roomsCount: '0',
      occupancies: [
        {
          peopleCount: 0,
          rate: 0,
        },
        {
          peopleCount: 1,
          rate: 29,
        },
        {
          peopleCount: 2,
          rate: 10,
        },
        {
          peopleCount: 3,
          rate: 5,
        },
        {
          peopleCount: 4,
          rate: 10,
        },
        {
          peopleCount: 5,
          rate: 19,
        },
        {
          peopleCount: 6,
          rate: 9,
        },
        {
          peopleCount: 7,
          rate: 9,
        },
        {
          peopleCount: 8,
          rate: 9,
        },
        {
          peopleCount: 9,
          rate: 0,
        },
        {
          peopleCount: 10,
          rate: 0,
        },
        {
          peopleCount: 11,
          rate: 0,
        },
      ],
    },
  ],
}

import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'
type UpdateSiteBody = {
  population: number
  totalArea: string
  areaPrice: number
  areaCost: number
  desks: {
    count: number
    area: string
  }
  focusRooms: {
    count: number
    area: string
  }
  meetingRooms: {
    count: number
    area: string
  }
  seatsCount: number
}

const getSiteByPath = (clientCode: string, domainPath: string): Promise<any> => {
  return execute(generatePath(API.SITES.GET_ONE, { clientCode, domainPath: domainPath }), 'GET')
}

const getSitePictureByPath = (clientCode: string, domainPath: string): Promise<any> => {
  return execute(generatePath(API.SITES.GET_PICTURE, { clientCode, domainPath: domainPath }), 'GET')
}
const updateSiteInfos = (clientCode: string, domainPath: string, body: UpdateSiteBody): Promise<any> => {
  return execute(generatePath(API.SITES.EDIT, { clientCode, domainPath: domainPath }), 'PUT', body)
}
const editImage = (clientCode: string, domainPath: string, body: any): Promise<any> => {
  return execute(generatePath(API.SITES.EDIT_PICTURE, { clientCode, domainPath: domainPath }), 'PUT', body)
}

export const sitesService = {
  getOne: getSiteByPath,
  getPicture: getSitePictureByPath,
  editSite: updateSiteInfos,
  editImage: editImage,
}

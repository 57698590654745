import { FC, useEffect, useState } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'assets/colors/colors'

import useFetchStructure from 'utils/hooks/useFetchStructure'

const ComfortView: FC = (props) => {
  const { t } = useTranslation()
  const [hasNuvap, setHasNuvap] = useState<boolean | undefined>(false)
  const [hasAirthings, setHasAirthings] = useState<boolean | undefined>(false)
  const { structure } = useFetchStructure()
  useEffect(() => {
    setHasNuvap(structure?.domains[0].features.nuvap)
    setHasAirthings(structure?.domains[0].features.airthings)
  }, [structure])

  return (
    <PageLayout>
      <div className="flex w-full justify-center pt-10">
        <div className="w-2/5">
          <div
            className="comfort"
            style={{
              backgroundImage: `url(${'/images/people.jpg'})`,
              backgroundSize: 'cover',
              width: '100%',
              height: '250px',
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <img
              src="/images/graph.png"
              alt="graph"
              style={{ width: '90%', position: 'absolute', top: '18%', right: '30%' }}
            />
          </div>
          {hasNuvap || hasAirthings ? (
            <>
              {hasNuvap && (
                <div className="text-center mt-10">
                  <p style={{ color: COLORS.deepCerise, fontWeight: 'bold', paddingBottom: '10px', fontSize: '18px' }}>
                    {t('comfort.title')}
                  </p>
                  <p className="pb-6">{t('comfort.platform')}</p>
                  <a
                    style={{
                      color: 'white',
                      backgroundColor: COLORS.darkGrey,
                      padding: '15px',
                      margin: '20px',
                      borderRadius: '8px',
                    }}
                    href="https://my.nuvap.com/auth/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('comfort.log')}
                  </a>
                </div>
              )}

              {hasAirthings && (
                <div className="text-center mt-10">
                  <p style={{ color: COLORS.deepCerise, fontWeight: 'bold', paddingBottom: '10px', fontSize: '18px' }}>
                    {t('comfort.title')}
                  </p>
                  <p className="pb-6">{t('comfort.platform')}</p>
                  <a
                    style={{
                      color: 'white',
                      backgroundColor: COLORS.darkGrey,
                      padding: '15px',
                      margin: '20px',
                      borderRadius: '8px',
                    }}
                    href="https://dashboard.airthings.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('comfort.log')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <div className="text-center mt-10">
              <p style={{ color: COLORS.deepCerise, fontWeight: 'bold', paddingBottom: '10px', fontSize: '18px' }}>
                {t('comfort.title')}
              </p>
              <p className="pb-4">
                {t('comfort.description')}{' '}
                <a style={{ textDecoration: 'underline' }} href="mailto:sales@jooxter.com">
                  {t('comfort.contact')}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  )
}

export default ComfortView

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType } from 'utils/filtersUtils'

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.MONITORING_ENTITY.value === value) return state
  const formattedScope: any = []
  const isMonitoringTable = state.GRAPH.value === 'MONITORING'
  if (!isMonitoringTable) {
    state.MONITORING_SCOPE.value.map((elt: string) => {
      if (elt.indexOf('_AND_BUSINESS_UNIT') > -1) {
        formattedScope.push(elt.replace('_AND_BUSINESS_UNIT', ''))
      } else {
        formattedScope.push(elt)
      }
    })
  }

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    KPI: {
      ...state.KPI,
      active: !!value,
    },
    QUARTER: state.QUARTER,
    SPACE_TYPE: state.SPACE_TYPE,
    MONITORING_ROOM_TYPE: state.MONITORING_ROOM_TYPE,
    MONITORING_SCOPE:
      value === 'false'
        ? {
            ...state.MONITORING_SCOPE,
            value: isMonitoringTable ? state.MONITORING_SCOPE.value : formattedScope,
          }
        : {
            ...state.MONITORING_SCOPE,
            value: isMonitoringTable
              ? state.MONITORING_SCOPE.value
              : state.MONITORING_SCOPE.value.map((elt: string) => elt + '_AND_BUSINESS_UNIT'),
          },
    MONITORING_ENTITY: {
      ...state.MONITORING_ENTITY,
      value,
      active: !value,
    },
    CUSTOM_ATTRIBUT_WKS: {
      ...initialFilterState.CUSTOM_ATTRIBUT_WKS,
      active: true,
    },
    CUSTOM_ATTRIBUT_COLLAB: state.CUSTOM_ATTRIBUT_COLLAB,
  }
}

const MonitoringBusniessUnitsFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const { placeholder, ...structure2 } = graphsStructure.monitoringBusinessUnits
  const workstationNature = [
    'ALL_WORKSTATIONS',
    'OPEN_SPACE',
    'INDIVIDUAL_OFFICE',
    'WORKSTATION',
    'SHARED_OFFICE',
    'COMMON_SPACE',
  ]

  const values = Object.entries(structure2).map(([value, label]) => ({ label, value }))
  const monitoringBusinessUnitsValue = state.MONITORING_ENTITY.value

  const options = values.map((value) => ({
    ...value,
    label: t(value.label),
  }))
  const busniessUnitsData = options.find((value) => value.value === monitoringBusinessUnitsValue)
  const type = getFilterType(state.GRAPH.value, state.MONITORING_ENTITY.active, busniessUnitsData)

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))
  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={busniessUnitsData?.label}
      disabled={
        state.GRAPH.value === 'CALIBRATED_UTILIZATION'
          ? false
          : (!state.MONITORING_ENTITY.active && !state.MONITORING_ENTITY.value) ||
            (state.MONITORING_ROOM_TYPE.value &&
              !workstationNature.some((i) => state.MONITORING_ROOM_TYPE.value.includes(i)))
      }
      selected={monitoringBusinessUnitsValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default MonitoringBusniessUnitsFilter

import { FC, useEffect, useState } from 'react'
import { TopFlopDataType, TopFlopDataWSType } from 'types/GraphDataType'
import HorizontalBarChart from './HorizontalBarChart'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'

type Props = {
  loading: boolean
  data: TopFlopDataWSType
  title: string
  id?: string
  colors: string[]
}

const HorizontalBarChartController: FC<Props> = (props) => {
  const { loading, data, title, id, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<TopFlopDataType>()

  useEffect(() => {
    if (loading) {
      return
    }

    const truncatedIndex = !data.truncatedResult
      ? undefined
      : data.result.length % 2 === 0
      ? data.result.length / 2 - 1
      : Math.floor(data.result.length / 2)

    // We convert the data sent from the server into the data taken from the library
    const {
      data: series,
      categories,
      minutes,
    } = data.result.reduce(
      (acc: Omit<TopFlopDataType, 'truncated'>, curr, index) => {
        acc.data.push(Math.round(curr.occupancyRate))
        acc.minutes.push(curr.occupancyDurationMinutes)

        // ApexCharts can take string arrays instead of strings for each name : this will cause the labels to "line break"
        // if too long, and that's what we want because they often are too long.
        const splittedName =
          curr.name.length > 20 ? [curr.name.substring(0, 16), curr.name.substring(16, curr.name.length)] : [curr.name]
        acc.categories.push([...splittedName, t('graph.topFlop.xAxisLabels', { capacity: curr.capacity })])

        // We add the false value in the graph for the truncated row
        if (index === truncatedIndex) {
          acc.data.push((curr.occupancyRate + data.result[index + 1].occupancyRate) / 2)
          acc.categories.push(' ')
          acc.minutes.push(0)
        }

        return acc
      },
      {
        data: [],
        categories: [],
        minutes: [],
      },
    )

    setFormattedData({
      data: series,
      minutes,
      categories,
    })
  }, [loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="bar" horizontal />
  }
  return (
    <HorizontalBarChart
      id={id}
      title={title}
      subtitle={t('graph.common.roomsSubtitle', { roomsCount: data.totalRoomsCount })}
      data={formattedData.data}
      minutes={formattedData.minutes}
      categories={formattedData.categories}
      truncated={data.truncatedResult}
      colors={colors}
    />
  )
}

export default HorizontalBarChartController

import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormInput from 'components/atoms/input/FormInput'
import { useTranslation } from 'react-i18next'
import Button from 'components/atoms/button/Button'
import { NavLink } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import { useMutation } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { authService } from 'services/authService'
import { ResetPasswordData } from 'types/Auth'
import { toast } from 'react-toastify'
import ResetPasswordConfirmation from './ResetPasswordConfirmation'
import LoginPageLayout from 'components/layouts/LoginPageLayout'
import { COLORS } from 'assets/colors/colors'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('forms.errors.required'),
  passwordRepeater: Yup.string()
    .required('forms.errors.required')
    .oneOf([Yup.ref('password'), null], 'forms.errors.passwordConfirmation'),
})

const ResetPassword: FC = () => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const { mutate, isSuccess } = useMutation({
    mutationKey: QUERY_KEYS.AUTH.RESET_PASSWORD,
    mutationFn: authService.resetPassword,
    onError: () => {
      // TODO : Voir l'erreur du serveur
      toast.error(t('api.unknown'))
    },
  })

  if (isSuccess) {
    return <ResetPasswordConfirmation />
  }
  const onSubmit = (data: ResetPasswordData) => {
    data.token = token || ''
    mutate(data)
  }

  return (
    <LoginPageLayout>
      <FormProvider {...form}>
        <form className="login-form" onSubmit={form.handleSubmit(onSubmit)}>
          <h4 style={{ fontWeight: 'bold', fontSize: '34px', textAlign: 'center', marginBottom: '25px' }}>
            {t('auth.login.login')}
          </h4>
          <label style={{ textTransform: 'uppercase', fontSize: '12px', margin: '0 0.75rem' }}>
            {t('forms.fields.password')}
          </label>
          <FormInput name="password" type="password" placeholder={t('forms.fields.password')} className="my-2" />
          <label style={{ textTransform: 'uppercase', fontSize: '12px', margin: '0 0.75rem' }}>
            {t('forms.fields.confirmPassword')}
          </label>
          <FormInput
            name="passwordRepeater"
            type="password"
            placeholder={t('forms.fields.confirmPassword')}
            className="my-2"
          />
          <div className="flex justify-center flex-col m-3">
            <Button type="submit" style={{ backgroundColor: COLORS.deepCerise }}>
              {t('common.confirm')}
            </Button>
            <NavLink to={PATHS.HOME} className="text-basic-100 text-c1 text-center">
              {t('common.cancel')}
            </NavLink>
          </div>
        </form>
      </FormProvider>
    </LoginPageLayout>
  )
}

export default ResetPassword

/**
 * Localization API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@csmres.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocalizationInformationDto { 
    /**
     * Localization code
     */
    code?: string;
    /**
     * Localization name
     */
    name?: string;
    /**
     * Localization path
     */
    path?: string;
    /**
     * Localization type
     */
    type?: LocalizationInformationDtoTypeEnum;
    attributes?: { [key: string]: string; };
    /**
     * Hash visioglobe MoveEssential
     */
    hash_visioglobe_moveessential?: string;
    /**
     * Hash visioglobe Web
     */
    hash_visioglobe_web?: string;
    /**
     * Room classification
     */
    room_classification?: LocalizationInformationDtoRoomClassificationEnum;
    /**
     * Realtime display
     */
    realtime_display?: boolean;
    /**
     * Sub localizations
     */
    sub_localizations?: Array<LocalizationInformationDto>;
}
export enum LocalizationInformationDtoTypeEnum {
    Continent = 'CONTINENT',
    Country = 'COUNTRY',
    State = 'STATE',
    City = 'CITY',
    Domain = 'DOMAIN',
    Building = 'BUILDING',
    Zone = 'ZONE',
    Level = 'LEVEL',
    Compartment = 'COMPARTMENT',
    Room = 'ROOM',
    Group = 'GROUP',
    Bench = 'BENCH',
    Desk = 'DESK'
};
export enum LocalizationInformationDtoRoomClassificationEnum {
    FocusRoom = 'FOCUS_ROOM',
    MeetingRoom = 'MEETING_ROOM',
    Workstation = 'WORKSTATION',
    Restaurant = 'RESTAURANT',
    Unknown = 'UNKNOWN'
};




import { execute } from 'utils/api/api'
import { API, serverPrefixes } from 'utils/constants/routes/ApiRoutes'
import {
  ForgotPasswordData,
  ResetPasswordData,
  TokenResponse,
  UserData,
  ChangePasswordData,
  sendCodeData,
  UserEmail,
} from 'types/Auth'
import { User } from 'types/User'
import { cookies } from 'core/cookies'

export const getNoSSORedirectUri = (data: UserEmail): string =>
  `${serverPrefixes.noSsoRedirectURI}?identifier=${encodeURIComponent(data.username)}`

const getToken = async (data: UserData): Promise<TokenResponse> => {
  const fa2Token = cookies.get('fa2_token')
  const formData = new FormData()
  formData.append('scope', 'openid')
  formData.append('grant_type', data.fa2_code ? 'fa2' : 'password')
  formData.append('client_id', 'clientNoSecret')
  formData.append('username', data.username)
  formData.append('password', data.password)
  fa2Token ? formData.append('fa2_token', fa2Token) : null
  data.fa2_code ? formData.append('fa2_code', data.fa2_code.replace(/\s/g, '')) : null

  return execute(API.AUTH.TOKEN, 'POST', formData)
}

const getPreLogin = async (data: UserEmail): Promise<{ redirectionUrl: string }> => {
  return execute(API.AUTH.PRE_LOGIN, 'POST', {
    identifier: data.username,
    no_sso_redirect_uri: getNoSSORedirectUri(data),
    // for development, use staging as a redirect URI as Okta won't accept localhost
    oidc_redirect_uri: window.location.host.includes('localhost')
      ? 'https://analytics.sta.jooxter.io/oidc'
      : `https://${window.location.host}/oidc`,
  })
}

const getAccessTokenFromSSO = async (data: { code: string; state: string }): Promise<TokenResponse> => {
  return execute(
    `${API.AUTH.LOGIN_SSO}?code=${data.code}&state=${data.state}&oidc_redirect_uri=${encodeURIComponent(
      'https://' + window.location.host + '/oidc',
    )}`,
    'GET',
  )
}

const getAccessToken = (refreshToken?: string): Promise<TokenResponse> => {
  const formData = new FormData()
  formData.append('grant_type', 'refresh_token')
  formData.append('client_id', 'clientNoSecret')
  refreshToken && formData.append('refresh_token', refreshToken)

  return execute(API.AUTH.REFRESH_TOKEN, 'POST', formData)
}

const forgotPassword = (data: ForgotPasswordData): Promise<void> => {
  return execute(API.AUTH.FORGOT_PASSWORD, 'POST', data)
}

const resetPassword = (data: ResetPasswordData): Promise<void> => {
  return execute(API.AUTH.RESET_PASSWORD, 'POST', data)
}
const changePassword = (data: ChangePasswordData): Promise<void> => {
  return execute(API.AUTH.CHANGE_PASSWORD, 'POST', data)
}
const sendCode = (data: sendCodeData): Promise<void> => {
  return execute(API.AUTH.SEND_CODE, 'POST', data)
}

const getCurrentUser = (): Promise<User> => {
  return execute(API.AUTH.USER_CURRENT, 'GET')
}

type CurrentClientType = {
  currentClientCode: string
  favoriteLocalization: {
    buildingCode?: string
    domainPath?: string
  }
}

const patchCurrentClient = (data: Partial<CurrentClientType>): Promise<unknown> => {
  return execute(API.AUTH.USER_CURRENT, 'PATCH', data)
}

export const authService = {
  getToken,
  getPreLogin,
  getAccessTokenFromSSO,
  getAccessToken,
  forgotPassword,
  resetPassword,
  getCurrentUser,
  patchCurrentClient,
  changePassword,
  sendCode,
}

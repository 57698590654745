import { FC } from 'react'
import ReactApexChart from '../../atoms/graph/ReactApexChart'
import { getLoadingChartOptions, loadingBarChartData } from 'utils/constants/graphs/loadingBarChart'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { loadingHeatmapData, loadingHeatmapOptions } from 'utils/constants/graphs/heatmap'

type Props = {
  type: ChartProps<never>['type']
  horizontal?: boolean
  stacked?: boolean
}

const LoadingChart: FC<Props> = (props) => {
  const { type, horizontal = false, stacked = false } = props

  const data = type === 'heatmap' ? loadingHeatmapData : [{ data: loadingBarChartData }]

  const options = type === 'heatmap' ? loadingHeatmapOptions : getLoadingChartOptions({ type, horizontal, stacked })

  return (
    <div className="relative max-w-full max-h-full overflow-x-hidden overflow-y-auto">
      <ReactApexChart type={type} series={data} options={options} noContextMenu />
    </div>
  )
}

export default LoadingChart

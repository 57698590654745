import { FC, useEffect, useState } from 'react'
import { MonthlyDataType, MonthlyDataWSType } from 'types/GraphDataType'
import LoadingChart from '../LoadingChart'
import MonthlyChart from './MonthlyChart'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: string
  title: string
  loading: boolean
  data: MonthlyDataWSType
  isWorkStation: boolean
  colors: string[]
}

const MonthlyController: FC<Props> = (props) => {
  const { title, loading, data, isWorkStation, id, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<MonthlyDataType>()
  let graphSubtitle

  useEffect(() => {
    if (loading) {
      return
    }

    const avg: number[] = []
    const ninthDecile: number[] = []
    const max: number[] = []
    const categories: string[] = []
    const avgTooltipValue: string[] = []
    const ninthDecileTooltipValue: string[] = []
    const maxTooltipValue: string[] = []
    data.result.forEach((el, index) => {
      avg.push(el.real.average)
      ninthDecile.push(el.real.ninthDecile - el.real.average)
      max.push(el.real.max - el.real.ninthDecile)
      avgTooltipValue.push(el.real.average.toString())
      ninthDecileTooltipValue.push(el.real.ninthDecile.toString())
      maxTooltipValue.push(el.real.max.toString())
      categories.push(
        isWorkStation
          ? t(`graph.monthly.month.${el.month.substring(5, 7)}`) +
              ' ' +
              el.month.substring(0, 4) +
              ' (' +
              t('graph.monthly.real') +
              ')'
          : t(`graph.monthly.month.${el.month.substring(5, 7)}`) + ' ' + el.month.substring(0, 4),
      )

      // If WORKSTATION, the graph is a bit different (the columns are grouped by 2, and there is an empty space
      // between each). For that, we inject 0 values.
      if (isWorkStation && el.perceived) {
        avg.push(el.perceived.average)
        ninthDecile.push(el.perceived.ninthDecile - el.perceived.average)
        max.push(el.perceived.max - el.perceived.ninthDecile)
        avgTooltipValue.push(el.perceived.average.toString())
        ninthDecileTooltipValue.push(el.perceived.ninthDecile.toString())
        maxTooltipValue.push(el.perceived.max.toString())
        categories.push(t('graph.monthly.perceived'))

        // We check because we don't want an empty space after the last group of columns
        if (index !== data.result.length - 1) {
          avg.push(0)
          ninthDecile.push(0)
          max.push(0)
          avgTooltipValue.push('')
          ninthDecileTooltipValue.push('')
          maxTooltipValue.push('')
          categories.push(' ')
        }
      }
    })

    const series = [
      { name: avgTooltipValue.toString(), data: avg, description: t('graph.common.average') },
      { name: ninthDecileTooltipValue.toString(), data: ninthDecile, description: t('graph.monthly.decile') },
      { name: maxTooltipValue.toString(), data: max, description: t('graph.common.max') },
    ]
    setFormattedData({ series, categories })
  }, [isWorkStation, loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="bar" />
  }

  isWorkStation
    ? (graphSubtitle = t('graph.common.workstationSubtitle', { roomsCount: data.totalRoomsCount }))
    : (graphSubtitle = t('graph.common.roomsSubtitle', { roomsCount: data.totalRoomsCount }))

  return (
    <MonthlyChart
      id={id}
      title={title}
      subtitle={graphSubtitle}
      series={formattedData.series}
      categories={formattedData.categories}
      colors={colors}
    />
  )
}

export default MonthlyController

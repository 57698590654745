import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useQueryParams = (): { [key: string]: any } => {
  const { search } = useLocation()

  const result = useMemo(() => {
    const iterator = new URLSearchParams(search).entries()
    const entries = []

    let iteratorYield = iterator.next()
    while (!iteratorYield.done) {
      const [key, value] = iteratorYield.value
      entries.push([key, value])
      iteratorYield = iterator.next()
    }

    return Object.fromEntries(entries)
  }, [search])

  return result
}

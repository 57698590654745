import { FC } from 'react'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { initialFilterState } from '../../molecules/filters/required/GraphFiltersContext'
import DetailedOccupancyController from '../charts/detailedOccupancy/DetailedOccupancyController'
import MonthlyController from '../../molecules/charts/monthly/MonthlyController'
import HorizontalBarChartController from '../../molecules/charts/topFlop/HorizontalBarChartController'
import UtilizationOfSpaceController from '../../molecules/charts/utilizationOfSpace/UtilizationOfSpaceController'
import RestaurantController from '../../molecules/charts/RIE/RestaurantController'
import FrequentationController from '../../molecules/charts/RIE/FrequentationController'
import SpaceAttendanceController from '../../molecules/charts/spaceAttendance/SpaceAttendanceController'
import { GraphType } from '../../molecules/filters/required/GraphFilter'

import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  WORKSTATION,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  heatmapValue,
  roomBookingValue,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'

import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { savedReportsService } from 'services/savedReportsService'
import { graphService } from 'services/graphService'

import {
  ColumnChartDataWSType,
  DetailedOccupancyDataWSType,
  MonthlyDataWSType,
  TopFlopDataWSType,
  RestaurantDataWSType,
  FrequentationDataWSType,
  RoomBookingDataWSType,
  BenchmarkDataWSType,
} from 'types/GraphDataType'
import IconSvg from '../../atoms/icons/IconSvg'
import HeatmapController from '../charts/heatmap/HeatmapController'
import RoomBookingController from '../charts/roomBooking/roomBookingController'
import UtilizationQualityController from '../charts/utilizationQuality/utilizationQualityController'
import BenchmarkController from '../charts/benchmark/BenchmarkController'

type Props = {
  reportID: string
  editMode: boolean
  isAutomaticSending?: boolean
  superAdminClientCode?: string
  onTrashClick: (reportID: string) => void
}

const DashboardReportView: FC<Props> = (props) => {
  const { t } = useTranslation()

  const { reportID, editMode, onTrashClick, isAutomaticSending = false, superAdminClientCode } = props

  const { id } = useParams<{ id: string }>()
  const { clientCode = '' } = useCurrentUser()

  const { data: savedReportData } = useQuery({
    queryKey: QUERY_KEYS.SAVED_REPORTS.GET_ONE(clientCode, reportID),
    queryFn: () => savedReportsService.getOne(clientCode, reportID),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })

  const graphType = savedReportData?.filters.graph as GraphType

  const { data: graphData, isSuccess } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.GRAPH(savedReportData?.filters || initialFilterState),
    queryFn: () => {
      if (savedReportData) {
        return graphService.getGraphDataFromSavedReport(
          //@ts-ignore
          isAutomaticSending ? superAdminClientCode : clientCode,
          savedReportData.filters,
        )
      }
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!id || !!clientCode,
  })
  return (
    <div key={reportID} className="m-3 overflow-auto">
      {editMode && (
        <div>
          <IconSvg name="TRASH_CAN" className="m-3" onClick={() => onTrashClick(reportID)} />
        </div>
      )}
      {savedReportData && graphType === topFlopValue && (
        <div>
          <HorizontalBarChartController
            loading={!isSuccess}
            data={graphData as TopFlopDataWSType}
            title={savedReportData.name}
            id={savedReportData.id.toString()}
          />
        </div>
      )}
      {savedReportData && graphType === stackedColumnValue && (
        <div>
          <UtilizationOfSpaceController
            loading={!isSuccess}
            data={graphData as ColumnChartDataWSType}
            title={savedReportData.name}
            id={savedReportData.id.toString()}
          />
        </div>
      )}
      {savedReportData && graphType === detailedOccupancyValue && (
        <div>
          <DetailedOccupancyController
            loading={!isSuccess}
            data={graphData as DetailedOccupancyDataWSType}
            title={savedReportData.name}
            savedReportFilters={savedReportData?.filters}
            id={savedReportData.id.toString()}
          />
        </div>
      )}
      {savedReportData && graphType === monthlyValue && (
        <div>
          <MonthlyController
            id={savedReportData.id.toString()}
            isWorkStation={savedReportData?.filters.roomType === WORKSTATION.value}
            title={savedReportData.name}
            loading={!isSuccess}
            data={graphData as MonthlyDataWSType}
          />
        </div>
      )}
      {savedReportData && graphType === RIE && (
        <div>
          <RestaurantController
            id={savedReportData.id.toString()}
            title={savedReportData.name}
            loading={!isSuccess}
            data={graphData as RestaurantDataWSType}
          />
        </div>
      )}
      {savedReportData && graphType === frequentationValue && (
        <div>
          <FrequentationController
            id={savedReportData.id.toString()}
            title={savedReportData.name}
            loading={!isSuccess}
            data={graphData as FrequentationDataWSType}
          />
        </div>
      )}
      {savedReportData && graphType === spaceAttendanceValue && (
        <div>
          <SpaceAttendanceController
            id={savedReportData.id.toString()}
            title={savedReportData.name}
            loading={!isSuccess}
            data={graphData as FrequentationDataWSType}
          />
        </div>
      )}
      {savedReportData && graphType === utilizationQualityValue && (
        <div>
          <UtilizationQualityController
            loading={!isSuccess}
            data={graphData as TopFlopDataWSType}
            title={savedReportData.name}
            id={savedReportData.id.toString()}
          />
        </div>
      )}
      {savedReportData && graphType === heatmapValue && (
        <div>
          <HeatmapController
            loading={!isSuccess}
            data={graphData as TopFlopDataWSType}
            title={savedReportData.name}
            selectedFilters={savedReportData.filters}
          />
        </div>
      )}
      {savedReportData && graphType === roomBookingValue && (
        <div>
          <RoomBookingController
            loading={!isSuccess}
            data={graphData as RoomBookingDataWSType}
            title={savedReportData.name}
            selectedFilters={savedReportData.filters}
          />
        </div>
      )}
      {savedReportData && graphType === benchmarkValue && (
        <div>
          <BenchmarkController
            id={savedReportData.id.toString()}
            loading={!isSuccess}
            data={graphData as BenchmarkDataWSType}
            title={savedReportData.name}
            selectedFilters={savedReportData.filters}
          />
        </div>
      )}
    </div>
  )
}

export default DashboardReportView

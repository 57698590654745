import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { savedReportsService } from 'services/savedReportsService'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { SavedReportList } from 'types/SavedReportsTypes'
import SearchReport from '../dialogs/searchReport/searchReport'
import ConfirmationToAddDialog from '../dialogs/confirmationToAdd/confirmationToAddDialog'
import { PagesType } from 'types/DashboardType'

type Props = {
  confirmation?: boolean
  reportID?: number
  onClose: any
  editedPage?: PagesType
  editedPosition: number
  dashboardName: string
}

const AddReportActions: FC<Props> = (props) => {
  const { confirmation, reportID: reportID, onClose, editedPage, editedPosition, dashboardName } = props
  const { t } = useTranslation()
  const [reportId, setReportId] = useState(reportID ? reportID : 0)
  const { clientCode = '' } = useCurrentUser()
  const { data } = useQuery<SavedReportList>({
    queryKey: QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode),
    queryFn: () => savedReportsService.getAll(clientCode),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })
  let selectedFavorite: any
  const onItemClick = (newSelectedSavedReportId: number) => {
    selectedFavorite = data?.result.find((savedReport) => savedReport.id == newSelectedSavedReportId)
    setTimeout(() => {
      setReportId(selectedFavorite)
    }, 500)
    setSearchReportModal(false)
    setTimeout(() => {
      setConfirmAddModal(true), 500
    })
  }

  const [ConfirmAddModal, setConfirmAddModal] = useState(confirmation ? true : false)
  const [SearchReportModal, setSearchReportModal] = useState(confirmation ? false : true)

  const AddToDashboardConfirmation = () => {
    setSearchReportModal(false)
    setTimeout(() => {
      setConfirmAddModal(true), 500
    })
  }
  return (
    <div className="flex justify-end space-x-4 mt-4">
      {SearchReportModal && !ConfirmAddModal && (
        <SearchReport
          open={SearchReportModal}
          onClose={onClose}
          onClick={AddToDashboardConfirmation}
          onItemClick={onItemClick}
        />
      )}
      {ConfirmAddModal && (
        <ConfirmationToAddDialog
          open={ConfirmAddModal}
          onClose={onClose}
          reportID={reportId}
          editedPage={editedPage}
          editedPosition={editedPosition}
          dashboardName={dashboardName}
        />
      )}
    </div>
  )
}

export default AddReportActions

import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import ApexCharts from 'apexcharts'
import { Page, Text, View, Document, StyleSheet, Image, pdf, Font } from '@react-pdf/renderer'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { DashboardType, ReportsType } from 'types/DashboardType'
import DashboardReportView from 'components/molecules/dashboardList/dashboardReportView'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { savedReportsService } from 'services/savedReportsService'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { optionAllValue } from 'utils/constants/graphs/global'
import { graphService } from 'services/graphService'
import Roboto from 'assets/fonts/Roboto-Bold.ttf'
import RobotoMono from 'assets/fonts/RobotoMono-Regular.ttf'
import RobotoMonoItalic from 'assets/fonts/RobotoMono-MediumItalic.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto }, // font-style: normal, font-weight: normal
  ],
})
Font.register({
  family: 'RobotoItalic',
  src: RobotoMonoItalic,
  fontStyle: 'italic',
  fontWeight: 'normal',
})
Font.register({
  family: 'RobotoMono',
  fonts: [{ src: RobotoMono }],
})
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    width: '720px',
    backgroundColor: 'transparent',
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px',
    marginBottom: '10px',
    lineHeight: '1.55',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'extrabold',
    fontFamily: 'Roboto',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '22px',
    marginBottom: '25px',
    color: '#D33087',
    fontWeight: 800,
  },
  value: {
    fontSize: '12px',
    fontWeight: 'light',
  },
  section: {
    marginTop: '30px',
    marginRight: 5,
    padding: 2,
    flexGrow: 1,
  },
  image: {
    width: '489px',
  },
  logo: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: '85px !important',
  },
  simpleLogo: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: '55px',
  },
  view: {
    width: '100%',
    height: '100%',
    padding: 0,
    backgroundColor: '#2d2d3b',
    color: 'white',
  },
  background: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    opacity: 0.7,
  },
  motto: {
    position: 'absolute',
    bottom: '24px',
    left: '18%',
    fontSize: '14px',
    fontFamiliy: 'RobotoItalic',
  },
  information: {
    margin: '15px 42px',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  informationDescription: {
    fontSize: '14px',
    display: 'flex',
    marginTop: '22px',
    paddingRight: '25px',
  },
  informationSection: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    borderBottom: '1px dotted #fff',
    top: '-23px',
    width: 'auto',
    overflow: 'hidden',
    flex: '1',
    marginRight: '30px',
    marginLeft: '25px',
  },
})
const DashboardView: FC = (props) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const queryParams = new URLSearchParams(window.location.search)
  const recipient = queryParams.get('recipient')
  const { clientCode = '' } = useCurrentUser()
  const [namesReport, setNamesReport] = useState<any[]>([])
  const [dashboardGraphTypes, setDashboardGraphTypes] = useState<any>([])

  const { data: selectedDashboardData } = useQuery<DashboardType>({
    queryKey: QUERY_KEYS.DASHBOARDS.GET_DASHBOARD(clientCode, parseInt(id)),
    queryFn: () => savedReportsService.getDashboard(clientCode, id),

    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })
  const { data: structure } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.FILTER(selectedDashboardData?.clientCode as string),
    queryFn: () => graphService.getFiltersData(selectedDashboardData?.clientCode as string),
    onError: () => toast.error(t('api.unknown')),
    enabled: !!selectedDashboardData?.clientCode,
  })
  const [pages, setPages] = useState(selectedDashboardData?.pages)
  const [reports, setReports] = useState<ReportsType[][]>()
  const [dashboardFilters, setDashboardFilters] = useState<any[]>([])
  const [imagesReport, setImagesReport] = useState<any[]>([])
  const [frontPageNumber, setFrontPageNumber] = useState(1)

  useEffect(() => {
    if (selectedDashboardData) {
      setPages(selectedDashboardData.pages)
      const reportsByDashboard = selectedDashboardData.pages?.map((page) => page.reports)
      setReports(
        reportsByDashboard.reduce((acc, cur) => {
          const newReports = cur.sort((a, b) => a.position - b.position)

          return [...acc, newReports]
        }, [] as ReportsType[][]),
      )
    }
  }, [selectedDashboardData])
  const filterDashboard = dashboardFilters.length == reports?.reduce((acc, element) => acc + element.length, 0)
  useEffect(() => {
    if (
      new Date().getDate() == 1 &&
      dashboardFilters.length == reports?.reduce((acc, element) => acc + element.length, 0)
    ) {
      const dashboardFiltersWithoutCurrentMonth = dashboardFilters.filter((objet) => {
        return !objet.filters.some((filtre) => filtre.value === t('graph.filters.quarter.currentMonth'))
      })
      setDashboardFilters(
        dashboardFilters.filter((objet) => {
          return !objet.filters.some((filtre) => filtre.value === t('graph.filters.quarter.currentMonth'))
        }),
      )
      const commonReports = []
      const commonNamesReports = []

      reports[0].forEach((report) => {
        const filtered = dashboardFiltersWithoutCurrentMonth.find((objet) => objet.report === report.id)
        if (filtered) {
          commonReports.push(report)
        }
      })
      if (dashboardFiltersWithoutCurrentMonth.length !== reports?.reduce((acc, element) => acc + element.length, 0)) {
        setReports([commonReports])
      }
      namesReport.forEach((name) => {
        const filteredDashboard = dashboardFiltersWithoutCurrentMonth.find((objet) => objet.report === name.report)
        if (filteredDashboard) {
          commonNamesReports.push(name)
        }
      })

      setNamesReport(commonNamesReports)
    }
  }, [filterDashboard])
  useEffect(() => {
    if (reports && reports.length > 0 && selectedDashboardData?.clientCode && structure) {
      setTimeout(() => getDataUri(), 15000)
    }
  }, [reports, structure])
  useEffect(() => {
    if (reports && imagesReport.length === reports?.reduce((acc, element) => acc + element.length, 0)) {
      getBlob()
    }
  }, [imagesReport])

  const current = new Date()
  const date = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear()

  let graphId = ''
  let pdfName = ''
  const getGraphType = (type: any) => {
    switch (type) {
      case 'DETAILED_OCCUPANCY':
        graphId = 'heatmap-chart'
        pdfName = `heatmapChart-${current.getDate()}.pdf`
        return { graphId, pdfName }

      case 'TOP_FLOP':
        graphId = 'topflop-chart'
        pdfName = `topFlopChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'UTILIZATION_OF_SPACE':
        graphId = 'utilization-chart'
        pdfName = `utilizationOfSpaceChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'OCCUPANCY_MONTHLY':
        graphId = 'monthly-chart'
        pdfName = `monthlyChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'RESTAURANT_MAX_CUMULATION':
        graphId = 'restaurant-chart'
        pdfName = `restaurantChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'RESTAURANT_ATTENDANCE_DAY':
        graphId = 'heatmap-chart'
        pdfName = `frequentationChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'BUILDING_ATTENDANCE':
        graphId = 'heatmap-chart'
        pdfName = `spaceAttendanceChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'UTILIZATION_QUALITY':
        graphId = 'utilizationQuality-chart'
        pdfName = `utilizationQualityChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'BENCHMARK':
        graphId = 'benchmark-chart'
        pdfName = `benchmarkChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
    }
  }

  const getLabelsfilter = async (filtersToformat: any) => {
    if (structure) {
      const labels: any = []
      const siteData =
        filtersToformat?.domainPath !== undefined
          ? structure?.domains.find((domain) => domain.path === filtersToformat?.domainPath)
          : structure?.domains.find((domain) => domain.path === filtersToformat?.SITE.value)
      const siteFilter = { key: t(graphsStructure.domainPath.placeholder), value: siteData?.name }
      const buildingName = filtersToformat?.buildingCode
        ? filtersToformat?.buildingCode === optionAllValue
          ? t(graphsStructure.buildingCode.all)
          : siteData?.buildings?.find((building) => building.code === filtersToformat?.buildingCode)?.name
        : undefined
      const buildingCode = { key: t(graphsStructure.buildingCode.placeholder), value: buildingName }
      const floorName = filtersToformat?.floorPath
        ? filtersToformat?.floorPath === optionAllValue
          ? t(graphsStructure.floorPath.all)
          : siteData?.floors?.find((floor) => floor.path === filtersToformat?.floorPath)?.name
        : filtersToformat?.FLOOR
        ? filtersToformat?.FLOOR.value === optionAllValue
          ? t(graphsStructure.floorPath.all)
          : siteData?.floors?.find((floor) => floor.path === filtersToformat?.FLOOR.value)?.name
        : undefined
      const floorPath = { key: t(graphsStructure.floorPath.placeholder), value: floorName }
      const CompartmentName =
        filtersToformat?.compartmentPath === optionAllValue
          ? t(graphsStructure.compartmentPath.all)
          : siteData?.compartments?.find((compartment) => compartment.path === filtersToformat?.compartmentPath)?.name
      const compartmentPath = { key: t(graphsStructure.compartmentPath.placeholder), value: CompartmentName }
      const restaurantName =
        filtersToformat.restaurantId !== undefined ||
        (filtersToformat.RESTAURANT && filtersToformat.RESTAURANT.value !== undefined)
          ? filtersToformat.restaurantId
            ? siteData?.restaurants?.find((restaurant) => restaurant.id === filtersToformat.restaurantId)?.name
            : filtersToformat.RESTAURANT
            ? siteData?.restaurants?.find((restaurant) => restaurant.id === filtersToformat.RESTAURANT.id)?.name
            : ''
          : ''
      const restaurant = { key: t(graphsStructure.restaurantId.placeholder), value: restaurantName }
      const graph = {
        key: t(graphsStructure.graph.placeholder),
        //@ts-ignore
        value: t(graphsStructure.graph[filtersToformat.graph]),
      }
      const roomType = {
        key: t(graphsStructure.roomType.placeholder),
        value: filtersToformat?.roomType
          ? t(graphsStructure.roomType[filtersToformat.roomType as keyof typeof graphsStructure.roomType])
          : filtersToformat.ROOM_TYPE
          ? t(graphsStructure.roomType[filtersToformat.ROOM_TYPE.value as keyof typeof graphsStructure.roomType])
          : undefined,
      }
      const rangeType = {
        key: t(graphsStructure.rangeType.placeholder),
        value: filtersToformat.rangeType
          ? filtersToformat.rangeType == 'CURRENT_MONTH'
            ? t(graphsStructure.rangeType[filtersToformat.rangeType as keyof typeof graphsStructure.rangeType].name)
            : t(graphsStructure.rangeType[filtersToformat.rangeType as keyof typeof graphsStructure.rangeType])
          : t(graphsStructure.rangeType[filtersToformat.QUARTER.value as keyof typeof graphsStructure.rangeType]),
      }
      const capacityType = {
        key: t(graphsStructure.capacityType.placeholder),
        value: filtersToformat.capacityType
          ? t(graphsStructure.capacityType[filtersToformat.capacityType as keyof typeof graphsStructure.capacityType])
          : filtersToformat.CAPACITY_SIZE
          ? t(
              graphsStructure.capacityType[
                filtersToformat.CAPACITY_SIZE.value as keyof typeof graphsStructure.capacityType
              ],
            )
          : undefined,
      }
      const ordinateAxis = {
        key: t(graphsStructure.ordinateAxis.placeholder),
        value: filtersToformat.ordinateAxis
          ? filtersToformat?.ordinateAxis
            ? t(graphsStructure.ordinateAxis[filtersToformat.ordinateAxis as keyof typeof graphsStructure.ordinateAxis])
            : undefined
          : filtersToformat.DETAILED_OCCUPANCY
          ? t(
              graphsStructure.ordinateAxis[
                filtersToformat.DETAILED_OCCUPANCY.value as keyof typeof graphsStructure.ordinateAxis
              ],
            )
          : undefined,
      }
      const kpi = {
        key: t(graphsStructure.kpi.placeholder),
        value: filtersToformat.kpi
          ? t(graphsStructure.kpi[filtersToformat.kpi as keyof typeof graphsStructure.kpi])
          : filtersToformat.KPI
          ? t(graphsStructure.kpi[filtersToformat.KPI.value as keyof typeof graphsStructure.kpi])
          : undefined,
      }
      const capacityCategory = {
        key: t(graphsStructure.capacityCategory.placeholder),
        value: t(
          graphsStructure.capacityCategory[
            filtersToformat.capacityCategory as keyof typeof graphsStructure.capacityCategory
          ],
        ),
      }
      const bookable = {
        key: t(graphsStructure.bookable.placeholder),
        value: filtersToformat.bookable
          ? t(graphsStructure.bookable[filtersToformat.bookable as keyof typeof graphsStructure.bookable])
          : filtersToformat.BOOKABLE
          ? t(graphsStructure.bookable[filtersToformat.BOOKABLE.value as keyof typeof graphsStructure.bookable])
          : undefined,
      }
      const open = {
        key: t(graphsStructure.open.placeholder),
        value: filtersToformat?.open
          ? t(graphsStructure.open[filtersToformat.open as keyof typeof graphsStructure.open])
          : filtersToformat.OPEN_CLOSED
          ? t(graphsStructure.open[filtersToformat.OPEN_CLOSED.value as keyof typeof graphsStructure.open])
          : undefined,
      }
      const businessUnit = {
        key: t(graphsStructure.businessUnit.placeholder),
        value: filtersToformat.businessUnit
          ? filtersToformat.businessUnit
          : filtersToformat.BUSINESS_UNITS
          ? filtersToformat.BUSINESS_UNITS.value
          : undefined,
      }
      const nature = {
        key: t(graphsStructure.nature.placeholder),
        value: filtersToformat.nature
          ? t(graphsStructure.nature[filtersToformat.nature as keyof typeof graphsStructure.nature])
          : filtersToformat.OPEN_SPACE
          ? t(graphsStructure.nature[filtersToformat.OPEN_SPACE.value as keyof typeof graphsStructure.nature])
          : undefined,
      }
      const affectation = {
        key: t(graphsStructure.nature.placeholder),
        value: filtersToformat.affectation
          ? t(graphsStructure.affectation[filtersToformat.affectation as keyof typeof graphsStructure.affectation])
          : filtersToformat.DEDICATED_SHARED
          ? t(
              graphsStructure.affectation[
                filtersToformat.DEDICATED_SHARED.value as keyof typeof graphsStructure.affectation
              ],
            )
          : undefined,
      }
      const scopeType = {
        key: t(graphsStructure.scopeType.placeholder),
        value: filtersToformat.scopeType
          ? t(graphsStructure.scopeType[filtersToformat.scopeType as keyof typeof graphsStructure.scopeType])
          : filtersToformat.SCOPE_TYPE
          ? t(graphsStructure.scopeType[filtersToformat.SCOPE_TYPE.value as keyof typeof graphsStructure.scopeType])
          : undefined,
      }

      labels.push(
        graph,
        siteFilter,
        buildingCode,
        floorPath,
        capacityCategory,
        kpi,
        roomType,
        compartmentPath,
        rangeType,
        restaurant,
        ordinateAxis,
        capacityType,
        nature,
        businessUnit,
        open,
        bookable,
        affectation,
        scopeType,
      )
      return labels
    }
  }

  const getDataUri = () => {
    selectedDashboardData?.pages.map(async (page: any) => {
      page.reports.map(async (report: any, index: number) => {
        savedReportsService.getOne(selectedDashboardData?.clientCode, report.id).then(async (data) => {
          setNamesReport((namesReport) => [...namesReport, { report: report.id, name: data.name }])
          setDashboardGraphTypes((dashboardGraphTypes: any) => [data.filters.graph, ...dashboardGraphTypes])
          const pdfDataFilters = await getLabelsfilter(data.filters)
          const filtered = pdfDataFilters.filter(function (el: any) {
            if (el !== undefined && el.value !== undefined) return el.value.length > 0
          })

          setDashboardFilters((dashboardFilters) => [{ report: report.id, filters: filtered }, ...dashboardFilters])

          const type = getGraphType(data.filters.graph)

          if (type && type.graphId && report.id !== undefined) {
            if (ApexCharts.exec(report.id.toString(), 'dataURI') !== undefined) {
              ApexCharts.exec(report.id.toString(), 'dataURI').then(({ imgURI = '' }) => {
                setImagesReport((imagesReport) => [...imagesReport, { report: report.id, img: imgURI }])
              })
            }
          }
        })
      })
    })
  }
  useEffect(() => {
    if (namesReport) setFrontPageNumber(Math.ceil(namesReport.length / 10))
  }, [namesReport])
  const displayMetrics =
    dashboardGraphTypes.includes('HEAT_MAP') ||
    dashboardGraphTypes.includes('DETAILED_OCCUPANCY') ||
    dashboardGraphTypes.includes('BUILDING_ATTENDANCE') ||
    dashboardGraphTypes.includes('OCCUPANCY_MONTHLY')
  const MyDocument = () => (
    <Document>
      {Array(frontPageNumber)
        .fill()
        .map((x, i) => i)
        .map((frontPage, j) => (
          <Page size="A4" style={styles.page} orientation="landscape" key={j}>
            <View style={styles.view}>
              <Image style={styles.background} src="/images/default.png" />

              <Text
                style={{
                  marginBottom: '16px',
                  marginLeft: '24px',
                  marginTop: '24px',
                  fontFamily: 'RobotoMono',
                  fontSize: '30px',
                }}
              >
                {selectedDashboardData!.name}
              </Text>
              <Text
                style={{
                  marginBottom: '30px',
                  marginLeft: '24px',
                  color: '#1CB9D6',
                  fontFamily: 'RobotoMono',
                  fontSize: '20px',
                }}
              >
                {t('dashboard.edited')} : {date}
              </Text>

              {reports &&
                reports
                  .flat()
                  .slice(10 * frontPage, 10 * (frontPage + 1))
                  .map((report, index) => (
                    <>
                      {namesReport.find((elt: any) => elt.report === report.id) && (
                        <View
                          key={index}
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text
                            style={{
                              marginBottom: '16px',
                              marginLeft: '48px',
                              fontSize: '14px',
                              marginRight:
                                namesReport.find((elt: any) => elt.report === report.id).name.length > 100
                                  ? '48px'
                                  : '0',
                            }}
                            break
                          >
                            {}
                          </Text>
                          <Text style={styles.line}> </Text>
                          <Text style={{ position: 'relative', right: '20px', top: '-5px' }}>
                            p{frontPage * 10 + (index + 1)}
                          </Text>
                        </View>
                      )}
                    </>
                  ))}

              <Text style={styles.motto}>{t('dashboard.motto')}</Text>
            </View>
            <Image src="/images/logo_white.png" style={styles.simpleLogo} />
          </Page>
        ))}
      {dashboardFilters &&
        dashboardFilters &&
        imagesReport &&
        reports &&
        reports?.map((reports, indexPage) =>
          reports.map((report, index) => (
            <Page key={index} size="A4" style={styles.page} orientation="landscape">
              <View style={styles.section}>
                {dashboardFilters &&
                  dashboardFilters
                    .find((filters) => filters.report === report.id)
                    .filters.map((reportFilter: any, i: any) => (
                      <>
                        {reportFilter.key == t(graphsStructure.graph.placeholder) && (
                          <View key={reportFilter.key + i} style={styles.filter}>
                            <Text style={styles.title}>{reportFilter.value}</Text>
                          </View>
                        )}
                        <View key={i} style={styles.filter}>
                          <Text style={styles.label}>{reportFilter.key}</Text>
                          <Text style={styles.value}>{reportFilter.value}</Text>
                        </View>
                      </>
                    ))}
              </View>
              <View style={styles.section}>
                {imagesReport.find((elt) => elt.report === report.id) && (
                  <Image
                    style={{
                      position: 'absolute',
                      top: 40,
                      bottom: 0,
                      right: 0,
                      objectFit: 'contain',
                      width: '80vw',
                      height: '80%',
                    }}
                    src={
                      imagesReport.find((elt) => elt.report === report.id)
                        ? imagesReport.find((elt) => elt.report === report.id).img
                        : ''
                    }
                  />
                )}
              </View>
              <Image src="/images/logo_white.png" style={styles.simpleLogo} />
              <Text
                style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                  objectFit: 'contain',
                  fontSize: '12px',
                }}
                render={({ pageNumber }) => `p.${pageNumber - frontPageNumber}`}
              />
            </Page>
          )),
        )}
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.view}>
          <Image style={styles.background} src="/images/default.png" />

          <Text
            style={{
              marginBottom: '16px',
              marginLeft: '24px',
              marginTop: '24px',
              fontFamily: 'RobotoMono',
              fontSize: '30px',
            }}
          >
            {t('Appendix')}
          </Text>
          <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
            {t('dashboard.report')}
          </Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              {dashboardGraphTypes.includes('TOP_FLOP') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.topFlop')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.utilizationQuality')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.detailedOccupancy')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.spaceAttendance')}</Text>
                </View>
              )}

              {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/stacked_column.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.stackedColumn')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/monthly.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.monthly')}</Text>{' '}
                </View>
              )}
              {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.frequentation')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.restaurant')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('HEAT_MAP') && dashboardGraphTypes.length < 8 && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.heatmap')}</Text>
                </View>
              )}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                height: '100%',
                justifyContent: 'space-between',
              }}
            >
              {dashboardGraphTypes.includes('TOP_FLOP') && (
                <Text style={styles.informationDescription}> {t('informativeSection.topFlop')}</Text>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.utilizationQuality')}</Text>
              )}
              {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.detailedOccupancy')}</Text>
              )}
              {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                <Text style={styles.informationDescription}> {t('informativeSection.frequentation')}</Text>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                <Text style={styles.informationDescription}> {t('informativeSection.stackedColumn')}</Text>
              )}
              {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.monthly')}</Text>
              )}{' '}
              {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.restaurantFrequentation')}</Text>
              )}{' '}
              {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                <Text style={styles.informationDescription}>{t('informativeSection.restaurantPeak')}</Text>
              )}
              {dashboardGraphTypes.includes('HEAT_MAP') && dashboardGraphTypes.length < 8 && (
                <Text style={styles.informationDescription}>{t('informativeSection.heatmap')}</Text>
              )}
            </View>
          </View>
          <Text style={styles.motto}>
            {t('dashboard.link')}: <a href="https://analytics.jooxter.io/">{'https://analytics.jooxter.io/'}</a>
          </Text>
        </View>

        <Image src="/images/logo_white.png" style={styles.simpleLogo} />
      </Page>

      {dashboardGraphTypes.length >= 8 && (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.view}>
            <Image style={styles.background} src="/images/default.png" />

            <Text
              style={{
                marginBottom: '16px',
                marginLeft: '24px',
                marginTop: '24px',
                fontFamily: 'RobotoMono',
                fontSize: '30px',
              }}
            >
              {t('Appendix')}
            </Text>
            <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
              {t('dashboard.report')}
            </Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'column' }}>
                {dashboardGraphTypes.includes('HEAT_MAP') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/map.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.heatmap')}</Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {dashboardGraphTypes.includes('HEAT_MAP') && (
                  <Text style={styles.informationDescription}>{t('informativeSection.heatmap')}</Text>
                )}
              </View>
            </View>
            <Text style={styles.motto}>
              {t('dashboard.link')}: <a href="https://analytics.jooxter.io/">{'https://analytics.jooxter.io/'}</a>
            </Text>
          </View>

          <Image src="/images/logo_white.png" style={styles.simpleLogo} />
        </Page>
      )}

      {displayMetrics && (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.view}>
            <Image style={styles.background} src="/images/default.png" />

            <Text
              style={{
                marginBottom: '16px',
                marginLeft: '24px',
                marginTop: '24px',
                fontFamily: 'RobotoMono',
                fontSize: '30px',
              }}
            >
              {t('Appendix')}
            </Text>
            <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
              {t('Metrics')}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'column', flex: '1', height: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.information}>
                  <Text style={{ paddingLeft: '8px' }}>{t('dashboard.ninthDecile')}</Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.information}>
                  <Text style={{ paddingLeft: '8px' }}>{t('dashboard.perceivedOccupancy')}</Text>
                </View>
              </View>
            </View>

            <Image src="/images/logo_white.png" style={styles.simpleLogo} />
          </View>
        </Page>
      )}
    </Document>
  )
  const fileToBase64 = (file: any, cb: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const getBlob = async () => {
    const doc = (
      <Document>
        {Array(frontPageNumber)
          .fill()
          .map((x, i) => i)
          .map((frontPage, j) => (
            <Page key={j} size="A4" style={styles.page} orientation="landscape">
              <View style={styles.view}>
                <Image style={styles.background} src="/images/default.png" />

                <Text
                  style={{
                    marginBottom: '16px',
                    marginLeft: '24px',
                    marginTop: '24px',
                    fontFamily: 'RobotoMono',
                    fontSize: '30px',
                  }}
                >
                  {selectedDashboardData!.name}
                </Text>
                <Text
                  style={{
                    marginBottom: '30px',
                    marginLeft: '24px',
                    color: '#1CB9D6',
                    fontFamily: 'RobotoMono',
                    fontSize: '20px',
                  }}
                >
                  {t('dashboard.edited')} : {date}
                </Text>

                {reports &&
                  reports
                    .flat()
                    .slice(10 * frontPage, 10 * (frontPage + 1))
                    .map((report, index) => (
                      <>
                        {namesReport.find((elt: any) => elt.report === report.id) && (
                          <View
                            key={index}
                            style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              style={{
                                marginBottom: '16px',
                                marginLeft: '48px',
                                fontSize: '14px',
                                marginRight:
                                  namesReport.find((elt: any) => elt.report === report.id).name.length > 100
                                    ? '48px'
                                    : '0',
                              }}
                            >
                              {namesReport.find((elt: any) => elt.report === report.id).name}
                            </Text>
                            <Text style={styles.line}> </Text>
                            <Text style={{ position: 'relative', right: '20px', top: '-5px' }}>
                              p{frontPage * 10 + (index + 1)}
                            </Text>
                          </View>
                        )}
                      </>
                    ))}

                <Text style={styles.motto}>{t('dashboard.motto')}</Text>
              </View>
              <Image src="/images/logo_white.png" style={styles.simpleLogo} />
            </Page>
          ))}
        {dashboardFilters &&
          dashboardFilters &&
          imagesReport &&
          reports &&
          reports?.map((reports: any) =>
            reports.map((report: any, index: any) => (
              <Page key={index} size="A4" style={styles.page} orientation="landscape">
                <View style={styles.section}>
                  {dashboardFilters &&
                    dashboardFilters
                      .find((filters: any) => filters.report === report.id)
                      .filters.map((reportFilter: any, i: any) => (
                        <>
                          {reportFilter.key == t(graphsStructure.graph.placeholder) && (
                            <View key={reportFilter.key + i} style={styles.filter}>
                              <Text style={styles.title}>{reportFilter.value}</Text>
                            </View>
                          )}
                          {reportFilter.key !== t(graphsStructure.graph.placeholder) && (
                            <View key={i} style={styles.filter}>
                              <Text style={styles.label}>{reportFilter.key}</Text>
                              <Text style={styles.value}>{reportFilter.value}</Text>
                            </View>
                          )}
                        </>
                      ))}
                </View>
                <View style={styles.section}>
                  {imagesReport.find((elt: any) => elt.report === report.id) && (
                    <Image
                      style={{
                        position: 'absolute',
                        top: 40,
                        bottom: 0,
                        right: 0,
                        objectFit: 'contain',
                        width: '80vw',
                        height: '80%',
                      }}
                      src={
                        imagesReport.find((elt) => elt.report === report.id)
                          ? imagesReport.find((elt) => elt.report === report.id).img
                          : ''
                      }
                    />
                  )}
                </View>
                <Image src="/images/logo_white.png" style={styles.simpleLogo} />
                <Text
                  style={{
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                    objectFit: 'contain',
                    fontSize: '12px',
                  }}
                  render={({ pageNumber }) => `p.${pageNumber - frontPageNumber}`}
                />
              </Page>
            )),
          )}
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.view}>
            <Image style={styles.background} src="/images/default.png" />

            <Text
              style={{
                marginBottom: '16px',
                marginLeft: '24px',
                marginTop: '24px',
                fontFamily: 'RobotoMono',
                fontSize: '30px',
              }}
            >
              {t('Appendix')}
            </Text>
            <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
              {t('dashboard.report')}
            </Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'column' }}>
                {dashboardGraphTypes.includes('TOP_FLOP') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.topFlop')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.utilizationQuality')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.detailedOccupancy')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.spaceAttendance')}</Text>
                  </View>
                )}

                {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/stacked_column.png" />{' '}
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.stackedColumn')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/monthly.png" />{' '}
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.monthly')}</Text>{' '}
                  </View>
                )}
                {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.frequentation')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.restaurant')}</Text>
                  </View>
                )}
                {dashboardGraphTypes.includes('HEAT_MAP') && dashboardGraphTypes.length < 8 && (
                  <View style={styles.information}>
                    <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                    <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.heatmap')}</Text>
                  </View>
                )}
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1',
                  height: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {dashboardGraphTypes.includes('TOP_FLOP') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.topFlop')}</Text>
                )}
                {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.utilizationQuality')}</Text>
                )}
                {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.detailedOccupancy')}</Text>
                )}
                {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.frequentation')}</Text>
                )}
                {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.stackedColumn')}</Text>
                )}
                {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.monthly')}</Text>
                )}{' '}
                {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                  <Text style={styles.informationDescription}> {t('informativeSection.restaurantFrequentation')}</Text>
                )}{' '}
                {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                  <Text style={styles.informationDescription}>{t('informativeSection.restaurantPeak')}</Text>
                )}
                {dashboardGraphTypes.includes('HEAT_MAP') && dashboardGraphTypes.length < 8 && (
                  <Text style={styles.informationDescription}>{t('informativeSection.heatmap')}</Text>
                )}
              </View>
            </View>
            <Text style={styles.motto}>
              {t('dashboard.link')}: <a href="https://analytics.jooxter.io/">{'https://analytics.jooxter.io/'}</a>
            </Text>
          </View>

          <Image src="/images/logo_white.png" style={styles.simpleLogo} />
        </Page>

        {dashboardGraphTypes.length >= 8 && (
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.view}>
              <Image style={styles.background} src="/images/default.png" />

              <Text
                style={{
                  marginBottom: '16px',
                  marginLeft: '24px',
                  marginTop: '24px',
                  fontFamily: 'RobotoMono',
                  fontSize: '30px',
                }}
              >
                {t('Appendix')}
              </Text>
              <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
                {t('dashboard.report')}
              </Text>

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  {dashboardGraphTypes.includes('HEAT_MAP') && (
                    <View style={styles.information}>
                      <Image style={{ width: '34px' }} src="/images/map.png" />
                      <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.heatmap')}</Text>
                    </View>
                  )}
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    height: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {dashboardGraphTypes.includes('HEAT_MAP') && (
                    <Text style={styles.informationDescription}>{t('informativeSection.heatmap')}</Text>
                  )}
                </View>
              </View>
              <Text style={styles.motto}>
                {t('dashboard.link')}: <a href="https://analytics.jooxter.io/">{'https://analytics.jooxter.io/'}</a>
              </Text>
            </View>

            <Image src="/images/logo_white.png" style={styles.simpleLogo} />
          </Page>
        )}

        {displayMetrics && (
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.view}>
              <Image style={styles.background} src="/images/default.png" />

              <Text
                style={{
                  marginBottom: '16px',
                  marginLeft: '24px',
                  marginTop: '24px',
                  fontFamily: 'RobotoMono',
                  fontSize: '30px',
                }}
              >
                {t('Appendix')}
              </Text>
              <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
                {t('Metrics')}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'column', flex: '1', height: '100%' }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={styles.information}>
                    <Text style={{ paddingLeft: '8px' }}>{t('dashboard.ninthDecile')}</Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={styles.information}>
                    <Text style={{ paddingLeft: '8px' }}>{t('dashboard.perceivedOccupancy')}</Text>
                  </View>
                </View>
              </View>

              <Image src="/images/logo_white.png" style={styles.simpleLogo} />
            </View>
          </Page>
        )}
      </Document>
    )
    const blobPdf = await pdf(doc)
    blobPdf.updateContainer(doc)
    const pdfInblob = await blobPdf.toBlob()

    fileToBase64(pdfInblob, (err: any, result: any) => {
      if (result && selectedDashboardData && dashboardFilters && reports?.flat().length === namesReport.length) {
        if (recipient) {
          savedReportsService
            .sendMail(selectedDashboardData.clientCode, id, result.split(',')[1], recipient)
            .then(() => {
              const finish = document.createElement('p')
              finish.setAttribute('id', 'sending-complete')
              document.body.appendChild(finish)
            })
        } else {
          savedReportsService.sendMail(selectedDashboardData.clientCode, id, result.split(',')[1]).then(() => {
            const finish = document.createElement('p')
            finish.setAttribute('id', 'sending-complete')
            document.body.appendChild(finish)
          })
        }
      }
    })
  }
  return (
    <>
      {imagesReport.length > 0 &&
        dashboardFilters.length === reports?.reduce((acc, element) => acc + element.length, 0) && (
          <PDFDownloadLink document={<MyDocument />} fileName="dashboard.pdf" onClick={getBlob}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'donwload now')}
          </PDFDownloadLink>
        )}

      <div className="flex flex-col m-5 w-2/3">
        {reports?.map((reports, indexPage) =>
          reports.map((report, index) => {
            return (
              <DashboardReportView
                isAutomaticSending={true}
                superAdminClientCode={selectedDashboardData?.clientCode}
                key={index}
                reportID={report.id.toString()}
                editMode={false}
                onTrashClick={() => undefined}
              />
            )
          }),
        )}
      </div>
    </>
  )
}

export default DashboardView

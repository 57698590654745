import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { spaceAttendanceValue, frequentationValue, graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { getFilterType, isRIEGraph } from 'utils/filtersUtils'

const { placeholder } = graphsStructure.restaurantId

const handleStateChange = (state: FilterState, value?: string, id?: number): FilterState => {
  if (state.RESTAURANT.value === value) return state

  const isFrequentation = state.GRAPH.value === frequentationValue
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  return {
    ...state,
    RESTAURANT: {
      ...initialFilterState.RESTAURANT,
      required: isRIEGraph(state.GRAPH) || isFrequentation,
      value,
      id,
    },
    QUARTER: {
      active: isRIEGraph(state.GRAPH) || isFrequentation,
      required: isRIEGraph(state.GRAPH) || isFrequentation,
    },
    BUILDING: {
      active: !isRIEGraph(state.GRAPH) && !isFrequentation,
      required: !isRIEGraph(state.GRAPH) && !isFrequentation,
    },
    ROOM_TYPE: {
      active: !isRIEGraph(state.GRAPH),
      required: !isRIEGraph(state.GRAPH) && !isFrequentation && !isSpaceAttendance,
    },
    FLOOR: {
      active: !isRIEGraph(state.GRAPH),
      required: !isRIEGraph(state.GRAPH) && !isFrequentation,
    },
    KPI: {
      ...initialFilterState.KPI,
      active: !!value,
    },
  }
}

const RestaurantFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)

  const restaurantValue = state.RESTAURANT.value

  const restaurants = structure.domains.find((domain) => domain.path === state.SITE.value)?.restaurants || []

  const options = restaurants.map((restaurant) => ({ name: restaurant.name, id: restaurant.id }))

  const restaurantData = options.find((option) => option.name === state.RESTAURANT.value)
  const type = getFilterType(state.GRAPH.value, state.RESTAURANT.active, restaurantData)

  const onChange = (value?: string, id?: number) => onFilterChange(handleStateChange(state, value, id))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={restaurantData?.name}
      selected={restaurantValue}
      // Rule : If there's only one value, then it is automatically selected and the user can't open the filter.
      // Therefore, we add the '[all]' option only in the case where there's more than 1 option. Otherwise, the filter can
      // be opened.
      disabled={(!state.RESTAURANT.active && !state.RESTAURANT.value) || options.length < 1}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.name} value={option.name} id={option.id}>
          {option.name}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default RestaurantFilter

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { DomainsStructure } from 'types/DomainsStructure'
import { useTranslation } from 'react-i18next'
import {
  domainFilterByGraph,
  frequentationValue,
  graphsStructure,
  detailedOccupancyValue,
  monthlyValue,
  detailedOccupancyTableValue,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'
import { getBusinessUnits, getFilterType, isMonthlyGraph, isRIEGraph } from 'utils/filtersUtils'

const { placeholder } = graphsStructure.domainPath
const { placeholder: graphPlaceholder, ...graphStructure } = graphsStructure.graph

const handleStateChange = (state: FilterState, structure: DomainsStructure, value?: string): FilterState => {
  if (state.SITE.value === value) return state

  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isMonthly = state.GRAPH.value === monthlyValue
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const isRestaurantFrequentation = state.GRAPH.value === frequentationValue
  // Rule : If the SITE changes, then we have to reset the BUILDING state and all the other states.
  // If in that site there is only one BUILDING, then we want to automatically set it.
  const selectedDomain = structure.domains.find((domain) => domain.path === value)
  const buildings = selectedDomain?.buildings
  const buildingValue = buildings?.length === 1 ? buildings[0].code : undefined
  const floorValue = selectedDomain?.floors?.length === 1 ? selectedDomain?.floors[0].path : undefined
  const businessUnits = getBusinessUnits(structure, state)
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    MONITORING_ROOM_TYPE: { ...initialFilterState.MONITORING_ROOM_TYPE, value: [] },
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ENTITY: { ...state.MONITORING_ENTITY, active: !!value },
    SITE: {
      ...state.SITE,
      active: !value,
      value,
    },
    BUILDING: {
      ...state.BUILDING,
      required: !!buildings && !isRIEGraph(state.GRAPH) && !frequentationValue,
      value: buildingValue,
      active: !!value && !buildingValue,
    },
    FLOOR: {
      ...initialFilterState.FLOOR,
      value: floorValue,
      // Rule : The FLOOR filter must not be filled for the HEATMAP
      required: !isDetailedOccupancy && !isRIEGraph(state.GRAPH) && !frequentationValue,
      // If there are no buildings, then the BUILDING filter is hidden. In this case, the FLOOR filter must be activated.
      // (only if not heatmap)
      active: !isDetailedOccupancy && !!value && (!!buildingValue || !selectedDomain?.buildings) && !floorValue,
    },
    KPI: {
      ...initialFilterState.KPI,
      required: isDetailedOccupancy || isRestaurantFrequentation,
      active: !!value && !!detailedOccupancyTableValue,
    },
    BUSINESS_UNITS: {
      ...initialFilterState.BUSINESS_UNITS,
      // Rule : Only if MONTHLY is picked (same conditions as HEATMAP except for HEATMAP being picked) and there are
      // business units
      active:
        isMonthly &&
        businessUnits?.length > 0 &&
        !!value &&
        (!!buildingValue || !selectedDomain?.buildings) &&
        (!!selectedDomain?.floors || !!floorValue),
    },
    ROOM_TYPE: {
      ...initialFilterState.ROOM_TYPE,
      // The condition is quite large... But there are a lot of filters which can be skipped because they have no or only
      // one value. We have to check them all independently to activate this filter. For the FLOOR and the BUILDING
      // filters, either there are not any in the site, either there's only one and it is already selected.
      // As there are no default value for the HEATMAP filter, it will always be the first to be active (in the case the
      // user picked Heatmap or Monthly). One more condition (because it wasn't enough) : if no business units for the
      // client, then we don't show the BUSINESS UNITS filter and we skip it...
      active:
        (!isMonthly || (isMonthly && businessUnits?.length == 0)) &&
        !!value &&
        (!!buildingValue || !selectedDomain?.buildings) &&
        ((isDetailedOccupancy && !!selectedDomain?.floors) || !!floorValue || isBenchmark),
      required:
        isMonthlyGraph(state.GRAPH) ||
        (!isRIEGraph(state.GRAPH) && !frequentationValue && !detailedOccupancyTableValue),
    },
    QUARTER: {
      ...initialFilterState.QUARTER,
      // If there is only one option in all the filters before this one, then they are all disabled. So, this one must
      // be active. Only if heatmap is NOT picked (because the FLOOR filter isn't included, there is a special one).
      active: !!value && !!buildingValue && state.GRAPH.value !== detailedOccupancyValue && !!floorValue,
    },
    RESTAURANT: {
      ...initialFilterState.RESTAURANT,
      required: isRestaurantFrequentation,
    },
  }
}

const SiteFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const isBenchmark = state.GRAPH.value == 'BENCHMARK'
  const options =
    state.GRAPH.value == 'BENCHMARK'
      ? structure.domains.map((domain) => ({ label: domain.name, value: domain.path }))
      : structure.domains
          .filter((domain) => domainFilterByGraph[state.GRAPH.value as keyof typeof graphStructure](domain.features))
          .map((domain) => ({ label: domain.name, value: domain.path }))
  const value = state.SITE.value
  const siteData = options.find((option) => option.value === value)
  const type = getFilterType(state.GRAPH.value, state.SITE.active, siteData)
  const onChange = (value?: string) => onFilterChange(handleStateChange(state, structure, value))
  if (isBenchmark && options.length == 1) {
    state.SITE.value = options[0].value
    structure?.domains[0].buildings ? (state.BUILDING.active = true) : (state.ROOM_TYPE.active = true)
  }

  return (
    <Filter
      isBenchmark={isBenchmark}
      type={type}
      placeholder={t(placeholder)}
      label={siteData?.label}
      disabled={(!state.SITE.active && !state.SITE.value) || options.length <= 1}
      selected={value}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default SiteFilter

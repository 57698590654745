import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  RIE,
  calibratedUtilizationValue,
  countingMaxValue,
  detailedOccupancyTableValue,
  detailedOccupancyValue,
  frequentationValue,
  heatmapValue,
  monitoringValue,
  monthlyValue,
  roomBookingValue,
  spaceAttendanceValue,
  stackedColumnValue,
  topFlopValue,
  utilizationQualityValue,
} from 'utils/constants/graphs/graphsStructure'

export default function graphPreview({ graphType, entry }) {
  const isWorkstation = entry === 'DESKTOP'

  const { t } = useTranslation()
  const getGraphPreview = () => {
    switch (true) {
      case graphType === topFlopValue: {
        return '/images/graphPreview/topFlopPreview.png'
      }
      case graphType === heatmapValue: {
        return '/images/graphPreview/heatmapPreview.png'
      }
      case graphType === detailedOccupancyValue && isWorkstation: {
        return '/images/graphPreview/detailedOccupancyWorkstationPreview.png'
      }
      case graphType === detailedOccupancyValue && !isWorkstation: {
        return '/images/graphPreview/detailedOccupancyCsPreview.png'
      }
      case graphType === utilizationQualityValue: {
        return '/images/graphPreview/utilizationQualityPreview.png'
      }
      case graphType === monthlyValue && isWorkstation: {
        return '/images/graphPreview/monthlyWorkstationPreview.png'
      }
      case graphType === monthlyValue && !isWorkstation: {
        return '/images/graphPreview/monthlyCsPreview.png'
      }
      case graphType === stackedColumnValue: {
        return '/images/graphPreview/stackedColumnPreview.png'
      }
      case graphType === spaceAttendanceValue: {
        return '/images/graphPreview/spaceAttendancePreview.png'
      }
      case graphType === frequentationValue: {
        return '/images/graphPreview/restaurantFrequentationPreview.png'
      }
      case graphType === RIE: {
        return '/images/graphPreview/restaurantPeaksPreview.png'
      }
    }
  }
  const getGraphDescription = () => {
    switch (true) {
      case graphType === topFlopValue: {
        return t('graphPreview.topFlopDesc')
      }
      case graphType === heatmapValue: {
        return t('graphPreview.heatmapDesc')
      }
      case graphType === utilizationQualityValue: {
        return t('graphPreview.utilizationQualityDesc')
      }
      case graphType === monthlyValue && isWorkstation: {
        return t('graphPreview.monthlyWsDesc')
      }
      case graphType === monthlyValue && !isWorkstation: {
        return t('graphPreview.monthlyEcDesc')
      }
      case graphType === stackedColumnValue: {
        return t('graphPreview.stackedColumnDesc')
      }
      case graphType === roomBookingValue: {
        return t('graphPreview.roomBookingDesc')
      }
      case graphType === spaceAttendanceValue: {
        return t('graphPreview.spaceAttendanceDesc')
      }
      case graphType === frequentationValue: {
        return t('graphPreview.restaurantFrequentationDesc')
      }
      case graphType === RIE: {
        return t('graphPreview.peaksRestaurantDesc')
      }
      case graphType === countingMaxValue: {
        return t('graphPreview.detailedUtilizationDesc')
      }
      case graphType === detailedOccupancyTableValue: {
        return t('graphPreview.detailedOccupancyTable')
      }
      case graphType === monitoringValue: {
        return t('graphPreview.monitoringTableDesc')
      }
      case graphType === detailedOccupancyValue && isWorkstation: {
        return t('graphPreview.detailedOccupancyWsDesc')
      }
      case graphType === detailedOccupancyValue && !isWorkstation: {
        return t('graphPreview.detailedOccupancyEcDesc')
      }
      case graphType === calibratedUtilizationValue: {
        return (
          <>
            <p>{t('graphPreview.advancedMonitoringTableDesc.primary')}</p>
            <ul>
              {t('graphPreview.advancedMonitoringTableDesc.details', { returnObjects: true }).map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </>
        )
      }
    }
  }
  return (
    <div>
      {getGraphPreview() && (
        <img src={getGraphPreview()} alt="graph-preview" className="mb-2" style={{ height: '80%' }} />
      )}
      <div className="text-center line-break">{getGraphDescription()}</div>
    </div>
  )
}

import { FC, useEffect, useState } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { horizontalBarChartOptions } from 'utils/constants/graphs/horizontalBarChart'
import { HorizontalBarChartSeries } from 'types/react-apexcharts/charts'
import IconSvg from '../../../atoms/icons/IconSvg'
import styled from 'styled-components'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

type Props = {
  data: number[]
  title: string
  id?: string
  subtitle: string
  categories: (string | string[])[]
  truncated: boolean
  minutes: number[]
  colors: string[]
}

const HorizontalBarChart: FC<Props> = (props) => {
  const { data, title, subtitle, categories, truncated, id, minutes, colors } = props
  const { t } = useTranslation()

  const [chartData, setChartData] = useState({ series: data, categories })
  // If the data is truncated, which means it's a top flop (20 top values & 20 bottom values), then we have to know
  // where the data are split. It's always starting at the middle, but if the user removes some data by right clicking,
  // then it might not be the middle anymore.
  const [splitIndex, setSplitIndex] = useState(
    !truncated ? -1 : data.length % 2 === 0 ? data.length / 2 - 1 : Math.floor(data.length / 2),
  )

  useEffect(() => {
    setChartData({
      series: data,
      categories,
    })
    setSplitIndex(!truncated ? -1 : data.length % 2 === 0 ? data.length / 2 - 1 : Math.floor(data.length / 2))
  }, [data, categories])

  const onReverseButtonClick = () => {
    setChartData((prevState) => ({
      series: [...prevState.series].reverse(),
      categories: [...prevState.categories].reverse(),
    }))
  }

  // If we remove the last element of the series data between the beginning and the fake empty row
  // (with label '...' representing truncated data) or between the end and the fake empty row, we don't want it
  // to be displayed anymore. So we have to check these cases individually (same for categories)
  const filterOnDeleteClick = (indexToRemove: number, elementIndex: number, arrayLength: number) => {
    // check case : we remove the last element between the beginning and the fake row if we click on the top part
    if (truncated && indexToRemove === 0 && splitIndex === 1 && elementIndex === 1) {
      return false
    }
    // check case : we remove the last element between the end and the fake row if we click on the bottom part
    else if (indexToRemove === arrayLength - 1 && splitIndex === arrayLength - 2 && elementIndex === arrayLength - 2) {
      return false
    }

    return elementIndex !== indexToRemove
  }

  // The categories list must have at least one element for ReactApexCharts, otherwise it renders a list of numbers
  // and it's awful. So, if there are no data, we give an array with a blank space.
  const options = horizontalBarChartOptions({
    categories: chartData.categories.length === 0 ? [' '] : chartData.categories,
    id,
    title,
    subtitle,
    minutes,
    colors,
    truncatedIndex: truncated ? splitIndex : undefined,
    onRightClickCallback: (indexToRemove) => {
      const prevSplitIndex = splitIndex

      // Make sure the user doesn't right click on the custom row which represents truncated data (the '...' row)
      // We have to move the splitIndex only if the user removes an element before the split, in the "top" values.
      if (prevSplitIndex !== 0 && indexToRemove === prevSplitIndex) {
        return
      }

      setSplitIndex((prevSplitIndex) => {
        return indexToRemove > prevSplitIndex ? prevSplitIndex : prevSplitIndex - 1
      })

      setChartData((prevState) => {
        return {
          series: prevState.series.filter((el, index) =>
            filterOnDeleteClick(indexToRemove, index, prevState.series.length),
          ),
          categories: prevState.categories.filter((el, index) =>
            filterOnDeleteClick(indexToRemove, index, prevState.categories.length),
          ),
        }
      })
    },
  })

  return (
    <div className="relative max-w-full max-h-full overflow-x-hidden overflow-y-auto">
      <button
        onClick={onReverseButtonClick}
        className="ring-basic text-c1 z-1 absolute right-12 top-0 mt-3 px-2 py-1 rounded ring-1"
      >
        <IconSvg name="REVERSE" color="BLACK" />
      </button>
      <CustomBarChart
        type="bar"
        height={chartData.series.length > 10 ? 50 * chartData.series.length : undefined}
        options={options}
        series={[{ data: chartData.series }]}
        truncatedIndex={splitIndex}
        noContextMenu
      />
    </div>
  )
}

type CustomBarChartProps = ChartProps<HorizontalBarChartSeries> & {
  truncatedIndex: number | undefined
}
const name = 'seriesName'

const CustomBarChart = styled(({ truncatedIndex, ...rest }: CustomBarChartProps) => <ReactApexChart {...rest} />)`
  & [${name}='seriesx1'] > [j='${(props) => props.truncatedIndex}'] {
    fill: #cccccc;
  }
`

export default HorizontalBarChart

import { FC, useEffect, useState } from 'react'
import { RestaurantDataType, RestaurantDataWSType } from 'types/GraphDataType'
import LoadingChart from '../LoadingChart'
import RestaurantChart from './RestaurantChart'
import { useTranslation } from 'react-i18next'
import { dayName } from 'utils/dateUtils'

type Props = {
  id?: string
  title: string
  loading: boolean
  data: RestaurantDataWSType
  colors: string[]
}

const RestaurantController: FC<Props> = (props) => {
  const { data, id, title, loading, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<RestaurantDataType>()
  const categories: string[] = []

  useEffect(() => {
    if (loading) {
      return
    }

    const maxValues: number[] = []
    const cumulationValues: number[] = []
    function getMonth(date: any) {
      const month = date.getMonth() + 1
      return month < 10 ? '0' + month : '' + month // ('' + month) for string result
    }

    data.result.forEach((el, index) => {
      let formattedDate = ''
      const currentDate = new Date(el.period)
      const startDate = new Date(currentDate.getFullYear(), 0, 1)
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
      const weekNumber = Math.ceil(days / 7)
      const lang = navigator.language
      if (el.period.indexOf('/') > -1) {
        const startDate = new Date(el.period.substring(0, el.period.indexOf('/')).replace(/\s/g, ''))
        const endDate = new Date(el.period.substring(el.period.indexOf('/') + 1, el.period.length).replace(/\s/g, ''))
        formattedDate = `${startDate.getDate()}  ${t(`graph.monthly.month.${getMonth(startDate)}`).substring(
          0,
          3,
        )} - ${endDate.getDate()}  ${t(`graph.monthly.month.${getMonth(endDate)}`).substring(0, 3)}  `
      } else if (el.period.indexOf('M') > -1) {
        const year = el.period.substring(0, el.period.indexOf('M'))
        const month = el.period.substring(el.period.indexOf('M') + 1, el.period.length)
        formattedDate = ` ${t(`graph.monthly.month.${month}`)}  ${year} `
      } else {
        formattedDate =
          new Date(el.period).getUTCDay() == 1
            ? `${t('graph.common.week')} ${weekNumber} - ${dayName(new Date(el.period))} ${String(
                new Date(el.period).getUTCDate(),
              ).padStart(2, '0')}`
            : `${`${dayName(new Date(el.period))} ${String(new Date(el.period).getUTCDate()).padStart(2, '0')}`} `
      }

      maxValues.push(el.max)
      cumulationValues.push(el.cumulativeEntries)

      categories.push(formattedDate)
    })

    const series = [
      {
        name: 'Max',
        data: maxValues,
        description: t('graph.restaurant.maxLegend'),
      },
      {
        name: t('graph.restaurant.entries'),
        data: cumulationValues,
        description: t('graph.restaurant.cumulationLegend'),
      },
    ]
    setFormattedData({ series, categories })
  }, [data])

  if (loading || !formattedData) {
    return <LoadingChart type="bar" stacked={false} />
  }

  return (
    <RestaurantChart
      peopleCount={data.capacity}
      categories={formattedData.categories}
      id={id}
      title={title}
      series={formattedData.series}
      colors={colors}
    />
  )
}

export default RestaurantController

import PageNotFound from '../../../views/PageNotFound'
import PageNoRight from '../../../views/PageNoRight'
import Login from '../../../views/Login'
import ForgotPassword from '../../../views/ForgotPassword'
import ResetPassword from '../../../views/ResetPassword'
import AnalysisView from '../../../views/loggedIn/AnalysisView'
import ChangePassword from '../../../views/loggedIn/ChangePassword'
import { FC } from 'react'
import { PATHS } from './Paths'
import HomeView from '../../../views/loggedIn/HomeView'
import SavedReportsView from '../../../views/loggedIn/SavedReportsView'
import SavedReportsEditView from '../../../views/loggedIn/SavedReportsEditView'
import IdCardView from '../../../views/loggedIn/IdCardView'
import DashboardView from 'views/loggedIn/DashboardView'
import ComfortView from 'views/loggedIn/ComfortView'
import SitesView from 'views/loggedIn/SitesView'
import BenchmarkView from 'views/loggedIn/BenchmarkView'
import SettingsView from 'views/loggedIn/SettingsView'
import ColorsSettings from 'views/loggedIn/ColorsSettingsView'
import LoginPassword from 'views/LoginPassword'
import Oidc from '../../../views/Oidc'
import RieView from 'views/loggedIn/RieView'
import RealTimeView from 'views/loggedIn/RealtTimeView'
import { PermissionEnum } from '../permissions'
import OverView from 'views/loggedIn/OverView'

export type RouteType = {
  path: string
  component: FC
  exact?: boolean
  permissions?: PermissionEnum[]
}
export const SuperAdminRoutes: RouteType[] = [
  {
    path: PATHS.AUTOMAICSENDING.HOME,
    component: DashboardView,
    permissions: [PermissionEnum.ANALYTICS_SUPER_ADMIN],
  },
]

export const NoRightsRoutes: RouteType[] = [
  {
    path: PATHS.ALL,
    component: PageNoRight,
  },
]

export const LoggedInRoutes: RouteType[] = [
  {
    path: PATHS.HOME,
    component: HomeView,
    exact: true,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.ANALYSIS.HOME,
    component: AnalysisView,
    exact: false,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.ANALYSIS.HOME,
    component: AnalysisView,
    exact: false,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.REAL_TIME.HOME,
    component: RealTimeView,
    exact: true,
    permissions: [PermissionEnum.ANALYTICS_REALTIME],
  },
  {
    path: PATHS.COMFORT.HOME,
    component: ComfortView,
    exact: false,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.FAVORITES.EDIT_SINGLE_REPORT,
    component: SavedReportsEditView,
    exact: false,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SAVED_REPORTS.HOME,
    component: SavedReportsView,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.AUTH.CHANGE_PASSWORD,
    component: ChangePassword,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SITES.HOME,
    component: SitesView,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SITES.BENCHMARK,
    component: BenchmarkView,
    exact: false,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SITES.ID_CARD,
    component: IdCardView,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SETTINGS.COLORS,
    component: ColorsSettings,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.SETTINGS.HOME,
    component: SettingsView,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.RIE.HOME,
    component: RieView,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.ALL,
    component: PageNotFound,
    permissions: [PermissionEnum.ANALYTICS],
  },
  {
    path: PATHS.OVERVIEW.HOME,
    component: OverView,
  },
]

export const LoggedOutRoutes: RouteType[] = [
  {
    path: PATHS.HOME,
    component: Login,
    exact: true,
  },
  {
    path: PATHS.AUTH.LOGIN,
    component: LoginPassword,
    exact: true,
  },
  {
    path: PATHS.AUTH.OIDC,
    component: Oidc,
  },
  {
    path: PATHS.AUTH.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: PATHS.AUTH.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    path: PATHS.RIE.HOME,
    component: RieView,
  },
  {
    path: PATHS.OVERVIEW.HOME,
    component: OverView,
  },
  {
    path: PATHS.ALL,
    component: PageNotFound,
  },
]

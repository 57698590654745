import { FC } from 'react'
import { FiltersType } from 'types/SavedReportsTypes'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { useTranslation } from 'react-i18next'
import { optionAllValue, optionalOptionAllValue } from 'utils/constants/graphs/global'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import FilterSkeleton from '../../atoms/filters/FilterSkeleton'

type FilterInformationProps = {
  label: string
  value?: string
}

const FilterInformation = (props: FilterInformationProps) => {
  const { label, value } = props

  return (
    <div className="w-1/2 px-1">
      <span className="text-c1 text-basic-400">{label}</span>
      <p className="mt-0 font-bold">{value}</p>
    </div>
  )
}

type Props = {
  filters: FiltersType
}

const FavoriteItemFilters: FC<Props> = (props) => {
  const { filters } = props
  const { t } = useTranslation()
  const { structure, isError, isLoading } = useFetchStructure()

  if (isLoading) {
    return (
      <div className="flex flex-wrap p-4 bg-basic-100">
        <FilterSkeleton type="long" />
        <FilterSkeleton />
        <FilterSkeleton type="short" />
        <FilterSkeleton />
        <FilterSkeleton type="short" />
        <FilterSkeleton type="long" />
      </div>
    )
  }

  if (isError || !structure) {
    return (
      <div className="flex flex-wrap px-4 pb-4 bg-basic-100">
        <p className="text-red-500">{t('savedReports.singleReport.errorLoadingStructure')}</p>
      </div>
    )
  }
  const siteData = structure.domains.find((domain) => domain.path === filters.domainPath)
  const buildingName =
    filters.buildingCode === optionAllValue
      ? t(graphsStructure.buildingCode.all)
      : siteData?.buildings?.find((building) => building.code === filters.buildingCode)?.name
  const floorName =
    filters.floorPath === optionAllValue
      ? t(graphsStructure.floorPath.all)
      : siteData?.floors?.find((floor) => floor.path === filters.floorPath)?.name
  const CompartmentName =
    filters.compartmentPath === optionAllValue
      ? t(graphsStructure.compartmentPath.all)
      : siteData?.compartments?.find((compartment) => compartment.path === filters.compartmentPath)?.name
  const restaurantName = siteData?.restaurants?.find(
    (restaurant) =>
      //@ts-ignore
      restaurant.id === filters.restaurantId,
  )?.name

  return (
    <div className="flex flex-wrap px-4 pb-4 bg-basic-100">
      {filters.domainPath && (
        <FilterInformation label={t(graphsStructure.domainPath.placeholder)} value={siteData?.name} />
      )}
      {filters.buildingCode && (
        <FilterInformation label={t(graphsStructure.buildingCode.placeholder)} value={buildingName} />
      )}
      {filters.restaurantId && (
        <FilterInformation label={t(graphsStructure.restaurantId.placeholder)} value={restaurantName} />
      )}
      {filters.floorPath && <FilterInformation label={t(graphsStructure.floorPath.placeholder)} value={floorName} />}
      {filters.compartmentPath && (
        <FilterInformation label={t(graphsStructure.compartmentPath.placeholder)} value={CompartmentName} />
      )}
      {filters.roomType && (
        <FilterInformation
          label={t(graphsStructure.roomType.placeholder)}
          value={
            graphsStructure.roomType[filters.roomType as keyof typeof graphsStructure.roomType] == undefined
              ? filters.roomType
              : t(graphsStructure.roomType[filters.roomType as keyof typeof graphsStructure.roomType])
          }
        />
      )}

      {filters.scopeType && (
        <FilterInformation
          label={t(graphsStructure.scopeType.placeholder)}
          value={t(graphsStructure.scopeType[filters.scopeType as keyof typeof graphsStructure.scopeType])}
        />
      )}
      {filters.rangeType && (
        <FilterInformation
          label={t(graphsStructure.rangeType.placeholder)}
          value={
            filters.rangeType == 'CURRENT_MONTH'
              ? t(graphsStructure.rangeType[filters.rangeType as keyof typeof graphsStructure.rangeType].name)
              : t(graphsStructure.rangeType[filters.rangeType as keyof typeof graphsStructure.rangeType])
          }
        />
      )}
      {filters.capacityType && (
        <FilterInformation
          label={t(graphsStructure.capacityType.placeholder)}
          value={t(graphsStructure.capacityType[filters.capacityType as keyof typeof graphsStructure.capacityType])}
        />
      )}
      {filters.ordinateAxis && (
        <FilterInformation
          label={t(graphsStructure.ordinateAxis.placeholder)}
          value={t(graphsStructure.ordinateAxis[filters.ordinateAxis as keyof typeof graphsStructure.ordinateAxis])}
        />
      )}
      {filters.kpi && (
        <FilterInformation
          label={t(graphsStructure.kpi.placeholder)}
          value={t(graphsStructure.kpi[filters.kpi as keyof typeof graphsStructure.kpi])}
        />
      )}
      {filters.capacityCategory && filters.capacityCategory !== optionalOptionAllValue && (
        <FilterInformation
          label={t(graphsStructure.capacityCategory.placeholder)}
          value={t(
            graphsStructure.capacityCategory[filters.capacityCategory as keyof typeof graphsStructure.capacityCategory],
          )}
        />
      )}
      {filters.bookable && filters.bookable !== optionalOptionAllValue && (
        <FilterInformation
          label={t(graphsStructure.bookable.placeholder)}
          value={t(graphsStructure.bookable[filters.bookable as keyof typeof graphsStructure.bookable])}
        />
      )}
      {filters.open && filters.open !== optionalOptionAllValue && (
        <FilterInformation
          label={t(graphsStructure.open.placeholder)}
          value={t(graphsStructure.open[filters.open as keyof typeof graphsStructure.open])}
        />
      )}
      {filters.businessUnit && filters.businessUnit !== optionAllValue && (
        <FilterInformation label={t(graphsStructure.businessUnit.placeholder)} value={filters.businessUnit} />
      )}
      {filters.nature && filters.nature !== optionalOptionAllValue && (
        <FilterInformation
          label={t(graphsStructure.nature.placeholder)}
          value={t(graphsStructure.nature[filters.nature as keyof typeof graphsStructure.nature])}
        />
      )}
      {filters.affectation && filters.affectation !== optionalOptionAllValue && (
        <FilterInformation
          label={t(graphsStructure.affectation.placeholder)}
          value={t(graphsStructure.affectation[filters.affectation as keyof typeof graphsStructure.affectation])}
        />
      )}
    </div>
  )
}

export default FavoriteItemFilters

import { ElementType, FC } from 'react'
import { Dialog as HUIDialog } from '@headlessui/react'
import IconSvg from '../icons/IconSvg'

type Props = {
  onClose?: () => void
  as?: ElementType
  className?: string
}

const DialogTitle: FC<Props> = (props) => {
  const { onClose, as = 'div', className = '', children } = props

  return (
    <HUIDialog.Title
      as={as}
      style={{ fontSize: '1.1rem' }}
      className={`rounded-tl-2xl rounded-tr-2xl w-full flex flex-row items-center px-6 py-4 justify-${
        children ? 'between bg-basic text-basic-100' : 'end bg-basic-100 text-basic'
      } ${className}`}
    >
      {children}
      {onClose && <IconSvg name="CLOSE" color={children ? 'WHITE' : 'BLACK'} onClick={onClose} />}
    </HUIDialog.Title>
  )
}

export default DialogTitle

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { toast } from 'react-toastify'

import useCurrentUser from 'utils/hooks/useCurrentUser'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import FavoriteList from '../../favorites/FavoriteList'

import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { savedReportsService } from 'services/savedReportsService'
import { SavedReportList } from 'types/SavedReportsTypes'

type Props = {
  open: boolean
  onClose: () => void
  onClick: any
  onItemClick: any
}

const SearchReportDialog: FC<Props> = ({ onClose, open, onClick, onItemClick }: Props) => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()

  const { isLoading, isFetched, data } = useQuery<SavedReportList>({
    queryKey: QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode),
    queryFn: () => savedReportsService.getAll(clientCode),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })
  return (
    <Dialog isOpen={open} onClose={onClose}>
      <DialogTitle>{t('savedReports.singleReport.dashboard.existingDashboardTitle')}</DialogTitle>
      <DialogBody>
        <div className="flex justify-center">
          <FavoriteList
            isLoading={isLoading || !isFetched}
            items={data?.result.filter((item) => item.filters?.graph !== 'DETAILED_OCCUPANCY_TABLE')}
            onItemClick={onItemClick}
          />
        </div>
        <div className="mt-8 flex justify-end space-x-4"></div>
      </DialogBody>
    </Dialog>
  )
}

export default SearchReportDialog

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import {
  FOCUS_ROOM,
  graphsStructure,
  detailedOccupancyValue,
  MEETING_AND_FOCUS,
  MEETING_ROOM,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  utilizationQualityValue,
  workstation,
  WORKSTATION,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'
import { getFilterType, isMonthlyGraph } from 'utils/filtersUtils'

export const fullValues = [MEETING_AND_FOCUS, FOCUS_ROOM, MEETING_ROOM, WORKSTATION]

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.ROOM_TYPE.value === value) return state
  const isRoomTypeWorkstation = value === workstation
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isMonthly = state.GRAPH.value === monthlyValue
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    FLOOR: state.FLOOR,
    COMPARTMENT: state.COMPARTMENT,
    KPI: state.KPI,
    LEVEL: state.LEVEL,
    MONITORING_ENTITY: state.MONITORING_ENTITY,
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    ROOM_TYPE: {
      ...state.ROOM_TYPE,
      value,
      active: !value,
      required: isMonthlyGraph(state.GRAPH),
    },
    BUSINESS_UNITS: {
      ...initialFilterState.BUSINESS_UNITS,
      active: isMonthly && !!isRoomTypeWorkstation,
    },
    DETAILED_OCCUPANCY: {
      ...initialFilterState.DETAILED_OCCUPANCY,
      active: isDetailedOccupancy,
    },
    QUARTER: {
      ...initialFilterState.QUARTER,
      active: !isDetailedOccupancy && !!value,
    },
    CUSTOM_ATTRIBUT_COLLAB_VALUE: {
      ...initialFilterState.CUSTOM_ATTRIBUT_COLLAB_VALUE,
      active: isDetailedOccupancy,
    },
  }
}

const RoomTypeFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const features = selectedDomain?.features
  const specificCollaborativeSpaceNatures = selectedDomain?.specificCollaborativeSpaceNatures
  const filteredRoomTypes = []
  const lang = navigator.language
  const formattedSpecificCollaborativeSpaceNatures =
    specificCollaborativeSpaceNatures && specificCollaborativeSpaceNatures!.length > 0
      ? specificCollaborativeSpaceNatures!.map((spaceNature) => {
          return {
            value: spaceNature.code,
            label: lang === 'fr-FR' || lang === 'fr' ? spaceNature.i18nFR : spaceNature.i18nEN,
          }
        })
      : []
  const isTopFlop = state.GRAPH.value === topFlopValue
  const isUtilizationOfSpace = state.GRAPH.value === stackedColumnValue
  const isUtilizationQuality = state.GRAPH.value === utilizationQualityValue
  const isBenchmark = state.GRAPH.value === benchmarkValue

  if (features && features.meetingRoom && features.focusRoom && state.ENTRY.value !== 'DESKTOP' && !isBenchmark)
    filteredRoomTypes.push(MEETING_AND_FOCUS)

  if (
    specificCollaborativeSpaceNatures &&
    specificCollaborativeSpaceNatures!.length > 0 &&
    formattedSpecificCollaborativeSpaceNatures.length > 0 &&
    state.ENTRY.value !== 'DESKTOP' &&
    !isBenchmark
  ) {
    formattedSpecificCollaborativeSpaceNatures.map((elt) => filteredRoomTypes.push(elt))
  } else {
    if (features && features.focusRoom && state.ENTRY.value !== 'DESKTOP' && !isBenchmark)
      filteredRoomTypes.push(FOCUS_ROOM)
    if (features && features.meetingRoom && state.ENTRY.value !== 'DESKTOP' && !isBenchmark)
      filteredRoomTypes.push(MEETING_ROOM)
  }

  if (!isTopFlop && !isUtilizationOfSpace && !isUtilizationQuality && !isBenchmark && state.ENTRY.value === 'DESKTOP')
    filteredRoomTypes.push(WORKSTATION)
  if (isBenchmark)
    filteredRoomTypes.push(
      {
        label: 'graph.filters.roomType.allCollaborativeSpaces',
        value: 'ALL_COLLABORATIVE_SPACES',
      },
      {
        label: 'graph.filters.roomType.allWorkstations',
        value: 'ALL_WORKSTATIONS',
      },
    )

  const roomTypeValue = state.ROOM_TYPE.value

  const options = (filteredRoomTypes || []).map((option) => ({
    ...option,
    label: t(option.label),
  }))

  const roomTypeData = options.find((option) => option.value === roomTypeValue)
  const type = getFilterType(state.GRAPH.value, state.ROOM_TYPE.active, roomTypeData)
  if (options.length === 1) {
    state.QUARTER.active = true
    state.CUSTOM_ATTRIBUT_COLLAB_VALUE.active = true
    state.CUSTOM_ATTRIBUT_WKS_VALUE.active = true
    state.ROOM_TYPE.value = options[0].value
    state.DETAILED_OCCUPANCY.active = true
    if (state.GRAPH.value === monthlyValue) {
      state.BUSINESS_UNITS.active = true
      if (
        (state.FLOOR.value !== undefined && state.FLOOR.value !== '[ALL]') ||
        (state.FLOOR.value !== undefined && state.FLOOR.value == '[ALL]' && state.GRAPH.value === monthlyValue)
      )
        state.QUARTER.active = true
    }
  }

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      isBenchmark={isBenchmark}
      type={type}
      placeholder={t(graphsStructure.roomType.placeholder)}
      label={roomTypeData?.label}
      disabled={!state.ROOM_TYPE.active && !state.ROOM_TYPE.value}
      selected={roomTypeValue}
      onSelect={onChange}
      invisible={state.ENTRY.value === 'DESKTOP'}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default RoomTypeFilter

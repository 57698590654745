import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

const CollaborativeSpacesUtilization: FC = (props) => {
  const { data } = props
  const { t } = useTranslation()
  const filterResult = (data, value: string) => {
    return data.filter((item) => item.utilizationQuality == value)[0]
  }
  const fillArray = (value) => {
    return [
      data.small ? filterResult(data.small, value).utilizationQualityRate : '',
      data.medium ? filterResult(data.medium, value).utilizationQualityRate : '',
      data.large ? filterResult(data.large, value).utilizationQualityRate : '',
    ]
  }
  const emptyData = data ? fillArray('EMPTY') : []
  const underOccupiedData = data ? fillArray('UNDER_OCCUPIED') : []
  const balancedData = data ? fillArray('BALANCED') : []
  const saturatedData = data ? fillArray('SATURATED') : []
  const series =
    data.length == 0
      ? [
          { name: '', data: [12, 35, 27] },

          { name: '', data: [25, 8, 12] },

          { name: '', data: [25, 28, 22] },

          { name: '', data: [10, 15, 7] },
        ]
      : [
          { name: t('landingPage.collaborativeSpacesUtilization.empty'), data: emptyData },
          { name: t('landingPage.collaborativeSpacesUtilization.under-occupied'), data: underOccupiedData },
          { name: t('landingPage.collaborativeSpacesUtilization.balanced'), data: balancedData },
          { name: t('landingPage.collaborativeSpacesUtilization.saturated'), data: saturatedData },
        ]
  const options = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      type: 'bar',
      stacked: true,
    },
    legend: {
      show: data.length !== 0,
      position: 'right',
      offsetX: 0,
      offsetY: 150,
      inverseOrder: true,
      markers: {
        width: 39,
        height: 16,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 4,
        customHTML: undefined,
        onClick: undefined,
      },
      itemMargin: {
        horizontal: 0,
        vertical: 10,
      },
    },
    colors:
      data.length == 0
        ? [COLORS.darkGrey30, COLORS.darkGrey50]
        : [COLORS.blue, COLORS.deepCerise50, COLORS.deepCerise, COLORS.darkGrey],
    plotOptions: {},
    dataLabels: {
      enabled: data.length !== 0,
      position: 'center',
      formatter: (value) => Math.round(value) + '%',
      offsetX: 0,
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          strokeDashArray: 4,
          borderColor: COLORS.darkGrey80,
          fillColor: COLORS.darkGrey80,
          opacity: 0.8,
          offsetX: 0,
          offsetY: 0,
        },
      ],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: function (value) {
          if (value == 0 || value == 50 || value == 100) return value + '%'
        },
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      enabled: data.length !== 0,
      style: {
        fontSize: '12px',
        fontFamily: undefined,
        backgroundColor: COLORS.darkGrey80,
      },
      x: {
        show: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: function (val, obj) {
            return obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex] + '%'
          },
        },
      },
      marker: {
        show: false,
      },
    },
    title: {
      text: t('landingPage.collaborativeSpacesUtilization.title'),
      style: {
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: 'Roboto',
        color: '#4F4F64',
      },
    },
    xaxis: {
      categories:
        !data.small && !data.medium && !data.large
          ? [
              t('landingPage.collaborativeSpacesUtilization.small'),
              t('landingPage.collaborativeSpacesUtilization.medium'),
              t('landingPage.collaborativeSpacesUtilization.large'),
            ]
          : [
              data.small ? t('landingPage.collaborativeSpacesUtilization.small') : '',
              data.medium ? t('landingPage.collaborativeSpacesUtilization.medium') : '',
              data.large ? t('landingPage.collaborativeSpacesUtilization.large') : '',
            ].filter((elt) => elt !== ''),
      axisBorder: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  return (
    <div id="collaborativeSpacesUtilization" className="shadowSection collaborativeSpacesUtilization h-full">
      <div data-tip data-for="collaborativeSpacesKpi" className="info-tooltip" style={{ zIndex: 1 }}>
        <IconSvg name="FILLED_INFO" color="GREY30" className="mr-2" />
      </div>
      <ReactTooltip id="collaborativeSpacesKpi" place="bottom">
        {t('landingPage.collaborativeSpacesUtilization.kpi')}
      </ReactTooltip>
      <ReactApexChart
        options={options}
        series={series.map((elt) => {
          return { name: elt.name, data: elt.data.filter((el) => el !== '') }
        })}
        type="bar"
        height={'100%'}
      />
    </div>
  )
}
export default CollaborativeSpacesUtilization

import { toast } from 'react-toastify'
import { AxiosError, AxiosResponse } from 'axios'
import { MAX_TIMEOUT } from '../constants/config'
import i18next from 'i18next'
import { getApiKey } from 'utils/urlUtils'
import { cookies } from 'core/cookies'
import { client } from 'core/http/client'

const request = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: FormData | Record<string, unknown>,
  headers?: Record<string, string | undefined>,
  params?: Record<string, unknown>,
): Promise<T> => {
  return await client
    .request<T>({
      url,
      method: method || (body ? 'POST' : 'GET'),
      headers,
      data: body instanceof FormData ? body : JSON.stringify(body),
      params,
      timeout: MAX_TIMEOUT,
      withCredentials: true,
      // responseType: 'arraybuffer',
    })
    .then((res: AxiosResponse<T>) => res.data)
    .catch((res: AxiosError) => {
      if (res.response) {
        switch (res.response.data.error?.type) {
          case 'INCOMPATIBLE_DATA':
            toast.error(i18next.t('api.incompatible_data'))
            break
          case 'NAME_ALREADY_EXISTS':
            toast.error(i18next.t('api.name_already_exist'))
            break
          case 'ID_NOT_FOUND':
            toast.error(i18next.t('api.id_not_found'))
            break
          case 'FORBIDDEN_DOMAIN':
            toast.error(i18next.t('api.forbidden_domain'))
            break
          case 'FORBIDDEN_REPORT':
            toast.error(i18next.t('api.forbidden_report'))
            break
          case 'FORBIDDEN_DASHBOARD':
            toast.error(i18next.t('api.forbidden_dashboard'))
            break
          case 'REPORTS_COUNT_PER_PAGE_EXCEEDED':
            toast.error(i18next.t('api.reports_exceeded'))
            break
          case 'INCORRECT_REPORT_POSITION':
            toast.error(i18next.t('api.incorrect_report_position'))
            break
          case 'FORBIDDEN_DELETE_REPORT':
            toast.error(i18next.t('api.forbidden_delete_report'))
            break
          case 'DASHBOARD_ALREADY_LOCKED':
            toast.error(i18next.t('api.dashboard_already_locked'))
            break
          case 'REPORT_ALREADY_LOCKED':
            toast.error(i18next.t('api.report_already_locked'))
            break
          case 'RESTAURANT_NOT_FOUND':
            toast.error(i18next.t('api.restaurant_not_found'))
            break
          case 'FORBIDDEN_RESTAURANT':
            toast.error(i18next.t('api.forbidden_restaurant'))
            break
        }
        switch (res.response.status) {
          case 400:
            toast.error(i18next.t('api.bad_request'))
            break
          case 401:
            // noop, case handled in interceptor
            break
          case 403:
            cookies.get('fa2_token')
              ? null
              : res.response.data.error === 'fa2_required'
              ? null
              : toast.error(i18next.t('api.forbidden'))
            break
          case 404:
            toast.error(i18next.t('api.not_found'))
            break
          case 409:
            toast.error(i18next.t('api.conflict'))
            break
          case 422:
            toast.error(res.response.data.error.details)
            break
          case 423:
            toast.error(i18next.t('api.locked'))
            break
          case 500:
            toast.error(i18next.t('api.internal_server_error'))
            break
        }
      }
      throw res
    })
}

const requestArrayBuffer = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: FormData | Record<string, unknown>,
  headers?: Record<string, string | undefined>,
  params?: Record<string, unknown>,
): Promise<T> => {
  return await client
    .request<T>({
      url,
      method: method || (body ? 'POST' : 'GET'),
      headers,
      data: body instanceof FormData ? body : JSON.stringify(body),
      params,
      timeout: MAX_TIMEOUT,
      withCredentials: true,
      responseType: 'arraybuffer',
    })
    .then((res: AxiosResponse<T>) => res.data)
    .catch((res: AxiosError) => {
      if (res.response) {
        switch (res.response.data.error?.type) {
          case 'INCOMPATIBLE_DATA':
            toast.error(i18next.t('api.incompatible_data'))
            break
          case 'NAME_ALREADY_EXISTS':
            toast.error(i18next.t('error.api.name_already_exist'))
            break
          case 'ID_NOT_FOUND':
            toast.error(i18next.t('error.api.id_not_found'))
            break
          case 'FORBIDDEN_DOMAIN':
            toast.error(i18next.t('error.api.forbidden_domain'))
            break
          case 'FORBIDDEN_REPORT':
            toast.error(i18next.t('error.api.forbidden_report'))
            break
          case 'FORBIDDEN_DASHBOARD':
            toast.error(i18next.t('error.api.forbidden_dashboard'))
            break
          case 'REPORTS_COUNT_PER_PAGE_EXCEEDED':
            toast.error(i18next.t('error.api.reports_exceeded'))
            break
          case 'INCORRECT_REPORT_POSITION':
            toast.error(i18next.t('error.api.incorrect_report_position'))
            break
          case 'FORBIDDEN_DELETE_REPORT':
            toast.error(i18next.t('error.api.forbidden_delete_report'))
            break
          case 'DASHBOARD_ALREADY_LOCKED':
            toast.error(i18next.t('error.api.dashboard_already_locked'))
            break
          case 'REPORT_ALREADY_LOCKED':
            toast.error(i18next.t('error.api.report_already_locked'))
            break
          case 'RESTAURANT_NOT_FOUND':
            toast.error(i18next.t('error.api.restaurant_not_found'))
            break
          case 'FORBIDDEN_RESTAURANT':
            toast.error(i18next.t('error.api.forbidden_restaurant'))
            break
        }
        switch (res.response.status) {
          case 400:
            toast.error(i18next.t('api.bad_request'))
            break
          case 401:
            // noop, case handled in interceptor
            break
          case 403:
            cookies.get('fa2_token') ? null : toast.error(i18next.t('api.forbidden'))
            break
          case 404:
            toast.error(i18next.t('api.not_found'))
            break
          case 409:
            toast.error(i18next.t('api.conflict'))
            break
          case 422:
            toast.error(res.response.data.error.details)
            break
          case 423:
            toast.error(i18next.t('api.locked'))
            break
          case 500:
            toast.error(i18next.t('api.internal_server_error'))
            break
        }
      }
      throw res
    })
}

export const execute = async <T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: FormData | Record<string, unknown>,
  headers: Record<string, string> = {},
  params?: Record<string, unknown>,
): Promise<T> => {
  const baseHeaders = {
    'Content-Type': body instanceof FormData ? 'application/x-www-form-urlencoded' : 'application/json',
    ...headers,
  }

  // We don't want to send the "authorization" header on calls like login, forgot password, reset password
  const token = getApiKey() || cookies.get('access_token')
  const apiHeaders = !token
    ? baseHeaders
    : {
        ...baseHeaders,
        Authorization: token ? `Bearer ${token}` : undefined,
      }
  if (path.includes('/map')) {
    return requestArrayBuffer<T>(path, method, body, apiHeaders, params)
  } else {
    return request<T>(path, method, body, apiHeaders, params)
  }
}

import { LocalizationInformationDto } from 'core/api/models/localizationInformationDto'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'

const getRealTimeInfos = (clientCode: string, path: string, apiKey?: string): Promise<SensorStatusDto[]> => {
  if (apiKey) {
    return execute(generatePath(API.ANALYSIS.LOCALIZATION_STATUS_GW, { clientCode, path }), 'GET', undefined, {
      'X-Authentication-Type': 'APIKey',
    })
  }

  return execute(generatePath(API.ANALYSIS.LOCALIZATION_STATUS, { clientCode, path }), 'GET')
}

const getDomain = (clientCode: string, path: string, apiKey?: string): Promise<LocalizationInformationDto> => {
  if (apiKey) {
    return execute(generatePath(API.ANALYSIS.LOCALIZATION_GW, { clientCode, path }), 'GET', undefined, {
      'X-Authentication-Type': 'APIKey',
    })
  }

  return execute(generatePath(API.ANALYSIS.LOCALIZATION, { clientCode, path }), 'GET')
}

export const realTimeService = { getRealTimeInfos, getDomain }

import { FC, useContext, useState, useEffect } from 'react'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'

import { useTranslation } from 'react-i18next'
import { DomainsStructure } from 'types/DomainsStructure'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FILTER_ARROW_ICONS, FILTER_STYLE } from 'styles/Filters'
import { detailedOccupancyTableValue } from 'utils/constants/graphs/graphsStructure'
import { isCountingMaxGraph } from 'utils/filtersUtils'

export const workstationNatures = [
  'ALL_WORKSTATIONS',
  'OPEN_SPACE',
  'INDIVIDUAL_OFFICE',
  'WORKSTATION',
  'SHARED_OFFICE',
  'COMMON_SPACE',
]

const handleStateChange = (state: FilterState, structure: DomainsStructure, selected?: any): FilterState => {
  if (selected.length > 0 && state.MONITORING_ROOM_TYPE.value === selected[0].value) return state
  const arrayOfValues = selected.map((elt: any) => elt.value)
  const isMonitoring = state.GRAPH.value === 'MONITORING'
  const hasWorkstation = selected.some((s: { value: string }) => workstationNatures.includes(s.value))
  const hasMonitoringSpaceTypeSelected = selected.length > 0

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    FLOOR: state.FLOOR,
    COMPARTMENT: state.COMPARTMENT,
    QUARTER: {
      ...state.QUARTER,
      active: isCountingMaxGraph(state.GRAPH),
    },
    BUILDING: { ...state.BUILDING, required: !detailedOccupancyTableValue },
    KPI: {
      ...state.KPI,
      required: isMonitoring,
      active: !!selected,
      value: hasWorkstation || !hasMonitoringSpaceTypeSelected ? undefined : state.KPI.value,
    },
    ROOM_TYPE: {
      ...initialFilterState.KPI,
      required: false,
    },
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ROOM_TYPE: {
      ...state.MONITORING_ROOM_TYPE,
      value: arrayOfValues,
      active: selected,
    },
    MONITORING_ENTITY: {
      ...initialFilterState.MONITORING_ENTITY,
      active: workstationNatures.some((i) => arrayOfValues.includes(i)),
    },
    CUSTOM_ATTRIBUT_WKS: {
      ...initialFilterState.CUSTOM_ATTRIBUT_WKS,
      active: arrayOfValues.length > 0 && arrayOfValues.filter((elt) => workstationNatures.includes(elt)).length > 0,
    },
    CUSTOM_ATTRIBUT_COLLAB: {
      ...initialFilterState.CUSTOM_ATTRIBUT_COLLAB,
      active: arrayOfValues.length > 0 && arrayOfValues.filter((elt) => !workstationNatures.includes(elt)).length > 0,
    },
  }
}
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{
            accentColor: '#D33087',
            width: '12px',
            height: '12px',
            marginRight: '5px',
            borderBottom: '1px',
            borderColor: 'red',
          }}
        />{' '}
        <label
          style={{ color: props.disabled ? ' #9C9CB5' : '#74748D', opacity: '1', fontWeight: '500', fontSize: '1rem' }}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  )
}

const monitoringScopeFilter: FC = () => {
  const { t } = useTranslation()
  const [optionSelected, setOptionSelected] = useState<Array<{ value: string; label: string }> | null>(null)
  const [open, setOpen] = useState(false)
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const { openIcon, closeIcon, ...iconColors } = FILTER_ARROW_ICONS
  const isMonitoring = state.GRAPH.value === 'MONITORING'
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const specificCollaborativeSpaceNatures = selectedDomain?.specificCollaborativeSpaceNatures
  const lang = navigator.language
  const formattedSpecificCollaborativeSpaceNatures =
    specificCollaborativeSpaceNatures && specificCollaborativeSpaceNatures!.length > 0
      ? specificCollaborativeSpaceNatures!.map((spaceNature) => ({
          value: spaceNature.code,
          label: lang === 'fr-FR' || lang === 'fr' ? spaceNature.i18nFR : spaceNature.i18nEN,
        }))
      : []

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconSvg name={open ? openIcon : closeIcon} color={iconColors[type]} className="ml-2" />{' '}
        </components.DropdownIndicator>
      )
    )
  }
  const workstationNatures = [
    { value: 'ALL_WORKSTATIONS', label: t('graph.filters.roomType.allWorkstations') },
    { value: 'OPEN_SPACE', label: t('graph.filters.roomType.openSpace') },
    { value: 'INDIVIDUAL_OFFICE', label: t('graph.filters.roomType.individuelOffice') },
    { value: 'SHARED_OFFICE', label: t('graph.filters.roomType.sharedOffice') },
    { value: 'COMMON_SPACE', label: t('graph.filters.roomType.commonSpace') },
  ]
  state.MONITORING_ROOM_TYPE.active = true
  const type = //@ts-ignore
    optionSelected !== null && optionSelected.length > 0
      ? 'PRIMARY'
      : state.MONITORING_ROOM_TYPE.active
      ? 'OUTLINED_BLACK'
      : 'OUTLINED'
  const handleChange = (selected: any) => {
    setOptionSelected(selected)
    onFilterChange(handleStateChange(state, structure, selected))
  }
  useEffect(() => {
    if (state.MONITORING_ROOM_TYPE.value && state.MONITORING_ROOM_TYPE.value.length == 0) setOptionSelected(null)
  }, [state.MONITORING_ROOM_TYPE.value])

  const roomTypesOptions: Array<{ value: string; label: string }> = []
  if (
    specificCollaborativeSpaceNatures &&
    specificCollaborativeSpaceNatures!.length > 0 &&
    formattedSpecificCollaborativeSpaceNatures.length > 0
  ) {
    formattedSpecificCollaborativeSpaceNatures.map((elt) => roomTypesOptions.push(elt))
    isMonitoring
      ? roomTypesOptions.push(...workstationNatures)
      : !isCountingMaxGraph(state.GRAPH)
      ? roomTypesOptions.push({ value: 'WORKSTATION', label: t('graph.filters.roomType.workstation') })
      : roomTypesOptions.push({ value: 'OPEN_SPACE', label: t('graph.filters.roomType.openSpace') })
  } else {
    isMonitoring
      ? roomTypesOptions.push(
          { value: 'ALL_COLLABORATIVE_SPACES', label: t('graph.filters.roomType.allCollaborativeSpaces') },
          { value: 'MEETING_ROOM', label: t('graph.filters.roomType.meetingRoom') },
          { value: 'FOCUS_ROOM', label: t('graph.filters.roomType.focus') },
          ...workstationNatures,
        )
      : isCountingMaxGraph(state.GRAPH)
      ? roomTypesOptions.push(
          { value: 'ALL_COLLABORATIVE_SPACES', label: t('graph.filters.roomType.allCollaborativeSpaces') },
          { value: 'MEETING_ROOM', label: t('graph.filters.roomType.meetingRoom') },
          { value: 'FOCUS_ROOM', label: t('graph.filters.roomType.focus') },
          { value: 'OPEN_SPACE', label: t('graph.filters.roomType.openSpace') },
        )
      : roomTypesOptions.push(
          { value: 'ALL_COLLABORATIVE_SPACES', label: t('graph.filters.roomType.allCollaborativeSpaces') },
          { value: 'MEETING_ROOM', label: t('graph.filters.roomType.meetingRoom') },
          { value: 'FOCUS_ROOM', label: t('graph.filters.roomType.focus') },
          { value: 'WORKSTATION', label: t('graph.filters.roomType.workstation') },
        )
  }

  const style = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: open ? 'white' : '#4F4F64',
        backgroundColor: 'transparent',
      }
    },
    control: (base: any, state: any) => ({
      ...base,
      borderWidth: 0,
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      // minWidth: '3rem',
      color: 'white',
      '&:hover': {
        border: 'none',
      },
    }),
    //@ts-ignore
    option: (base, state) => ({
      color: state.selected ? 'white' : '#74748D',
      borderBottom: '20px',
      borderColor: 'red',
      padding: '0.5rem',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      color: 'white',
      padding: '3px',
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    menu: (base: any) => ({
      ...base,
      width: 'max-content',
      minWidth: '100%',
      padding: '5px',
      borderRadius: '8px',
      borderColor: '#c6c6de',
      borderWidth: '2px',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    }),
  }
  const disabled = false
  const filterType = open ? 'BLACK' : type
  useEffect(() => {
    if (state.MONITORING_ROOM_TYPE.value)
      setOptionSelected(roomTypesOptions.filter((el) => state.MONITORING_ROOM_TYPE.value.includes(el.value)))
  }, [])

  return (
    <span data-toggle="popover" data-trigger="focus" className="mr-2">
      <ReactSelect
        options={roomTypesOptions}
        isMulti
        className={`inline-flex min-h-13 items-center mb-2 px-2 pb-1 rounded-lg border-2 text-b2 font-bold ${
          FILTER_STYLE[filterType]
        } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none`}
        styles={style}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          DropdownIndicator,
        }}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        onChange={handleChange}
        value={optionSelected}
        placeholder={<div>{t('graph.monitoring.roomType')}</div>}
      />
    </span>
  )
}

export default monitoringScopeFilter

import { bpConvert } from 'utils/breakPointsUtils'
import { useTranslation } from 'react-i18next'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import ReactTooltip from 'react-tooltip'
import IconSvg from 'components/atoms/icons/IconSvg'
import Gauge from '../../atoms/gauge/Gauge'

const numberOfColumns = 1

type BuildingTableProps = {
  sensor?: SensorStatusDto
  capacity?: number
  name?: string
}

const BuildingTable = ({ name, sensor, capacity }: BuildingTableProps): JSX.Element => {
  const { t } = useTranslation()

  if (!(sensor?.tally && capacity)) {
    return <></>
  }
  const renderCapacityGauge = (tally: number, capacity?: number) => {
    const unavailablePercentage = capacity ? (tally / capacity) * 100 : undefined

    if (unavailablePercentage === undefined) {
      return <div className="bg-basic-500 h-2 w-2/3 rounded-lg overflow-hidden"></div>
    }

    return <Gauge percentage={unavailablePercentage} />
  }

  const renderGaugeIndicator = (occupation: number, capacity: number) => {
    return (
      <div className="flex justify-center items-center w-full bg-basic-100 p-2">
        <div className="flex justify-center items-center flex-1 flex-col">
          <span className="text-sm text-basic mb-1">
            {occupation} / {capacity ?? '?'}
          </span>
          {renderCapacityGauge(occupation, capacity)}
        </div>
      </div>
    )
  }

  return (
    <div
      className="grid gap-0.5 w-max bg-basic items-center grid-rows-1 border-basic border-2 text-basic"
      style={{
        gridTemplateColumns: `160px repeat(${numberOfColumns}, minmax(160px, max-content))`,
        gridTemplateRows: `minmax(${bpConvert(window.innerWidth, 70)}px, auto)`,
        gridAutoRows: 'auto',
        // maxHeight: maxHeight ?? 'none',
        // overflow: maxHeight ? 'auto' : 'visible',
      }}
    >
      <div className="justify-center bg-basic-100 font-semibold h-full w-full flex items-center">
        {t('overviewSupervisor.buildingTitle')}
      </div>
      <div
        data-tip
        data-for="overviewSupervisor.gaugeBuildingInfos"
        className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 px-2"
      >
        {t('overviewSupervisor.gauge')}
        <IconSvg className="items-center" name="HELP" />
      </div>
      <ReactTooltip effect="solid" id="overviewSupervisor.gaugeBuildingInfos">
        {t('overviewSupervisor.gaugeBuildingInfos')}
      </ReactTooltip>
      <div className="p-2 flex items-center w-full h-full bg-basic-100">
        <p className="truncate text-left" title={name}>
          {name}
        </p>
      </div>
      {renderGaugeIndicator(sensor?.tally, capacity)}
    </div>
  )
}

export default BuildingTable

import { FC } from 'react'
import { useMutation } from 'react-query'
import { authService, getNoSSORedirectUri } from 'services/authService'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { useTranslation } from 'react-i18next'
import { UserData, UserEmail } from 'types/Auth'
import Button from 'components/atoms/button/Button'
import FormInput from 'components/atoms/input/FormInput'
import { inputs, emailValidationSchema } from 'utils/constants/forms/login'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'assets/colors/colors'
import LoginPageLayout from 'components/layouts/LoginPageLayout'

const Login: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(emailValidationSchema),
  })

  const { mutate, isLoading } = useMutation({
    mutationKey: QUERY_KEYS.AUTH.PRE_LOGIN,
    mutationFn: authService.getPreLogin,
    onSuccess: (data: { redirectionUrl: string }, variables: UserEmail) => {
      if (data.redirectionUrl === getNoSSORedirectUri(variables)) {
        history.push(data.redirectionUrl)
      } else {
        // redirect to SSO
        window.location.assign(data.redirectionUrl)
      }
    },
  })

  const onSubmit = async (data: UserData) => {
    mutate(data)
  }

  return (
    <LoginPageLayout>
      <FormProvider {...form}>
        <form className="login-form" onSubmit={form.handleSubmit(onSubmit)}>
          <h4 style={{ fontWeight: 'bold', fontSize: '34px', textAlign: 'center' }}>{t('auth.login.login')}</h4>
          <label style={{ textTransform: 'uppercase', fontSize: '12px', margin: '0 0.75rem' }}>
            {t(inputs[0].placeholder)}
          </label>
          <FormInput
            key={inputs[0].name}
            name={inputs[0].name}
            placeholder={t(inputs[0].placeholder)}
            className="mb-6"
            type={inputs[0].type}
          />

          <div className="flex justify-center">
            <Button
              style={{ backgroundColor: COLORS.deepCerise, marginBottom: '0px' }}
              type="submit"
              disabled={isLoading}
            >
              {t('auth.login.sendEmail')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </LoginPageLayout>
  )
}

export default Login

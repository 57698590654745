import { LocalizationInformationDto } from 'core/api/models/localizationInformationDto'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'

const getRestaurantInfos = (clientCode: string, path: string): Promise<SensorStatusDto[]> => {
  return execute(generatePath(API.ANALYSIS.RIE_VIEW_STATUS, { clientCode, path }), 'GET', undefined, {
    'X-Authentication-Type': 'APIKey',
  })
}

const getRestaurantDomain = (clientCode: string, path: string): Promise<LocalizationInformationDto> => {
  return execute(generatePath(API.ANALYSIS.RIE_VIEW, { clientCode, path }), 'GET', undefined, {
    'X-Authentication-Type': 'APIKey',
  })
}

export const rieService = { getRestaurantInfos, getRestaurantDomain }

import { ReactElement } from 'react'
import Chart from 'react-apexcharts'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'

const ReactApexChart = <T,>(props: ChartProps<T>): ReactElement => {
  const { series, noContextMenu, height, width, options, ...rest } = props
  return (
    <Chart
      series={series as never}
      height={height}
      width={width}
      options={options}
      onContextMenu={(e: MouseEvent) => {
        if (noContextMenu) e.preventDefault()
      }}
      {...rest}
    />
  )
}

export default ReactApexChart

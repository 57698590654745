import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { optionAllValue } from 'utils/constants/graphs/global'
import { useTranslation } from 'react-i18next'
import {
  calendarDayValue,
  CURRENT_MONTH,
  graphsStructure,
  detailedOccupancyValue,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_TWELVE_MONTHS,
  BY_WEEK,
  BY_MONTH,
  BY_YEAR,
  LAST_WEEK,
  LAST_TWELVE_WEEKS,
  BY_DAY,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  weekDayValue,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
  calibratedUtilizationValue,
  roomBookingValue,
  benchmarkValue,
  CAPACITY_TYPE_DETAILED,
  countingMaxValue,
  businessUnitValue,
  floorValue,
} from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType, isDetailedOccupancyTableGraph, isCountingMaxGraph, isMonthlyGraph } from 'utils/filtersUtils'

const baseValues = [LAST_WEEK, LAST_MONTH, LAST_QUARTER, LAST_TWELVE_MONTHS]
const getQuarterValues = (state: FilterState) => {
  switch (state.GRAPH.value) {
    case topFlopValue:
    case stackedColumnValue:
      return baseValues

    case detailedOccupancyValue: {
      if (state.DETAILED_OCCUPANCY.value === calendarDayValue) {
        return [CURRENT_MONTH, LAST_MONTH]
      } else {
        const quarters = []
        if (state.DETAILED_OCCUPANCY.value === businessUnitValue || state.DETAILED_OCCUPANCY.value === floorValue) {
          quarters.push(CURRENT_MONTH)
        }
        if (state.KPI.value !== 'NINTH_DECILE' && state.KPI.value !== 'PERCEIVED_NINTH_DECILE') {
          quarters.push(LAST_MONTH)
        }
        quarters.push(LAST_QUARTER, LAST_TWELVE_MONTHS)
        return quarters
      }
    }

    case monthlyValue:
      return [LAST_QUARTER, LAST_TWELVE_MONTHS]

    case RIE:
      return [CURRENT_MONTH, LAST_MONTH, LAST_TWELVE_WEEKS, LAST_TWELVE_MONTHS]

    case frequentationValue:
      return [CURRENT_MONTH, LAST_MONTH]

    case spaceAttendanceValue:
      return state.KPI.value == 'NINTH_DECILE'
        ? [LAST_TWELVE_MONTHS]
        : [CURRENT_MONTH, LAST_MONTH, LAST_TWELVE_WEEKS, LAST_TWELVE_MONTHS]
    case utilizationQualityValue:
      return [LAST_MONTH, LAST_QUARTER, LAST_TWELVE_MONTHS]
    case detailedOccupancyTableValue:
      return [BY_DAY, BY_WEEK, BY_MONTH, BY_YEAR]
    case heatmapValue:
      return [BY_DAY, BY_WEEK, BY_MONTH, BY_YEAR]
    case calibratedUtilizationValue:
      return [BY_DAY, BY_WEEK, BY_MONTH]
    case monitoringValue:
      return state.KPI.value == 'NINTH_DECILE' || state.KPI.value == 'PERCEIVED_NINTH_DECILE'
        ? [BY_MONTH]
        : [BY_DAY, BY_WEEK, BY_MONTH]
    case roomBookingValue:
      return [LAST_MONTH, LAST_QUARTER, LAST_TWELVE_MONTHS]
    case benchmarkValue:
      return [BY_DAY, BY_WEEK, BY_MONTH]
    case countingMaxValue:
      return [CURRENT_MONTH, LAST_MONTH]

    default:
      return baseValues
  }
}

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.QUARTER.value === value) return state
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isMonthly = state.GRAPH.value === monthlyValue
  const isFloorCounting = state.SPACE_TYPE.value === 'FLOOR'
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const isByCalendarDays = state.DETAILED_OCCUPANCY.value === calendarDayValue
  const isByWeekDays = state.DETAILED_OCCUPANCY.value === weekDayValue
  if (isBenchmark && value) state.KPI.active = true

  // If in that building there is only one floor, then we want to automatically set the FLOOR value.
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const compartments = selectedDomain?.compartments

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    COMPARTMENT:
      isMonthly && compartments == undefined ? { ...initialFilterState.COMPARTMENT, value: null } : state.COMPARTMENT,
    DETAILED_OCCUPANCY: state.DETAILED_OCCUPANCY,
    KPI: state.KPI,
    BUSINESS_UNITS: state.BUSINESS_UNITS,
    ROOM_TYPE: {
      ...state.ROOM_TYPE,
      required: isMonthlyGraph(state.GRAPH),
    },
    SPACE_TYPE: state.SPACE_TYPE,
    MONITORING_ROOM_TYPE: {
      ...state.MONITORING_ROOM_TYPE,
      required: isDetailedOccupancyTableGraph(state.GRAPH) || isCountingMaxGraph(state.GRAPH),
    },
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ENTITY: state.MONITORING_ENTITY,
    LEVEL: state.LEVEL,
    CUSTOM_ATTRIBUT_COLLAB: state.CUSTOM_ATTRIBUT_COLLAB,
    CUSTOM_ATTRIBUT_COLLAB_VALUE: state.CUSTOM_ATTRIBUT_COLLAB_VALUE,
    CUSTOM_ATTRIBUT_WKS: state.CUSTOM_ATTRIBUT_WKS,
    CUSTOM_ATTRIBUT_WKS_VALUE: state.CUSTOM_ATTRIBUT_WKS_VALUE,
    QUARTER: {
      ...state.QUARTER,
      value,
      active:
        !value ||
        state.GRAPH.value == 'RIE' ||
        state.GRAPH.value == frequentationValue ||
        state.GRAPH.value == detailedOccupancyTableValue,
    },
    ROOM_SIZE_TYPE: {
      ...initialFilterState.ROOM_SIZE_TYPE,
      value:
        state.GRAPH.value === stackedColumnValue ? CAPACITY_TYPE_DETAILED : initialFilterState.ROOM_SIZE_TYPE.value,
    },

    FLOOR: {
      ...state.FLOOR,
      required:
        (isFloorCounting && isSpaceAttendance) ||
        ((isByCalendarDays || isByWeekDays) && state.BUILDING.value !== optionAllValue),
      active:
        (isFloorCounting && isSpaceAttendance) ||
        ((isByCalendarDays || isByWeekDays) && isDetailedOccupancy && !!value),
    },
    RESTAURANT: state.RESTAURANT,
  }
}

const QuarterFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const quarterValue = state.QUARTER.value
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const options = getQuarterValues(state).map((option) => {
    if (option.name) {
      return {
        ...option,
        label: t(option.name),
        disabled: option.disabled,
      }
    }
    return {
      ...option,
      label: t(option.label),
    }
  })
  const quarterData = options.find((option) => option.value === quarterValue)
  const type = getFilterType(state.GRAPH.value, state.QUARTER.active, quarterValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))

  return (
    <Filter
      isBenchmark={isBenchmark}
      type={type}
      placeholder={t(graphsStructure.rangeType.placeholder)}
      label={quarterData?.label}
      disabled={!state.QUARTER.active && !state.QUARTER.value}
      selected={quarterValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default QuarterFilter

import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { DetailedOccupancyDataWSType, FrequentationDataWSType } from 'types/GraphDataType'
import { COLORS } from 'assets/colors/colors'

export const getLargeBreakpointConfig = (minifiedCategories: string[]) => ({
  breakpoint: 1550,
  options: {
    stroke: {
      width: 3,
    },
    xaxis: {
      categories: minifiedCategories,
    },
  },
})

export const getMediumBreakpointConfig = (minifiedCategories: string[]) => ({
  breakpoint: 1370,
  options: {
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: minifiedCategories,
    },
  },
})

type ConfigOptions = {
  id: string | undefined
  subtitle: string[]
  title?: string
  xAxisTitle: string
  categories: string[]
  minifiedCategories: string[]
  kpiValue?: string
  capacity?: number
  colors: string[]
  tooltipFormatter: (
    seriesName: string,
    seriesValue: string,
    categories: string[],
    dataPointIndex: number,
    seriesIndex,
    w,
  ) => string
}

export const heatmapConfig = (options: ConfigOptions): ChartProps<HeatmapSeries>['options'] => ({
  title: {
    text: options.title ? options.title : '',
  },
  grid: {
    show: false,
  },
  legend: {
    show: true,
    showForSingleSeries: false,
    horizontalAlign: 'right',
    offsetY: -30,
  },
  chart: {
    id: options.id ? options.id.toString() : `detailedOccupancy-chart`,
    animations: {
      enabled: false,
    },
    selection: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    width: 8,
  },
  subtitle: {
    //@ts-ignore
    text: options.subtitle,
  },
  xaxis: {
    categories: options.categories,
    position: 'top',
    tickAmount: options.categories.length,
    title: {
      text: options.xAxisTitle,
      offsetY: -110,
      style: {
        fontSize: '16px',
        color: '#9C9CB5',
      },
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      rotate: 0,
      offsetY: 12,
      offsetX: -10,
      style: {
        colors: '#9C9CB5',
        fontSize: '14px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '14px',
      },

      formatter: function (str: any) {
        const n = 20
        return str.length > n
          ? str.replace(/\s+/g, ' ').indexOf('(') < n - 1
            ? str
            : str.replace(/\s+/g, ' ').substr(0, n - 1) + '...' + str.substr(str.indexOf('('), str.length)
          : str
      },
    },
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0,
      },
    },
  },
  dataLabels: {
    formatter: (val: number) => {
      const value = val.toString() === '1' ? 0 : val

      return options.kpiValue === 'ENTRIES'
        ? Math.round((value / 1000) * options.capacity)
        : `${Math.round(value / 10)} %`
    },
    style: {
      fontSize: '10px',
    },
  },
  plotOptions: {
    heatmap: {
      radius: 8,
      enableShades: true,
      shadeIntensity: 0.5,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: '#FFFFFF',
            name: '0%',
          },
          {
            from: 1,
            to: 100.49999,
            color: options.colors?.find((elt) => elt.type == 'ZERO_TO_TEN')?.color ?? COLORS.blue,
            name: '0-10%',
          },
          {
            from: 100.5,
            to: 200.49999,
            color: options.colors?.find((elt) => elt.type == 'ELEVEN_TO_TWENTY')?.color ?? COLORS.blue50,
            name: '11-20%',
          },
          {
            from: 200.5,
            to: 300.49999,
            color: options.colors?.find((elt) => elt.type == 'TWENTY_ONE_TO_THIRTY')?.color ?? COLORS.blue30,
            name: '21-30%',
          },
          {
            from: 300.5,
            to: 400.49999,
            color: options.colors?.find((elt) => elt.type == 'THIRTY_ONE_TO_FORTY')?.color ?? COLORS.deepCerise30,
            name: '31-40%',
          },
          {
            from: 400.5,
            to: 500.49999,
            color: options.colors?.find((elt) => elt.type == 'FORTY_ONE_TO_FIFTY')?.color ?? COLORS.deepCerise50,
            name: '41-50%',
          },
          {
            from: 500.5,
            to: 600.49999,
            color: options.colors?.find((elt) => elt.type == 'FIFTY_ONE_TO_SIXTY')?.color ?? COLORS.deepCerise80,
            name: '51-60%',
          },
          {
            from: 600.5,
            to: 700.49999,
            color: options.colors?.find((elt) => elt.type == 'SIXTY_ONE_TO_SEVENTY')?.color ?? COLORS.deepCerise,
            name: '61-70%',
          },
          {
            from: 700.5,
            to: 800.49999,
            color: options.colors?.find((elt) => elt.type == 'SEVENTY_ONE_TO_EIGHTY')?.color ?? COLORS.darkGrey30,
            name: '71-80%',
          },
          {
            from: 800.5,
            to: 900.49999,
            color: options.colors?.find((elt) => elt.type == 'EIGHTY_ONE_TO_NINETY')?.color ?? COLORS.darkGrey50,
            name: '81-90%',
          },
          {
            from: 900.5,
            to: 1000.49999,
            color: options.colors?.find((elt) => elt.type == 'NINETY_ONE_TO_ONE_HUNDRED')?.color ?? COLORS.darkGrey80,
            name: '91-100%',
          },
          {
            from: 1000.5,
            to: Infinity,
            color: options.colors?.find((elt) => elt.type == 'MORE_THAN_ONE_HUNDRED')?.color ?? COLORS.darkGrey,
            name: '> 100%',
          },
        ],
      },
    },
  },
  tooltip: {
    custom: (tooltipOptions) => {
      const { series, seriesIndex, dataPointIndex, w } = tooltipOptions
      const seriesName = w.globals.seriesNames[seriesIndex]
      const seriesValue = series[seriesIndex][dataPointIndex]

      return options.tooltipFormatter(seriesName, seriesValue, options.categories, dataPointIndex, seriesIndex, w)
    },
  },
  responsive: [
    getLargeBreakpointConfig(options.minifiedCategories),
    getMediumBreakpointConfig(options.minifiedCategories),
  ],
})

export const heatMapFakeData: DetailedOccupancyDataWSType = {
  kpi: 'MAX',
  totalRoomsCount: 372,
  average: [
    {
      hour: '1',
      valueInPercentage: 41,
    },
    {
      hour: '2',
      valueInPercentage: 20,
    },
    {
      hour: '3',
      valueInPercentage: 76,
    },
    {
      hour: '4',
      valueInPercentage: 35,
    },
    {
      hour: '5',
      valueInPercentage: 58,
    },
    {
      hour: '6',
      valueInPercentage: 26,
    },
    {
      hour: '7',
      valueInPercentage: 2,
    },
    {
      hour: '8',
      valueInPercentage: 45,
    },
    {
      hour: '9',
      valueInPercentage: 62,
    },
    {
      hour: '10',
      valueInPercentage: 78,
    },
    {
      hour: '11',
      valueInPercentage: 16,
    },
    {
      hour: '12',
      valueInPercentage: 25,
    },
    {
      hour: '13',
      valueInPercentage: 1,
    },
    {
      hour: '14',
      valueInPercentage: 63,
    },
    {
      hour: '15',
      valueInPercentage: 62,
    },
    {
      hour: 'avg',
      valueInPercentage: 40.66,
    },
    {
      hour: 'max',
      valueInPercentage: 78,
    },
  ],
  max: [
    {
      hour: '1',
      valueInPercentage: 41,
    },
    {
      hour: '2',
      valueInPercentage: 89,
    },
    {
      hour: '3',
      valueInPercentage: 76,
    },
    {
      hour: '4',
      valueInPercentage: 35,
    },
    {
      hour: '5',
      valueInPercentage: 58,
    },
    {
      hour: '6',
      valueInPercentage: 26,
    },
    {
      hour: '7',
      valueInPercentage: 2,
    },
    {
      hour: '8',
      valueInPercentage: 45,
    },
    {
      hour: '9',
      valueInPercentage: 62,
    },
    {
      hour: '10',
      valueInPercentage: 78,
    },
    {
      hour: '11',
      valueInPercentage: 16,
    },
    {
      hour: '12',
      valueInPercentage: 25,
    },
    {
      hour: '13',
      valueInPercentage: 1,
    },
    {
      hour: '14',
      valueInPercentage: 63,
    },
    {
      hour: '15',
      valueInPercentage: 62,
    },
    {
      hour: 'avg',
      valueInPercentage: 45.26,
    },
    {
      hour: 'max',
      valueInPercentage: 89,
    },
  ],
  result: [
    {
      label: '20 September 2021',
      average: 35.64,
      max: 83,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 2,
        },
        {
          hour: '2',
          valueInPercentage: 49,
        },
        {
          hour: '3',
          valueInPercentage: 42,
        },
        {
          hour: '4',
          valueInPercentage: 39,
        },
        {
          hour: '5',
          valueInPercentage: 7,
        },
        {
          hour: '6',
          valueInPercentage: 29,
        },
        {
          hour: '7',
          valueInPercentage: 34,
        },
        {
          hour: '8',
          valueInPercentage: 45,
        },
        {
          hour: '9',
          valueInPercentage: 83,
        },
        {
          hour: '10',
          valueInPercentage: 2,
        },
        {
          hour: '11',
          valueInPercentage: 33,
        },
        {
          hour: '12',
          valueInPercentage: 41,
        },
        {
          hour: '13',
          valueInPercentage: 63,
        },
        {
          hour: '14',
          valueInPercentage: 83,
        },
        {
          hour: '15',
          valueInPercentage: 11,
        },
        {
          hour: '18',
          valueInPercentage: 18,
        },
        {
          hour: '19',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '21 September 2021',
      average: 38.52,
      max: 86,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 57,
        },
        {
          hour: '2',
          valueInPercentage: 22,
        },
        {
          hour: '3',
          valueInPercentage: 23,
        },
        {
          hour: '4',
          valueInPercentage: 58,
        },
        {
          hour: '5',
          valueInPercentage: 71,
        },
        {
          hour: '6',
          valueInPercentage: 15,
        },
        {
          hour: '7',
          valueInPercentage: 30,
        },
        {
          hour: '8',
          valueInPercentage: 3,
        },
        {
          hour: '9',
          valueInPercentage: 72,
        },
        {
          hour: '10',
          valueInPercentage: 19,
        },
        {
          hour: '11',
          valueInPercentage: 86,
        },
        {
          hour: '12',
          valueInPercentage: 17,
        },
        {
          hour: '13',
          valueInPercentage: 47,
        },
        {
          hour: '14',
          valueInPercentage: 6,
        },
        {
          hour: '15',
          valueInPercentage: 45,
        },
        {
          hour: '18',
          valueInPercentage: 48,
        },
        {
          hour: '19',
          valueInPercentage: 36,
        },
      ],
    },
    {
      label: '22 September 2021',
      average: 52.05,
      max: 86,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 79,
        },
        {
          hour: '2',
          valueInPercentage: 40,
        },
        {
          hour: '3',
          valueInPercentage: 30,
        },
        {
          hour: '4',
          valueInPercentage: 17,
        },
        {
          hour: '5',
          valueInPercentage: 55,
        },
        {
          hour: '6',
          valueInPercentage: 86,
        },
        {
          hour: '7',
          valueInPercentage: 60,
        },
        {
          hour: '8',
          valueInPercentage: 24,
        },
        {
          hour: '9',
          valueInPercentage: 63,
        },
        {
          hour: '10',
          valueInPercentage: 59,
        },
        {
          hour: '11',
          valueInPercentage: 30,
        },
        {
          hour: '12',
          valueInPercentage: 68,
        },
        {
          hour: '13',
          valueInPercentage: 39,
        },
        {
          hour: '14',
          valueInPercentage: 42,
        },
        {
          hour: '15',
          valueInPercentage: 81,
        },
        {
          hour: '18',
          valueInPercentage: 30,
        },
        {
          hour: '19',
          valueInPercentage: 82,
        },
      ],
    },
    {
      label: '23 September 2021',
      average: 41.47,
      max: 86,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 81,
        },
        {
          hour: '2',
          valueInPercentage: 29,
        },
        {
          hour: '3',
          valueInPercentage: 30,
        },
        {
          hour: '4',
          valueInPercentage: 50,
        },
        {
          hour: '5',
          valueInPercentage: 64,
        },
        {
          hour: '6',
          valueInPercentage: 18,
        },
        {
          hour: '7',
          valueInPercentage: 24,
        },
        {
          hour: '8',
          valueInPercentage: 86,
        },
        {
          hour: '9',
          valueInPercentage: 0,
        },
        {
          hour: '10',
          valueInPercentage: 82,
        },
        {
          hour: '11',
          valueInPercentage: 49,
        },
        {
          hour: '12',
          valueInPercentage: 5,
        },
        {
          hour: '13',
          valueInPercentage: 61,
        },
        {
          hour: '14',
          valueInPercentage: 15,
        },
        {
          hour: '15',
          valueInPercentage: 16,
        },
        {
          hour: '18',
          valueInPercentage: 57,
        },
        {
          hour: '19',
          valueInPercentage: 38,
        },
      ],
    },
    {
      label: '24 September 2021',
      average: 35.76,
      max: 90,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 90,
        },
        {
          hour: '2',
          valueInPercentage: 9,
        },
        {
          hour: '3',
          valueInPercentage: 3,
        },
        {
          hour: '4',
          valueInPercentage: 86,
        },
        {
          hour: '5',
          valueInPercentage: 11,
        },
        {
          hour: '6',
          valueInPercentage: 2,
        },
        {
          hour: '7',
          valueInPercentage: 74,
        },
        {
          hour: '8',
          valueInPercentage: 75,
        },
        {
          hour: '9',
          valueInPercentage: 49,
        },
        {
          hour: '10',
          valueInPercentage: 62,
        },
        {
          hour: '11',
          valueInPercentage: 36,
        },
        {
          hour: '12',
          valueInPercentage: 45,
        },
        {
          hour: '13',
          valueInPercentage: 27,
        },
        {
          hour: '14',
          valueInPercentage: 1,
        },
        {
          hour: '15',
          valueInPercentage: 2,
        },
        {
          hour: '18',
          valueInPercentage: 31,
        },
        {
          hour: '19',
          valueInPercentage: 5,
        },
      ],
    },
    {
      label: '25 September 2021',
      average: 44.41,
      max: 90,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 10,
        },
        {
          hour: '2',
          valueInPercentage: 31,
        },
        {
          hour: '3',
          valueInPercentage: 50,
        },
        {
          hour: '4',
          valueInPercentage: 43,
        },
        {
          hour: '5',
          valueInPercentage: 22,
        },
        {
          hour: '6',
          valueInPercentage: 58,
        },
        {
          hour: '7',
          valueInPercentage: 17,
        },
        {
          hour: '8',
          valueInPercentage: 3,
        },
        {
          hour: '9',
          valueInPercentage: 80,
        },
        {
          hour: '10',
          valueInPercentage: 48,
        },
        {
          hour: '11',
          valueInPercentage: 43,
        },
        {
          hour: '12',
          valueInPercentage: 4,
        },
        {
          hour: '13',
          valueInPercentage: 61,
        },
        {
          hour: '14',
          valueInPercentage: 63,
        },
        {
          hour: '15',
          valueInPercentage: 43,
        },
        {
          hour: '18',
          valueInPercentage: 89,
        },
        {
          hour: '19',
          valueInPercentage: 90,
        },
      ],
    },
    {
      label: '26 September 2021',
      average: 40.11,
      max: 89,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 44,
        },
        {
          hour: '2',
          valueInPercentage: 63,
        },
        {
          hour: '3',
          valueInPercentage: 6,
        },
        {
          hour: '4',
          valueInPercentage: 16,
        },
        {
          hour: '5',
          valueInPercentage: 44,
        },
        {
          hour: '6',
          valueInPercentage: 11,
        },
        {
          hour: '7',
          valueInPercentage: 65,
        },
        {
          hour: '8',
          valueInPercentage: 74,
        },
        {
          hour: '9',
          valueInPercentage: 58,
        },
        {
          hour: '10',
          valueInPercentage: 10,
        },
        {
          hour: '11',
          valueInPercentage: 28,
        },
        {
          hour: '12',
          valueInPercentage: 23,
        },
        {
          hour: '13',
          valueInPercentage: 2,
        },
        {
          hour: '14',
          valueInPercentage: 16,
        },
        {
          hour: '15',
          valueInPercentage: 55,
        },
        {
          hour: '18',
          valueInPercentage: 78,
        },
        {
          hour: '19',
          valueInPercentage: 89,
        },
      ],
    },
    {
      label: '27 September 2021',
      average: 57.76,
      max: 99,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 40,
        },
        {
          hour: '2',
          valueInPercentage: 99,
        },
        {
          hour: '3',
          valueInPercentage: 69,
        },
        {
          hour: '4',
          valueInPercentage: 8,
        },
        {
          hour: '5',
          valueInPercentage: 31,
        },
        {
          hour: '6',
          valueInPercentage: 54,
        },
        {
          hour: '7',
          valueInPercentage: 28,
        },
        {
          hour: '8',
          valueInPercentage: 68,
        },
        {
          hour: '9',
          valueInPercentage: 63,
        },
        {
          hour: '10',
          valueInPercentage: 19,
        },
        {
          hour: '11',
          valueInPercentage: 89,
        },
        {
          hour: '12',
          valueInPercentage: 88,
        },
        {
          hour: '13',
          valueInPercentage: 86,
        },
        {
          hour: '14',
          valueInPercentage: 81,
        },
        {
          hour: '15',
          valueInPercentage: 66,
        },
        {
          hour: '18',
          valueInPercentage: 82,
        },
        {
          hour: '19',
          valueInPercentage: 11,
        },
      ],
    },
    {
      label: '28 September 2021',
      average: 45.94,
      max: 96,
      roomsCount: 5,
      valuesByHour: [
        {
          hour: '1',
          valueInPercentage: 41,
        },
        {
          hour: '2',
          valueInPercentage: 89,
        },
        {
          hour: '3',
          valueInPercentage: 76,
        },
        {
          hour: '4',
          valueInPercentage: 35,
        },
        {
          hour: '5',
          valueInPercentage: 58,
        },
        {
          hour: '6',
          valueInPercentage: 26,
        },
        {
          hour: '7',
          valueInPercentage: 2,
        },
        {
          hour: '8',
          valueInPercentage: 45,
        },
        {
          hour: '9',
          valueInPercentage: 62,
        },
        {
          hour: '10',
          valueInPercentage: 78,
        },
        {
          hour: '11',
          valueInPercentage: 16,
        },
        {
          hour: '12',
          valueInPercentage: 25,
        },
        {
          hour: '13',
          valueInPercentage: 1,
        },
        {
          hour: '14',
          valueInPercentage: 63,
        },
        {
          hour: '15',
          valueInPercentage: 62,
        },
        {
          hour: '18',
          valueInPercentage: 96,
        },
        {
          hour: '19',
          valueInPercentage: 6,
        },
      ],
    },
  ],
}
export const frequentationFakeData: FrequentationDataWSType = {
  capacity: 220,
  average: [
    {
      hour: '11:00',
      valueInPercentage: 41,
    },
    {
      hour: '11:15',
      valueInPercentage: 20,
    },
    {
      hour: '11:30',
      valueInPercentage: 76,
    },
    {
      hour: '11:45',
      valueInPercentage: 35,
    },
    {
      hour: '12:00',
      valueInPercentage: 58,
    },
    {
      hour: '12:15',
      valueInPercentage: 26,
    },
    {
      hour: '12:30',
      valueInPercentage: 2,
    },
    {
      hour: '12:45',
      valueInPercentage: 45,
    },
    {
      hour: '13:00',
      valueInPercentage: 62,
    },
    {
      hour: '13:15',
      valueInPercentage: 78,
    },
    {
      hour: '13:30',
      valueInPercentage: 16,
    },
    {
      hour: '13:45',
      valueInPercentage: 25,
    },
    {
      hour: '14:00',
      valueInPercentage: 1,
    },
    {
      hour: '14:15',
      valueInPercentage: 63,
    },
    {
      hour: '14:30',
      valueInPercentage: 62,
    },
    {
      hour: 'avg',
      valueInPercentage: 40.66,
    },
    {
      hour: 'max',
      valueInPercentage: 78,
    },
  ],
  max: [
    {
      hour: '11:15',
      valueInPercentage: 41,
    },
    {
      hour: '11:30',
      valueInPercentage: 89,
    },
    {
      hour: '11:45',
      valueInPercentage: 76,
    },
    {
      hour: '12:00',
      valueInPercentage: 35,
    },
    {
      hour: '12:15',
      valueInPercentage: 58,
    },
    {
      hour: '12:30',
      valueInPercentage: 26,
    },
    {
      hour: '12:45',
      valueInPercentage: 2,
    },
    {
      hour: '13:00',
      valueInPercentage: 45,
    },
    {
      hour: '13:15',
      valueInPercentage: 62,
    },
    {
      hour: '13:30',
      valueInPercentage: 78,
    },
    {
      hour: '13:45',
      valueInPercentage: 16,
    },
    {
      hour: '14:00',
      valueInPercentage: 25,
    },
    {
      hour: '14:15',
      valueInPercentage: 1,
    },
    {
      hour: '14:30',
      valueInPercentage: 63,
    },
    {
      hour: '14:45',
      valueInPercentage: 62,
    },
    {
      hour: 'avg',
      valueInPercentage: 45.26,
    },
    {
      hour: 'max',
      valueInPercentage: 89,
    },
  ],
  result: [
    {
      label: '20 September 2021',
      average: 35.64,
      max: 83,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '21 September 2021',
      average: 38.52,
      max: 86,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '22 September 2021',
      average: 52.05,
      max: 86,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '23 September 2021',
      average: 41.47,
      max: 86,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '24 September 2021',
      average: 35.76,
      max: 90,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '25 September 2021',
      average: 44.41,
      max: 90,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '26 September 2021',
      average: 40.11,
      max: 89,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '27 September 2021',
      average: 57.76,
      max: 99,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
    {
      label: '28 September 2021',
      average: 45.94,
      max: 96,
      valuesByHour: [
        {
          hour: '11:00',
          valueInPercentage: 2,
        },
        {
          hour: '11:15',
          valueInPercentage: 49,
        },
        {
          hour: '11:30',
          valueInPercentage: 42,
        },
        {
          hour: '11:45',
          valueInPercentage: 39,
        },
        {
          hour: '12:00',
          valueInPercentage: 7,
        },
        {
          hour: '12:15',
          valueInPercentage: 29,
        },
        {
          hour: '12:30',
          valueInPercentage: 34,
        },
        {
          hour: '13:00',
          valueInPercentage: 45,
        },
        {
          hour: '13:15',
          valueInPercentage: 83,
        },
        {
          hour: '13:30',
          valueInPercentage: 2,
        },
        {
          hour: '13:45',
          valueInPercentage: 33,
        },
        {
          hour: '14:00',
          valueInPercentage: 41,
        },
        {
          hour: '14:15',
          valueInPercentage: 63,
        },
        {
          hour: '14:30',
          valueInPercentage: 83,
        },
        {
          hour: '14:45',
          valueInPercentage: 11,
        },
        {
          hour: '15:00',
          valueInPercentage: 18,
        },
        {
          hour: '15:15',
          valueInPercentage: 25,
        },
      ],
    },
  ],
}

export const loadingHeatmapOptions: ChartProps<HeatmapSeries>['options'] = {
  grid: {
    show: false,
  },
  legend: {
    show: false,
  },
  chart: {
    type: 'heatmap',
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  stroke: {
    width: 10,
  },
  plotOptions: {
    heatmap: {
      radius: 12,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 33,
            color: '#e2e2e2',
            name: 'low',
          },
          {
            from: 34,
            to: 66,
            color: '#BFBFBF',
            name: 'medium',
          },
          {
            from: 67,
            to: 100,
            color: '#949494',
            name: 'high',
          },
        ],
      },
    },
  },
  xaxis: {
    tickAmount: 0,
    labels: {
      formatter: () => '',
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
}

export const loadingHeatmapData = [
  {
    name: ' ',
    data: [
      {
        x: 0,
        y: 20,
      },
      {
        x: 0,
        y: 50,
      },
      {
        x: 0,
        y: 80,
      },
      {
        x: 0,
        y: 20,
      },
    ],
  },
  {
    name: ' ',
    data: [
      {
        x: 0,
        y: 80,
      },
      {
        x: 0,
        y: 20,
      },
      {
        x: 0,
        y: 50,
      },
      {
        x: 0,
        y: 50,
      },
    ],
  },
  {
    name: ' ',
    data: [
      {
        x: 0,
        y: 20,
      },
      {
        x: 0,
        y: 80,
      },
      {
        x: 0,
        y: 20,
      },
      {
        x: 0,
        y: 80,
      },
    ],
  },
  {
    name: ' ',
    data: [
      {
        x: 0,
        y: 50,
      },
      {
        x: 0,
        y: 80,
      },
      {
        x: 0,
        y: 80,
      },
      {
        x: 0,
        y: 20,
      },
    ],
  },
]

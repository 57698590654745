import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Language } from './utils/constants/Languages'

i18n
  .use(Backend) // loads translations from public/locales folder
  .use(LanguageDetector) // detect user language
  .use(initReactI18next)
  .init({
    fallbackLng: Language.en,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

const nbOfRepetitions = 2
const baseData = [40, 41, 42, 43, 44, 45, 45, 45, 44, 43, 42, 41, 40, 40] // 14 elements
export const baseSerie = Array(nbOfRepetitions)
  .fill(baseData)
  .join()
  .split(',')
  .map((el) => parseInt(el))

export const categories = baseSerie.map((el, index) => 1000 + index)
export const barChartFakeData = baseSerie

export const getBarChartOptions = (hasAnimation = false, max?: number, hasData = true): Record<string, unknown> => ({
  chart: {
    id: 'basic-bar',
    animations: {
      enabled: hasAnimation,
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
      },
    },
  },
  colors: ['#C0C0C0'],
  xaxis: {
    categories,
    labels: {
      show: false,
    },
  },
  yaxis: {
    min: 35,
    max,
    labels: {
      show: !hasData,
    },
  },
  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  states: {
    active: {
      filter: {
        type: 'none',
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  noData: {
    offsetY: 8,
  },
})

import { FC } from 'react'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'
import LoadingChart from '../LoadingChart'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { getMonthlyOptions } from 'utils/constants/graphs/monthly'
import IconSvg from '../../../atoms/icons/IconSvg'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

type Props = {
  loading?: boolean
  id?: string
  title: string
  subtitle: string
  series: ColumnChartSeries
  categories: string[]
  colors: string[]
}

const MonthlyChart: FC<Props> = (props) => {
  const { loading = false, title, subtitle, series, categories, id, colors } = props
  const { t } = useTranslation()

  if (loading) {
    return <LoadingChart type="bar" />
  }

  const options = getMonthlyOptions({ id, title, subtitle, categories, colors })
  let tooltipFaq
  subtitle.includes('workstations')
    ? (tooltipFaq = t('graph.monthly.workstationFaq'))
    : (tooltipFaq = t('graph.monthly.faq'))

  return (
    <>
      <ReactApexChart type="bar" series={series} options={options} />
    </>
  )
}

export default MonthlyChart

import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'

export default function Calendar() {
  const { t } = useTranslation()
  return (
    <div
      className="shadowSection p-2 justify-center flex flex-col items-center mr-2"
      style={{ color: COLORS.darkGrey80, minWidth: '30%' }}
    >
      <p className="text-center mb-6">{t('landingPage.analyseToCome')}</p>
      <IconSvg name="LEADER_BOARD" className="ml-2" />
    </div>
  )
}

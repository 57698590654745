import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { FOCUS_ROOM, graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, ...structure } = graphsStructure.capacityCategory
const [SMALL, MEDIUM, LARGE, OPEN_SPACE_1_2, OPEN_SPACE_3] = Object.entries(structure).map(([value, label]) => ({
  label,
  value,
}))
const baseValues = [SMALL, MEDIUM, LARGE]
const focusRoomValues = [OPEN_SPACE_1_2, OPEN_SPACE_3]

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.CAPACITY_SIZE.value === value) return state

  return {
    ...state,
    CAPACITY_SIZE: {
      ...initialFilterState.CAPACITY_SIZE,
      value,
    },
    ROOM_SIZE_GROUPED: {
      ...initialFilterState.ROOM_SIZE_GROUPED,
    },
    ROOM_SIZE_DETAILED: {
      ...initialFilterState.ROOM_SIZE_DETAILED,
    },
  }
}

const CapacitySizeFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const capacitySizeValue = state.CAPACITY_SIZE.value

  const values = state.ROOM_TYPE.value === FOCUS_ROOM.value ? focusRoomValues : baseValues
  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const capacitySizeData = options.find((option) => option.value === capacitySizeValue)
  const type = getFilterType(state.GRAPH.value, state.CAPACITY_SIZE.active, capacitySizeValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={capacitySizeData?.label}
      disabled={!state.CAPACITY_SIZE.active && !state.CAPACITY_SIZE.value}
      selected={capacitySizeValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default CapacitySizeFilter

import { FC } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  path: string
  exact?: boolean
  disabled?: boolean
  comingSoon?: boolean
  notification?: boolean
  id?: string
}

const NavItem: FC<Props> = (props) => {
  const { path, exact, comingSoon, children, disabled, notification, id } = props

  const className = `select-none relative text-basic-500 h-full px-4 flex flex-col justify-items-center justify-center border-b-2 border-basic ${
    comingSoon ? 'comingSoon' : 'hover:bg-basic-600 navItem'
  }
  ${notification ? 'notification' : ''}`

  if (comingSoon) {
    return <div className={className}>{children}</div>
  }

  if (disabled) {
    return null
  }

  return (
    <NavLink to={path} exact={exact} className={className} activeClassName="active-border active-text" id={id}>
      {children}
    </NavLink>
  )
}

export default NavItem

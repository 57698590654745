import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import PageLayout from 'components/layouts/PageLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const SettingsView = () => {
  const { t } = useTranslation()

  return (
    <PageLayout>
      <div className="flex flex-col items-center">
        <h1 className="font-bold" style={{ margin: '2rem auto', color: COLORS.deepCerise, fontSize: '2rem' }}>
          {t('settings.title')}
        </h1>
        <div className="flex justify-between" style={{ margin: '2rem auto' }}>
          <div className="right-side">
            <div className="one-item">
              <Link to="/settings/colors">
                <div className="flex">
                  <IconSvg name="COLORS" color="GREY80" className="mr-2" />
                  <p className="text-lg"> {t('settings.colors.title')} </p>
                </div>
              </Link>
              <Link to="/settings/colors">
                <span
                  style={{
                    backgroundColor: COLORS.darkGrey80,
                    height: '28px',
                    width: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                  }}
                >
                  <IconSvg name="WHITE_ARROW_RIGHT" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default SettingsView

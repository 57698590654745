import { useAuth } from 'core/auth/useAuth'
import { useCookies } from 'react-cookie'
import { useQueryClient } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { PATHS } from 'utils/constants/routes/Paths'

export const useDeleteStorageAndCookies = (): { clear: () => void } => {
  const [, , removeCookie] = useCookies(['config', 'access_token', 'refresh_token'])
  const authContext = useAuth()
  const queryClient = useQueryClient()

  const clear = (): void => {
    // @TODO upgrade to react 18
    queryClient.removeQueries(QUERY_KEYS.AUTH.USER_CURRENT)
    // specifically clear tokens to avoid deleting Jimo items
    localStorage.removeItem('firstDomain')
    removeCookie('config', { path: PATHS.HOME })
    removeCookie('access_token', { path: PATHS.HOME })
    removeCookie('refresh_token', { path: PATHS.HOME })

    if (!authContext) {
      return
    }

    const { setAccessToken, setRefreshToken } = authContext

    setAccessToken(null)
    setRefreshToken(null)
  }

  return { clear }
}

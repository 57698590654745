import { FC, HTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import Input, { InputProps } from './Input'
import { useTranslation } from 'react-i18next'

type Props = HTMLAttributes<HTMLInputElement> &
  InputProps & {
    name: string
  }

const FormInput: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { className = '', name, value, ...rest } = props
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={`m-3 ${className}`}>
      <Input {...rest} {...register(name)} value={value} error={!!errors[name]?.message} />
      <p className="text-c2 text-primary-400 h-3">{errors[name] ? t(errors[name].message) : ' '}</p>
    </div>
  )
}

export default FormInput

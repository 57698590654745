import { BenchmarkDataWSType, CountingMaxDataWSType } from './../types/GraphDataType'
import {
  benchmarkValue,
  calibratedUtilizationValue,
  countingMaxValue,
  roomBookingValue,
  spaceAttendanceValue,
} from 'utils/constants/graphs/graphsStructure'
import {
  CalibratedUtilizationDataWSType,
  ColumnChartDataWSType,
  DetailedOccupancyDataWSType,
  HeatmapDataWSType,
  MonitoringDataWSType,
  MonthlyDataWSType,
  RestaurantDataWSType,
  RoomBookingDataWSType,
  TopFlopDataWSType,
  UtilizationQualityDataWSType,
} from 'types/GraphDataType'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  frequentationValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
} from './constants/graphs/graphsStructure'
import { ExportDataType, ExportType } from 'types/ExportType'
import { dateIsDay, dayName, formatDate, formatHourForCsv, toHoursAndMinutes } from './dateUtils'
import i18next from 'i18next'

const cache = {}
const checkCache = (value) => {
  if (cache[value]) {
    return cache[value]
  } else {
    const formattedDate = formatDate(value)
    cache[value] = formattedDate
    return formattedDate
  }
}
const formatMinutes = (minutes) => {
  if (cache[minutes]) {
    return cache[minutes]
  } else {
    const formattedMinutes = `${toHoursAndMinutes(minutes).hours}h${Math.round(toHoursAndMinutes(minutes).minutes)}min`
    cache[minutes] = formattedMinutes
    return formattedMinutes
  }
}

const formatDayName = (date: string): string => {
  const day = dayName(new Date(date))
  return day[0].toUpperCase() + day.slice(1)
}

const formatRate = (rate: number | string): string => {
  if (typeof rate === 'number') {
    rate = Math.round((rate + Number.EPSILON) * 100) / 100
  }
  return rate.toString().replace('.', ',') + '%'
}

const getExportTopFlopData = (topFlopData: TopFlopDataWSType): ExportType => {
  const totalRoomsCount = topFlopData.totalRoomsCount
  const headers = [
    'graph.common.export.name',
    'graph.common.total',
    'graph.topFlop.capacity',
    'graph.common.export.occupancyRate',
    'graph.common.export.occupancyDuration',
  ]

  return {
    headers,
    data: topFlopData.result.map(({ name, capacity, occupancyRate, occupancyDurationMinutes }) => [
      name,
      totalRoomsCount,
      capacity,
      formatRate(occupancyRate),
      formatMinutes(occupancyDurationMinutes),
    ]),
  }
}
const getExportUtilizationQualityData = (utilizationQualityData: UtilizationQualityDataWSType): ExportType => {
  const totalRoomsCount = utilizationQualityData.totalRoomsCount
  const headers = [
    'graph.common.export.name',
    'graph.common.total',
    'graph.topFlop.capacity',
    'graph.utilizationQuality.EMPTY',
    'graph.utilizationQuality.emptyDuration',
    'graph.utilizationQuality.UNDER_OCCUPIED',
    'graph.utilizationQuality.underOccupiedDuration',
    'graph.utilizationQuality.BALANCED',
    'graph.utilizationQuality.balancedDuration',
    'graph.utilizationQuality.SATURATED',
    'graph.utilizationQuality.saturatedDuration',
  ]

  return {
    headers,
    data: utilizationQualityData.result.map(({ name, capacity, rates }) => [
      name,
      totalRoomsCount,
      capacity,
      rates.filter((elt) => elt.utilizationQuality == 'EMPTY')
        ? formatRate(rates.filter((elt) => elt.utilizationQuality == 'EMPTY')[0].utilizationQualityRate)
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'EMPTY')
        ? formatMinutes(rates.filter((elt) => elt.utilizationQuality == 'EMPTY')[0].utilizationQualityDurationMinutes)
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'UNDER_OCCUPIED')
        ? formatRate(rates.filter((elt) => elt.utilizationQuality == 'UNDER_OCCUPIED')[0].utilizationQualityRate)
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'UNDER_OCCUPIED')
        ? formatMinutes(
            rates.filter((elt) => elt.utilizationQuality == 'UNDER_OCCUPIED')[0].utilizationQualityDurationMinutes,
          )
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'BALANCED')
        ? formatRate(rates.filter((elt) => elt.utilizationQuality == 'BALANCED')[0].utilizationQualityRate)
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'BALANCED')
        ? formatMinutes(
            rates.filter((elt) => elt.utilizationQuality == 'BALANCED')[0].utilizationQualityDurationMinutes,
          )
        : '',
      rates.filter((elt) => elt.utilizationQuality == 'SATURATED')
        ? formatRate(rates.filter((elt) => elt.utilizationQuality == 'SATURATED')[0].utilizationQualityRate)
        : '',

      rates.filter((elt) => elt.utilizationQuality == 'SATURATED')
        ? formatMinutes(
            rates.filter((elt) => elt.utilizationQuality == 'SATURATED')[0].utilizationQualityDurationMinutes,
          )
        : '',
    ]),
  }
}
const getExportRoomBookingData = (roomBookingData: RoomBookingDataWSType): ExportType => {
  const headers = [
    'graph.common.export.name',
    'graph.topFlop.capacity',
    'graph.roomBooking.FREE',
    'graph.roomBooking.NO_SHOW',
    'graph.roomBooking.NOT_BOOKED',
    'graph.roomBooking.BOOKED_AND_OCCUPIED',
  ]

  return {
    headers,
    data: roomBookingData.result.map(({ name, capacity, rates }) => [
      name,
      capacity,
      rates.filter((elt) => elt.status == 'FREE')
        ? formatRate(rates.filter((elt) => elt.status == 'FREE')[0].ratio * 100)
        : '',
      rates.filter((elt) => elt.status == 'NO_SHOW')
        ? formatRate(rates.filter((elt) => elt.status == 'NO_SHOW')[0].ratio * 100)
        : '',
      rates.filter((elt) => elt.status == 'NOT_BOOKED')
        ? formatRate(rates.filter((elt) => elt.status == 'NOT_BOOKED')[0].ratio * 100)
        : '',
      rates.filter((elt) => elt.status == 'BOOKED_AND_OCCUPIED')
        ? formatRate(rates.filter((elt) => elt.status == 'BOOKED_AND_OCCUPIED')[0].ratio * 100)
        : '',
    ]),
  }
}
const getExportHeatmapData = (heatmapData: HeatmapDataWSType, period): ExportType => {
  const isDayView = dateIsDay(period)
  const hours =
    heatmapData.result.length > 0 && heatmapData.result[0].periods.length > 0
      ? heatmapData.result[0].periods[0].valuesByHour.map(({ hour }) => hour)
      : ['8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h']

  const formattedHours = hours.map((hour) => formatHourForCsv(hour))

  const headers = [
    heatmapData.result.some((elt) => elt.buildingName) && 'graph.heatmap.buildingName',
    heatmapData.result.some((elt) => elt.floorName) && 'graph.heatmap.floorName',
    heatmapData.result.some((elt) => elt.compartmentName) && 'graph.heatmap.compartmentName',
    'graph.heatmap.roomName',
    'graph.heatmap.workstationName',
    'graph.heatmap.roomType',
    'graph.heatmap.period',
    'graph.common.average',
    ...formattedHours,
    'graph.common.export.occupancyDuration',
  ].filter(Boolean)

  if (isDayView) {
    const periodIndex = headers.findIndex((header) => header === 'graph.heatmap.period')
    headers.splice(periodIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  const data = heatmapData.result
    .map(({ buildingName, floorName, compartmentName, roomName, workstationName, spaceType, periods }) => {
      const hoursPeriods = hours.map((hour) => {
        const periodValues = periods.find((prd) => prd.period === period)
        if (periodValues) {
          const hourValue = periodValues.valuesByHour.find((elt) => elt.hour === hour)
          return hourValue ? formatRate(hourValue.valueInPercentage) : null
        }
        return null
      })

      const durationMinutes = periods.find((prd) => prd.period === period)?.averageDurationMinutes || null

      if (periods.find((prd) => prd.period === period)) {
        const periodData = [
          headers.includes('graph.heatmap.buildingName') ? buildingName || '_' : '',
          headers.includes('graph.heatmap.floorName') ? floorName || '_' : '',
          headers.includes('graph.heatmap.compartmentName') ? compartmentName || '_' : '',
          headers.includes('graph.heatmap.roomName') ? roomName || '_' : '',
          headers.includes('graph.heatmap.workstationName') ? workstationName || '_' : '',
          spaceType === 'UNKNOWN' ? i18next.t('graph.filters.customAttribut.notSpecified') : spaceType,
        ]

        const foundPeriod = periods.find((prd) => prd.period === period)
        if (foundPeriod.period && isDayView) {
          periodData.push(formatDayName(foundPeriod.period))
        } else {
          periodData.push('')
        }

        if (foundPeriod.period) {
          periodData.push(checkCache(foundPeriod.period))
        } else {
          periodData.push('')
        }

        if (foundPeriod.average || foundPeriod.average === 0) {
          periodData.push(formatRate(foundPeriod.average))
        } else {
          periodData.push('')
        }

        periodData.push(...hoursPeriods, formatMinutes(durationMinutes))

        return periodData.filter(Boolean)
      }
      return []
    })
    .filter((arr) => arr.length > 0)
  return {
    headers,
    data,
  }
}

const getExportMonitoringData = (
  monitoringData: MonitoringDataWSType,
  roomType: any,
  customAttributWks,
  customAttributCollab,
): ExportType => {
  const workstationNature = [
    'ALL_WORKSTATIONS',
    'OPEN_SPACE',
    'INDIVIDUAL_OFFICE',
    'WORKSTATION',
    'SHARED_OFFICE',
    'COMMON_SPACE',
  ]

  const isDayView = monitoringData.results[0] ? dateIsDay(monitoringData.results[0].period) : false

  const headers = [
    'graph.monitoring.domainName',
    'graph.common.totalSpaces',
    monitoringData.results.filter((elt) => elt.buildingName).length > 0 && 'graph.heatmap.buildingName',
    monitoringData.results.filter((elt) => elt.zoneName).length > 0 && 'graph.monitoring.zoneName',
    monitoringData.results.filter((elt) => elt.floorName).length > 0 && 'graph.heatmap.floorName',
    monitoringData.results.filter((elt) => elt.compartmentName).length > 0 && 'graph.heatmap.compartmentName',
    monitoringData.results.filter((elt) => elt.businessUnit).length > 0 && 'graph.heatmap.businessUnit',
    customAttributWks?.value == 'true' && customAttributWks.placeholder,
    customAttributCollab?.value == 'true' && customAttributCollab.placeholder,
    'graph.heatmap.roomType',
    'graph.heatmap.period',
    'graph.monitoring.rate',
    'graph.monitoring.occupiedSpaces',
  ].filter(Boolean)

  if (isDayView) {
    const periodIndex = headers.findIndex((header) => header === 'graph.heatmap.period')
    headers.splice(periodIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  return {
    headers,
    data: monitoringData.results.map(
      ({
        domainName,
        roomsCount,
        buildingName,
        zoneName,
        floorName,
        compartmentName,
        businessUnit,
        roomType,
        period,
        rate,
        customAttribute,
        rateRoomsCount,
      }) => {
        const isCustomAttributWksTrue = customAttributWks?.value == 'true'
        const isCustomAttributCollabTrue = customAttributCollab?.value == 'true'

        if (isCustomAttributWksTrue && isCustomAttributCollabTrue) {
          return [
            domainName,
            roomsCount,
            buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
            zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
            floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
            compartmentName == undefined && headers.includes('graph.heatmap.compartmentName') ? '_' : compartmentName,
            businessUnit == undefined && headers.includes('graph.heatmap.businessUnit') ? '_' : businessUnit,
            workstationNature.includes(roomType)
              ? customAttribute === '[NO_CUSTOM_ATTRIBUTE]'
                ? i18next.t('graph.filters.customAttribut.notSpecified')
                : customAttribute
              : '_',
            !workstationNature.includes(roomType)
              ? customAttribute === '[NO_CUSTOM_ATTRIBUTE]'
                ? i18next.t('graph.filters.customAttribut.notSpecified')
                : customAttribute
              : '_',
            roomType,
            period && isDayView ? formatDayName(period) : '',
            period ? checkCache(period) : '',
            formatRate(rate),
            rateRoomsCount.toString(),
          ].filter(Boolean)
        } else if (!isCustomAttributWksTrue && isCustomAttributCollabTrue) {
          return [
            domainName,
            roomsCount,
            buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
            zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
            floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
            compartmentName == undefined && headers.includes('graph.heatmap.compartmentName') ? '_' : compartmentName,
            businessUnit == undefined && headers.includes('graph.heatmap.businessUnit') ? '_' : businessUnit,
            !workstationNature.includes(roomType)
              ? customAttribute === '[NO_CUSTOM_ATTRIBUTE]'
                ? i18next.t('graph.filters.customAttribut.notSpecified')
                : customAttribute
              : '_',
            roomType,
            period && isDayView ? formatDayName(period) : '',
            period ? checkCache(period) : '',
            formatRate(rate),
            rateRoomsCount.toString(),
          ].filter(Boolean)
        } else if (isCustomAttributWksTrue && !isCustomAttributCollabTrue) {
          return [
            domainName,
            roomsCount,
            buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
            zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
            floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
            compartmentName == undefined && headers.includes('graph.heatmap.compartmentName') ? '_' : compartmentName,
            businessUnit == undefined && headers.includes('graph.heatmap.businessUnit') ? '_' : businessUnit,
            workstationNature.includes(roomType)
              ? customAttribute === '[NO_CUSTOM_ATTRIBUTE]'
                ? i18next.t('graph.filters.customAttribut.notSpecified')
                : customAttribute
              : '_',
            roomType,
            period && isDayView ? formatDayName(period) : '',
            period ? checkCache(period) : '',
            formatRate(rate),
            rateRoomsCount.toString(),
          ].filter(Boolean)
        } else {
          return [
            domainName,
            roomsCount,
            buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
            zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
            floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
            compartmentName == undefined && headers.includes('graph.heatmap.compartmentName') ? '_' : compartmentName,
            businessUnit == undefined && headers.includes('graph.heatmap.businessUnit') ? '_' : businessUnit,
            roomType,
            period && isDayView ? formatDayName(period) : '',
            period ? checkCache(period) : '',
            formatRate(rate),
            rateRoomsCount.toString(),
          ].filter(Boolean)
        }
      },
    ),
  }
}

const getKpiLabel = (kpi: any) => {
  switch (kpi) {
    case 'NB_OCCUPIED_WORKSTATION':
      return 'graph.filters.kpi.numberOccupiedWorkstation'
    case 'AVG_CONSECUTIVE_UTILIZATION':
      return 'graph.filters.kpi.averageConsecutiveUtilization'
    case 'AVG_OCCUPANCY_DURATION':
      return 'graph.filters.kpi.averageOccupancyDuration'
    case 'DISTRIBUTION_UTILIZATION_DURATION':
      return 'graph.filters.kpi.distribution'
    case 'DISTRIBUTION_UTILIZATION_PERCENTAGE':
      return 'graph.filters.kpi.distributionPercentage'
  }
}
const getExportCalibratedUtilizationData = (
  calibratedUtilizationData: CalibratedUtilizationDataWSType,
  roomType: any,
  kpi: any,
): ExportType => {
  const firstResult = calibratedUtilizationData.results[0]
  const isDayView = firstResult ? dateIsDay(firstResult.period) : false
  const headers =
    calibratedUtilizationData && calibratedUtilizationData.results.length > 0
      ? firstResult.distributionUtilizationDuration
        ? [
            'graph.monitoring.domainName',
            roomType && roomType.includes('WORKSTATION')
              ? JSON.stringify(roomType) == JSON.stringify(['WORKSTATION'])
                ? 'graph.common.totalWorkstation'
                : 'graph.common.totalAll'
              : 'graph.common.capacity',
            calibratedUtilizationData.results.filter((elt) => elt.buildingName).length > 0 &&
              'graph.heatmap.buildingName',
            calibratedUtilizationData.results.filter((elt) => elt.zoneName).length > 0 && 'graph.monitoring.zoneName',
            calibratedUtilizationData.results.filter((elt) => elt.floorName).length > 0 && 'graph.heatmap.floorName',
            calibratedUtilizationData.results.filter((elt) => elt.compartmentName).length > 0 &&
              'graph.heatmap.compartmentName',
            calibratedUtilizationData.results.filter((elt) => elt.businessUnit).length > 0 &&
              'graph.filters.monitoringBusinessUnits.placeholder',
            'graph.heatmap.roomType',
            'graph.heatmap.period',
            '<30 min',
            '30 min - 1h',
            '1h - 2h',
            '2h - 3h',
            '3h - 4h',
            '4h+',
          ].filter(Boolean)
        : firstResult.distributionUtilizationPercentage
        ? [
            'graph.monitoring.domainName',
            roomType && roomType.includes('WORKSTATION')
              ? JSON.stringify(roomType) == JSON.stringify(['WORKSTATION'])
                ? 'graph.common.totalWorkstation'
                : 'graph.common.totalAll'
              : 'graph.common.capacity',
            calibratedUtilizationData.results.filter((elt) => elt.buildingName).length > 0 &&
              'graph.heatmap.buildingName',
            calibratedUtilizationData.results.filter((elt) => elt.zoneName).length > 0 && 'graph.monitoring.zoneName',
            calibratedUtilizationData.results.filter((elt) => elt.floorName).length > 0 && 'graph.heatmap.floorName',
            calibratedUtilizationData.results.filter((elt) => elt.compartmentName).length > 0 &&
              'graph.heatmap.compartmentName',
            calibratedUtilizationData.results.filter((elt) => elt.businessUnit).length > 0 &&
              'graph.filters.monitoringBusinessUnits.placeholder',
            'graph.heatmap.roomType',
            'graph.heatmap.period',
            '<25%',
            '25% - 50%',
            '50% - 75%',
            '>75%',
          ].filter(Boolean)
        : [
            'graph.monitoring.domainName',
            roomType && roomType.includes('WORKSTATION')
              ? JSON.stringify(roomType) == JSON.stringify(['WORKSTATION'])
                ? 'graph.common.totalWorkstation'
                : 'graph.common.totalAll'
              : 'graph.common.capacity',
            calibratedUtilizationData.results.filter((elt) => elt.buildingName).length > 0 &&
              'graph.heatmap.buildingName',
            calibratedUtilizationData.results.filter((elt) => elt.zoneName).length > 0 && 'graph.monitoring.zoneName',
            calibratedUtilizationData.results.filter((elt) => elt.floorName).length > 0 && 'graph.heatmap.floorName',
            calibratedUtilizationData.results.filter((elt) => elt.compartmentName).length > 0 &&
              'graph.heatmap.compartmentName',
            calibratedUtilizationData.results.filter((elt) => elt.businessUnit).length > 0 &&
              'graph.filters.monitoringBusinessUnits.placeholder',
            'graph.heatmap.roomType',
            'graph.heatmap.period',
            getKpiLabel(kpi),
          ].filter(Boolean)
      : [
          'graph.monitoring.domainName',
          roomType && roomType.includes('WORKSTATION')
            ? JSON.stringify(roomType) == JSON.stringify(['WORKSTATION'])
              ? 'graph.common.totalWorkstation'
              : 'graph.common.totalAll'
            : 'graph.common.capacity',
          calibratedUtilizationData.results.filter((elt) => elt.buildingName).length > 0 &&
            'graph.heatmap.buildingName',
          calibratedUtilizationData.results.filter((elt) => elt.zoneName).length > 0 && 'graph.monitoring.zoneName',
          calibratedUtilizationData.results.filter((elt) => elt.floorName).length > 0 && 'graph.heatmap.floorName',
          calibratedUtilizationData.results.filter((elt) => elt.compartmentName).length > 0 &&
            'graph.heatmap.compartmentName',
          calibratedUtilizationData.results.filter((elt) => elt.businessUnit).length > 0 &&
            'graph.filters.monitoringBusinessUnits.placeholder',
          'graph.heatmap.roomType',
          'graph.heatmap.period',
          getKpiLabel(kpi),
        ].filter(Boolean)

  if (isDayView) {
    const periodIndex = headers.findIndex((header) => header === 'graph.heatmap.period')
    headers.splice(periodIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  return {
    headers,
    data:
      calibratedUtilizationData.results.length > 0
        ? calibratedUtilizationData.results.map(
            ({
              domainName,
              nbScope,
              buildingName,
              zoneName,
              floorName,
              compartmentName,
              businessUnit,
              roomType,
              period,
              value,
              distributionUtilizationDuration,
              distributionUtilizationPercentage,
              valueInMinutes,
            }) => {
              if (distributionUtilizationDuration) {
                return [
                  domainName,
                  nbScope,
                  buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
                  zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
                  floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
                  compartmentName == undefined && headers.includes('graph.heatmap.compartmentName')
                    ? '_'
                    : compartmentName,
                  businessUnit == undefined && headers.includes('graph.filters.monitoringBusinessUnits.placeholder')
                    ? '_'
                    : businessUnit,
                  roomType,
                  period && isDayView ? formatDayName(period) : '',
                  period ? checkCache(period) : '',
                  formatRate(distributionUtilizationDuration.below30m),
                  formatRate(distributionUtilizationDuration.between30mAnd1h),
                  formatRate(distributionUtilizationDuration.between1h2h),
                  formatRate(distributionUtilizationDuration.between2h3h),
                  formatRate(distributionUtilizationDuration.between3h4h),
                  formatRate(distributionUtilizationDuration.beyond4h),
                ].filter(Boolean)
              } else if (distributionUtilizationPercentage) {
                return [
                  domainName,
                  nbScope,
                  buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
                  zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
                  floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
                  compartmentName == undefined && headers.includes('graph.heatmap.compartmentName')
                    ? '_'
                    : compartmentName,
                  businessUnit == undefined && headers.includes('graph.filters.monitoringBusinessUnits.placeholder')
                    ? '_'
                    : businessUnit,
                  roomType,
                  period && isDayView ? formatDayName(period) : '',
                  period ? checkCache(period) : '',
                  formatRate(distributionUtilizationPercentage.lessThan25Percent * 100),
                  formatRate(distributionUtilizationPercentage.between25And50Percent * 100),
                  formatRate(distributionUtilizationPercentage.between50And75Percent * 100),
                  formatRate(distributionUtilizationPercentage.moreThan75Percent * 100),
                ].filter(Boolean)
              } else if (valueInMinutes) {
                return [
                  domainName,
                  nbScope,
                  buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
                  zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
                  floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
                  compartmentName == undefined && headers.includes('graph.heatmap.compartmentName')
                    ? '_'
                    : compartmentName,
                  businessUnit == undefined && headers.includes('graph.filters.monitoringBusinessUnits.placeholder')
                    ? '_'
                    : businessUnit,
                  roomType,
                  period && isDayView ? formatDayName(period) : '',
                  period ? checkCache(period) : '',
                  formatMinutes(valueInMinutes),
                ].filter(Boolean)
              } else {
                return [
                  domainName,
                  nbScope,
                  buildingName == undefined && headers.includes('graph.heatmap.buildingName') ? '_' : buildingName,
                  zoneName == undefined && headers.includes('graph.monitoring.zoneName') ? '_' : zoneName,
                  floorName == undefined && headers.includes('graph.heatmap.floorName') ? '_' : floorName,
                  compartmentName == undefined && headers.includes('graph.heatmap.compartmentName')
                    ? '_'
                    : compartmentName,
                  businessUnit == undefined && headers.includes('graph.filters.monitoringBusinessUnits.placeholder')
                    ? '_'
                    : businessUnit,
                  roomType,
                  period && isDayView ? formatDayName(period) : '',
                  period ? checkCache(period) : '',
                  value,
                ].filter(Boolean)
              }
            },
          )
        : [],
  }
}

const getExportStackedColumnData = (stackedColumnData: ColumnChartDataWSType): ExportType => {
  const headers = [
    'graph.stackedColumn.capacityGroup',
    'graph.common.total',
    'graph.common.export.peopleCount',
    'graph.common.export.occupancyRate',
    'graph.common.export.occupancyDuration',
  ]
  const data: ExportDataType = []

  stackedColumnData.result.forEach((group) => {
    group.occupancies.forEach((occupancy) =>
      data.push([
        group.capacityGroup,
        group.roomsCount,
        occupancy.peopleCount,
        formatRate(occupancy.rate),
        formatMinutes(occupancy.durationMinutes),
      ]),
    )
  })

  return {
    headers,
    data,
  }
}
const getExportRestaurantColumnData = (RestaurantData: RestaurantDataWSType): ExportType => {
  const isDayView = RestaurantData.result[0] ? dateIsDay(RestaurantData.result[0].period) : false
  const headers = [
    'graph.heatmap.period',
    'graph.common.totalRestaurant',
    'graph.common.export.restaurantMax',
    'graph.common.export.cumulativeEntries',
  ]
  const data: ExportDataType = []

  if (isDayView) {
    const periodIndex = headers.findIndex((header) => header === 'graph.heatmap.period')
    headers.splice(periodIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  RestaurantData.result.forEach((group) => {
    const dataGroup = [checkCache(group.period), RestaurantData.capacity, group.max, group.cumulativeEntries]
    if (isDayView) {
      dataGroup.splice(0, 0, formatDayName(group.period))
    }
    data.push(dataGroup)
  })

  return {
    headers,
    data,
  }
}
const getExportBenchmarkData = (BenchmarkData: BenchmarkDataWSType): ExportType => {
  const headers = [
    'graph.heatmap.period',
    'graph.benchmark.buildingPerformance',
    'graph.benchmark.benchmarkFirstDecile',
    'graph.benchmark.benchmarkMedian',
    'graph.benchmark.benchmarkNinthDecile',
  ]
  const data: ExportDataType = []

  BenchmarkData.result.forEach((item) => {
    data.push([
      item.period,
      `${Math.round(item.value * 100)}%`,
      `${Math.round(item.benchmarkFirstDecile * 100)}%`,
      `${Math.round(item.benchmarkMedian * 100)}%`,
      `${Math.round(item.benchmarkNinthDecile * 100)}%`,
    ])
  })

  return {
    headers,
    data,
  }
}
const getExportDetailedOccupancyTableData = (heatmapData: HeatmapDataWSType): ExportType => {
  const isDayView = heatmapData.result[0] ? dateIsDay(heatmapData.result[0].period) : false

  const headers: string[] = [
    { key: 'buildingName', header: 'graph.heatmap.buildingName' },
    { key: 'zoneName', header: 'graph.monitoring.zoneName' },
    { key: 'floorName', header: 'graph.heatmap.floorName' },
    { key: 'compartmentName', header: 'graph.heatmap.compartmentName' },
    { key: 'roomName', header: 'graph.heatmap.roomName' },
    { key: 'workstationName', header: 'graph.heatmap.workstationName' },
    { key: 'spaceType', header: 'graph.heatmap.roomType' },
    { key: 'businessUnit', header: 'graph.heatmap.businessUnit' },
    { key: 'period', header: 'graph.heatmap.period' },
    { key: 'time', header: 'graph.heatmap.time' },
    { key: 'real', header: 'graph.heatmap.real' },
    { key: 'realDurationMinutes', header: 'graph.heatmap.realDurationMinutes' },
    { key: 'perceived', header: 'graph.heatmap.perceived' },
    { key: 'perceivedDurationMinutes', header: 'graph.heatmap.perceivedDurationMinutes' },
  ]
    .filter(({ key }) => heatmapData.result.some((elt) => elt[key]))
    .map(({ header }) => header)

  if (isDayView) {
    const periodIndex = headers.findIndex((header) => header === 'graph.heatmap.period')
    if (periodIndex !== -1) {
      headers.splice(periodIndex, 0, 'graph.common.export.dayOfTheWeek')
    }
  }

  const data = []
  const resultLength = heatmapData.result.length

  for (let i = 0; i < resultLength; i++) {
    const {
      buildingName,
      zoneName,
      floorName,
      compartmentName,
      roomName,
      workstationName,
      spaceType,
      businessUnit,
      period,
      time,
      real,
      perceived,
      realDurationMinutes,
      perceivedDurationMinutes,
    } = heatmapData.result[i]

    const row = [
      buildingName ?? (headers.includes('graph.heatmap.buildingName') ? '_' : ''),
      zoneName ?? (headers.includes('graph.monitoring.zoneName') ? '_' : ''),
      floorName ?? (headers.includes('graph.heatmap.floorName') ? '_' : ''),
      compartmentName ?? (headers.includes('graph.heatmap.compartmentName') ? '_' : ''),
      roomName ?? (headers.includes('graph.heatmap.roomName') ? '_' : ''),
      workstationName ?? (headers.includes('graph.heatmap.workstationName') ? '_' : ''),
      spaceType ??
        (headers.includes('graph.heatmap.roomType')
          ? '_'
          : spaceType === 'UNKNOWN'
          ? i18next.t('graph.filters.customAttribut.notSpecified')
          : spaceType),
      businessUnit ?? (headers.includes('graph.heatmap.businessUnit') ? '_' : ''),
      period && isDayView ? formatDayName(period) : '',
      period ? checkCache(period) : '',
      time ?? (headers.includes('graph.heatmap.time') ? '_' : ''),
      real !== undefined ? formatRate(real) : headers.includes('graph.heatmap.real') ? '_' : '',
      realDurationMinutes !== undefined
        ? formatMinutes(realDurationMinutes)
        : headers.includes('graph.heatmap.realDurationMinutes')
        ? '_'
        : '',
      perceived !== undefined ? formatRate(perceived) : headers.includes('graph.heatmap.perceived') ? '_' : '',
      perceivedDurationMinutes !== undefined
        ? formatMinutes(perceivedDurationMinutes)
        : headers.includes('graph.heatmap.perceivedDurationMinutes')
        ? '_'
        : '',
    ].filter(Boolean)

    data.push(row)
  }

  return {
    headers,
    data,
  }
}

const getExportDetailedOccupancyData = (heatmapData: DetailedOccupancyDataWSType, roomType): ExportType => {
  const isDayView = heatmapData.result[0] ? dateIsDay(heatmapData.result[0].label) : false
  const hours = heatmapData.max.map((maxData) => `graph.detailedOccupancy.xaxis.${maxData.hour.replace(':00', '')}`)
  const totalRoomsCount = heatmapData.totalRoomsCount ? heatmapData.totalRoomsCount : heatmapData.capacity
  const totalTranslation = 'graph.common.totalSpaces'
  const headers = [
    '', // Done on purpose because still not decided what to put in this header
    totalTranslation,
    ...hours,
    'graph.common.average',
    'graph.common.max',
    'graph.common.export.averageSpacesCount',
  ]

  if (isDayView) {
    const totalIndex = headers.findIndex((header) => header === totalTranslation)
    headers.splice(totalIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  const data: ExportDataType = []
  const maxData = ['Max', '', ...heatmapData.max.map((maxData) => formatRate(maxData.valueInPercentage))]
  const averageData = ['Average', '', ...heatmapData.average.map((avgData) => formatRate(avgData.valueInPercentage))]

  if (isDayView) {
    maxData.splice(1, 0, '')
    averageData.splice(1, 0, '')
  }

  data.push(maxData)
  data.push(averageData)
  heatmapData.result.forEach((dayData) => {
    const dayDataToPush = [
      checkCache(dayData.label),
      totalRoomsCount,
      ...dayData.valuesByHour.map((value) => formatRate(value.valueInPercentage)),
      formatRate(dayData.average),
      formatRate(dayData.max),
      dayData.averageCount,
    ]
    if (isDayView) {
      dayDataToPush.splice(1, 0, formatDayName(dayData.label))
    }
    data.push(dayDataToPush)
  })

  return {
    headers,
    data: data.reverse(),
  }
}
const getExportSpaceAttendanceData = (heatmapData: DetailedOccupancyDataWSType, roomType): ExportType => {
  const isDayView = heatmapData.result[0] ? dateIsDay(heatmapData.result[0].label) : false
  const hours = heatmapData.max.map((maxData) => `graph.detailedOccupancy.xaxis.${maxData.hour.replace(':00', '')}`)
  const totalRoomsCount = heatmapData.totalRoomsCount ? heatmapData.totalRoomsCount : heatmapData.capacity
  const headers = [
    '',
    'graph.common.export.capacity',
    ...hours,
    'graph.common.average',
    'graph.common.max',
    'graph.common.export.averagePeopleCount',
  ]

  if (isDayView) {
    const capacityIndex = headers.findIndex((header) => header === 'graph.common.export.capacity')
    headers.splice(capacityIndex, 0, 'graph.common.export.dayOfTheWeek')
  }

  const data: ExportDataType = []
  const maxData = ['Max', '', ...heatmapData.max.map((maxData) => formatRate(maxData.valueInPercentage))]
  const averageData = ['Average', '', ...heatmapData.average.map((avgData) => formatRate(avgData.valueInPercentage))]

  if (isDayView) {
    maxData.splice(1, 0, '')
    averageData.splice(1, 0, '')
  }

  data.push(maxData)
  data.push(averageData)
  heatmapData.result.forEach((dayData) => {
    const dayDataToPush = [
      checkCache(dayData.label),
      totalRoomsCount,
      ...dayData.valuesByHour.map((value) => formatRate(value.valueInPercentage)),
      formatRate(dayData.average),
      formatRate(dayData.max),
      dayData.averageCount,
    ]
    if (isDayView) {
      dayDataToPush.splice(1, 0, formatDayName(dayData.label))
    }
    data.push(dayDataToPush)
  })

  return {
    headers,
    data: data.reverse(),
  }
}

const getExportMonthlyData = (monthlyData: MonthlyDataWSType): ExportType => {
  const totalRoomsCount = monthlyData.totalRoomsCount
  const headers = [
    'graph.monthly.export.month',
    'graph.common.totalSpaces',
    'graph.monthly.export.realAverage',
    'graph.monthly.export.realNinthDecile',
    'graph.monthly.export.realMax',
    'graph.monthly.export.perceivedAverage',
    'graph.monthly.export.perceivedNinthDecile',
    'graph.monthly.export.perceivedMax',
  ]

  const data: ExportDataType = []

  monthlyData.result.forEach((monthlyData) => {
    const dataToPush = [
      checkCache(monthlyData.month),
      totalRoomsCount,
      formatRate(monthlyData.real.average),
      formatRate(monthlyData.real.ninthDecile),
      formatRate(monthlyData.real.max),
    ]

    if (monthlyData.perceived) {
      dataToPush.push(formatRate(monthlyData.perceived.average))
      dataToPush.push(formatRate(monthlyData.perceived.ninthDecile))
      dataToPush.push(formatRate(monthlyData.perceived.max))
    }

    data.push(dataToPush)
  })

  return {
    headers,
    data,
  }
}

const getExportCountingMax = (countingMaxData: CountingMaxDataWSType): ExportType => {
  const headers = [
    countingMaxData?.result?.some((elt) => elt.roomName) && 'graph.heatmap.roomName',
    countingMaxData?.result?.some((elt) => elt.roomType) && 'graph.heatmap.roomType',
    countingMaxData?.result?.some((elt) => elt.capacity) && 'graph.topFlop.capacity',
    countingMaxData?.result?.some((elt) => elt.day) && 'graph.common.export.dayOfTheWeek',
    countingMaxData?.result?.some((elt) => elt.day) && 'graph.countingMax.date',
    countingMaxData?.result?.some((elt) => elt.hour) && 'graph.countingMax.hour',
    countingMaxData?.result?.some((elt) => elt.occupationDuration) && 'graph.countingMax.occupancyDuration',
    countingMaxData?.result?.some((elt) => elt.utilizationMax) && 'graph.countingMax.utilizationMax',
  ].filter((item) => item !== null || item !== undefined)

  return {
    headers,
    data: countingMaxData?.result?.map(
      ({ roomName, roomType, capacity, day, hour, occupationDuration, utilizationMax }) => {
        return [
          headers.includes('graph.heatmap.roomName') ? roomName ?? '_' : roomName,
          headers.includes('graph.heatmap.roomType') ? roomType ?? '_' : roomType,
          headers.includes('graph.topFlop.capacity') ? capacity ?? '_' : capacity,
          headers.includes('graph.heatmap.period') ? (day ? formatDayName(day) : '_') : formatDayName(day),
          headers.includes('graph.heatmap.period') ? (day ? checkCache(day) : '_') : checkCache(day),
          headers.includes('graph.heatmap.time') ? hour ?? '_' : hour,
          headers.includes('graph.countingMax.occupancyDuration') ? occupationDuration ?? '_' : occupationDuration,
          headers.includes('graph.countingMax.utilizationMax') ? utilizationMax ?? '_' : utilizationMax,
        ].filter((item) => item !== null || item !== undefined)
      },
    ),
  }
}

export const getExportFormattedData = (
  roomType: any,
  graphType: GraphType,
  data: any,
  kpi: any,
  period: any,
  customAttributWks: any,
  customAttributCollab: any,
): ExportType => {
  switch (graphType) {
    case topFlopValue:
      return getExportTopFlopData(data)

    case stackedColumnValue:
      return getExportStackedColumnData(data, roomType)

    case detailedOccupancyValue:
      return getExportDetailedOccupancyData(data, roomType)

    case monthlyValue:
      return getExportMonthlyData(data)
    case RIE:
      return getExportRestaurantColumnData(data)
    case frequentationValue:
      return getExportSpaceAttendanceData(data, roomType)
    case spaceAttendanceValue:
      return getExportSpaceAttendanceData(data, roomType)
    case utilizationQualityValue:
      return getExportUtilizationQualityData(data, roomType)
    case detailedOccupancyTableValue:
      return getExportDetailedOccupancyTableData(data, roomType)
    case heatmapValue:
      return getExportHeatmapData(data, period)
    case monitoringValue:
      return getExportMonitoringData(data, roomType, customAttributWks, customAttributCollab)
    case calibratedUtilizationValue:
      return getExportCalibratedUtilizationData(data, roomType, kpi)
    case roomBookingValue:
      return getExportRoomBookingData(data)
    case benchmarkValue:
      return getExportBenchmarkData(data)
    case countingMaxValue:
      return getExportCountingMax(data)

    default:
      throw new Error('Unknown graph')
  }
}

import { FC } from 'react'
import FilterSkeleton from '../../atoms/filters/FilterSkeleton'

const FilterSkeletons: FC = () => (
  <>
    <FilterSkeleton type="long" />
    <FilterSkeleton />
    <FilterSkeleton type="short" />
    <FilterSkeleton />
    <FilterSkeleton type="long" />
    <FilterSkeleton type="long" />
    <FilterSkeleton />
    <FilterSkeleton type="short" />
  </>
)

export default FilterSkeletons

import { useEffect } from 'react'
import { EnvConfig } from 'utils/constants/routes/ApiRoutes'

export const useJimo = () => {
  // see https://docs.usejimo.com/guides/install-jimo/install-snippet/react/
  useEffect(() => {
    const jimoId = (window as unknown as EnvConfig).env.JIMO_ID

    if (jimoId) {
      window.jimo = []
      const s = document.createElement('script')

      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://undercity.usejimo.com/jimo-invader.js'
      window.JIMO_PROJECT_ID = jimoId
      window.JIMO_MANUAL_INIT = true
      document.getElementsByTagName('head')[0].appendChild(s)
    }
  }, [])
}

import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'

export default function Meteo() {
  const { t } = useTranslation()
  return (
    <div
      className="shadowSection justify-center  p-2 flex flex-col items-center w-1/5 mr-4"
      style={{ marginTop: '-80px', color: COLORS.darkGrey80 }}
    >
      <p className="text-center mb-6">{t('landingPage.analyseToCome')}</p>
      <IconSvg name="CANDLE_STICK" />
    </div>
  )
}

import { LocalizationInfo } from 'types/RieView'
import { buildLocalizationInfo, getBuildingsNames, getZones } from 'utils/LocalizationUtils'
import { Domain, Localization, Zone } from 'utils/api/types'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'

export const getRieInfosForBuilding = (domain: Domain, desks, building: string) => {
  const zones = getZones(domain, building)
  if (zones.length === 0) {
    return [buildLocalizationInfo(domain.sub_localizations as Localization[], desks, building)]
  }
  return zones
    .sort(zoneSorter)
    .map((zone) => buildLocalizationInfo(domain.sub_localizations as Localization[], desks, building, zone.name))
}

export const getRieInfos = (domain: Domain, desks: SensorStatusDto[]): LocalizationInfo[] => {
  const buildings = getBuildingsNames(domain)

  if (!domain.sub_localizations.length) {
    return []
  }

  if (buildings.length > 0 && domain.sub_localizations.length > 0) {
    return buildings
      .map((building) => getRieInfosForBuilding(domain, desks, building))
      .reduce((acc: LocalizationInfo[], infos: LocalizationInfo[]) => {
        return acc.concat(infos)
      }, [])
  }

  const zones = getZones(domain)

  if (zones.length > 0) {
    return zones
      .sort(zoneSorter)
      .map((zone) => buildLocalizationInfo(domain.sub_localizations as Localization[], desks, undefined, zone.name))
  }

  return [buildLocalizationInfo(domain.sub_localizations as Localization[], desks)]
}
const zoneSorter = (zoneA: Zone, zoneB: Zone): number => {
  if (!(zoneA.name && zoneB.name)) {
    return 0
  }

  return zoneA.code === 'OTHER' ? 0 : zoneA.name < zoneB.name ? -1 : zoneA.name > zoneB.name ? 1 : 0
}

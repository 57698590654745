import { authService } from 'services/authService'
import { TokenResponse } from 'types/Auth'

export const fetchNewToken = async (refreshToken: string): Promise<TokenResponse | null> => {
  try {
    return await authService.getAccessToken(refreshToken)
  } catch (error) {
    return null
  }
}

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, ...structure } = graphsStructure.bookable
const values = Object.entries(structure).map(([value, label]) => ({ label, value }))

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.BOOKABLE.value === value) return state

  return {
    ...state,
    BOOKABLE: {
      ...initialFilterState.BOOKABLE,
      value,
    },
  }
}

const BookableFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const bookableValue = state.BOOKABLE.value

  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const bookableData = options.find((option) => option.value === bookableValue)
  const type = getFilterType(state.GRAPH.value, state.BOOKABLE.active, bookableValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={bookableData?.label}
      disabled={!state.BOOKABLE.active && !state.BOOKABLE.value}
      selected={bookableValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default BookableFilter

import { LocalizationInfo, RestaurantAvailableInfo } from 'types/RieView'
import { bpConvert } from 'utils/breakPointsUtils'
import { useTranslation } from 'react-i18next'
import RieCell from './RieCell'
import RieInfoCell from './RieInfoCell'
import clsx from 'clsx'

const width = window.innerWidth

type RieTableProps = {
  infos: LocalizationInfo[]
  mode?: 'dark' | 'light'
  size?: 'sm' | 'lg'
  maxHeight?: string
}

const RieTable = ({ infos, mode = 'dark', size = 'sm', maxHeight }: RieTableProps): JSX.Element => {
  const { t } = useTranslation()
  const borderGridClass = `border${size === 'sm' ? '-2' : '-4'} gap-${size === 'sm' ? '0.5' : '1'}`
  const baseGridClass = 'grid grid-cols-3 gap'
  const gridClass = clsx(
    baseGridClass,
    mode === 'dark' ? 'dark bg-basic-100 border-basic-100' : 'bg-basic border-basic',
    borderGridClass,
  )

  if (!infos) {
    return <></>
  }

  const sortRestaurants = (informations: LocalizationInfo[]) => {
    const restaurants: RestaurantAvailableInfo[] = []
    informations.forEach((info) => {
      info.floorInfos.forEach((floor) => {
        floor.restaurants.forEach((restaurant) => {
          restaurant.displayName = restaurant.displayName || floor.floorName
          restaurants.push(restaurant)
        })
      })
    })
    return restaurants
  }

  return (
    <div
      className={gridClass}
      style={{
        gridTemplateRows: size === 'lg' ? `minmax(${bpConvert(width, 70)}, auto)` : 70,
        gridAutoRows: size === 'lg' ? bpConvert(width, 130) : 'auto',
        gridTemplateColumns: size === 'lg' ? 'minmax(160px,auto) repeat(2, 1fr)' : '160px repeat(2, 1fr)',
        maxHeight: maxHeight ?? 'none',
        overflow: maxHeight ? 'auto' : 'visible',
      }}
    >
      <RieCell size={size}>{/* Empty Cell */}</RieCell>
      <RieCell size={size}>{t('realTime.rieView.access')}</RieCell>
      <RieCell size={size}>{t('realTime.rieView.rateOccupancy')}</RieCell>

      {sortRestaurants(infos)
        .sort((a, b) => parseInt(a.displayOrder as string) - parseInt(b.displayOrder as string))
        .map((restaurant) => (
          <>
            <RieCell size={size}>
              <p className="truncate text-left">{restaurant.name}</p>
            </RieCell>
            <RieCell size={size}>
              <p className="truncate text-left">{restaurant.displayName}</p>
            </RieCell>
            <RieCell size={size}>
              <RieInfoCell size={size} infos={restaurant} />
            </RieCell>
          </>
        ))}
    </div>
  )
}

export default RieTable

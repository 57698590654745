import { FC, useContext, useEffect, useState } from 'react'
import { DetailedOccupancyDataType, FrequentationDataWSType } from 'types/GraphDataType'
import FrequentationChart from './FrequentationChart'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { dayName } from 'utils/dateUtils'

type DetailedOccupancyFormattedDataType = {
  series: DetailedOccupancyDataType
  categories: string[]
  minifiedCategories: string[]
}

type Props = {
  loading: boolean
  data: FrequentationDataWSType
  id?: string | undefined
  title?: string
  colors: string[]
}

const FrequentationController: FC<Props> = (props) => {
  const { loading, data, id, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<DetailedOccupancyFormattedDataType>()
  const { state: filters } = useContext(GraphFiltersContext)

  useEffect(() => {
    if (loading) {
      return
    }

    // Transform the data from the server's format to the library's format
    // Explanation why we multiplied all values by 10 :
    // As we want to inject false values to show empty columns to separate Avg/Max to the rest, we need to differentiate
    // the real zeros (0%) from the false ones (separators). We couldn't use floats (like 0.1) because the library rounds
    // the values in the legend and fucks everything up.
    // Therefore, we don't work in "per cents" but in "per thousands". (in clear, not values *100, but *1000) so
    // that the values at 0 are false values and values at 1 are real 0s. Because the previous "real 1s" are now "10".
    // Then, in the options of the graph, we can look for those 1 values and show 0 instead, and divide all other values
    // by 10.
    const formattedSeries = data.result.map((el) => {
      const formattedData = el.valuesByHour.map((subEl) => {
        return {
          x: subEl.hour,
          y: Math.max(subEl.valueInPercentage * 10, 1),
          count: subEl.count,
        }
      })
      const lang = navigator.language
      const currentDate = new Date(el.label)
      const startDate = new Date(currentDate.getFullYear(), 0, 1)
      //@ts-ignore
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
      const weekNumber = Math.ceil(days / 7)
      return {
        name:
          new Date(el.label).getDay() == 1
            ? t('graph.common.week') +
              weekNumber +
              ' ' +
              '-' +
              ' ' +
              dayName(new Date(el.label)) +
              ' ' +
              String(new Date(el.label).getDate()).padStart(2, '0')
            : dayName(new Date(el.label)) + ' ' + String(new Date(el.label).getDate()).padStart(2, '0'),
        data: [
          ...formattedData,
          { x: ' ', y: 0 },
          { x: 'avg', y: Math.max(el.average * 10, 1), count: el.averageCount },
          { x: 'max', y: Math.max(el.max * 10, 1), count: el.maxCount },
        ],
      }
    })
    const formattedMax = data.max.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const formattedAvg = data.average.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const emptyLine = data.average.map((el, index) => ({
      x: index.toString(),
      y: 0,
    }))

    const baseCategories = data.average.map((el) => `${el.hour}`)
    const categories = [
      ...baseCategories,
      ' ',
      ' ',
      'graph.detailedOccupancy.xaxis.avg',
      'graph.detailedOccupancy.xaxis.max',
    ]
    const minifiedCategories = [
      ...baseCategories.map((el, index) => (index % 2 === 0 ? el : '')),
      t('graph.detailedOccupancy.xaxis.avg'),
      t('graph.detailedOccupancy.xaxis.max'),
    ]

    setFormattedData({
      series: [
        { name: t('graph.filters.kpi.max'), data: formattedMax },
        { name: t('graph.filters.kpi.average'), data: formattedAvg },
        { name: ' ', data: emptyLine },
        ...formattedSeries.reverse(),
      ],
      categories,
      minifiedCategories,
    })
  }, [loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="heatmap" />
  }

  return (
    <FrequentationChart
      id={id}
      subtitle={t('graph.frequentation.capacity', { capacity: data.capacity })}
      capacity={data.capacity || 0}
      series={formattedData.series}
      categories={formattedData.categories}
      minifiedCategories={formattedData.minifiedCategories}
      colors={colors}
    />
  )
}

export default FrequentationController

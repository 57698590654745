import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import IconSvg from '../icons/IconSvg'

type Props = {
  className?: string
  value: unknown
  onChange: (value: never) => void
  label?: string
  placeholder: string
}

const Select: FC<Props> = (props) => {
  const { className = '', value, onChange, label, placeholder, children } = props

  return (
    <div className={`w-full ${className}`}>
      <Listbox value={value} onChange={onChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-basic-200 rounded-lg shadow-md cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{label || placeholder}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <IconSvg name="ARROW_SELECT" color="GREY" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-basic-200 rounded-md shadow-lg max-h-96 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default Select

import { FC } from 'react'

type Props = {
  className?: string
  wrapperClassName?: string
}

const SubPageLayout: FC<Props> = (props) => {
  const { wrapperClassName = '', className = '', children } = props

  return (
    <div className={`flex w-full max-w-full h-custom-favorite-body ${wrapperClassName}`}>
      <div className={`flex w-full h-full max-h-full ${className}`}>{children}</div>
    </div>
  )
}

export default SubPageLayout

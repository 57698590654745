export const ReactQueryConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 60000, // 60s
      staleTime: 30000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
}

import { COLORS } from 'assets/colors/colors'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { categories } from './barChart'

export const fakeRestaurantData = {
  capacity: 222,
  result: [
    {
      period: '2022-02-14 / 2022-02-18',
      max: 350,
      cumulation: 28,
    },
    {
      period: '2022-11-30',
      max: 50,
      cumulation: 28,
    },
    {
      period: '2022M01',
      max: 170,
      cumulation: 128,
    },
  ],
}

type OptionsType = {
  id: string | undefined
  title?: string
  subtitle: string
  categories: string[]
  peopleCount?: number
  colors: string[]
}

export const getRestaurantOptions = (options: OptionsType): ChartProps<HeatmapSeries>['options'] => ({
  title: {
    text: options.title,
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  subtitle: {
    text: options.subtitle,
    style: {
      fontSize: '16px',
      fontWeight: 'normal',
      color: COLORS.darkGrey50,
    },
  },
  colors:
    options.colors?.length > 0
      ? [
          options.colors.find((obj) => obj.type == 'MAX')?.color,
          options.colors.find((obj) => obj.type == 'CUMULATIVE_ENTRIES')?.color,
        ]
      : ['#d33087', '#1CB9D6'],
  legend: {
    show: true,
    position: 'top',
    showForSingleSeries: true,
    horizontalAlign: 'right',
    offsetY: -20,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      radius: 12,
    },
    // @ts-ignore: Unreachable code error
    formatter: function (seriesName, opts) {
      return [opts.w.config.series[opts.seriesIndex].description]
    },
  },
  chart: {
    id: options.id ? options.id.toString() : 'restaurant-chart',
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: true,
    x: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: false,
      columnWidth: '50%',
    },
  },
  xaxis: {
    type: 'category',
    categories: options.categories,
    labels: {
      rotateAlways: true,
      style: {
        // If there are less color values given to the lib than there are categories, the rest will be printed in black.
        // So we must give as many colors as there are categories on the axis x.
        colors: categories.map(() => '#9C9CB5'),
      },
    },
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(0)
      },
      style: {
        colors: ['#9C9CB5'],
      },
    },
  },
  fill: {
    opacity: 1,
  },
  annotations: {
    yaxis: [
      {
        y: options.peopleCount,
        borderColor: '#FF7254',
        strokeDashArray: 8,
      },
    ],
  },
})

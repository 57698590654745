import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'

type UpdateColorConfigurationBody = {
  enabled: boolean

  colorsConfiguration: [
    {
      graph: string
      type: string
      color: string
    },
  ]
}
const updateColorsConfiguration = (clientCode: string, body: UpdateColorConfigurationBody): Promise<any> => {
  return execute(generatePath(API.CONFIGURATION.COLORS, { clientCode }), 'PUT', body)
}

export const configurationService = {
  updateColors: updateColorsConfiguration,
}

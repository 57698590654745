import { COLORS } from 'assets/colors/colors'
import { FC, useContext } from 'react'
import { FilterContext } from './FilterContext'

type Props = {
  value: string
  id?: number
  disabled?: boolean
}

const FilterOption: FC<Props> = (props) => {
  const { value, children, id, disabled } = props
  const { selected, onSelect } = useContext(FilterContext)
  const isSelected = selected?.toString() === value.toString()

  const onOptionClick = () => {
    const newValue = isSelected ? undefined : value

    onSelect(newValue, id)
  }

  return (
    <div
      className={`flex justify-between items-center p-2 cursor-pointer ${
        isSelected ? 'text-basic bg-basic-300' : 'text-basic-500 bg-basic-100 hover:bg-basic-200'
      } border-b-1 border-basic-200 overflow-hidden overflow-ellipsis whitespace-nowrap select-none ${
        disabled ? 'pointer-events-none' : ''
      }`}
      style={{ color: disabled ? COLORS.lightGrey : '' }}
      onClick={onOptionClick}
    >
      {children}
    </div>
  )
}

export default FilterOption

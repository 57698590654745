import { ChangeEvent, FC, useState } from 'react'
import Input from '../../atoms/input/Input'
import IconSvg from '../../atoms/icons/IconSvg'
import DraggableFavoriteItem from './DraggableFavoriteItem'
import { useTranslation } from 'react-i18next'
import { SavedReport } from 'types/SavedReportsTypes'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import { LoaderComponent } from '../../atoms/loader/Loader'
import { Droppable } from 'react-beautiful-dnd'

type Props = {
  isLoading: boolean
  isDashboard?: boolean
  items?: SavedReport[]
  selectedItem?: number
  onItemClick: (id: number, title: string) => void
}

const DroppableFavoriteList: FC<Props> = (props) => {
  const { isLoading, isDashboard = false, items = [], selectedItem, onItemClick } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  // We preload the structure so that it's already available when we open the filters container
  useFetchStructure()

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)

  const filteredItems = items.filter((item) => item.name.toString().toLowerCase().includes(search.toLowerCase()))

  return (
    <Droppable droppableId="characters">
      {(provided) => (
        <div
          className="h-full px-2 w-1/3 max-w-1/3 min-w-300 overflow-y-auto bg-basic"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <div className="relative pt-2">
            <Input white placeholder={t('common.search')} onChange={onInputChange} />
            <IconSvg name="SEARCH" className="absolute right-4 top-6 pointer-events-none" />
          </div>
          {isLoading ? (
            <div className="mt-12 flex justify-center">
              <LoaderComponent />
            </div>
          ) : (
            <>
              {filteredItems.length === 0 && (
                <div className="mt-8 flex justify-center">
                  <span className="text-basic-100">{t('common.noResult')}</span>
                </div>
              )}
              {filteredItems.map((item, index) => (
                <DraggableFavoriteItem
                  key={item.name}
                  id={item.id}
                  index={index}
                  title={item.name}
                  isDashboard={isDashboard}
                  usedInDashboard={item.usedInDashboard}
                  filters={item.filters}
                  active={item.id === selectedItem}
                  onClick={onItemClick}
                />
              ))}
            </>
          )}
        </div>
      )}
    </Droppable>
  )
}

export default DroppableFavoriteList

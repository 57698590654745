import Dialog from 'components/atoms/dialog/Dialog'
import DialogBody from 'components/atoms/dialog/DialogBody'
import { SketchPicker, MaterialPicker } from 'react-color'
import IconSvg from 'components/atoms/icons/IconSvg'
import Button from 'components/atoms/button/Button'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'assets/colors/colors'
interface ColorPickerProps {
  selectedColorsNumber: number
  colors: string[]
  setColors: Dispatch<SetStateAction<string[]>>
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  getColorPickerNumbers: () => number
  initialColors: string[]
  mutate: () => void
  threeGraphColors: string[]
  oneGraphColors: string[]
  twoGraphColors: string[]
  fourBenchmarkGraphColors: string[]
  fourBookingGraphColors: string[]
  fourOccupancyGraphColors: string[]
  elevenGraphColors: string[]
}
const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedColorsNumber,
  colors,
  setColors,
  handleChange,
  getColorPickerNumbers,
  initialColors,
  mutate,
  threeGraphColors,
  oneGraphColors,
  twoGraphColors,
  fourBenchmarkGraphColors,
  fourBookingGraphColors,
  fourOccupancyGraphColors,
  elevenGraphColors,
}) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const [canSave, setCanSave] = useState(colors.length === getColorPickerNumbers(selectedColorsNumber))

  const [displayColorPickers, setDisplayColorPickers] = useState(
    Array(getColorPickerNumbers(selectedColorsNumber)).fill(false),
  )

  const handleClick = (index) => {
    setDisplayColorPickers((prevDisplayColorPickers) => {
      for (let i = 0; i <= prevDisplayColorPickers.length; i++) {
        if (i !== index && prevDisplayColorPickers[i]) prevDisplayColorPickers[i] = !prevDisplayColorPickers[i]
      }
      const updatedDisplayColorPickers = [...prevDisplayColorPickers]
      updatedDisplayColorPickers[index] = !updatedDisplayColorPickers[index]
      return updatedDisplayColorPickers
    })
  }

  useEffect(() => {
    setCanSave(colors.length === getColorPickerNumbers(selectedColorsNumber))
  }, [colors, selectedColorsNumber])

  const handleSave = () => {
    const body = {
      enabled: true,
      colorsConfiguration: [],
    }

    const pushColorConfiguration = (graph, type, color) => {
      body.colorsConfiguration.push({ graph, type, color })
    }
    const pushOneColorConfiguration = (colorsTable: string[]) => {
      pushColorConfiguration(
        'ONE_COLOR',
        'SINGLE_COLOR',
        colorsTable.find((obj) => obj.type == 'SINGLE_COLOR')?.color || colorsTable[0],
      )
    }
    const pushTwoGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration('TWO_COLORS', 'MAX', colorsTable.find((obj) => obj.type == 'MAX')?.color || colorsTable[0])
      pushColorConfiguration(
        'TWO_COLORS',
        'CUMULATIVE_ENTRIES',
        colorsTable.find((obj) => obj.type == 'CUMULATIVE_ENTRIES')?.color || colorsTable[1],
      )
    }
    const pushThreeGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration(
        'THREE_COLORS',
        'MOY',
        colorsTable.find((obj) => obj.type == 'MOY')?.color || colorsTable[0],
      )
      pushColorConfiguration(
        'THREE_COLORS',
        'NINETH_DECILE',
        colorsTable.find((obj) => obj.type == 'NINETH_DECILE')?.color || colorsTable[1],
      )
      pushColorConfiguration(
        'THREE_COLORS',
        'MAX',
        colorsTable.find((obj) => obj.type == 'MAX')?.color || colorsTable[2],
      )
    }
    const pushFourOccupancyGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration(
        'FOUR_COLORS_OCCUPANCY',
        'EMPTY',
        colorsTable.find((obj) => obj.type == 'EMPTY')?.color || colorsTable[0],
      )
      pushColorConfiguration(
        'FOUR_COLORS_OCCUPANCY',
        'UNDER_OCCUPIED',
        colorsTable.find((obj) => obj.type == 'UNDER_OCCUPIED')?.color || colorsTable[1],
      )
      pushColorConfiguration(
        'FOUR_COLORS_OCCUPANCY',
        'BALANCED',
        colorsTable.find((obj) => obj.type == 'BALANCED')?.color || colorsTable[2],
      )
      pushColorConfiguration(
        'FOUR_COLORS_OCCUPANCY',
        'SATURATED',
        colorsTable.find((obj) => obj.type == 'SATURATED')?.color || colorsTable[3],
      )
    }
    const pushFourBenchmarkGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration(
        'FOUR_COLORS_BENCHMARK',
        'VALUE',
        colorsTable.find((obj) => obj.type == 'VALUE')?.color || colorsTable[3],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BENCHMARK',
        'FIRST_DECILE',
        colorsTable.find((obj) => obj.type == 'FIRST_DECILE')?.color || colorsTable[0],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BENCHMARK',
        'MEDIAN',
        colorsTable.find((obj) => obj.type == 'MEDIAN')?.color || colorsTable[1],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BENCHMARK',
        'NINTH_DECILE',
        colorsTable.find((obj) => obj.type == 'NINTH_DECILE')?.color || colorsTable[2],
      )
    }
    const pushFourBookingGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration(
        'FOUR_COLORS_BOOKING',
        'FREE',
        colorsTable.find((obj) => obj.type == 'FREE')?.color || colorsTable[0],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BOOKING',
        'NO_SHOW',
        colorsTable.find((obj) => obj.type == 'NO_SHOW')?.color || colorsTable[1],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BOOKING',
        'NOT_BOOKED',
        colorsTable.find((obj) => obj.type == 'NOT_BOOKED')?.color || colorsTable[2],
      )
      pushColorConfiguration(
        'FOUR_COLORS_BOOKING',
        'BOOKED_AND_OCCUPIED',
        colorsTable.find((obj) => obj.type == 'BOOKED_AND_OCCUPIED')?.color || colorsTable[3],
      )
    }
    const pushElevenGraphColor = (colorsTable: string[]) => {
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'ZERO_TO_TEN',
        colorsTable.find((obj) => obj.type == 'ZERO_TO_TEN')?.color || colorsTable[0],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'ELEVEN_TO_TWENTY',
        colorsTable.find((obj) => obj.type == 'ELEVEN_TO_TWENTY')?.color || colorsTable[1],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'TWENTY_ONE_TO_THIRTY',
        colorsTable.find((obj) => obj.type == 'TWENTY_ONE_TO_THIRTY')?.color || colorsTable[2],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'THIRTY_ONE_TO_FORTY',
        colorsTable.find((obj) => obj.type == 'THIRTY_ONE_TO_FORTY')?.color || colorsTable[3],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'FORTY_ONE_TO_FIFTY',
        colorsTable.find((obj) => obj.type == 'FORTY_ONE_TO_FIFTY')?.color || colorsTable[4],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'FIFTY_ONE_TO_SIXTY',
        colorsTable.find((obj) => obj.type == 'FIFTY_ONE_TO_SIXTY')?.color || colorsTable[5],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'SIXTY_ONE_TO_SEVENTY',
        colorsTable.find((obj) => obj.type == 'SIXTY_ONE_TO_SEVENTY')?.color || colorsTable[6],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'SEVENTY_ONE_TO_EIGHTY',
        colorsTable.find((obj) => obj.type == 'SEVENTY_ONE_TO_EIGHTY')?.color || colorsTable[7],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'EIGHTY_ONE_TO_NINETY',
        colorsTable.find((obj) => obj.type == 'EIGHTY_ONE_TO_NINETY')?.color || colorsTable[8],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'NINETY_ONE_TO_ONE_HUNDRED',
        colorsTable.find((obj) => obj.type == 'NINETY_ONE_TO_ONE_HUNDRED')?.color || colorsTable[9],
      )
      pushColorConfiguration(
        'ELEVEN_COLORS',
        'MORE_THAN_ONE_HUNDRED',
        colorsTable.find((obj) => obj.type == 'MORE_THAN_ONE_HUNDRED')?.color || colorsTable[10],
      )
    }

    const selectedValue = selectedColorsNumber?.value

    switch (selectedValue) {
      case 'ONE_COLOR':
        pushOneColorConfiguration(colors)
        break
      case 'TWO_COLORS':
        pushTwoGraphColor(colors)
        break
      case 'THREE_COLORS':
        pushThreeGraphColor(colors)
        break
      case 'FOUR_COLORS_OCCUPANCY':
        pushFourOccupancyGraphColor(colors)
        break
      case 'FOUR_COLORS_BENCHMARK':
        pushFourBenchmarkGraphColor(colors)
        break
      case 'FOUR_COLORS_BOOKING':
        pushFourBookingGraphColor(colors)
        break
      case 'ELEVEN_COLORS':
        pushElevenGraphColor(colors)
        break
      default:
        break
    }
    if (
      selectedColorsNumber?.value !== 'THREE_COLORS' &&
      threeGraphColors.filter((elt) => elt === '#FFFFFF').length !== threeGraphColors.length
    ) {
      pushThreeGraphColor(threeGraphColors)
    }
    if (selectedColorsNumber?.value !== 'ONE_COLOR' && oneGraphColors?.length > 0 && oneGraphColors[0] !== '#FFFFFF') {
      pushOneColorConfiguration(oneGraphColors)
    }
    if (
      selectedColorsNumber?.value !== 'TWO_COLORS' &&
      twoGraphColors.filter((elt) => elt === '#FFFFFF').length !== twoGraphColors.length
    ) {
      pushTwoGraphColor(twoGraphColors)
    }
    if (
      selectedColorsNumber?.value !== 'FOUR_COLORS_OCCUPANCY' &&
      fourOccupancyGraphColors.filter((elt) => elt === '#FFFFFF').length !== fourOccupancyGraphColors.length
    ) {
      pushFourOccupancyGraphColor(fourOccupancyGraphColors)
    }
    if (
      selectedColorsNumber?.value !== 'FOUR_COLORS_BENCHMARK' &&
      fourBenchmarkGraphColors.filter((elt) => elt === '#FFFFFF').length !== fourBenchmarkGraphColors.length
    ) {
      pushFourBenchmarkGraphColor(fourBenchmarkGraphColors)
    }
    if (
      selectedColorsNumber?.value !== 'FOUR_COLORS_BOOKING' &&
      fourBookingGraphColors.filter((elt) => elt === '#FFFFFF').length !== fourBookingGraphColors.length
    ) {
      pushFourBookingGraphColor(fourBookingGraphColors)
    }
    if (
      selectedColorsNumber?.value !== 'ELEVEN_COLORS' &&
      elevenGraphColors.filter((elt) => elt === '#FFFFFF').length !== elevenGraphColors.length
    ) {
      pushElevenGraphColor(elevenGraphColors)
    }

    mutate(body)
  }
  const handleReset = () => {
    setColors(initialColors)
    setCanSave(false)
    onClose()
  }

  useEffect(() => {
    const hasEnteredLastColor = colors.length === getColorPickerNumbers(selectedColorsNumber)
    const hasFullySelectedLastColor = colors[colors.length - 1] !== '#FFFFFF'
    setCanSave(hasEnteredLastColor && hasFullySelectedLastColor)
  }, [colors, selectedColorsNumber])

  const onClose = () => {
    setOpen(false)
  }
  const handleSketchPickerClick = (event) => {
    event.stopPropagation()
  }
  const percentages = [
    null,
    '0-10%',
    '11-20%',
    '21-30%',
    '31-40%',
    '41-50%',
    '51-60%',
    '61-70%',
    '71-80%',
    '81-90%',
    '91-100%',
    '>100%',
  ]
  const textMappings = {
    TWO_COLORS: {
      1: t('graph.restaurant.maxLegend'),
      2: t('graph.restaurant.cumulationLegend'),
    },
    THREE_COLORS: {
      1: t('graph.filters.kpi.average'),
      2: t('graph.filters.kpi.ninthDecile'),
      3: t('graph.filters.kpi.max'),
    },
    FOUR_COLORS_OCCUPANCY: {
      1: t('landingPage.collaborativeSpacesUtilization.empty'),
      2: t('landingPage.collaborativeSpacesUtilization.under-occupied'),
      3: t('landingPage.collaborativeSpacesUtilization.balanced'),
      4: t('landingPage.collaborativeSpacesUtilization.saturated'),
    },
    FOUR_COLORS_BENCHMARK: {
      1: t('graph.benchmark.benchmarkFirstDecile'),
      2: t('graph.benchmark.benchmarkMedian'),
      3: t('graph.benchmark.benchmarkNinthDecile'),
      4: t('graph.benchmark.buildingPerformance'),
    },
    FOUR_COLORS_BOOKING: {
      1: t('graph.roomBooking.FREE'),
      2: t('graph.roomBooking.NO_SHOW'),
      3: t('graph.roomBooking.NOT_BOOKED'),
      4: t('graph.roomBooking.BOOKED_AND_OCCUPIED'),
    },
  }
  return (
    colors &&
    colors.length == getColorPickerNumbers(selectedColorsNumber) && (
      <div>
        {Array(getColorPickerNumbers(selectedColorsNumber))
          .fill()
          .map((_, i) => i + 1)
          .map((index) => (
            <div key={index} className="flex items-center mb-8 relative">
              <div
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '1rem',
                  background: `${colors[index - 1]}`,
                  borderRadius: '1px',
                  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick(index - 1)}
              ></div>
              {displayColorPickers[index - 1] ? (
                <div style={{ position: 'absolute', left: '10%', zIndex: '2' }}>
                  <SketchPicker
                    style={{ position: 'absolute', top: '0' }}
                    color={colors[index - 1]}
                    onChange={(color) => handleChange(index - 1, color, displayColorPickers[index - 1])}
                    onClick={handleSketchPickerClick}
                  />
                </div>
              ) : null}
              <p className="mr-4">{t('settings.colors.switch')}</p>
              <div className="color-input">
                <MaterialPicker
                  color={colors[index - 1]}
                  onChange={(color) => handleChange(index - 1, color, displayColorPickers[index - 1])}
                />
              </div>
              <div
                className="absolute flex"
                style={{
                  right:
                    selectedColorsNumber?.value === 'TWO_COLORS'
                      ? '-70px'
                      : selectedColorsNumber?.value === 'FOUR_COLORS_BOOKING' ||
                        selectedColorsNumber?.value === 'FOUR_COLORS_BENCHMARK'
                      ? '-120px'
                      : '0',
                }}
              >
                {textMappings[selectedColorsNumber?.value]?.[index] && (
                  <p className="mx-4">{textMappings[selectedColorsNumber?.value]?.[index]}</p>
                )}
                {selectedColorsNumber?.value === 'ELEVEN_COLORS' && index >= 1 && index <= 11 && (
                  <p className="mx-4">{percentages[index]}</p>
                )}
                <div style={{ width: '20px', height: '20px', backgroundColor: colors[index - 1] }} />
              </div>
            </div>
          ))}
        {colors?.length > 0 && (
          <div className="flex justify-end">
            <button
              disabled={!canSave}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: canSave ? '#D33087' : '#E95401',
                color: '#fff',
                padding: '5px 10px',
                margin: '5px',
                borderRadius: '5px',
                cursor: canSave ? 'pointer' : 'not-allowed',
              }}
              onClick={handleSave}
            >
              {t('settings.colors.save')}
              <IconSvg name="DONE" color="WHITE" className="ml-4" />
            </button>
            <button
              disabled={!canSave}
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: canSave ? '#D33087' : '#E95401',
                color: '#fff',
                padding: '5px 10px',
                margin: '5px',
                borderRadius: '5px',
                cursor: canSave ? 'pointer' : 'not-allowed',
              }}
              onClick={() => setOpen(true)}
            >
              {t('settings.colors.reset')}
              <IconSvg name="UNDO" color="WHITE" className="ml-4" />
            </button>
          </div>
        )}
        {open && (
          <Dialog isOpen={open} onClose={onClose}>
            <DialogBody>
              <div className="flex items-center justify-center m-2">
                <p className="text-center">{t('settings.colors.resetColors')}</p>
              </div>
              <div className="flex items-center justify-center mb-8">
                <Button
                  onClick={handleReset}
                  className="m-2 xl:mb-0 flex items-center justify-center border-transparent"
                  style={{ backgroundColor: COLORS.deepCerise }}
                >
                  {t('common.confirm')}
                </Button>
              </div>
            </DialogBody>
          </Dialog>
        )}
      </div>
    )
  )
}
export default ColorPicker

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icons/IconSvg'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'

import { savedReportsService } from 'services/savedReportsService'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { DashboardType } from 'types/DashboardType'
import DeleteDashboardConfirmationDialog from '../dialogs/confirmation/DeleteDashboardConfirmationDialog'
import ExportationDialog from '../dialogs/exportation/ExportationDialog'
import AutomaticSendingDialog from '../dialogs/automaticSending/AutomaticSending'

type Props = {
  selectedDashboard: DashboardType
  selectedDashboardData: any
  onDeleteSuccess: () => void
  onEditMode: () => void
  editMode: boolean
  currentPage: any
}

const SelectedDashboardActions: FC<Props> = (props) => {
  const { selectedDashboard, onDeleteSuccess, onEditMode, editMode, selectedDashboardData, currentPage } = props
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const queryClient = useQueryClient()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [exportModal, setExportModal] = useState(false)
  const [automaticSendingModal, setAutomaticSendingModal] = useState(false)
  const [open, setOpen] = useState(false)

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.DASHBOARDS.DUPLICATE_DASHBOARD(selectedDashboard.id),
    mutationFn: () => savedReportsService.duplicateDashboard(clientCode, selectedDashboard.id),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode))
      toast.success(t('dashboard.duplicateSuccess', { name: selectedDashboard.name }))
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onDeleteButtonClick = () => setDeleteOpen(true)
  const onCancel = () => setDeleteOpen(false)

  const onExportClick = () => setExportModal(!exportModal)
  const onSentClick = () => setAutomaticSendingModal(!automaticSendingModal)
  const onClose = () => {
    setOpen(false)
    setExportModal(false)
    setAutomaticSendingModal(false)
  }

  return (
    <div className="flex justify-end space-x-4 mt-4">
      <Button
        onClick={onExportClick}
        className="flex items-center justify-center"
        rounded="xl"
        disabled={selectedDashboardData == undefined}
      >
        {t('analysis.report.export')}
        <IconSvg name="SHARE" color={'WHITE'} className="ml-3" />
      </Button>
      <Button onClick={onSentClick} className=" flex items-center justify-center" rounded="xl" disabled={editMode}>
        {t('dashboard.automaticSending')}
        <IconSvg name="SEND" color={'WHITE'} className="ml-3" />
      </Button>
      <Button
        color={editMode ? 'blue' : 'default'}
        rounded="lg"
        disabled={deleteOpen}
        className="flex flex-row items-center"
        onClick={onEditMode}
        type={editMode ? 'button' : 'submit'}
      >
        {editMode ? t('dashboard.editDashboardEnd') : t('savedReports.singleReport.openEdit')}
        <IconSvg name="EDIT" color="WHITE" className="ml-2" />
      </Button>

      <Button
        color="default"
        rounded="lg"
        disabled={deleteOpen || editMode}
        onClick={mutate}
        className="flex flex-row items-center"
      >
        {t('savedReports.singleReport.duplicate.button')}
        <IconSvg name="DUPLICATE" color="WHITE" className="ml-2" />
      </Button>

      <Button color="red" rounded="lg" disabled={deleteOpen || editMode} onClick={onDeleteButtonClick}>
        {t('common.delete')}
      </Button>
      <DeleteDashboardConfirmationDialog
        open={deleteOpen}
        onClose={onCancel}
        onDeleteSuccess={onDeleteSuccess}
        dashboardId={selectedDashboard.id}
        dashboardName={selectedDashboard.name}
      />
      {exportModal && (
        <ExportationDialog
          open={exportModal}
          onClose={onClose}
          dashboardData={selectedDashboardData}
          currentPage={currentPage}
        />
      )}
      {automaticSendingModal && (
        <AutomaticSendingDialog
          open={automaticSendingModal}
          onClose={onClose}
          dashboardId={selectedDashboard.id}
          dashboardName={selectedDashboard.name}
        />
      )}
    </div>
  )
}

export default SelectedDashboardActions

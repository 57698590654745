import { FC } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { useBenchmarkOptions } from 'utils/constants/graphs/benchmark'

type Props = {
  title: string
  id?: string | undefined
  series: HeatmapSeries
  categories: string[]
  minifiedCategories: string[]
  colors: string[]
}

const BenchmarkChart: FC<Props> = (props) => {
  const { title, series, categories, colors, id } = props
  const { getBenchmarkOptions } = useBenchmarkOptions()
  const options = getBenchmarkOptions({ id, colors, title, categories })

  return (
    <div className="benchmark">
      <ReactApexChart series={series} type="line" options={options} />
    </div>
  )
}

export default BenchmarkChart

import { FC } from 'react'
import IconSvg from '../../atoms/icons/IconSvg'

type Props = {
  active?: boolean
  dashboardId: number
  dashboardName: string
  onClick: (dashboardId: number) => void
}

const DashboardItem: FC<Props> = (props) => {
  const { active, dashboardName, dashboardId, onClick } = props

  return (
    <div className={`group my-2 rounded-lg overflow-hidden hover:bg-primary ${active ? 'bg-primary' : 'bg-basic-300'}`}>
      <div className="p-4 shadow-xl">
        <div className="flex justify-between">
          <div className="flex items-center">
            <IconSvg className="mx-3" name="DASHBOARD" color="WHITE" onClick={() => onClick(dashboardId)} />
            <p
              className={`cursor-pointer group-hover:text-basic-100 ${active ? 'text-basic-100' : 'text-basic-700'}`}
              onClick={() => onClick(dashboardId)}
            >
              {dashboardName}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardItem

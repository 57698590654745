import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
const EnvironnementalData: FC = (props) => {
  const { t } = useTranslation()
  const series = [
    {
      name: 'Series 1',
      data: [80, 50, 30, 40, 100, 20],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },

    title: {
      text: t('landingPage.envData.title'),
      style: {
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: 'Roboto',
        color: '#4F4F64',
      },
    },
    subtitle: {
      text: t('landingPage.envData.confort'),
      align: 'left',
      margin: 0,
      offsetX: 0,
      offsetY: 30,
      floating: false,
      style: {
        fontSize: '12px',
        fontWeight: 'normal',
        fontFamily: undefined,
        color: '#D33087',
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: [
        t('landingPage.envData.co'),
        t('landingPage.envData.temp'),
        [t('landingPage.envData.humidity')],
        t('landingPage.envData.compound'),
        t('landingPage.envData.noise'),
        t('landingPage.envData.radon'),
        t('landingPage.envData.pm'),
      ],
    },
    colors: ['#c0c0c4'],
    plotOptions: {
      radar: {
        size: 80,
        polygons: {
          strokeColors: '#c0c0c4',
          connectorColors: '#c0c0c4',
          fill: {
            colors: undefined,
          },
        },
      },
    },
  }

  return (
    <div id="environnement" style={{ height: '100%' }}>
      <ReactApexChart options={options} series={series} type="radar" height={'100%'} />
    </div>
  )
}
export default EnvironnementalData

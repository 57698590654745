import { useState } from 'react'
import { usePagination, DOTS } from 'utils/hooks/usePagination'
import classnames from 'classnames'
import 'styles/pagination.css'

const Pagination = (props: {
  onPageChange: any
  totalCount: number
  siblingCount?: 1 | undefined
  currentPage: any
  pageSize: number
  className: any
}) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props
  const [input, setInput] = useState('')

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (paginationRange) {
    if (currentPage === 0 || paginationRange.length < 2) {
      return null
    }
  }

  const onNext = () => {
    onPageChange(parseInt(currentPage) + 1)
  }

  const onPrevious = () => {
    onPageChange(parseInt(currentPage) - 1)
  }
  const handleChange = (e: any) => {
    setInput(e.target.value)
    if (e.target.value <= totalCount && e.target.value !== '') onPageChange(e.target.value)
  }

  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : null
  return (
    <ul className={classnames('pagination-container', { [className]: className })}>
      {/* Left navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      <li className={classnames('pagination-item')} key="{inputNumber}">
        <input
          className="bg-transparent w-8 border-none focus:outline-0"
          type="text"
          pattern="[0-9]+"
          placeholder={currentPage}
          value={input}
          onChange={(e) => handleChange(e)}
        />
        / {totalCount}
      </li>

      {/*  Right Navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        key="{right-arrow}"
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  )
}

export default Pagination

import { usePermissions } from 'utils/hooks/usePermissions'
import { LoggedInRoutes, RouteType } from './AppRoutes'
import { PATHS } from './Paths'

export const useNavbarItems = () => {
  const { userCanAccessRoute } = usePermissions()

  return [
    {
      label: 'nav.home',
      path: PATHS.HOME,
      id: 'home-link',
      exact: true,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.HOME)),
    },
    {
      label: 'nav.analysis',
      path: PATHS.ANALYSIS.HOME,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.ANALYSIS.HOME)),
    },
    {
      label: 'nav.confort',
      path: PATHS.COMFORT.HOME,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.COMFORT.HOME)),
    },
    {
      label: 'nav.savedReports',
      path: PATHS.SAVED_REPORTS.HOME,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.SAVED_REPORTS.HOME)),
    },
    {
      label: 'nav.sites',
      path: PATHS.SITES.HOME,
      comingSoon: false,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.SITES.HOME)),
    },
    {
      label: 'nav.realTime',
      path: PATHS.REAL_TIME.HOME,
      disabled: !userCanAccessRoute(LoggedInRoutes.find((route) => route.path === PATHS.REAL_TIME.HOME)),
    },
  ]
}
export const FavoritesNavbarItems = [
  { label: 'nav.singleReport', path: PATHS.ANALYSIS.SINGLE_REPORT },
  { label: 'nav.dashboard', path: PATHS.ANALYSIS.DASHBOARD },
]
export const MySitesNavbarItems = [
  { label: 'nav.idCard', path: PATHS.SITES.ID_CARD },
  { label: 'nav.benchmark', path: PATHS.SITES.BENCHMARK },
]

import { FC, ForwardedRef, forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react'

export type InputProps = HTMLAttributes<HTMLInputElement> & {
  className?: string
  type?: string
  error?: boolean
  white?: boolean
  grey?: boolean
  value?: string | number
}

const InputComponent: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const { className = '', type = 'text', error = false, white = false, value, grey = false, ...rest } = props

  return (
    <input
      className={`w-full h-12 p-4 rounded placeholder-basic-400 outline-none appearance-none border ${
        white ? 'bg-basic-100 text-basic-600' : grey ? 'bg-basic-200 text-basic-600' : 'bg-basic-600 text-basic-100'
      } ${
        error ? 'border-primary-400' : `${white ? 'border-basic' : 'border-transparent'} focus:border-blue`
      } ${className}`}
      type={type}
      ref={ref}
      value={value}
      {...rest}
    />
  )
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(InputComponent)

export default Input

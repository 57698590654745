import { FC, useEffect, useState } from 'react'
import NoDashboardPanel from 'components/molecules/panels/NoDashboardPanel'
import { useTranslation } from 'react-i18next'
import DroppableFavoriteList from 'components/molecules/favorites/DroppableFavoriteList'
import SubPageLayout from 'components/layouts/SubPageLayout'
import FavoriteItemActions from 'components/molecules/favorites/FavoriteItemActions'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { savedReportsService } from 'services/savedReportsService'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { toast } from 'react-toastify'
import { SavedReportList } from 'types/SavedReportsTypes'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  WORKSTATION,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  heatmapValue,
  roomBookingValue,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'
import HorizontalBarChartController from 'components/molecules/charts/topFlop/HorizontalBarChartController'
import UtilizationOfSpaceController from 'components/molecules/charts/utilizationOfSpace/UtilizationOfSpaceController'
import { graphService } from 'services/graphService'
import { initialFilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import {
  ColumnChartDataWSType,
  DetailedOccupancyDataWSType,
  MonthlyDataWSType,
  TopFlopDataWSType,
  RestaurantDataWSType,
  FrequentationDataWSType,
  UtilizationQualityDataWSType,
  RoomBookingDataWSType,
  BenchmarkDataWSType,
} from 'types/GraphDataType'
import { useHistory, useParams } from 'react-router-dom'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'
import DetailedOccupancyController from 'components/molecules/charts/detailedOccupancy/DetailedOccupancyController'
import MonthlyController from 'components/molecules/charts/monthly/MonthlyController'
import RestaurantController from 'components/molecules/charts/RIE/RestaurantController'
import FrequentationController from 'components/molecules/charts/RIE/FrequentationController'
import SpaceAttendanceController from 'components/molecules/charts/spaceAttendance/SpaceAttendanceController'
import UtilizationQualityController from 'components/molecules/charts/utilizationQuality/utilizationQualityController'
import HeatmapController from 'components/molecules/charts/heatmap/HeatmapController'
import RoomBookingController from 'components/molecules/charts/roomBooking/roomBookingController'
import { DragDropContext } from 'react-beautiful-dnd'
import BenchmarkController from 'components/molecules/charts/benchmark/BenchmarkController'

const SavedReportsListView: FC = () => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [reportsList, setReportsList] = useState('')
  const { isLoading, isFetched, data, refetch } = useQuery<SavedReportList>({
    queryKey: QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode),
    queryFn: () => {
      if (clientCode) return savedReportsService.getAll(clientCode)
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })

  const selectedFavorite = data?.result.find((savedReport) => savedReport.id.toString() === id)

  const { data: graphData, isSuccess } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.GRAPH(selectedFavorite?.filters || initialFilterState),
    queryFn: () => {
      if (selectedFavorite) {
        return graphService.getGraphDataFromSavedReport(clientCode, selectedFavorite.filters)
      }
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!id,
  })
  useEffect(
    () =>
      //@ts-ignore
      refetch(),
    [],
  )
  useEffect(() => {
    if (data !== undefined) setReportsList(data.result)
  }, [data])

  if ((!data && isFetched) || data?.result.length === 0) {
    return (
      <NoDashboardPanel
        title={t('savedReports.singleReport.noItemTitle')}
        content={t('savedReports.singleReport.noItemContent')}
      />
    )
  }

  const graphType = selectedFavorite?.filters.graph as GraphType

  const onItemClick = (newSelectedSavedReportId: number) =>
    history.push(
      generatePath(PATHS.SAVED_REPORTS.SINGLE_REPORT_ID, {
        url: PATHS.SAVED_REPORTS.HOME,
        id: newSelectedSavedReportId.toString(),
      }),
    )

  const onDelete = () =>
    history.push(
      generatePath(PATHS.SAVED_REPORTS.SINGLE_REPORT, {
        url: PATHS.SAVED_REPORTS.HOME,
      }),
    )
  const onDragEnd = (result) => {
    if (!result.destination) return
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return
    const newItems = [...reportsList]
    const draggedItem = newItems[result.source.index]
    newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, draggedItem)
    setReportsList(newItems)
  }

  return (
    <SubPageLayout>
      {reportsList !== '' && (
        <DragDropContext onDragEnd={onDragEnd}>
          <DroppableFavoriteList
            isLoading={isLoading || !isFetched}
            items={reportsList}
            selectedItem={selectedFavorite?.id}
            onItemClick={onItemClick}
          />
        </DragDropContext>
      )}
      <div className="py-6 px-12 h-full max-h-full w-2/3 max-w-2/3 overflow-y-auto flex flex-col justify-between">
        {selectedFavorite && (
          <>
            {/* Quick trick to center correctly the graph on smaller screens */}
            <div />
            {graphType === topFlopValue && (
              <HorizontalBarChartController
                loading={!isSuccess}
                data={graphData as TopFlopDataWSType}
                title={selectedFavorite.name}
              />
            )}
            {graphType === stackedColumnValue && (
              <UtilizationOfSpaceController
                loading={!isSuccess}
                data={graphData as ColumnChartDataWSType}
                title={selectedFavorite.name}
              />
            )}
            {graphType === detailedOccupancyValue && (
              <DetailedOccupancyController
                loading={!isSuccess}
                data={graphData as DetailedOccupancyDataWSType}
                title={selectedFavorite.name}
                savedReportFilters={selectedFavorite.filters}
              />
            )}
            {graphType === monthlyValue && (
              <MonthlyController
                isWorkStation={selectedFavorite.filters.roomType === WORKSTATION.value}
                title={selectedFavorite.name}
                loading={!isSuccess}
                data={graphData as MonthlyDataWSType}
              />
            )}
            {graphType === RIE && (
              <RestaurantController
                title={selectedFavorite.name}
                loading={!isSuccess}
                data={graphData as RestaurantDataWSType}
              />
            )}
            {graphType === frequentationValue && (
              <FrequentationController
                title={selectedFavorite.name}
                loading={!isSuccess}
                data={graphData as FrequentationDataWSType}
              />
            )}
            {graphType === spaceAttendanceValue && (
              <SpaceAttendanceController
                title={selectedFavorite.name}
                loading={!isSuccess}
                data={graphData as FrequentationDataWSType}
              />
            )}
            {graphType === utilizationQualityValue && (
              <UtilizationQualityController
                title={selectedFavorite.name}
                loading={!isSuccess}
                data={graphData as UtilizationQualityDataWSType}
              />
            )}
            {graphType === heatmapValue && (
              <HeatmapController
                title={selectedFavorite.name}
                selectedFilters={selectedFavorite.filters}
                loading={!isSuccess}
                data={graphData as UtilizationQualityDataWSType}
              />
            )}
            {graphType === roomBookingValue && (
              <RoomBookingController
                title={selectedFavorite.name}
                selectedFilters={selectedFavorite.filters}
                loading={!isSuccess}
                data={graphData as RoomBookingDataWSType}
              />
            )}
            {graphType === benchmarkValue && (
              <BenchmarkController
                title={selectedFavorite.name}
                selectedFilters={selectedFavorite.filters}
                loading={!isSuccess}
                data={graphData as BenchmarkDataWSType}
              />
            )}
            <div />
            <div />
            {selectedFavorite && isSuccess && (
              <FavoriteItemActions
                selectedFavorite={selectedFavorite}
                onDeleteSuccess={onDelete}
                graphType={graphType}
                exportData={graphData}
              />
            )}
          </>
        )}
      </div>
    </SubPageLayout>
  )
}

export default SavedReportsListView

import {
  LocalizationInformationDto,
  LocalizationInformationDtoRoomClassificationEnum,
} from 'core/api/models/localizationInformationDto'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableInfo, FloorInfo, RestaurantAvailableInfo } from 'types/RieView'
import { countSpaces, getLevel, hasRealtimeDisplay, hasSpaces } from 'utils/LocalizationUtils'
import Gauge from '../../atoms/gauge/Gauge'
import ReactTooltip from 'react-tooltip'
import IconSvg from 'components/atoms/icons/IconSvg'
import { bpConvert } from 'utils/breakPointsUtils'
import LevelTable from './LevelTable'
import clsx from 'clsx'

type RealTimeTableProps = {
  infos?: FloorInfo[]
  domain: LocalizationInformationDto
  desksInfos?: SensorStatusDto[] | null
  buildingName?: string
  floor?: {
    roomType: LocalizationInformationDtoRoomClassificationEnum
    floorInfos?: FloorInfo
    space: AvailableInfo | RestaurantAvailableInfo
    filter?: LocalizationInformationDtoRoomClassificationEnum
  } | null
  onSetFloor: (floor: {
    buildingName?: string
    roomType: LocalizationInformationDtoRoomClassificationEnum
    floorInfos?: FloorInfo
    space: AvailableInfo | RestaurantAvailableInfo
    filter?: LocalizationInformationDtoRoomClassificationEnum
  }) => void
  maxHeight?: string
}

const RealTimeTable = ({
  infos,
  domain,
  desksInfos,
  buildingName,
  floor,
  onSetFloor,
  maxHeight,
}: RealTimeTableProps): JSX.Element | null => {
  const { t } = useTranslation()

  const spaces = useMemo(
    () =>
      infos?.filter(
        (info) =>
          hasRealtimeDisplay(info, domain) ||
          hasSpaces(info.focusRoom) ||
          hasSpaces(info.meetingRoom) ||
          hasSpaces(info.workstation),
      ) ?? [],
    [infos],
  )

  const focusRoomCount = useMemo(
    () => spaces.map((space) => countSpaces(space.focusRoom)).reduce((acc, count) => acc + count, 0),
    [spaces],
  )
  const workstationCount = useMemo(
    () => spaces.map((space) => countSpaces(space.workstation)).reduce((acc, count) => acc + count, 0),
    [spaces],
  )
  const meetingRoomCount = useMemo(
    () => spaces.map((space) => countSpaces(space.meetingRoom)).reduce((acc, count) => acc + count, 0),
    [spaces],
  )
  const gaugeCount = useMemo(
    () =>
      spaces
        .map((space) => hasRealtimeDisplay(space, domain))
        .reduce((acc, isGauge) => {
          const incrementCount = isGauge ? 1 : 0
          return acc + incrementCount
        }, 0),
    [spaces, domain],
  )

  const numberOfColumns = useMemo(
    () =>
      Number(gaugeCount > 0) + Number(focusRoomCount > 0) + Number(workstationCount > 0) + Number(meetingRoomCount > 0),
    [focusRoomCount, workstationCount, meetingRoomCount],
  )

  const renderOverviewIndicator = (
    space: AvailableInfo | RestaurantAvailableInfo,
    roomType: LocalizationInformationDtoRoomClassificationEnum,
    floorInfos?: FloorInfo,
    filter?: LocalizationInformationDtoRoomClassificationEnum,
  ) => {
    const hasAnySpace = hasSpaces(space)
    // ParseInt sur le offPeriod car malheureusement le serveur renvoie
    // parfois un string pour ce champ :(
    const totalCapacity = space.available + space.dirty + parseInt(space.offPeriod as any) + space.unavailable
    const occupiedSpaces = space.unavailable
    return (
      <div
        className={clsx(
          'flex justify-center items-center w-full h-full bg-basic-100 p-2',
          hasAnySpace ? 'cursor-pointer' : '',
        )}
        onClick={() => {
          if (hasAnySpace) {
            onSetFloor({
              buildingName,
              roomType,
              floorInfos,
              space,
              filter,
            })
          }
        }}
      >
        {hasAnySpace && (
          <div className="flex justify-center items-center flex-1 flex-col">
            <span className="text-sm text-basic mb-1">
              {occupiedSpaces} / {totalCapacity}
            </span>
            {renderCapacityGauge(occupiedSpaces, totalCapacity)}
          </div>
        )}
      </div>
    )
  }

  const renderCapacityGauge = (tally: number, capacity?: number) => {
    const unavailablePercentage = capacity ? (tally / capacity) * 100 : undefined

    if (unavailablePercentage === undefined) {
      return <div className="bg-basic-500 h-2 w-2/3 rounded-lg overflow-hidden"></div>
    }

    return <Gauge percentage={unavailablePercentage} />
  }

  const renderGaugeIndicator = (floorInfos: FloorInfo) => {
    const levelInfos = getLevel(floorInfos.floorName, domain)
    const isGaugeActivated = !!levelInfos && levelInfos.type === 'LEVEL' && levelInfos.realtime_display
    const filteredDesk =
      !!desksInfos &&
      isGaugeActivated &&
      desksInfos.filter(
        (desk) => desk.path?.replace(/_/g, '') == floorInfos.floorId.replace(/-/g, '/').replace(/_/g, ''),
      )
    const tallyCount =
      !!filteredDesk && isGaugeActivated ? filteredDesk.reduce((acc, cur) => acc + (cur.tally ?? 0), 0) : 0

    const capacity =
      isGaugeActivated && !!levelInfos && !!levelInfos.attributes
        ? levelInfos.attributes.CSM_CAPSAN ?? levelInfos.attributes.CSM_CAPNOR
        : undefined
    return (
      <div className="flex justify-center items-center w-full h-full bg-basic-100 p-2">
        {isGaugeActivated && (
          <div className="flex justify-center items-center flex-1 flex-col">
            <span className="text-sm text-basic mb-1">
              {tallyCount} / {capacity ?? '?'}
            </span>
            {renderCapacityGauge(tallyCount, capacity)}
          </div>
        )}
      </div>
    )
  }

  if (!(gaugeCount || workstationCount || meetingRoomCount || focusRoomCount)) {
    return null
  }

  if (floor) {
    return <LevelTable {...floor} domain={domain} desksInfos={desksInfos} buildingName={buildingName} />
  }

  return (
    <div
      className="grid gap-0.5 w-max bg-basic items-center grid-rows-1 border-basic border-2 text-basic"
      style={{
        gridTemplateColumns: `160px repeat(${numberOfColumns}, minmax(160px, max-content))`,
        gridTemplateRows: `minmax(${bpConvert(window.innerWidth, 70)}px, auto)`,
        gridAutoRows: 'auto',
        maxHeight: maxHeight ?? 'none',
        overflow: maxHeight ? 'auto' : 'visible',
      }}
    >
      <div className="justify-center bg-basic-100 font-semibold h-full w-full flex items-center">
        {t('overviewSupervisor.floorTitle')}
      </div>
      {gaugeCount > 0 && (
        <>
          <div
            data-tip
            data-for="overviewSupervisor.gaugeInfos"
            className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 px-2"
          >
            {t('overviewSupervisor.gauge')}
            <IconSvg className="items-center" name="HELP" />
          </div>
          <ReactTooltip effect="solid" id="overviewSupervisor.gaugeInfos">
            {t('overviewSupervisor.gaugeInfos')}
          </ReactTooltip>
        </>
      )}

      {workstationCount > 0 && (
        <>
          <div
            data-tip
            data-for="overviewSupervisor.workstationInfos"
            className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 p-2"
          >
            <div className="flex flex-col">
              <IconSvg className="items-center justify-center" iconClassName="h-6" name="WORKSTATION" />
              {t('overview.WORKSTATION')}
            </div>
            <IconSvg className="items-center" name="HELP" />
          </div>
          <ReactTooltip effect="solid" id="overviewSupervisor.workstationInfos">
            {t('overviewSupervisor.workstationInfos')}
          </ReactTooltip>
        </>
      )}

      {focusRoomCount > 0 && (
        <>
          <div
            className="justify-center gap-4 bg-basic-100 h-full w-full flex items-center px-2"
            data-tip
            data-for="overviewSupervisor.focusRoomInfos"
          >
            <div className="flex flex-col">
              <IconSvg className="items-center justify-center" iconClassName="h-6" name="FOCUS_ROOM" />
              {t('overview.FOCUS_ROOM')}
            </div>
            <IconSvg className="items-center" name="HELP" />
          </div>
          <ReactTooltip className="w-52" place="top" effect="solid" id="overviewSupervisor.focusRoomInfos">
            {t('overviewSupervisor.focusRoomInfos')}
          </ReactTooltip>
        </>
      )}

      {meetingRoomCount > 0 && (
        <>
          <div
            data-tip
            data-for="overviewSupervisor.meetingRoomInfos"
            className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 px-2"
          >
            <div className="flex flex-col">
              <IconSvg className="items-center justify-center" iconClassName="h-6" name="MEETING_ROOM" />
              {t('overview.MEETING_ROOM')}
            </div>
            <IconSvg className="items-center" name="HELP" />
          </div>
          <ReactTooltip effect="solid" id="overviewSupervisor.meetingRoomInfos">
            {t('overviewSupervisor.meetingRoomInfos')}
          </ReactTooltip>
        </>
      )}

      {spaces.map((floor) => {
        return (
          <>
            <div className="p-2 flex items-center w-full h-full bg-basic-100">
              <p className="truncate text-left" title={floor.floorName}>
                {floor.floorName}
              </p>
            </div>
            {gaugeCount > 0 && renderGaugeIndicator(floor)}
            {workstationCount > 0 &&
              renderOverviewIndicator(
                floor.workstation,
                LocalizationInformationDtoRoomClassificationEnum.Workstation,
                floor,
              )}
            {focusRoomCount > 0 &&
              renderOverviewIndicator(
                floor.focusRoom,
                LocalizationInformationDtoRoomClassificationEnum.FocusRoom,
                floor,
              )}
            {meetingRoomCount > 0 &&
              renderOverviewIndicator(
                floor.meetingRoom,
                LocalizationInformationDtoRoomClassificationEnum.MeetingRoom,
                floor,
              )}
          </>
        )
      })}
    </div>
  )
}

export default RealTimeTable

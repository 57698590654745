import { FC } from 'react'
import Dialog from '../../../atoms/dialog/Dialog'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { savedReportsService } from 'services/savedReportsService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import { generatePath } from 'utils/routeUtils'
import Loader from '../../../atoms/loader/Loader'
import { DashboardTypeList } from 'types/DashboardType'

type Props = {
  dashboardId: number
  dashboardName: string
  open: boolean
  onClose: () => void
  onDeleteSuccess: () => void
}

const DeleteDashboardConfirmationDialog: FC<Props> = (props) => {
  const { dashboardId, dashboardName, open, onClose, onDeleteSuccess } = props
  const { t } = useTranslation()
  const history = useHistory()
  const { clientCode } = useCurrentUser()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationKey: QUERY_KEYS.DASHBOARDS.DELETE_DASHBOARD(dashboardId),
    mutationFn: (clientCode: string) => savedReportsService.deleteDashboard(clientCode, dashboardId),
    onSuccess: (resData, clientCode) => {
      toast.success(t('dashboard.delete.success', { name: dashboardName }))

      // We update the cache to remove the deleted element from the list
      const prevDahboardsList = queryClient.getQueryData<DashboardTypeList>(
        QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode),
      )
      const filteredDashboardList = prevDahboardsList?.result.filter((dashboard) => dashboard.id !== dashboardId)
      queryClient.setQueryData(QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode), { result: filteredDashboardList })

      onDeleteSuccess()
      onClose()
      // We redirect to remove the id of the selected dashboard in the URL, as it is now deleted.
      history.push(generatePath(PATHS.SAVED_REPORTS.DASHBOARD, { url: PATHS.SAVED_REPORTS.HOME }))
      location.reload()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onConfirmationButtonClick = () => {
    if (clientCode) {
      mutate(clientCode)
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <p>{t('common.confirmation')}</p>
      </DialogTitle>
      <DialogBody>
        <p>{t('dashboard.delete.content', { name: dashboardName })}</p>
        <div className="mt-8 flex justify-end space-x-4">
          {isLoading && <Loader />}
          <Button onClick={onClose} disabled={isLoading}>
            {t('common.cancel')}
          </Button>
          <Button color="red" onClick={onConfirmationButtonClick} disabled={isLoading}>
            {t('common.delete')}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default DeleteDashboardConfirmationDialog

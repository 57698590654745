import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import useCurrentUser from 'utils/hooks/useCurrentUser'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import FormInput from '../../../atoms/input/FormInput'

import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { savedReportsService } from 'services/savedReportsService'
import { SavedReportList } from 'types/SavedReportsTypes'

type Props = {
  open: boolean
  reportID?: number
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('forms.errors.required'),
})

const NewDashboardDialog: FC<Props> = ({ onClose, open, reportID }: Props) => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const queryClient = useQueryClient()

  const onCancelClick = () => onClose()

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
    },
  })

  const { isLoading, mutate } = useMutation({
    mutationKey: QUERY_KEYS.DASHBOARDS.ADD_TO_NEW(clientCode),
    mutationFn: (dashboardName: string) => savedReportsService.createDashboard(clientCode, dashboardName, reportID),
    onSuccess: async () => {
      queryClient.invalidateQueries(QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode))

      toast.success(t('savedReports.singleReport.dashboard.newDashboardSuccess'))

      const prevSavedReportsList = queryClient.getQueryData<SavedReportList>(
        QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode),
      )
      const filteredSavedReportsList = prevSavedReportsList?.result.filter((savedReport) => savedReport.id !== reportID)
      queryClient.setQueryData(QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode), { result: filteredSavedReportsList })

      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
      onClose()
    },
  })

  const onSubmit = ({ name }: { name: string }) => mutate(name)

  const { name } = form.watch()
  const disableConfirm = !name

  return (
    <>
      <Dialog isOpen={open} onClose={onClose}>
        <DialogTitle>{t('savedReports.singleReport.dashboard.dashboardModalNewDashboard')}</DialogTitle>
        <DialogBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-4">
                <p>{t('savedReports.singleReport.dashboard.newDashboardNameTitle')}</p>
                <FormInput white placeholder={t('savedReports.singleReport.dashboard.newDashboardName')} name="name" />
              </div>
              <div className="mt-8 flex justify-end space-x-4">
                <Button color="red" onClick={onCancelClick}>
                  {t('common.cancel')}
                </Button>
                <Button type="submit" disabled={disableConfirm || isLoading}>
                  {t('common.confirm')}
                </Button>
              </div>
            </form>
          </FormProvider>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default NewDashboardDialog

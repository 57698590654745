import { FC } from 'react'
import Dialog from '../../../atoms/dialog/Dialog'
import { useTranslation } from 'react-i18next'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'

type Props = {
  savedReportId: number
  open: boolean
  onClose: () => void
  onConfirmation: () => void
}

const DeleteReportInDashboardDialog: FC<Props> = (props) => {
  const { savedReportId, open, onClose, onConfirmation } = props
  const { t } = useTranslation()

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <p>{t('common.confirmation')}</p>
      </DialogTitle>
      <DialogBody>
        <p>{t('dashboard.deleteReportFromDashboard', { ID: savedReportId })}</p>
        <div className="mt-8 flex justify-end space-x-4">
          <Button onClick={onClose}>{t('common.cancel')}</Button>
          <Button color="red" onClick={onConfirmation}>
            {t('common.delete')}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default DeleteReportInDashboardDialog

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure, detailedOccupancyValue, WORKSTATION } from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getBusinessUnits, getFilterType } from 'utils/filtersUtils'

const { placeholder, ...structure } = graphsStructure.ordinateAxis
const [ORDINATE_AXIS_BUSINESS_UNIT, ORDINATE_AXIS_FLOOR, ORDINATE_AXIS_CALENDAR_DAY, ORDINATE_AXIS_WEEK_DAY] =
  Object.entries(structure).map(([value, label]) => ({ label, value }))
const baseValues = [ORDINATE_AXIS_CALENDAR_DAY, ORDINATE_AXIS_WEEK_DAY, ORDINATE_AXIS_FLOOR]
let workstationValues = [...baseValues, ORDINATE_AXIS_BUSINESS_UNIT]

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string) => {
  if (state.DETAILED_OCCUPANCY.value === value) return state

  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    ROOM_TYPE: state.ROOM_TYPE,
    CUSTOM_ATTRIBUT_COLLAB_VALUE: state.CUSTOM_ATTRIBUT_COLLAB_VALUE,
    CUSTOM_ATTRIBUT_WKS_VALUE: state.CUSTOM_ATTRIBUT_WKS_VALUE,
    DETAILED_OCCUPANCY: {
      ...state.DETAILED_OCCUPANCY,
      value,
      active: !value,
    },
    KPI: {
      ...initialFilterState.KPI,
      required: isDetailedOccupancy,
      active: isDetailedOccupancy && !!value,
    },
  }
}

const HeatmapFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const value = state.DETAILED_OCCUPANCY.value
  const roomType = state.ROOM_TYPE.value
  const values = roomType === WORKSTATION.value ? workstationValues : baseValues
  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const data = options.find((option) => option.value === value)
  const type = getFilterType(state.GRAPH.value, state.DETAILED_OCCUPANCY.active, value)
  const businessUnits = getBusinessUnits(structure, state)
  businessUnits?.length > 0
    ? (workstationValues = [...baseValues, ORDINATE_AXIS_BUSINESS_UNIT])
    : (workstationValues = [...baseValues])

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      disabled={!state.DETAILED_OCCUPANCY.active && !state.DETAILED_OCCUPANCY.value}
      label={data?.label}
      selected={value}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default HeatmapFilter

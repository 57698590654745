import { FC } from 'react'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'
import LoadingChart from '../LoadingChart'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { getRestaurantOptions } from 'utils/constants/graphs/restaurant'
import { useTranslation } from 'react-i18next'

type Props = {
  loading?: boolean
  id?: string
  title?: string
  peopleCount?: number
  series: ColumnChartSeries
  categories: string[]
  colors: string[]
}

const RestaurantChart: FC<Props> = (props) => {
  const { loading = false, title, peopleCount, series, categories, id, colors } = props
  const { t } = useTranslation()

  if (loading) {
    return <LoadingChart type="bar" />
  }
  const options = getRestaurantOptions({
    id,
    subtitle: peopleCount ? t('graph.restaurant.subtitle', { peopleCount }) : t('graph.restaurant.capacityUndefined'),
    categories,
    peopleCount,
    colors,
  })

  return (
    <>
      <ReactApexChart type="bar" series={series} options={options} />
    </>
  )
}

export default RestaurantChart

import { BreakpointType } from 'types/BreakpointType'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
  calibratedUtilizationValue,
  roomBookingValue,
  benchmarkValue,
  countingMaxValue,
} from './graphsStructure'

type LayoutSizes = 'full' | 'big' | 'half'
export const chartLayoutSizes: Record<GraphType, (breakpoint: BreakpointType) => LayoutSizes> = {
  [topFlopValue]: () => 'big',
  [stackedColumnValue]: (breakpoint: BreakpointType): LayoutSizes => {
    return breakpoint && ['desktop', 'wide'].includes(breakpoint) ? 'big' : 'full'
  },
  [detailedOccupancyValue]: (breakpoint: BreakpointType): LayoutSizes => {
    return breakpoint && ['desktop', 'wide'].includes(breakpoint) ? 'big' : 'full'
  },
  [frequentationValue]: (breakpoint: BreakpointType): LayoutSizes => {
    return breakpoint && ['desktop', 'wide'].includes(breakpoint) ? 'big' : 'full'
  },
  [detailedOccupancyTableValue]: (breakpoint: BreakpointType): LayoutSizes => {
    return breakpoint && ['desktop', 'wide'].includes(breakpoint) ? 'big' : 'full'
  },
  [monthlyValue]: () => 'big',
  [RIE]: () => 'big',
  [spaceAttendanceValue]: () => 'big',
  [utilizationQualityValue]: () => 'big',
  [monitoringValue]: () => 'big',
  [heatmapValue]: () => 'big',
  [calibratedUtilizationValue]: () => 'big',
  [roomBookingValue]: () => 'big',
  [benchmarkValue]: () => 'big',
  [countingMaxValue]: () => 'big',
}

// There are 2 types of "ALL" values :
// - On an optional filter, the user can not select anything in the filter. But these filters has enumerable values, like
//    "BOOKABLE" / "NOT_BOOKABLE". So the value will be ALL so that it can be a value of an enum for the server.
// - On a required filter, on which there is a specific "ALL" option, visible, clickable. It is selectable because there
//    can be an infinite possibility of options (like "BUILDING_1", "BUILDING_2", ...) and therefore, an infinity of
//    possible values. So it must not be an classic "ALL" value because one of these infinite values can be "ALL". It has
//    to have a specific format : we chose "[ALL]".

export const optionalOptionAllValue = 'ALL'
export const optionAllValue = '[ALL]'

import { FC, useContext } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'
import { GraphFiltersContext } from 'components/molecules/filters/required/GraphFiltersContext'
import { getStackedColumnOptions } from 'utils/constants/graphs/columnChart'
import { useTranslation } from 'react-i18next'
import IconSvg from '../../../atoms/icons/IconSvg'
import ReactTooltip from 'react-tooltip'

type Props = {
  id?: string
  title: string
  data: ColumnChartSeries
  categories: string[]
  roomsCount: number
  minutes?: any
}

const StackedColumnChart: FC<Props> = (props) => {
  const { data, categories, roomsCount, title, id, minutes } = props
  const { t } = useTranslation()
  const { state: filters } = useContext(GraphFiltersContext)
  const roomSizeType = filters.ROOM_SIZE_TYPE.value
  const roomType = filters.ROOM_TYPE.value

  const options = getStackedColumnOptions({
    id: id,
    title: title,
    minutes,
    subtitle: [
      t('graph.stackedColumn.roomsSubtitle', { roomsCount }),
      roomSizeType === 'CATEGORY' && (roomType === 'ALL_COLLABORATIVE_SPACES' || roomType === 'MEETING_ROOM')
        ? t('graph.stackedColumn.capacitySubtitle')
        : '',
      t('graph.stackedColumn.legend'),
    ],
    categories,
    yaxisTitle: t('graph.stackedColumn.utilization'),
    xaxisTitle: t('graph.stackedColumn.capacity'),
  })

  return (
    <>
      <ReactApexChart<ColumnChartSeries> type="bar" options={options} series={data} />
    </>
  )
}
export default StackedColumnChart

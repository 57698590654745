import { FC } from 'react'

type Props = {
  firstname?: string
  lastname?: string
  className?: string
}

const Avatar: FC<Props> = (props) => {
  const { firstname, lastname, className = '' } = props

  return (
    <div
      className={`${className} flex items-center justify-center p-1/2 h-full w-full text-basic-100 bg-basic-500 text-xs font-bold rounded-full select-none bg-clip-content border-solid border-2 transition duration-300`}
    >
      <span className={`m-2 uppercase ${!firstname && !lastname ? 'text-basic-500' : 'text-basic-100'}`}>
        {firstname ? firstname[0] : 'A'}
        {lastname ? lastname[0] : 'A'}
      </span>
    </div>
  )
}

export default Avatar

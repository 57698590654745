import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { savedReportsService } from 'services/savedReportsService'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import SavedReportsEditContentView from './SavedReportsEditContentView'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { graphService } from 'services/graphService'
import { getFilterStateFromFiltersWS, getLabelsfilter } from 'utils/filtersUtils'
import { FilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import { getExportFormattedData } from 'utils/exportUtils'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import useFetchStructure from 'utils/hooks/useFetchStructure'

type Params = {
  id: string
}

const SavedReportsEditView: FC = () => {
  const { id } = useParams<Params>()
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const { structure } = useFetchStructure()
  const [filterState, setFilterState] = useState<{ isDirty: boolean; state?: FilterState }>({
    isDirty: false,
    state: undefined,
  })

  const { data: savedReport, refetch } = useQuery({
    queryKey: QUERY_KEYS.SAVED_REPORTS.GET_ONE(clientCode, id),
    queryFn: () => savedReportsService.getOne(clientCode, id),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })

  const { mutate, data, isSuccess, reset } = useMutation({
    mutationKey: QUERY_KEYS.SAVED_REPORTS.GRAPH_GET_ONE(clientCode, savedReport?.filters),
    mutationFn: ({ clientCode, filterState }: { clientCode: string; filterState: FilterState }) => {
      return graphService.generateGraph(clientCode, filterState)
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  useEffect(() => {
    const newFilterState = getFilterStateFromFiltersWS(savedReport?.filters)
    setFilterState({ isDirty: false, state: newFilterState })

    // We check the value of GRAPH because we don't want to call mutate() if we get the initialFilterState
    if (clientCode && newFilterState && newFilterState.GRAPH.value) {
      mutate({ clientCode, filterState: newFilterState })
    }
  }, [savedReport])
  useEffect(
    () =>
      //@ts-ignore
      refetch(),
    [],
  )

  const generateGraph = async () => {
    if (clientCode && filterState.state) {
      mutate({ clientCode, filterState: filterState.state })
    }
  }
  const generateHeatmapGraph = async () => {
    if (clientCode && filterState.state) {
      mutate(
        { clientCode, filterState: filterState.state },
        {
          onSuccess: (response) => {
            const { headers, data } = getExportFormattedData('', filterState.state!.GRAPH.value as GraphType, response)
            const translatedHeaders = headers.map((header) => t(header))
            const formattedData = [translatedHeaders, ...data]
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            const ws = XLSX.utils.json_to_sheet(formattedData)
            const wb = { Sheets: { data1: ws }, SheetNames: ['data1'] }
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            const expData = new Blob([excelBuffer], { type: fileType })
            const filters = getLabelsfilter(filterState.state, structure)
            const filtersTitle = Object.values(filters).join('_')
            FileSaver.saveAs(expData, `${filtersTitle.substring(0, 202)}` + fileExtension)
          },
        },
      )
    }
  }

  const onFilterChange = (state: FilterState) => {
    reset()
    setFilterState({ isDirty: true, state })
  }

  return (
    <SavedReportsEditContentView
      savedReport={savedReport}
      isGenerateGraphSuccess={isSuccess}
      filterState={filterState.state}
      isFilterStateDirty={filterState.isDirty}
      graphData={data}
      onFilterChange={onFilterChange}
      generateGraph={generateGraph}
      generateHeatmapGraph={generateHeatmapGraph}
    />
  )
}

export default SavedReportsEditView

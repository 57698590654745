import { FeaturesType } from 'types/DomainsStructure'

export const topFlopValue = 'TOP_FLOP'
export const stackedColumnValue = 'UTILIZATION_OF_SPACE'
export const detailedOccupancyValue = 'DETAILED_OCCUPANCY'
export const monthlyValue = 'OCCUPANCY_MONTHLY'
export const RIE = 'RESTAURANT_MAX_CUMULATION'
export const frequentationValue = 'RESTAURANT_ATTENDANCE_DAY'
export const spaceAttendanceValue = 'BUILDING_ATTENDANCE'
export const utilizationQualityValue = 'UTILIZATION_QUALITY'
export const detailedOccupancyTableValue = 'DETAILED_OCCUPANCY_TABLE'
export const heatmapValue = 'HEAT_MAP'
export const monitoringValue = 'MONITORING'
export const calibratedUtilizationValue = 'CALIBRATED_UTILIZATION'
export const roomBookingValue = 'ROOM_BOOKING'
export const benchmarkValue = 'BENCHMARK'
export const countingMaxValue = 'COUNTING_MAX'
export const workstation = 'WORKSTATION'
export const compartment = 'COMPARTMENT'
export const calendarDayValue = 'CALENDAR_DAY'
export const weekDayValue = 'WEEK_DAY'
export const businessUnitValue = 'BUSINESS_UNIT'
export const floorValue = 'FLOOR'

// The keys are the ones which are sent by the server and are different than the ones on the front.
// Maybe it needs a refactoring, I let a FIXME for now.
export const graphsStructure = {
  graph: {
    placeholder: 'graph.filters.graph.placeholder',
    [heatmapValue]: 'graph.filters.graph.heatmap',
    [spaceAttendanceValue]: 'graph.filters.graph.spaceAttendance',
    [topFlopValue]: 'graph.filters.graph.topFlop',
    [detailedOccupancyValue]: 'graph.filters.graph.detailedOccupancy',
    [stackedColumnValue]: 'graph.filters.graph.stackedColumn',
    [utilizationQualityValue]: 'graph.filters.graph.utilizationQuality',
    [monthlyValue]: 'graph.filters.graph.monthly',
    [roomBookingValue]: 'graph.filters.graph.roomBooking',
    [RIE]: 'graph.filters.graph.restaurant',
    [frequentationValue]: 'graph.filters.graph.frequentation',
    [detailedOccupancyTableValue]: 'graph.filters.graph.detailedOccupancyTable',
    [monitoringValue]: 'graph.filters.graph.monitoring',
    [calibratedUtilizationValue]: 'graph.filters.graph.calibratedUtilization',
    [benchmarkValue]: 'graph.filters.graph.benchmark',
    [countingMaxValue]: 'graph.filters.graph.countingMax',
  },
  spaceGraph: {
    BUILDING: 'graph.filters.graph.building',
    ALL_COLLABORATIVE_SPACES: 'graph.filters.graph.collaborativeSpace',
    INDIVIDUAL_SPACES: 'graph.filters.graph.individualSpaces',
    RESTAURANT: 'graph.filters.graph.restaurants',
  },
  domainPath: {
    placeholder: 'graph.filters.site',
  },
  buildingCode: {
    placeholder: 'graph.filters.building.placeholder',
    all: 'graph.filters.building.all',
    name: 'graph.filters.building.labels',
  },
  floorPath: {
    placeholder: 'graph.filters.floor.placeholder',
    all: 'graph.filters.floor.all',
  },
  compartmentPath: {
    placeholder: 'graph.filters.compartment.placeholder',
    all: 'graph.filters.compartment.all',
  },
  heatmapFloorPath: {
    placeholder: 'graph.filters.floor.placeholder',
    all: 'graph.filters.floor.all',
  },
  roomType: {
    placeholder: 'graph.filters.roomType.placeholder',
    MEETING_ROOM: 'graph.filters.roomType.meetingRoom',
    FOCUS_ROOM: 'graph.filters.roomType.focus',
    ALL_COLLABORATIVE_SPACES: 'graph.filters.roomType.allCollaborativeSpaces',
    WORKSTATION: 'graph.filters.roomType.workstation',
    ALL_WORKSTATIONS: 'graph.filters.roomType.allWorkstations',
  },
  scopeType: {
    placeholder: 'graph.filters.scopeType.placeholder',
    BUILDING: 'graph.filters.scopeType.building',
    COMPARTMENT: 'graph.filters.scopeType.compartment',
    FLOOR: 'graph.filters.scopeType.floor',
    DOMAIN: 'graph.filters.scopeType.domain',
  },
  monitoringScopeType: {
    DOMAIN: 'graph.filters.site',
    BUILDING: 'graph.filters.scopeType.building',
    COMPARTMENT: 'graph.filters.scopeType.compartment',
    FLOOR: 'graph.filters.scopeType.floor',
  },
  rangeType: {
    placeholder: 'graph.filters.quarter.placeholder',
    LAST_WEEK: 'graph.filters.quarter.lastWeek',
    CURRENT_MONTH: { name: 'graph.filters.quarter.currentMonth', disabled: new Date().getDate() == 1 ? true : false },
    LAST_MONTH: 'graph.filters.quarter.lastMonth',
    LAST_THREE_MONTHS: 'graph.filters.quarter.lastQuarter',
    LAST_TWELVE_MONTHS: 'graph.filters.quarter.lastTwelveMonths',
    LAST_TWELVE_WEEKS: 'graph.filters.quarter.lastTwelveWeeks',
    CALENDAR_DAY: 'graph.filters.quarter.monthCalendarDays',
    WEEK_DAY: 'graph.filters.quarter.weekDays',
    BY_DAY: 'graph.filters.quarter.day',
    BY_WEEK: 'graph.filters.quarter.byWeek',
    BY_MONTH: 'graph.filters.quarter.byMonth',
    BY_YEAR: 'graph.filters.quarter.byYear',
  },
  capacityType: {
    placeholder: 'graph.filters.roomSizeType.placeholder',
    DETAILED: 'graph.filters.roomSizeType.detailed',
    GROUPED: 'graph.filters.roomSizeType.grouped',
    CATEGORY: 'graph.filters.roomSizeType.sizes',
  },
  ordinateAxis: {
    placeholder: 'graph.filters.heatmapFilter.placeholder',
    BUSINESS_UNIT: 'graph.filters.heatmapFilter.byEntity',
    FLOOR: 'graph.filters.heatmapFilter.byFloor',
    CALENDAR_DAY: 'graph.filters.heatmapFilter.calendarDays',
    WEEK_DAY: 'graph.filters.heatmapFilter.weekDays',
  },
  kpi: {
    placeholder: 'graph.filters.kpi.placeholder',
    AVERAGE: 'graph.filters.kpi.average',
    MAX: 'graph.filters.kpi.max',
  },
  kpiWorkstation: {
    placeholder: 'graph.filters.kpi.placeholder',
    AVERAGE: 'graph.filters.kpi.average',
    PERCEIVED_AVERAGE: 'graph.filters.kpi.perceivedAverage',
    MAX: 'graph.filters.kpi.max',
    PERCEIVED_MAX: 'graph.filters.kpi.perceivedMax',
  },
  kpiMonitoring: {
    placeholder: 'graph.filters.kpi.placeholder',
    AVERAGE: 'graph.filters.kpi.realAverage',
    PERCEIVED_AVERAGE: 'graph.filters.kpi.occupationPerceivedAverage',
    MAX: 'graph.filters.kpi.realMax',
    PERCEIVED_MAX: 'graph.filters.kpi.occupationPerceivedMax',
    NINTH_DECILE: 'graph.filters.kpi.realNinthDecile',
    PERCEIVED_NINTH_DECILE: 'graph.filters.kpi.perceivedNinthDecile',
  },
  calibratedUtilizationKpi: {
    placeholder: 'graph.filters.kpi.placeholder',
    NB_OCCUPIED_WORKSTATION: 'graph.filters.kpi.numberOccupiedWorkstation',
    AVG_CONSECUTIVE_UTILIZATION: 'graph.filters.kpi.averageConsecutiveUtilization',
    AVG_OCCUPANCY_DURATION: 'graph.filters.kpi.averageOccupancyDuration',
    DISTRIBUTION_UTILIZATION_DURATION: 'graph.filters.kpi.distribution',
    DISTRIBUTION_UTILIZATION_PERCENTAGE: 'graph.filters.kpi.distributionPercentage',
  },
  capacityCategory: {
    placeholder: 'graph.filters.capacitySize.placeholder',
    SMALL: 'graph.filters.capacitySize.small',
    MEDIUM: 'graph.filters.capacitySize.medium',
    LARGE: 'graph.filters.capacitySize.large',
    '1-2': 'graph.filters.capacitySize.openSpace1-2',
    '3+': 'graph.filters.capacitySize.openSpace3',
  },
  bookable: {
    placeholder: 'graph.filters.bookable.placeholder',
    BOOKABLE: 'graph.filters.bookable.bookable',
    NON_BOOKABLE: 'graph.filters.bookable.notBookable',
  },
  open: {
    placeholder: 'graph.filters.openClosed.placeholder',
    OPEN: 'graph.filters.openClosed.open',
    CLOSED: 'graph.filters.openClosed.closed',
  },
  businessUnit: {
    placeholder: 'graph.filters.businessUnit.placeholder',
    all: 'graph.filters.businessUnit.all',
  },
  nature: {
    placeholder: 'graph.filters.openSpace.placeholder',
    OPEN_SPACE: 'graph.filters.openSpace.openSpace',
  },
  affectation: {
    placeholder: 'graph.filters.dedicatedShared.placeholder',
    DEDICATED: 'graph.filters.dedicatedShared.dedicated',
    SHARED: 'graph.filters.dedicatedShared.shared',
    NOMADIC: 'graph.filters.dedicatedShared.nomadic',
  },
  restaurantId: {
    placeholder: 'graph.filters.restaurant.placeholder',
  },
  level: {
    placeholder: 'graph.filters.level.placeholder',
  },
  monitoringBusinessUnits: {
    placeholder: 'graph.filters.monitoringBusinessUnits.placeholder',
    true: 'graph.filters.monitoringBusinessUnits.active',
    false: 'graph.filters.monitoringBusinessUnits.inactive',
  },
  customAttribut: {
    true: 'graph.filters.monitoringBusinessUnits.active',
    false: 'graph.filters.monitoringBusinessUnits.inactive',
  },
}

const { placeholder: graphPlaceholder, ...graphValuesStructure } = graphsStructure.graph
export const domainFilterByGraph: Record<keyof typeof graphValuesStructure, (features: FeaturesType) => boolean> = {
  // These rules are specified in the Excel listing all the filters needed and the particularities.
  [topFlopValue]: (features: FeaturesType) => features.meetingRoom || features.focusRoom,
  [stackedColumnValue]: (features: FeaturesType) => features.countingMeetingRoom || features.countingFocusRoom,
  [detailedOccupancyValue]: (features: FeaturesType) =>
    features.meetingRoom || features.focusRoom || features.workstation,
  [monthlyValue]: (features: FeaturesType) => features.meetingRoom || features.focusRoom || features.workstation,
  [detailedOccupancyTableValue]: (features: FeaturesType) =>
    features.meetingRoom || features.focusRoom || features.workstation,
  [heatmapValue]: (features: FeaturesType) => features.meetingRoom || features.focusRoom || features.workstation,
  [RIE]: (features: FeaturesType) => features.restaurant,
  [frequentationValue]: (features: FeaturesType) => features.restaurant,
  [spaceAttendanceValue]: (features: FeaturesType) =>
    Boolean(
      features.countingBuilding || features.countingFloor || features.countingCompartment || features.countingDomain,
    ),
  [utilizationQualityValue]: (features: FeaturesType) => features.meetingRoom || features.focusRoom,
  [monitoringValue]: (features: FeaturesType) => features.meetingRoom || features.focusRoom || features.workstation,
  [calibratedUtilizationValue]: (features: FeaturesType) => features.workstation,
  [roomBookingValue]: (features: FeaturesType) => features.booking,
  [benchmarkValue]: (features: FeaturesType) => true,
  [countingMaxValue]: (features: FeaturesType) => true,
}

const { placeholder: RTPlaceholder, ...roomTypeStructure } = graphsStructure.roomType
export const [MEETING_ROOM, FOCUS_ROOM, MEETING_AND_FOCUS, WORKSTATION] = Object.entries(roomTypeStructure).map(
  ([value, label]) => ({
    label,
    value,
  }),
)
const { placeholder: RSPlaceholder, ...scopeTypeStructure } = graphsStructure.scopeType
export const [BUILDING, COMPARTMENT, FLOOR, DOMAIN] = Object.entries(scopeTypeStructure).map(([value, label]) => ({
  label,
  value,
}))

const { placeholder, ...rangeTypeStructure } = graphsStructure.rangeType
export const allQuarterValues = Object.entries(rangeTypeStructure).map(([value, label]) => {
  if (typeof label === 'string') {
    return { label, value }
  }

  const { name, disabled } = label

  return { name, value, disabled }
})
export const [
  LAST_WEEK,
  CURRENT_MONTH,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_TWELVE_MONTHS,
  LAST_TWELVE_WEEKS,
  CALENDAR_DAY,
  WEEK_DAY,
  BY_DAY,
  BY_WEEK,
  BY_MONTH,
  BY_YEAR,
  LAST_THREE_MONTHS,
] = allQuarterValues
// We extract the value "ALL_COLLABORATIVE_SPACES" just to use it for the check when sending data to the server
const { placeholder: bookablePlaceholder, ...bookableStructure } = graphsStructure.bookable
export const [, , ALL_COLLABORATIVE_SPACES] = Object.keys(bookableStructure)

const { placeholder: capacityTypePlaceholder, ...capacityTypeStructure } = graphsStructure.capacityType
export const [CAPACITY_TYPE_DETAILED, CAPACITY_TYPE_GROUPED, CAPACITY_TYPE_CATEGORY] =
  Object.keys(capacityTypeStructure)

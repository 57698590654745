import { FC } from 'react'
import NavItem from '../../../atoms/navbar/NavItem'
import { useNavbarItems } from 'utils/constants/routes/NavbarItems'
import IconSvg from '../../../atoms/icons/IconSvg'
import NavbarContainer from '../../../atoms/navbar/NavbarContainer'
import { useTranslation } from 'react-i18next'
import NavbarMenu from './menu/NavbarMenu'
import { PATHS } from 'utils/constants/routes/Paths'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const Navbar: FC = () => {
  const { t } = useTranslation()
  const navbarItems = useNavbarItems()

  const onIconClick = () => undefined
  const navbarIconClassName = 'flex items-center cursor-pointer m-3 p-1'

  return (
    <NavbarContainer>
      <div className="flex">
        {navbarItems.map((item) => (
          <NavItem key={item.label} id={item.id} path={item.path} exact={item.exact} disabled={item.disabled}>
            {t(item.label)}
          </NavItem>
        ))}
      </div>
      <div className="flex">
        <div className="flex mr-8">
          <IconSvg
            name="ALERT"
            color="WHITE"
            className={clsx(navbarIconClassName, 'comingSoon')}
            onClick={onIconClick}
          />
          <IconSvg
            name="GIFT"
            color="WHITE"
            className={clsx(navbarIconClassName, 'jimo-widget')}
            onClick={onIconClick}
          />
          <Link to={PATHS.SETTINGS.HOME} className={navbarIconClassName}>
            <IconSvg name="SETTINGS" color="WHITE" onClick={onIconClick} />
          </Link>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={t('support.help-center-url')}
            className={navbarIconClassName}
          >
            <IconSvg name="HELP" color="WHITE" onClick={onIconClick} />
          </a>
        </div>
        <NavbarMenu />
      </div>
    </NavbarContainer>
  )
}

export default Navbar

import IconSvg from 'components/atoms/icons/IconSvg'
import PageLayout from 'components/layouts/PageLayout'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import Select from 'react-select'
import { components } from 'react-select'
import { configurationService } from 'services/configurationService'

import { toast } from 'react-toastify'
import {
  ElevenGraphColor,
  FourGraphColorBenchmark,
  FourGraphColorBooking,
  FourGraphColorOccupancy,
  OneGraphColor,
  ThreeGraphColor,
  TwoGraphColor,
} from 'components/molecules/graphColorsPreview/graphColorsPreview'
import { useMutation, useQueryClient } from 'react-query'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import { COLORS } from 'assets/colors/colors'
import { FILTER_ARROW_ICONS } from 'styles/Filters'
import ColorPicker from 'components/molecules/colorPicker/ColorPicker'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'

const graphColorsCount = {
  ONE_COLOR: 1,
  TWO_COLORS: 2,
  THREE_COLORS: 3,
  FOUR_COLORS_OCCUPANCY: 4,
  FOUR_COLORS_BENCHMARK: 4,
  FOUR_COLORS_BOOKING: 4,
  ELEVEN_COLORS: 11,
}
const { closeIcon } = FILTER_ARROW_ICONS
const ColorsSettings = () => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const history = useHistory()
  const { structure } = useFetchStructure()
  const queryClient = useQueryClient()

  const isGraphConfigured = (structure, graphType) => {
    return structure?.configuration?.graphColors.some((item) => item.graph === graphType) ?? false
  }

  const hasOneColorGraphConfigured = isGraphConfigured(structure, 'ONE_COLOR')
  const hasTwoColorGraphConfigured = isGraphConfigured(structure, 'TWO_COLORS')
  const hasThreeColorGraphConfigured = isGraphConfigured(structure, 'THREE_COLORS')
  const hasFourBookingColorsConfigured = isGraphConfigured(structure, 'FOUR_COLORS_BOOKING')
  const hasFourBenchmarkColorsConfigured = isGraphConfigured(structure, 'FOUR_COLORS_BENCHMARK')
  const hasFourOccupancyColorsConfigured = isGraphConfigured(structure, 'FOUR_COLORS_OCCUPANCY')
  const hasElevenColorGraphConfigured = isGraphConfigured(structure, 'ELEVEN_COLORS')
  const [enabled, setEnabled] = useState(false)
  const isInitialRenderRef = useRef(true)

  const initializeGraphColors = (numberOfColors) => {
    return Array.from({ length: numberOfColors }, () => '#FFFFFF')
  }
  const graphConfigurations = [
    hasOneColorGraphConfigured,
    hasTwoColorGraphConfigured,
    hasThreeColorGraphConfigured,
    hasFourBookingColorsConfigured,
    hasFourOccupancyColorsConfigured,
    hasElevenColorGraphConfigured,
    hasFourBenchmarkColorsConfigured,
  ]

  useEffect(() => {
    setEnabled(graphConfigurations.some((config) => config))
  }, graphConfigurations)

  const [selectedColorsNumber, setSelectedColorsNumber] = useState<Array<{ value: string; label: string }> | null>(null)
  const [oneGraphColors, setOneGraphColors] = useState(initializeGraphColors(1))
  const [twoGraphColors, setTwoGraphColors] = useState(initializeGraphColors(2))
  const [threeGraphColors, setThreeGraphColors] = useState(initializeGraphColors(3))
  const [fourBookingGraphColors, setFourBookingGraphColors] = useState(initializeGraphColors(4))
  const [fourBenchmarkGraphColors, setFourBenchmarkGraphColors] = useState(initializeGraphColors(4))
  const [fourOccupancyGraphColors, setFourOccupancyGraphColors] = useState(initializeGraphColors(4))
  const [elevenGraphColors, setElevenGraphColors] = useState(initializeGraphColors(11))
  const graphColorsNumber = [
    { label: t('settings.colors.oneColor'), value: 'ONE_COLOR' },
    { label: t('settings.colors.twoColor'), value: 'TWO_COLORS' },
    { label: t('settings.colors.threeColor'), value: 'THREE_COLORS' },
    { label: t('settings.colors.fourColorOccupancy'), value: 'FOUR_COLORS_OCCUPANCY' },
    { label: t('settings.colors.fourColorsBenchmark'), value: 'FOUR_COLORS_BENCHMARK' },
    { label: t('settings.colors.fourColorsBooking'), value: 'FOUR_COLORS_BOOKING' },
    { label: t('settings.colors.elevenColor'), value: 'ELEVEN_COLORS' },
  ]
  const getColorPickerNumbers = (selectedColorsNumber) => {
    return graphColorsCount[selectedColorsNumber?.value] || 0
  }
  const initialColors = Array(getColorPickerNumbers(selectedColorsNumber)).fill('#FFFFFF')
  const [colors, setColors] = useState(initialColors)
  const graphColors = structure?.configuration?.graphColors

  useEffect(() => {
    if (structure?.configuration) {
      setThreeGraphColors(getGraphColorsByType(graphColors, 'THREE_COLORS'))
      setOneGraphColors(getGraphColorsByType(graphColors, 'ONE_COLOR'))
      setTwoGraphColors(getGraphColorsByType(graphColors, 'TWO_COLORS'))
      setFourBenchmarkGraphColors(getGraphColorsByType(graphColors, 'FOUR_COLORS_BENCHMARK'))
      setFourBookingGraphColors(getGraphColorsByType(graphColors, 'FOUR_COLORS_BOOKING'))
      setFourOccupancyGraphColors(getGraphColorsByType(graphColors, 'FOUR_COLORS_OCCUPANCY'))
      setElevenGraphColors(getGraphColorsByType(graphColors, 'ELEVEN_COLORS'))
    }
  }, [structure, selectedColorsNumber])

  function getGraphColorsByType(graphColors, type) {
    return graphColors.filter((item) => item.graph === type)
  }

  const handleChange = (index: number, color: any, display: boolean) => {
    const updatedColors = [...colors]
    updatedColors[index] = color.hex
    setColors(updatedColors)
  }

  useEffect(() => {
    const colorLookup = {
      THREE_COLORS: threeGraphColors,
      ONE_COLOR: oneGraphColors,
      TWO_COLORS: twoGraphColors,
      FOUR_COLORS_OCCUPANCY: fourOccupancyGraphColors,
      FOUR_COLORS_BENCHMARK: fourBenchmarkGraphColors,
      FOUR_COLORS_BOOKING: fourBookingGraphColors,
      ELEVEN_COLORS: elevenGraphColors,
    }
    const selectedColorsArray = colorLookup[selectedColorsNumber?.value]
    const isColorArray = Array.isArray(selectedColorsArray)
    const specialCasesLookup = {
      ELEVEN_COLORS: [
        'ZERO_TO_TEN',
        'ELEVEN_TO_TWENTY',
        'TWENTY_ONE_TO_THIRTY',
        'THIRTY_ONE_TO_FORTY',
        'FORTY_ONE_TO_FIFTY',
        'FIFTY_ONE_TO_SIXTY',
        'SIXTY_ONE_TO_SEVENTY',
        'SEVENTY_ONE_TO_EIGHTY',
        'EIGHTY_ONE_TO_NINETY',
        'NINETY_ONE_TO_ONE_HUNDRED',
        'MORE_THAN_ONE_HUNDRED',
      ],
      THREE_COLORS: ['MOY', 'NINETH_DECILE', 'MAX'],
      FOUR_COLORS_BENCHMARK: ['FIRST_DECILE', 'MEDIAN', 'NINTH_DECILE', 'VALUE'],
      FOUR_COLORS_OCCUPANCY: ['EMPTY', 'UNDER_OCCUPIED', 'BALANCED', 'SATURATED'],
      FOUR_COLORS_BOOKING: ['FREE', 'NO_SHOW', 'NOT_BOOKED', 'BOOKED_AND_OCCUPIED'],
      TWO_COLORS: ['MAX', 'CUMULATIVE_ENTRIES'],
      ONE_COLOR: ['SINGLE_COLOR'],
    }

    const isValidSelectedColors =
      isColorArray &&
      selectedColorsArray.length > 0 &&
      !selectedColorsArray.some((element) => typeof element === 'undefined')

    const specialCaseColors =
      selectedColorsNumber?.value === 'ELEVEN_COLORS'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => elevenGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'THREE_COLORS'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => threeGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'FOUR_COLORS_BENCHMARK'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => fourBenchmarkGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'FOUR_COLORS_OCCUPANCY'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => fourOccupancyGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'FOUR_COLORS_BOOKING'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => fourBookingGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'TWO_COLORS'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => twoGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : selectedColorsNumber?.value === 'ONE_COLOR'
        ? specialCasesLookup[selectedColorsNumber.value].map(
            (type) => oneGraphColors.find((obj) => obj?.type === type)?.color || '#FFFFFF',
          )
        : null

    const selectedColors = isValidSelectedColors ? specialCaseColors || selectedColorsArray : initialColors
    setColors(selectedColors)
  }, [selectedColorsNumber])

  useEffect(() => {
    if (selectedColorsNumber?.value === 'ONE_COLOR' && colors && colors.length > 0) {
      setOneGraphColors(colors.slice(0, 1).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'TWO_COLORS' && colors && colors.length > 1) {
      setTwoGraphColors(colors.slice(0, 2).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'THREE_COLORS' && colors && colors.length > 1) {
      setThreeGraphColors(colors.slice(0, 3).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'FOUR_COLORS_OCCUPANCY' && colors && colors.length > 1) {
      setFourOccupancyGraphColors(colors.slice(0, 4).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'FOUR_COLORS_BENCHMARK' && colors && colors.length > 1) {
      setFourBenchmarkGraphColors(colors.slice(0, 4).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'FOUR_COLORS_BOOKING' && colors && colors.length > 1) {
      setFourBookingGraphColors(colors.slice(0, 4).map((color) => color))
    }
    if (selectedColorsNumber?.value === 'ELEVEN_COLORS' && colors && colors.length > 1) {
      setElevenGraphColors(colors.slice(0, 11).map((color) => color))
    }
  }, [selectedColorsNumber, colors])
  const { isLoading, mutate } = useMutation({
    mutationFn: (colorConfiguration) => {
      //@ts-ignore
      return configurationService.updateColors(clientCode, colorConfiguration)
    },
    onSuccess: async (data) => {
      toast.success(t('settings.colors.success'))
      setSelectedColorsNumber(null)
      setColors(initialColors)
      queryClient.invalidateQueries(QUERY_KEYS.ANALYSIS.FILTER(clientCode))
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const renderGraph = (selectedColorsNumber: string) => {
    switch (selectedColorsNumber?.value) {
      case 'ONE_COLOR':
        return [t('graph.filters.graph.topFlop')]
      case 'TWO_COLORS':
        return [t('graph.filters.graph.restaurant')]
      case 'THREE_COLORS':
        return [t('graph.filters.graph.monthly')]
      case 'FOUR_COLORS_OCCUPANCY':
        return [t('graph.filters.graph.monthly')]
      case 'ELEVEN_COLORS':
        return [
          t('graph.filters.graph.detailedOccupancy'),
          t('graph.filters.graph.spaceAttendance'),
          t('graph.filters.graph.frequentation'),
          t('graph.filters.graph.heatmap'),
        ]

      default:
        return []
    }
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      color: 'black',
      backgroundColor:
        (state.data.value === 'ONE_COLOR' && hasOneColorGraphConfigured) ||
        (state.data.value === 'TWO_COLORS' && hasTwoColorGraphConfigured) ||
        (state.data.value === 'THREE_COLORS' && hasThreeColorGraphConfigured) ||
        (state.data.value === 'FOUR_COLORS_BOOKING' && hasFourBookingColorsConfigured) ||
        (state.data.value === 'FOUR_COLORS_BENCHMARK' && hasFourBenchmarkColorsConfigured) ||
        (state.data.value === 'FOUR_COLORS_OCCUPANCY' && hasFourOccupancyColorsConfigured) ||
        (state.data.value === 'ELEVEN_COLORS' && hasElevenColorGraphConfigured)
          ? '#EAFAE7'
          : 'transparent',
    }),
    control: () => ({
      minWidth: 166,
      marginRight: 18,
      border: '1px solid #575762',
      borderRadius: '8px',
      display: 'flex',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  }
  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconSvg name={closeIcon} className="ml-2" />{' '}
        </components.DropdownIndicator>
      )
    )
  }

  useEffect(() => {
    if (!isInitialRenderRef.current && !enabled) {
      mutate({
        enabled: false,
        colorsConfiguration: [],
      })
    }
    isInitialRenderRef.current = false
  }, [enabled])

  return (
    <PageLayout>
      <div className="p-8">
        <h1
          className="font-bold flex items-center cursor-pointer mb-4"
          style={{ fontSize: '1.5rem' }}
          onClick={() => history.push('settings')}
        >
          <IconSvg name="ARROW_BACK" className="mr-2" />

          {t('settings.colors.title')}
        </h1>
        <div className="flex pl-12 mb-4">
          <p className={`${!enabled && 'font-bold'} mr-2`}>{t('settings.colors.default')}</p>
          <Switch
            width={36}
            height={20}
            handleDiameter={16}
            onChange={() => {
              setEnabled(!enabled)
              setSelectedColorsNumber(null)
            }}
            checked={enabled}
            onColor="#d33087"
            offColor="#c0c0c4"
            activeBoxShadow="transparent"
            checkedIcon={false}
            uncheckedIcon={false}
          />
          <p className={`${enabled && 'font-bold'} mx-2`}>{t('settings.colors.custom')}</p>
        </div>
        {enabled && (
          <div className="flex px-14 justify-between">
            <div style={{ minWidth: '30%' }}>
              <p className="font-bold mb-8">{t('settings.colors.selectLabel')}</p>
              <Select
                placeholder={t('settings.colors.colorsNumber')}
                components={{
                  DropdownIndicator,
                }}
                styles={customStyles}
                options={graphColorsNumber}
                value={selectedColorsNumber}
                onChange={(e) => {
                  setSelectedColorsNumber(e)
                }}
              />
              {selectedColorsNumber !== null && <p className="font-bold my-8">{t('settings.colors.selectColor')}</p>}
              <ColorPicker
                selectedColorsNumber={selectedColorsNumber}
                setSelectedColorsNumber={setSelectedColorsNumber}
                colors={colors}
                setColors={setColors}
                handleChange={handleChange}
                getColorPickerNumbers={getColorPickerNumbers}
                initialColors={initialColors}
                mutate={mutate}
                threeGraphColors={threeGraphColors}
                oneGraphColors={oneGraphColors}
                twoGraphColors={twoGraphColors}
                fourBenchmarkGraphColors={fourBenchmarkGraphColors}
                fourBookingGraphColors={fourBookingGraphColors}
                fourOccupancyGraphColors={fourOccupancyGraphColors}
                elevenGraphColors={elevenGraphColors}
              />
            </div>
            <div style={{ minWidth: '30%' }}>
              <h2 className="text-center font-bold mb-4">{t('settings.colors.preview')}</h2>
              {selectedColorsNumber?.value === 'ONE_COLOR' && colors && <OneGraphColor color={oneGraphColors} />}
              {selectedColorsNumber?.value === 'TWO_COLORS' && colors && colors.length > 0 && (
                <TwoGraphColor colors={twoGraphColors} />
              )}
              {selectedColorsNumber?.value === 'THREE_COLORS' && colors && colors.length > 0 && (
                <ThreeGraphColor colors={threeGraphColors} />
              )}
              {selectedColorsNumber?.value === 'FOUR_COLORS_OCCUPANCY' && colors && colors.length > 0 && (
                <FourGraphColorOccupancy colors={fourOccupancyGraphColors} />
              )}
              {selectedColorsNumber?.value === 'FOUR_COLORS_BENCHMARK' && colors && colors.length > 0 && (
                <FourGraphColorBenchmark colors={fourBenchmarkGraphColors} />
              )}
              {selectedColorsNumber?.value === 'FOUR_COLORS_BOOKING' && colors && colors.length > 0 && (
                <FourGraphColorBooking colors={fourBookingGraphColors} />
              )}
              {selectedColorsNumber?.value === 'ELEVEN_COLORS' && colors && colors.length > 0 && (
                <ElevenGraphColor colors={elevenGraphColors} />
              )}
              {selectedColorsNumber?.value !== undefined &&
                selectedColorsNumber?.value !== 'FOUR_COLORS_BENCHMARK' &&
                selectedColorsNumber?.value !== 'FOUR_COLORS_BOOKING' && (
                  <div
                    className="m-3 p-3"
                    style={{ backgroundColor: COLORS.blue30, color: COLORS.darkGrey80, borderRadius: '8px' }}
                  >
                    <h3
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: '600',
                        color: COLORS.darkGrey80,
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      <IconSvg name="FILLED_INFO" className="flex mr-3" color="GREY100" />

                      {t('settings.colors.concernedGraphs')}
                    </h3>
                    <ul>
                      {(renderGraph(selectedColorsNumber) as any).map((item: any, i: any) => (
                        <li key={i} style={{ paddingBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  )
}

export default ColorsSettings

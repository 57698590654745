import { FC } from 'react'
import SubNavbar from 'components/molecules/navbar/SubNavbar'
import { MySitesNavbarItems } from 'utils/constants/routes/NavbarItems'
import PageLayout from 'components/layouts/PageLayout'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'
import SavedReportsListView from './SavedReportsListView'
import SavedReportsDashboardsView from './SavedReportsDashboardsView'
import IdCardView from './IdCardView'
import BenchmarkView from './BenchmarkView'

const SitesView: FC = () => {
  const { url } = useRouteMatch()

  return (
    <PageLayout className="max-w-full">
      <SubNavbar items={MySitesNavbarItems} />
      <Switch>
        <Route exact path={generatePath(PATHS.SITES.HOME, { url })} component={IdCardView} />
        <Route exact path={generatePath(PATHS.SITES.ID_CARD, { url })} component={IdCardView} />

        <Route exact path={generatePath(PATHS.SITES.BENCHMARK, { url })} component={BenchmarkView} />
        <Route path={PATHS.ALL} component={() => <Redirect to={generatePath(PATHS.SITES.HOME, { url })} />} />
      </Switch>
    </PageLayout>
  )
}

export default SitesView

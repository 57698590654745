// Moved in his own file to avoid circular dependency
export const PATHS = {
  HOME: '/',
  AUTOMAICSENDING: {
    HOME: '/dashboard-email/:id',
  },
  AUTH: {
    LOGIN: '/login',
    OIDC: '/oidc',
    FORGOT_PASSWORD: '/public/forgot-password',
    RESET_PASSWORD: '/public/change-password',
    CHANGE_PASSWORD: '/change-password',
    SEND_CODE: '/send-code',
  },
  ANALYSIS: {
    HOME: '/analysis',
    SINGLE_REPORT: '/:url/single-report',
    DASHBOARD: '/:url/dashboard',
  },
  REAL_TIME: {
    HOME: '/real-time',
  },
  COMFORT: {
    HOME: '/comfort',
  },
  SAVED_REPORTS: {
    HOME: '/saved-reports',
    SINGLE_REPORT: '/:url/single-report',
    SINGLE_REPORT_ID: '/:url/single-report/:id',
    EDIT_SINGLE_REPORT: '/:url/single-report/:id/edit',
    DASHBOARD: '/:url/dashboard',
    DASHBOARD_ID: '/:url/dashboard/:id',
  },
  FAVORITES: {
    HOME: '/favorites',
    SINGLE_REPORT: '/:url/single-report',
    SINGLE_REPORT_ID: '/:url/single-report/:id',
    EDIT_SINGLE_REPORT: '/:url/single-report/:id/edit',
    DASHBOARD: '/:url/dashboard',
    DASHBOARD_ID: '/:url/dashboard/:id',
  },
  SITES: {
    HOME: '/sites',
    ID_CARD: '/:url/idCard',
    BENCHMARK: '/:url/benchmark',
  },
  SETTINGS: {
    HOME: '/settings',
    COLORS: '/:url/colors',
  },
  RIE: {
    HOME: '/rie',
  },
  OVERVIEW: {
    HOME: '/overview',
  },
  ALL: '*',
}

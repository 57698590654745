import * as Yup from 'yup'

export const jooxter_link = 'https://jooxter.com/'

export const inputs = [
  {
    name: 'username',
    placeholder: 'forms.fields.username',
  },
  {
    name: 'password',
    placeholder: 'forms.fields.password',
    type: 'password',
  },
]

export const emailValidationSchema = Yup.object().shape({
  username: Yup.string().required('forms.errors.required').email('forms.errors.email'),
})

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string().required('forms.errors.required'),
})

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, ...structure } = graphsStructure.open
const values = Object.entries(structure).map(([value, label]) => ({ label, value }))

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.OPEN_CLOSED.value === value) return state

  return {
    ...state,
    OPEN_CLOSED: {
      ...initialFilterState.OPEN_CLOSED,
      value,
    },
  }
}

const OpenClosedFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const openClosedValue = state.OPEN_CLOSED.value

  const options = values.map((option) => ({ ...option, label: t(option.label) }))
  const bookableData = options.find((option) => option.value === openClosedValue)
  const type = getFilterType(state.GRAPH.value, state.OPEN_CLOSED.active, openClosedValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={bookableData?.label}
      disabled={!state.OPEN_CLOSED.active && !state.OPEN_CLOSED.value}
      selected={openClosedValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default OpenClosedFilter

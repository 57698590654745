import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IconSvg from 'components/atoms/icons/IconSvg'
import { COLORS } from 'assets/colors/colors'
import { useEffect, useState } from 'react'

export interface IInformativeSection {
  period: string
  capacity: string
  site: string
  building: string
  floor: string
  spaceType: string
  graph: string
  date?: string
  analysisHours?: any
}

export default function InformativeSection(props: IInformativeSection) {
  const { period, capacity, site, building, floor, spaceType, graph, date, kpi, analysisHours } = props
  const { t } = useTranslation()
  const [formattedStartTime, setFormattedStartTime] = useState()
  const [formattedEndTime, setFormattedEndTime] = useState()
  const renderGraph = (graph: string) => {
    switch (graph) {
      case 'TOP_FLOP':
        return {
          target: t('informativeSection.topFlop'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.utilizationQuality'), value: 'UTILIZATION_QUALITY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
          ],
        }
      case 'UTILIZATION_OF_SPACE':
        return {
          target: t('informativeSection.stackedColumn'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.utilizationQuality'), value: 'UTILIZATION_QUALITY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
          ],
        }
      case 'DETAILED_OCCUPANCY':
        return {
          target: t('informativeSection.detailedOccupancy'),
          scope: [
            { name: t('graph.filters.graph.topFlop'), value: 'TOP_FLOP' },
            { name: t('graph.filters.graph.utilizationQuality'), value: 'UTILIZATION_QUALITY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
          ],
        }
      case 'OCCUPANCY_MONTHLY':
        return {
          target: t('informativeSection.monthly'),
          scope: [
            { name: t('graph.filters.graph.spaceAttendance'), value: 'BUILDING_ATTENDANCE' },
            { name: t('graph.filters.graph.utilizationQuality'), value: 'UTILIZATION_QUALITY' },
            { name: t('graph.filters.graph.detailedOccupancyTable'), value: 'DETAILED_OCCUPANCY_TABLE' },
          ],
        }
      case 'RESTAURANT_MAX_CUMULATION':
        return {
          target: t('informativeSection.restaurantPeak'),
          scope: [{ name: t('graph.filters.graph.frequentation'), value: 'RESTAURANT_ATTENDANCE_DAY' }],
        }
      case 'RESTAURANT_ATTENDANCE_DAY':
        return {
          target: t('informativeSection.restaurantFrequentation'),
          scope: [{ name: t('graph.filters.graph.restaurant'), value: 'RESTAURANT_MAX_CUMULATION' }],
        }
      case 'BUILDING_ATTENDANCE':
        return {
          target: t('informativeSection.frequentation'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancyTable'), value: 'DETAILED_OCCUPANCY_TABLE' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
            { name: t('graph.filters.graph.stackedColumn'), value: 'UTILIZATION_OF_SPACE' },
          ],
        }
      case 'UTILIZATION_QUALITY':
        return {
          target: t('informativeSection.utilizationQuality'),
          scope: [
            { name: t('graph.filters.graph.topFlop'), value: 'TOP_FLOP' },
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.stackedColumn'), value: 'UTILIZATION_OF_SPACE' },
          ],
        }
      case 'DETAILED_OCCUPANCY_TABLE':
        return {
          target: t('informativeSection.detailedOccupancyTable'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.stackedColumn'), value: 'UTILIZATION_OF_SPACE' },
            { name: t('graph.filters.graph.spaceAttendance'), value: 'BUILDING_ATTENDANCE' },
          ],
        }
      case 'HEAT_MAP':
        return {
          target: t('informativeSection.heatmap'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
            { name: t('graph.filters.graph.spaceAttendance'), value: 'BUILDING_ATTENDANCE' },
          ],
        }
      case 'ROOM_BOOKING':
        return {
          target: t('informativeSection.roomBooking'),
          scope: [
            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
            { name: t('graph.filters.graph.spaceAttendance'), value: 'BUILDING_ATTENDANCE' },
          ],
        }
      case 'BENCHMARK':
        return {
          target: t('informativeSection.benchmark'),
          scope: [
            { name: t('informativeSection.idCard'), value: 'ID_CARD' },

            { name: t('graph.filters.graph.detailedOccupancy'), value: 'DETAILED_OCCUPANCY' },
            { name: t('graph.filters.graph.monthly'), value: 'OCCUPANCY_MONTHLY' },
          ],
        }
      default:
        return 'foo'
    }
  }

  useEffect(() => {
    if (analysisHours) {
      setFormattedStartTime({
        hour: analysisHours.analysisStartTime.split(':')[0],
        minutes: analysisHours.analysisStartTime.split(':')[1],
      })
      setFormattedEndTime({
        hour: analysisHours.analysisEndTime.split(':')[0],
        minutes: analysisHours.analysisEndTime.split(':')[1],
      })
    }
  }, [])

  const displayDate = (date) => {
    return `${parseInt(date.hour, 10)}${date.minutes !== '00' ? `h${date.minutes}` : 'h'}`
  }

  return (
    <div
      className="flex flex-col relative"
      style={{
        fontSize: '14px',
        width: '25%',
        right: `${graph === 'DETAILED_OCCUPANCY_TABLE' ? '-68%' : '-4%'}`,
        minWidth: '300px',
      }}
    >
      <section
        className="m-3 p-3"
        style={{ backgroundColor: COLORS.lightGrey, color: COLORS.darkGrey80, borderRadius: '8px' }}
      >
        <h3
          style={{
            display: 'flex',
            fontWeight: '600',
            color: COLORS.darkGrey80,
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <IconSvg name="SCOPE" className="flex items-center mr-3" />
          {t('informativeSection.scope')}
        </h3>
        <ul>
          <li className="flex justify-between">
            <span>
              {t('informativeSection.period')} {''}:{' '}
            </span>
            <span>
              {t(period)} {date !== '' && <span>({date})</span>}
            </span>
          </li>
          {analysisHours && formattedStartTime && formattedEndTime && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.analysisHours')} {''}:{' '}
              </span>
              <span>{displayDate(formattedStartTime) + '-' + displayDate(formattedEndTime)}</span>
            </li>
          )}
          {capacity !== undefined && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.capacity')} {''} :{' '}
              </span>
              {capacity}
            </li>
          )}
          {site && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.site')} {''}: {''}
              </span>
              {site}
            </li>
          )}
          {building && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.building')} {''} : {''}
              </span>
              {building}
            </li>
          )}
          {floor && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.floor')}
                {''} : {''}
              </span>
              {floor}
            </li>
          )}
          {spaceType && (
            <li className="flex justify-between">
              <span>
                {t('informativeSection.spaceType')}
                {''} : {''}
              </span>
              {spaceType == 'Tous les espaces collaboratifs' ? 'Tous les EC' : spaceType}
            </li>
          )}
          {kpi && (
            <li className="flex justify-between">
              <span>{t('informativeSection.kpi')} :</span>
              {kpi}
            </li>
          )}
        </ul>
      </section>
      <section
        className="m-3 p-3"
        style={{ backgroundColor: COLORS.lightGrey, color: COLORS.darkGrey80, borderRadius: '8px' }}
      >
        <h3
          style={{
            display: 'flex',
            fontWeight: '600',
            alignItems: 'center',
            color: COLORS.darkGrey80,
            borderRadius: '8px',
            marginBottom: '10px',
          }}
        >
          <IconSvg name="OBJECTIF" className="flex items-center mr-3" />

          {t('informativeSection.target')}
        </h3>

        <p>{(renderGraph(graph) as any).target}</p>
      </section>
      <section
        className="m-3 p-3"
        style={{ backgroundColor: COLORS.blue30, color: COLORS.darkGrey80, borderRadius: '8px' }}
      >
        <h3
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            color: COLORS.darkGrey80,
            borderRadius: '8px',
            marginBottom: '10px',
          }}
        >
          <IconSvg name="FILLED_INFO" className="flex  mr-3" color="GREY100" />

          {t('informativeSection.more')}
        </h3>
        <ul>
          {(renderGraph(graph) as any).scope &&
            (renderGraph(graph) as any).scope.map((item: any, i: any) => (
              <li key={i}>
                <Link
                  style={{ paddingBottom: '10px', display: 'flex', justifyContent: 'space-between' }}
                  to={
                    item.value == 'ID_CARD'
                      ? '/sites'
                      : {
                          pathname: '/analysis/single-report/',
                          search: `?graph=${item.value}`,
                        }
                  }
                  target="_blank"
                >
                  {item.name}
                  <span
                    style={{
                      backgroundColor: COLORS.darkGrey80,
                      height: '28px',
                      width: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                  >
                    <IconSvg name="WHITE_ARROW_RIGHT" />
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </section>
    </div>
  )
}

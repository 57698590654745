import { FilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import { FiltersType } from 'types/SavedReportsTypes'

export const QUERY_KEYS = {
  AUTH: {
    PRE_LOGIN: ['AUTH', 'PRE_LOGIN'],
    TOKEN: ['AUTH', 'TOKEN'],
    FORGOT_PASSWORD: ['AUTH', 'FORGOT_PASSWORD'],
    RESET_PASSWORD: ['AUTH', 'RESET_PASSWORD'],
    CHANGE_PASSWORD: ['AUTH', 'CHANGE_PASSWORD'],
    USER_CURRENT: ['AUTH', 'USER', 'CURRENT'],
    CLIENT: ['AUTH', 'USER', 'CLIENT'],
    LOGOUT: ['AUTH', 'LOGOUT'],
    UPDATE_SITE: (clientCode: string) => ['AUTH', 'USER', 'SITE', clientCode],
  },
  ANALYSIS: {
    FILTER: (clientCode: string): string[] => ['ANALYSIS', 'FILTER', clientCode],
    MAP: (clientCode: string): string[] => ['ANALYSIS', 'MAP', clientCode],
    GRAPH: (filters: FilterState | FiltersType): (string | FilterState | FiltersType)[] => [
      'ANALYSIS',
      'GRAPH',
      filters,
    ],
    SAVE: (filters: FilterState): (string | FilterState)[] => ['ANALYSIS', 'SAVE', filters],
    LANDING_PAGE: (clientCode: string): string[] => ['ANALYSIS', 'LANDING_PAGE', clientCode],
    RIE_ATTENDANCE: (clientCode: string): string[] => ['ANALYSIS', 'LANDING_PAGE', clientCode],
    LOCALIZATION_DESKS: (clientCode: string, path: string): string[] => [
      'ANALYSIS',
      'LOCALIZATION_DESKS',
      clientCode,
      path,
    ],
    LOCALIZATION_DOMAIN: (clientCode: string, path: string): string[] => [
      'ANALYSIS',
      'LOCALIZATION_DOMAIN',
      clientCode,
      path,
    ],
    RIE_VIEW_DESKS: (clientCode: string, path: string): string[] => ['ANALYSIS', 'RIE_VIEW_DESKS', clientCode, path],
    RIE_VIEW_DOMAIN: (clientCode: string, path: string): string[] => ['ANALYSIS', 'RIE_VIEW_DOMAIN', clientCode, path],
    RIE_VIEW_CLIENT_DISPLAY: (clientCode: string): string[] => ['ANALYSIS', 'RIE_VIEW_CLIENT_DISPLAY', clientCode],
  },
  SAVED_REPORTS: {
    GET_ALL: (clientCode: string) => ['SAVED_REPORTS', 'GET_ALL', clientCode],
    GET_ONE: (clientCode: string, reportId: string) => ['SAVED_REPORTS', 'GET_ONE', clientCode, reportId],
    GRAPH_GET_ONE: (clientCode: string, filters?: FiltersType) => [
      'SAVED_REPORTS',
      'GRAPH_GET_ONE',
      clientCode,
      filters,
    ],
    UPDATE: (reportId: string, filters?: FilterState) => ['SAVED_REPORT', 'UPDATE', reportId, filters],
    DUPLICATE: (reportId: number): (string | number)[] => ['SAVED_REPORTS', 'DUPLICATE', reportId],
    DELETE: (reportId: number): (string | number)[] => ['SAVED_REPORTS', 'DELETE', reportId],
  },
  DASHBOARDS: {
    ADD_TO_NEW: (clientCode: string) => ['DASHBOARDS', 'ADD_TO_NEW', clientCode],
    ADD_TO_EXISTING: (clientCode: string, reportID: number) => ['DASHBOARDS', 'ADD_TO_EXISTING', clientCode, reportID],
    GET_ALL_DASHBOARDS: (clientCode: string) => ['DASHBOARDS', 'GET_ALL', clientCode],
    GET_HOMEPAGE_DASHBOARD: (clientCode: string) => ['DASHBOARDS', 'GET_ALL_HOMEPAGE', clientCode],
    GET_DASHBOARD: (clientCode: string, dashboardID: number) => ['DASHBOARDS', 'GET_ONE', clientCode, dashboardID],
    GET_AUTHORIZED_DASHBOARD: (clientCode: string, reportID: number) => [
      'DASHBOARDS',
      'GET_AUTHORIZED',
      clientCode,
      reportID,
    ],
    DELETE_DASHBOARD: (dashboardID: number): (string | number)[] => ['DASHBOARDS', 'DELETE', dashboardID],
    DUPLICATE_DASHBOARD: (dashboardID: number): (string | number)[] => ['DASHBOARDS', 'DUPLICATE', dashboardID],
    UPDATE_DASHBOARD: (dashboardID: number) => ['SAVED_REPORT', 'UPDATE', dashboardID],
    ADD_DASHBOARD_TO_HOMEPAGE: (clientCode: string, dashboardID: number) => [
      'DASHBOARDS',
      'ADD_TO_HOMEPAGE',
      clientCode,
      dashboardID,
    ],
    GET_AUTOMATIC_SENDING: (clientCode: string, dashboardId: number) => [
      'DASHBOARDS',
      'AUTOMATIC_SENDING',
      clientCode,
      dashboardId,
    ],
    PUT_AUTOMATIC_SENDING: (clientCode: string, dashboardId: number) => [
      'DASHBOARDS',
      'AUTOMATIC_SENDING',
      clientCode,
      dashboardId,
    ],
  },
}

import { FC, useContext, useEffect, useState } from 'react'
import { DetailedOccupancyDataType, FrequentationDataWSType } from 'types/GraphDataType'
import SpaceAttendanceChart from './SpaceAttendanceChart'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { displayDate } from 'utils/dateUtils'

type HeatmapFormattedDataType = {
  series: DetailedOccupancyDataType
  categories: string[]
  minifiedCategories: string[]
}

type Props = {
  loading: boolean
  data: FrequentationDataWSType
  id?: string | undefined
  title?: string
  colors: string[]
}

const SpaceAttendanceController: FC<Props> = (props) => {
  const { loading, data, id, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<HeatmapFormattedDataType>()
  const { state: filters } = useContext(GraphFiltersContext)

  useEffect(() => {
    if (loading) {
      return
    }
    function getMonth(date: any) {
      const month = date.getMonth() + 1
      return month < 10 ? '0' + month : '' + month // ('' + month) for string result
    }
    const formattedSeries = data.result.map((el) => {
      const formattedData = el.valuesByHour.map((subEl) => {
        return {
          x: subEl.hour,
          y: Math.max(subEl.valueInPercentage * 10, 1),
          count: subEl.count,
        }
      })
      let formattedLabel = ''
      if (el.label.indexOf('/') > -1) {
        const startDate = new Date(el.label.substring(0, el.label.indexOf('/')) + '12:00:00')
        const endDate = new Date((el.label.substring(el.label.indexOf('/') + 1, el.label.length) + ' 12:00:00').trim())
        formattedLabel = `${startDate.getDate()}  ${t(`graph.monthly.month.${getMonth(startDate)}`).substring(
          0,
          3,
        )} - ${endDate.getDate()}  ${t(`graph.monthly.month.${getMonth(endDate)}`).substring(0, 3)}  `
      } else if (el.label.indexOf('M') > -1) {
        const year = el.label.substring(0, el.label.indexOf('M'))
        const month = el.label.substring(el.label.indexOf('M') + 1, el.label.length)
        formattedLabel = ` ${t(`graph.monthly.month.${month}`)}  ${year} `
      } else {
        const label = new Date(el.label)
        const day = label.getDate()
        const month = getMonth(label)
        const year = label.getFullYear()
        const lang = navigator.language
        const currentDate = new Date(el.label)
        const startDate = new Date(currentDate.getFullYear(), 0, 1)
        //@ts-ignore
        const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
        const weekNumber = Math.ceil(days / 7)

        formattedLabel =
          label.getDay() == 1
            ? `${t('graph.common.week')}${weekNumber} - 
              ${label.toLocaleString(window.navigator.language, { weekday: 'long' })} ${String(day).padStart(2, '0')} `
            : `${label.toLocaleString(window.navigator.language, { weekday: 'long' })}  ${String(day).padStart(2, '0')}`
      }
      return {
        name: formattedLabel,

        data: [
          ...formattedData,
          { x: ' ', y: 0 },
          { x: 'avg', y: Math.max(el.average * 10, 1), count: el.averageCount },
          { x: 'max', y: Math.max(el.max * 10, 1), count: el.maxCount },
        ],
      }
    })
    const formattedMax = data.max.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const formattedAvg = data.average.map((el) => ({
      x: el.hour,
      y: Math.max(el.valueInPercentage * 10, 1),
      count: el.count,
    }))
    const emptyLine = data.average.map((el, index) => ({
      x: index.toString(),
      y: 0,
    }))

    const baseCategories = data.average.map((el) => displayDate(el.hour))
    const categories = [
      ...baseCategories,
      ' ',
      'graph.detailedOccupancy.xaxis.avg',
      'graph.detailedOccupancy.xaxis.max',
    ].map((category) => t(category))
    const minifiedCategories = [
      ...baseCategories.map((el, index) => (index % 2 === 0 ? el : '')),
      'graph.detailedOccupancy.xaxis.avg',
      'graph.detailedOccupancy.xaxis.max',
    ].map((category) => t(category))

    setFormattedData({
      series: [
        { name: t('graph.filters.kpi.max'), data: formattedMax },
        { name: t('graph.filters.kpi.average'), data: formattedAvg },
        { name: ' ', data: emptyLine },
        ...formattedSeries.reverse(),
      ],
      categories,
      minifiedCategories,
    })
  }, [loading, data])

  if (loading || !formattedData) {
    return <LoadingChart type="heatmap" />
  }

  return (
    <SpaceAttendanceChart
      id={id}
      subtitle={t('graph.frequentation.capacity', { capacity: data.capacity })}
      series={formattedData.series}
      categories={formattedData.categories}
      minifiedCategories={formattedData.minifiedCategories}
      colors={colors}
    />
  )
}

export default SpaceAttendanceController

type GaugeProps = {
  percentage: number
}

const Gauge = ({ percentage }: GaugeProps): JSX.Element => {
  const availabilityClass = percentage < 70 ? 'bg-green' : percentage < 100 ? 'bg-orange' : 'bg-red'
  return (
    <div className="flex justify-center items-center w-2/3 h-2 rounded-lg overflow-hidden">
      <div className={`h-full ${availabilityClass}`} style={{ width: `${percentage}%` }}></div>
      <div className="h-full bg-basic-300" style={{ width: `${100 - percentage}%` }}></div>
      <div></div>
    </div>
  )
}

export default Gauge

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { DomainsStructure } from 'types/DomainsStructure'
import { useTranslation } from 'react-i18next'
import { optionAllValue } from 'utils/constants/graphs/global'
import {
  graphsStructure,
  detailedOccupancyValue,
  monthlyValue,
  workstation,
  frequentationValue,
  spaceAttendanceValue,
  detailedOccupancyTableValue,
  calibratedUtilizationValue,
  benchmarkValue,
} from 'utils/constants/graphs/graphsStructure'
import { getFilterType, isMonthlyGraph, isRIEGraph } from 'utils/filtersUtils'

const { placeholder, all, name } = graphsStructure.buildingCode

const handleStateChange = (state: FilterState, structure: DomainsStructure, value?: string): FilterState => {
  if (state.BUILDING.value === value) return state

  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isMonthly = state.GRAPH.value === monthlyValue
  const isFrequentation = state.GRAPH.value === frequentationValue
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isHeatmap = state.GRAPH.value === detailedOccupancyTableValue
  const isCalibratedUtilization = state.GRAPH.value === calibratedUtilizationValue
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const isRoomTypeWorkstation = state.ROOM_TYPE.value === workstation
  // If in that building there is only one floor, then we want to automatically set the FLOOR value.
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const filteredFloors = selectedDomain?.floors?.filter((floor) => floor.buildingCode === value)
  // Rule : If "all" is selected for the BUILDING filter, then the FLOOR filter must be set as "All" and cannot be changed.
  const floorValue = filteredFloors?.length === 1 ? filteredFloors[0].path : undefined
  const buildingIsAll = value === optionAllValue

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    SPACE_TYPE: state.SPACE_TYPE,
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    BUILDING: {
      ...state.BUILDING,
      active: !value,
      value,
    },
    FLOOR: {
      ...initialFilterState.FLOOR,
      value: floorValue,
      active: !!value && !floorValue && !isDetailedOccupancy,
      // Rule : The FLOOR filter must not be filled for the heatmap and RIE and Frequentation RIE
      required:
        !isRIEGraph(state.GRAPH) && !isDetailedOccupancy && !isMonthly && !isFrequentation && value !== optionAllValue,
    },
    KPI: {
      ...initialFilterState.KPI,
      required: isDetailedOccupancy || isSpaceAttendance,
      active: (isSpaceAttendance || isHeatmap || isCalibratedUtilization) && !!value,
    },
    // Specific case when the chosen GRAPH is heatmap
    BUSINESS_UNITS: {
      ...initialFilterState.BUSINESS_UNITS,
      active: isMonthly && !!floorValue && !!isRoomTypeWorkstation,
    },
    ROOM_TYPE: {
      ...initialFilterState.ROOM_TYPE,
      // If a building is selected and it's "ALL", then the FLOOR filter is automatically set to "ALL", and then we skip
      // to this filter. Also, there are no FLOOR filter for the heatmap chart. In this case, it should be activated
      // If it is MONTHLY, then we must check that there are business units for this client
      active: !!value || !!buildingIsAll,
      required:
        isMonthlyGraph(state.GRAPH) ||
        (!isRIEGraph(state.GRAPH) && !frequentationValue && !spaceAttendanceValue && !detailedOccupancyTableValue),
    },
    QUARTER: {
      ...initialFilterState.QUARTER,
      active: isBenchmark ? false : !!value,
    },
  }
}

const BuildingFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const buildingValue = state.BUILDING.value
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isBenchmark = state.GRAPH.value === benchmarkValue
  const isHeatmap = state.GRAPH.value === detailedOccupancyTableValue
  if ((isSpaceAttendance || isHeatmap) && buildingValue !== undefined) state.KPI.active = true
  const isBuildingCounting = state.SPACE_TYPE.value === 'BUILDING'
  const isFloorCounting = state.SPACE_TYPE.value === 'FLOOR'
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const filteredFloors = selectedDomain?.floors?.filter((floor) => floor.buildingCode === state.BUILDING.value)

  const buildings = structure.domains.find((domain) => domain.path === state.SITE.value)?.buildings || []
  const countingCompartments = structure.domains
    .find((domain) => domain.path === state.SITE.value)
    ?.compartments?.filter((compartment) => compartment.counting)
  const countingFloors = isFloorCounting
    ? structure.domains.find((domain) => domain.path === state.SITE.value)?.floors?.filter((floor) => floor.counting)
    : structure.domains
        .find((domain) => domain.path === state.SITE.value)
        ?.floors?.filter((floor) => countingCompartments?.find((compartment) => compartment.floorPath == floor.path))

  const floorValue = isSpaceAttendance
    ? countingFloors?.length === 1
      ? countingFloors[0].path
      : undefined
    : filteredFloors?.length === 1
    ? filteredFloors[0].path
    : undefined
  if (floorValue) state.FLOOR.value = floorValue
  const buildingsForCountingFloor = buildings.filter((building) =>
    countingFloors?.find((floor) => floor.buildingCode == building.code),
  )
  const buildingsForCountingCompartment = buildings.filter((building) =>
    countingCompartments?.find((compartment) => compartment.buildingCode == building.code),
  )

  const spaceAttendanceBuildings = buildings.filter((building) => building.counting)
  // Rule : if there are no buildings in a site, then we don't render show the BUILDING filter.
  // But we make this check only when a site is selected. Otherwise, this filter won't show between when we select
  // a graph and a site
  if (state.SITE.value && buildings.length === 0) return null
  const options = isSpaceAttendance
    ? isBuildingCounting
      ? [
          ...spaceAttendanceBuildings.map((building) => ({
            label: t(name, { name: building.name }),
            value: building.code,
          })),
        ]
      : isFloorCounting
      ? [
          ...buildingsForCountingFloor.map((building) => ({
            label: t(name, { name: building.name }),
            value: building.code,
          })),
        ]
      : [
          ...buildingsForCountingCompartment.map((building) => ({
            label: t(name, { name: building.name }),
            value: building.code,
          })),
        ]
    : buildings.length <= 1
    ? [
        ...buildings.map((building) => ({
          label: t(name, { name: building.name }),
          value: building.code,
        })),
      ]
    : [
        {
          label: t(all),
          value: optionAllValue,
        },
        ...buildings.map((building) => ({
          label: t(name, { name: building.name }),
          value: building.code,
        })),
      ]
  const buildingData = options.find((building) => building.value === buildingValue)
  const type = getFilterType(state.GRAPH.value, state.BUILDING.active, buildingData)
  const onChange = (value?: string) => onFilterChange(handleStateChange(state, structure, value))
  if (buildings.length == 1 && isBenchmark) {
    state.BUILDING.value = buildings[0].code
    state.ROOM_TYPE.active = true
  }
  return (
    <Filter
      isBenchmark={isBenchmark}
      type={type}
      placeholder={t(placeholder)}
      label={buildingData?.label}
      // Rule : The BUILDING filter has an "ALL" option. But when there is only one building, the value must
      // automatically be set, and disable the possibility to open the filter. (same for the SITE filter)
      disabled={(!state.BUILDING.value && !state.BUILDING.active) || options.length <= 1}
      selected={buildingValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default BuildingFilter

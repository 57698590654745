import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from '../required/GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { getFilterType } from 'utils/filtersUtils'

const options = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2-3',
    value: '2-3',
  },
  {
    label: '4-5',
    value: '4-5',
  },
  {
    label: '6-7',
    value: '6-7',
  },
  {
    label: '8-10',
    value: '8-10',
  },
  {
    label: '11-15',
    value: '11-15',
  },
  {
    label: '16-25',
    value: '16-25',
  },
  {
    label: '25+',
    value: '25+',
  },
]

const handleStateChange = (state: FilterState, value?: string): FilterState => {
  if (state.ROOM_SIZE_GROUPED.value === value) return state

  return {
    ...state,
    ROOM_SIZE_GROUPED: {
      ...initialFilterState.ROOM_SIZE_GROUPED,
      value,
    },
    ROOM_SIZE_DETAILED: {
      ...initialFilterState.ROOM_SIZE_DETAILED,
    },
    CAPACITY_SIZE: {
      ...initialFilterState.CAPACITY_SIZE,
    },
  }
}

const RoomSizeGroupedFilter: FC = () => {
  const { t } = useTranslation()
  const { state, onFilterChange } = useContext(GraphFiltersContext)
  const roomSizeValue = state.ROOM_SIZE_GROUPED.value

  const roomSizeData = options.find((option) => option.value === roomSizeValue)
  const type = getFilterType(state.GRAPH.value, state.ROOM_SIZE_GROUPED.active, roomSizeValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, value))

  return (
    <Filter
      type={type}
      placeholder={t('graph.filters.groupedRoomSize')}
      label={roomSizeData?.label}
      disabled={!state.ROOM_SIZE_GROUPED.active && !state.ROOM_SIZE_GROUPED.value}
      selected={roomSizeValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default RoomSizeGroupedFilter

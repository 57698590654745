import { FC } from 'react'
import SubNavbar from 'components/molecules/navbar/SubNavbar'
import { FavoritesNavbarItems } from 'utils/constants/routes/NavbarItems'
import PageLayout from 'components/layouts/PageLayout'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'
import SavedReportsListView from './SavedReportsListView'
import SavedReportsDashboardsView from './SavedReportsDashboardsView'

const SavedReportsView: FC = () => {
  const { url } = useRouteMatch()

  return (
    <PageLayout className="max-w-full overflow-y-hidden">
      <SubNavbar items={FavoritesNavbarItems} />
      <Switch>
        <Route exact path={generatePath(PATHS.FAVORITES.SINGLE_REPORT, { url })} component={SavedReportsListView} />
        <Route exact path={generatePath(PATHS.FAVORITES.SINGLE_REPORT_ID, { url })} component={SavedReportsListView} />

        <Route
          exact
          path={generatePath(PATHS.FAVORITES.DASHBOARD, {
            url,
          })}
          component={SavedReportsDashboardsView}
        />
        <Route
          exact
          path={generatePath(PATHS.FAVORITES.DASHBOARD_ID, {
            url,
          })}
          component={SavedReportsDashboardsView}
        />
        <Route
          path={PATHS.ALL}
          component={() => <Redirect to={generatePath(PATHS.FAVORITES.SINGLE_REPORT, { url })} />}
        />
      </Switch>
    </PageLayout>
  )
}

export default SavedReportsView

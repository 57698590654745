import axios from 'axios'

export const client = axios.create()

export const setHeaderToken = (token: string): void => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const removeHeaderToken = (): void => {
  delete client.defaults.headers.common.Authorization
}

import { COLORS } from 'assets/colors/colors'
import { useTranslation } from 'react-i18next'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'

type OptionsType = {
  id: string | undefined
  title: string
  categories: string[]
  colors: string[]
}

export const useBenchmarkOptions = () => {
  const { t } = useTranslation()

  const getBenchmarkOptions = ({
    title,
    id,
    colors,
    categories,
  }: OptionsType): ChartProps<HeatmapSeries>['options'] => ({
    legend: {
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      position: 'top',
      markers: {
        width: 32,
        height: 8,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 8,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value * 100 + '%'
        },
      },
    },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
      },
      y: {
        formatter: function (obj, seriesIndex, dataPointIndex) {
          return `${t('graph.benchmark.tooltip', {
            percentage: Math.round(obj * 100),
          })}`
        },
        title: {
          formatter: (seriesName, value) => {
            if (seriesName) return ''
          },
        },
      },
      z: {
        formatter: undefined,
        title: 'Size: ',
      },
      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: 'Roboto',
        color: '#4F4F64',
      },
    },
    colors:
      colors?.length > 0
        ? [
            colors.find((obj) => obj.type == 'FIRST_DECILE')?.color,

            colors.find((obj) => obj.type == 'MEDIAN')?.color,
            colors.find((obj) => obj.type == 'NINTH_DECILE')?.color,
            colors.find((obj) => obj.type == 'VALUE')?.color,
          ]
        : [COLORS.deepCerise50, COLORS.deepCerise, COLORS.darkGrey, COLORS.blue],
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 180,
      },
      categories,
    },
    stroke: {
      width: [3, 3, 3, 3],
      dashArray: [8, 8, 8, 0],
      curve: 'straight',
      lineCap: 'round ',
    },

    chart: {
      id: id ? id.toString() : 'benchmark-chart',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
  })

  return { getBenchmarkOptions }
}

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType } from 'utils/filtersUtils'

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.CUSTOM_ATTRIBUT_COLLAB.value === value) return state

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    KPI: {
      ...state.KPI,
      active: !!value,
    },
    QUARTER: state.QUARTER,
    SPACE_TYPE: state.SPACE_TYPE,
    MONITORING_ROOM_TYPE: state.MONITORING_ROOM_TYPE,
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ENTITY: state.MONITORING_ENTITY,
    CUSTOM_ATTRIBUT_WKS: state.CUSTOM_ATTRIBUT_WKS,
    CUSTOM_ATTRIBUT_COLLAB: {
      ...state.CUSTOM_ATTRIBUT_COLLAB,
      value,
      active: !value,
    },
  }
}

const CustomAttributCollabFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const { ...customAttributStructure } = graphsStructure.customAttribut

  const values = Object.entries(customAttributStructure).map(([value, label]) => ({ label, value }))
  const locale = navigator.language === 'fr-FR' || navigator.language === 'fr' ? 'fr' : 'en'
  const customAttributValue = state.CUSTOM_ATTRIBUT_COLLAB.value

  const haveCustomAttributeCollab = structure.domains.find(
    (domain) => domain.path === state.SITE.value,
  )?.customAttributeCollab
  const customAttributPlaceholder =
    state.MONITORING_ROOM_TYPE.value &&
    !state.MONITORING_ROOM_TYPE.value.includes('WORKSTATION') &&
    haveCustomAttributeCollab
      ? locale == 'fr'
        ? structure.domains.find((domain) => domain.path === state.SITE.value)?.customAttributeCollab.i18nFR
        : structure.domains.find((domain) => domain.path === state.SITE.value)?.customAttributeCollab.i18nEN
      : undefined
  state.CUSTOM_ATTRIBUT_COLLAB.placeholder = customAttributPlaceholder
  const options = values.map((value) => ({
    ...value,
    label: t(value.label),
  }))
  const customAttributData = options.find((value) => value.value === customAttributValue)

  const type = getFilterType(state.GRAPH.value, state.CUSTOM_ATTRIBUT_COLLAB.active, customAttributData)

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))
  return (
    <Filter
      type={type}
      placeholder={customAttributPlaceholder}
      label={customAttributData?.label}
      disabled={!state.CUSTOM_ATTRIBUT_COLLAB.active && !state.CUSTOM_ATTRIBUT_COLLAB.value}
      selected={customAttributValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default CustomAttributCollabFilter

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { optionAllValue } from 'utils/constants/graphs/global'
import {
  countingMaxValue,
  detailedOccupancyValue,
  graphsStructure,
  heatmapValue,
  monthlyValue,
  roomBookingValue,
  spaceAttendanceValue,
  workstation,
} from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, all } = graphsStructure.floorPath

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.FLOOR.value === value) return state
  const isMonthly = state.GRAPH.value === monthlyValue
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isRoomTypeWorkstation = state.ROOM_TYPE.value === workstation
  const isHeatmap = state.GRAPH.value === heatmapValue
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isCountingMax = state.GRAPH.value === countingMaxValue
  const isRoomBooking = state.GRAPH.value === roomBookingValue
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const filteredCompartments = selectedDomain?.compartments?.filter((compartment) => compartment.floorPath === value)
  const compartmentValue =
    (filteredCompartments?.length === 1 && !isHeatmap) ||
    (isHeatmap && state.LEVEL.value === 'COMPARTMENT' && filteredCompartments?.length === 1)
      ? filteredCompartments[0].path
      : undefined
  const activeCompartment =
    !!compartmentValue ||
    isDetailedOccupancy ||
    isMonthly ||
    isCountingMax ||
    isRoomBooking ||
    state.LEVEL.value === 'COMPARTMENT' ||
    (isSpaceAttendance && state.SPACE_TYPE.value === 'COMPARTMENT')
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    SPACE_TYPE: state.SPACE_TYPE,
    DETAILED_OCCUPANCY: state.DETAILED_OCCUPANCY,
    QUARTER: state.QUARTER,
    LEVEL: state.LEVEL,
    MONITORING_SCOPE: state.MONITORING_SCOPE,
    MONITORING_ROOM_TYPE: state.MONITORING_ROOM_TYPE,
    CUSTOM_ATTRIBUT_COLLAB_VALUE: state.CUSTOM_ATTRIBUT_COLLAB_VALUE,
    CUSTOM_ATTRIBUT_WKS_VALUE: state.CUSTOM_ATTRIBUT_WKS_VALUE,
    FLOOR: {
      ...initialFilterState.FLOOR,
      value,
      active: true,
    },
    COMPARTMENT: {
      ...initialFilterState.COMPARTMENT,
      value: compartmentValue,
      required: state.SPACE_TYPE.value == 'COMPARTMENT',
      active: activeCompartment,
    },
    ROOM_TYPE: {
      ...state.ROOM_TYPE,
      active: !!value,
    },
    BUSINESS_UNITS: {
      ...initialFilterState.BUSINESS_UNITS,
      active: isMonthly && !!isRoomTypeWorkstation,
    },
    KPI: {
      ...state.KPI,
      required: isSpaceAttendance,
      active: true,
      // active: isSpaceAttendance && state.SPACE_TYPE.value === 'FLOOR' && !!value ,
    },
  }
}

const FloorFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isHeatmap = state.GRAPH.value === heatmapValue
  const isMonthly = state.GRAPH.value === monthlyValue

  const floorValue = state.FLOOR.value
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const compartments = selectedDomain?.compartments
  if (floorValue && compartments == undefined && isMonthly) state.ROOM_TYPE.active = true
  const filteredCompartmentsCounting = selectedDomain?.compartments?.filter(
    (compartment) => compartment.floorPath === floorValue && compartment.counting,
  )
  const filteredCompartmentsWithMap = selectedDomain?.compartments?.filter(
    (compartment) => compartment.floorPath === floorValue && compartment.map,
  )
  const compartmentValue = isHeatmap
    ? filteredCompartmentsWithMap?.length === 1
      ? filteredCompartmentsWithMap[0].path
      : undefined
    : isSpaceAttendance
    ? filteredCompartmentsCounting?.length === 1
      ? filteredCompartmentsCounting[0].path
      : undefined
    : undefined
  if (compartmentValue && spaceAttendanceValue) {
    state.COMPARTMENT.value = compartmentValue
  }
  if (isSpaceAttendance && state.SPACE_TYPE.value === 'FLOOR' && floorValue !== undefined) state.KPI.active = true
  if (isHeatmap && floorValue !== undefined) state.KPI.active = true
  const optionAll = {
    label: t(all),
    value: optionAllValue,
  }

  const floors =
    state.BUILDING.value !== undefined
      ? structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.floors?.filter((floor) => floor.buildingCode === state.BUILDING.value) || []
      : structure.domains.find((domain) => domain.path === state.SITE.value)?.floors || []
  const countingCompartments = structure.domains
    .find((domain) => domain.path === state.SITE.value)
    ?.compartments?.filter((compartment) => compartment.counting)
  const countingFloors = floors.filter((floor) =>
    countingCompartments?.find((compartment) => compartment.floorPath == floor.path),
  )
  const spaceAttendanceFloors = floors.filter((floor) => floor.counting)
  const heatmapCompartments = structure.domains
    .find((domain) => domain.path === state.SITE.value)
    ?.compartments?.filter((compartment) => compartment.map)
  const heatmapCompartmentsFloors = floors.filter((floor) =>
    heatmapCompartments?.find((compartment) => compartment.floorPath == floor.path),
  )
  const heatmapFloors =
    state.LEVEL.value === 'COMPARTMENT' ? heatmapCompartmentsFloors : floors.filter((floor) => floor.map)
  const isFloorCounting = state.SPACE_TYPE.value === 'FLOOR'

  const options =
    state.BUILDING.value === optionAllValue && !isSpaceAttendance
      ? [optionAll]
      : isHeatmap
      ? heatmapFloors.map((floor) => ({ label: floor.name, value: floor.path }))
      : isSpaceAttendance
      ? isFloorCounting
        ? spaceAttendanceFloors.map((floor) => ({ label: floor.name, value: floor.path }))
        : countingFloors.map((floor) => ({ label: floor.name, value: floor.path }))
      : floors.length > 1
      ? [optionAll, ...floors.map((floor) => ({ label: floor.name, value: floor.path }))]
      : [...floors.map((floor) => ({ label: floor.name, value: floor.path }))]
  const floorData = options.find((option) => option.value === state.FLOOR.value)
  const type = getFilterType(state.GRAPH.value, state.FLOOR.active, floorData)
  if (options.length == 1) state.COMPARTMENT.active = true

  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))

  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={floorData?.label}
      selected={floorValue}
      // Rule : If there's only one value, then it is automatically selected and the user can't open the filter.
      // Therefore, we add the '[all]' option only in the case where there's more than 1 option. Otherwise, the filter can
      // be opened.
      // NAF-810: the BuildingFilter might have several values that are narrowed to one in this component, but in this case it was not autoselected
      // and the filter was required but inactive and empty, preventing to submit the form
      disabled={!state.FLOOR.active && !state.FLOOR.value}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default FloorFilter

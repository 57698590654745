// TODO : Put the real response once the webservice is available
import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'
import { SavedReport, SavedReportList } from 'types/SavedReportsTypes'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { PagesType } from 'types/DashboardType'

type UpdateSavedReportBody = {
  id: string
  name: string
  filters: Partial<Record<keyof typeof graphsStructure, string>>
}

// type UpdateDashboardBody = {
//   name: string
//   page: PagesType[]
// }

const getAllSavedReports = (clientCode: string): Promise<SavedReportList> => {
  return execute<SavedReportList>(generatePath(API.SAVED_REPORTS.GET_ALL, { clientCode }), 'GET')
}

const getSavedReportById = (clientCode: string, id: string): Promise<SavedReport> => {
  return execute<SavedReport>(generatePath(API.SAVED_REPORTS.GET_ONE, { clientCode, id }), 'GET')
}

const updateSavedReport = (clientCode: string, id: string, body: UpdateSavedReportBody): Promise<unknown> => {
  return execute<unknown>(generatePath(API.SAVED_REPORTS.UPDATE, { clientCode, id }), 'PUT', body)
}

const duplicateSavedReport = (clientCode: string, savedReportId: number): Promise<void> => {
  return execute(
    generatePath(API.SAVED_REPORTS.DUPLICATE, {
      clientCode,
      id: savedReportId.toString(),
    }),
    'POST',
  )
}

const deleteSavedReport = (clientCode: string, savedReportId: number): Promise<void> => {
  return execute(
    generatePath(API.SAVED_REPORTS.DELETE, {
      clientCode,
      id: savedReportId.toString(),
    }),
    'DELETE',
  )
}

const createDashboard = (clientCode: string, dashboardName: string, reportId: number): Promise<unknown> => {
  const body = {
    name: dashboardName,
    reportId: reportId,
  }
  return execute(generatePath(API.SAVED_REPORTS.NEWDASHBOARD, { clientCode }), 'POST', body)
}
const addToExistingDashboard = (clientCode: string, dashboardId: number, reportId: number): Promise<unknown> => {
  return execute(
    generatePath(API.SAVED_REPORTS.EXISTING_DASHBOARD, {
      clientCode,
      dashboardId: dashboardId.toString(),
      reportId: reportId.toString(),
    }),
    'POST',
  )
}
const getAllDashboards = (clientCode: string): Promise<any> => {
  return execute(generatePath(API.SAVED_REPORTS.GET_ALL_DASHBOARDS, { clientCode }), 'GET')
}

const getDashboard = (clientCode: string, dashboardId: string): Promise<any> => {
  return execute(
    generatePath(API.SAVED_REPORTS.GET_DASHBOARD, { clientCode, dashboardId: dashboardId.toString() }),
    'GET',
  )
}
const getAuthorizedDashboard = (clientCode: string, reportId: number): Promise<any> => {
  return execute(
    generatePath(API.SAVED_REPORTS.GET_AUTHORIZED_DASHBOARD, { clientCode, reportId: reportId.toString() }),
    'GET',
  )
}
const getHomepageDashboard = (clientCode: string): Promise<any> => {
  return execute(generatePath(API.SAVED_REPORTS.GET_HOMEPAGE_DASHBOARD, { clientCode }), 'GET')
}

const deleteDashboard = (clientCode: string, dashboardId: number): Promise<void> => {
  return execute(
    generatePath(API.SAVED_REPORTS.DELETE_DASHBOARD, {
      clientCode,
      id: dashboardId.toString(),
    }),
    'DELETE',
  )
}

const duplicateDashboard = (clientCode: string, dashboardId: number): Promise<void> => {
  return execute(
    generatePath(API.SAVED_REPORTS.DUPLICATE_DASHBOARD, {
      clientCode,
      id: dashboardId.toString(),
    }),
    'POST',
  )
}
const addDashboardToHomepage = (clientCode: string, dashboardId: number): Promise<void> => {
  return execute(
    generatePath(API.SAVED_REPORTS.ADD_DASHBOARD_TO_HOMEPAGE, {
      clientCode,
      id: dashboardId.toString(),
    }),
    'POST',
  )
}

const editDashboard = (
  clientCode: string,
  dashboardName: string,
  dashboardId: number,
  page: PagesType,
): Promise<unknown> => {
  const body = {
    name: dashboardName,
    page: page,
  }
  return execute(
    generatePath(API.SAVED_REPORTS.EDIT_DASHBOARD, { clientCode, id: dashboardId.toString() }),
    'PATCH',
    body,
  )
}
const automaticSendingDashboard = (
  clientCode: string,
  periodicity: string,
  dashboardId: number,
  recipients: string[],
  activated: boolean,
  language: string,
): Promise<unknown> => {
  const body = {
    periodicity: periodicity,
    recipients: recipients,
    activated: activated,
    language: language,
  }
  return execute(
    generatePath(API.SAVED_REPORTS.ADD_AUTOMATIC_SENT, { clientCode, id: dashboardId.toString() }),
    'PUT',
    body,
  )
}
const getAutomaticSendingDashboard = (clientCode: string, dashboardId: number): Promise<unknown> => {
  return execute(generatePath(API.SAVED_REPORTS.GET_AUTOMATIC_SENT, { clientCode, id: dashboardId.toString() }), 'GET')
}
const sendMail = (clientCode: string, dashboardId: string, file: any, recipient: string): Promise<unknown> => {
  const body = recipient ? { file: file, recipient: recipient } : { file: file }
  return execute(generatePath(API.SAVED_REPORTS.SEND_MAIL, { clientCode, id: dashboardId }), 'POST', body)
}
const sendInstantMail = (clientCode: string, dashboardId: string): Promise<unknown> => {
  return execute(generatePath(API.SAVED_REPORTS.INSTANT_MAIL, { clientCode, id: dashboardId }), 'POST')
}

export const savedReportsService = {
  getAll: getAllSavedReports,
  getOne: getSavedReportById,
  update: updateSavedReport,
  delete: deleteSavedReport,
  duplicate: duplicateSavedReport,
  createDashboard,
  getAllDashboards,
  addToExistingDashboard,
  getDashboard,
  duplicateDashboard,
  deleteDashboard,
  editDashboard,
  addDashboardToHomepage,
  getHomepageDashboard,
  getAuthorizedDashboard,
  automaticSendingDashboard,
  getAutomaticSendingDashboard,
  sendMail,
  sendInstantMail,
}

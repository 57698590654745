import { FC, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { LoggedInRoutes, LoggedOutRoutes, NoRightsRoutes, SuperAdminRoutes } from './utils/constants/routes/AppRoutes'
import Navbar from './components/molecules/navbar/main/Navbar'

import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useJimo } from 'hooks/useJimo'
import { PermissionEnum } from 'utils/constants/permissions'
import { useAuthentication } from 'core/auth/useAuthentication'
import { cookies } from 'core/cookies'
import { useQueryParams } from 'hooks/useQueryParams'

const App: FC = () => {
  const { isAuthenticated, permissions, userCanAccessRoute, redirectUrlForLoggedInUser } = useAuthentication()
  const history = useHistory()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const codeInParams = queryParams.get('code')
  const stateInParams = queryParams.get('state')
  const { apiKey, client = '' } = useQueryParams()
  const { clientCode = client, data: user, isLoading } = useCurrentUser()
  const isSuperAdmin = permissions.includes(PermissionEnum.ANALYTICS_SUPER_ADMIN)
  const hasRight =
    permissions.filter((p) => [PermissionEnum.ANALYTICS, PermissionEnum.ANALYTICS_REALTIME].includes(p)).length > 0
  const loggedInRoutes = hasRight ? LoggedInRoutes : NoRightsRoutes
  cookies.remove(`skip_preferred_site_${clientCode}`, { path: '/' })
  const routes = isAuthenticated
    ? isSuperAdmin
      ? SuperAdminRoutes.concat(loggedInRoutes)
      : loggedInRoutes
    : LoggedOutRoutes
  useJimo()

  useEffect(() => {
    if (isAuthenticated && redirectUrlForLoggedInUser && !location.pathname.includes('/dashboard-email')) {
      history.push(redirectUrlForLoggedInUser)
    }
  }, [isAuthenticated, redirectUrlForLoggedInUser])

  useEffect(() => {
    if (user) {
      const { email, firstname, lastname, id, language, clients } = user

      window.jimo.push(['set', 'user:email', [email]])
      window.jimo.push(['set', 'user:name', [`${firstname} ${lastname}`]])

      if (id) {
        window.jimo.push(['set', 'user:id', [id?.toString()]])
        window['jimo'].push(['do', 'identify', [id?.toString()]])
      }

      window.jimo.push([
        'set',
        'user:attributes',
        [
          {
            language,
            clients: clients
              .map((c) => c.name)
              .join('_')
              .substring(0, 249),
          },
        ],
      ])
      // if possible call this only once
      // if window.jimo is not null/undefined, Array.isArray(window.jimo) is true => jimo not initialized yet
      if (Array.isArray(window.jimo) && window.jimoInit) {
        window.jimoInit()
      }
      // call kill on logout
    }
  }, [user])

  if (isLoading && !(user || apiKey || codeInParams || stateInParams)) {
    return <p>Loading...</p>
  }

  return (
    <div className="flex flex-col h-screen">
      {isAuthenticated && !apiKey && <Navbar />}
      <Switch>
        {routes.filter(userCanAccessRoute).map((route) => (
          <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
        ))}
      </Switch>
    </div>
  )
}

export default App

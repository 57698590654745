import { FC, useEffect, useState } from 'react'
import { UtilizationQualityDataWSType } from 'types/GraphDataType'
import UtilizationQualityChart from './utilizationQualityChart'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'
import { ColumnChartSeries } from 'types/react-apexcharts/charts'

type Props = {
  loading: boolean
  data: UtilizationQualityDataWSType
  title: string
  id?: string
  colors: string[]
}
type FormattedData = {
  data: ColumnChartSeries
  categories: (string | string[])[]
  minutes: any
}

const UtilizationQualityController: FC<Props> = (props) => {
  const { loading, data, title, id, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<FormattedData>()
  useEffect(() => {
    if (loading) {
      return
    }

    const categories: (string | string[])[] = []
    data.result.forEach((roomGroup) => {
      const splittedName =
        roomGroup.name.length > 20
          ? [roomGroup.name.substring(0, 20), roomGroup.name.substring(20, roomGroup.name.length)]
          : [roomGroup.name]
      categories.push([...splittedName, '(' + roomGroup.capacity + 'p.)'])
    })
    const ratesByCount: Record<string, number[]> = {}

    data.result.forEach((roomGroup, index) => {
      roomGroup.rates.forEach((rate) => {
        const key = t(`graph.utilizationQuality.${rate.utilizationQuality}`) as keyof typeof ratesByCount

        if (!ratesByCount[key]) {
          ratesByCount[key] = []
        }

        if (ratesByCount[key][index] === undefined) {
          ratesByCount[key].push(rate.utilizationQualityRate)
        } else {
          const oldValue = ratesByCount[key][index]
          ratesByCount[key][index] = oldValue + rate.utilizationQualityRate
        }
      })
    })

    const ratesAsSeries = Object.entries(ratesByCount).map(([name, data]) => ({
      name,
      data,
    }))
    const ratesByMinutes: Record<string, number[]> = {}

    data.result.forEach((roomGroup, index) => {
      roomGroup.rates.forEach((rate) => {
        const key = t(`graph.utilizationQuality.${rate.utilizationQuality}`) as keyof typeof ratesByCount

        if (!ratesByMinutes[key]) {
          ratesByMinutes[key] = []
        }

        if (ratesByMinutes[key][index] === undefined) {
          ratesByMinutes[key].push(rate.utilizationQualityDurationMinutes)
        } else {
          const oldValue = ratesByCount[key][index]
          ratesByMinutes[key][index] = oldValue + rate.utilizationQualityDurationMinutes
        }
      })
    })

    const minutesAsSeries = Object.entries(ratesByMinutes).map(([name, data]) => ({
      name,
      data,
    }))

    setFormattedData({
      data: ratesAsSeries,
      minutes: minutesAsSeries,
      categories,
    })
  }, [loading, data])
  if (loading || !formattedData) {
    return <LoadingChart type="bar" horizontal stacked />
  }

  return (
    <div id="utilization-quality-chart">
      <UtilizationQualityChart
        id={id}
        title={title}
        totalRoomsCount={data.totalRoomsCount}
        data={formattedData.data}
        categories={formattedData.categories}
        minutes={formattedData.minutes}
        colors={colors}
      />
    </div>
  )
}

export default UtilizationQualityController

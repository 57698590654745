import { FC, useContext, useEffect, useState } from 'react'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import { DomainsStructure } from 'types/DomainsStructure'
import { useTranslation } from 'react-i18next'
import MenuItems from 'components/atoms/dropdown/MenuItems'
import {
  domainFilterByGraph,
  graphsStructure,
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  calibratedUtilizationValue,
  heatmapValue,
  roomBookingValue,
  countingMaxValue,
} from 'utils/constants/graphs/graphsStructure'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { getBusinessUnits } from 'utils/filtersUtils'

export type GraphType =
  | typeof topFlopValue
  | typeof stackedColumnValue
  | typeof detailedOccupancyValue
  | typeof monthlyValue
  | typeof RIE
  | typeof frequentationValue
  | typeof spaceAttendanceValue
  | typeof utilizationQualityValue
  | typeof detailedOccupancyTableValue
  | typeof monitoringValue
  | typeof heatmapValue
  | typeof calibratedUtilizationValue
  | typeof roomBookingValue
  | typeof countingMaxValue

const { spaceGraph } = graphsStructure
const { placeholder, ...graphStructure } = graphsStructure.graph

export const filterGraphs = Object.entries(graphStructure).map(([value, label]) => ({ label, value }))

export const nameGraphs = Object.entries(spaceGraph).map(([value, label]) => ({ label, value }))

const handleStateChange = (
  state: FilterState,
  structure: DomainsStructure,
  preferredSite?: string,
  value?: string,
  graphType?: string,
  graphMenu?: any,
): FilterState => {
  // Rule : we reinitialize all filters when a new GRAPH value is selected.
  // If the GRAPH value hasn't changed, then we don't change anything.
  if (graphType) state.GRAPH.value = graphType
  if (value === state.GRAPH.value) return state
  if (!value) return initialFilterState
  // Otherwise, we reset the state.
  // Also, we want to automatically set the site to the user's preferred site.
  // If there is only one building in that site, we want to automatically set it.
  // Same for the floor in that building.

  // We find all the sites available for the selected graph.
  const filteredSites = structure.domains.filter((domain) =>
    domainFilterByGraph[value as keyof typeof graphStructure](domain.features),
  )
  // If the user has chosen a preferred site for this client, then we want to automatically fill this filter.
  // In this case, everything cascades : if he doesn't choose a site, then we have to automatically fill the BUILDING
  // filter if there are no buildings, same for floors, and so on....
  const preferredSiteData = filteredSites.find((domain) => domain.path === preferredSite)

  const selectedSite = filteredSites.length === 1 ? filteredSites[0] : preferredSiteData
  const siteValue = selectedSite?.path
  const buildings = selectedSite?.buildings
  const spaceAttendanceValue = buildings?.length === 1 ? buildings[0].code : undefined
  const floors = selectedSite?.floors?.filter((floor) => floor.buildingCode === spaceAttendanceValue)
  const floorValue = floors?.length === 1 ? floors[0].path : undefined
  const isDetailedOccupancy = value === detailedOccupancyValue
  const isMonthly = value === monthlyValue
  const enableValue =
    graphMenu[0].submenu.find((elt) => elt.value == state.ENTRY.value).submenu.find((item) => item.value === value)
      ?.enable || false
  const businessUnits = getBusinessUnits(structure, state)

  return {
    ...initialFilterState,
    GRAPH: {
      ...initialFilterState.GRAPH,
      value: value,
      active: !value,
      enable: enableValue,
    },
    SITE: {
      ...initialFilterState.SITE,
      value: siteValue,
      active: !!value && !siteValue,
    },
    BUILDING: {
      ...initialFilterState.BUILDING,
      required: !!buildings,
      value: spaceAttendanceValue,
      active: !!value && !!siteValue && !spaceAttendanceValue,
    },
    FLOOR: {
      ...initialFilterState.FLOOR,
      value: floorValue,
      // Rule : The FLOOR filter must not be filled for the heatmap
      required:
        value !== detailedOccupancyValue &&
        value !== RIE &&
        value !== frequentationValue &&
        value !== detailedOccupancyTableValue,
      // If there are no buildings, then the BUILDING filter is hidden. In this case, the FLOOR filter must be activated.
      active: !!value && !!siteValue && (!!spaceAttendanceValue || !buildings) && !floorValue && !isDetailedOccupancy,
    },
    DETAILED_OCCUPANCY: {
      ...initialFilterState.DETAILED_OCCUPANCY,
      required: value === detailedOccupancyValue,
      //   // If there are no buildings and no floors or that there is only one option in each of these filters (which means
      //   // they are already selected and we skip them), we activate the HEATMAP filter. (Only in the case where the selected
      //   // GRAPH is Heatmap).
      //   active: isDetailedOccupancy && !!value && !!siteValue && (!!spaceAttendanceValue || !buildings),
    },
    KPI: {
      ...initialFilterState.DETAILED_OCCUPANCY,
      required: value === detailedOccupancyValue,
      active: value === detailedOccupancyTableValue,
    },
    ROOM_TYPE: {
      ...initialFilterState.ROOM_TYPE,
      // The condition is quite large... But there are a lot of filters which can be skipped because they have no or only
      // one value. We have to check them all independently to activate this filter. For the FLOOR and the BUILDING
      // filters, either there are not any in the site, either there's only one and it is already selected.
      active:
        (!isMonthly || (isMonthly && businessUnits?.length === 0)) &&
        !!value &&
        !!siteValue &&
        (!!spaceAttendanceValue || !buildings) &&
        ((isDetailedOccupancy && !!floors) || !floors || !!floorValue),
      required:
        value === countingMaxValue ||
        value === detailedOccupancyTableValue ||
        value === monthlyValue ||
        (value !== RIE &&
          value !== frequentationValue &&
          value !== spaceAttendanceValue &&
          value !== detailedOccupancyTableValue),
    },
    BUSINESS_UNITS: {
      ...initialFilterState.BUSINESS_UNITS,
      // Rule : Only if MONTHLY is picked (same conditions as HEATMAP except for HEATMAP being picked)
      active:
        isMonthly &&
        businessUnits?.length > 0 &&
        !!value &&
        !!siteValue &&
        (!!spaceAttendanceValue || !buildings) &&
        (!floors || !!floorValue),
    },
    ROOM_SIZE_TYPE: {
      ...initialFilterState.ROOM_SIZE_TYPE,
      value: value === stackedColumnValue ? 'CATEGORY' : initialFilterState.ROOM_SIZE_TYPE.value,
    },
  }
}
type Props = {
  graphType: any
}

const GraphFilter: FC<Props> = (props) => {
  const { graphType } = props
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const { data: user } = useCurrentUser()
  const [selectedGraph, setSelectedGraph] = useState(t(placeholder))

  const activeGraphs = filterGraphs.filter((option) =>
    structure.domains.some((domain) =>
      domainFilterByGraph[option.value as keyof typeof graphStructure](domain.features),
    ),
  )
  const inactiveGraphs = filterGraphs
    .filter((val) => !activeGraphs.includes(val))
    .map((elt) => ({ ...elt, enable: false }))
  const options = [...activeGraphs.map((elt) => ({ ...elt, enable: true })), ...inactiveGraphs]
    .map((option) => ({
      ...option,
      label: t(option.label),
    }))
    .sort((a, b) => {
      const aValue = filterGraphs.findIndex((item) => item.value === a.value)
      const bValue = filterGraphs.findIndex((item) => item.value === b.value)
      return aValue - bValue
    })
  const buildingGraphs = options.filter((graph) => graph.value === 'BUILDING_ATTENDANCE')
  const collaborativeSpacesGraphs = structure.domains.some(
    (domain) => domain.features.meetingRoom || domain.features.focusRoom,
  )
    ? options.filter(
        (graph) =>
          graph.value === 'TOP_FLOP' ||
          graph.value === 'DETAILED_OCCUPANCY_TABLE' ||
          graph.value === 'HEAT_MAP' ||
          graph.value === 'UTILIZATION_OF_SPACE' ||
          graph.value === 'UTILIZATION_QUALITY' ||
          graph.value === 'OCCUPANCY_MONTHLY' ||
          graph.value === 'DETAILED_OCCUPANCY' ||
          graph.value === 'MONITORING' ||
          graph.value === 'COUNTING_MAX' ||
          graph.value === 'ROOM_BOOKING',
      )
    : options
        .filter(
          (graph) =>
            graph.value === 'TOP_FLOP' ||
            graph.value === 'DETAILED_OCCUPANCY_TABLE' ||
            graph.value === 'HEAT_MAP' ||
            graph.value === 'UTILIZATION_OF_SPACE' ||
            graph.value === 'UTILIZATION_QUALITY' ||
            graph.value === 'OCCUPANCY_MONTHLY' ||
            graph.value === 'DETAILED_OCCUPANCY' ||
            graph.value === 'MONITORING' ||
            graph.value === 'COUNTING_MAX' ||
            graph.value === 'ROOM_BOOKING',
        )
        .map((elt) => ({ ...elt, enable: false }))
  const workstationGraphs = structure.domains.some((domain) => domain.features.workstation)
    ? options.filter(
        (graph) =>
          graph.value === 'OCCUPANCY_MONTHLY' ||
          graph.value === 'DETAILED_OCCUPANCY' ||
          graph.value === 'DETAILED_OCCUPANCY_TABLE' ||
          graph.value === 'HEAT_MAP' ||
          graph.value === 'MONITORING' ||
          graph.value === 'CALIBRATED_UTILIZATION' ||
          graph.value === 'COUNTING_MAX',
      )
    : options
        .filter(
          (graph) =>
            graph.value === 'OCCUPANCY_MONTHLY' ||
            graph.value === 'DETAILED_OCCUPANCY' ||
            graph.value === 'DETAILED_OCCUPANCY_TABLE' ||
            graph.value === 'HEAT_MAP' ||
            graph.value === 'MONITORING' ||
            graph.value === 'CALIBRATED_UTILIZATION' ||
            graph.value === 'COUNTING_MAX',
        )
        .map((elt) => ({ ...elt, enable: false }))
  const restaurantGraphs = options.filter(
    (graph) => graph.value === 'RESTAURANT_MAX_CUMULATION' || graph.value === 'RESTAURANT_ATTENDANCE_DAY',
  )
  const preferredSite = user?.currentClient.favoriteLocalization?.domainPath?.replaceAll('$', '/')
  const collaborativeSpacesLabels = collaborativeSpacesGraphs.map((item) => item.label)
  const collaborativeSpacesValues = collaborativeSpacesGraphs.map((item) => ({
    value: item.value,
    enable: item.enable,
  }))
  const workstationLabels = workstationGraphs.map((item) => item.label)
  const workstationValues = workstationGraphs.map((item) => ({ value: item.value, enable: item.enable }))
  const restaurantLabels = restaurantGraphs.map((item) => {
    if (item.value === 'RESTAURANT_MAX_CUMULATION') {
      return t('graph.filters.graph.peakRestaurant')
    } else {
      return t('graph.filters.graph.frequentationRestaurant')
    }
  })
  const restaurantValues = restaurantGraphs.map((item) => ({ value: item.value, enable: item.enable }))
  const collaborativeSpacesTitles = []
  const workstationsTitles = []
  const restaurantTitles = []
  for (let i = 0; i < collaborativeSpacesLabels.length; i++) {
    collaborativeSpacesTitles.push({
      title: t(collaborativeSpacesLabels[i]),
      value: collaborativeSpacesValues[i].value,
      enable: collaborativeSpacesValues[i].enable,
    })
  }
  for (let i = 0; i < workstationLabels.length; i++) {
    workstationsTitles.push({
      title: t(workstationLabels[i]),
      value: workstationValues[i].value,
      enable: workstationValues[i].enable,
    })
  }
  for (let i = 0; i < restaurantLabels.length; i++) {
    restaurantTitles.push({
      title: t(restaurantLabels[i]),
      value: restaurantValues[i].value,
      enable: restaurantValues[i].enable,
    })
  }
  const graphMenu = [
    {
      title: t(placeholder),
      submenu: [
        buildingGraphs.length > 0
          ? {
              title: t(spaceGraph.BUILDING),
              value: 'APARTMENT',
              premium: !buildingGraphs.map((item) => item.enable)[0],
              submenu: [
                {
                  title: buildingGraphs.map((item) => item.label)[0],
                  value: buildingGraphs.map((item) => item.value)[0],
                  enable: buildingGraphs.map((item) => item.enable)[0],
                },
              ],
            }
          : '',
        collaborativeSpacesGraphs.length > 0
          ? {
              title: t(spaceGraph.ALL_COLLABORATIVE_SPACES),
              value: 'MEETING',
              premium:
                collaborativeSpacesTitles.filter((title) => !title.enable).length === collaborativeSpacesTitles.length,
              submenu: collaborativeSpacesTitles,
            }
          : '',
        workstationGraphs.length > 0
          ? {
              title: t(spaceGraph.INDIVIDUAL_SPACES),
              value: 'DESKTOP',
              premium: workstationsTitles.filter((title) => !title.enable).length === workstationsTitles.length,
              submenu: workstationsTitles,
            }
          : '',
        restaurantGraphs.length > 0
          ? {
              title: t(spaceGraph.RESTAURANT),
              value: 'RESTAURANT',
              premium: restaurantTitles.filter((title) => !title.enable).length === restaurantTitles.length,
              submenu: restaurantTitles,
            }
          : '',
      ],
    },
  ]

  const onChange = (value?: string) => {
    if (graphMenu) {
      onFilterChange(handleStateChange(state, structure, preferredSite, value, graphType, graphMenu))
      //@ts-ignore
      setSelectedGraph(t(filterGraphs.filter((graph) => graph.value === value)[0].label || ''))
    }
  }
  let type: any
  useEffect(() => {
    if (graphType !== null && graphType.length > 0) {
      setSelectedGraph(t(filterGraphs.filter((graph) => graph.value === graphType)[0]?.label || ''))
      state.SITE.active = true
      type = 'PRIMARY'
    }
  }, [])
  const value = state.GRAPH.value

  const valueData = options.find((option) => option.value === value)
  if (graphType === null || graphType.length == 0) type = state.GRAPH.active ? 'OUTLINED_BLACK' : 'PRIMARY'
  return (
    <ul className="menus">
      {graphMenu.map((menu, index) => {
        const depthLevel = 0
        return (
          <MenuItems
            items={menu}
            onSelect={onChange}
            key={index}
            depthLevel={depthLevel}
            selectedOption={selectedGraph}
            type={type}
          />
        )
      })}
    </ul>
  )
}

export default GraphFilter

import { MapContainer, ZoomControl, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import 'react-step-progress-bar/styles.css'
import { ProgressBar, Step } from 'react-step-progress-bar'
import IconSvg from '../../../atoms/icons/IconSvg'
import Switch from 'react-switch'
import L from 'leaflet'
import { graphService } from 'services/graphService'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { formatDate, formatHour, toHoursAndMinutes } from 'utils/dateUtils'
import { COLORS } from 'assets/colors/colors'

function getColor(percentage, colors) {
  switch (true) {
    case percentage >= 0 && percentage <= 10:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'ZERO_TO_TEN')?.color : COLORS.blue
    case percentage > 10 && percentage <= 20:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'ELEVEN_TO_TWENTY')?.color : COLORS.blue50
    case percentage > 20 && percentage <= 30:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'TWENTY_ONE_TO_THIRTY')?.color : COLORS.blue30
    case percentage > 30 && percentage <= 40:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'THIRTY_ONE_TO_FORTY')?.color : COLORS.deepCerise30
    case percentage > 40 && percentage <= 50:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'FORTY_ONE_TO_FIFTY')?.color : COLORS.deepCerise50
    case percentage > 50 && percentage <= 60:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'FIFTY_ONE_TO_SIXTY')?.color : COLORS.deepCerise80
    case percentage > 60 && percentage <= 70:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'SIXTY_ONE_TO_SEVENTY')?.color : COLORS.deepCerise
    case percentage > 70 && percentage <= 80:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'SEVENTY_ONE_TO_EIGHTY')?.color : COLORS.darkGrey30
    case percentage > 80 && percentage <= 90:
      return colors?.length > 0 ? colors?.find((elt) => elt.type == 'EIGHTY_ONE_TO_NINETY')?.color : COLORS.darkGrey50
    case percentage > 90 && percentage <= 100:
      return colors?.length > 0
        ? colors?.find((elt) => elt.type == 'NINETY_ONE_TO_ONE_HUNDRED')?.color
        : COLORS.darkGrey80
    default:
      return '#ffff'
  }
}
function Legend({ colors }) {
  const map = useMap()
  useEffect(() => {
    if (map) {
      const legend = L.control({ position: 'bottomright' })

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend')
        const backgroundColorNine =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'NINETY_ONE_TO_ONE_HUNDRED')?.color}`
            : 'background-color:#575762'
        const backgroundColorEight =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'EIGHTY_ONE_TO_NINETY')?.color}`
            : 'background-color:#96969d'
        const backgroundColorSeven =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'SEVENTY_ONE_TO_EIGHTY')?.color}`
            : 'background-color:#c0c0c4'
        const backgroundColorSix =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'SIXTY_ONE_TO_SEVENTY')?.color}`
            : 'background-color:#D33087'
        const backgroundColorFive =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'FIFTY_ONE_TO_SIXTY')?.color}`
            : 'background-color:#dc599f'
        const backgroundColorFour =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'FORTY_ONE_TO_FIFTY')?.color}`
            : 'background-color:#e998c3'
        const backgroundColorThree =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'THIRTY_ONE_TO_FORTY')?.color}`
            : 'background-color:#f2c1db'
        const backgroundColorTwo =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'TWENTY_ONE_TO_THIRTY')?.color}`
            : 'background-color:#bbeaf3'
        const backgroundColorOne =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'ELEVEN_TO_TWENTY')?.color}`
            : 'background-color:#8edceb'
        const backgroundColor =
          colors?.length > 0
            ? `background-color: ${colors.find((elt) => elt.type == 'ZERO_TO_TEN')?.color}`
            : 'background-color:#1CB9D6'
        div.innerHTML =
          `<div class="map-legend" style="${backgroundColorNine}">91-100%</div>` +
          `<div class="map-legend" style="${backgroundColorEight}">81-90%</div>` +
          `<div class="map-legend" style="${backgroundColorSeven}">71-80%</div>` +
          `<div class="map-legend" style="${backgroundColorSix}">61-70%</div>` +
          `<div class="map-legend" style="${backgroundColorFive}">51-60%</div>` +
          `<div class="map-legend" style="${backgroundColorFour}">41-50%</div>` +
          `<div class="map-legend" style="${backgroundColorThree}">31-40%</div>` +
          `<div class="map-legend" style="${backgroundColorTwo}">21-30%</div>` +
          `<div class="map-legend" style="${backgroundColorOne}">11-20%</div>` +
          `<div class="map-legend" style="${backgroundColor}">0-10%</div>`
        return div
      }
      legend.addTo(map)
    }
  }, [map])
  return null
}
function CustomMarker(props) {
  const { markerPositions, progressPercent, imageSize, colors } = props
  const heatmap = useMap()
  if (progressPercent == -1)
    heatmap.eachLayer(function (layer) {
      heatmap.removeLayer(layer)
    })
  if (markerPositions.length > 0) {
    markerPositions.map((marker) => {
      new L.CircleMarker(heatmap.unproject(marker.position, heatmap.zoomRatio), {
        radius: imageSize.height > 3000 ? 7 : 10,
        fillOpacity: 0.85,
        color: getColor(Math.round(marker.percentage), colors),
        className: 'circle',
      })
        .addTo(heatmap)
        .bindPopup(
          `${marker.name} : ${Math.round(marker.percentage)}% (${toHoursAndMinutes(marker.minutes).hours}h${
            toHoursAndMinutes(marker.minutes).minutes
          }min)`,
        )
    })
  }

  return null
}

const HeatmapController = (props: any) => {
  const { loading, data, title, id, selectedFilters = [], zoom, colors } = props

  const { state: filters } = useContext(GraphFiltersContext)
  const [progressPercent, setProgressPercent] = useState(-1)
  const [map, setMap] = useState(null)
  const [dayView, setDayView] = useState(false)
  const [markerPositions, setMarkerPositions] = useState([])
  const [mapUrl, setMapUrl] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [imageSize, setImageSize] = useState({ width: 1000, height: 1000 })
  const [datesOptions, setDateOptions] = useState()
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [overlay, setOverlay] = useState(true)
  const [clickedIndex, setClickedIndex] = useState(0)
  const [onclicked, setOnclicked] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [mapZoom, setMapZoom] = useState(1)

  const { t } = useTranslation()
  function Overlay(mapUrl: string) {
    const heatmap = useMap()

    useEffect(() => {
      if (imageSize && Object.keys(imageSize).length !== 0 && overlay && progressPercent == -1) {
        const zoomRatio = computeZoomRatio(imageSize.width)
        heatmap.zoomRatio = zoomRatio
        const mapBounds = computeMapBounds(heatmap, imageSize.width, imageSize.height, zoomRatio)
        heatmap.setMaxBounds(mapBounds)
        heatmap.fitBounds(mapBounds, {})

        if (mapBounds) L.imageOverlay(mapUrl.mapUrl, mapBounds).addTo(heatmap)
      }
    }, [])
    return null
  }
  useEffect(() => {
    if (zoom !== null && map) {
      setMapZoom(1)
    }
  }, [zoom, mapZoom])

  const getPercentage = (elt: any, step) => {
    if (progressPercent == -1 && datesOptions && selectedPeriod === '') {
      setSelectedPeriod(datesOptions[0])
      filters.SELECTED_PERIOD.value = datesOptions[0].value
    }
    if (elt.periods.filter((prd) => prd.period === selectedPeriod.value).length > 0) {
      if (progressPercent == -1 && !!datesOptions) {
        return elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].average
      } else if (progressPercent >= 0 && progressPercent < step) {
        return elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour[0].valueInPercentage
      } else if (progressPercent >= step && progressPercent < 2 * step) {
        return elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour[1].valueInPercentage
      } else {
        for (
          let i = 2;
          i <= elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour.length;
          i++
        ) {
          if (i < elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour.length) {
            switch (true) {
              case progressPercent > step * (i - 1) && progressPercent <= step * i: {
                return elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour[i]
                  .valueInPercentage
              }
            }
          }
          if (i == elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour.length) {
            return elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0].valuesByHour[i - 1]
              .valueInPercentage
          }
        }
      }
    }
  }
  useEffect(() => {
    if (data && data.result.filter((elt) => elt.coordinates).length > 0) {
      const periods = data.result.filter((elt) => elt.coordinates).map((elt) => elt.periods)
      const arrayOfPeriods = periods.flat()
      const filteredPeriods = arrayOfPeriods.reduce((acc, current) => {
        const x = acc.find((item) => item.period === current.period)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])

      setDateOptions(
        filteredPeriods
          .sort(function (a, b) {
            return new Date(a.period) - new Date(b.period)
          })
          .map((item) => {
            return { value: item.period, label: formatDate(item.period) }
          }),
      )
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    if (loading) {
      return
    }
    const positions = []
    if (data.result.filter((elt) => elt.coordinates).length > 0 && datesOptions !== undefined) {
      data.result
        .filter((elt) => elt.coordinates)
        .map((elt) => {
          positions.push({
            position: [elt.coordinates.x, elt.coordinates.y],
            percentage: getPercentage(elt, Math.round(100 / (steps.length - 1))),
            name: elt.roomName,
            minutes: elt.periods.filter((prd) => prd.period === selectedPeriod.value)[0]?.averageDurationMinutes,
          })
        })
    }
    setMarkerPositions(positions)

    return () => {
      abortController.abort()
    }
  }, [data, selectedPeriod, progressPercent, datesOptions])

  const { clientCode = '' } = useCurrentUser()

  function arrayBufferToBase64(buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  const { data: mapData, refetch } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.MAP(clientCode),
    headers: {
      responseType: 'arraybuffer',
    },
    queryFn: () => {
      if (filters.COMPARTMENT.value !== undefined || selectedFilters.compartmentPath !== undefined) {
        return graphService.getMapForPath(
          clientCode,
          filters.COMPARTMENT.value == undefined
            ? selectedFilters.compartmentPath.replaceAll('/', '$')
            : filters.COMPARTMENT.value.replaceAll('/', '$'),
        )
      } else {
        return graphService.getMapForPath(
          clientCode,
          filters.FLOOR.value == undefined
            ? selectedFilters.floorPath.replaceAll('/', '$')
            : filters.FLOOR.value.replaceAll('/', '$'),
        )
      }
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!filters.FLOOR.value,
  })
  useEffect(() => refetch(), [filters.FLOOR])

  useEffect(() => {
    if (mapData) {
      const blob = new Blob([mapData], { type: 'image/jpeg' })
      const img = new Image()
      img.src = URL.createObjectURL(blob)
      img.onload = function () {
        setImageSize({ width: img.width, height: img.height })
      }

      const base64ImageString = arrayBufferToBase64(mapData)

      setMapUrl('data:image/png;base64,' + base64ImageString)
    }
  }, [mapData])
  useEffect(() => {
    if (dayView && progressPercent > -1 && progressPercent <= 101) {
      const timer = setInterval(() => {
        if (!isPaused) setProgressPercent((prevCount) => Math.trunc(prevCount + 1))
      }, 500)
      return () => {
        clearInterval(timer)
      }
    }
  }, [progressPercent, isPaused])

  const dayProgress = () => {
    document.getElementsByClassName('RSPBprogression')[0].style.background = 'rgb(87, 87, 98)'

    setOnclicked(false)
    setEnabled(false)
    setDayView(true)
    if (!isPaused) setProgressPercent(1)
    setOverlay(false)
    setIsPaused(false)
  }
  const pauseProgress = () => {
    setIsPaused(true)
    setDayView(false)
    setOverlay(true)
  }
  const steps =
    data && data.result.length > 0 && data.result[0].periods.length > 0
      ? data.result[0].periods[0].valuesByHour.map((elt) => formatHour(elt.hour))
      : ['8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h']

  const computeZoomRatio = function (imageWidth) {
    const offset = 1
    if (imageWidth) {
      if (imageWidth < 1000) {
        return 1
      }
      if (imageWidth > 4000) {
        return 4 + offset
      }
      return Math.ceil(imageWidth / 1000) + offset
    } else {
      return 1
    }
  }

  const computeMapBounds = function (map, imageWidth, imageHeight, zoomRatio) {
    if (imageWidth && imageHeight) {
      const southWest = map.unproject([0, imageHeight], zoomRatio)
      const northEast = map.unproject([imageWidth, 0], zoomRatio)
      return new L.LatLngBounds(southWest, northEast)
    } else {
      return L.latLngBounds([0, 0], [100, 100])
    }
  }
  const setHour = (step: any, index: number) => {
    setIsPaused(false)
    setClickedIndex(index)
    setOnclicked(true)
    document.getElementsByClassName('RSPBprogression')[0].style.background = 'rgba(211, 211, 211, 0.6)'
    if (index <= 4) {
      setProgressPercent(Math.round(index * (100 / (steps.length - 1))))
    } else {
      setProgressPercent(Math.round(index * (100 / (steps.length - 1)) - 0.9))
    }
    setEnabled(false)
  }
  return (
    <>
      <div className="flex items-center justify-center">
        {datesOptions && (
          <Select
            className="basic-single my-5 w-1/4"
            classNamePrefix="select"
            defaultValue={datesOptions[0]}
            isDisabled={dayView}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name="date"
            options={datesOptions}
            onChange={(e) => {
              filters.SELECTED_PERIOD.value = e.value
              setSelectedPeriod(e)
              setProgressPercent(-1)
              setEnabled(true)
              setOnclicked(false)
              setIsPaused(false)
            }}
          />
        )}
      </div>
      {imageSize && Object.keys(imageSize).length !== 0 && (
        <MapContainer
          id="my-map"
          className="map"
          zoomControl={false}
          crs={L.CRS.Simple}
          center={[0, 0]}
          zoom={mapZoom}
          minZoom={-1}
          maxZoom={4}
          zoomSnap={0}
          zoomDelta={0.1}
          boundsOptions={{ padding: [50, 50] }}
          attributionControl={false}
          filtersUpdated={true}
          whenReady={setMap}
        >
          {markerPositions && <Overlay mapUrl={mapUrl}></Overlay>}
          <ZoomControl></ZoomControl>
          {markerPositions &&
            (progressPercent == -1 ||
              progressPercent == 0 ||
              progressPercent == Math.round(100 / (steps.length - 1)) ||
              progressPercent == Math.round(2 * (100 / (steps.length - 1))) ||
              progressPercent == Math.round(3 * (100 / (steps.length - 1))) ||
              progressPercent == Math.round(4 * (100 / (steps.length - 1))) ||
              progressPercent == Math.round(5 * (100 / (steps.length - 1)) - 0.9) ||
              progressPercent == Math.round(6 * (100 / (steps.length - 1)) - 0.9) ||
              progressPercent == Math.round(7 * (100 / (steps.length - 1)) - 0.9) ||
              progressPercent == Math.round(8 * (100 / (steps.length - 1)) - 0.9) ||
              (progressPercent >= Math.round(9 * (100 / (steps.length - 1)) - 0.9) && progressPercent <= 100)) && (
              <CustomMarker
                markerPositions={markerPositions}
                progressPercent={progressPercent}
                imageSize={imageSize}
                colors={colors}
              ></CustomMarker>
            )}
          <Legend colors={colors} />
        </MapContainer>
      )}
      {filters.QUARTER.value !== 'BY_YEAR' ? (
        <div className="my-3 flex items-center">
          <div className="flex mr-3 pr-3 flex items-center" style={{ width: '22%' }}>
            <span className="pr-1">{t('graph.heatmap.dayView')}</span>
            {!dayView ? (
              <IconSvg name="PLAY" onClick={dayProgress} />
            ) : (
              <IconSvg name="PAUSE" onClick={pauseProgress} />
            )}
          </div>
          <ProgressBar
            width={'80%'}
            percent={Math.trunc(progressPercent)}
            filledBackground={COLORS.darkGrey80}
            height="2px"
          >
            {steps.map((step, index) => (
              <Step key={index}>
                {({ accomplished }) => (
                  <div
                    onClick={() => setHour(step, index)}
                    className={`${
                      index == '00' && progressPercent == -1
                        ? 'stepProgress'
                        : onclicked && index == clickedIndex + 1
                        ? 'stepComplete'
                        : onclicked && index !== clickedIndex
                        ? 'stepProgress'
                        : onclicked && accomplished
                        ? 'stepComplete stepCompleteClicked'
                        : accomplished
                        ? 'stepComplete'
                        : 'stepProgress'
                    }`}
                  >
                    <p>{step}</p>
                  </div>
                )}
              </Step>
            ))}
            <Step>
              {({ accomplished }) => (
                <div
                  onClick={() => null}
                  className={`${
                    clickedIndex == steps.length - 1 && onclicked
                      ? 'stepComplete'
                      : progressPercent == 100
                      ? 'stepComplete'
                      : accomplished
                      ? 'stepComplete'
                      : 'stepProgress'
                  }`}
                >
                  <p>
                    {parseInt(steps[steps.length - 1].substring(0, steps[steps.length - 1].indexOf('h'))) + 1 + 'h'}
                  </p>
                  {/* {index} */}
                </div>
              )}
            </Step>
          </ProgressBar>
          <div className="flex pl-3 ml-3 items-center" style={{ width: '22%' }}>
            <Switch
              width={36}
              height={20}
              handleDiameter={16}
              onChange={
                enabled == false
                  ? () => {
                      setEnabled(true)
                      setOnclicked(false)
                      setProgressPercent(-1)
                      setIsPaused(false)
                      setOverlay(true)
                      setDayView(false)
                    }
                  : () => {
                      setEnabled(false)
                    }
              }
              checked={enabled}
              onColor="#d33087"
              offColor="#c0c0c4"
              activeBoxShadow="transparent"
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <span className="pl-1">{t('graph.heatmap.averageValue')}</span>
          </div>
        </div>
      ) : (
        <div style={{ height: '45px' }}></div>
      )}
    </>
  )
}
export default HeatmapController

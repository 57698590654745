import { FC, useState, useEffect } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { graphService } from 'services/graphService'
import Select, { components, GroupBase, StylesConfig } from 'react-select'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import OccupancyByTypeOfSpace from 'components/molecules/landingPage/occupancyByTypeOfSpace'
import CollaborativeAndIndividualUse from 'components/molecules/landingPage/collaborativeAndIndividualUse'
import WorkstationUtilization from 'components/molecules/landingPage/workstationUtilization'
import EvolutionOfOccupancy from 'components/molecules/landingPage/evolutionOfOccupancy'
import CollaborativeSpacesUtilization from 'components/molecules/landingPage/collaborativeSpacesUtilization'
import CharacteristicsOfMeetings from 'components/molecules/landingPage/characteristicsOfMeetings'
import { useCookies } from 'react-cookie'
import ReactTooltip from 'react-tooltip'

import IconSvg from 'components/atoms/icons/IconSvg'
import { FILTER_ARROW_ICONS } from 'styles/Filters'
import EnvironnementalData from 'components/molecules/landingPage/environnementalData'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import Calendar from 'components/molecules/landingPage/calendar'
import Meteo from 'components/molecules/landingPage/meteo'
import { COLORS } from 'assets/colors/colors'
import { getQuarterLabel } from 'utils/filtersUtils'

const { closeIcon } = FILTER_ARROW_ICONS

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconSvg name={closeIcon} className="ml-2" />{' '}
      </components.DropdownIndicator>
    )
  )
}

const HomeView: FC = () => {
  const customStyles: StylesConfig<
    {
      label: string
      value: string
    },
    false,
    GroupBase<{
      label: string
      value: string
    }>
  > = {
    option: (provided) => ({
      ...provided,
      padding: 10,
    }),
    control: () => ({
      minWidth: 166,
      marginRight: 18,
      border: '1px solid #575762',
      borderRadius: '8px',
      display: 'flex',
    }),
    indicatorSeparator: () => ({ display: 'none' }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }
  const { t } = useTranslation()
  const lang = navigator.language
  const { clientCode = '' } = useCurrentUser()
  const { isLoading: isStructureLoading, structure } = useFetchStructure()
  const [cookies, setCookie] = useCookies(['config'])
  const savedConfig = cookies['config']

  const [buildingOptions, setBuildingOptions] = useState<{ label: string; value: string }[]>()
  const [hasRestaurant, setHasRestaurant] = useState<boolean>(false)
  const [hasWorkstation, setHasWorkstation] = useState<boolean>(false)
  const [hasMeetingRoom, setHasMeetingRoom] = useState<boolean>(false)
  const [maxSimultaneousPersons, setMaxSimultaneousPersons] = useState<string>('')
  const [busiestTimeSlot, setBusiestTimeSlot] = useState<string>('')
  const [capacity, setCapacity] = useState<string>('')
  const [restaurantOptions, setRestaurantOptions] = useState<{ label: string; value: string }[]>([])
  const [domainOptions, setDomainOptions] = useState<{ label: string; value: string }[]>()
  const [selectedDomain, setSelectedDomain] = useState<{ label: string; value: string } | null>(
    savedConfig ? savedConfig.domain : domainOptions ? domainOptions[0] : '',
  )
  const [selectedBuilding, setSelectedBuilding] = useState<{ label: string; value: string } | null>(
    savedConfig ? savedConfig.building : buildingOptions ? buildingOptions[0] : '',
  )
  const [selectedQuarter, setSelectedQuarter] = useState<{ label: string; value: string } | null>(
    savedConfig ? savedConfig.quarter : { value: 'LAST_MONTH', label: t('graph.filters.quarter.lastMonth') },
  )
  const [selectedRestaurant, setSelectedRestaurant] = useState<{ label: string; value: string } | null>()

  useEffect(() => {
    setDomainOptions([])
    setBuildingOptions([])
    setRestaurantOptions([])
    setSelectedDomain(null)
    setSelectedBuilding(null)
    setSelectedRestaurant(null)
  }, [clientCode])

  useEffect(() => {
    if (!isStructureLoading && structure && !!structure.domains) {
      setDomainOptions(structure.domains.map((domain) => ({ label: domain.name, value: domain.path })))
    }
  }, [structure])

  useEffect(() => {
    if (structure && !!structure.domains && selectedDomain) {
      setHasWorkstation(
        !!structure.domains.find((domain) => domain.path === selectedDomain.value)?.features.workstation,
      )
      setHasRestaurant(!!structure.domains.find((domain) => domain.path === selectedDomain.value)?.features.restaurant)
      setHasMeetingRoom(
        !!structure.domains.find((domain) => domain.path === selectedDomain.value)?.features.meetingRoom,
      )
      const buildings = structure.domains.find((domain) => domain.path === selectedDomain.value)?.buildings || []
      const restaurants = structure.domains.find((domain) => domain.path === selectedDomain.value)?.restaurants || []
      setBuildingOptions(
        buildings.length > 0
          ? buildings.map((building) => ({
              label: t('graph.filters.building.labels', { name: building.name }),
              value: building.code,
            }))
          : [],
      )

      setRestaurantOptions(
        restaurants.length > 0
          ? restaurants.map((rest) => ({
              label: t('graph.filters.building.labels', { name: rest.name }),
              value: rest.id.toString(),
            }))
          : [],
      )
    }
    if (selectedRestaurant !== null && selectedRestaurant !== '' && !!selectedQuarter) {
      frequentation()
    }
  }, [selectedDomain, structure])

  useEffect(() => {
    if (savedConfig && savedConfig.domain == '' && !!domainOptions && !isStructureLoading)
      setSelectedDomain(domainOptions[0])
    if (savedConfig && savedConfig.building == '' && !!buildingOptions) setSelectedBuilding(buildingOptions[0])
    if (restaurantOptions.length > 0) {
      setSelectedRestaurant(restaurantOptions[0])
    } else {
      setSelectedRestaurant(null)
    }
  }, [domainOptions, buildingOptions, restaurantOptions])

  useEffect(
    () =>
      setCookie('config', {
        domain: selectedDomain ? selectedDomain : '',
        building: selectedBuilding ? selectedBuilding : '',
        quarter: selectedQuarter ? selectedQuarter : '',
      }),
    [selectedDomain, selectedBuilding, selectedQuarter],
  )

  const body = {
    domainPath: selectedDomain ? selectedDomain.value : '',
    buildingCode: selectedBuilding ? selectedBuilding.value : null,
    rangeType: selectedQuarter ? selectedQuarter.value : '',
  }

  const bodyFrequentation = {
    restaurantId: selectedRestaurant ? selectedRestaurant.value : '',
    rangeType: selectedQuarter ? selectedQuarter.value : '',
  }

  const { data, refetch, isFetching } = useQuery<any>({
    queryKey: QUERY_KEYS.ANALYSIS.LANDING_PAGE(clientCode),
    queryFn: () => {
      if (clientCode && body.domainPath) {
        return graphService.getLandingPage(clientCode, body)
      }

      return Promise.resolve(null)
    },
    retry: 3,
    retryDelay: 100,
    onError: () => {
      console.error(t('api.unknown'))
    },
    enabled: false,
  })

  const { mutate: frequentation, data: restaurantAttendance } = useMutation({
    mutationKey: QUERY_KEYS.ANALYSIS.RIE_ATTENDANCE(clientCode),
    mutationFn: () => {
      if (clientCode && bodyFrequentation.restaurantId) {
        return graphService.getRestaurantAttendance(clientCode, bodyFrequentation)
      }

      return Promise.resolve(null)
    },

    onError: () => {
      console.error(t('api.unknown'))
    },
  })

  useEffect(() => {
    if (clientCode && !isStructureLoading) {
      if (structure && !!selectedDomain) {
        if (structure.domains.find((domain) => domain.path === selectedDomain.value)?.buildings == undefined) {
          if (!!selectedDomain && !!selectedQuarter) {
            refetch()
          }
        } else if (!!selectedDomain && !!selectedQuarter && !!selectedBuilding) {
          refetch()
        }
      }
    }
  }, [selectedDomain, selectedQuarter, selectedBuilding, isStructureLoading])

  const quarterOptions = [
    { value: 'LAST_MONTH', label: t('graph.filters.quarter.lastMonth') },
    { value: 'LAST_TWELVE_WEEKS', label: t('graph.filters.quarter.lastTwelveWeeks') },
  ]

  useEffect(() => {
    if (structure?.domains?.find((domain) => domain.path === savedConfig?.domain.value) == undefined) {
      setCookie('config', {
        domain: '',
        building: '',
        quarter: '',
      })
    }
  }, [clientCode && structure])

  useEffect(() => {
    if (selectedRestaurant !== null && selectedRestaurant !== '' && !!selectedQuarter && clientCode) {
      refetch()
      frequentation()
    }
  }, [selectedRestaurant, selectedQuarter, refetch])

  useEffect(() => {
    if (restaurantAttendance?.restaurantAttendance) {
      setMaxSimultaneousPersons(restaurantAttendance.restaurantAttendance.maxSimultaneousPersons)
      setBusiestTimeSlot(restaurantAttendance.restaurantAttendance.busiestTimeSlot)
      setCapacity(restaurantAttendance.restaurantAttendance.capacity)
    }
  }, [restaurantAttendance])

  useEffect(() => {
    if (restaurantOptions.length > 0) {
      frequentation()
    } else {
      setBusiestTimeSlot('')
      setMaxSimultaneousPersons('')
      setCapacity('')
    }
  }, [data])

  return (
    <PageLayout>
      <div className="flex m-4" style={{ columnGap: '24px' }}>
        <Select
          components={{
            DropdownIndicator,
          }}
          styles={customStyles}
          name="domain"
          options={domainOptions}
          value={selectedDomain}
          onChange={(e) => {
            setSelectedDomain(e)
            setSelectedBuilding(null)
            setSelectedQuarter(null)
          }}
        />

        {selectedBuilding && (
          <Select
            components={{
              DropdownIndicator,
            }}
            styles={customStyles}
            options={buildingOptions}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e)
              setSelectedQuarter(null)
            }}
          />
        )}

        <Select
          components={{
            DropdownIndicator,
          }}
          styles={customStyles}
          options={quarterOptions}
          value={selectedQuarter}
          name="quarter"
          onChange={(e) => {
            setSelectedQuarter(e)
          }}
        />
        {isFetching && <LoaderComponent />}
      </div>
      <div className="first-row flex space-between mb-4" style={{ height: '55%' }}>
        <div className="w-1/5 mx-4 h-full">
          <OccupancyByTypeOfSpace
            quarter={selectedQuarter}
            workstation={data?.occupancyByTypeOfSpace?.workstation ? data.occupancyByTypeOfSpace.workstation : ''}
            allCollaborativeSpaces={
              data?.occupancyByTypeOfSpace?.allCollaborativeSpaces
                ? data.occupancyByTypeOfSpace.allCollaborativeSpaces
                : ''
            }
          />
        </div>
        <div className="w-3/5 mr-4">
          <CollaborativeAndIndividualUse data={data?.collaborativeAndIndividualUse ?? []} />
        </div>
        <div className="w-1/5 mr-4">
          {structure?.domains && (
            <WorkstationUtilization
              capacity={data?.workstationUtilization ? data.workstationUtilization.capacity : ''}
              quarter={selectedQuarter}
              hasWorkstation={hasWorkstation}
              averageDuration={data?.workstationUtilization ? data.workstationUtilization.averageDurationInMinutes : ''}
              occupied={
                data?.workstationUtilization ? data.workstationUtilization.averageOccupiedAtLeast30MinPerDay : ''
              }
            />
          )}
        </div>
      </div>

      <div className="flex space-between mb-4" style={{ height: '50vh' }}>
        <div className="w-3/5 mx-4">
          <EvolutionOfOccupancy data={data?.evolutionOfOccupancy ?? []} />
        </div>
        <div className="mr-4" style={{ width: '28%' }}>
          <CollaborativeSpacesUtilization data={data?.allCollaborativeSpacesUtilization ?? []} />
        </div>
        <div className="w-1/5 mr-4">
          {structure?.domains && (
            <div
              className={`${
                !restaurantAttendance || busiestTimeSlot == '' ? 'inactiveShadowSection' : ''
              } flex flex-col px-2 relative shadowSection h-full`}
            >
              <div data-tip data-for="restaurantAttendanceKpi" className="info-tooltip">
                <IconSvg name="FILLED_INFO" className="mr-2" color="GREY30" />
              </div>
              <ReactTooltip id="restaurantAttendanceKpi" place="bottom">
                <p>{t('landingPage.restaurantAttendance.kpiSimultaneous')}</p>
                <p>{t('landingPage.restaurantAttendance.kpiBusiest')}</p>
              </ReactTooltip>
              <h2 className="section-title">{t('landingPage.restaurantAttendance.title')}</h2>
              {restaurantOptions && restaurantOptions.length > 0 && (
                <div className="w-1/2 mb-2">
                  <Select
                    options={restaurantOptions}
                    value={selectedRestaurant}
                    styles={customStyles}
                    onChange={(e) => {
                      setSelectedRestaurant(e)
                    }}
                  />
                </div>
              )}
              <small>
                {t('landingPage.restaurantAttendance.capacity')}:{' '}
                {restaurantAttendance?.restaurantAttendance ? restaurantAttendance.restaurantAttendance.capacity : '_'}{' '}
                p.
              </small>
              <div
                className="flex flex-col items-center my-2"
                data-for="maxSimultaneousPersons"
                data-tip="maxSimultaneousPersons"
              >
                <small>{t('landingPage.restaurantAttendance.simultaneousPersons')}</small>
                <div className="grayBloc flex flex-col justify-center" style={{ minWidth: '175px', height: '70px' }}>
                  <p className="pb-2">
                    {restaurantAttendance?.restaurantAttendance ? maxSimultaneousPersons.value + '/' + capacity : ''}
                  </p>
                  <small style={{ fontSize: '12px', color: COLORS.darkGrey30, fontWeight: 'lighter' }}>
                    {restaurantAttendance?.restaurantAttendance
                      ? lang === 'fr-FR' || lang === 'fr'
                        ? new Date(maxSimultaneousPersons.date).toLocaleString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC',
                          })
                        : new Date(maxSimultaneousPersons.date).toLocaleString('en-us', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC',
                          })
                      : ''}
                  </small>
                </div>
              </div>
              {selectedQuarter && (
                <ReactTooltip id="maxSimultaneousPersons" className="breakLine" type="info">
                  {!hasRestaurant
                    ? t('landingPage.news')
                    : restaurantAttendance?.restaurantAttendance?.maxSimultaneousPersons !== ''
                    ? t('landingPage.restaurantAttendance.tooltipSimultaneously', {
                        quarter: getQuarterLabel(selectedQuarter),
                        value: maxSimultaneousPersons.value,
                      })
                    : t('landingPage.unavailable')}
                </ReactTooltip>
              )}
              <div className="flex flex-col items-center my-2" data-for="busiestTimeSlot" data-tip="busiestTimeSlot">
                <small>{t('landingPage.restaurantAttendance.busiestTimeSlot')}</small>
                <div className="grayBloc flex flex-col justify-center" style={{ minWidth: '175px', height: '70px' }}>
                  <p className="pb-2">
                    {restaurantAttendance?.restaurantAttendance?.busiestTimeSlot ? busiestTimeSlot.value : '_'}
                  </p>
                  <small style={{ fontSize: '12px', color: COLORS.darkGrey30, fontWeight: 'lighter' }}>
                    {restaurantAttendance?.restaurantAttendance?.busiestTimeSlot
                      ? lang === 'fr-FR' || lang === 'fr'
                        ? new Date(busiestTimeSlot.date).toLocaleString('fr-FR', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC',
                          })
                        : new Date(busiestTimeSlot.date).toLocaleString('en-us', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC',
                          })
                      : '_'}
                  </small>
                </div>
              </div>
              {selectedQuarter && (
                <ReactTooltip id="busiestTimeSlot" className="breakLine" type="info">
                  {!hasRestaurant
                    ? t('landingPage.news')
                    : restaurantAttendance?.restaurantAttendance?.busiestTimeSlot
                    ? t('landingPage.restaurantAttendance.tooltipBusiest', {
                        quarter: getQuarterLabel(selectedQuarter),
                        value: busiestTimeSlot.value,
                      })
                    : t('landingPage.unavailable')}
                </ReactTooltip>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex ml-2 mb-12 relative" style={{ height: '45vh' }}>
        <div className="mr-4 shadowSection px-8 relative" data-for="envData" data-tip style={{ width: '30%' }}>
          <div data-tip data-for="envDataKpi" className="info-tooltip">
            <IconSvg name="FILLED_INFO" className="mr-2" color="GREY30" />
          </div>
          <ReactTooltip id="envDataKpi" place="bottom">
            {t('landingPage.envData.kpi')}
          </ReactTooltip>

          <EnvironnementalData />
        </div>
        <ReactTooltip id="envData" className="breakLine" type="info">
          {t('landingPage.news')}
        </ReactTooltip>
        <div className="w-1/5 mr-4">
          {structure?.domains && (
            <CharacteristicsOfMeetings
              hasMeetingRoom={hasMeetingRoom}
              noShowRate={data?.characteristicsOfMeetings ? data.characteristicsOfMeetings.noShowRate : ''}
              averageDuration={data?.characteristicsOfMeetings ? data.characteristicsOfMeetings.averageDuration : ''}
            />
          )}
        </div>
        <Calendar />
        <Meteo />
      </div>
      <div className="flex ml-2 mb-4" style={{ height: '5px' }}></div>
    </PageLayout>
  )
}

export default HomeView

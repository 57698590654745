import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { optionAllValue } from 'utils/constants/graphs/global'
import {
  graphsStructure,
  compartment,
  detailedOccupancyValue,
  spaceAttendanceValue,
  heatmapValue,
  monthlyValue,
} from 'utils/constants/graphs/graphsStructure'
import { DomainsStructure } from 'types/DomainsStructure'
import { getFilterType } from 'utils/filtersUtils'

const { placeholder, all } = graphsStructure.compartmentPath

const handleStateChange = (structure: DomainsStructure, state: FilterState, value?: string): FilterState => {
  if (state.COMPARTMENT.value === value) return state
  const isCompartment = state.COMPARTMENT.value === compartment
  return {
    ...state,
    KPI: { ...state.KPI, active: !!value },
    COMPARTMENT: {
      ...initialFilterState.COMPARTMENT,
      required: isCompartment,
      active: true,
      value,
    },
  }
}

const CompartmentFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const isHeatmap = state.GRAPH.value === heatmapValue
  const isMonthly = state.GRAPH.value === monthlyValue

  const compartmentValue = state.COMPARTMENT.value
  if ((isSpaceAttendance || isHeatmap) && compartmentValue !== undefined) state.KPI.active = true
  if (compartmentValue && isMonthly) state.ROOM_TYPE.active = true

  const optionAll = {
    label: t(all),
    value: optionAllValue,
  }
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const compartments =
    state.BUILDING.value !== undefined
      ? structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.compartments?.filter(
            (compartment) =>
              compartment.buildingCode === state.BUILDING.value && compartment.floorPath === state.FLOOR.value,
          ) || []
      : structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.compartments?.filter((compartment) => compartment.floorPath === state.FLOOR.value) || []

  const spaceAttendanceCompartments = compartments.filter((compartment) => compartment.counting)
  const heatmapCompartments = compartments.filter((compartment) => compartment.map)
  const options =
    state.BUILDING.value === optionAllValue && !isSpaceAttendance
      ? [optionAll]
      : isSpaceAttendance
      ? [...spaceAttendanceCompartments.map((compartment) => ({ label: compartment.name, value: compartment.path }))]
      : isHeatmap
      ? [...heatmapCompartments.map((compartment) => ({ label: compartment.name, value: compartment.path }))]
      : [optionAll, ...compartments.map((compartment) => ({ label: compartment.name, value: compartment.path }))]
  const compartmentData = options.find((option) => option.value === state.COMPARTMENT.value)
  const type = getFilterType(state.GRAPH.value, state.COMPARTMENT.active, compartmentData)
  const onChange = (value?: string) => onFilterChange(handleStateChange(structure, state, value))
  return (
    <Filter
      type={type}
      placeholder={t(placeholder)}
      label={compartmentData?.label}
      selected={compartmentValue}
      disabled={
        (!state.COMPARTMENT.active && !state.COMPARTMENT.value) || options.length <= 1 || state.FLOOR.value == '[ALL]'
      }
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default CompartmentFilter

import { detailedOccupancyTableValue } from 'utils/constants/graphs/graphsStructure'
import { createContext } from 'react'
import { DomainsStructure } from 'types/DomainsStructure'

type FilterType =
  | 'GRAPH'
  | 'QUARTER'
  | 'SITE'
  | 'BUILDING'
  | 'FLOOR'
  | 'BUSINESS_UNITS'
  | 'ROOM_TYPE'
  | 'BOOKABLE'
  | 'OPEN_CLOSED'
  | 'OPEN_SPACE'
  | 'ROOM_SIZE_GROUPED'
  | 'ROOM_SIZE_DETAILED'
  | 'CAPACITY_SIZE'
  | 'ROOM_SIZE_TYPE'
  | 'DETAILED_OCCUPANCY'
  | 'KPI'
  | 'DEDICATED_SHARED'
  | 'COMPARTMENT'
  | 'RESTAURANT'
  | 'SPACE_TYPE'
  | 'MONITORING_SCOPE'
  | 'MONITORING_ROOM_TYPE'
  | 'ENTRY'
  | 'MONITORING_ENTITY'
  | 'LEVEL'
  | 'SELECTED_PERIOD'
  | 'CUSTOM_ATTRIBUT_COLLAB'
  | 'CUSTOM_ATTRIBUT_WKS'
  | 'CUSTOM_ATTRIBUT_COLLAB_VALUE'
  | 'CUSTOM_ATTRIBUT_WKS_VALUE'

export type FilterState = Record<
  FilterType,
  {
    value?: any
    required: boolean
    // The 'active' attribute is used to tell if the filter is in "OUTLINED_BLACK" or not
    // (i.e : it is the filter which must be picked by the user at the moment)
    active: boolean
    enable?: boolean
    id?: any
  }
>

type ContextType = {
  structure: DomainsStructure
  state: FilterState
  onFilterChange: (newState: FilterState) => void
}

export const initialFilterState: FilterState = {
  GRAPH: {
    value: undefined,
    required: true,
    active: true,
    enable: true,
  },
  SITE: {
    value: undefined,
    required: true,
    active: false,
  },
  BUILDING: {
    value: undefined,
    required: true,
    active: false,
  },
  FLOOR: {
    value: undefined,
    required: !detailedOccupancyTableValue,
    active: false,
  },
  COMPARTMENT: {
    value: undefined,
    required: false,
    active: false,
  },
  ROOM_TYPE: {
    value: undefined,
    required: !detailedOccupancyTableValue,
    active: false,
  },
  BUSINESS_UNITS: {
    value: undefined,
    required: false,
    active: false,
  },
  QUARTER: {
    value: undefined,
    required: true,
    active: false,
  },
  // Filter used for GRAPH 3
  KPI: {
    value: undefined,
    required: false,
    active: false,
  },
  DETAILED_OCCUPANCY: {
    value: undefined,
    required: false,
    active: false,
  },

  BOOKABLE: {
    value: undefined,
    required: false,
    active: true,
  },
  OPEN_CLOSED: {
    value: undefined,
    required: false,
    active: true,
  },
  ROOM_SIZE_GROUPED: {
    value: undefined,
    required: false,
    active: true,
  },
  ROOM_SIZE_DETAILED: {
    value: undefined,
    required: false,
    active: true,
  },
  CAPACITY_SIZE: {
    value: undefined,
    required: false,
    active: true,
  },
  // Filter used for GRAPH 2
  ROOM_SIZE_TYPE: {
    value: undefined,
    required: false,
    active: true,
  },
  // Filter used for GRAPH 4
  OPEN_SPACE: {
    value: undefined,
    required: false,
    active: true,
  },
  DEDICATED_SHARED: {
    value: undefined,
    required: false,
    active: true,
  },
  // Filter used for graph 5
  RESTAURANT: {
    value: undefined,
    required: false,
    active: true,
    id: undefined,
  },
  SPACE_TYPE: {
    value: undefined,
    required: false,
    active: false,
  },
  MONITORING_SCOPE: {
    value: undefined,
    required: false,
    active: false,
  },
  MONITORING_ROOM_TYPE: {
    value: undefined,
    required: false,
    active: false,
  },
  ENTRY: {
    value: undefined,
    required: false,
    active: false,
  },
  MONITORING_ENTITY: {
    value: undefined,
    required: false,
    active: false,
  },
  LEVEL: {
    value: undefined,
    required: false,
    active: false,
  },
  SELECTED_PERIOD: {
    value: undefined,
    required: false,
    active: false,
  },
  CUSTOM_ATTRIBUT_COLLAB: {
    value: undefined,
    required: false,
    active: false,
    placeholder: undefined,
  },
  CUSTOM_ATTRIBUT_WKS: {
    value: undefined,
    required: false,
    active: false,
    placeholder: undefined,
  },
  CUSTOM_ATTRIBUT_COLLAB_VALUE: {
    value: undefined,
    required: false,
    active: false,
  },
  CUSTOM_ATTRIBUT_WKS_VALUE: {
    value: undefined,
    required: false,
    active: false,
  },
}

const structureEmptyState: DomainsStructure = {
  domains: [],
}

export const GraphFiltersContext = createContext<ContextType>({
  structure: structureEmptyState,
  state: initialFilterState,
  onFilterChange: () => undefined,
})

import { useState, useEffect, useContext, useRef, FC } from 'react'
import Dropdown from './Dropdown'
import { FILTER_ARROW_ICONS, FILTER_STYLE } from 'styles/Filters'
import useFocusedRef from 'utils/hooks/useFocusedRef'
import { FilterContext } from '../filters/FilterContext'
import IconSvg from '../../atoms/icons/IconSvg'
import { ICON_SVG_MAP } from '../../atoms/icons/IconSvgMap'
import { useTranslation } from 'react-i18next'
import { GraphFiltersContext } from 'components/molecules/filters/required/GraphFiltersContext'
import GraphPreview from 'components/molecules/graphPreview/graphPreview'
import ReactTooltip from 'react-tooltip'

type Props = {
  className?: string
  type?: keyof typeof FILTER_STYLE
  placeholder?: string
  selected?: string
  disabled?: boolean
  onSelect?: (value?: string) => void
  items: any
  depthLevel: any
  key: any
  onOptionClick?: any
  selectedOption?: any
}

const MenuItems: FC<Props> = (props) => {
  const {
    className = '',
    type = 'PRIMARY',
    placeholder,
    disabled = false,
    items,
    depthLevel,
    onSelect,
    onOptionClick,
    selected,
    selectedOption,
  } = props

  const [dropdown, setDropdown] = useState(false)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const ref = useRef()
  const { state } = useContext(GraphFiltersContext)
  const { refButton, refContainer } = useFocusedRef(false, true)
  const filterType = open ? 'BLACK' : type
  const { openIcon, closeIcon, ...iconColors } = FILTER_ARROW_ICONS

  useEffect(() => {
    const handler = (event: any) => {
      //@ts-ignore
      if (dropdown && ref.current && !ref?.current?.contains(event.target)) {
        setDropdown(false)
      }
    }

    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [dropdown])
  const handleStateChange = () => {
    if (items.value !== undefined) state.ENTRY.value = items.value
  }
  return (
    <>
      <ReactTooltip id={items.value} className="custom-tooltip" place="right" arrowColor="transparent">
        <GraphPreview graphType={items.value} entry={state.ENTRY.value} />
      </ReactTooltip>

      <li
        data-tip
        data-for={depthLevel > 1 && !items.enable ? items.value : ''}
        key={Math.random()}
        className={'menu-items text-basic-500 '}
        //@ts-ignore
        ref={ref}
        onClick={depthLevel === 1 ? handleStateChange : onOptionClick}
        data-value={items.value}
      >
        {!items.url && items.submenu ? (
          <>
            {depthLevel === 0 ? (
              <div className="relative mx-1 max-h-filter" style={{ height: '68px' }}>
                <div
                  className={`inline-flex min-h-13 items-center mb-2 px-2 pb-1 rounded-lg border-2 text-b2 ${
                    FILTER_STYLE[filterType]
                  } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none`}
                >
                  <div
                    className={`${disabled ? 'w-32' : 'w-33'} overflow-hidden overflow-ellipsis whitespace-nowrap`}
                    title={placeholder}
                  >
                    <button
                      type="button"
                      ref={refButton}
                      aria-haspopup="menu"
                      aria-expanded={dropdown ? 'true' : 'false'}
                      onClick={() => setDropdown(!dropdown)}
                    >
                      <a href={items.url} className="font-bold">
                        {selectedOption}
                      </a>
                      {depthLevel > 0 ? (
                        <span>&raquo;</span>
                      ) : (
                        !disabled && (
                          <IconSvg name={open ? openIcon : closeIcon} color={iconColors[type]} className="ml-2" />
                        )
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <button
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? 'true' : 'false'}
                onClick={() => setDropdown((prev) => !prev)}
                className="hover:bg-basic-200"
              >
                <a
                  href={items.url}
                  className="flex justify-left items-center p-2 cursor-pointer border-b-1 border-basic-200 overflow-hidden overflow-ellipsis whitespace-nowrap select-none"
                >
                  <IconSvg className="mr-4" name={items.value as keyof typeof ICON_SVG_MAP} />
                  {items.title}
                </a>
                {depthLevel > 0 ? (
                  <span className="m-1 flex">
                    {items.premium && <IconSvg name="PREMIUM" className="mt-1" />}
                    <IconSvg name="CHEVRON_RIGHT" />
                  </span>
                ) : (
                  <span className="arrow m-2" />
                )}
              </button>
            )}
            <FilterContext.Provider value={{ selected, onSelect }}>
              <div ref={refContainer}>
                <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
              </div>
            </FilterContext.Provider>
          </>
        ) : items.enable === false ? (
          <div className="flex items-center">
            <IconSvg className="mr-4" name={items.value as keyof typeof ICON_SVG_MAP} />
            <a className="inactive-graph cursor-pointer mr-2">{t(items.title)}</a>
            <IconSvg className="absolute right-2" name="PREMIUM" />
          </div>
        ) : (
          <div className="flex items-center ">
            <IconSvg className="mr-4" name={items.value as keyof typeof ICON_SVG_MAP} />
            <a className="cursor-pointer" href={items.url}>
              {t(items.title)}
            </a>
          </div>
        )}
      </li>
    </>
  )
}

export default MenuItems

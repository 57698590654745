import clsx from 'clsx'
import Gauge from 'components/atoms/gauge/Gauge'
import IconSvg, { iconColors } from 'components/atoms/icons/IconSvg'
import { LocalizationInformationDtoRoomClassificationEnum } from 'core/api/models/localizationInformationDto'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableInfo, RestaurantAvailableInfo, FloorInfo } from 'types/RieView'
import { Domain } from 'utils/api/types'
import { bpConvert } from 'utils/breakPointsUtils'
import {
  availableFilter,
  dirtyFilter,
  filterPlaces,
  getCapacityLabel,
  unavailableFilter,
} from 'utils/LocalizationUtils'

type LevelTableProps = {
  space: AvailableInfo | RestaurantAvailableInfo
  roomType: LocalizationInformationDtoRoomClassificationEnum
  floorInfos?: FloorInfo
  filter?: LocalizationInformationDtoRoomClassificationEnum
  domain: Domain
  desksInfos?: SensorStatusDto[] | null
  buildingName?: string
}

type IconType = {
  name: 'NO_ENTRY' | 'CHECK_CIRCLE' | 'MOON' | 'DIRTY'
  color: typeof iconColors
  className?: string
}

const LevelTable = ({ roomType, floorInfos, domain, desksInfos }: LevelTableProps): JSX.Element => {
  const { t } = useTranslation()

  const filteredPois = useMemo(
    () =>
      !!domain && !!floorInfos
        ? filterPlaces(floorInfos.floorId.replace(/-/g, '/').replace(/_/g, ''), roomType, domain)
        : [],
    [domain],
  )

  const renderCapacity = (capacity: string) => {
    const capacities = capacity.split('/')
    if (!capacities[0] || !capacities[1]) {
      return null
    }
    const unAvailablePercentage = 100 - (parseInt(capacities[0]) / parseInt(capacities[1])) * 100
    return <Gauge percentage={unAvailablePercentage} />
  }

  const findIcon = (status: 'dirty' | 'available' | 'unavailable' | 'offPeriod'): IconType => {
    switch (status) {
      case 'unavailable':
        return {
          name: 'NO_ENTRY',
          color: 'RED',
          className: 'text-red',
        }
      case 'available':
        return {
          name: 'CHECK_CIRCLE',
          color: 'GREEN',
          className: 'text-green',
        }
      case 'offPeriod':
        return {
          name: 'MOON',
          color: 'GREY80',
          className: 'text-grey-80',
        }
      case 'dirty':
        return {
          name: 'DIRTY',
          color: 'ORANGE',
          className: 'text-orange',
        }
      default:
        return {
          name: 'NO_ENTRY',
          color: 'RED',
          className: 'text-red',
        }
    }
  }

  const renderTable = () => {
    return (
      <>
        {filteredPois.map((poi, index) => {
          const sensors: SensorStatusDto[] = desksInfos
            ? desksInfos.filter(
                (deskInfos) => deskInfos.path === poi.path || deskInfos.path?.includes(poi.path?.concat('/') ?? ''),
              )
            : []
          const capacity: string | undefined = getCapacityLabel(poi, sensors)
          const filteredDesk = desksInfos ? desksInfos.filter((desk) => desk.path === poi.path) : undefined
          const isAvailable = filteredDesk ? availableFilter(filteredDesk) : false
          const isDirty = filteredDesk ? dirtyFilter(filteredDesk) : false
          const isUnavailable = filteredDesk ? unavailableFilter(filteredDesk) : false
          const finalStatus = isAvailable
            ? 'available'
            : isDirty
            ? 'dirty'
            : isUnavailable
            ? 'unavailable'
            : 'offPeriod'
          const splitCapacity = !!capacity && capacity.includes('/') ? capacity.split('/') : undefined

          return (
            <>
              <div
                className={clsx(
                  'h-full w-full flex items-center justify-center gap-4 p-2',
                  index % 2 !== 0 ? 'bg-basic-200' : 'bg-basic-100',
                )}
              >
                <p className="truncate text-left">{poi.name}</p>
                {roomType !== LocalizationInformationDtoRoomClassificationEnum.Workstation && (
                  <IconSvg {...findIcon(finalStatus)} />
                )}
              </div>
              <div
                className={clsx(
                  'h-full w-full flex items-center justify-center gap-4 p-2',
                  index % 2 !== 0 ? 'bg-basic-200' : 'bg-basic-100',
                )}
              >
                {roomType === LocalizationInformationDtoRoomClassificationEnum.Workstation && !!capacity ? (
                  renderCapacity(capacity)
                ) : (
                  <p className="text-underline">{poi.attributes?.CSM_NAT}</p>
                )}
              </div>
              <div
                className={clsx(
                  'h-full w-full flex items-center justify-center gap-4 p-2',
                  index % 2 !== 0 ? 'bg-basic-200' : 'bg-basic-100',
                )}
              >
                {!capacity ? (
                  '-'
                ) : roomType === LocalizationInformationDtoRoomClassificationEnum.FocusRoom ||
                  roomType === LocalizationInformationDtoRoomClassificationEnum.MeetingRoom ? (
                  t('overviewDetails.capacity', {
                    capacity,
                  })
                ) : splitCapacity ? (
                  <span>
                    <b>{splitCapacity[0]}</b>/{splitCapacity[1]}
                  </span>
                ) : (
                  capacity
                )}
              </div>
            </>
          )
        })}
      </>
    )
  }

  return (
    <div
      className="grid gap-0.5 w-max bg-basic items-center grid-rows-1 border-basic border-2 text-basic"
      style={{
        gridTemplateColumns: `max-content repeat(2, minmax(160px, max-content))`,
        gridTemplateRows: `minmax(${bpConvert(window.innerWidth, 70)}px, auto)`,
        gridAutoRows: 'auto',
      }}
    >
      <div className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 p-2">{t('overview.room')}</div>
      <div className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 p-2">
        {t(
          `overview.${
            roomType === LocalizationInformationDtoRoomClassificationEnum.Workstation ? 'occupancy' : 'type'
          }`,
        )}
      </div>
      <div className="bg-basic-100 h-full w-full flex items-center justify-center gap-4 p-2">
        {t(
          `overview.${
            roomType === LocalizationInformationDtoRoomClassificationEnum.Workstation ? 'availablePlaces' : 'places'
          }`,
        )}
      </div>
      {renderTable()}
    </div>
  )
}

export default LevelTable

import { FC, useContext, useEffect, useState } from 'react'
import { BenchmarkDataWSType } from 'types/GraphDataType'
import LoadingChart from '../LoadingChart'
import { useTranslation } from 'react-i18next'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { FiltersType } from 'types/SavedReportsTypes'
import BenchmarkChart from './BenchmarkChart'
import { formatDate } from 'utils/dateUtils'

type Props = {
  loading: boolean
  data: BenchmarkDataWSType
  title: string
  id?: string | undefined
  savedReportFilters?: FiltersType
  colors: string[]
}

const BenchmarkController: FC<Props> = (props) => {
  const { loading, data, title, id, savedReportFilters, colors } = props
  const { t } = useTranslation()
  const [formattedData, setFormattedData] = useState<BenchmarkDataWSType>()
  const { state: filters } = useContext(GraphFiltersContext)
  useEffect(() => {
    if (loading) {
      return
    }
    const categories: string[] = []
    const benchmarkFirstDecile = data.result.map((item) => item.benchmarkFirstDecile)
    const benchmarkMedian = data.result.map((item) => item.benchmarkMedian)
    const benchmarkNinthDecile = data.result.map((item) => item.benchmarkNinthDecile)
    const buildingPerformance = data.result.map((item) => item.value)

    const formattedSeries = [
      benchmarkFirstDecile.length > 0
        ? {
            name: t('graph.benchmark.benchmarkFirstDecile'),
            data: benchmarkFirstDecile,
          }
        : {
            name: t('graph.benchmark.benchmarkFirstDecile'),
            data: [],
          },
      benchmarkMedian.length > 0
        ? {
            name: t('graph.benchmark.benchmarkMedian'),
            data: benchmarkMedian,
          }
        : {
            name: t('graph.benchmark.benchmarkMedian'),
            data: [],
          },
      benchmarkNinthDecile.length > 0
        ? {
            name: t('graph.benchmark.benchmarkNinthDecile'),
            data: benchmarkNinthDecile,
          }
        : {
            name: t('graph.benchmark.benchmarkNinthDecile'),
            data: [],
          },
      buildingPerformance.length > 0
        ? {
            name: t('graph.benchmark.buildingPerformance'),
            data: buildingPerformance,
          }
        : {
            name: t('graph.benchmark.buildingPerformance'),
            data: [],
          },
    ]
    data.result.map((item) => categories.push(formatDate(item.period)))
    setFormattedData({ series: formattedSeries, categories: categories })
  }, [loading, data])
  if (loading || !formattedData) {
    return <LoadingChart type="line" />
  }

  return (
    <BenchmarkChart
      id={id}
      title={title}
      series={formattedData.series}
      categories={formattedData.categories}
      minifiedCategories={formattedData.minifiedCategories}
      colors={colors}
    />
  )
}

export default BenchmarkController

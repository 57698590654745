import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

const CollaborativeAndIndividualUse = (props: { data: any }): JSX.Element => {
  const { data } = props
  const { t } = useTranslation()
  const formattedData = data.allCollaborativeSpaces || data.workstation
  const fakeData = [
    {
      name: t('landingPage.collaborativeAndIndividualUse.allCollaborativeSpaces'),
      data: [-50, -20, -80, -30, -10],
    },
    {
      name: t('landingPage.collaborativeAndIndividualUse.workstation'),
      data: [20, 30, 60, 10, 44],
    },
  ]
  const series =
    formattedData !== undefined
      ? [
          data['allCollaborativeSpaces'] !== undefined
            ? {
                name: t('landingPage.collaborativeAndIndividualUse.allCollaborativeSpaces'),
                data: [
                  -(formattedData ? formattedData['monday'] : 0),
                  -(formattedData ? formattedData['tuesday'] : 0),
                  -(formattedData ? formattedData['wednesday'] : 0),
                  -(formattedData ? formattedData['thursday'] : 0),
                  -(formattedData ? formattedData['friday'] : 0),
                ],
              }
            : {
                name: t('landingPage.collaborativeAndIndividualUse.allCollaborativeSpaces'),
                data: [0, 0, 0, 0, 0],
              },
          data['workstation'] !== undefined
            ? {
                name: t('landingPage.collaborativeAndIndividualUse.workstation'),
                data: [
                  data && data.workstation ? data.workstation['monday'] : '',
                  data && data.workstation ? data.workstation['tuesday'] : '',
                  data && data.workstation ? data.workstation['wednesday'] : '',
                  data && data.workstation ? data.workstation['thursday'] : '',
                  data && data.workstation ? data.workstation['friday'] : '',
                ],
              }
            : {
                name: t('landingPage.collaborativeAndIndividualUse.workstation'),
                data: [0, 0, 0, 0, 0],
              },
        ]
      : fakeData
  const state = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 'auto',
        stacked: true,
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '13px',
        fontFamily: 'Roboto',
        fontWeight: 100,
        letterSpacing: 2,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 35,
        offsetY: 15,
        margin: 20,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          size: 0,
          width: 0,
          height: 0,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      colors:
        data['workstation'] == undefined && data['allCollaborativeSpaces'] !== undefined
          ? [COLORS.deepCerise, COLORS.darkGrey30]
          : formattedData !== undefined
          ? [COLORS.deepCerise, COLORS.blue]
          : [COLORS.darkGrey30],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '50%',
          borderRadius: [6],
        },
      },
      dataLabels: {
        enabled: formattedData !== undefined ? true : false,
        position: 'left',
        offsetX: 5,
        offsetY: 0,
        formatter: (value) => {
          if (value !== 0) return Math.round(Math.abs(value)) + '%'
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 4,
            borderColor: COLORS.darkGrey80,
            fillColor: COLORS.darkGrey80,
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0,
          },
        ],
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        min: -100,
        max: 100,
        offsetX: -15,
        offsetY: -15,
      },
      tooltip: {
        enabled: false,
        shared: false,

        x: {
          formatter: function (val) {
            return Math.abs(val) + '%'
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) + '%'
          },
        },
      },
      title: {
        text: t('landingPage.collaborativeAndIndividualUse.title'),
        style: {
          fontSize: '15px',
          fontWeight: '700',
          fontFamily: 'Roboto',
          color: '#4F4F64',
        },
      },

      xaxis: {
        categories: [
          t('graph.detailedOccupancy.yaxisDays.MONDAY'),
          t('graph.detailedOccupancy.yaxisDays.TUESDAY'),
          t('graph.detailedOccupancy.yaxisDays.WEDNESDAY'),
          t('graph.detailedOccupancy.yaxisDays.THURSDAY'),
          t('graph.detailedOccupancy.yaxisDays.FRIDAY'),
        ],
        position: 'top',
        style: {
          fontSize: '9px',
          fontFamily: 'roboto',
          fontWeight: '400',
        },
        labels: {
          formatter: function (val) {
            if (val == 0 || val == 100 || val == -100) return Math.abs(val) + '%'
          },
        },
        axisBorder: {
          show: false,
        },
      },
    },
  }

  return (
    <div id="collaborativeAndIndividualUse" className="shadowSection relative" style={{ height: '100%' }}>
      <div data-tip data-for="collaborativeAndIndividualUseKpi" className="info-tooltip" style={{ zIndex: 1 }}>
        <IconSvg name="FILLED_INFO" color="GREY30" className="mr-2" />
      </div>
      <ReactTooltip id="collaborativeAndIndividualUseKpi" place="bottom">
        {t('landingPage.collaborativeAndIndividualUse.kpi')}
      </ReactTooltip>
      <ReactApexChart options={state.options} series={series} type="bar" height={'100%'} />
    </div>
  )
}
export default CollaborativeAndIndividualUse

import { FC } from 'react'
import IconSvg, { iconColors } from '../../../../atoms/icons/IconSvg'
import { ICON_SVG_MAP } from '../../../../atoms/icons/IconSvgMap'

type Props = {
  selected?: boolean
  className?: string
  icon?: keyof typeof ICON_SVG_MAP
  iconColor?: keyof typeof iconColors
  hovered?: boolean
  disabled?: boolean
  onClick?: () => void
}

const NavbarMenuItem: FC<Props> = (props) => {
  const { selected, className = '', icon, iconColor = 'BLACK', hovered, disabled, onClick, children } = props

  return (
    <div
      className={`flex justify-between h-10 p-3 w-full transition duration-200 ease-out ${
        selected
          ? 'bg-primary text-basic-100'
          : hovered
          ? 'bg-basic-300 text-basic-600 cursor-pointer'
          : disabled
          ? 'text-basic'
          : 'text-basic-600'
      } ${className}`}
      onClick={onClick}
    >
      <div className="flex">
        {icon && <IconSvg name={icon} color={iconColor} className="flex items-center mr-3" />}
        <span className={`flex items-center text-b2 capitalize ${icon ? 'font-bold' : 'font-medium'}`}>{children}</span>
      </div>
      {selected && <IconSvg name="SELECTED_CLIENT" color="WHITE" className="flex items-center ml-3" />}
    </div>
  )
}

export default NavbarMenuItem

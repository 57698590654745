import { FC, useState, useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'

import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import IconSvg from 'components/atoms/icons/IconSvg'
import NoDashboardPanel from 'components/molecules/panels/NoDashboardPanel'
import DashboardList from 'components/molecules/dashboardList/dashboardList'

import { DashboardType, DashboardTypeList, PagesType } from 'types/DashboardType'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { savedReportsService } from 'services/savedReportsService'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import DashboardPageLayout from 'components/layouts/DashboardPageLayout'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'
import SelectedDashboardActions from 'components/molecules/dashboardList/SelectedDashboardActions'
import FormInput from 'components/atoms/input/FormInput'
import { useLocation } from 'react-router-dom'

const SavedReportsDashboardsView: FC = () => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const history = useHistory()
  const location = useLocation()
  const [modifiedDashboard, setModifiedDashboard] = useState<DashboardType>()
  useEffect(() => {
    if (location && location.state) {
      //@ts-ignore
      setModifiedDashboard(location.state.modifiedDashboard)
      //@ts-ignore
      setSelectedDashboard(location.state.modifiedDashboard)
    }
  }, [location])
  const [selectedDashboard, setSelectedDashboard] = useState<DashboardType>()
  const [editableName, setEditableName] = useState(selectedDashboard?.name)
  const [editMode, setEditMode] = useState(false)
  const [dashboardModifiedPage, setDashboardModifiedPage] = useState<PagesType>()
  const [pageIndex, setPageIndex] = useState(0)

  useEffect(() => {
    if (selectedDashboard) {
      setEditMode(false)
      setEditableName(selectedDashboard.name)
      history.push(
        generatePath(PATHS.SAVED_REPORTS.DASHBOARD_ID, {
          url: PATHS.SAVED_REPORTS.HOME,
          id: selectedDashboard.id.toString(),
        }),
      ),
        toggleDrawer()
    }
  }, [selectedDashboard])

  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('forms.errors.required'),
  })
  useEffect(() => {
    refetch()
    if (selectedDashboard !== undefined) {
      setSelectedDashboard(undefined)
      setIsOpen(true)
    }
  }, [clientCode])

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: editableName,
    },
  })

  const {
    data: dashboardsData,
    isLoading,
    refetch,
  } = useQuery<DashboardTypeList>({
    queryKey: QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode),
    queryFn: () => {
      if (clientCode) return savedReportsService.getAllDashboards(clientCode)
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })

  const onItemClick = (selectedDashboardId: number) => {
    setSelectedDashboard(dashboardsData?.result.find((dashboard) => dashboard.id === selectedDashboardId))
  }

  const { data: selectedDashboardData } = useQuery<DashboardType>({
    queryKey:
      selectedDashboard !== undefined
        ? QUERY_KEYS.DASHBOARDS.GET_DASHBOARD(clientCode, selectedDashboard.id)
        : undefined,
    queryFn:
      selectedDashboard !== undefined && !isLoading
        ? () => savedReportsService.getDashboard(clientCode, selectedDashboard.id.toLocaleString())
        : () => null,

    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode && !(selectedDashboard === undefined),
  })

  const onDelete = () =>
    history.push(
      generatePath(PATHS.SAVED_REPORTS.DASHBOARD, {
        url: PATHS.SAVED_REPORTS.HOME,
      }),
    )

  const handleChange = (e: any) => {
    if (e.target.value == '') setEditableName('')
    const newName = e.target.value
    if (newName && newName.length > 0) {
      setEditableName(newName)
    }
    e.preventDefault()
  }

  const onEditMode = () => setEditMode(!editMode)

  const { mutate } = useMutation({
    mutationKey: selectedDashboard && QUERY_KEYS.DASHBOARDS.UPDATE_DASHBOARD(selectedDashboard.id),
    mutationFn:
      selectedDashboard !== undefined && selectedDashboardData != undefined
        ? ({ dashboardName, dashboardPage }: { dashboardName: string; dashboardPage: PagesType }) =>
            savedReportsService.editDashboard(
              clientCode,
              dashboardName,
              selectedDashboard?.id,
              dashboardPage || selectedDashboardData?.pages[0] || { index: 1, reports: [] },
            )
        : undefined,
    onSuccess: async () => {
      toast.success(t('dashboard.editDashboardSuccess'))
      refetch()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const getPageIndex = (pageIndex: number) => {
    setPageIndex(pageIndex)
  }

  const onSubmit = (event: any, { name, dashboardPage }: { name: string; dashboardPage: PagesType }) => {
    mutate({ dashboardName: name, dashboardPage })
    setEditMode(false)
    event.preventDefault()
  }

  const onPageEdit = (dashboardPage: PagesType) => {
    setDashboardModifiedPage(dashboardPage)
  }

  return (
    <>
      {isLoading ? (
        <div className="mt-12 flex justify-center">
          <LoaderComponent />
        </div>
      ) : dashboardsData?.result.length === 0 ? (
        <>
          <NoDashboardPanel title={t('dashboard.noItemTitle')} content={t('dashboard.noItemContent')} />
        </>
      ) : (
        <>
          <div className="flex flew-wrap pr-5" style={{ height: '70px' }}>
            <button onClick={toggleDrawer}>
              <IconSvg name="ARROW_RIGHT" className="m-3" />
            </button>

            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit((event) =>
                  //@ts-ignore
                  onSubmit(event, { name: editableName || '', dashboardPage: dashboardModifiedPage }),
                )}
                className=" container flex flew-wrap justify-between"
              >
                <div className="flex flew-wrap place-items-center">
                  <div className="ml-5" onChange={handleChange}>
                    {editMode ? (
                      <FormInput white name="name" value={editableName} />
                    ) : (
                      <p className="text-h2">{editableName}</p>
                    )}
                  </div>
                </div>
                <div>
                  {selectedDashboard && (
                    <SelectedDashboardActions
                      selectedDashboard={selectedDashboard}
                      selectedDashboardData={selectedDashboardData}
                      onEditMode={onEditMode}
                      onDeleteSuccess={onDelete}
                      editMode={editMode}
                      currentPage={pageIndex}
                    />
                  )}
                </div>
              </form>
            </FormProvider>
          </div>

          {selectedDashboard ? (
            <div className="h-screen flex-1">
              {selectedDashboardData && (
                <DashboardPageLayout
                  getPageIndex={getPageIndex}
                  selectedDashboardData={
                    modifiedDashboard && modifiedDashboard.id === selectedDashboardData.id
                      ? modifiedDashboard
                      : selectedDashboardData
                  }
                  editMode={editMode}
                  onPageEdit={onPageEdit}
                />
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-start items-center">
              <img
                style={{ objectFit: 'cover' }}
                className="max-w-full h-auto rounded-xl transform -mt-2"
                src="/images/no_dashboard.svg"
                alt={t('home.noDashboard.alt')}
              />
              <div className="mt-10 mx-auto w-1/3 mb-12">
                <div className="w-5/6 mx-auto">
                  <p className="text-primary text-h4 my-4 text-center font-bold leading-normal">
                    {t('dashboard.selectDashboard')}
                  </p>
                </div>
              </div>
            </div>
          )}

          <Drawer zIndex={99} size={350} open={isOpen} onClose={toggleDrawer} direction="left">
            <div className="bg-basic w-full flex flex-row justify-between">
              <p className="text-primary text-h4 my-4 text-center font-bold leading-normal ml-3">
                {t('dashboard.drawerTitle')}
              </p>
              <IconSvg name="CLOSE" color="WHITE" className="m-2" onClick={toggleDrawer} />
            </div>
            <DashboardList
              items={dashboardsData?.result}
              isLoading={isLoading}
              onItemClick={onItemClick}
              setIsOpen={setIsOpen}
              selectedItem={selectedDashboard && selectedDashboard.id}
            />
          </Drawer>
        </>
      )}
    </>
  )
}

export default SavedReportsDashboardsView

import React from 'react'
import Chart from 'react-apexcharts'

interface OneGraphColorProps {
  color: string
}

const OneGraphColor: React.FC<OneGraphColorProps> = ({ color }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: color,
  }
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]
  return <Chart options={options} series={series} type="bar" width="500" />
}

interface TwoGraphColorProps {
  colors: string[]
}

const TwoGraphColor: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    },
    colors: colors,
  }
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: 'series-2',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]
  return <Chart options={options} series={series} type="bar" width="500" />
}
const ThreeGraphColor: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    },
    colors: colors,
  }
  const series = [
    {
      name: 'Moy',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: '9th decile',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: 'max',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ]
  return <Chart options={options} series={series} type="bar" width="500" />
}
const FourGraphColorOccupancy: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: ['A', 'B', 'C'],
    },
    colors: colors,
  }
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 45],
    },
    {
      name: 'series-2',
      data: [30, 40, 45],
    },
    {
      name: 'series-3',
      data: [30, 40, 45],
    },
    {
      name: 'series-4',
      data: [30, 40, 45],
    },
  ]
  return <Chart options={options} series={series} type="bar" width="500" />
}
const FourGraphColorBooking: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: ['A', 'B', 'C'],
    },
    colors: colors,
  }
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 45],
    },
    {
      name: 'series-2',
      data: [30, 40, 45],
    },
    {
      name: 'series-3',
      data: [30, 40, 45],
    },
    {
      name: 'series-4',
      data: [30, 40, 45],
    },
  ]
  return <Chart options={options} series={series} type="bar" width="500" />
}
const FourGraphColorBenchmark: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: ['1 jan', '2 jan', '3 jan', '4 jan', '5 jan'],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: colors,
    stroke: {
      width: [3, 3, 3, 3],
      dashArray: [0, 0, 0, 8],
      curve: 'straight',
      lineCap: 'round ',
    },
  }
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 15, 80, 55],
    },
    {
      name: 'series-2',
      data: [50, 20, 25, 75, 60],
    },
    {
      name: 'series-3',
      data: [70, 60, 35, 90, 20],
    },
    {
      name: 'series-4',
      data: [10, 80, 45, 10, 30],
    },
  ]
  return <Chart options={options} series={series} type="line" width="500" />
}
const ElevenGraphColor: React.FC<TwoGraphColorProps> = ({ colors }) => {
  const options = {
    chart: {
      height: 450,
      type: 'heatmap',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '01:00', '01:30'],
    },
    plotOptions: {
      heatmap: {
        radius: 8,
        enableShades: true,
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#FFFFFF',
              name: '0%',
            },
            {
              from: 1,
              to: 100.49999,
              color: colors[0],
              name: '0-10%',
            },
            {
              from: 100.5,
              to: 200.49999,
              color: colors[1],
              name: '11-20%',
            },
            {
              from: 200.5,
              to: 300.49999,
              color: colors[2],
              name: '21-30%',
            },
            {
              from: 300.5,
              to: 400.49999,
              color: colors[3],
              name: '31-40%',
            },
            {
              from: 400.5,
              to: 500.49999,
              color: colors[4],
              name: '41-50%',
            },
            {
              from: 500.5,
              to: 600.49999,
              color: colors[5],
              name: '51-60%',
            },
            {
              from: 600.5,
              to: 700.49999,
              color: colors[6],
              name: '61-70%',
            },
            {
              from: 700.5,
              to: 800.49999,
              color: colors[7],
              name: '71-80%',
            },
            {
              from: 800.5,
              to: 900.49999,
              color: colors[8],
              name: '81-90%',
            },
            {
              from: 900.5,
              to: 1000.49999,
              color: colors[9],
              name: '91-100%',
            },
            {
              from: 1000.5,
              to: Infinity,
              color: colors[10],
              name: '> 100%',
            },
          ],
        },
      },
    },
    grid: {
      padding: {
        right: 20,
      },
    },
  }
  const series = [
    {
      name: 'Thursday 30',
      data: [
        {
          x: '08:00',
          y: 28,
        },
        {
          x: '09:00',
          y: 43.3,
        },
        {
          x: '10:00',
          y: 36,
        },
        {
          x: '11:00',
          y: 488,
        },
        {
          x: '12:00',
          y: 724,
        },
        {
          x: '13:00',
          y: 27.3,
        },
        {
          x: '14:00',
          y: 27.7,
        },
        {
          x: '15:00',
          y: 728,
        },
        {
          x: '16:00',
          y: 849,
        },
        {
          x: '17:00',
          y: 48.3,
        },
        {
          x: '18:00',
          y: 20,
        },
        {
          x: ' ',
          y: 580,
        },
        {
          x: 'avg',
          y: 40.8,
        },
        {
          x: 'max',
          y: 74,
        },
      ],
    },
    {
      name: 'Wednesday 29',
      data: [
        {
          x: '08:00',
          y: 1100.39999999999999,
        },
        {
          x: '09:00',
          y: 670.39999999999998,
        },
        {
          x: '10:00',
          y: 217.8,
        },
        {
          x: '11:00',
          y: 230.39999999999998,
        },
        {
          x: '12:00',
          y: 544.1,
        },
        {
          x: '13:00',
          y: 164.8,
        },
        {
          x: '14:00',
          y: 247.8,
        },
        {
          x: '15:00',
          y: 815.9,
        },
        {
          x: '16:00',
          y: 532.60000000000002,
        },
        {
          x: '17:00',
          y: 207.8,
        },
        {
          x: '18:00',
          y: 941.5,
        },
        {
          x: ' ',
          y: 610,
        },
        {
          x: 'avg',
          y: 1898.4,
        },
        {
          x: 'max',
          y: 247.8,
        },
      ],
    },
    {
      name: 'Tuesday 28',
      data: [
        {
          x: '08:00',
          y: 94.1,
        },
        {
          x: '09:00',
          y: 426.1,
        },
        {
          x: '10:00',
          y: 637.6,
        },
        {
          x: '11:00',
          y: 975.79999999999998,
        },
        {
          x: '12:00',
          y: 141.8,
        },
        {
          x: '13:00',
          y: 778.79999999999998,
        },
        {
          x: '14:00',
          y: 236.6,
        },
        {
          x: '15:00',
          y: 215.7,
        },
        {
          x: '16:00',
          y: 244.4,
        },
        {
          x: '17:00',
          y: 173.5,
        },
        {
          x: '18:00',
          y: 132.4,
        },
        {
          x: ' ',
          y: 200,
        },
        {
          x: 'avg',
          y: 168.79999999999998,
        },
        {
          x: 'max',
          y: 1244.4,
        },
      ],
    },
    {
      name: 'Monday 27',
      data: [
        {
          x: '08:00',
          y: 111.6,
        },
        {
          x: '09:00',
          y: 231.29999999999998,
        },
        {
          x: '10:00',
          y: 365.3,
        },
        {
          x: '11:00',
          y: 413.6,
        },
        {
          x: '12:00',
          y: 244.89999999999998,
        },
        {
          x: '13:00',
          y: 315.3,
        },
        {
          x: '14:00',
          y: 472.8,
        },
        {
          x: '15:00',
          y: 396.9,
        },
        {
          x: '16:00',
          y: 415.3,
        },
        {
          x: '17:00',
          y: 346.9,
        },
        {
          x: '18:00',
          y: 865,
        },
        {
          x: ' ',
          y: 0,
        },
        {
          x: 'avg',
          y: 316.3,
        },
        {
          x: 'max',
          y: 472.8,
        },
      ],
    },
  ]
  return <Chart options={options} series={series} type="heatmap" width="600" />
}

export {
  OneGraphColor,
  TwoGraphColor,
  ThreeGraphColor,
  FourGraphColorOccupancy,
  FourGraphColorBenchmark,
  FourGraphColorBooking,
  ElevenGraphColor,
}

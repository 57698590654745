import { FC, useContext, useState, useEffect } from 'react'

import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import { ExportDataType } from 'types/ExportType'
import { GraphType } from '../../filters/required/GraphFilter'
import Button from '../../../atoms/button/Button'
import { useTranslation } from 'react-i18next'
import ApexCharts from 'apexcharts'
import { saveAs } from 'file-saver'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { savedReportsService } from 'services/savedReportsService'
import { FiltersType } from 'types/SavedReportsTypes'
import { GraphFiltersContext } from 'components/molecules/filters/required/GraphFiltersContext'
import { optionAllValue } from 'utils/constants/graphs/global'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import { ReportsType } from 'types/DashboardType'
import Roboto from 'assets/fonts/Roboto-Bold.ttf'
import RobotoMono from 'assets/fonts/RobotoMono-Regular.ttf'
import RobotoMonoItalic from 'assets/fonts/RobotoMono-MediumItalic.ttf'
import { Page, Text, View, Document, StyleSheet, Image, pdf, Font } from '@react-pdf/renderer'
import domtoimage from 'dom-to-image'
import IconSvg from 'components/atoms/icons/IconSvg'
import { toast } from 'react-toastify'
import { getLabelsfilter } from 'utils/filtersUtils'
import * as XLSX from 'xlsx'

type Props = {
  open: boolean
  graphType?: GraphType
  exportData?: any
  data?: ExportDataType
  dashboardData?: any
  graphName?: string
  onClose: () => void
  filters?: FiltersType
  currentPage?: number
  zoom?: number
}

const ExportationDialog: FC<Props> = ({
  onClose,
  open,
  data,
  graphType,
  dashboardData,
  graphName,
  filters,
  currentPage,
}: Props) => {
  const { t } = useTranslation()
  const onExportClose = () => onClose()
  const current = new Date()
  const { state } = useContext(GraphFiltersContext)
  const { clientCode = '' } = useCurrentUser()
  const [list, setListItems] = useState<any>()
  const [imageReport, setImageReport] = useState<any>()
  const [dashboardFilters, setDashboardFilters] = useState<any[]>([])
  const [imagesReport, setImagesReport] = useState<any[]>([])
  const [namesReport, setNamesReport] = useState<any[]>([])
  const [dashboardGraphTypes, setDashboardGraphTypes] = useState<any>([])
  const [xlsxName, setXlsxName] = useState('')
  const { structure } = useFetchStructure()
  const [pages, setPages] = useState(dashboardData?.pages)
  const [reports, setReports] = useState<ReportsType[][]>()
  const [currentPageLoader, setCurrentPageLoader] = useState(false)
  const [completeDashboardLoader, setCompleteDashboardLoader] = useState(false)

  const date = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear()
  Font.register({
    family: 'Roboto',
    fonts: [
      { src: Roboto }, // font-style: normal, font-weight: normal
    ],
  })
  Font.register({
    family: 'RobotoItalic',
    src: RobotoMonoItalic,
    fontStyle: 'italic',
    fontWeight: 'normal',
  })
  Font.register({
    family: 'RobotoMono',
    fonts: [{ src: RobotoMono }],
  })
  Font.registerHyphenationCallback((word) => [word])
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      width: '720px',
      backgroundColor: 'transparent',
      position: 'relative',
    },
    filter: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '8px',
      marginBottom: '10px',
      lineHeight: '1.55',
    },
    label: {
      fontSize: '14px',
      fontWeight: 'extrabold',
      fontFamily: 'Roboto',
      width: 100,
    },
    title: {
      fontFamily: 'Roboto',
      fontSize: '22px',
      marginBottom: '25px',
      color: '#D33087',
      fontWeight: 800,
    },
    value: {
      fontSize: '12px',
      fontWeight: 'light',
      width: 100,
    },
    section: {
      marginTop: '30px',
      marginRight: 5,
      padding: 2,
      flexGrow: 1,
    },
    image: {
      width: '489px',
    },
    logo: {
      position: 'absolute',
      top: 12,
      right: 12,
      width: '35px',
    },
    simpleLogo: {
      position: 'absolute',
      top: 12,
      right: 12,
      width: '55px',
    },
    view: {
      width: '100%',
      height: '100%',
      padding: 0,
      backgroundColor: '#2d2d3b',
      color: 'white',
    },
    background: {
      position: 'absolute',
      minWidth: '100%',
      minHeight: '100%',
      // display: 'block',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      opacity: 0.3,
    },
    motto: {
      position: 'absolute',
      bottom: '24px',
      left: '18%',
      fontSize: '14px',
      fontFamiliy: 'RobotoItalic',
    },
    information: {
      margin: '15px 42px',
      fontSize: '14px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    informationDescription: {
      fontSize: '14px',
      display: 'flex',
      marginTop: '22px',
      paddingRight: '25px',
    },
    informationSection: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '14px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    line: {
      borderBottom: '1px dotted #fff',
      top: '-23px',
      width: 'auto',
      overflow: 'hidden',
      flex: '1',
      marginRight: '30px',
      marginLeft: '25px',
    },
  })
  useEffect(() => {
    if (dashboardData) {
      setPages(dashboardData.pages)
      const reportsByDashboard = dashboardData.pages?.map((page: any) => page.reports)
      setReports(
        reportsByDashboard.reduce((acc: any, cur: any) => {
          const newReports = cur.sort((a: any, b: any) => a.position - b.position)

          return [...acc, newReports]
        }, [] as ReportsType[][]),
      )
    }
  }, [dashboardData])
  const getformattedFilters = (filtersToformat: any) => {
    const labels: any = []
    const siteData = filtersToformat?.domainPath
      ? structure?.domains.find((domain) => domain.path === filtersToformat?.domainPath)
      : structure?.domains.find((domain) => domain.path === filtersToformat?.SITE.value)
    const siteFilter = { key: t(graphsStructure.domainPath.placeholder), value: siteData?.name }
    const buildingName =
      !!filtersToformat && !!filtersToformat?.buildingCode
        ? filtersToformat?.buildingCode === optionAllValue
          ? t(graphsStructure.buildingCode.all)
          : siteData?.buildings?.find((building) => building.code === filtersToformat?.buildingCode)?.name
        : filtersToformat?.BUILDING
        ? filtersToformat?.BUILDING.value === optionAllValue
          ? t(graphsStructure.buildingCode.all)
          : filtersToformat?.BUILDING !== undefined && filtersToformat?.BUILDING.value !== undefined
          ? siteData?.buildings?.find((building) => building.code === filtersToformat?.BUILDING.value)?.name
          : ''
        : ''

    const buildingCode = { key: t(graphsStructure.buildingCode.placeholder), value: buildingName ? buildingName : '' }
    const floorName = filtersToformat?.floorPath
      ? filtersToformat?.floorPath === optionAllValue
        ? t(graphsStructure.floorPath.all)
        : siteData?.floors?.find((floor) => floor.path === filtersToformat?.floorPath)?.name
      : filtersToformat?.FLOOR
      ? filtersToformat?.FLOOR.value === optionAllValue
        ? t(graphsStructure.floorPath.all)
        : siteData?.floors?.find((floor) => floor.path === filtersToformat?.FLOOR.value)?.name
      : undefined
    const floorPath = { key: t(graphsStructure.floorPath.placeholder), value: floorName }
    const CompartmentName = filtersToformat?.compartmentPath
      ? filters?.compartmentPath === optionAllValue
        ? t(graphsStructure.compartmentPath.all)
        : siteData?.compartments?.find((compartment) => compartment.path === filtersToformat?.compartmentPath)?.name
      : filtersToformat?.COMPARTMENT
      ? filtersToformat?.COMPARTMENT.value === optionAllValue
        ? t(graphsStructure.compartmentPath.all)
        : siteData?.compartments?.find((compartment) => compartment.path === filtersToformat?.COMPARTMENT.value)?.name
      : undefined
    const compartmentPath = { key: t(graphsStructure.compartmentPath.placeholder), value: CompartmentName }
    const restaurantName =
      filtersToformat.restaurantId !== undefined ||
      (filtersToformat.RESTAURANT && filtersToformat.RESTAURANT.value !== undefined)
        ? filtersToformat.restaurantId
          ? siteData?.restaurants?.find((restaurant) => restaurant.id === filtersToformat.restaurantId)?.name
          : filtersToformat.RESTAURANT
          ? siteData?.restaurants?.find((restaurant) => restaurant.id === filtersToformat.RESTAURANT.id)?.name
          : ''
        : ''
    const restaurant = { key: t(graphsStructure.restaurantId.placeholder), value: restaurantName }
    const roomType = {
      key: t(graphsStructure.roomType.placeholder),
      value: filtersToformat?.roomType
        ? graphsStructure.roomType[filtersToformat.roomType as keyof typeof graphsStructure.roomType] == undefined
          ? filtersToformat?.roomType
          : t(graphsStructure.roomType[filtersToformat.roomType as keyof typeof graphsStructure.roomType])
        : filtersToformat.ROOM_TYPE
        ? graphsStructure.roomType[filtersToformat.ROOM_TYPE.value as keyof typeof graphsStructure.roomType] ==
          undefined
          ? filtersToformat.ROOM_TYPE['value']
          : t(graphsStructure.roomType[filtersToformat.ROOM_TYPE.value as keyof typeof graphsStructure.roomType])
        : undefined,
    }
    const quarterValue = filtersToformat.QUARTER && filtersToformat.QUARTER.value
    const rangeTypeValue = filtersToformat.rangeType
    const rangeType = {
      key: t(graphsStructure.rangeType.placeholder),
      value:
        quarterValue === 'CURRENT_MONTH'
          ? t(graphsStructure.rangeType[quarterValue as keyof typeof graphsStructure.rangeType].name)
          : quarterValue
          ? t(graphsStructure.rangeType[quarterValue as keyof typeof graphsStructure.rangeType])
          : rangeTypeValue === 'CURRENT_MONTH'
          ? t(graphsStructure.rangeType[rangeTypeValue as keyof typeof graphsStructure.rangeType].name)
          : t(graphsStructure.rangeType[rangeTypeValue as keyof typeof graphsStructure.rangeType]),
    }
    const capacityType = {
      key: t(graphsStructure.capacityType.placeholder),
      value: filtersToformat.capacityType
        ? t(graphsStructure.capacityType[filtersToformat.capacityType as keyof typeof graphsStructure.capacityType])
        : filtersToformat.CAPACITY_SIZE
        ? t(
            graphsStructure.capacityType[
              filtersToformat.CAPACITY_SIZE.value as keyof typeof graphsStructure.capacityType
            ],
          )
        : undefined,
    }
    const ordinateAxis = {
      key: t(graphsStructure.ordinateAxis.placeholder),
      value: filtersToformat.ordinateAxis
        ? filters?.ordinateAxis
          ? t(graphsStructure.ordinateAxis[filtersToformat.ordinateAxis as keyof typeof graphsStructure.ordinateAxis])
          : undefined
        : filtersToformat.DETAILED_OCCUPANCY
        ? t(
            graphsStructure.ordinateAxis[
              filtersToformat.DETAILED_OCCUPANCY.value as keyof typeof graphsStructure.ordinateAxis
            ],
          )
        : undefined,
    }
    const heatmapKpi = {
      placeholder: 'graph.filters.kpi.placeholder',
      AVERAGE: 'graph.filters.kpi.realAverage',
      PERCEIVED_AVERAGE: 'graph.filters.kpi.occupationPerceivedAverage',
    }
    const detailedOccupancyKpi = {
      ...graphsStructure.kpiWorkstation,
      NINTH_DECILE: 'graph.filters.kpi.ninthDecile',
      PERCEIVED_NINTH_DECILE: 'graph.filters.kpi.perceivedNinthDecile',
    }
    const kpi = {
      key: t(graphsStructure.kpi.placeholder),
      value: filtersToformat.kpi
        ? graphType == 'HEAT_MAP'
          ? t(heatmapKpi[filtersToformat.kpi as keyof typeof heatmapKpi])
          : graphType == 'DETAILED_OCCUPANCY' || graphType == 'BUILDING_ATTENDANCE'
          ? t(detailedOccupancyKpi[filtersToformat.kpi as keyof typeof detailedOccupancyKpi])
          : t(graphsStructure.kpi[filtersToformat.kpi as keyof typeof graphsStructure.kpi])
        : filtersToformat.KPI
        ? graphType == 'HEAT_MAP'
          ? t(heatmapKpi[filtersToformat.KPI.value as keyof typeof heatmapKpi])
          : graphType == 'DETAILED_OCCUPANCY' || graphType == 'BUILDING_ATTENDANCE'
          ? t(detailedOccupancyKpi[filtersToformat.KPI.value as keyof typeof detailedOccupancyKpi])
          : t(graphsStructure.kpi[filtersToformat.KPI.value as keyof typeof graphsStructure.kpi])
        : undefined,
    }
    const capacityCategory = {
      key: t(graphsStructure.capacityCategory.placeholder),
      value: t(
        graphsStructure.capacityCategory[
          filtersToformat.capacityCategory as keyof typeof graphsStructure.capacityCategory
        ],
      ),
    }
    const bookable = {
      key: t(graphsStructure.bookable.placeholder),
      value: filtersToformat.bookable
        ? t(graphsStructure.bookable[filtersToformat.bookable as keyof typeof graphsStructure.bookable])
        : filtersToformat.BOOKABLE
        ? t(graphsStructure.bookable[filtersToformat.BOOKABLE.value as keyof typeof graphsStructure.bookable])
        : undefined,
    }
    const open = {
      key: t(graphsStructure.open.placeholder),
      value: filtersToformat?.open
        ? t(graphsStructure.open[filtersToformat.open as keyof typeof graphsStructure.open])
        : filtersToformat.OPEN_CLOSED
        ? t(graphsStructure.open[filtersToformat.OPEN_CLOSED.value as keyof typeof graphsStructure.open])
        : undefined,
    }
    const businessUnit = {
      key: t(graphsStructure.businessUnit.placeholder),
      value: filtersToformat.businessUnit
        ? filtersToformat.businessUnit === optionAllValue
          ? t('graph.filters.businessUnit.all')
          : filtersToformat.businessUnit
        : filtersToformat.BUSINESS_UNITS
        ? filtersToformat.BUSINESS_UNITS.value === optionAllValue
          ? t('graph.filters.businessUnit.all')
          : filtersToformat.BUSINESS_UNITS.value
        : undefined,
    }
    const nature = {
      key: t(graphsStructure.nature.placeholder),
      value: filtersToformat.nature
        ? t(graphsStructure.nature[filtersToformat.nature as keyof typeof graphsStructure.nature])
        : filtersToformat.OPEN_SPACE
        ? t(graphsStructure.nature[filtersToformat.OPEN_SPACE.value as keyof typeof graphsStructure.nature])
        : undefined,
    }
    const affectation = {
      key: t(graphsStructure.nature.placeholder),
      value: filtersToformat.affectation
        ? t(graphsStructure.affectation[filtersToformat.affectation as keyof typeof graphsStructure.affectation])
        : filtersToformat.DEDICATED_SHARED
        ? t(
            graphsStructure.affectation[
              filtersToformat.DEDICATED_SHARED.value as keyof typeof graphsStructure.affectation
            ],
          )
        : undefined,
    }
    const scopeType = {
      key: t(graphsStructure.scopeType.placeholder),
      value: filtersToformat.scopeType
        ? t(graphsStructure.scopeType[filtersToformat.scopeType as keyof typeof graphsStructure.scopeType])
        : filtersToformat.SCOPE_TYPE
        ? t(graphsStructure.scopeType[filtersToformat.SCOPE_TYPE.value as keyof typeof graphsStructure.scopeType])
        : undefined,
    }
    const roomSizeType = {
      key: t(graphsStructure.capacityType.placeholder),
      value: filtersToformat.capacityType
        ? t(graphsStructure.capacityType[filtersToformat.capacityType as keyof typeof graphsStructure.capacityType])
        : filtersToformat.ROOM_SIZE_TYPE
        ? t(
            graphsStructure.capacityType[
              filtersToformat.ROOM_SIZE_TYPE.value as keyof typeof graphsStructure.capacityType
            ],
          )
        : undefined,
    }
    const graph = {
      key: t(graphsStructure.graph.placeholder),
      value: filtersToformat.graph
        ? //@ts-ignore
          t(graphsStructure.graph[filtersToformat.graph])
        : t(graphsStructure.graph[filtersToformat.GRAPH.value as GraphType]),
    }

    labels.push(
      graph,
      siteFilter,
      buildingCode,
      floorPath,
      capacityCategory,
      kpi,
      roomType,
      compartmentPath,
      rangeType,
      restaurant,
      ordinateAxis,
      capacityType,
      nature,
      businessUnit,
      open,
      bookable,
      affectation,
      scopeType,
      roomSizeType,
    )
    return labels
  }

  let graphId = ''
  let pdfName = ''
  const getGraphType = (type: any) => {
    switch (type) {
      case 'DETAILED_OCCUPANCY':
        graphId = 'detailedOccupancy-chart'
        pdfName = `detailedOccupancyChart-${current.getDate()}.pdf`
        return { graphId, pdfName }

      case 'TOP_FLOP':
        graphId = 'topflop-chart'
        pdfName = `topFlopChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'UTILIZATION_OF_SPACE':
        graphId = 'utilization-chart'
        pdfName = `utilizationOfSpaceChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'OCCUPANCY_MONTHLY':
        graphId = 'monthly-chart'
        pdfName = `monthlyChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'RESTAURANT_MAX_CUMULATION':
        graphId = 'restaurant-chart'
        pdfName = `restaurantChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'RESTAURANT_ATTENDANCE_DAY':
        graphId = 'detailedOccupancy-chart'
        pdfName = `frequentationChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'BUILDING_ATTENDANCE':
        graphId = 'detailedOccupancy-chart'
        pdfName = `spaceAttendanceChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'UTILIZATION_QUALITY':
        graphId = 'utilizationQuality-chart'
        pdfName = `utilizationQualityChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'ROOM_BOOKING':
        graphId = 'roomBooking-chart'
        pdfName = `roomBookingChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
      case 'BENCHMARK':
        graphId = 'benchmark-chart'
        pdfName = `benchmarkChart-${current.getDate()}/${current.getMonth()}/${current.getFullYear()}.pdf`
        return { graphId, pdfName }
    }
  }
  useEffect(() => {
    if (dashboardData == undefined) {
      const filtersLabels = filters ? getLabelsfilter(filters, structure) : getLabelsfilter(state, structure)
      const filtersTitle = Object.values(filtersLabels).join('_')
      if (filtersLabels) setXlsxName(filtersTitle.substring(0, 202))
      const pdfDataFilters = filters ? getformattedFilters(filters) : getformattedFilters(state)
      const filtered = pdfDataFilters.filter(function (el: any) {
        if (el !== undefined && el.value !== undefined) return el.value?.length > 0
      })
      setListItems(filtered)
    }
  }, [filters])
  useEffect(() => {
    if (list && list.length > 0) {
      getDataUri()
    }
  }, [list])
  useEffect(() => {
    if (dashboardData) getDataUri()
  }, [])
  const setHeatmapImage = (report) => {
    setTimeout(() => {
      const map = document.getElementById('my-map')
      if (document.getElementById('my-map')) {
        domtoimage
          .toPng(map)
          .then(function (dataUrl) {
            dashboardData
              ? setImagesReport((imagesReport) => [...imagesReport, { report: report.id, img: dataUrl }])
              : setImageReport(dataUrl)
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error)
          })
      }
    }, 500)
  }
  const getDataUri = async () => {
    if (dashboardData) {
      dashboardData.pages
        .filter((page: any) => page.index == currentPage)[0]
        .reports.map(async (report: any, index: any) => {
          await savedReportsService.getOne(clientCode, report.id).then((data) => {
            setDashboardGraphTypes((dashboardGraphTypes: any) => [data.filters.graph, ...dashboardGraphTypes])
            const type = getGraphType(data.filters.graph)
            setNamesReport((namesReport) => [...namesReport, { report: report.id, name: data.name }])
            const pdfDataFilters = getformattedFilters(data.filters)
            const filtered = pdfDataFilters.filter(function (el: any) {
              if (el !== undefined && el.value !== undefined) return el.value.length > 0
            })
            setDashboardFilters((dashboardFilters) => [{ report: report.id, filters: filtered }, ...dashboardFilters])
            if (data.filters.graph == 'HEAT_MAP') setHeatmapImage(report)
            else {
              if (type && type.graphId && report.id !== undefined) {
                if (ApexCharts.exec(report.id.toString(), 'dataURI') !== undefined) {
                  ApexCharts.exec(report.id.toString(), 'dataURI').then(({ imgURI = '' }) => {
                    setImagesReport((imagesReport) => [...imagesReport, { report: report.id, img: imgURI }])
                  })
                }
              }
            }
          })
        })
    } else {
      getGraphType(graphType)
      if (graphType !== 'HEAT_MAP') {
        if (ApexCharts.exec(graphId.toString(), 'dataURI') !== undefined) {
          ApexCharts.exec(graphId, 'dataURI').then(({ imgURI = '' }) => {
            setImageReport(imgURI)
          })
        }
      }
      if (graphType == 'HEAT_MAP') {
        setHeatmapImage()
      }
    }
  }

  const MyReport = () => (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.section}>
          {list &&
            list.length > 0 &&
            list.map((reportFilter: any, i: any) => (
              <View key={i}>
                {reportFilter.key == t(graphsStructure.graph.placeholder) && (
                  <View key={reportFilter.key + i} style={styles.filter}>
                    <Text style={styles.title}>{reportFilter.value}</Text>
                  </View>
                )}
                {reportFilter.key !== t(graphsStructure.graph.placeholder) && (
                  <View style={styles.filter}>
                    <Text style={styles.label}>{reportFilter.key}</Text>
                    <Text style={styles.value}>{reportFilter.value}</Text>
                  </View>
                )}
              </View>
            ))}
        </View>
        {imageReport && (
          <View style={styles.section}>
            <Image
              style={{
                position: 'absolute',
                top: 40,
                bottom: 0,
                right: 0,
                objectFit: 'contain',
                width: '80vw',
                height: '80%',
              }}
              src={imageReport}
            />
          </View>
        )}
        <Image src="/images/logo_white.png" style={styles.simpleLogo} />
      </Page>
    </Document>
  )

  const displayMetrics =
    dashboardGraphTypes.includes('HEATMAP') ||
    dashboardGraphTypes.includes('DETAILED_OCCUPANCY') ||
    dashboardGraphTypes.includes('BUILDING_ATTENDANCE') ||
    dashboardGraphTypes.includes('OCCUPANCY_MONTHLY')
  const MyDashboard = () => (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.view}>
          <Image style={styles.background} src="/images/default.png" />

          <Text
            style={{
              marginBottom: '16px',
              marginLeft: '24px',
              marginTop: '24px',
              fontFamily: 'RobotoMono',
              fontSize: '30px',
            }}
          >
            {dashboardData.name}
          </Text>
          <Text
            style={{
              marginBottom: '30px',
              marginLeft: '24px',
              color: '#1CB9D6',
              fontFamily: 'RobotoMono',
              fontSize: '20px',
            }}
          >
            {t('dashboard.edited')} : {date}
          </Text>

          {pages
            .filter((page: any) => page.index == currentPage)[0]
            ['reports'].map((report: any, index: number) => (
              <>
                {namesReport && namesReport.find((elt: any) => elt.report === report.id) && (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: '16px',
                        marginLeft: '48px',
                        fontSize: '14px',
                        marginRight:
                          namesReport.find((elt: any) => elt.report === report.id).name.length > 100 ? '48px' : '0',
                      }}
                    >
                      {namesReport.find((elt: any) => elt.report === report.id).name}
                    </Text>
                    <Text style={styles.line}> </Text>

                    <Text style={{ position: 'relative', right: '20px', top: '-5px' }}>p.{index + 1}</Text>
                  </View>
                )}
              </>
            ))}

          <Text style={styles.motto}>{t('dashboard.motto')}</Text>
        </View>
        <Image src="/images/logo_white.png" style={styles.simpleLogo} />
      </Page>
      {dashboardFilters.length > 0 &&
        pages
          .filter((page: any) => page.index == currentPage)[0]
          ['reports'].map((report: any, index: number) => (
            <Page key={index} size="A4" style={styles.page} orientation="landscape">
              {dashboardFilters.length > 0 && (
                <View key={index} style={styles.section}>
                  {dashboardFilters
                    .filter((o1: any) => imagesReport.map((o2) => o2.report).includes(o1.report))
                    .find((filters: any) => filters.report === report.id)
                    .filters?.map((reportFilter: any, i: any) => (
                      <>
                        {reportFilter.key == t(graphsStructure.graph.placeholder) && (
                          <View key={reportFilter.key + i} style={styles.filter}>
                            <Text style={styles.title}>{reportFilter.value}</Text>
                          </View>
                        )}
                        {reportFilter.key !== t(graphsStructure.graph.placeholder) && (
                          <View key={i} style={styles.filter}>
                            <Text style={styles.label}>{reportFilter.key}</Text>
                            <Text style={styles.value}>{reportFilter.value}</Text>
                          </View>
                        )}
                      </>
                    ))}
                </View>
              )}
              <View style={styles.section}>
                {imagesReport.find((elt: any) => elt.report === report.id) && (
                  <Image
                    style={{
                      position: 'absolute',
                      top: 40,
                      bottom: 0,
                      right: 0,
                      objectFit: 'contain',
                      width: '80vw',
                      height: '80%',
                    }}
                    src={
                      imagesReport.find((elt: any) => elt.report === report.id)
                        ? imagesReport.find((elt: any) => elt.report === report.id).img
                        : ''
                    }
                  />
                )}
              </View>
              <Image src="/images/logo.png" style={styles.simpleLogo} />
              <Text
                style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                  objectFit: 'contain',
                  fontSize: '12px',
                }}
                render={({ pageNumber }) => `p.${pageNumber - 1}`}
              />
            </Page>
          ))}
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.view}>
          <Image style={styles.background} src="/images/default.png" />

          <Text
            style={{
              marginBottom: '16px',
              marginLeft: '24px',
              marginTop: '24px',
              fontFamily: 'RobotoMono',
              fontSize: '30px',
            }}
          >
            {t('Appendix')}
          </Text>
          <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
            {t('dashboard.report')}
          </Text>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              {dashboardGraphTypes.includes('TOP_FLOP') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.topFlop')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.utilizationQuality')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.detailedOccupancy')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.spaceAttendance')}</Text>
                </View>
              )}

              {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/stacked_column.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.stackedColumn')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/monthly.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.monthly')}</Text>{' '}
                </View>
              )}
              {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />{' '}
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.frequentation')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/top_flop.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.restaurant')}</Text>
                </View>
              )}
              {dashboardGraphTypes.includes('HEAT_MAP') && (
                <View style={styles.information}>
                  <Image style={{ width: '34px' }} src="/images/heatmap.png" />
                  <Text style={{ paddingLeft: '8px' }}>{t('graph.filters.graph.heatmap')}</Text>
                </View>
              )}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                height: '100%',
                justifyContent: 'space-between',
              }}
            >
              {dashboardGraphTypes.includes('TOP_FLOP') && (
                <Text style={styles.informationDescription}> {t('informativeSection.topFlop')}</Text>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_QUALITY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.utilizationQuality')}</Text>
              )}
              {dashboardGraphTypes.includes('DETAILED_OCCUPANCY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.detailedOccupancy')}</Text>
              )}
              {dashboardGraphTypes.includes('BUILDING_ATTENDANCE') && (
                <Text style={styles.informationDescription}> {t('informativeSection.frequentation')}</Text>
              )}
              {dashboardGraphTypes.includes('UTILIZATION_OF_SPACE') && (
                <Text style={styles.informationDescription}> {t('informativeSection.stackedColumn')}</Text>
              )}
              {dashboardGraphTypes.includes('OCCUPANCY_MONTHLY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.monthly')}</Text>
              )}{' '}
              {dashboardGraphTypes.includes('RESTAURANT_ATTENDANCE_DAY') && (
                <Text style={styles.informationDescription}> {t('informativeSection.restaurantFrequentation')}</Text>
              )}{' '}
              {dashboardGraphTypes.includes('RESTAURANT_MAX_CUMULATION') && (
                <Text style={styles.informationDescription}>{t('informativeSection.restaurantPeak')}</Text>
              )}
            </View>
          </View>
          <Text style={styles.motto}>
            {t('dashboard.link')}: <a href="https://analytics.jooxter.io/">{'https://analytics.jooxter.io/'}</a>
          </Text>
        </View>

        <Image src="/images/logo_white.png" style={styles.simpleLogo} />
      </Page>
      {displayMetrics && (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.view}>
            <Image style={styles.background} src="/images/default.png" />

            <Text
              style={{
                marginBottom: '16px',
                marginLeft: '24px',
                marginTop: '24px',
                fontFamily: 'RobotoMono',
                fontSize: '30px',
              }}
            >
              {t('Appendix')}
            </Text>
            <Text style={{ marginBottom: '10px', marginLeft: '24px', fontFamily: 'RobotoMono', fontSize: '20px' }}>
              {t('Metrics')}
            </Text>
            <View style={{ display: 'flex', flexDirection: 'column', flex: '1', height: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {displayMetrics && (
                  <View style={styles.information}>
                    <Text style={{ paddingLeft: '8px' }}>{t('dashboard.ninthDecile')}</Text>
                  </View>
                )}
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {displayMetrics && (
                  <View style={styles.information}>
                    <Text style={{ paddingLeft: '8px' }}>{t('dashboard.perceivedOccupancy')}</Text>
                  </View>
                )}
              </View>
            </View>

            <Image src="/images/logo_white.png" style={styles.simpleLogo} />
          </View>
        </Page>
      )}
    </Document>
  )
  const downloadPdf = async () => {
    setCurrentPageLoader(true)
    const doc = dashboardData ? <MyDashboard /> : <MyReport />
    const asPdf = pdf()
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    await saveAs(blob, dashboardData ? `${dashboardData.name}.pdf` : getGraphType(graphType)?.pdfName)
    setDashboardFilters([])
    setImageReport([])
    onClose()
  }

  const downloadXLSX = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(data as unknown as string[])
    const wb = { Sheets: { data1: ws }, SheetNames: ['data1'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const expData = new Blob([excelBuffer], { type: fileType })
    saveAs(expData, xlsxName + fileExtension)
    onClose()
  }

  const sendInstantMail = () => {
    setCompleteDashboardLoader(true)
    savedReportsService
      .sendInstantMail(clientCode, dashboardData.id)
      .then(() => {
        toast.success(t('analysis.export.instantMail'))
        onClose()
      })
      .catch((error) => {
        toast.error(error)
        onClose()
      })
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={dashboardData ? 'exportDashboard' : ''}>
      <DialogTitle onClose={onClose}>
        {dashboardData ? t('analysis.export.exportDashboardTitle') : t('analysis.export.exportReportTitle')}
      </DialogTitle>
      <DialogBody>
        <div className="flex justify-center px-2 pb-12">
          <p>{dashboardData ? t('analysis.export.choose') : t('analysis.export.exportTitle')}</p>
        </div>
        <div className="flex items-center justify-center">
          {data && (
            <Button className="m-2 xl:mb-8 flex items-center justify-center border-transparent" onClick={downloadXLSX}>
              {t('analysis.export.exportXLSX')}
            </Button>
          )}
          {!imageReport && data && <progress className="pure-material-progress-circular" />}
          {data ? (
            <Button
              className="m-2 xl:mb-8 flex items-center justify-center border-transparent"
              onClick={downloadPdf}
              disabled={!imageReport}
            >
              {t('analysis.export.exportPDF')}
            </Button>
          ) : (
            <>
              <Button
                disabled={
                  dashboardData &&
                  pages.filter((page: any) => page.index == currentPage)[0] &&
                  dashboardFilters.length !== pages.filter((page: any) => page.index == currentPage)[0].reports?.length
                }
                className="m-2 flex items-center justify-center border-transparent mb-8"
                onClick={downloadPdf}
              >
                <span className="pr-2">{t('analysis.export.currentPage')}</span>
                <IconSvg name="DOWNLOAD" color="WHITE" className="" />
              </Button>
              {currentPageLoader && <progress className="pure-material-progress-circular" />}

              <Button
                disabled={
                  dashboardData &&
                  pages.filter((page: any) => page.index == currentPage)[0] &&
                  dashboardFilters.length !== pages.filter((page: any) => page.index == currentPage)[0].reports?.length
                }
                className="m-2 flex items-center justify-center border-transparent mb-8"
                onClick={sendInstantMail}
              >
                <span className="pr-2">{t('analysis.export.completeDashboard')}</span>
                <IconSvg name="MAIL" color="WHITE" className="" />
              </Button>
              {completeDashboardLoader && <progress className="pure-material-progress-circular" />}
            </>
          )}
        </div>
        <div className="flex items-center justify-center">
          {dashboardData &&
            pages.filter((page: any) => page.index == currentPage)[0] &&
            dashboardFilters.length !== pages.filter((page: any) => page.index == currentPage)[0].reports?.length && (
              <progress className="pure-material-progress-circular" />
            )}
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default ExportationDialog

import { DisplayInformationDto } from 'core/api/models/displayInformationDto'
import { execute } from 'utils/api/api'
import { API } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'

const getClientDisplay = (clientCode: string): Promise<DisplayInformationDto> =>
  execute(generatePath(API.ANALYSIS.RIE_VIEW_CLIENT_DISPLAY, { clientCode }), 'GET', undefined, {
    'X-Authentication-Type': 'APIKey',
  })

export const clientService = {
  getClientDisplay,
}

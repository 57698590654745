import MenuItems from './MenuItems'
import { FilterContext } from '../filters/FilterContext'
import { useContext, useState } from 'react'

const Dropdown = ({ submenus, dropdown, depthLevel }: any) => {
  const { selected, onSelect } = useContext(FilterContext)
  const [selectedOption, setSelectedOption] = useState(null)

  depthLevel = depthLevel + 1
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : ''
  const onSelectClick = (event: any) => {
    setSelectedOption(event.currentTarget.getAttribute('data-value'))
    onSelect(event.currentTarget.getAttribute('data-value'), 1)
  }
  return (
    <ul
      style={{ marginTop: '7px' }}
      className={`absolute top-12 left-0 z-10 mt-1 border-2 border-basic-300 bg-basic-100 rounded-xl shadow-xl overflow-y-auto"
    dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}
    >
      {submenus.map((submenu: any, index: any) => (
        <MenuItems
          selectedOption={selectedOption}
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          onSelect={onSelect}
          onOptionClick={depthLevel === 2 && submenu.enable ? onSelectClick : undefined}
        />
      ))}
    </ul>
  )
}

export default Dropdown

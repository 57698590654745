import { FC } from 'react'
import { Link } from 'react-router-dom'
import AuthPageLayout from 'components/layouts/AuthPageLayout'
import Button from 'components/atoms/button/Button'
import { PATHS } from 'utils/constants/routes/Paths'
import { useTranslation } from 'react-i18next'

const PageNotFound: FC = () => {
  const { t } = useTranslation()

  return (
    <AuthPageLayout>
      <div className="mb-12 space-y-2 text-center text-basic-100">
        <p className="text-h2">{t('home.notFound.title')}</p>
        <p className="text-h4">{t('home.notFound.body')}</p>
      </div>
      <div className="flex justify-center">
        <Link to={PATHS.HOME}>
          <Button color="blue" rounded="lg">
            {t('home.notFound.button')}
          </Button>
        </Link>
      </div>
    </AuthPageLayout>
  )
}

export default PageNotFound

import { FC } from 'react'
import { useMutation } from 'react-query'
import { authService } from 'services/authService'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordData } from 'types/Auth'
import { NavLink } from 'react-router-dom'
import Button from 'components/atoms/button/Button'
import { PATHS } from 'utils/constants/routes/Paths'
import FormInput from 'components/atoms/input/FormInput'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import LoginPageLayout from 'components/layouts/LoginPageLayout'
import { COLORS } from 'assets/colors/colors'

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required('forms.errors.username').email('forms.errors.email'),
})

const ForgotPasswordView: FC = () => {
  const { t } = useTranslation()

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.AUTH.FORGOT_PASSWORD,
    mutationFn: authService.forgotPassword,
    onSuccess: () => {
      toast.success(t('auth.forgotPassword.success'))
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onSubmit = async (data: ForgotPasswordData) => mutate(data)

  return (
    <LoginPageLayout>
      <FormProvider {...form}>
        <form className="login-form" onSubmit={form.handleSubmit(onSubmit)}>
          <h4 style={{ fontWeight: 'bold', fontSize: '34px', textAlign: 'center' }}>{t('auth.login.login')}</h4>
          <label style={{ textTransform: 'uppercase', fontSize: '12px', margin: '0 0.75rem' }}>
            {t('forms.fields.username')}
          </label>
          <FormInput className="mt-2 mb-8" name="identifier" placeholder={t('forms.fields.username')} type="email" />
          <div className="flex justify-center flex-col m-3">
            <Button type="submit" style={{ backgroundColor: COLORS.deepCerise }}>
              {t('auth.forgotPassword.validate')}
            </Button>
            <NavLink to={PATHS.HOME} className="text-basic-100 text-c1 text-center">
              {t('common.cancel')}
            </NavLink>
          </div>
        </form>
      </FormProvider>
    </LoginPageLayout>
  )
}

export default ForgotPasswordView

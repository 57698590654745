import {
  benchmarkValue,
  calibratedUtilizationValue,
  countingMaxValue,
  monitoringValue,
  roomBookingValue,
} from './../utils/constants/graphs/graphsStructure'
import { execute } from 'utils/api/api'
import { API, serverPrefixes } from 'utils/constants/routes/ApiRoutes'
import { generatePath } from 'utils/routeUtils'
import { DomainsStructure } from 'types/DomainsStructure'
import { FilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import { optionAllValue, optionalOptionAllValue } from 'utils/constants/graphs/global'
import {
  ALL_COLLABORATIVE_SPACES,
  CAPACITY_TYPE_CATEGORY,
  CAPACITY_TYPE_DETAILED,
  CAPACITY_TYPE_GROUPED,
  FOCUS_ROOM,
  detailedOccupancyValue,
  MEETING_ROOM,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  WORKSTATION,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  heatmapValue,
} from 'utils/constants/graphs/graphsStructure'
import { fakeTopFlopData } from 'utils/constants/graphs/horizontalBarChart'
import { fakeStackedColumnChartData } from 'utils/constants/graphs/columnChart'
import { FiltersType } from 'types/SavedReportsTypes'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import { frequentationFakeData, heatMapFakeData } from 'utils/constants/graphs/heatmap'
import { fakeMonthlyData } from 'utils/constants/graphs/monthly'
import { fakeRestaurantData } from 'utils/constants/graphs/restaurant'
import { fakeUtilizationQualityData } from 'utils/constants/graphs/horizontalStackedBarChart'

export const getFiltersForTopFlop = (state: FilterState) => {
  const roomType = state.ROOM_TYPE.value
  const buildingCode = state.BUILDING.value
  const bookableValue =
    !state.BOOKABLE.value || state.BOOKABLE.value === ALL_COLLABORATIVE_SPACES
      ? optionalOptionAllValue
      : state.BOOKABLE.value

  return {
    graph: topFlopValue,
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    roomType: roomType,
    rangeType: state.QUARTER.value,

    // optional front side but must be sent
    capacityCategory: state.CAPACITY_SIZE.value || optionalOptionAllValue,

    // MEETING ROOM
    bookable: roomType === MEETING_ROOM.value ? bookableValue : undefined,

    // FOCUS ROOM
    open: roomType === FOCUS_ROOM.value ? state.OPEN_CLOSED.value || optionalOptionAllValue : undefined,
  }
}
export const getFiltersForUtilizationQuality = (state: FilterState) => {
  const roomType = state.ROOM_TYPE.value
  const buildingCode = state.BUILDING.value
  const bookableValue =
    !state.BOOKABLE.value || state.BOOKABLE.value === ALL_COLLABORATIVE_SPACES
      ? optionalOptionAllValue
      : state.BOOKABLE.value

  return {
    graph: utilizationQualityValue,
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    roomType: roomType,
    rangeType: state.QUARTER.value,

    // optional front side but must be sent
    capacityCategory: state.CAPACITY_SIZE.value || optionalOptionAllValue,

    // MEETING ROOM
    bookable: roomType === MEETING_ROOM.value ? bookableValue : undefined,

    // FOCUS ROOM
    open: roomType === FOCUS_ROOM.value ? state.OPEN_CLOSED.value || optionalOptionAllValue : undefined,
  }
}

const getCapacityValues = (state: FilterState) => {
  switch (state.ROOM_SIZE_TYPE.value) {
    case CAPACITY_TYPE_DETAILED: {
      return {
        capacity: state.ROOM_SIZE_DETAILED.value,
      }
    }

    case CAPACITY_TYPE_GROUPED: {
      return {
        capacityGrouped: state.ROOM_SIZE_GROUPED.value,
      }
    }

    case CAPACITY_TYPE_CATEGORY: {
      return {
        capacityCategory: state.CAPACITY_SIZE.value,
      }
    }
  }
}

export const getFiltersForStackedColumn = (state: FilterState) => {
  const roomType = state.ROOM_TYPE.value
  const buildingCode = state.BUILDING.value
  const bookableValue =
    !state.BOOKABLE.value || state.BOOKABLE.value === ALL_COLLABORATIVE_SPACES
      ? optionalOptionAllValue
      : state.BOOKABLE.value

  return {
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    roomType: roomType,
    rangeType: state.QUARTER.value,

    bookable: roomType === MEETING_ROOM.value ? bookableValue : undefined,
    open: roomType === FOCUS_ROOM.value ? state.OPEN_CLOSED.value || optionalOptionAllValue : undefined,

    capacityType: state.ROOM_SIZE_TYPE.value,
    ...getCapacityValues(state),
  }
}

export const getFiltersForDetailedOccupancy = (state: FilterState) => {
  const roomType = state.ROOM_TYPE.value
  const buildingCode = state.BUILDING.value
  const bookableValue =
    !state.BOOKABLE.value || state.BOOKABLE.value === ALL_COLLABORATIVE_SPACES
      ? optionalOptionAllValue
      : state.BOOKABLE.value
  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    businessUnit: state.BUSINESS_UNITS.value || optionAllValue,
    roomType,
    rangeType: state.QUARTER.value,
    ordinateAxis: state.DETAILED_OCCUPANCY.value,
    kpi: state.KPI.value,

    floorPath:
      state.DETAILED_OCCUPANCY.value == 'CALENDAR_DAY' || state.DETAILED_OCCUPANCY.value == 'WEEK_DAY'
        ? state.FLOOR.value
        : null,
    compartmentPath: state.COMPARTMENT.value,

    capacityCategory: state.CAPACITY_SIZE.value || optionalOptionAllValue,
    bookable: roomType === MEETING_ROOM.value ? bookableValue : undefined,
    open: roomType === FOCUS_ROOM.value ? state.OPEN_CLOSED.value || optionalOptionAllValue : undefined,
    nature: roomType === WORKSTATION.value ? state.OPEN_SPACE.value || optionalOptionAllValue : undefined,
    affectation: roomType === WORKSTATION.value ? state.DEDICATED_SHARED.value || optionalOptionAllValue : undefined,
    customAttribute: state.CUSTOM_ATTRIBUT_WKS_VALUE.value || state.CUSTOM_ATTRIBUT_COLLAB_VALUE.value,
  }
}

export const getFiltersForMonthly = (state: FilterState) => {
  const roomType = state.ROOM_TYPE.value
  const buildingCode = state.BUILDING.value
  const bookableValue =
    !state.BOOKABLE.value || state.BOOKABLE.value === ALL_COLLABORATIVE_SPACES
      ? optionalOptionAllValue
      : state.BOOKABLE.value

  return {
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    compartmentPath: state.COMPARTMENT.value,
    businessUnit: state.BUSINESS_UNITS.value || optionAllValue,
    roomType,
    rangeType: state.QUARTER.value,
    graph: state.GRAPH.value,

    capacityCategory: state.CAPACITY_SIZE.value || optionalOptionAllValue,
    bookable: roomType === MEETING_ROOM.value ? bookableValue : undefined,
    open: roomType === FOCUS_ROOM.value ? state.OPEN_CLOSED.value || optionalOptionAllValue : undefined,
    nature: roomType === WORKSTATION.value ? state.OPEN_SPACE.value || optionalOptionAllValue : undefined,
    affectation: roomType === WORKSTATION.value ? state.DEDICATED_SHARED.value || optionalOptionAllValue : undefined,
    customAttribute: state.CUSTOM_ATTRIBUT_WKS_VALUE.value || state.CUSTOM_ATTRIBUT_COLLAB_VALUE.value,
  }
}
export const getFiltersForRestaurant = (state: FilterState) => {
  return {
    graph: state.GRAPH.value,
    site: state.SITE.value,
    restaurantId: state.RESTAURANT.id,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
  }
}
export const getFiltersForSpaceAttendance = (state: FilterState) => {
  const buildingCode = state.BUILDING.value

  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    scopeType: state.SPACE_TYPE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    compartmentPath: state.COMPARTMENT.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
  }
}
export const getFiltersForDetailedOccupancyTable = (state: FilterState) => {
  const buildingCode = state.BUILDING.value

  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
    roomTypes: state.MONITORING_ROOM_TYPE.value,
  }
}
export const getFiltersForHeatmap = (state: FilterState) => {
  const buildingCode = state.BUILDING.value

  return {
    domainPath: state.SITE.value,
    buildingCode: buildingCode,
    floorPath: state.FLOOR.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
    compartmentPath: state.COMPARTMENT.value,
    roomType: '[ALL]',
  }
}
export const getFiltersForMonitoring = (state: FilterState) => {
  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
    roomTypes: state.MONITORING_ROOM_TYPE.value,
    spaceTypes: state.MONITORING_SCOPE.value,
    byBusinessUnit: state.MONITORING_ENTITY.value,
    byCustomAttributeCollab: state.CUSTOM_ATTRIBUT_COLLAB.value,
    byCustomAttributeWks: state.CUSTOM_ATTRIBUT_WKS.value,
  }
}
export const getFiltersForCalibratedUtilization = (state: FilterState) => {
  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
    roomTypes: ['WORKSTATION'],
    aggregates: state.MONITORING_SCOPE.value.includes('BUSINESS_UNIT')
      ? state.MONITORING_SCOPE.value
          .filter((el) => el !== 'BUSINESS_UNIT')
          .map((elt: string) => elt + '_AND_BUSINESS_UNIT')
      : state.MONITORING_SCOPE.value,
  }
}
export const getFiltersForRoomBooking = (state: FilterState) => {
  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    buildingCode: state.BUILDING.value,
    floorPath: state.FLOOR.value,
    roomType: state.ROOM_TYPE.value,
    compartmentPath: state.COMPARTMENT.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
  }
}
export const getFiltersForBenchmark = (state: FilterState) => {
  return {
    domainPath: state.SITE.value,
    buildingCode: state.BUILDING.value,
    roomType: state.ROOM_TYPE.value,
    rangeType: state.QUARTER.value,
    kpi: state.KPI.value,
  }
}
export const getFiltersForCountingMax = (state: FilterState) => {
  return {
    graph: state.GRAPH.value,
    domainPath: state.SITE.value,
    buildingCode: state.BUILDING.value,
    floorPath: state.FLOOR.value,
    compartmentPath: state.COMPARTMENT.value,
    roomTypes: state.MONITORING_ROOM_TYPE.value,
    rangeType: state.QUARTER.value,
  }
}

// isSaveFilters is a temporary argument, while no data are available on the serverside.
// We use it because we need empty data for when we generate a graph, but we need data for when we want to generate a
// SavedReport. When it's available : TODO : remove the isSaveFilters parameter
const getDataForGraph = (state: FilterState) => {
  switch (state.GRAPH.value) {
    case topFlopValue: {
      return {
        url: API.ANALYSIS[topFlopValue],
        data: getFiltersForTopFlop(state),
      }
    }

    case stackedColumnValue: {
      return {
        url: API.ANALYSIS[stackedColumnValue],
        data: getFiltersForStackedColumn(state),
      }
    }

    case detailedOccupancyValue: {
      return {
        url: API.ANALYSIS[detailedOccupancyValue],
        data: getFiltersForDetailedOccupancy(state),
      }
    }

    case monthlyValue: {
      return {
        url: API.ANALYSIS[monthlyValue],
        data: getFiltersForMonthly(state),
      }
    }
    case RIE: {
      return {
        url: API.ANALYSIS[RIE],
        data: getFiltersForRestaurant(state),
      }
    }

    case frequentationValue: {
      return {
        url: API.ANALYSIS[frequentationValue],
        data: getFiltersForRestaurant(state),
      }
    }
    case spaceAttendanceValue: {
      return {
        url: API.ANALYSIS[spaceAttendanceValue],
        data: getFiltersForSpaceAttendance(state),
      }
    }
    case utilizationQualityValue: {
      return {
        url: API.ANALYSIS[utilizationQualityValue],
        data: getFiltersForUtilizationQuality(state),
      }
    }
    case detailedOccupancyTableValue: {
      return {
        url: API.ANALYSIS[detailedOccupancyTableValue],
        data: getFiltersForDetailedOccupancyTable(state),
      }
    }
    case monitoringValue: {
      return {
        url: API.ANALYSIS[monitoringValue],
        data: getFiltersForMonitoring(state),
      }
    }
    case heatmapValue: {
      return {
        url: API.ANALYSIS[heatmapValue],
        data: getFiltersForHeatmap(state),
      }
    }
    case calibratedUtilizationValue: {
      return {
        url: API.ANALYSIS[calibratedUtilizationValue],
        data: getFiltersForCalibratedUtilization(state),
      }
    }
    case roomBookingValue: {
      return {
        url: API.ANALYSIS[roomBookingValue],
        data: getFiltersForRoomBooking(state),
      }
    }
    case benchmarkValue: {
      return {
        url: API.ANALYSIS[benchmarkValue],
        data: getFiltersForBenchmark(state),
      }
    }
    case countingMaxValue: {
      return {
        url: API.ANALYSIS[countingMaxValue],
        data: getFiltersForCountingMax(state),
      }
    }

    default: {
      throw new Error('Unknown graph value')
    }
  }
}

const getFiltersData = (clientCode: string): Promise<DomainsStructure> => {
  return execute(generatePath(API.ANALYSIS.FILTER, { clientCode }), 'GET')
}

const generateGraph = (clientCode: string, filterState: FilterState): Promise<any> => {
  const { url, data } = getDataForGraph(filterState)

  // If no base URL is specified for the graph endpoint, then it means that we're on the environment on which there are
  // no data and we want to render fake values to see how the graphs work
  // or if is in TODO
  if (!serverPrefixes.graph || !data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        switch (filterState.GRAPH.value) {
          case topFlopValue:
            resolve(fakeTopFlopData)
            break

          case stackedColumnValue:
            resolve(fakeStackedColumnChartData)
            return

          case detailedOccupancyValue:
            resolve(heatMapFakeData)
            return

          case monthlyValue:
            resolve(fakeMonthlyData)
            return
          case RIE:
            resolve(fakeRestaurantData)
            return
          case frequentationValue:
            resolve(frequentationFakeData)
            return
          case spaceAttendanceValue:
            resolve(frequentationFakeData)
            return
          case utilizationQualityValue:
            resolve(fakeUtilizationQualityData)
            return
        }
      }, 300)
    })
  }

  return execute(generatePath(url, { clientCode }), 'POST', data)
}

const getGraphDataFromSavedReport = (clientCode: string, filters: FiltersType) => {
  const apiUrl = API.ANALYSIS[filters.graph as GraphType]

  return execute(generatePath(apiUrl, { clientCode }), 'POST', filters)
}

const saveFilters = (clientCode: string, reportName: string, filters: FilterState): Promise<unknown> => {
  const body = {
    name: reportName,
    filters: { ...getDataForGraph(filters).data, graph: filters.GRAPH.value },
  }

  return execute(generatePath(API.ANALYSIS.SAVE, { clientCode }), 'POST', body)
}
const getMapForPath = (clientCode: string, path: string): Promise<any> => {
  return execute(generatePath(API.ANALYSIS.MAP, { clientCode, path }), 'GET')
}
const getLandingPage = (clientCode: string, body: any): Promise<any> => {
  return execute(generatePath(API.ANALYSIS.LANDING_PAGE, { clientCode }), 'POST', body)
}
const getRestaurantAttendance = (clientCode: string, body: any): Promise<any> => {
  return execute(generatePath(API.ANALYSIS.LANDING_PAGE_ATTENDANCE, { clientCode }), 'POST', body)
}

export const graphService = {
  getFiltersData,
  generateGraph,
  getGraphDataFromSavedReport,
  saveFilters,
  getMapForPath,
  getLandingPage,
  getRestaurantAttendance,
}

export const COLORS = {
  white: '#fff',
  black: '#000',
  deepCerise: '#D33087',
  deepCerise80: '#dc599f',
  deepCerise50: '#e998c3',
  deepCerise30: '#f2c1db',
  blue: '#1CB9D6',
  blue70: '#60cee2',
  blue50: '#8edceb',
  blue40: '#a4e3ef',
  blue30: '#bbeaf3',
  blue25: '#d2f1f7',
  darkGrey: '#2d2d3b',
  darkGrey80: '#575762',
  darkGrey50: '#96969d',
  darkGrey30: '#c0c0c4',
  lightGrey: '#EAEAEC',
  purple: '#945FA2',
  darkRed: '#871E56',
  green: '#40B573',
  orange: '#FF9237',
  red: '#F53838',
}

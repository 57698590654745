// GRAPHS
import { ReactComponent as IconTopFlop } from 'assets/graphs/top_flop.svg'
import { ReactComponent as IconStackedColumn } from 'assets/graphs/stacked_column.svg'
import { ReactComponent as IconHeatmap } from 'assets/graphs/heatmap.svg'
import { ReactComponent as IconMonthly } from 'assets/graphs/monthly.svg'
import { ReactComponent as IconMap } from 'assets/graphs/map.svg'
import { ReactComponent as IconXLSX } from 'assets/graphs/xlsx.svg'

// ICONS
import { ReactComponent as IconNotifications } from 'assets/icons/notifications.svg'
import { ReactComponent as IconSettings } from 'assets/icons/settings.svg'
import { ReactComponent as IconHelp } from 'assets/icons/help.svg'
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow_down.svg'
import { ReactComponent as IconArrowUp } from 'assets/icons/arrow_up.svg'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-circle-right.svg'
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-circle-left.svg'
import { ReactComponent as IconLogout } from 'assets/icons/logout.svg'
import { ReactComponent as IconSelectedClient } from 'assets/icons/selected_client.svg'
import { ReactComponent as IconUpdate } from 'assets/icons/update.svg'
import { ReactComponent as IconDashboard } from 'assets/icons/dashboard.svg'
import { ReactComponent as IconClose } from 'assets/icons/close.svg'
import { ReactComponent as IconInfo } from 'assets/icons/info.svg'
import { ReactComponent as IconArrowSelect } from 'assets/icons/select_arrow.svg'
import { ReactComponent as IconFavorite } from 'assets/icons/favorite.svg'
import { ReactComponent as IconFavoriteFull } from 'assets/icons/favorite_full.svg'
import { ReactComponent as IconShare } from 'assets/icons/share.svg'
import { ReactComponent as IconWelcome } from 'assets/icons/welcome.svg'
import { ReactComponent as IconReverse } from 'assets/icons/reverse.svg'
import { ReactComponent as IconDuplicate } from 'assets/icons/duplicate.svg'
import { ReactComponent as IconHome } from 'assets/icons/home.svg'
import { ReactComponent as IconMove } from 'assets/icons/move.svg'
import { ReactComponent as IconSearch } from 'assets/icons/search.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import { ReactComponent as TrashCan } from 'assets/icons/trash-alt-solid.svg'
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg'
import { ReactComponent as IconDesktop } from 'assets/icons/desktop.svg'
import { ReactComponent as IconMeeting } from 'assets/icons/meeting.svg'
import { ReactComponent as IconMeetingRoom } from 'assets/icons/meeting_room_icon.svg'
import { ReactComponent as IconFocusRoom } from 'assets/icons/focus_room_icon.svg'
import { ReactComponent as IconWorkstation } from 'assets/icons/workstation_icon.svg'
import { ReactComponent as IconRestaurant } from 'assets/icons/restaurant.svg'
import { ReactComponent as IconApartment } from 'assets/icons/apartment.svg'
import { ReactComponent as IconRightChevron } from 'assets/icons/chevron_right.svg'
import { ReactComponent as IconSeat } from 'assets/icons/seat.svg'
import { ReactComponent as IconDesk } from 'assets/icons/desk.svg'
import { ReactComponent as IconSend } from 'assets/icons/send.svg'
import { ReactComponent as IconSupport } from 'assets/icons/support.svg'
import { ReactComponent as IconMail } from 'assets/icons/mail.svg'
import { ReactComponent as IconExport } from 'assets/icons/export.svg'
import { ReactComponent as IconSave } from 'assets/icons/save.svg'
import { ReactComponent as IconGenerate } from 'assets/icons/generate.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconAdd } from 'assets/icons/add.svg'
import { ReactComponent as IconObjectif } from 'assets/icons/objectif.svg'
import { ReactComponent as IconScope } from 'assets/icons/scope.svg'
import { ReactComponent as IconFilledInfo } from 'assets/icons/infoGrey.svg'
import { ReactComponent as IconWhiteRightArrow } from 'assets/icons/arrow_right.svg'
import { ReactComponent as IconPlay } from 'assets/icons/play.svg'
import { ReactComponent as IconPause } from 'assets/icons/pause.svg'
import { ReactComponent as IconCandleStickChart } from 'assets/icons/candlestickChart.svg'
import { ReactComponent as IconLeaderBoard } from 'assets/icons/leaderboard.svg'
import { ReactComponent as IconDownload } from 'assets/icons/download.svg'
import { ReactComponent as IconPremium } from 'assets/icons/Premium.svg'
import { ReactComponent as NoDataIcon } from 'assets/icons/infoIcon.svg'
import { ReactComponent as ColorsIcon } from 'assets/icons/colors.svg'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { ReactComponent as LanguageIcon } from 'assets/icons/language.svg'
import { ReactComponent as ArrowBack } from 'assets/icons/arrow_back.svg'
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg'
import { ReactComponent as DoneIcon } from 'assets/icons/done.svg'
import { ReactComponent as CheckCircle } from 'assets/icons/check_circle.svg'
import { ReactComponent as Dirty } from 'assets/icons/dirty.svg'
import { ReactComponent as NoEntry } from 'assets/icons/no_entry.svg'
import { ReactComponent as Moon } from 'assets/icons/moon.svg'
import { ReactComponent as Gift } from 'assets/icons/gift.svg'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
  calibratedUtilizationValue,
  roomBookingValue,
  countingMaxValue,
} from 'utils/constants/graphs/graphsStructure'

export const ICON_SVG_MAP = {
  // GRAPHS
  [topFlopValue]: <IconTopFlop />,
  [stackedColumnValue]: <IconStackedColumn />,
  [detailedOccupancyValue]: <IconHeatmap />,
  [monthlyValue]: <IconMonthly />,
  [RIE]: <IconMonthly />,
  [frequentationValue]: <IconHeatmap />,
  [spaceAttendanceValue]: <IconHeatmap />,
  [utilizationQualityValue]: <IconTopFlop />,
  [detailedOccupancyTableValue]: <IconXLSX />,
  [monitoringValue]: <IconXLSX />,
  [heatmapValue]: <IconMap />,
  [calibratedUtilizationValue]: <IconXLSX />,
  [roomBookingValue]: <IconTopFlop />,
  [countingMaxValue]: <IconXLSX />,

  // ICONS
  ALERT: <IconNotifications />,
  SETTINGS: <IconSettings />,
  HELP: <IconHelp />,
  ARROW_DOWN: <IconArrowDown />,
  ARROW_UP: <IconArrowUp />,
  ARROW_RIGHT: <IconArrowRight />,
  ARROW_LEFT: <IconArrowLeft />,
  LOGOUT: <IconLogout />,
  SELECTED_CLIENT: <IconSelectedClient />,
  UPDATE: <IconUpdate />,
  DASHBOARD: <IconDashboard />,
  CLOSE: <IconClose />,
  INFO: <IconInfo />,
  ARROW_SELECT: <IconArrowSelect />,
  FAVORITE: <IconFavorite />,
  FAVORITE_FULL: <IconFavoriteFull />,
  SHARE: <IconShare />,
  WELCOME: <IconWelcome />,
  REVERSE: <IconReverse />,
  DUPLICATE: <IconDuplicate />,
  HOME: <IconHome />,
  MOVE: <IconMove />,
  SEARCH: <IconSearch />,
  EDIT: <IconEdit />,
  TRASH_CAN: <TrashCan />,
  PLUS: <IconPlus />,
  DESKTOP: <IconDesktop />,
  RESTAURANT: <IconRestaurant />,
  MEETING: <IconMeeting />,
  MEETING_ROOM: <IconMeetingRoom />,
  FOCUS_ROOM: <IconFocusRoom />,
  WORKSTATION: <IconWorkstation />,
  APARTMENT: <IconApartment />,
  CHEVRON_RIGHT: <IconRightChevron />,
  SEAT: <IconSeat />,
  DESK: <IconDesk />,
  SEND: <IconSend />,
  SUPPORT: <IconSupport />,
  MAIL: <IconMail />,
  EXPORT: <IconExport />,
  SAVE: <IconSave />,
  GENERATE: <IconGenerate />,
  DELETE: <IconDelete />,
  ADD: <IconAdd />,
  OBJECTIF: <IconObjectif />,
  SCOPE: <IconScope />,
  FILLED_INFO: <IconFilledInfo />,
  WHITE_ARROW_RIGHT: <IconWhiteRightArrow />,
  PLAY: <IconPlay />,
  PAUSE: <IconPause />,
  CANDLE_STICK: <IconCandleStickChart />,
  LEADER_BOARD: <IconLeaderBoard />,
  DOWNLOAD: <IconDownload />,
  PREMIUM: <IconPremium />,
  NO_DATA: <NoDataIcon />,
  COLORS: <ColorsIcon />,
  LOCK: <LockIcon />,
  LANGUAGE: <LanguageIcon />,
  ARROW_BACK: <ArrowBack />,
  UNDO: <UndoIcon />,
  DONE: <DoneIcon />,
  CHECK_CIRCLE: <CheckCircle />,
  DIRTY: <Dirty />,
  NO_ENTRY: <NoEntry />,
  MOON: <Moon />,
  GIFT: <Gift />,
}

import { FC, MouseEvent } from 'react'
import IconSvg from '../../atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import FavoriteItemFilters from './FavoriteItemFilters'
import { FiltersType } from 'types/SavedReportsTypes'
import { graphsStructure } from 'utils/constants/graphs/graphsStructure'
import { Draggable } from 'react-beautiful-dnd'

type Props = {
  id: number
  index: number
  title: string
  filters: FiltersType
  isDashboard: boolean
  usedInDashboard?: boolean
  active?: boolean
  className?: string
  onClick: (id: number, title: string) => void
}

const FavoriteItem: FC<Props> = (props) => {
  const { id, index, title, filters, isDashboard, active = false, usedInDashboard, className = '', onClick } = props
  const { t } = useTranslation()
  const onIconClick = (event: MouseEvent<HTMLSpanElement>) => {
    // We stop the propagation of the event because if we don't, the raw will be clicked as well and be selected
    event.stopPropagation()

    // TODO : once the webservice is done, register this report as the default one
  }

  const graphLabel = graphsStructure.graph[filters.graph as keyof typeof graphsStructure.graph]

  return (
    <div className="my-2 rounded-lg overflow-hidden">
      <div
        className={`p-4 shadow-xl ${active ? 'bg-primary' : 'bg-basic-100 cursor-pointer'} ${className}`}
        onClick={() => onClick(id, title)}
      >
        <div className="flex justify-between">
          <div>
            {filters && (
              <p className={`text-c1 ${active ? 'text-basic-100' : 'text-basic-400'}`}>
                {t(graphLabel) || t('savedReports.unknown')}
              </p>
            )}
          </div>
          <div className="flex items-center">
            {isDashboard && (
              <IconSvg
                className="ml-3 cursor-not-allowed"
                color={active ? 'WHITE' : 'BLACK'}
                name="HOME"
                onClick={onIconClick}
              />
            )}
            {usedInDashboard && <IconSvg className="ml-3" color={'GREY'} name="DASHBOARD" />}
            <IconSvg className="ml-3" name="MOVE" color="GREY" />
          </div>
        </div>
        <p
          className={`mt-1 text-b2 2xl:text-b1 font-bold break-words max-w-full ${
            active ? 'text-basic-100' : 'text-basic-600'
          }`}
          title={title}
        >
          {title}
        </p>
      </div>
      {active && <FavoriteItemFilters filters={filters} />}
    </div>
  )
}

export default FavoriteItem

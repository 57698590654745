import { FC } from 'react'

type Props = {
  className?: string
  overflow?: boolean
}

const PageLayout: FC<Props> = (props) => {
  const { className = '', overflow = true, children } = props

  return (
    <div className={`flex flex-1 ${overflow ? 'overflow-y-auto' : 'overflow-hidden'}`}>
      <div className={`h-full max-h-full w-full ${className}`}>{children}</div>
    </div>
  )
}

export default PageLayout

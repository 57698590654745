import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory, useLocation } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import { client, removeHeaderToken, setHeaderToken } from '../http/client'
import { AuthContext } from './AuthContext'
import { fetchNewToken } from './fetch-new-token'
import { TContextValue } from './types'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { useDeleteStorageAndCookies } from 'utils/hooks/useDeleteStorageAndCookies'
import { AxiosError } from 'axios'
import { TokenResponse } from 'types/Auth'
import { useQueryParams } from 'hooks/useQueryParams'

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token'])
  const [accessToken, setAccessToken] = useState<string | null>(cookies.access_token)
  const [refreshToken, setRefreshToken] = useState<string | null>(cookies.refresh_token)
  const history = useHistory()
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const codeInParams = queryParams.get('code')
  const stateInParams = queryParams.get('state')
  const { apiKey } = useQueryParams()
  const shouldNotRedirectToHome =
    apiKey || accessToken || pathname.includes(PATHS.AUTH.RESET_PASSWORD) || codeInParams || stateInParams
  const { clear } = useDeleteStorageAndCookies()
  const contextValue: TContextValue = useMemo(
    () => ({
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
    }),
    [accessToken, refreshToken],
  )

  const refreshAuth = async (failedRequest: AxiosError): Promise<AxiosError | TokenResponse> => {
    if (!refreshToken) {
      return Promise.reject(failedRequest)
    }

    if (failedRequest.response?.status === 401 && failedRequest.request.responseURL?.indexOf('token') === -1) {
      const newToken = await fetchNewToken(refreshToken)

      if (newToken) {
        failedRequest.response.config.headers.Authorization = `Bearer ${newToken.access_token}`
        setAccessToken(newToken.access_token)
        setRefreshToken(newToken.refresh_token)

        return Promise.resolve(newToken)
      } else {
        clear()
        history.push(PATHS.HOME)
        return Promise.reject(failedRequest)
      }
    }
    return Promise.reject(failedRequest)
  }

  useEffect(() => {
    const responseInterceptorId = createAuthRefreshInterceptor(client, refreshAuth, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: true,
    })

    // clear the interceptor when accessToken or refreshToken changes
    return () => client.interceptors.response.eject(responseInterceptorId)
  }, [refreshToken])

  useEffect(() => {
    if (accessToken) {
      setHeaderToken(accessToken)
      setCookie('access_token', accessToken, {
        path: PATHS.HOME,
        secure: true,
        sameSite: 'none',
      })
    } else if (!shouldNotRedirectToHome) {
      removeHeaderToken()
      removeCookie('access_token', { path: PATHS.HOME })
      history.push(PATHS.HOME)
    }

    if (refreshToken) {
      setCookie('refresh_token', refreshToken, {
        path: PATHS.HOME,
        secure: true,
        sameSite: 'none',
      })
    } else if (!refreshToken) {
      removeCookie('refresh_token', { path: PATHS.HOME })
    }
  }, [accessToken, refreshToken])

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

//@ts-nocheck
import { FC } from 'react'
import Dialog from '../../../atoms/dialog/Dialog'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useMutation } from 'react-query'
import { savedReportsService } from 'services/savedReportsService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import { generatePath } from 'utils/routeUtils'
import { PagesType } from 'types/DashboardType'

type Props = {
  open: boolean
  reportID: number
  onClose: () => void
  editedPage: PagesType
  editedPosition: number
  dashboardName: string
}
type Params = {
  id: string
}
const ConfirmationToAddDialog: FC<Props> = (props) => {
  const { open, onClose, reportID, editedPage, editedPosition, dashboardName } = props
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const { id } = useParams<Params>()
  const history = useHistory()

  const removeObjectWithId = (arr, id) => {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id)

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1)
    }

    return arr
  }
  if (editedPage.reports && editedPage.reports.find((page) => page.position == editedPosition + 1)) {
    editedPage.reports.find((page) => page.position == editedPosition + 1).id = reportID.id
    removeObjectWithId(editedPage.reports, -1)
  } else {
    editedPage.reports = [{ position: editedPosition + 1, id: reportID.id }]
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (clientCode: string) => savedReportsService.editDashboard(clientCode, dashboardName, id, editedPage),
    onSuccess: (resData, clientCode) => {
      toast.success(t('savedReports.singleReport.add.success', { name: reportID.name }))
      onClose()
      savedReportsService.getDashboard(clientCode, id).then((resData) => {
        history.push({
          pathname: generatePath(PATHS.SAVED_REPORTS.DASHBOARD_ID, {
            url: PATHS.SAVED_REPORTS.HOME,
            id: id.toString(),
          }),
          state: { modifiedDashboard: resData },
        })
      })
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onConfirmationButtonClick = () => {
    if (clientCode) {
      mutate(clientCode)
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <p>{t('common.confirmation')}</p>
      </DialogTitle>
      <DialogBody>
        <p>{t('dashboard.editDashboard', { name: reportID.name })}</p>
        <div className="mt-8 flex justify-end space-x-4">
          <Button onClick={onClose} disabled={isLoading}>
            {t('common.cancel')}
          </Button>
          <Button color="red" onClick={onConfirmationButtonClick} disabled={isLoading}>
            {t('common.confirm')}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default ConfirmationToAddDialog

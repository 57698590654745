import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { rieService } from 'services/rieService'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { PATHS } from 'utils/constants/routes/Paths'
import RieTable from '../../components/molecules/tables/RieTable'
import { useMemo, useState } from 'react'
import { getRieInfos } from 'utils/helpers/rieView'
import PageLayout from 'components/layouts/PageLayout'
import { Domain } from 'utils/api/types'
import { format } from 'date-fns'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import { clientService } from 'services/clientService'
import { useQueryParams } from 'hooks/useQueryParams'

const RieView = (): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const [dateLastRefresh, setDateLastRefresh] = useState<Date>()
  const formattedDateLastRefresh = useMemo(() => {
    if (dateLastRefresh?.getDate() === new Date().getDate()) {
      return dateLastRefresh && format(dateLastRefresh, "HH'h'mm")
    }

    return dateLastRefresh && format(dateLastRefresh, "iii do MMM y, HH'h'mm")
  }, [dateLastRefresh])
  const { apiKey, client, path } = useQueryParams()

  const { data: domain, isLoading: isDomainLoading } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DOMAIN(client, path),
    queryFn: () => rieService.getRestaurantDomain(client, path),
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!client,
    cacheTime: Infinity,
    refetchInterval: 60000,
  })

  const { data: desksInfos, isLoading: isDesksInfosLoading } = useQuery({
    queryFn: () => rieService.getRestaurantInfos(client, path),
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DESKS(client, path),
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!client,
    cacheTime: Infinity,
    refetchInterval: 60000,
  })

  const { data: clientDisplay } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.RIE_VIEW_CLIENT_DISPLAY(client),
    queryFn: () => clientService.getClientDisplay(client),
    onError: () => toast.error(t('api.unknown')),
    enabled: !!client,
    cacheTime: 600000,
  })

  const localizationInfos = useMemo(
    () => domain && desksInfos && getRieInfos(domain as Domain, desksInfos),
    [domain, desksInfos],
  )

  if (!(client && apiKey && path)) {
    history.push(PATHS.HOME)
  }

  if (isDomainLoading || isDesksInfosLoading || !domain) {
    return <LoaderComponent />
  }

  return (
    <PageLayout className="bg-basic flex flex-col gap-6 p-4 lg:p-12">
      <header className="flex gap-4 w-full justify-between flex-wrap items-center space-between">
        {domain.name && (
          <h1 className="text-h1 text-white font-semibold bg-primary px-2 flex items-center rounded-md justify-start flex-grow-0">
            {domain.name}
          </h1>
        )}
        <img
          src={clientDisplay?.logo ? `data:image/png;base64,${clientDisplay.logo}` : '/images/logo_white.png'}
          alt={t('auth.logo')}
          style={{ height: '70px' }}
        />
        {formattedDateLastRefresh && (
          <h2 className="text-white text-h4 flex items-center justify-end flex-grow-0">
            {t('realTime.rieView.lastRefresh', {
              hour: formattedDateLastRefresh,
            })}
          </h2>
        )}
      </header>
      <section>
        <h2 className="bg-primary text-white text-h2 mb-3 px-2 inline-flex items-center rounded-md">
          {t('realTime.rieView.restaurants')}
        </h2>
        <RieTable infos={localizationInfos ?? []} size="lg" />
      </section>
    </PageLayout>
  )
}

export default RieView

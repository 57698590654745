import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'

import { toast } from 'react-toastify'

import useCurrentUser from 'utils/hooks/useCurrentUser'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import DashboardList from '../../dashboardList/dashboardList'

import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { DashboardType, DashboardTypeList } from 'types/DashboardType'
import { savedReportsService } from 'services/savedReportsService'

type Props = {
  open: boolean
  reportID: number
  onClose: () => void
}

const ExistingDashboardDialog: FC<Props> = ({ onClose, open, reportID }: Props) => {
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const onCancelClick = () => onClose()
  const [selectedDashboard, setSelectedDashboard] = useState<DashboardType>()

  const { data, isLoading } = useQuery<DashboardTypeList>({
    queryKey: QUERY_KEYS.DASHBOARDS.GET_ALL_DASHBOARDS(clientCode),
    queryFn: () => savedReportsService.getAllDashboards(clientCode),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.DASHBOARDS.ADD_TO_EXISTING(clientCode, reportID),
    mutationFn: (dashboardId: number) => savedReportsService.addToExistingDashboard(clientCode, dashboardId, reportID),
    onSuccess: async () => {
      toast.success(t('savedReports.singleReport.dashboard.existingDashboardSuccess'))
      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
      onClose()
    },
  })

  const onItemClick = (dashboardId: number) => {
    setSelectedDashboard(data?.result.find((dashboard) => dashboard.id === dashboardId))
  }

  return (
    <>
      <Dialog isOpen={open} onClose={onClose}>
        <DialogTitle>{t('savedReports.singleReport.dashboard.existingDashboardTitle')}</DialogTitle>
        <DialogBody>
          <div className="flex justify-center">
            <DashboardList
              items={data?.result}
              isLoading={isLoading}
              onItemClick={onItemClick}
              selectedItem={selectedDashboard && selectedDashboard.id}
            />
          </div>
          <div className="mt-8 flex justify-end space-x-4">
            <Button color="red" onClick={onCancelClick}>
              {t('common.cancel')}
            </Button>
            <Button
              disabled={!selectedDashboard}
              type="submit"
              onClick={() => selectedDashboard && mutate(selectedDashboard?.id)}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default ExistingDashboardDialog

import { useQueryParams } from 'hooks/useQueryParams'
import RealTimeTable from 'components/molecules/tables/RealTimeTable'
import RieTable from 'components/molecules/tables/RieTable'
import BuildingTable from 'components/molecules/tables/BuildingTable'
import IconSvg, { iconColors } from 'components/atoms/icons/IconSvg'
import Button from 'components/atoms/button/Button'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { PATHS } from 'utils/constants/routes/Paths'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { toast } from 'react-toastify'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import { realTimeService } from 'services/realTime'
import PageLayout from 'components/layouts/PageLayout'
import {
  filterBuildings,
  findBuildingByPath,
  getBuildingSensor,
  getCapacity,
  getOverviewInfo,
} from 'utils/LocalizationUtils'
import {
  LocalizationInformationDto,
  LocalizationInformationDtoRoomClassificationEnum,
  LocalizationInformationDtoTypeEnum,
} from 'core/api/models/localizationInformationDto'
import { AvailableInfo, FloorInfo, RestaurantAvailableInfo } from 'types/RieView'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import { Domain, Localization } from 'utils/api/types'
import { getRieInfos, getRieInfosForBuilding } from 'utils/helpers/rieView'
import { clientService } from 'services/clientService'

const OverView = (): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const [dateLastRefresh, setDateLastRefresh] = useState<Date>()

  const formattedDateLastRefresh = useMemo(() => {
    if (dateLastRefresh?.getDate() === new Date().getDate()) {
      return dateLastRefresh && format(dateLastRefresh, "HH'h'mm")
    }

    return dateLastRefresh && format(dateLastRefresh, "iii do MMM y, HH'h'mm")
  }, [dateLastRefresh])
  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const tableWrapperStyle = useMemo(() => {
    if (tableWrapperRef.current) {
      return {
        maxHeight: `calc(100vh - ${tableWrapperRef.current.getBoundingClientRect().top}px)`,
      }
    }

    return {}
  }, [tableWrapperRef.current])
  const [floor, setFloor] = useState<{
    buildingName?: string
    roomType: LocalizationInformationDtoRoomClassificationEnum
    floorInfos?: FloorInfo
    space: AvailableInfo | RestaurantAvailableInfo
    filter?: LocalizationInformationDtoRoomClassificationEnum
  } | null>(null)
  const { apiKey, client, path } = useQueryParams()
  const { data: domain, isLoading: isDomainLoading } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DOMAIN(client, path),
    queryFn: () => {
      return realTimeService.getDomain(client, path, apiKey)
    },
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!(client && path),
    cacheTime: Infinity,
    refetchInterval: 60000,
  })

  const { data: desksInfos, isLoading: isDesksInfosLoading } = useQuery({
    queryFn: () => {
      return realTimeService.getRealTimeInfos(client, path, apiKey)
    },
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DESKS(client, path),
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!(client && path),
    cacheTime: Infinity,
    refetchInterval: 60000,
  })
  const { data: clientDisplay } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.RIE_VIEW_CLIENT_DISPLAY(client),
    queryFn: () => clientService.getClientDisplay(client),
    onError: () => toast.error(t('api.unknown')),
    enabled: !!client,
    cacheTime: 600000,
  })

  // according to the path, top level can be domain or building
  const isTopLevelDomain = domain?.type === LocalizationInformationDtoTypeEnum.Domain
  const selectedBuilding: LocalizationInformationDto | undefined = isTopLevelDomain ? undefined : domain

  const infos = useMemo(() => {
    if (!(domain?.sub_localizations && desksInfos?.length && desksInfos?.length > 0)) {
      return []
    }
    if (isTopLevelDomain) {
      return filterBuildings(domain.sub_localizations as Localization[])
        .map((b) => getOverviewInfo(domain.sub_localizations as Localization[], desksInfos, b?.name))
        .flat()
        .sort((a, b) => a.sortValue.localeCompare(b.sortValue))
    }

    // top level is a building
    return getOverviewInfo([domain] as Localization[], desksInfos, selectedBuilding?.name)
  }, [domain, desksInfos, path])

  const restaurantInfos = useMemo(
    () =>
      domain && desksInfos && isTopLevelDomain
        ? getRieInfos(domain as Domain, desksInfos)
        : selectedBuilding &&
          selectedBuilding?.name &&
          getRieInfosForBuilding(domain as Domain, desksInfos, selectedBuilding.name),
    [domain, desksInfos],
  )

  const buildingInfos: undefined | { name?: string; capacity?: number; sensor?: SensorStatusDto }[] = useMemo(() => {
    if (!(domain && desksInfos?.length && path)) {
      return []
    }

    const buildings =
      isTopLevelDomain && domain.sub_localizations?.length
        ? filterBuildings(domain.sub_localizations as Localization[])
        : [findBuildingByPath([domain] as Localization[], path)]

    if (!buildings.filter((b): b is Localization => !!b).length) {
      return []
    }

    return buildings
      .filter((b): b is Localization => !!b)
      .map((building) => ({
        name: building.name,
        sensor: getBuildingSensor(building, desksInfos),
        capacity: getCapacity(building),
      }))
  }, [domain, desksInfos, path])

  if (!(client && apiKey && path)) {
    history.push(PATHS.HOME)
  }

  if (isDomainLoading || isDesksInfosLoading || !domain) {
    return <LoaderComponent />
  }

  return (
    <PageLayout>
      <header className="bg-basic p-4 flex gap-4 w-full justify-between flex-wrap items-center space-between">
        {domain.name && (
          <h1 className="text-h1 text-white font-semibold bg-primary px-2 flex items-center rounded-md justify-start flex-grow-0">
            {domain.name}
          </h1>
        )}
        <img
          src={clientDisplay?.logo ? `data:image/png;base64,${clientDisplay.logo}` : '/images/logo_white.png'}
          alt={t('auth.logo')}
          style={{ height: '70px' }}
        />
        {formattedDateLastRefresh && (
          <h2 className="text-basic-100 text-h3 flex items-center flex-grow-0">
            {t('realTime.rieView.lastRefresh', {
              hour: formattedDateLastRefresh,
            })}
          </h2>
        )}
      </header>
      <div className="flex flex-col m-4" style={{ columnGap: '24px' }}>
        {floor && (
          <Button size="sm" className="w-min -mb-2" onClick={() => setFloor(null)}>
            <IconSvg name="ARROW_BACK" color={iconColors.GREY100} />
          </Button>
        )}

        <br />

        <div className="flex items-start gap-6 flex-wrap" ref={tableWrapperRef} style={tableWrapperStyle}>
          {isTopLevelDomain &&
            domain.sub_localizations?.map((sub) => (
              <>
                <div key={sub.name} className="mb-2 ">
                  {domain && floor && sub.name && floor?.buildingName === sub.name && (
                    <header>
                      <h2 className="font-semibold text-lg">
                        {t(`overview.${sub.name ? 'locationTitle' : 'noBuildingLocation'}`, {
                          site: domain.name,
                          building: sub?.name,
                        })}
                      </h2>
                      {floor?.buildingName === sub.name && (
                        <h3 className="font-semibold text-md">
                          {t('overviewDetails.detailsFloor', {
                            floor: floor?.floorInfos?.floor,
                            typeRoom: t(`overview.${floor?.roomType}`),
                          })}
                        </h3>
                      )}
                    </header>
                  )}
                  <h2 className="font-semibold text-lg">{sub.name}</h2>
                  {buildingInfos.find((b) => b.name === sub.name) && (
                    <BuildingTable {...buildingInfos.find((b) => b.name === sub.name)} />
                  )}
                  <br />
                  {!(isDesksInfosLoading || isDomainLoading) && !!domain && !!buildingInfos && (
                    <RealTimeTable
                      domain={domain}
                      maxHeight={tableWrapperStyle.maxHeight}
                      desksInfos={
                        desksInfos?.length && sub?.path
                          ? desksInfos
                              .filter((i): i is { path: string } => !!i.path)
                              .filter((i) => i.path.includes(sub.path as string))
                          : []
                      }
                      infos={infos?.filter((b) => b.buildingName === sub.name)}
                      buildingName={buildingInfos.find((b) => b.name === sub.name)?.name}
                      floor={floor?.buildingName === sub.name ? floor : undefined}
                      onSetFloor={setFloor}
                    />
                  )}
                  <br />
                  <hr className="my-2" />
                </div>
              </>
            ))}
          {!isTopLevelDomain && selectedBuilding && (
            <div className="mb-2 ">
              {domain && floor && domain.name && buildingInfos?.length && (
                <header>
                  <h2 className="font-semibold text-lg">
                    {t(`overview.${selectedBuilding?.name ? 'locationTitle' : 'noBuildingLocation'}`, {
                      site: domain.name,
                      building: selectedBuilding?.name,
                    })}
                  </h2>
                  <h3 className="font-semibold text-md">
                    {t('overviewDetails.detailsFloor', {
                      floor: floor?.floorInfos?.floor,
                      typeRoom: t(`overview.${floor?.roomType}`),
                    })}
                  </h3>
                </header>
              )}
              <h2 className="font-semibold text-lg">{selectedBuilding?.name}</h2>
              {buildingInfos.find((b) => b.name === selectedBuilding?.name) && (
                <BuildingTable {...buildingInfos.find((b) => b.name === selectedBuilding?.name)} />
              )}
              <br />
              {!(isDesksInfosLoading || isDomainLoading) && !!domain && !!buildingInfos && (
                <RealTimeTable
                  domain={domain}
                  maxHeight={tableWrapperStyle.maxHeight}
                  desksInfos={
                    desksInfos?.length && selectedBuilding?.path
                      ? desksInfos
                          .filter((i): i is { path: string } => !!i.path)
                          .filter((i) => i.path.includes(selectedBuilding.path as string))
                      : []
                  }
                  infos={infos?.filter((b) => b.buildingName === selectedBuilding?.name)}
                  buildingName={buildingInfos.find((b) => b.name === selectedBuilding?.name)?.name}
                  floor={floor}
                  onSetFloor={setFloor}
                />
              )}
              <br />
              <hr className="my-2" />
            </div>
          )}
          {!(isDesksInfosLoading || isDomainLoading) && !!restaurantInfos && !!restaurantInfos?.length && (
            <RieTable infos={restaurantInfos} maxHeight={tableWrapperStyle.maxHeight} mode="light" size="sm" />
          )}
          <br />
        </div>
      </div>
    </PageLayout>
  )
}

export default OverView

import { FC } from 'react'

const chartLayoutWidths = {
  full: 'w-full',
  big: 'w-2/3',
  half: 'w-1/2',
}

type Props = {
  className?: string
  fullH?: boolean
  width?: keyof typeof chartLayoutWidths
}

const ChartLayout: FC<Props> = (props) => {
  const { className = '', fullH = false, width = 'half', children } = props

  return <div className={`${fullH ? 'h-full' : 'h-1/2'} ${chartLayoutWidths[width]} ${className}`}>{children}</div>
}

export default ChartLayout

import {
  getFiltersForCalibratedUtilization,
  getFiltersForCountingMax,
  getFiltersForMonitoring,
  getFiltersForMonthly,
  getFiltersForRoomBooking,
  getFiltersForSpaceAttendance,
  getFiltersForUtilizationQuality,
} from './../services/graphService'
import { FiltersType } from 'types/SavedReportsTypes'
import { FilterState, initialFilterState } from 'components/molecules/filters/required/GraphFiltersContext'
import { optionalOptionAllValue } from './constants/graphs/global'
import {
  calibratedUtilizationValue,
  countingMaxValue,
  graphsStructure,
  monitoringValue,
  roomBookingValue,
  utilizationQualityValue,
} from 'utils/constants/graphs/graphsStructure'
import { optionAllValue } from 'utils/constants/graphs/global'
import i18next from 'i18next'

import deepmerge from 'deepmerge'
import {
  ALL_COLLABORATIVE_SPACES,
  CAPACITY_TYPE_CATEGORY,
  CAPACITY_TYPE_DETAILED,
  CAPACITY_TYPE_GROUPED,
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  frequentationValue,
  RIE,
  detailedOccupancyTableValue,
  spaceAttendanceValue,
} from './constants/graphs/graphsStructure'
import {
  getFiltersForDetailedOccupancy,
  getFiltersForStackedColumn,
  getFiltersForTopFlop,
  getFiltersForRestaurant,
  getFiltersForDetailedOccupancyTable,
} from 'services/graphService'

export const getFilterStateFromFiltersWS = (filters?: FiltersType): FilterState => {
  if (!filters) {
    return initialFilterState
  }

  const filterState: FilterState = deepmerge(initialFilterState, {})
  filterState.GRAPH.value = filters.graph
  filterState.GRAPH.active = false
  filterState.SITE.value = filters.domainPath
  filterState.BUILDING.value = filters.buildingCode
  filterState.FLOOR.value = filters.floorPath
  filterState.ROOM_TYPE.value = filters.roomType
  filterState.BUSINESS_UNITS.value = filters.businessUnit
  filterState.QUARTER.value = filters.rangeType
  filterState.QUARTER.value = filters.rangeType
  filterState.KPI.value = filters.kpi
  filterState.DETAILED_OCCUPANCY.value = filters.ordinateAxis
  filterState.RESTAURANT.id = filters.restaurantId
  filterState.SPACE_TYPE.value = filters.scopeType
  filterState.COMPARTMENT.value = filters.compartmentPath
  filterState.MONITORING_ROOM_TYPE.value = filters.roomTypes
  filterState.MONITORING_SCOPE.value = filters.spaceTypes || filters.aggregates

  const heatmapFloor = filters.heatmapFloorPath

  const bookable = filters.bookable
  filterState.BOOKABLE.value = bookable !== optionalOptionAllValue ? bookable : ALL_COLLABORATIVE_SPACES

  const openClosed = filters.open
  filterState.OPEN_CLOSED.value = openClosed !== optionalOptionAllValue ? openClosed : undefined

  const capacityType = filters.capacityType
  filterState.ROOM_SIZE_TYPE.value =
    capacityType === CAPACITY_TYPE_GROUPED && capacityType !== optionalOptionAllValue ? capacityType : undefined

  const capacitySize = filters.capacityCategory
  filterState.CAPACITY_SIZE.value =
    capacityType === CAPACITY_TYPE_CATEGORY && capacitySize !== optionalOptionAllValue ? capacitySize : undefined
  filterState.ROOM_SIZE_DETAILED.value =
    capacityType === CAPACITY_TYPE_DETAILED && capacitySize !== optionalOptionAllValue ? capacitySize : undefined
  filterState.ROOM_SIZE_GROUPED.value =
    capacityType === CAPACITY_TYPE_GROUPED && capacitySize !== optionalOptionAllValue ? capacitySize : undefined

  const openSpace = filters.nature
  filterState.OPEN_SPACE.value = openSpace !== optionalOptionAllValue ? openSpace : undefined

  const dedicatedShared = filters.affectation
  filterState.DEDICATED_SHARED.value = dedicatedShared !== optionalOptionAllValue ? dedicatedShared : undefined
  return filterState
}

export const getFiltersWSFromFilterState = (filters: FilterState): any => {
  switch (filters.GRAPH.value) {
    case topFlopValue:
      return getFiltersForTopFlop(filters)

    case stackedColumnValue:
      return getFiltersForStackedColumn(filters)

    case detailedOccupancyValue:
      return getFiltersForDetailedOccupancy(filters)

    case monthlyValue:
      return getFiltersForMonthly(filters)

    case RIE:
    case frequentationValue:
      return getFiltersForRestaurant(filters)
    case detailedOccupancyTableValue:
      return getFiltersForDetailedOccupancyTable(filters)
    case spaceAttendanceValue:
      return getFiltersForSpaceAttendance(filters)
    case monitoringValue:
      return getFiltersForMonitoring(filters)
    case calibratedUtilizationValue:
      return getFiltersForCalibratedUtilization(filters)
    case roomBookingValue:
      return getFiltersForRoomBooking(filters)
    case utilizationQualityValue:
      return getFiltersForUtilizationQuality(filters)
    case countingMaxValue:
      return getFiltersForCountingMax(filters)

    default:
      throw new Error('Unknown graph')
  }
}
export const getLabelsfilter = (filtersToformat: any, structure: any) => {
  const siteData = filtersToformat?.domainPath
    ? structure?.domains.find((domain: any) => domain.path === filtersToformat?.domainPath)
    : structure?.domains.find((domain: any) => domain.path === filtersToformat?.SITE.value)
  const siteFilter = siteData?.name
  const buildingName = filtersToformat?.BUILDING
    ? filtersToformat?.BUILDING.value === optionAllValue
      ? i18next.t(graphsStructure.buildingCode.all)
      : filtersToformat?.BUILDING !== undefined && filtersToformat?.BUILDING.value !== undefined
      ? siteData?.buildings?.find((building: any) => building.code === filtersToformat?.BUILDING.value)?.name
      : ''
    : ''

  const buildingCode = buildingName ? buildingName : ''

  const floorName = filtersToformat?.FLOOR
    ? filtersToformat?.FLOOR.value === optionAllValue
      ? i18next.t(graphsStructure.floorPath.all)
      : siteData?.floors?.find((floor: any) => floor.path === filtersToformat?.FLOOR.value)?.name
    : ''

  const CompartmentName =
    filtersToformat?.compartmentPath === optionAllValue
      ? i18next.t(graphsStructure.compartmentPath.all)
      : filtersToformat?.compartmentPath
      ? siteData?.compartments?.find((compartment: any) => compartment.path === filtersToformat?.compartmentPath)?.name
      : filtersToformat?.COMPARTMENT
      ? siteData?.compartments?.find((compartment: any) => compartment.path === filtersToformat?.COMPARTMENT.value)
          ?.name
      : null
  const restaurant =
    filtersToformat.restaurantId !== undefined ||
    (filtersToformat.RESTAURANT && filtersToformat.RESTAURANT.value !== undefined)
      ? filtersToformat.restaurantId
        ? siteData?.restaurants?.find((restaurant: any) => restaurant.id === filtersToformat.restaurantId)?.name
        : filtersToformat.RESTAURANT
        ? siteData?.restaurants?.find((restaurant: any) => restaurant.id === filtersToformat.RESTAURANT.id)?.name
        : ''
      : ''
  const roomType = filtersToformat.ROOM_TYPE
    ? graphsStructure.roomType[filtersToformat.ROOM_TYPE.value as keyof typeof graphsStructure.roomType] == undefined
      ? filtersToformat.ROOM_TYPE['value']
      : i18next.t(graphsStructure.roomType[filtersToformat.ROOM_TYPE.value as keyof typeof graphsStructure.roomType])
    : undefined
  const multipleRoomType =
    filtersToformat.MONITORING_ROOM_TYPE && filtersToformat.MONITORING_ROOM_TYPE.value
      ? filtersToformat.MONITORING_ROOM_TYPE.value
          .map((item) => (i18next.t(graphsStructure.roomType[item]) ? i18next.t(graphsStructure.roomType[item]) : item))
          .join('_')
      : undefined
  const monitoringScope =
    filtersToformat.MONITORING_SCOPE && filtersToformat.MONITORING_SCOPE.value
      ? filtersToformat.MONITORING_SCOPE.value
          .map((item) =>
            i18next.t(graphsStructure.monitoringScopeType[item])
              ? i18next.t(graphsStructure.monitoringScopeType[item])
              : item,
          )
          .join('_')
      : undefined
  const rangeType =
    filtersToformat.QUARTER && filtersToformat.QUARTER!.value === 'CURRENT_MONTH'
      ? i18next.t(
          graphsStructure.rangeType[filtersToformat.QUARTER.value as keyof typeof graphsStructure.rangeType].name,
        )
      : filtersToformat.QUARTER
      ? i18next.t(graphsStructure.rangeType[filtersToformat.QUARTER.value as keyof typeof graphsStructure.rangeType])
      : null
  const spaceType =
    filtersToformat.SPACE_TYPE && filtersToformat.SPACE_TYPE.value
      ? i18next.t(graphsStructure.scopeType[filtersToformat.SPACE_TYPE.value])
      : undefined

  const kpi = filtersToformat.KPI
    ? filtersToformat.KPI.value in graphsStructure.kpi && filtersToformat.GRAPH.value !== 'MONITORING'
      ? i18next.t(graphsStructure.kpi[filtersToformat.KPI.value as keyof typeof graphsStructure.kpi])
      : filtersToformat.GRAPH.value == 'BUILDING_ATTENDANCE' && filtersToformat.KPI.value == 'NINTH_DECILE'
      ? i18next.t('graph.filters.kpi.ninthDecile')
      : filtersToformat.KPI.value in graphsStructure.kpiWorkstation && filtersToformat.ROOM_TYPE.value == 'WORKSTATION'
      ? i18next.t(
          graphsStructure.kpiWorkstation[filtersToformat.KPI.value as keyof typeof graphsStructure.kpiWorkstation],
        )
      : filtersToformat.GRAPH.value == 'CALIBRATED_UTILIZATION'
      ? i18next.t(
          graphsStructure.calibratedUtilizationKpi[
            filtersToformat.KPI.value as keyof typeof graphsStructure.calibratedUtilizationKpi
          ],
        )
      : i18next.t(
          graphsStructure.kpiMonitoring[filtersToformat.KPI.value as keyof typeof graphsStructure.kpiMonitoring],
        )
    : undefined

  const graph = filtersToformat.graph
    ? //@ts-ignore
      i18next.t(graphsStructure.graph[filtersToformat.graph])
    : //@ts-ignore
      i18next.t(graphsStructure.graph[filtersToformat.GRAPH.value])
  return {
    graph,
    siteFilter: siteFilter,
    floorName: floorName,
    CompartmentName: CompartmentName,
    rangeType: rangeType,
    buildingCode: buildingCode,
    roomType: roomType,
    multipleRoomType: multipleRoomType,
    kpi: kpi,
    monitoringScope: monitoringScope,
    spaceType: spaceType,
    restaurant: restaurant,
  }
}
export const getFilterType = (graphType, active, data) => {
  return data
    ? graphType == 'BENCHMARK'
      ? 'TRANSPARENT_PRIMARY'
      : 'PRIMARY'
    : active
    ? graphType == 'BENCHMARK'
      ? 'TRANSPARENT'
      : 'OUTLINED_BLACK'
    : 'OUTLINED'
}
const lang = navigator.language

export const getLabel = (item) => {
  if (lang === 'fr-FR' || lang === 'fr') return item.i18nFR
  return item.i18nEN
}
export const getQuarterLabel = (quarter) => {
  return quarter.value == 'LAST_MONTH'
    ? lang === 'fr-FR' || lang === 'fr'
      ? `${i18next.t('landingPage.restaurantAttendance.the')}${' '} ${quarter.label.toLowerCase()}`
      : quarter.label
    : `${i18next.t('landingPage.restaurantAttendance.over')}${' '} ${quarter.label.toLowerCase()}`
}
export const sortArrayByLanguage = (arr: any) => {
  const language = navigator.language.toLowerCase()
  const sortProperty = language.startsWith('fr') ? 'i18nFR' : 'i18nEN'
  return arr.sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]))
}

export const getBusinessUnits = (structure: any, state: any) => {
  return structure.domains
    .find((domain) => domain.path === state.SITE.value)
    ?.businessUnits?.filter((element) => element !== null)
}

export const isCountingMaxGraph = (graph: FilterState['GRAPH']): boolean => {
  return graph.value === countingMaxValue
}

export const isDetailedOccupancyTableGraph = (graph: FilterState['GRAPH']): boolean => {
  return graph.value === detailedOccupancyTableValue
}

export const isRIEGraph = (graph: FilterState['GRAPH']): boolean => {
  return graph.value === RIE
}

export const isMonitoringGraph = (graph: FilterState['GRAPH']): boolean => {
  return graph.value === monitoringValue
}

export const isMonthlyGraph = (graph: FilterState['GRAPH']): boolean => {
  return graph.value === monthlyValue
}

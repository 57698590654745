import { FC } from 'react'
import './Loader.css'

export const LoaderComponent = () => <progress className="pure-material-progress-circular" />

const Loader: FC = () => (
  <div style={{ position: 'absolute', top: 150, left: 275 }}>
    <LoaderComponent />
  </div>
)

export default Loader

import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

type EvolutionOfOccupancyProps = {
  data: any
}

const EvolutionOfOccupancy: FC<EvolutionOfOccupancyProps> = (props) => {
  const { t } = useTranslation()
  const { data } = props
  function getMonth(date: any) {
    const month = date.getMonth() + 1
    return month < 10 ? '0' + month : '' + month
  }
  const formatDate = (date) => {
    if (date.indexOf('/') > -1) {
      const startDate = new Date(date.substring(0, date.indexOf('/')).replace(/\s/g, ''))
      const endDate = new Date(date.substring(date.indexOf('/') + 1, date.length).replace(/\s/g, ''))
      return `${startDate.getDate()}  ${t(`graph.monthly.month.${getMonth(startDate)}`).substring(
        0,
        3,
      )} - ${endDate.getDate()}  ${t(`graph.monthly.month.${getMonth(endDate)}`).substring(0, 3)}  `
    } else {
      const period = new Date(date)
      const day = period.getDate()
      const month = getMonth(period)
      return `${day}  ${t(`graph.monthly.month.${month}`).substring(0, 4)} `
    }
  }
  function getMatch(a, b) {
    const matches = []

    for (let i = 0; i < a.length; i++) {
      for (let e = 0; e < b.length; e++) {
        if (a[i].period === b[e]) matches.push(e)
      }
    }
    return matches
  }
  const categories =
    data && data.allCollaborativeSpaces
      ? data.allCollaborativeSpaces.map((item) => formatDate(item.period))
      : data && data.workstation
      ? data.workstation.map((item) => formatDate(item.period))
      : data && data.buildingAttendance
      ? data.buildingAttendance.map((item) => formatDate(item.period))
      : data && data.domainAttendance
      ? data.domainAttendance.map((item) => formatDate(item.period))
      : []
  const unformatedCategories =
    data && data.allCollaborativeSpaces
      ? data.allCollaborativeSpaces.map((item) => item.period)
      : data && data.workstation
      ? data.workstation.map((item) => item.period)
      : data && data.buildingAttendance
      ? data.buildingAttendance.map((item) => item.period)
      : data && data.domainAttendance
      ? data.domainAttendance.map((item) => formatDate(item.period))
      : []
  const collaborativeSpacesData =
    data && data.allCollaborativeSpaces ? data.allCollaborativeSpaces.map((item) => item.valueInPercentage) : []
  const workstationData = data.workstation ? data.workstation.map((item) => item.valueInPercentage) : []

  if (data.buildingAttendance && data.buildingAttendance.length <= unformatedCategories.length) {
    let i = 0
    do {
      if (!getMatch(data.buildingAttendance, unformatedCategories).includes(i)) {
        data.buildingAttendance.splice(i, 0, { period: unformatedCategories[i], valueInPercentage: 0 })
      }
      i = i + 1
    } while (i < unformatedCategories.length)
  }

  if (data.domainAttendance && data.domainAttendance.length <= unformatedCategories.length) {
    let i = 0
    do {
      if (!getMatch(data.domainAttendance, unformatedCategories).includes(i)) {
        data.domainAttendance.splice(i, 0, { period: unformatedCategories[i], valueInPercentage: 0 })
      }
      i = i + 1
    } while (i < unformatedCategories.length)
  }

  const buildingAttendanceData = data.buildingAttendance
    ? data.buildingAttendance.map((item) => item.valueInPercentage)
    : []
  const domainAttendanceData = data.domainAttendance ? data.domainAttendance.map((item) => item.valueInPercentage) : []

  const series = [
    workstationData.length > 0
      ? {
          name: t('landingPage.evolutionOfOccupancy.workstation'),
          data: workstationData,
        }
      : {
          name: t('landingPage.evolutionOfOccupancy.workstation'),
          data: [],
        },
    collaborativeSpacesData.length > 0
      ? {
          name: t('landingPage.evolutionOfOccupancy.allCollaborativeSpaces'),
          data: collaborativeSpacesData,
        }
      : {
          name: t('landingPage.evolutionOfOccupancy.allCollaborativeSpaces'),
          data: [],
        },
    buildingAttendanceData.length > 0
      ? {
          name: t('landingPage.evolutionOfOccupancy.buildingAttendance'),
          data: buildingAttendanceData,
        }
      : {
          name: t('landingPage.evolutionOfOccupancy.buildingAttendance'),
          data: [],
        },
    domainAttendanceData.length > 0
      ? {
          name: t('landingPage.evolutionOfOccupancy.domainAttendance'),
          data: domainAttendanceData,
        }
      : {
          name: t('landingPage.evolutionOfOccupancy.domainAttendance'),
          data: [],
        },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      markers: {
        width: 32,
        height: 8,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 8,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          return value + '%'
        },
      },
    },

    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: false,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: undefined,
        backgroundColor: COLORS.darkGrey80,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        format: 'dd MMM yy',
        formatter: undefined,
      },
      y: {
        formatter: function (obj) {
          return `${Math.round(obj)}%`
        },
        title: {
          formatter: () => `${t('landingPage.evolutionOfOccupancy.peak')}:`,
        },
      },
      z: {
        formatter: undefined,
        title: 'Size: ',
      },
      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    title: {
      text: t('landingPage.evolutionOfOccupancy.title'),
      style: {
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: 'Roboto',
        color: '#4F4F64',
      },
    },
    colors: [COLORS.blue, COLORS.darkGrey, COLORS.deepCerise50, COLORS.deepCerise],
    xaxis: {
      categories: categories,
      tooltip: {
        enabled: false,
      },
    },
    stroke: {
      width: [3, 3, 3, 3, 3],
      dashArray: [0, 0, 0, 0, 8],
      curve: 'straight',
      lineCap: 'round ',
    },
  }

  return (
    <div id="evolutionOfOccupancy" className="shadowSection pb-2" style={{ height: '100%' }}>
      <div data-tip data-for="evolutionOfOccupancyKpi" className="info-tooltip" style={{ zIndex: 1 }}>
        <IconSvg name="FILLED_INFO" color="GREY30" className="mr-2" />
      </div>
      <ReactTooltip id="evolutionOfOccupancyKpi" place="bottom">
        <p>{t('landingPage.evolutionOfOccupancy.collaborativeSpaceKpi')}</p>
        {t('landingPage.evolutionOfOccupancy.workstationKpi')}
      </ReactTooltip>
      <ReactApexChart type="line" series={series} options={options} height={'100%'} />
    </div>
  )
}
export default EvolutionOfOccupancy

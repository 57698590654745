import IconSvg from 'components/atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { toHoursAndMinutes } from 'utils/dateUtils'
import { getQuarterLabel } from 'utils/filtersUtils'

type Props = {
  averageDuration: string
  occupied: string
  capacity: string
  hasWorkstation: boolean
  quarter: any
}

const WorkstationUtilization = (props: Props): JSX.Element => {
  const { averageDuration, occupied, hasWorkstation, quarter, capacity } = props
  const { t } = useTranslation()
  return (
    <div
      className={`${
        averageDuration === '' && occupied === '' ? 'inactiveShadowSection' : ''
      } shadowSection px-4 flex flex-col relative h-full`}
    >
      <div data-tip data-for="workstationUtilizationKpi" className="info-tooltip">
        <IconSvg name="FILLED_INFO" className="mr-2" color="GREY30" />
      </div>
      <ReactTooltip id="workstationUtilizationKpi" place="bottom">
        {t('landingPage.workstationOccupation.kpi')}
      </ReactTooltip>
      <h2 className="section-title">{t('landingPage.workstationOccupation.title')}</h2>
      <div className={`${!averageDuration ? 'inactiveShadowSection' : ''}`}>
        <div className={` flex flex-col items-center my-2`} data-for="averageDuration" data-tip="averageDuration">
          <small className="text-center">{t('landingPage.workstationOccupation.average')}</small>
          <p className="grayBloc" style={{ minWidth: '175px', minHeight: '80px' }}>
            {averageDuration
              ? `${toHoursAndMinutes(averageDuration).hours}h${Math.round(
                  toHoursAndMinutes(averageDuration).minutes,
                )}min`
              : '_'}
          </p>
        </div>
      </div>
      {quarter && (
        <ReactTooltip id="averageDuration" className="breakLine" type="info">
          {!hasWorkstation
            ? t('landingPage.news')
            : averageDuration !== undefined
            ? t('landingPage.workstationOccupation.tooltip', {
                quarter: getQuarterLabel(quarter),
                value: `${toHoursAndMinutes(averageDuration).hours}h${Math.round(
                  toHoursAndMinutes(averageDuration).minutes,
                )}min`,
              })
            : t('landingPage.unavailable')}
        </ReactTooltip>
      )}
      <div className={`${!occupied ? 'inactiveShadowSection' : ''}`}>
        <div className="flex flex-col items-center my-2">
          <small className="text-center">{t('landingPage.workstationOccupation.averageOccupied')}</small>
          <p className="grayBloc" style={{ minWidth: '175px', minHeight: '80px' }}>
            {occupied ? Math.round(occupied) + '/' + Math.round(capacity) : '_'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default WorkstationUtilization

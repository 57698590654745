import { FC, useContext, useEffect, useState } from 'react'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import { default as ReactSelect, NonceProvider } from 'react-select'
import { components } from 'react-select'

import { useTranslation } from 'react-i18next'
import { DomainsStructure } from 'types/DomainsStructure'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FILTER_ARROW_ICONS, FILTER_STYLE } from 'styles/Filters'
import { calibratedUtilizationValue } from 'utils/constants/graphs/graphsStructure'

const handleStateChange = (state: FilterState, structure: DomainsStructure, selected?: any): FilterState => {
  if (selected.length > 0 && state.MONITORING_SCOPE.value === selected[0].value) return state
  let value
  const scopes = selected.map((elt: any) => elt.value)

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    MONITORING_ENTITY: {
      ...initialFilterState.MONITORING_ENTITY,
      active: true,
    },
    MONITORING_ROOM_TYPE: { ...initialFilterState.MONITORING_ROOM_TYPE, value: [] },
    MONITORING_SCOPE: {
      ...state.MONITORING_SCOPE,
      value: scopes,

      active: !value,
    },
    KPI: {
      ...initialFilterState.KPI,
      active: !!calibratedUtilizationValue,
    },
    BUILDING: {
      ...initialFilterState.KPI,
      active: !!calibratedUtilizationValue,
    },
  }
}
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{
            accentColor: props.data.disabled ? '#9C9CB5' : '#D33087',
            width: '12px',
            height: '12px',
            marginRight: '5px',
            borderBottom: '1px',
            borderColor: 'red',
          }}
        />{' '}
        <label
          style={{
            color: props.data.disabled ? ' #9C9CB5' : '#74748D',
            opacity: '1',
            fontWeight: '500',
            fontSize: '1rem',
          }}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  )
}

const monitoringScopeFilter: FC = () => {
  const { t } = useTranslation()
  const [optionSelected, setOptionSelected] = useState(null)
  const [disableDomain, setDisableDomain] = useState(false)
  const [disableBuilding, setDisableBuilding] = useState(false)
  const [disableFloor, setDisableFloor] = useState(false)
  const [disableCompartment, setDisableCompartment] = useState(false)
  const [open, setOpen] = useState(false)

  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconSvg name={open ? openIcon : closeIcon} color={iconColors[type]} className="ml-2" />{' '}
        </components.DropdownIndicator>
      )
    )
  }

  const handleChange = (selected: any) => {
    setOptionSelected(selected)
    //@ts-ignore
    if (selected.length == 0) {
      setDisableBuilding(false)
      setDisableCompartment(false)
      setDisableDomain(false)
      setDisableFloor(false)
    }
    onFilterChange(handleStateChange(state, structure, selected))
  }

  const scopeOptions = [
    { value: 'DOMAIN', label: t('graph.filters.site'), disabled: disableDomain },
    { value: 'BUILDING', label: t('graph.filters.building.placeholder'), disabled: disableBuilding },
    { value: 'FLOOR', label: t('graph.filters.floor.placeholder'), disabled: disableFloor },
    { value: 'COMPARTMENT', label: t('graph.filters.compartment.placeholder'), disabled: disableCompartment },
  ]
  useEffect(() => {
    if (state.MONITORING_SCOPE.value && state.MONITORING_SCOPE.value.length == 0) setOptionSelected(null)
  }, [state.MONITORING_SCOPE.value])
  state.MONITORING_SCOPE.active = true
  const type =
    //@ts-ignore
    optionSelected !== null && optionSelected.length > 0
      ? 'PRIMARY'
      : state.MONITORING_SCOPE.active
      ? 'OUTLINED_BLACK'
      : 'OUTLINED'
  const style = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: open ? 'white' : '#4F4F64',
        backgroundColor: 'transparent',
      }
    },
    control: (base: any, state: any) => ({
      ...base,
      borderWidth: 0,
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minWidth: '3rem',
      '&:hover': {
        border: 'none',
      },
    }),
    //@ts-ignore
    option: (base, state) => ({
      width: '100%',
      color: state.selected ? 'white' : '#74748D',
      borderBottom: '20px',
      borderColor: 'red',
      padding: '0.5rem',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: '1rem',
    }),
    multiValue: (base: any, state: any) => ({
      ...base,
      backgroundColor: 'transparent',
      color: state.selected ? 'white' : 'white',
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    menu: (base: any) => ({
      ...base,
      width: 'max-content',
      minWidth: '100%',
      padding: '5px',
      borderRadius: '8px',
      borderColor: '#c6c6de',
      borderWidth: '2px',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    }),
  }
  const disabled = false
  const { openIcon, closeIcon, ...iconColors } = FILTER_ARROW_ICONS
  const filterType = open ? 'BLACK' : type
  const arrowRenderer = () => {
    return <IconSvg name={open ? openIcon : closeIcon} color={iconColors[type]} className="ml-2" />
  }
  useEffect(() => {
    if (state.MONITORING_SCOPE.value)
      setOptionSelected(scopeOptions.filter((el) => state.MONITORING_SCOPE.value.includes(el.value)))
  }, [])

  return (
    <span data-toggle="popover" data-trigger="focus" className="mx-2" style={{ minWidth: '8rem' }}>
      <ReactSelect
        arrowRenderer={arrowRenderer}
        options={scopeOptions}
        className={`inline-flex min-h-13 items-center  px-2 pb-1 rounded-lg border-2 text-b2 font-bold ${
          FILTER_STYLE[filterType]
        } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none`}
        styles={style}
        //@ts-ignore
        isOptionDisabled={(option) => option?.disabled}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          DropdownIndicator,
        }}
        onChange={handleChange}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        value={state.MONITORING_SCOPE.value && state.MONITORING_SCOPE.value.length === 0 ? null : optionSelected}
        placeholder={<div>{t('graph.monitoring.scope')}</div>}
      />
    </span>
  )
}

export default monitoringScopeFilter

import { FC, useContext } from 'react'
import { FilterState, GraphFiltersContext } from './required/GraphFiltersContext'
import GraphFilter from './required/GraphFilter'
import SiteFilter from './required/SiteFilter'
import BuildingFilter from './required/BuildingFilter'
import {
  calendarDayValue,
  CAPACITY_TYPE_CATEGORY,
  CAPACITY_TYPE_DETAILED,
  CAPACITY_TYPE_GROUPED,
  FOCUS_ROOM,
  detailedOccupancyValue,
  MEETING_ROOM,
  MEETING_AND_FOCUS,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  weekDayValue,
  WORKSTATION,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  monitoringValue,
  heatmapValue,
  calibratedUtilizationValue,
  roomBookingValue,
  benchmarkValue,
  countingMaxValue,
} from 'utils/constants/graphs/graphsStructure'
import FloorFilter from './required/FloorFilter'
import CompartmentFilter from './required/CompartmentFilter'
import HeatmapFilter from './required/HeatmapFilter'
import RoomTypeFilter from './required/RoomTypeFilter'
import QuarterFilter from './required/QuarterFilter'
import BookableFilter from './optionals/BookableFilter'
import OpenClosedFilter from './optionals/OpenClosedFilter'
import RoomSizeDetailedFilter from './optionals/RoomSizeDetailedFilter'
import RoomSizeGroupedFilter from './optionals/RoomSizeGroupedFilter'
import CapacitySizeFilter from './optionals/CapacitySizeFilter'
import RoomSizeType from './optionals/RoomSizeTypeFilter'
import OpenSpaceFilter from './optionals/OpenSpaceFilter'
import DedicatedSharedFilter from './optionals/DedicatedSharedFilter'
import BusinessUnitsFilter from './required/BusinessUnitsFilter'
import KpiFilter from './required/KpiFilter'
import RestaurantFilter from './optionals/RestaurantFilter'
import ScopeTypeFilter from './required/ScopeTypeFilter'
import MonitoringScopeFilter from './required/monitoringScopeFilter'
import MonitoringSpaceTypeFilter from './required/monitoringSpaceTypeFilter'
import MonitoringBusniessUnitsFilter from './required/monitoringBusinessUnitsFilter'
import LevelFilter from './required/LevelFilter'
import CustomAttributCollabFilter from './required/CustomAttributCollabFilter'
import CustomAttributWksFilter from './required/CustomAttributWks'
import CustomAttributCollabValuesFilter from './required/CustomAttributCollabValuesFilter'
import CustomAttributWksValuesFilter from './required/CustomAttriburWksValuesFilter'
import { getBusinessUnits } from 'utils/filtersUtils'

type Props = {
  filterState: FilterState
  missingRequiredFilters: boolean
  graphType?: any
}

const AnalysisFilters: FC<Props> = (props) => {
  const { filterState, missingRequiredFilters, graphType } = props
  if (graphType == 'BENCHMARK') {
    filterState.GRAPH.value = benchmarkValue
    filterState.SITE.active = true
  }
  const { structure } = useContext(GraphFiltersContext)

  const haveCompartments =
    graphType !== 'BENCHMARK' &&
    structure.domains.find((domain) => domain.path === filterState.SITE.value)?.compartments
  const haveCollabCustomAttributs = structure.domains.find(
    (domain) => domain.path === filterState.SITE.value,
  )?.customAttributeCollab
  const haveWksCustomAttributs = structure.domains.find(
    (domain) => domain.path === filterState.SITE.value,
  )?.customAttributeWks

  const haveSpecificCollaborativeSpaceNatures =
    structure.domains.find((domain) => domain.path === filterState.SITE.value)?.specificCollaborativeSpaceNatures !==
    undefined
  const graphTypeValue = filterState.GRAPH.value
  const roomTypeValue = filterState.ROOM_TYPE.value?.toString()
  const capacityType = filterState.ROOM_SIZE_TYPE.value
  const isByCalendarDays = filterState.DETAILED_OCCUPANCY.value === calendarDayValue
  const isByWeekDays = filterState.DETAILED_OCCUPANCY.value === weekDayValue
  const isDetailedOccupancy = filterState.GRAPH.value === detailedOccupancyValue
  const isMonitoring = filterState.GRAPH.value === monitoringValue
  const isCalibratedUtilization = filterState.GRAPH.value === calibratedUtilizationValue
  const isDetailedOccupancyTable = filterState.GRAPH.value === detailedOccupancyTableValue
  const isCountingMax = filterState.GRAPH.value === countingMaxValue
  const compartmentMonthly = graphTypeValue === monthlyValue && filterState.FLOOR.value !== '[ALL]' && haveCompartments
  const compartmentHeatmap =
    graphTypeValue === heatmapValue &&
    filterState.FLOOR.value !== '[ALL]' &&
    haveCompartments &&
    filterState.LEVEL.value === 'COMPARTMENT'
  const isSpaceTypeCompartment = filterState.SPACE_TYPE.value === 'COMPARTMENT'
  const isRoomBookingWithCompartments = haveCompartments && graphTypeValue === roomBookingValue
  const isCountingMaxWithCompartments = haveCompartments && graphTypeValue === countingMaxValue
  const businessUnitsOptions = getBusinessUnits(structure, filterState)
  return (
    <div className="w-8/10 2xl:w-7/10 flex flex-wrap ">
      <div className="flex flex-wrap w-full">
        {graphType !== 'BENCHMARK' && <GraphFilter graphType={graphType} />}
        {/* Rule : If the GRAPH filter isn't selected, then no other filters are shown. */}
        {graphTypeValue && filterState.GRAPH.enable && (
          <>
            <SiteFilter />
            {graphTypeValue === spaceAttendanceValue && (
              <>
                <ScopeTypeFilter />
              </>
            )}
            {((graphTypeValue !== RIE &&
              graphTypeValue !== frequentationValue &&
              graphTypeValue !== monitoringValue &&
              graphTypeValue !== calibratedUtilizationValue) ||
              filterState.SPACE_TYPE.value === 'BUILDING') && <BuildingFilter />}
            {graphTypeValue === heatmapValue && <LevelFilter />}

            {/* Rule : The FLOOR filter must not be filled only for the heatmap, but there are 2 special filter instead. */}
            {((graphTypeValue !== detailedOccupancyValue &&
              graphTypeValue !== RIE &&
              graphTypeValue !== frequentationValue &&
              graphTypeValue !== spaceAttendanceValue &&
              graphTypeValue !== detailedOccupancyTableValue &&
              graphTypeValue !== monitoringValue &&
              graphTypeValue !== calibratedUtilizationValue &&
              graphTypeValue !== benchmarkValue) ||
              filterState.SPACE_TYPE.value === 'FLOOR' ||
              filterState.SPACE_TYPE.value === 'COMPARTMENT') && <FloorFilter />}
            {(compartmentMonthly ||
              compartmentHeatmap ||
              isSpaceTypeCompartment ||
              isRoomBookingWithCompartments ||
              isCountingMaxWithCompartments) && <CompartmentFilter />}

            {isMonitoring && (
              <>
                <MonitoringScopeFilter /> <MonitoringSpaceTypeFilter />
              </>
            )}
            {isCalibratedUtilization && (
              <>
                <MonitoringScopeFilter />
              </>
            )}

            {(graphTypeValue === spaceAttendanceValue ||
              graphTypeValue === detailedOccupancyTableValue ||
              graphTypeValue === heatmapValue ||
              graphTypeValue === monitoringValue ||
              graphTypeValue === calibratedUtilizationValue) && (
              <>
                {(graphTypeValue === monitoringValue || graphTypeValue === calibratedUtilizationValue) &&
                  businessUnitsOptions &&
                  businessUnitsOptions.length > 0 && <MonitoringBusniessUnitsFilter />}
                {graphTypeValue === monitoringValue && haveWksCustomAttributs && <CustomAttributWksFilter />}

                {graphTypeValue === monitoringValue && haveCollabCustomAttributs && <CustomAttributCollabFilter />}

                <KpiFilter />
              </>
            )}
            {graphTypeValue !== RIE &&
              graphTypeValue !== frequentationValue &&
              graphTypeValue !== spaceAttendanceValue &&
              graphTypeValue !== detailedOccupancyTableValue &&
              graphTypeValue !== monitoringValue &&
              graphTypeValue !== heatmapValue &&
              graphTypeValue !== countingMaxValue && <RoomTypeFilter />}
            {(graphTypeValue === detailedOccupancyValue || graphTypeValue === monthlyValue) &&
              haveWksCustomAttributs &&
              filterState.ENTRY.value == 'DESKTOP' && (
                <CustomAttributWksValuesFilter customAttributsWks={haveWksCustomAttributs} />
              )}
            {(graphTypeValue === detailedOccupancyValue || graphTypeValue === monthlyValue) &&
              haveCollabCustomAttributs &&
              filterState.ENTRY.value !== 'DESKTOP' && (
                <CustomAttributCollabValuesFilter customAttributsCollab={haveCollabCustomAttributs} />
              )}

            {graphTypeValue === detailedOccupancyValue && (
              <>
                <HeatmapFilter />

                <KpiFilter />
              </>
            )}
            {graphTypeValue === monthlyValue &&
              filterState.FLOOR.value == '[ALL]' &&
              businessUnitsOptions?.length > 0 && <BusinessUnitsFilter businessUnits={businessUnitsOptions} />}
            {(graphTypeValue === RIE || graphTypeValue === frequentationValue) && (
              <>
                <RestaurantFilter />
              </>
            )}
            {isCountingMax && <MonitoringSpaceTypeFilter />}
            <QuarterFilter />
            {(graphTypeValue === benchmarkValue || graphTypeValue === frequentationValue) && <KpiFilter />}

            {isDetailedOccupancyTable && <MonitoringSpaceTypeFilter />}
            {isDetailedOccupancy && (isByCalendarDays || isByWeekDays) && <FloorFilter />}
            {isDetailedOccupancy &&
              (isByCalendarDays || isByWeekDays) &&
              filterState.FLOOR.value &&
              haveCompartments && <CompartmentFilter />}
          </>
        )}
      </div>
      {/* Optional filters */}
      <div>
        {!missingRequiredFilters && (
          <div className="flex flex-wrap">
            {/* FILTERS FOR GRAPHS 1 */}
            {topFlopValue === graphTypeValue && (
              <>
                {roomTypeValue === MEETING_ROOM.value && <BookableFilter />}
                {roomTypeValue === FOCUS_ROOM.value && <OpenClosedFilter />}
              </>
            )}
            {/* FILTERS FOR GRAPHS 2 */}
            {stackedColumnValue === graphTypeValue && (
              <>
                {roomTypeValue === MEETING_ROOM.value && <BookableFilter />}
                {roomTypeValue === FOCUS_ROOM.value && <OpenClosedFilter />}
                {(roomTypeValue === MEETING_ROOM.value || roomTypeValue === MEETING_AND_FOCUS.value) && (
                  <RoomSizeType />
                )}
                {capacityType === CAPACITY_TYPE_DETAILED && <RoomSizeDetailedFilter />}
                {capacityType === CAPACITY_TYPE_GROUPED && <RoomSizeGroupedFilter />}
                {capacityType === CAPACITY_TYPE_CATEGORY && <CapacitySizeFilter />}
              </>
            )}
            {/* FILTERS FOR GRAPHS 3 */}
            {graphTypeValue === detailedOccupancyValue && (
              <>
                {roomTypeValue !== WORKSTATION.value && !haveSpecificCollaborativeSpaceNatures && (
                  <CapacitySizeFilter />
                )}
                {roomTypeValue === MEETING_ROOM.value && !haveSpecificCollaborativeSpaceNatures && <BookableFilter />}
                {roomTypeValue === FOCUS_ROOM.value && !haveSpecificCollaborativeSpaceNatures && <OpenClosedFilter />}
                {roomTypeValue === WORKSTATION.value && (
                  <>
                    <OpenSpaceFilter />
                    <DedicatedSharedFilter />
                  </>
                )}
              </>
            )}
            {/* FILTERS FOR GRAPHS 4 */}
            {graphTypeValue === monthlyValue && (
              <>
                {roomTypeValue === MEETING_ROOM.value && !haveSpecificCollaborativeSpaceNatures && <BookableFilter />}
                {roomTypeValue === FOCUS_ROOM.value && !haveSpecificCollaborativeSpaceNatures && <OpenClosedFilter />}
                {roomTypeValue === WORKSTATION.value && (
                  <>
                    <OpenSpaceFilter />
                    <DedicatedSharedFilter />
                  </>
                )}
              </>
            )}
            {/* FILTERS FOR GRAPHS 8 */}
            {utilizationQualityValue === graphTypeValue && (
              <>
                <CapacitySizeFilter />
                {roomTypeValue === MEETING_ROOM.value && <BookableFilter />}
                {roomTypeValue === FOCUS_ROOM.value && <OpenClosedFilter />}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalysisFilters

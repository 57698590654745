import { ChartProps } from 'types/react-apexcharts/react-apexcharts'

export const loadingBarChartData = [50, 85, 65, 30]

type ChartOptionsType = {
  type: ChartProps<never>['type']
  horizontal: boolean
  stacked: boolean
}

export const getLoadingChartOptions = ({ type, horizontal, stacked }: ChartOptionsType): Record<string, unknown> => ({
  colors: ['#DDDDDD'],
  chart: {
    type,
    stacked,
    stackType: stacked ? '100%' : undefined,
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    selection: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal,
      barHeight: '70%',
      borderRadius: 12,
    },
  },
  dataLabels: {
    position: 'end',
    formatter: () => ' ',
    offsetX: -15,
  },
  xaxis: {
    categories: [' '],
    labels: {
      show: false,
    },
    tickAmount: 1,
    max: 100,
    axisTicks: {
      show: true,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  title: {
    text: undefined,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
})

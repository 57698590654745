import { COLORS } from 'assets/colors/colors'
import clsx from 'clsx'
import IconSvg from 'components/atoms/icons/IconSvg'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { getQuarterLabel } from 'utils/filtersUtils'

type Props = {
  workstation: number
  allCollaborativeSpaces: number
  quarter: string
}

export default function OccupancyByTypeOfSpace({ quarter, allCollaborativeSpaces, workstation }: Props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col relative shadowSection h-full">
      <div data-tip data-for="occupancyByTypeOfSpaceKpi" className="info-tooltip">
        <IconSvg name="FILLED_INFO" className="mr-2" color="GREY30" />
      </div>
      <ReactTooltip id="occupancyByTypeOfSpaceKpi" place="bottom">
        {t('landingPage.occupancyBySpaceType.kpi')}{' '}
      </ReactTooltip>
      <h2 className="section-title pl-4">{t('landingPage.occupancyBySpaceType.title')}</h2>
      <div className="mx-8">
        <div className={clsx('flex flex-col items-center py-2', allCollaborativeSpaces ? '' : 'inactiveShadowSection')}>
          <small className="text-center">{t('landingPage.occupancyBySpaceType.allCollaborativeSpacesOccupancy')}</small>
          <div
            data-for="allCollaborativeSpaces"
            data-tip="allCollaborativeSpaces"
            style={{
              height: '80px',
              width: '175px',
              border: '2px solid #D8DEED',
              padding: '5px 40px',
              borderRadius: '8px',
              fontWeight: 'bold',
            }}
          >
            <CircularProgressbar
              className="h-full"
              styles={buildStyles({
                pathColor: allCollaborativeSpaces ? COLORS.deepCerise : '#c0c0c4',
                trailColor: '#C6C6DE',
                strokeLinecap: 'butt',
                textSize: '22',
              })}
              value={allCollaborativeSpaces ?? 40}
              text={allCollaborativeSpaces ? `${Math.round(allCollaborativeSpaces)}%` : '-'}
            />
          </div>
          {(quarter || !allCollaborativeSpaces) && (
            <ReactTooltip id="allCollaborativeSpaces" className="breakLine" type="info">
              {allCollaborativeSpaces ? (
                <span>
                  {getQuarterLabel(quarter) +
                    ' ' +
                    t('landingPage.occupancyBySpaceType.tooltip', {
                      percentage: Math.round(allCollaborativeSpaces),
                      roomType: t('landingPage.occupancyBySpaceType.allCollaborativeSpaces'),
                    })}
                </span>
              ) : (
                <span>{t('landingPage.news')}</span>
              )}
            </ReactTooltip>
          )}
        </div>
        <div className={clsx('flex flex-col items-center py-2', workstation ? '' : 'inactiveShadowSection')}>
          <small className="text-center">{t('landingPage.occupancyBySpaceType.workstationOccupancy')}</small>
          <div
            data-for="workstationOccupancy"
            data-tip="workstationOccupancy"
            style={{
              height: '80px',
              width: '175px',
              border: '2px solid #D8DEED',
              padding: '5px 40px',
              borderRadius: '8px',
              fontWeight: 'bold',
            }}
          >
            <CircularProgressbar
              className="h-full"
              styles={buildStyles({
                pathColor: workstation ? COLORS.blue : '#c0c0c4',
                trailColor: '#C6C6DE',
                strokeLinecap: 'butt',
                textSize: '22',
              })}
              value={workstation ?? 40}
              text={workstation ? `${Math.round(workstation)}%` : '-'}
            />
          </div>
          {(quarter || !workstation) && (
            <ReactTooltip id="workstationOccupancy" className="breakLine" type="info">
              {workstation ? (
                <span>
                  {getQuarterLabel(quarter) +
                    ' ' +
                    t('landingPage.occupancyBySpaceType.tooltip', {
                      percentage: Math.round(workstation),
                      roomType: t('landingPage.occupancyBySpaceType.workstation'),
                    })}
                </span>
              ) : (
                <span>{t('landingPage.news')}</span>
              )}
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  )
}

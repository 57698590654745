import { FC, useState, useEffect } from 'react'
import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icons/IconSvg'
import ConfirmationDialog from '../dialogs/confirmation/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { SavedReport } from 'types/SavedReportsTypes'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { savedReportsService } from 'services/savedReportsService'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { generatePath } from 'utils/routeUtils'
import { PATHS } from 'utils/constants/routes/Paths'
import { Link } from 'react-router-dom'
import AddToDashboardDialog from '../dialogs/addToDashboard/AddToDashboardDialog'
import NewDashboardDialog from '../dialogs/newDashboard/NewDashboardDialog'
import ExistingDashboardDialog from '../dialogs/existingDashboard/ExistingDashboardDialog'
import ExportationDialog from '../dialogs/exportation/ExportationDialog'
import { ExportDataType } from 'types/ExportType'
import { GraphType } from '../filters/required/GraphFilter'
import { getExportFormattedData } from 'utils/exportUtils'
import { detailedOccupancyTableValue } from 'utils/constants/graphs/graphsStructure'

type Props = {
  selectedFavorite: SavedReport
  onDeleteSuccess: () => void
  graphType?: GraphType
  exportData?: any
}

const FavoriteItemActions: FC<Props> = (props) => {
  const { selectedFavorite, onDeleteSuccess, graphType, exportData } = props
  const { t } = useTranslation()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { clientCode = '' } = useCurrentUser()
  const queryClient = useQueryClient()
  const [exportModal, setExportModal] = useState(false)
  const [csvData, setCSVData] = useState<ExportDataType>([])
  const isDetailedOccupancyTable = graphType === 'DETAILED_OCCUPANCY_TABLE'
  const isMonitoring = graphType === 'MONITORING'
  const isCalibratedUtilization = graphType === 'CALIBRATED_UTILIZATION'
  const isCountingMax = graphType === 'COUNTING_MAX'
  const isCsv = isDetailedOccupancyTable || isMonitoring || isCalibratedUtilization || isCountingMax

  const { mutate } = useMutation({
    mutationKey: QUERY_KEYS.SAVED_REPORTS.DUPLICATE(selectedFavorite.id),
    mutationFn: () => savedReportsService.duplicate(clientCode, selectedFavorite.id),
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode))
      toast.success(t('savedReports.singleReport.duplicate.success', { name: selectedFavorite.name }))
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  useEffect(() => {
    if (graphType && exportData) {
      const { headers, data } = getExportFormattedData('', graphType, exportData)
      const translatedHeaders = headers.map((header) => t(header))

      setCSVData([translatedHeaders, ...data])
    }
  }, [exportData])

  const [dashboardModal, setDashboardModal] = useState(false)
  const [NewDashboardModal, setNewDashboardModal] = useState(false)
  const [ExistingDashboardModal, setExistingDashboardModal] = useState(false)

  const onDashboardClick = () => setDashboardModal(true)

  const onNewDashboardClick = () => {
    setDashboardModal(false)
    setTimeout(() => {
      setNewDashboardModal(true), 500
    })
  }
  const onExistingDashboardClick = () => {
    setDashboardModal(false)
    setTimeout(() => {
      setExistingDashboardModal(true), 500
    })
  }

  const onClose = () => {
    setDashboardModal(false)
    setNewDashboardModal(false)
    setExistingDashboardModal(false)
    setExportModal(false)
  }

  const onDeleteButtonClick = () => setDeleteOpen(true)
  const onCancel = () => setDeleteOpen(false)
  const onExportClick = () => setExportModal(!exportModal)

  return (
    <div className="flex justify-end space-x-4 mt-4">
      {!isCsv && (
        <Button onClick={onExportClick} className="flex flex-row items-center" color="default" rounded="lg">
          {t('analysis.report.export')}
          <IconSvg name="SHARE" color={'WHITE'} className="ml-3" />
        </Button>
      )}
      <Link
        to={generatePath(PATHS.SAVED_REPORTS.EDIT_SINGLE_REPORT, {
          url: PATHS.SAVED_REPORTS.HOME,
          id: selectedFavorite.id.toString(),
        })}
      >
        <Button color="default" rounded="lg" disabled={deleteOpen} className="flex flex-row items-center">
          {t('savedReports.singleReport.openEdit')}
          <IconSvg name="EDIT" color="WHITE" className="ml-2" />
        </Button>
      </Link>
      <Button
        color="default"
        rounded="lg"
        disabled={deleteOpen}
        onClick={mutate}
        className="flex flex-row items-center"
      >
        {t('savedReports.singleReport.duplicate.button')}
        <IconSvg name="DUPLICATE" color="WHITE" className="ml-2" />
      </Button>
      {!isCsv && (
        <Button
          color="default"
          rounded="lg"
          disabled={deleteOpen}
          className="flex flex-row items-center"
          onClick={onDashboardClick}
        >
          {t('savedReports.singleReport.dashboard.addToDashboard')}
          <IconSvg name="DASHBOARD" color="WHITE" className="ml-2" />
        </Button>
      )}
      <Button
        color="red"
        rounded="lg"
        disabled={deleteOpen || !selectedFavorite.deletable}
        onClick={onDeleteButtonClick}
      >
        {t('common.delete')}
      </Button>
      <ConfirmationDialog
        open={deleteOpen}
        onClose={onCancel}
        onDeleteSuccess={onDeleteSuccess}
        savedReportId={selectedFavorite.id}
        savedReportName={selectedFavorite.name}
      />
      {dashboardModal && (
        <AddToDashboardDialog
          onNewDashboardClick={onNewDashboardClick}
          onExistingDashboardClick={onExistingDashboardClick}
          open={dashboardModal}
          onClose={onClose}
        />
      )}
      {NewDashboardModal && (
        <NewDashboardDialog open={NewDashboardModal} onClose={onClose} reportID={selectedFavorite.id} />
      )}
      {ExistingDashboardModal && (
        <ExistingDashboardDialog open={ExistingDashboardModal} onClose={onClose} reportID={selectedFavorite.id} />
      )}
      {exportModal && (
        <ExportationDialog
          data={csvData}
          open={exportModal}
          onClose={onClose}
          graphType={graphType}
          graphName={selectedFavorite.name}
          filters={selectedFavorite.filters}
        />
      )}
    </div>
  )
}

export default FavoriteItemActions

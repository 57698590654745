import { FC, useContext } from 'react'
import { toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../../../atoms/button/Button'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { format } from 'date-fns'
import * as Yup from 'yup'
import FormInput from '../../../atoms/input/FormInput'
import { optionAllValue } from 'utils/constants/graphs/global'
import { fullValues } from '../../filters/required/RoomTypeFilter'
import { allQuarterValues } from 'utils/constants/graphs/graphsStructure'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { graphService } from 'services/graphService'
import useCurrentUser from 'utils/hooks/useCurrentUser'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('forms.errors.required'),
})

type Props = {
  open: boolean
  onClose: () => void
}

const SaveFiltersDialog: FC<Props> = (props) => {
  const { open, onClose } = props
  const { t } = useTranslation()
  const { structure, state: filters } = useContext(GraphFiltersContext)

  // The default name's format is :
  // <Timestamp-yyyyMMddhhmmss>_<Type-de-rapport>_Site_Bâtiment_Etage_<Typologie-espace>_<Période-temporelle>
  // If BUILDING or FLOOR is "ALL", then it is not added in the name.
  let defaultName = `${format(new Date(), 'yyyyMMddhhmmss')}_${filters.GRAPH.value}_`

  const selectedSite = structure.domains.find((domain) => {
    return domain.path === filters.SITE.value
  })
  defaultName += selectedSite ? `${selectedSite.name}_` : ''

  const spaceAttendanceValue = filters.BUILDING.value
  const buildingName =
    spaceAttendanceValue !== optionAllValue &&
    selectedSite?.buildings?.find((building) => building.code === spaceAttendanceValue)?.name
  defaultName += buildingName ? `${buildingName}_` : ''

  const floorValue = filters.FLOOR.value
  const floorName =
    floorValue !== optionAllValue && selectedSite?.floors?.find((floor) => floor.path === floorValue)?.name
  defaultName += floorName ? `${floorName}_` : ''

  const roomTypeName = fullValues.find((roomType) => roomType.value === filters.ROOM_TYPE.value)?.label
  defaultName += roomTypeName ? `${t(roomTypeName)}_` : ''

  const quarterName = allQuarterValues.find((quarter) => quarter.value === filters.QUARTER.value)?.label
  defaultName += quarterName ? t(quarterName) : ''

  defaultName = defaultName.replaceAll(' ', '-')

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: defaultName,
    },
  })

  const { clientCode = '' } = useCurrentUser()
  const queryClient = useQueryClient()

  const { isLoading, mutate } = useMutation({
    mutationKey: QUERY_KEYS.ANALYSIS.SAVE(filters),
    mutationFn: (reportName: string) => graphService.saveFilters(clientCode, reportName, filters),
    onSuccess: async () => {
      toast.success(t('analysis.saveReport.success'))
      // We want to invalidate the cache of this query because there is a new value now
      await queryClient.removeQueries(QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode))
      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onSubmit = ({ name }: { name: string }) => mutate(name)
  const onModalClose = () => {
    if (!isLoading) {
      onClose()
    }
  }

  const { name } = form.watch()
  const disableConfirm = !name

  return (
    <Dialog isOpen={open} onClose={onModalClose} className="w-1/2">
      <DialogTitle onClose={onModalClose}>{t('analysis.saveReport.title')}</DialogTitle>
      <DialogBody>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <p>{t('analysis.saveReport.body')}</p>
              <FormInput grey placeholder={t('analysis.saveReport.placeholder')} name="name" />
            </div>
            <div className="mt-8 flex justify-center space-x-4">
              {/* <Button color="red" onClick={onModalClose} disabled={isLoading}>
                {t('common.cancel')}
              </Button> */}
              <Button type="submit" disabled={disableConfirm || isLoading}>
                {t('common.confirm')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </DialogBody>
    </Dialog>
  )
}

export default SaveFiltersDialog

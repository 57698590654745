import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './i18n'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import { ReactQueryConfig } from './utils/constants/ReactQueryConfig'
import { ReactQueryDevtools } from 'react-query/devtools'
import './styles/fonts.css'
import './styles/tailwind.css'
import 'react-toastify/dist/ReactToastify.css'
import { TypeOptions } from 'react-toastify/dist/types'
import { AuthProvider } from 'core/auth/AuthProvider'

const SnackbarConfig: { [key in TypeOptions]?: string } = {
  success: 'bg-green',
  default: 'bg-red',
}

const queryClient = new QueryClient(ReactQueryConfig)

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Suspense fallback="loading...">
            <ToastContainer
              toastClassName={(context) => {
                if (!context) return ''

                const config = SnackbarConfig[context.type as keyof typeof SnackbarConfig] || SnackbarConfig.default
                return `relative flex justify-space-between mb-4 p-4 min-h-12 max-h-800 overflow-hidden cursor-pointer box-border rounded-lg ${config}`
              }}
            />
            <App />
          </Suspense>
        </AuthProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById('root'),
)

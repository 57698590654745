import useCurrentUser from './useCurrentUser'
import { useQuery, UseQueryResult } from 'react-query'
import { QUERY_KEYS } from '../constants/ReactQueryKeys'
import { graphService } from 'services/graphService'
import { toast } from 'react-toastify'
import { DomainsStructure } from 'types/DomainsStructure'
import { useTranslation } from 'react-i18next'

type ReturnType = Omit<UseQueryResult, 'data'> & {
  structure: UseQueryResult<DomainsStructure>['data']
}

const useFetchStructure = (): ReturnType => {
  const { clientCode = '' } = useCurrentUser()
  const { t } = useTranslation()

  const { data: structure, ...rest } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.FILTER(clientCode),
    queryFn: () => graphService.getFiltersData(clientCode),
    onError: () => toast.error(t('api.unknown')),
    enabled: !!clientCode,
    cacheTime: Infinity,
  })

  return {
    structure,
    ...rest,
  }
}

export default useFetchStructure

import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../../../atoms/button/Button'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../atoms/dialog/Dialog'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Select from '../../../atoms/select/Select'
import Option from '../../../atoms/select/Option'
import Switch from 'react-switch'

import { automaticSending } from 'types/SavedReportsTypes'

import * as Yup from 'yup'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { savedReportsService } from 'services/savedReportsService'
import IconSvg from '../../../atoms/icons/IconSvg'
import DialogTitle from 'components/atoms/dialog/DialogTitle'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('forms.errors.required'),
})

type Props = {
  open: boolean
  dashboardId: number
  dashboardName: string
  onClose: () => void
}

const AutomaticSendingDialog: FC<Props> = (props) => {
  const { open, onClose, dashboardId, dashboardName } = props
  const { t } = useTranslation()
  const [selected, setSelected] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [enabled, setEnabled] = useState(true)

  const [emails, setEmails] = useState([])
  const [currentEmail, setCurrentEmail] = useState('')

  const handleEmailChange = (event) => {
    setCurrentEmail(event.target.value)
  }

  const handleEmailKeyPress = (event) => {
    if (event.key === 'Enter' && currentEmail.trim() !== '') {
      event.preventDefault()
      setEmails((prevEmails) => [...prevEmails, currentEmail])
      setCurrentEmail('')
    }
  }
  const automaticSendingForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: 'defaultName',
    },
  })
  const { clientCode = '' } = useCurrentUser()

  const { isLoading, mutate: configure } = useMutation({
    mutationKey: QUERY_KEYS.DASHBOARDS.PUT_AUTOMATIC_SENDING(clientCode, dashboardId),
    mutationFn: () => {
      return savedReportsService.automaticSendingDashboard(
        clientCode,
        selected,
        dashboardId,
        emails,
        enabled,
        selectedLanguage,
      )
    },
    onSuccess: async () => {
      refetch()
      toast.success(t('dashboard.automaticSendingSuccess'))
      onClose()
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  const onSubmit = () => {
    configure()
    refetch()
  }
  const onModalClose = () => {
    refetch()
    onClose()
  }

  const periodicity = [
    { label: t('dashboard.daily'), value: 'DAILY' },
    { label: t('dashboard.weekly'), value: 'WEEKLY' },
    { label: t('dashboard.monthly'), value: 'MONTHLY' },
    { label: t('dashboard.quarterly'), value: 'QUARTERLY' },
  ]
  const languages = [
    { label: t('dashboard.english'), value: 'en' },
    { label: t('dashboard.french'), value: 'fr' },
  ]

  const handleEmailDelete = (index) => {
    setEmails((prevEmails) => prevEmails.filter((_, i) => i !== index))
  }
  const { data, refetch } = useQuery({
    queryKey: QUERY_KEYS.DASHBOARDS.GET_AUTOMATIC_SENDING(clientCode, dashboardId),
    queryFn: () => savedReportsService.getAutomaticSendingDashboard(clientCode, dashboardId),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })
  useEffect(() => {
    if (data) {
      setSelected((data as automaticSending).periodicity)
      setEmails((data as automaticSending).recipients)
      setEnabled((data as automaticSending).activated)
      setSelectedLanguage((data as automaticSending).language)
    }
  }, [data])

  const setLabel = (selected: string) => {
    switch (selected) {
      case 'DAILY':
        return t('dashboard.daily')
      case 'WEEKLY':
        return t('dashboard.weekly')
      case 'MONTHLY':
        return t('dashboard.monthly')
      case 'QUARTERLY':
        return t('dashboard.quarterly')
    }
  }

  return (
    <Dialog isOpen={open} onClose={onModalClose} className={'max-w-3xl rounded-none'}>
      <DialogTitle onClose={onClose}>
        <div className="flex justify-center items-center">
          <h2 className="mx-3 font-bold" style={{ fontSize: '1.5rem' }}>
            {dashboardName}
          </h2>
          <Switch
            width={36}
            height={20}
            handleDiameter={16}
            onChange={setEnabled}
            checked={enabled}
            onColor="#d33087"
            offColor="#c0c0c4"
            activeBoxShadow="transparent"
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      </DialogTitle>

      <DialogBody>
        <FormProvider {...automaticSendingForm}>
          <form onSubmit={automaticSendingForm.handleSubmit(onSubmit)} className="flex flex-col flex-wrap">
            <div className="flex flex-col items-center justify-center m-8">
              <div className="space-y-4 w-2/3">
                <h4 className=" text-left my-3 font-bold">{t('dashboard.pdfLanguage')}</h4>
                <Select
                  label={languages.find((elt) => elt.value === selectedLanguage)?.label}
                  placeholder={t('dashboard.language')}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                >
                  {languages.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <span>{item.label}</span>
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="space-y-4 w-2/3">
                <h4 className=" text-left my-3 font-bold">{t('dashboard.periodicity')}</h4>
                <Select
                  label={setLabel(selected)}
                  placeholder={t('dashboard.periodicity')}
                  value={selected}
                  onChange={setSelected}
                >
                  {periodicity.map((item) => (
                    <Option key={item.value} value={item.value}>
                      <span>{item.label}</span>
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="flex flex-col w-2/3 mt-4">
                <h4 className="text-left my-2 rounded-md font-bold">{t('dashboard.recipients')}</h4>
                <div className="w-full">
                  <input
                    style={{ minWidth: '21rem', backgroundColor: ' #F5F5FF ', width: '100%' }}
                    className="mb-3 p-2 rounded-lg"
                    name="email"
                    type="email"
                    value={currentEmail}
                    onChange={handleEmailChange}
                    onKeyDown={handleEmailKeyPress}
                  />
                  <div className="flex flex-wrap">
                    {emails.map((email, index) => (
                      <div
                        className="flex items-center pl-1 rounded-lg m-1"
                        key={index}
                        style={{
                          backgroundColor: '#1CB9D6',
                          color: '#ffff',
                          fontSize: '12px',
                          minWidth: '30%',
                          height: '1.5rem',
                        }}
                      >
                        {email}

                        <IconSvg
                          name="CLOSE"
                          className="m-2 mini-close"
                          color="WHITE"
                          onClick={() => handleEmailDelete(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-center space-x-4">
              <Button type="submit" disabled={selected === undefined || isLoading}>
                {t('common.confirm')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </DialogBody>
    </Dialog>
  )
}

export default AutomaticSendingDialog

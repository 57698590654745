import { FC, useEffect, useState } from 'react'
import IconSvg from '../../atoms/icons/IconSvg'
import AddReportActions from '../../molecules/addReportToDashboard/AddReportToDashboardActions'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { PagesType } from 'types/DashboardType'

type Props = {
  editedPage?: PagesType
  editedPosition: number
  dashboardName: string
}
const AddReport: FC = (props: Props) => {
  const { editedPage, editedPosition, dashboardName } = props
  const [SearchReport, setSearchReport] = useState(false)
  const { t } = useTranslation()
  const onClose = () => {
    setSearchReport(false)
  }

  return (
    <div
      className="flex items-center justify-center border-2"
      style={{ borderColor: 'rgba(59, 130, 246, 0.7)', margin: '0 12px' }}
    >
      <a
        className="cursor-pointer"
        data-tip
        data-for="editDashboard"
        onClick={() => {
          setSearchReport(true)
        }}
      >
        <IconSvg name="PLUS" />
      </a>
      <ReactTooltip id="editDashboard" className="breakLine" type="info">
        <span>{t('dashboard.editTooltip')}</span>
      </ReactTooltip>
      {SearchReport && (
        <AddReportActions
          onClose={onClose}
          editedPage={editedPage}
          editedPosition={editedPosition}
          dashboardName={dashboardName}
        />
      )}
    </div>
  )
}

export default AddReport

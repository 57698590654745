import { FC } from 'react'
import PageLayout from './PageLayout'
import { useTranslation } from 'react-i18next'
import { jooxter_link } from 'utils/constants/forms/login'

type Props = {
  showLogo?: boolean
}

const AuthPageLayout: FC<Props> = (props) => {
  const { showLogo = true, children } = props
  const { t } = useTranslation()

  return (
    <PageLayout className="bg-basic bg-auth-top-right flex-grow h-screen bg-right-top bg-no-repeat">
      <div className="bg-auth-bottom-left h-full bg-left-bottom bg-no-repeat">
        <div className="flex flex-col justify-center mx-auto px-4 w-full h-full md:w-1/2 lg:w-2/5">
          {showLogo && (
            <div className="mb-8 mx-auto">
              <img src="/images/logo_white.png" alt={t('auth.logo')} />
            </div>
          )}
          {children}
          <div className="text-basic-100 flex flex-col justify-center mt-8 text-center">
            <a href={jooxter_link} target="_blank" rel="noopener noreferrer">
              Jooxter.com
            </a>
            {/*<NavLink to={PATHS.TODO} className="mt-5">*/}
            {/*  {t('auth.login.help')}*/}
            {/*</NavLink>*/}
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default AuthPageLayout

import { FC } from 'react'
import { FILTER_SKELETON_SIZE } from 'styles/Filters'

type Props = {
  type?: 'short' | 'normal' | 'long'
}

const FilterSkeleton: FC<Props> = (props) => {
  const { type = 'normal' } = props

  return (
    <div className="animate-pulse">
      <div className={`my-2 h-12 ${FILTER_SKELETON_SIZE[type]} bg-basic-300 mx-2 rounded-xl`} />
    </div>
  )
}

export default FilterSkeleton

import { FC, useState, useEffect } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useQuery } from 'react-query'
import { sitesService } from 'services/sitesService'
import Select from 'components/atoms/select/Select'
import Option from 'components/atoms/select/Option'
import { DomainType } from 'types/User'
import ReactTooltip from 'react-tooltip'

import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import IconSvg from 'components/atoms/icons/IconSvg'
import EditIdCardDialog from 'components/molecules/dialogs/IdCard/EditIdCardDialog'

const IdCardView: FC = () => {
  const { t } = useTranslation()
  const { data: user, clientCode = '', isLoading: isUserLoading } = useCurrentUser()
  const currentClientData = user?.clients.find((client) => client.code === clientCode)
  const [selected, setSelected] = useState<DomainType>()
  const [clientDomain, setClientDomain] = useState()
  const [domain, setDomain] = useState(currentClientData?.domains[0].path.replaceAll('/', '$'))

  const [openEditCardDialog, setOpenEditCardDialog] = useState(false)
  const { data: siteData, refetch } = useQuery({
    queryFn: () => {
      if (currentClientData?.domains.find((elt) => elt.path == selected?.path) !== undefined) {
        return selected !== undefined
          ? sitesService.getOne(clientCode, selected?.url_path)
          : //@ts-ignore
            sitesService.getOne(clientCode, localStorage.getItem('firstDomain')?.replaceAll('"', ''))
      }
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!currentClientData,
  })
  const { data: pictureData, refetch: reload } = useQuery({
    queryKey: QUERY_KEYS.SAVED_REPORTS,
    queryFn: () =>
      selected !== undefined
        ? sitesService.getPicture(clientCode, selected!.url_path)
        : sitesService.getPicture(clientCode, localStorage.getItem('firstDomain')?.replaceAll('"', '')),
    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!clientCode,
  })
  useEffect(() => {
    setSelected(currentClientData?.domains[0])
    if (!isUserLoading) {
      setClientDomain(currentClientData?.domains.find((elt) => elt.path == selected?.path))
    }
  }, [clientCode])
  useEffect(() => {
    refetch()
  }, [clientDomain])

  useEffect(() => {
    if (!isUserLoading) {
      if (selected) {
        refetch()
      }
      selected
        ? setDomain(selected.path.replaceAll('/', '$'))
        : setDomain(currentClientData?.domains[0]!.path.replaceAll('/', '$'))
    }
  }, [selected, pictureData])
  const onClose = () => {
    setOpenEditCardDialog(false)
    refetch()
    reload()
  }
  useEffect(() => {
    if (selected) reload()
  }, [selected])
  return (
    <>
      <div className="flex w-1/4 justify-center my-2 mx-8 ">
        <Select
          label={selected?.name}
          placeholder={t('home.dialog.placeholder')}
          value={selected}
          onChange={setSelected}
        >
          <Option value={undefined} disabled>
            <span>{t('home.dialog.placeholder')}</span>
          </Option>
          {currentClientData?.domains.map((client) => (
            <Option key={client.url_path} value={client}>
              <span>{client.name}</span>
              <span> - </span>
              <span className="text-primary text-b2">{client.path}</span>
            </Option>
          ))}
        </Select>
      </div>
      {siteData && (
        <div className="flex justify-between" style={{ height: '36vh' }}>
          {pictureData && (
            <figure className=" rounded-xl w-1/2 my-5 mx-8" style={{ backgroundColor: '#42424f' }}>
              <img
                className="rounded-xl"
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                src={
                  pictureData.picture === null || pictureData.picture === undefined
                    ? '/images/default.png'
                    : 'data:image/jpeg;base64,' + pictureData.picture
                }
                alt=""
              />

              <figcaption className="my-2">
                <span className="font-bold">{siteData.domainName}</span> / {siteData.address}
              </figcaption>
            </figure>
          )}
          <div className="w-1/2 m-5 ">
            <div
              className="flex justify-between rounded-xl px-6 py-2"
              style={{ backgroundColor: '#D33087', color: 'white' }}
            >
              <div className="flex flex-col	text-center	">
                <h2 className="text-xl" style={{ fontSize: '1rem' }}>
                  {t('idCard.presy')}
                </h2>
                <small>{t('idCard.presyExc')}</small>
                <p style={{ fontSize: '1.5rem' }}>
                  {siteData.potentialSavings ? siteData.potentialSavings.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '__'}
                </p>
              </div>
              <div className="flex flex-col	text-center ">
                <h2 className="text-xl" style={{ fontSize: '1rem' }}>
                  {t('idCard.tco')}
                </h2>
                <small>{t('idCard.tcoExc')}</small>
                <p style={{ fontSize: '1.5rem' }}>
                  {siteData.totalCostOfOwnership
                    ? siteData.totalCostOfOwnership.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    : '__'}
                </p>
              </div>
              <div className="flex flex-col text-center">
                <h2 className="text-xl" style={{ fontSize: '1rem' }}>
                  {siteData.areaUnit?.toUpperCase() ?? 'M²'}
                </h2>
                <small>{t('idCard.areaSavingsExc')}</small>
                <p style={{ fontSize: '1.5rem' }}>
                  {siteData.potentialAreaSavings ? siteData.potentialAreaSavings + siteData.areaUnit ?? 'm²' : '__'}
                </p>
              </div>
            </div>
            <div className="flex justify-between text-2xl">
              <div className="w-1/2 m-1 rounded-xl">
                <h4 className="font-bold my-1">{t('idCard.population')}</h4>
                <p
                  className=" rounded-xl align-center"
                  style={{ fontSize: '1.1rem', padding: '2rem', backgroundColor: '#eaeaeb' }}
                >
                  {siteData.population ? siteData.population : '__'}
                </p>
              </div>
              <div className="w-1/2 m-1 ">
                <h4 className="font-bold my-1">{t('idCard.totalSqm')}</h4>
                <p className="rounded-xl" style={{ fontSize: '1.1rem', padding: '2rem', backgroundColor: '#eaeaeb' }}>
                  {siteData.totalArea ? `${siteData.totalArea + siteData.areaUnit ?? 'm²'}` : '__'}
                </p>
              </div>
              <div className="w-1/2 m-1 ">
                <h4 className="font-bold my-1">{t('idCard.totalSeats')}</h4>
                <p className="rounded-xl" style={{ fontSize: '1.1rem', padding: '2rem', backgroundColor: '#eaeaeb' }}>
                  {siteData.seatsCount ? siteData.seatsCount + ' ' + t('idCard.seat') : '__'}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {siteData && (
        <div className="overview-capacity flex flex-col my-2 mx-8">
          <h2 className="font-bold my-4 text-2xl">{t('idCard.overviewCapacity')}</h2>

          <div
            className="flex flex-wrap justify-between px-6 pb-4 rounded-xl mb-5 relative"
            style={{ backgroundColor: '#eaeaeb' }}
          >
            <div
              className="border-2  absolute cursor-pointer 	p-2 top-5 right-5 rounded-xl text-basic-400"
              onClick={() => setOpenEditCardDialog(true)}
            >
              <IconSvg name="EDIT" color="GREY" />
            </div>
            <div className="flex flex-col  items-center  h-100">
              {/* <IconSvg name="DESK" color="WHITE" className="ml-2" /> */}
              <img style={{ width: '130px' }} src="/images/desk.png" />
              <div className="text-center">
                <p className="my-1">
                  {siteData.desks ? siteData.desks.count : '__'} {t('idCard.desks')}
                </p>
                <p className="mb-4">{siteData.desks ? siteData.desks.area + siteData.areaUnit : '__'}</p>
                <div
                  style={{
                    position: 'relative',
                    bottom: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ width: '45%' }}>
                    <CircularProgressbarWithChildren
                      value={siteData.desks ? siteData.desks.yearlyNinthDecileDailyMaxOccupancyRate : ''}
                      text={`${
                        siteData.desks
                          ? siteData.desks.yearlyNinthDecileDailyMaxOccupancyRate.toFixed(1).replace(/(\.0+|0+)$/, '')
                          : ''
                      }%`}
                      styles={buildStyles({
                        pathColor: '#D33087',
                        trailColor: 'transparent',
                        textColor: '#D33087',
                        strokeLinecap: 'butt',
                      })}
                    >
                      <div data-tip data-for="desks" style={{ width: '84%' }}>
                        <CircularProgressbar
                          value={siteData.desks ? siteData.desks.yearlyAverageDailyMaxOccupancyRate : ''}
                          styles={buildStyles({
                            pathColor: '#FF7254',
                            trailColor: 'transparent',
                            strokeLinecap: 'butt',
                          })}
                        />
                      </div>
                      <ReactTooltip id="desks" type="light">
                        <div className="flex items-center">
                          <div
                            style={{
                              height: '12px',
                              width: '12px',
                              backgroundColor: '#FF7254',
                              borderRadius: '50%',
                              marginRight: '5px',
                            }}
                          ></div>
                          {t('idCard.average')} :{' '}
                          {siteData.desks ? siteData.desks.yearlyAverageDailyMaxOccupancyRate : ''}
                        </div>
                        <div className="flex items-center">
                          <div
                            style={{
                              height: '12px',
                              width: '12px',
                              backgroundColor: '#D33087',
                              borderRadius: '50%',
                              marginRight: '5px',
                            }}
                          ></div>
                          {t('idCard.ninthDecile')} :{' '}
                          {siteData.desks ? siteData.desks.yearlyNinthDecileDailyMaxOccupancyRate : ''}
                        </div>
                      </ReactTooltip>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col  items-center">
              <img style={{ width: '130px' }} src="/images/bubble.png" />
              <div className="text-center">
                <p className="my-1">
                  {siteData.focusRooms ? siteData.focusRooms.count : '__'} {t('idCard.bubbles')}
                </p>
                <p className="mb-4">{siteData.focusRooms ? siteData.focusRooms.area + siteData.areaUnit : '__'}</p>

                <div style={{ position: 'relative', bottom: '0px', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '45%' }}>
                    <CircularProgressbarWithChildren
                      value={siteData.focusRooms ? siteData.focusRooms.yearlyNinthDecileDailyMaxOccupancyRate : ''}
                      text={`${
                        siteData.focusRooms
                          ? siteData.focusRooms.yearlyNinthDecileDailyMaxOccupancyRate
                              .toFixed(1)
                              .replace(/(\.0+|0+)$/, '')
                          : ''
                      }%`}
                      styles={buildStyles({
                        pathColor: '#D33087',
                        trailColor: 'transparent',
                        textColor: '#D33087',
                        strokeLinecap: 'butt',
                      })}
                    >
                      <div data-tip data-for="focusRooms" style={{ width: '84%' }}>
                        <CircularProgressbar
                          value={siteData.focusRooms ? siteData.focusRooms.yearlyAverageDailyMaxOccupancyRate : ''}
                          styles={buildStyles({
                            strokeLinecap: 'butt',

                            pathColor: `#FF7254`,
                            trailColor: 'transparent',
                          })}
                        />
                      </div>
                      <ReactTooltip id="focusRooms" type="light">
                        <div className="flex items-center">
                          <div
                            style={{
                              height: '12px',
                              width: '12px',
                              backgroundColor: '#FF7254',
                              borderRadius: '50%',
                              marginRight: '5px',
                            }}
                          ></div>
                          {t('idCard.average')} :{' '}
                          {siteData.focusRooms ? siteData.focusRooms.yearlyAverageDailyMaxOccupancyRate : ''}
                        </div>
                        <div className="flex items-center">
                          <div
                            style={{
                              height: '12px',
                              width: '12px',
                              backgroundColor: '#D33087',
                              borderRadius: '50%',
                              marginRight: '5px',
                            }}
                          ></div>
                          {t('idCard.ninthDecile')} :{' '}
                          {siteData.focusRooms ? siteData.focusRooms.yearlyNinthDecileDailyMaxOccupancyRate : ''}
                        </div>
                      </ReactTooltip>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center mr-12">
              <img style={{ width: '160px', height: '127px' }} src="/images/meetingroom.png" />

              <p className="my-1">
                {siteData.meetingRooms ? siteData.meetingRooms.count : '__'} {t('idCard.meetingRoom')}
              </p>
              <p className="mb-4">{siteData.meetingRooms ? siteData.meetingRooms.area + siteData.areaUnit : '__'}</p>
              <div className="flex items-center relative" style={{ bottom: '-20px' }}>
                <div className="text-center">
                  <p style={{ marginTop: '-60px', marginBottom: '20px', fontSize: '14px' }}>{t('idCard.occupancy')}</p>
                  <div style={{ position: 'relative', bottom: '0px', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '45%' }}>
                      <CircularProgressbarWithChildren
                        value={
                          siteData.meetingRooms ? siteData.meetingRooms.yearlyNinthDecileDailyMaxOccupancyRate : ''
                        }
                        text={`${
                          siteData.meetingRooms
                            ? siteData.meetingRooms.yearlyNinthDecileDailyMaxOccupancyRate
                                .toFixed(1)
                                .replace(/(\.0+|0+)$/, '')
                            : ''
                        }%`}
                        styles={buildStyles({
                          pathColor: '#D33087',
                          trailColor: 'transparent',
                          textColor: '#D33087',
                          strokeLinecap: 'butt',
                        })}
                      >
                        <div data-tip data-for="meetingRooms" style={{ width: '84%' }}>
                          <CircularProgressbar
                            value={
                              siteData.meetingRooms ? siteData.meetingRooms.yearlyAverageDailyMaxOccupancyRate : ''
                            }
                            styles={buildStyles({
                              strokeLinecap: 'butt',

                              pathColor: `#FF7254`,
                              trailColor: 'transparent',
                              backgroundColor: 'black',
                            })}
                          />
                        </div>
                        <ReactTooltip id="meetingRooms" type="light">
                          <div className="flex items-center">
                            <div
                              style={{
                                height: '12px',
                                width: '12px',
                                backgroundColor: '#FF7254',
                                borderRadius: '50%',
                                marginRight: '5px',
                              }}
                            ></div>
                            {t('idCard.average')} :{' '}
                            {siteData.meetingRooms ? siteData.meetingRooms.yearlyAverageDailyMaxOccupancyRate : ''}
                          </div>
                          <div className="flex items-center">
                            <div
                              style={{
                                height: '12px',
                                width: '12px',
                                backgroundColor: '#D33087',
                                borderRadius: '50%',
                                marginRight: '5px',
                              }}
                            ></div>
                            {t('idCard.ninthDecile')} :{' '}
                            {siteData.meetingRooms ? siteData.meetingRooms.yearlyNinthDecileDailyMaxOccupancyRate : ''}
                          </div>
                        </ReactTooltip>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
                <div className="text-center ml-4">
                  <p
                    style={{
                      marginTop: '-80px',
                      marginBottom: '20px',
                      fontSize: '14px',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      width: '50%',
                    }}
                  >
                    {t('idCard.underOccupied')}
                  </p>
                  <div
                    data-tip
                    data-for="underOccupied"
                    style={{ position: 'relative', bottom: '0px', display: 'flex', justifyContent: 'center' }}
                  >
                    <div style={{ width: '45%' }}>
                      <CircularProgressbarWithChildren
                        value={siteData.meetingRooms ? siteData.meetingRooms.yearlyUnderOccupiedRate : ''}
                        text={`${
                          siteData.meetingRooms
                            ? siteData.meetingRooms.yearlyUnderOccupiedRate.toFixed(1).replace(/(\.0+|0+)$/, '')
                            : ''
                        }%`}
                        styles={buildStyles({
                          strokeLinecap: 'butt',
                          pathColor: `#FF7254`,
                          trailColor: 'transparent',
                          textColor: '#D33087',
                        })}
                      >
                        <ReactTooltip id="underOccupied" type="light">
                          <div className="flex items-center">
                            <div
                              style={{
                                height: '12px',
                                width: '12px',
                                backgroundColor: '#FF7254',
                                borderRadius: '50%',
                                marginRight: '5px',
                              }}
                            ></div>
                            {t('idCard.average')} :{' '}
                            {siteData.meetingRooms ? siteData.meetingRooms.yearlyUnderOccupiedRate : ''}
                          </div>
                        </ReactTooltip>
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openEditCardDialog && (
        <EditIdCardDialog siteData={siteData} open={openEditCardDialog} onClose={onClose} domainPath={domain || ''} />
      )}
    </>
  )
}

export default IdCardView

import { FC } from 'react'
import PageLayout from 'components/layouts/PageLayout'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import SingleReportView from './SingleReportView'
import DashboardView from './DashboardView'
import { generatePath } from 'utils/routeUtils'
import useFetchStructure from 'utils/hooks/useFetchStructure'

const AnalysisView: FC = () => {
  const { url } = useRouteMatch()
  // We preload the structure data, regardless of the tab the user is on. So that if he switches from "Dashboard" to
  // "Single Report", the query (which takes quite some time) has partially or fully loaded and it improves the user experience.
  useFetchStructure()

  return (
    <PageLayout>
      <Switch>
        <Route exact path={generatePath(PATHS.ANALYSIS.SINGLE_REPORT, { url })} component={SingleReportView} />
        <Route
          exact
          path={generatePath(PATHS.ANALYSIS.DASHBOARD, {
            url,
          })}
          component={DashboardView}
        />
        <Route
          path={PATHS.ALL}
          component={() => <Redirect to={generatePath(PATHS.ANALYSIS.SINGLE_REPORT, { url })} />}
        />
      </Switch>
    </PageLayout>
  )
}

export default AnalysisView

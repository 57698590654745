import { yupResolver } from '@hookform/resolvers/yup'
import { COLORS } from 'assets/colors/colors'
import { AxiosError } from 'axios'
import Button from 'components/atoms/button/Button'
import FormInput from 'components/atoms/input/FormInput'
import LoginPageLayout from 'components/layouts/LoginPageLayout'
import { useAuth } from 'core/auth/useAuth'
import { useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { NavLink, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from 'services/authService'
import { TokenResponse, UserData } from 'types/Auth'
import { inputs, passwordValidationSchema } from 'utils/constants/forms/login'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { PATHS } from 'utils/constants/routes/Paths'

const LoginPassword = (): JSX.Element => {
  const { search } = useLocation()
  const identifier = useMemo(() => new URLSearchParams(search).get('identifier'), [search])
  const { t } = useTranslation()
  const [, setCookie] = useCookies(['fa2_token'])
  const [formData, setFormData] = useState<UserData>()
  const [doubleAuth, setDoubleAuth] = useState(false)
  const authContext = useAuth()

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(passwordValidationSchema),
  })

  const { mutate, isLoading } = useMutation({
    mutationKey: QUERY_KEYS.AUTH.TOKEN,
    mutationFn: authService.getToken,
    onSuccess: (data: TokenResponse) => {
      const { access_token, refresh_token } = data

      if (!authContext) {
        return
      }

      const { setAccessToken, setRefreshToken } = authContext

      // @TODO upgrade to react 18
      setAccessToken(access_token)
      setRefreshToken(refresh_token)
    },
    onError: (a: AxiosError) => {
      if (a.response?.data.fa2_token)
        setCookie('fa2_token', a.response?.data.fa2_token, {
          path: PATHS.HOME,
          secure: true,
          sameSite: 'none',
        })
      const errorMessage = a.response?.data.error_description
      const fa2Active = a.response?.data.error === 'fa2_required'
      if (fa2Active) {
        authService.sendCode({ identifier: `${formData?.username}` })
        setDoubleAuth(true)
      }

      if (errorMessage) {
        doubleAuth
          ? form.setError('fa2_code', { message: `api.${errorMessage}` })
          : form.setError('password', { message: `api.${errorMessage}` })
      }
    },
  })

  const onSubmit = async (data: UserData) => {
    if (identifier) {
      setFormData({ ...data, username: identifier })
      mutate({ ...data, username: identifier })
    }
  }

  const resendCode = () => {
    authService.sendCode({ identifier: `${formData?.username}` }).then(() => {
      toast.success(t('auth.login.sendCode'))
    })
  }

  return (
    <LoginPageLayout>
      <FormProvider {...form}>
        <form className="login-form" onSubmit={form.handleSubmit(onSubmit)}>
          <h4 style={{ fontWeight: 'bold', fontSize: '34px', textAlign: 'center' }}>{t('auth.login.login')}</h4>
          {!doubleAuth && (
            <>
              <label style={{ textTransform: 'uppercase', fontSize: '12px', margin: '0 0.75rem' }}>
                {t(inputs[1].placeholder)}
              </label>
              <FormInput
                key={inputs[1].name}
                name={inputs[1].name}
                placeholder={t(inputs[1].placeholder)}
                className="mb-6"
                type={inputs[1].type}
              />
              <div className="flex justify-between m-3" style={{ marginTop: '-20px' }}>
                <NavLink to={PATHS.AUTH.FORGOT_PASSWORD} className="text-basic-100" style={{ fontSize: '14px' }}>
                  {t('auth.login.forgotPassword')}
                </NavLink>
                <a
                  href={t('support.support-url')}
                  target="_blank"
                  className="text-basic-100"
                  style={{ fontSize: '14px' }}
                  rel="noopener noreferrer"
                >
                  {t('auth.support')}
                </a>
              </div>
            </>
          )}
          {doubleAuth && (
            <>
              <div className="text-center" style={{ color: '#fff' }}>
                <h1 style={{ fontSize: '1.7rem', fontWeight: '600', margin: '2rem' }}>{t('auth.doubleAuth.title')}</h1>
                <p className="line-break">{t('auth.doubleAuth.description')}</p>
              </div>
              <FormInput
                className="my-2"
                name="fa2_code"
                placeholder={t('forms.fields.code')}
                type="text"
                //@ts-ignore
                minLength={6}
              />
              <button className="text-basic-100 text-c1" onClick={resendCode} type="button">
                {t('auth.doubleAuth.resend')}
              </button>
            </>
          )}

          <div className="flex justify-center">
            <Button
              style={{ backgroundColor: COLORS.deepCerise, marginBottom: '0px' }}
              type="submit"
              disabled={isLoading}
            >
              {t('auth.login.submit')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </LoginPageLayout>
  )
}

export default LoginPassword

import { uniq, flattenDeep } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useCurrentUser from './useCurrentUser'
import { DomainType, PermissionType } from '../../types/User'
import { PermissionEnum } from 'utils/constants/permissions'
import { LoggedInRoutes, RouteType } from 'utils/constants/routes/AppRoutes'
import { PATHS } from 'utils/constants/routes/Paths'

export const usePermissions = (): {
  permissions: string[]
  userCanAccessRoute: (route?: RouteType) => boolean
  redirectUrlForLoggedInUser: string | undefined
  hasUser: boolean
} => {
  const { data: user } = useCurrentUser()
  const [permissions, setPermissions] = useState<string[]>([])
  const currentClient = useMemo(() => user?.clients.find((client) => client.code === user?.currentClient.code), [user])
  const homeRoute = LoggedInRoutes.find((route) => route.path === PATHS.HOME)
  const realTimeRoute = LoggedInRoutes.find((route) => route.path === PATHS.REAL_TIME.HOME)

  const userCanAccessRoute = useCallback(
    (route?: RouteType): boolean => {
      if (!route) {
        return false
      }

      if (!route.permissions || (route.permissions && route.permissions.length === 0)) {
        return true
      }

      return route.permissions.reduce<boolean>(
        (prev, permission) => prev || permissions.includes(permission as unknown as string),
        false,
      )
    },
    [permissions],
  )

  /*
   * If the user is not logged in or has no permissions, returns undefined
   */
  const redirectUrlForLoggedInUser = useMemo(() => {
    if (!user) {
      return
    }

    if (userCanAccessRoute(homeRoute)) {
      return PATHS.HOME
    } else if (userCanAccessRoute(realTimeRoute)) {
      return PATHS.REAL_TIME.HOME
    }
  }, [userCanAccessRoute, user])

  useEffect(() => {
    const result: Array<string[] | string> = []

    if (currentClient) {
      const superAdminPermission = currentClient.permissions?.find(
        (per) => per.functionality === PermissionEnum.ANALYTICS_SUPER_ADMIN,
      )

      if (superAdminPermission) {
        result.push(superAdminPermission as unknown as string)
      }

      if (currentClient.permissions && currentClient.permissions.length > 0) {
        result.push(...[currentClient.permissions.map((p) => p.permission).filter((p): p is string => !!p)])
      }

      currentClient.domains?.forEach((domain) =>
        result.push([
          ...((domain as DomainType).permissions
            ?.map((p: PermissionType) => p.permission)
            .filter((p): p is string => !!p) ?? []),
        ]),
      )
    }

    setPermissions(uniq(flattenDeep(result)))
  }, [currentClient])

  return { permissions, userCanAccessRoute, redirectUrlForLoggedInUser, hasUser: !!user }
}

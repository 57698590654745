import { ICON_SVG_MAP } from 'components/atoms/icons/IconSvgMap'
import { iconColors } from 'components/atoms/icons/IconSvg'

export const FILTER_STYLE = {
  PRIMARY: 'border-primary bg-primary text-basic-100',
  OUTLINED: 'border-basic-300 bg-transparent text-basic-300',
  OUTLINED_BLACK: 'border-basic-600 bg-basic-200 text-basic-600',
  BLACK: 'border-basic bg-basic text-basic-100',
  TRANSPARENT: 'text-basic-600 bg-basic-200',
  TRANSPARENT_PRIMARY: 'text-basic-600 bg-transparent '
}

export const FILTER_SKELETON_SIZE = {
  short: 'w-24',
  normal: 'w-32',
  long: 'w-48',
}

export const FILTER_ARROW_ICONS: {
  openIcon: keyof typeof ICON_SVG_MAP
  closeIcon: keyof typeof ICON_SVG_MAP
} & Record<keyof typeof FILTER_STYLE, keyof typeof iconColors> = {
  openIcon: 'ARROW_UP',
  closeIcon: 'ARROW_DOWN',
  PRIMARY: 'WHITE',
  BLACK: 'BLACK',
  OUTLINED: 'GREY',
  OUTLINED_BLACK: 'BLACK',
}

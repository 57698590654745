import { useQuery, UseQueryResult } from 'react-query'
import { QUERY_KEYS } from '../constants/ReactQueryKeys'
import { authService } from 'services/authService'
import { User } from 'types/User'
import { useQueryParams } from 'hooks/useQueryParams'
import { useLocation } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'

type ReturnType = UseQueryResult<User> & {
  clientCode?: string
}

const useCurrentUser = (enabled = true): ReturnType => {
  const { apiKey } = useQueryParams()
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const codeInParams = queryParams.get('code')
  const stateInParams = queryParams.get('state')
  const isEnabled =
    enabled && !apiKey && !pathname.includes(PATHS.AUTH.RESET_PASSWORD) && !(codeInParams && stateInParams)
  const queryData = useQuery({
    queryKey: QUERY_KEYS.AUTH.USER_CURRENT,
    queryFn: authService.getCurrentUser,
    // We fetch the current user only if the token has been refreshed (to not make a call with an expired one)
    enabled: isEnabled,
    retry: 3,
    retryDelay: 100,
  })

  const clientCode = queryData.data?.currentClient.code

  return { ...queryData, clientCode }
}

export default useCurrentUser

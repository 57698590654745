import { FC, useContext, useEffect, useState } from 'react'
import ReactApexChart from '../../../atoms/graph/ReactApexChart'
import { heatmapConfig } from 'utils/constants/graphs/heatmap'
import { HeatmapSeries } from 'types/react-apexcharts/charts'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ChartProps } from 'types/react-apexcharts/react-apexcharts'
import { DetailedOccupancyDataType } from 'types/GraphDataType'
import { GraphFiltersContext } from '../../filters/required/GraphFiltersContext'
import { getMatch } from 'utils/dateUtils'

type Props = {
  title?: string
  id?: string | undefined
  subtitle: string[]
  series: HeatmapSeries
  categories: string[]
  minifiedCategories: string[]
  colors: string[]
}

const SpaceAttendanceChart: FC<Props> = (props) => {
  const { title, subtitle, series, categories, minifiedCategories, id, colors } = props

  const { state: filters } = useContext(GraphFiltersContext)
  const [formattedSeries, setFormattedSeries] = useState([])
  const { t } = useTranslation()
  const kpiValue = filters.KPI.value
  const options = heatmapConfig({
    id,
    subtitle,
    xAxisTitle: t('graph.detailedOccupancy.xaxis.hours'),
    categories,
    minifiedCategories,
    colors,
    tooltipFormatter: (seriesName, seriesValue, categories, dataPointIndex, seriesIndex, w) => {
      const strSeriesValue = seriesValue as unknown as number

      if (strSeriesValue === 0) {
        return '<div />'
      }

      const valueToDisplay = strSeriesValue === 1 ? 0 : Math.round(strSeriesValue / 10)

      return `
            <div class="px-3 py-2">
              <p class="font-bold">${seriesName} - ${categories[dataPointIndex]}</p>
              <p>${
                kpiValue === 'AVERAGE'
                  ? `${t('graph.detailedOccupancy.tooltip.averageOccupancy', { seriesValue: valueToDisplay })} (${
                      w.globals.initialSeries[seriesIndex].data[dataPointIndex].count
                    } ${t('graph.stackedColumn.people')})`
                  : `${t('graph.detailedOccupancy.tooltip.maxOccupancy', { seriesValue: valueToDisplay })} (${
                      w.globals.initialSeries[seriesIndex].data[dataPointIndex].count
                    } ${t('graph.stackedColumn.people')})`
              }</p>
            </div>
      `
    },
  })
  useEffect(() => {
    series.map((item) => {
      if (
        item.name !== ' ' &&
        item.name !== t('graph.detailedOccupancy.xaxis.max') &&
        item.name !== t('graph.filters.kpi.average') &&
        item.data.length <= categories.length
      ) {
        let i = 0
        do {
          if (!getMatch(item.data, categories).includes(i)) {
            item.data.splice(i, 0, { x: 'null', y: 0 })
          }
          i = i + 1
        } while (i <= categories.length - 4)
      }
    })

    setFormattedSeries(series)
  }, [series])

  // The key property is a quick and dirty fix to solve a weird problem : when this graph replaces the "loading" one,
  // it appears messed up : no label, dashed lanes and the xAxis header are missplaced, no tooltip on hover, and so on.
  // By specifying the key, we tell React that they are completely different components, and thus it rerenders them
  // completely instead of doing some weird trick.
  return (
    formattedSeries && (
      <CustomHeatmapChart
        key={`heatmap_${JSON.stringify(series)}`}
        series={formattedSeries}
        type="heatmap"
        options={options}
      />
    )
  )
}

const CustomHeatmapChart = styled(({ series, options, ...rest }: ChartProps<DetailedOccupancyDataType>) => {
  return (
    <>
      <ReactApexChart
        key={`heatmap_${JSON.stringify(series)}`}
        series={series}
        type="heatmap"
        options={options}
        {...rest}
      />
    </>
  )
})`
  & [class='apexcharts-legend apexcharts-align-right position-top'] > [seriesname='0x'] {
    display: none !important;
  }
`

export default SpaceAttendanceChart

import { FC, useEffect, useMemo, useState } from 'react'
import {
  FilterState,
  GraphFiltersContext,
  initialFilterState,
} from 'components/molecules/filters/required/GraphFiltersContext'
import ChartLayout from 'components/layouts/ChartLayout'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useMutation } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { graphService } from 'services/graphService'
import { toast } from 'react-toastify'
import AnalysisFilters from 'components/molecules/filters/AnalysisFilters'
import AnalysisButtons from 'components/molecules/filters/AnalysisButtons'
import { useTranslation } from 'react-i18next'
import { GraphType } from 'components/molecules/filters/required/GraphFilter'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import useBreakpoint from 'utils/hooks/useBreakpoint'
import FilterSkeletons from 'components/molecules/skeletons/FilterSkeletons'
import { chartLayoutSizes } from 'utils/constants/graphs/global'
import AnalysisGraphs from 'components/molecules/filters/AnalysisGraphs'
import { getExportFormattedData } from 'utils/exportUtils'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import InformativeSection from 'components/molecules/informativeSection/informativeSection'
import { useLocation, useHistory } from 'react-router-dom'
import { getLabelsfilter } from 'utils/filtersUtils'
import moment from 'moment'

const SingleReportView: FC = () => {
  const [filterState, setFilterState] = useState(initialFilterState)
  if (filterState && filterState.GRAPH.value == 'BENCHMARK') filterState.GRAPH.value = undefined
  const [displayInformativeSection, setDisplayInformativeSection] = useState(false)
  const [urlGraph, setUrlGraph] = useState('')
  const [configuration, setConfiguration] = useState()
  const [selectedSite, setSelectedSite] = useState()
  const [zoom, setZoom] = useState(null)
  const { t } = useTranslation()
  const { clientCode = '' } = useCurrentUser()
  const search = useLocation().search
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const lang = navigator.language
  //Temporary solution until the API is changed to return the date to be displayed
  const monthToDisplay = useMemo(() => {
    if (filterState.QUARTER.value === 'LAST_MONTH') {
      const date = moment().locale(lang).subtract(1, 'months')
      return date.format('MMMM YYYY')
    }
    if (filterState.QUARTER.value === 'CURRENT_MONTH') {
      const date = moment().locale(lang)
      return date.format('MMMM YYYY')
    }
    return ''
  }, [filterState])

  useEffect(() => {
    setUrlGraph(new URLSearchParams(search).get('graph') || '')

    window.history.replaceState({}, document.title)
  }, [])

  if (urlGraph) {
    filterState.GRAPH.value = urlGraph
  }
  useEffect(() => {
    queryParams.delete('graph')
    history.replace({
      search: queryParams.toString(),
    })
  }, [])

  const { breakpoint } = useBreakpoint()
  const { isLoading: isStructureLoading, isError: isStructureError, structure } = useFetchStructure()
  const colorsConfiguration = structure?.configuration?.graphColors
  // Whenever the user changes his client, we must reset the filters as the data changes
  useEffect(() => {
    setFilterState(initialFilterState)
  }, [clientCode])
  useEffect(() => {
    setSelectedSite(structure?.domains.filter((elt) => elt.path == filterState.SITE.value))
  }, [filterState.SITE.value])
  useEffect(() => {
    if (selectedSite && selectedSite.length > 0) setConfiguration(selectedSite[0].configuration)
  }, [selectedSite])

  const { mutate, data, isLoading, isSuccess, reset } = useMutation({
    mutationKey: QUERY_KEYS.ANALYSIS.GRAPH(filterState),
    mutationFn: (clientCode: string) => graphService.generateGraph(clientCode, filterState),
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })
  if (isStructureError) {
    return <p>{t('api.domainStructureError')}</p>
  }

  if (isStructureLoading || !structure) {
    return (
      <div className="flex flex-wrap mt-1 mb-4 mx-2">
        <FilterSkeletons />
      </div>
    )
  }

  const generateGraph = async () => {
    if (clientCode) {
      mutate(clientCode)
      setDisplayInformativeSection(true)
    }
  }
  const setMapZoom = () => {
    setZoom(1)
    if (zoom == 1) setZoom(null)
  }
  const generateHeatmapGraph = async () => {
    if (clientCode) {
      mutate(clientCode, {
        onSuccess: (response) => {
          const { headers, data } = getExportFormattedData(
            filterState.MONITORING_ROOM_TYPE.value as any,
            filterState.GRAPH.value as GraphType,
            response,
            filterState.KPI.value as any,
            '',
            filterState.CUSTOM_ATTRIBUT_WKS,
            filterState.CUSTOM_ATTRIBUT_COLLAB,
          )
          const translatedHeaders = headers.map((header) => t(header))
          const formattedData = [translatedHeaders, ...data]
          const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          const fileExtension = '.xlsx'
          const filters = getLabelsfilter(filterState, structure)

          if (formattedData.length > Math.pow(2, 17)) {
            const size = Math.pow(2, 17)
            const arrayOfArrays = []
            for (let i = 0; i < formattedData.length; i += size) {
              arrayOfArrays.push(XLSX.utils.json_to_sheet(formattedData.slice(i, i + size), { cellDates: true }))
            }

            let sheets: any
            const sheetsNames = []
            for (let i = 0; i < arrayOfArrays.length; i++) {
              const key = `data${i + 1}`
              sheets = { ...sheets, ...{ [key]: arrayOfArrays[i] } }
              sheetsNames.push(key)
            }

            const wb = { Sheets: sheets, SheetNames: sheetsNames }

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer', WTF: true })
            const expData = new Blob([excelBuffer], { type: fileType })

            FileSaver.saveAs(
              expData,
              `${filters.graph}_${filters.siteFilter}_${filters.buildingCode}_${filters.kpi}_${filters.rangeType}` +
                fileExtension,
            )
          } else {
            const ws = XLSX.utils.json_to_sheet(formattedData, { cellDates: true })
            const wb = { Sheets: { data1: ws }, SheetNames: ['data1'] }
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            const expData = new Blob([excelBuffer], { type: fileType })
            const filtersTitle = Object.values(filters).join('_')
            FileSaver.saveAs(expData, `${filtersTitle.substring(0, 202)}` + fileExtension)
          }
        },
      })
    }
  }
  // We consider that filters are completed only when the required ones are.
  const missingRequiredFilters = Object.values(filterState).some(
    (filter) => filter.required && (!filter.value || filter.value.length === 0),
  )
  // We disable the generate button when the filters aren't fully filled. And when they are, it must be disabled while
  // the data fetched hasn't changed.
  const generateDisabled = missingRequiredFilters || (isSuccess && data)
  const saveDisabled = missingRequiredFilters || !data
  const graphTypeValue = filterState.GRAPH.value as GraphType
  const graphLayoutWidth = !graphTypeValue || !data ? 'big' : chartLayoutSizes[graphTypeValue](breakpoint)
  const formattedFilters = getLabelsfilter(filterState, structure)

  return (
    <div>
      <GraphFiltersContext.Provider
        value={{
          structure,
          state: filterState,
          onFilterChange: (state: FilterState) => {
            reset()
            setFilterState(state)
          },
        }}
      >
        <div className="flex px-2 pt-3">
          <AnalysisFilters
            filterState={filterState}
            missingRequiredFilters={missingRequiredFilters}
            graphType={urlGraph as GraphType}
          />
          {isLoading && <LoaderComponent />}
          <AnalysisButtons
            exportData={data}
            graphType={filterState.GRAPH.value as GraphType}
            onGenerateClick={generateGraph}
            onGenerateHeatmapClick={generateHeatmapGraph}
            isGeneratedDisabled={generateDisabled}
            isSaveDisabled={saveDisabled}
            filters={filterState}
            setMapZoom={setMapZoom}
            zoom={zoom}
          />
        </div>
        <div
          className={`flex flex-1`}
          style={{
            overflowY: 'hidden',
            marginLeft:
              filterState.GRAPH.value === 'DETAILED_OCCUPANCY' &&
              filterState.ROOM_TYPE.value == 'WORKSTATION' &&
              filterState.DETAILED_OCCUPANCY.value == 'BUSINESS_UNIT'
                ? '4%'
                : '5%',
            paddingLeft:
              filterState.GRAPH.value === 'DETAILED_OCCUPANCY' &&
              filterState.ROOM_TYPE.value == 'WORKSTATION' &&
              filterState.DETAILED_OCCUPANCY.value == 'BUSINESS_UNIT'
                ? '6rem'
                : '0',
          }}
        >
          {graphTypeValue !== 'DETAILED_OCCUPANCY_TABLE' && filterState.GRAPH.enable && (
            <ChartLayout fullH width={graphLayoutWidth}>
              <AnalysisGraphs
                filterState={filterState}
                graphData={data}
                zoom={zoom}
                colorsConfiguration={colorsConfiguration}
              />
            </ChartLayout>
          )}
          {displayInformativeSection && data && (
            <InformativeSection
              //@ts-ignore
              graph={filterState.GRAPH.value}
              period={formattedFilters.rangeType ? formattedFilters.rangeType : ''}
              capacity={
                data.capacity
                  ? `${data.capacity} ${t('informativeSection.people')}`
                  : data.totalRoomsCount !== undefined
                  ? filterState.ROOM_TYPE.value == 'WORKSTATION'
                    ? `${data.totalRoomsCount} ${t('informativeSection.workstation')}`
                    : `${data.totalRoomsCount} ${t('informativeSection.room')}`
                  : undefined
              }
              site={formattedFilters.siteFilter}
              building={formattedFilters.buildingCode ? formattedFilters.buildingCode : ''}
              floor={formattedFilters.floorName ? formattedFilters.floorName : ''}
              spaceType={formattedFilters.roomType ? formattedFilters.roomType : ''}
              date={monthToDisplay}
              kpi={formattedFilters.kpi}
              analysisHours={configuration}
            />
          )}
        </div>
      </GraphFiltersContext.Provider>
    </div>
  )
}

export default SingleReportView

import { COLORS } from 'assets/colors/colors'
import IconSvg from 'components/atoms/icons/IconSvg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  roomType?: string
}

const NoDataComponent: FC<Props> = (props) => {
  const { roomType } = props
  const { t } = useTranslation()
  const workstationNature = [
    'ALL_WORKSTATIONS',
    'OPEN_SPACE',
    'INDIVIDUAL_OFFICE',
    'WORKSTATION',
    'SHARED_OFFICE',
    'COMMON_SPACE',
  ]
  const isMultipleRoomTypes = Array.isArray(roomType)
  const isWorkStation = isMultipleRoomTypes ? false : workstationNature.includes(roomType)
  return (
    <div className="relative max-w-full max-h-full overflow-x-hidden overflow-y-auto flex flex-col items-center mt-12">
      <IconSvg name="NO_DATA" className="mt-1 mb-2" color="WHITE" />
      <div className="text-center">
        <h3 className="font-bold" style={{ color: COLORS.darkGrey, fontSize: '2rem' }}>
          {t('NoDataComponent.title')}
        </h3>
        <p style={{ color: COLORS.darkGrey50, fontSize: '0.8rem' }}>
          {isWorkStation
            ? t('NoDataComponent.wkDescription')
            : isMultipleRoomTypes
            ? t('NoDataComponent.description')
            : t('NoDataComponent.ecDescription')}
        </p>
      </div>
    </div>
  )
}

export default NoDataComponent

import { FC, useContext, useMemo } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import {
  BUILDING,
  graphsStructure,
  COMPARTMENT,
  DOMAIN,
  FLOOR,
  detailedOccupancyValue,
  monthlyValue,
  frequentationValue,
  spaceAttendanceValue,
} from 'utils/constants/graphs/graphsStructure'
import { getFilterType, isRIEGraph } from 'utils/filtersUtils'
import { DomainsStructure } from 'types/DomainsStructure'

const handleStateChange = (state: FilterState, structure: DomainsStructure, value?: string): FilterState => {
  if (state.SPACE_TYPE.value === value) return state
  const isBuildingCounting = value === 'BUILDING'
  const isFloorCounting = value === 'FLOOR'
  const isCompartmentCounting = value === 'COMPARTMENT'
  const isDetailedOccupancy = state.GRAPH.value === detailedOccupancyValue
  const isMonthly = state.GRAPH.value === monthlyValue
  const isFrequentation = state.GRAPH.value === frequentationValue
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const selectedDomain = structure?.domains?.find((domain) => domain?.path === state.SITE.value)
  const floors =
    structure.domains
      ?.find((domain) => domain.path === state.SITE.value)
      ?.floors?.filter((floor) => floor.buildingCode === state.BUILDING.value) || []
  const countingCompartments = structure.domains
    ?.find((domain) => domain.path === state.SITE.value)
    ?.compartments?.filter((compartment) => compartment.counting)
  const filteredFloors = isCompartmentCounting
    ? floors.filter((floor) => countingCompartments?.find((compartment) => compartment.floorPath == floor.path))
    : selectedDomain?.floors?.filter((floor) => floor.counting)
  const floorValue = filteredFloors?.length === 1 ? filteredFloors[0].path : undefined
  const countingFloors = structure.domains
    ?.find((domain) => domain.path === state.SITE.value)
    ?.floors?.filter((floor) => floor.counting)
  const buildings = isBuildingCounting
    ? selectedDomain?.buildings?.filter((building) => building.counting)
    : isFloorCounting
    ? selectedDomain?.buildings?.filter((building) =>
        countingFloors?.find((floor) => floor.buildingCode == building.code),
      )
    : selectedDomain?.buildings?.filter((building) =>
        countingCompartments?.find((compartment) => compartment.buildingCode == building.code),
      )
  const buildingValue = buildings?.length === 1 ? buildings[0].code : undefined
  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: {
      ...state.BUILDING,
      value: buildingValue,
      active: !!value && !buildingValue,
      required: !!buildings,
    },
    FLOOR: {
      ...initialFilterState.FLOOR,
      value: floorValue,
      active: !!value && !floorValue && !isDetailedOccupancy,
      required:
        (!isRIEGraph(state.GRAPH) && !isDetailedOccupancy && !isMonthly && !isFrequentation) ||
        (isFloorCounting && isSpaceAttendance),
    },
    COMPARTMENT: state.COMPARTMENT,
    SPACE_TYPE: {
      ...state.SPACE_TYPE,
      value,
      active: !value,
    },
    KPI: {
      ...state.KPI,
      active: value === 'DOMAIN' ? true : state.KPI.active,
      required: isSpaceAttendance,
    },
  }
}

const ScopeTypeFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const isSpaceAttendance = state.GRAPH.value === spaceAttendanceValue
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const features = selectedDomain?.features
  const filteredScopeTypes = []
  const isCountingDomain: boolean = useMemo(() => {
    if (selectedDomain) {
      return Boolean(selectedDomain?.features?.countingDomain)
    }

    return false
  }, [selectedDomain])

  if (features && features.countingBuilding) filteredScopeTypes.push(BUILDING)
  if (features && features.countingFloor) filteredScopeTypes.push(FLOOR)
  if (features && features.countingCompartment) filteredScopeTypes.push(COMPARTMENT)
  if (isCountingDomain && isSpaceAttendance) filteredScopeTypes.push(DOMAIN)

  const scopeTypeValue = state.SPACE_TYPE.value

  const options = (filteredScopeTypes || []).map((option) => ({
    ...option,
    label: t(option.label),
  }))
  state.SPACE_TYPE.active = true
  const scopeTypeData = options.find((option) => option.value === scopeTypeValue)
  const type = getFilterType(state.GRAPH.value, state.SPACE_TYPE.active, scopeTypeValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, structure, value))

  return (
    <Filter
      type={type}
      placeholder={t(graphsStructure.scopeType.placeholder)}
      label={scopeTypeData?.label}
      // disabled={!state.SPACE_TYPE.active && !state.SPACE_TYPE.value}
      selected={scopeTypeValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default ScopeTypeFilter

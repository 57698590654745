import { FC, useState, useEffect, useMemo } from 'react'
import { DashboardType, PagesType, ReportsType } from 'types/DashboardType'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import LoadingChart from '../molecules/charts/LoadingChart'
import DashboardReportView from '../molecules/dashboardList/dashboardReportView'
import DeleteReportInDashboardDialog from '../molecules/dialogs/confirmation/DeleteReportInDashboardDialog'
import Pagination from '../molecules/Pagination'
import AddReport from '../molecules/charts/addReport'

type Props = {
  selectedDashboardData: DashboardType
  editMode: boolean
  onPageEdit: (dashboardPage: PagesType) => void
  getPageIndex: (pageIndex: number) => void // TODO : when pagination is okay, used to get the page index
}

const DashboardPageLayout: FC<Props> = (props) => {
  const { selectedDashboardData, editMode, onPageEdit } = props
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [reportToDeleteID, setReportToDeleteID] = useState<number>()
  const [reportToDeletePageIndex, setReportToDeletePageIndex] = useState<number>()
  const [reportPosition, setReportPosition] = useState<number>()
  const [currentReportsData, setCurrentReports] = useState<ReportsType[][]>()

  const [pages, setPages] = useState(selectedDashboardData.pages)
  let reports = pages.map((page) => page.reports)
  useEffect(() => {
    setPages(selectedDashboardData.pages)
    reports = pages.map((page) => page.reports)
  }, [selectedDashboardData])

  const [currentPage, setCurrentPage] = useState(1)
  const PageSize = 1
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    setCurrentReports(
      reports.slice(firstPageIndex, lastPageIndex).reduce((acc, cur) => {
        const newReports = cur
          .sort((a, b) => a.position - b.position) // tri des rapports selon position
          .reduce((acc2, cur2) => {
            // on complète les positions vides par des faux rapports

            const reportsTemp = [...acc2]
            while (reportsTemp.length + 1 < cur2.position) {
              reportsTemp.push({ id: -1, position: reportsTemp.length + 1 })
            }
            reportsTemp.push(cur2)

            return reportsTemp
          }, [] as ReportsType[])
        // on remplie les vides tant que la page n'a pas 4 rapports
        while (newReports.length < 4) {
          newReports.push({ id: -1, position: newReports.length + 1 })
        }
        return [...acc, newReports]
      }, [] as ReportsType[][]),
    )
    props.getPageIndex(currentPage)
  }, [currentPage, pages])
  const newPages = reports.reduce((acc, cur) => {
    const newReports = cur
      .sort((a, b) => a.position - b.position) // tri des rapports selon position
      .reduce((acc2, cur2) => {
        // on complète les positions vides par des faux rapports

        const reportsTemp = [...acc2]
        while (reportsTemp.length + 1 < cur2.position) {
          reportsTemp.push({ id: -1, position: reportsTemp.length + 1 })
        }
        reportsTemp.push(cur2)

        return reportsTemp
      }, [] as ReportsType[])
    // on remplie les vides tant que la page n'a pas 4 rapports
    while (newReports.length < 4) {
      newReports.push({ id: -1, position: newReports.length + 1 })
    }
    return [...acc, newReports]
  }, [] as ReportsType[][])

  const onCancel = () => setOpen(false)

  const onTrashClick = (reportID: number, reportPosition: number) => {
    setReportToDeleteID(reportID)
    setReportToDeletePageIndex(currentPage)
    setReportPosition(reportPosition)
    setOpen(true)
  }

  const onConfirmation = () => {
    const currentPage = pages.find((p) => p.index == reportToDeletePageIndex)
    const reports = currentPage?.reports.reduce((acc, cur) => {
      const reportsTemp = [...acc]
      if (cur.position !== reportPosition) {
        reportsTemp.push(cur)
      }

      return reportsTemp
    }, [] as ReportsType[])
    if (currentPage && reports) {
      const newPage = { ...currentPage, reports }
      onPageEdit(newPage)
      toast.success(t('dashboard.deleteReportSuccess', { ID: reportToDeleteID }))

      onCancel()

      const newPages = pages.map((p) => (p.index == reportToDeletePageIndex ? newPage : p))
      setPages(newPages)
    }
  }

  return (
    <>
      <div className="grid grid-cols-2 grid-rows-2 flex-1 pb-5 h-custom-dashboard-body">
        {currentReportsData?.map((reports, indexPage) =>
          reports.map((report, index) => {
            return report.id > -1 ? (
              <DashboardReportView
                key={index}
                reportID={report.id.toString()}
                editMode={editMode}
                onTrashClick={() => onTrashClick(report.id, report.position)}
              />
            ) : (
              <AddReport
                key={index}
                editedPage={{ index: currentPage, reports: newPages[currentPage - 1] }}
                editedPosition={index}
                dashboardName={selectedDashboardData.name}
              />
            )
          }),
        )}
        {currentReportsData && currentReportsData.length === 0 && (
          <AddReport
            editedPage={{ index: currentPage, reports: newPages[currentPage - 1] }}
            editedPosition={0}
            dashboardName={selectedDashboardData.name}
          />
        )}

        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={
            reports.map((elt) => elt.length).filter((length) => length !== 4).length > 0
              ? reports.length
              : reports.length + 1
          }
          pageSize={PageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </div>
      {reportToDeleteID && (
        <DeleteReportInDashboardDialog
          open={open}
          onClose={onCancel}
          onConfirmation={onConfirmation}
          savedReportId={reportToDeleteID}
        />
      )}
    </>
  )
}

export default DashboardPageLayout

import { Ref, useEffect, useRef, useState } from 'react'

type ReturnType = {
  open: boolean
  refButton: Ref<HTMLDivElement>
  refContainer: Ref<HTMLDivElement>
}

const useFocusedRef = (disabled = false, closeOnContainerClick = false): ReturnType => {
  const [open, setOpen] = useState(false)
  const refButton = useRef<HTMLDivElement>(null)
  const refContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // eslint-disable-next-line
    const handleClickOutside = (event: any) => {
      const cond1 = refButton.current && refButton.current.contains(event.target)
      const cond2 = !refContainer.current || (refContainer.current && !refContainer.current.contains(event.target))

      if (cond1 && !disabled) {
        setOpen((prevState) => !prevState)
      } else if (closeOnContainerClick || cond2) {
        setOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [open, disabled])

  return { open, refButton, refContainer }
}

export default useFocusedRef

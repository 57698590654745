import { COLORS } from 'assets/colors/colors'
import { toHoursAndMinutes } from 'utils/dateUtils'
export const fakeUtilizationQualityData = {
  totalRoomsCount: 26,
  result: [
    {
      name: 'room1',
      capacity: 10, // NOT NULL
      countingType: 'COUNTING',
      rates: [
        {
          utilizationQuality: 'EMPTY',
          utilizationQualityRate: 54.7, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'UNDER_OCCUPIED',
          utilizationQualityRate: 17, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'BALANCED',
          utilizationQualityRate: 17, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'SATURATED',
          utilizationQualityRate: 17, // pourcentage du temps d'utilisation
        },
      ],
    },
    {
      name: 'room2',
      capacity: 50, // NOT NULL
      countingType: 'COUNTING',
      rates: [
        {
          utilizationQuality: 'EMPTY',
          utilizationQualityRate: 24.7, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'UNDER_OCCUPIED',
          utilizationQualityRate: 32, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'BALANCED',
          utilizationQualityRate: 0, // pourcentage du temps d'utilisation
        },
      ],
    },
    {
      name: 'room3',
      capacity: 50, // NOT NULL
      countingType: 'COUNTING',
      rates: [
        {
          utilizationQuality: 'EMPTY',
          utilizationQualityRate: 24.7, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'UNDER_OCCUPIED',
          utilizationQualityRate: 0, // pourcentage du temps d'utilisation
        },
        {
          utilizationQuality: 'BALANCED',
          utilizationQualityRate: 32, // pourcentage du temps d'utilisation
        },
      ],
    },
  ],
}
type OptionsType = {
  id: string | undefined
  categories: (string | string[])[]
  title: string
  subtitle: string[]
  minutes: number[]
  graphType: string
  truncatedIndex?: number
  colors: string[]
  onRightClickCallback?: (index: number) => void
}

export const horizontalBarChartOptions = (options: OptionsType): Record<string, unknown> => {
  const { categories, title, subtitle, graphType, minutes, onRightClickCallback } = options
  return {
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      id: options.id
        ? options.id.toString()
        : graphType == 'ROOM_BOOKING'
        ? 'roomBooking-chart'
        : 'utilizationQuality-chart',
      animations: {
        enabled: true,
        dynamicAnimation: {
          enabled: false,
        },
      },
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
      events: {
        // eslint-disable-next-line
        dataPointSelection: (event: MouseEvent, chartContext: never, config: any) => {
          if (onRightClickCallback && event.button === 2) {
            onRightClickCallback(config.dataPointIndex)
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        rangeBarGroupRows: true,
        barHeight: '70%',
      },
    },
    legend: {
      position: 'top',
      offsetY: graphType == 'ROOM_BOOKING' ? 0 : -25,
    },
    dataLabels: {
      enabled: graphType == 'ROOM_BOOKING' ? true : false,
      formatter: (value) => (value == 0 ? '' : `${value}%`),
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        style: {
          colors: ['#9C9CB5'],
        },
        formatter: (value: number): string => {
          return value % 20 === 0 ? `${value}%` : ''
        },
      },
      tickAmount: 10,
      max: 100,
      axisTicks: {
        show: true,
      },
    },
    title: {
      text: title,
      align: 'left',
      offsetY: 10,
      style: {
        fontWeight: 700,
        fontSize: 14,
      },
    },
    subtitle: {
      text: subtitle,
      align: 'left',
    },
    colors:
      options.colors?.length > 0
        ? graphType == 'ROOM_BOOKING'
          ? [
              options.colors.find((obj) => obj.type == 'FREE')?.color,
              options.colors.find((obj) => obj.type == 'NO_SHOW')?.color,
              options.colors.find((obj) => obj.type == 'NOT_BOOKED')?.color,
              options.colors.find((obj) => obj.type == 'BOOKED_AND_OCCUPIED')?.color,
            ]
          : [
              options.colors.find((obj) => obj.type == 'EMPTY')?.color,
              options.colors.find((obj) => obj.type == 'UNDER_OCCUPIED')?.color,
              options.colors.find((obj) => obj.type == 'BALANCED')?.color,
              options.colors.find((obj) => obj.type == 'SATURATED')?.color,
            ]
        : [COLORS.blue, COLORS.blue50, COLORS.deepCerise80, COLORS.darkGrey80],
    tooltip: {
      enabled: true,
      x: {
        show: graphType == 'UTILIZATION_QUALITY' && true,
        formatter: function (val, obj) {
          return '<div>' + '<span>' + val[0] + '</span>' + '</div>'
        },
      },
      y:
        graphType == 'ROOM_BOOKING'
          ? {
              formatter: function (val, obj) {
                return (
                  obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex] +
                  '% - ' +
                  minutes[obj.dataPointIndex * 4 + obj.seriesIndex] +
                  'h'
                )
              },
            }
          : {
              formatter: function (val, obj) {
                const mts = minutes[obj.seriesIndex].data[obj.dataPointIndex]
                return (
                  obj.w.config.series[obj.seriesIndex].data[obj.dataPointIndex] +
                  '% (' +
                  toHoursAndMinutes(mts).hours +
                  'h' +
                  toHoursAndMinutes(mts).minutes +
                  'min' +
                  ')'
                )
              },
            },
      marker: {
        show: graphType == 'ROOM_BOOKING' ? false : true,
      },
    },
    fill: {
      opacity: 1,
    },
  }
}

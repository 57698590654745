import IconSvg from 'components/atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

type Props = {
  noShowRate: string
  averageDuration: string
  hasMeetingRoom: boolean
}

export default function CharacteristicsOfMeetings({ hasMeetingRoom, noShowRate, averageDuration }: Props) {
  const { t } = useTranslation()
  return (
    <div
      className={`${averageDuration == '' ? 'inactiveShadowSection' : ''} flex flex-col shadowSection relative h-full`}
    >
      <div data-tip data-for="meetingCharacteristicsKpi" className="info-tooltip">
        <IconSvg name="FILLED_INFO" className="mr-2" color="GREY30" />
      </div>
      <ReactTooltip id="meetingCharacteristicsKpi" place="bottom">
        {t('landingPage.meetingCharacteristics.kpi')}
      </ReactTooltip>
      <h2 className="section-title">{t('landingPage.meetingCharacteristics.title')}</h2>
      <div className="flex flex-col items-center my-2" data-for="noShowRate" data-tip="noShowRate">
        <small>{t('landingPage.meetingCharacteristics.rateUnoccupied')}</small>
        <p className="grayBloc" style={{ minWidth: '175px' }}>
          {noShowRate.length > 0 ? noShowRate : '_'}
        </p>
      </div>
      <ReactTooltip id="noShowRate" className="breakLine" type="info">
        {!hasMeetingRoom
          ? t('landingPage.news')
          : noShowRate !== ''
          ? t('landingPage.workstationOccupation.tooltip', { quarter: quarter, value: occupied })
          : t('landingPage.news')}
      </ReactTooltip>
      <div
        className="flex flex-col items-center my-2"
        data-for="meetingAverageDuration"
        data-tip="meetingAverageDuration"
      >
        <small>{t('landingPage.meetingCharacteristics.average')}</small>
        <p className="grayBloc" style={{ minWidth: '175px' }}>
          {averageDuration.length > 0 ? averageDuration : '_'}
        </p>
      </div>
      <ReactTooltip id="meetingAverageDuration" className="breakLine" type="info">
        {!hasMeetingRoom
          ? t('landingPage.news')
          : averageDuration !== ''
          ? t('landingPage.workstationOccupation.tooltip', { quarter: quarter, value: occupied })
          : t('landingPage.news')}
      </ReactTooltip>
    </div>
  )
}

import { FC, useContext } from 'react'
import Filter from '../../../atoms/filters/Filter'
import { FilterState, GraphFiltersContext, initialFilterState } from './GraphFiltersContext'
import FilterOption from '../../../atoms/filters/FilterOption'
import { useTranslation } from 'react-i18next'
import { DomainsStructure } from 'types/DomainsStructure'
import { graphsStructure, COMPARTMENT, FLOOR } from 'utils/constants/graphs/graphsStructure'
import { getFilterType } from 'utils/filtersUtils'

const handleStateChange = (state: FilterState, structure: DomainsStructure, value?: string): FilterState => {
  if (state.LEVEL.value === value) return state
  const floors =
    state.BUILDING.value !== undefined
      ? structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.floors?.filter((floor) => floor.buildingCode === state.BUILDING.value) || []
      : structure.domains.find((domain) => domain.path === state.SITE.value)?.floors || []
  const floorsWithPlan = floors && floors.length > 0 ? floors!.filter((floor) => floor.map) : []
  const heatmapCompartments = structure.domains
    .find((domain) => domain.path === state.SITE.value)
    ?.compartments?.filter((compartment) => compartment.map)
  const heatmapCompartmentsFloors = floors.filter((floor) =>
    heatmapCompartments?.find((compartment) => compartment.floorPath == floor.path),
  )
  const floorValue =
    floorsWithPlan?.length === 1 && value == 'FLOOR'
      ? floorsWithPlan[0].path
      : heatmapCompartmentsFloors?.length === 1 && value == 'COMPARTMENT'
      ? heatmapCompartmentsFloors[0].path
      : undefined

  return {
    ...initialFilterState,
    GRAPH: state.GRAPH,
    SITE: state.SITE,
    BUILDING: state.BUILDING,
    FLOOR: {
      ...state.FLOOR,
      active: !!value,
      value: floorValue,
    },
    LEVEL: {
      ...state.LEVEL,
      value,
      active: !value,
    },
  }
}

const LevelFilter: FC = () => {
  const { t } = useTranslation()
  const { structure, state, onFilterChange } = useContext(GraphFiltersContext)
  const selectedDomain = structure.domains.find((domain) => domain.path === state.SITE.value)
  const features = selectedDomain?.features
  const levels = []
  const floors =
    state.BUILDING.value !== undefined
      ? structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.floors?.filter((floor) => floor.buildingCode === state.BUILDING.value) || []
      : structure.domains.find((domain) => domain.path === state.SITE.value)?.floors || []
  const floorsWithPlan = floors && floors.length > 0 ? floors!.filter((floor) => floor.map) : []
  const compartments =
    state.BUILDING.value !== undefined
      ? structure.domains
          .find((domain) => domain.path === state.SITE.value)
          ?.compartments?.filter((compartment) => compartment.buildingCode === state.BUILDING.value) || []
      : structure.domains.find((domain) => domain.path === state.SITE.value)?.compartments

  const compartmentsWithPlan = compartments && compartments.length > 0 ? compartments!.filter((cmp) => cmp.map) : []
  if (floorsWithPlan && floorsWithPlan.length > 0) levels.push(FLOOR)
  if (compartmentsWithPlan && compartmentsWithPlan.length > 0) levels.push(COMPARTMENT)
  const levelValue = state.LEVEL.value

  const options = (levels || []).map((option) => ({
    ...option,
    label: t(option.label),
  }))
  state.SPACE_TYPE.active = true
  const levelData = options.find((option) => option.value === levelValue)
  const type = getFilterType(state.GRAPH.value, state.SPACE_TYPE.active, levelValue)

  const onChange = (value?: string) => onFilterChange(handleStateChange(state, structure, value))

  return (
    <Filter
      type={type}
      placeholder={t(graphsStructure.level.placeholder)}
      label={levelData?.label}
      // disabled={!state.SPACE_TYPE.active && !state.SPACE_TYPE.value}
      selected={levelValue}
      onSelect={onChange}
    >
      {options.map((option) => (
        <FilterOption key={option.value} value={option.value}>
          {option.label}
        </FilterOption>
      ))}
    </Filter>
  )
}

export default LevelFilter

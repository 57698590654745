import IconSvg, { iconColors } from 'components/atoms/icons/IconSvg'
import Select, { GroupBase, StylesConfig } from 'react-select'
import PageLayout from 'components/layouts/PageLayout'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { FILTER_ARROW_ICONS } from 'styles/Filters'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { toast } from 'react-toastify'
import { getRieInfos } from 'utils/helpers/rieView'
import { Domain, Localization } from 'utils/api/types'
import { findBuildingByName, getBuildingSensor, getCapacity, getOverviewInfo } from 'utils/LocalizationUtils'
import RieTable from 'components/molecules/tables/RieTable'
import { realTimeService } from 'services/realTime'
import RealTimeTable from 'components/molecules/tables/RealTimeTable'
import { LoaderComponent } from 'components/atoms/loader/Loader'
import { LocalizationInformationDtoRoomClassificationEnum } from 'core/api/models/localizationInformationDto'
import { FloorInfo, AvailableInfo, RestaurantAvailableInfo } from 'types/RieView'
import Button from 'components/atoms/button/Button'
import { SensorStatusDto } from 'core/api/models/sensorStatusDto'
import BuildingTable from 'components/molecules/tables/BuildingTable'

type RealTimeViewProps = {
  apiKey?: string
  inheritedClientCode?: string
  inheritedPath?: string
}

const RealTimeView = ({ apiKey, inheritedClientCode, inheritedPath }: RealTimeViewProps): JSX.Element => {
  const [cookies, setCookie] = useCookies(['config'])
  const savedConfig = cookies['config']
  const { clientCode = inheritedClientCode } = useCurrentUser()

  const [domainOptions, setdomainOptions] = useState<{ label: string; value: string }[]>()
  const [hasRestaurant, setHasRestaurant] = useState<boolean>(false)
  const [selectedDomain, setSelectedDomain] = useState<{ label: string; value: string } | null>(
    savedConfig ? savedConfig.domain : domainOptions ? domainOptions[0] : '',
  )
  const [buildingOptions, setBuildingOptions] = useState<{ label: string; value: string }[]>()
  const { isLoading: isStructureLoading, structure } = useFetchStructure()
  const { t } = useTranslation()
  const [selectedBuilding, setSelectedBuilding] = useState<{ label: string; value: string } | null>(
    savedConfig ? savedConfig.building : buildingOptions ? buildingOptions[0] : '',
  )
  const { closeIcon } = FILTER_ARROW_ICONS
  const path = useMemo(
    () =>
      (inheritedPath || structure?.domains.find((d) => d.path === selectedDomain?.value)?.path.replace(/\//g, '$')) ??
      '',
    [structure, selectedDomain, inheritedPath],
  )
  const [floor, setFloor] = useState<{
    roomType: LocalizationInformationDtoRoomClassificationEnum
    floorInfos?: FloorInfo
    space: AvailableInfo | RestaurantAvailableInfo
    filter?: LocalizationInformationDtoRoomClassificationEnum
  } | null>(null)

  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const tableWrapperStyle = useMemo(() => {
    if (tableWrapperRef.current) {
      return {
        maxHeight: `calc(100vh - ${tableWrapperRef.current.getBoundingClientRect().top}px)`,
      }
    }

    return {}
  }, [tableWrapperRef.current])

  const customStyles: StylesConfig<
    {
      label: string
      value: string
    },
    false,
    GroupBase<{
      label: string
      value: string
    }>
  > = {
    option: (provided) => ({
      ...provided,
      padding: 10,
    }),
    control: () => ({
      cursor: 'pointer',
      minWidth: 166,
      marginRight: 18,
      border: '1px solid #575762',
      borderRadius: '8px',
      display: 'flex',
    }),
    indicatorSeparator: () => ({ display: 'none' }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
  }

  useEffect(() => {
    setSelectedDomain(null)
    setSelectedBuilding(null)
  }, [clientCode])

  const [dateLastRefresh, setDateLastRefresh] = useState<Date>()
  const formattedDateLastRefresh = useMemo(() => {
    if (dateLastRefresh?.getDate() === new Date().getDate()) {
      return dateLastRefresh && format(dateLastRefresh, "HH'h'mm")
    }

    return dateLastRefresh && format(dateLastRefresh, "iii do MMM y, HH'h'mm")
  }, [dateLastRefresh])

  const { data: domain, isLoading: isDomainLoading } = useQuery({
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DOMAIN(clientCode, path),
    queryFn: () => {
      if (clientCode && path) {
        return realTimeService.getDomain(clientCode, path, apiKey)
      }

      return Promise.resolve(null)
    },
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!clientCode,
    cacheTime: Infinity,
    refetchInterval: 60000,
  })

  const { data: desksInfos, isLoading: isDesksInfosLoading } = useQuery({
    queryFn: () => {
      if (clientCode && path) {
        return realTimeService.getRealTimeInfos(clientCode, path, apiKey)
      }

      return Promise.resolve(null)
    },
    queryKey: QUERY_KEYS.ANALYSIS.LOCALIZATION_DESKS(clientCode, path),
    onError: () => toast.error(t('api.unknown')),
    onSuccess: () => setDateLastRefresh(new Date()),
    enabled: !!clientCode,
    cacheTime: Infinity,
    refetchInterval: 60000,
  })

  const infos = useMemo(() => {
    if (!(domain?.sub_localizations && desksInfos?.length && desksInfos?.length > 0)) {
      return undefined
    }

    return getOverviewInfo(domain.sub_localizations as Localization[], desksInfos, selectedBuilding?.value).sort(
      (a, b) => a.sortValue.localeCompare(b.sortValue),
    )
  }, [domain, desksInfos, selectedBuilding])

  const buildingInfo: undefined | { name?: string; capacity?: number; sensor?: SensorStatusDto } = useMemo(() => {
    if (!(domain?.sub_localizations?.length && desksInfos?.length && desksInfos?.length > 0 && selectedBuilding)) {
      return
    }

    const building = findBuildingByName(domain.sub_localizations as Localization[], selectedBuilding.value)

    if (!building) {
      return
    }

    return {
      name: building.name,
      sensor: getBuildingSensor(building, desksInfos),
      capacity: getCapacity(building),
    }
  }, [domain, desksInfos, selectedBuilding])

  const restaurantInfos = useMemo(
    () => domain && desksInfos && getRieInfos(domain as Domain, desksInfos),
    [domain, desksInfos],
  )

  useEffect(() => {
    if (!isStructureLoading && structure && !!structure.domains) {
      setdomainOptions(structure.domains.map((domain) => ({ label: domain.name, value: domain.path })))
    }

    if (structure && !!structure.domains && selectedDomain) {
      setHasRestaurant(!!structure.domains.find((domain) => domain.path === selectedDomain.value)?.features.restaurant)
      const buildings = structure.domains.find((domain) => domain.path === selectedDomain.value)?.buildings || []
      setBuildingOptions(
        buildings.length > 0
          ? buildings.map((building) => ({
              label: t('graph.filters.building.labels', { name: building.name }),
              value: building.name,
            }))
          : [],
      )
    }
  }, [selectedDomain, structure, isStructureLoading])

  useEffect(() => {
    setCookie('config', {
      domain: selectedDomain ? selectedDomain : '',
      building: selectedBuilding ? selectedBuilding : '',
    })
  }, [selectedDomain, selectedBuilding])

  useEffect(() => {
    if (savedConfig && savedConfig.domain == '' && !!domainOptions && !isStructureLoading)
      setSelectedDomain(domainOptions[0])
    if (savedConfig && savedConfig.building == '' && !!buildingOptions) setSelectedBuilding(buildingOptions[0])
  }, [structure, clientCode, domainOptions, buildingOptions])

  useEffect(() => {
    setFloor(null)
  }, [domain, selectedBuilding?.label])

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconSvg name={closeIcon} className="ml-2" />{' '}
        </components.DropdownIndicator>
      )
    )
  }

  return (
    <PageLayout>
      <div className="flex m-4" style={{ columnGap: '24px' }}>
        <Select
          components={{
            DropdownIndicator,
          }}
          styles={customStyles}
          name="domain"
          options={domainOptions}
          value={selectedDomain}
          onChange={(e) => {
            if (e) {
              setSelectedDomain(e)
              setSelectedBuilding(null)
            }
          }}
        />
        {selectedBuilding && (
          <Select
            components={{
              DropdownIndicator,
            }}
            styles={customStyles}
            options={buildingOptions}
            value={selectedBuilding}
            onChange={(e) => {
              setSelectedBuilding(e)
            }}
          />
        )}
      </div>

      <div className="flex m-4 flex-col gap-6 items-start">
        {(isDesksInfosLoading || isDomainLoading) && <LoaderComponent />}

        {formattedDateLastRefresh && !(isDesksInfosLoading || isDomainLoading) && (
          <h5 className="text-basic text-h5 flex items-center justify-end flex-grow-0">
            {t('realTime.rieView.lastRefresh', {
              hour: formattedDateLastRefresh,
            })}
          </h5>
        )}

        {floor && (
          <Button size="sm" className="w-min -mb-2" onClick={() => setFloor(null)}>
            <IconSvg name="ARROW_BACK" color={iconColors.GREY100} />
          </Button>
        )}

        {domain && floor && (
          <header>
            <h1 className="font-semibold text-lg">
              {t(`overview.${selectedBuilding?.label ? 'locationTitle' : 'noBuildingLocation'}`, {
                site: domain.name,
                building: selectedBuilding?.label,
              })}
            </h1>
            <h2 className="font-semibold text-md">
              {t('overviewDetails.detailsFloor', {
                floor: floor?.floorInfos?.floor,
                typeRoom: t(`overview.${floor?.roomType}`),
              })}
            </h2>
          </header>
        )}

        <div className="flex items-start gap-6 flex-wrap" ref={tableWrapperRef} style={tableWrapperStyle}>
          {buildingInfo && <BuildingTable {...buildingInfo} />}
          {!(isDesksInfosLoading || isDomainLoading) && !!domain && !!infos && (
            <RealTimeTable
              domain={domain}
              maxHeight={tableWrapperStyle.maxHeight}
              desksInfos={desksInfos}
              infos={infos}
              buildingName={selectedBuilding?.label}
              floor={floor}
              onSetFloor={setFloor}
            />
          )}
          {!(isDesksInfosLoading || isDomainLoading) && hasRestaurant && !!restaurantInfos?.length && (
            <RieTable infos={restaurantInfos} maxHeight={tableWrapperStyle.maxHeight} mode="light" size="sm" />
          )}
        </div>
      </div>
    </PageLayout>
  )
}

export default RealTimeView

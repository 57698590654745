import { ChangeEvent, FC, useState } from 'react'
import Input from '../../atoms/input/Input'
import IconSvg from '../../atoms/icons/IconSvg'
import { useTranslation } from 'react-i18next'
import useFetchStructure from 'utils/hooks/useFetchStructure'
import { LoaderComponent } from '../../atoms/loader/Loader'
import DashboardItem from './dashboardItem'
import { DashboardType } from 'types/DashboardType'
import NewDashboardDialog from '../dialogs/newDashboard/NewDashboardDialog'

type Props = {
  isLoading: boolean
  items?: DashboardType[]
  onItemClick: (dashboardId: number) => void
  setIsOpen?: () => void
  selectedItem?: number
}

const DashboardList: FC<Props> = (props) => {
  const { isLoading, items = [], onItemClick, selectedItem, setIsOpen } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [addDashboardDialog, setAddDashboardDialog] = useState(false)
  // We preload the structure so that it's already available when we open the filters container
  useFetchStructure()

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)
  const filteredItems = items.filter((item) => item.name.toString().toLowerCase().includes(search.toLowerCase()))
  const onClose = () => {
    setAddDashboardDialog(false)
  }

  return (
    <div className="h-full px-2 overflow-y-auto">
      <div className="flex">
        <div className="relative pt-2" style={{ width: '80%', marginRight: '1rem' }}>
          <Input white placeholder={t('common.search')} onChange={onInputChange} />
          <IconSvg name="SEARCH" className="absolute right-4 top-6 pointer-events-none" />
        </div>
        <div
          className="border-solid border-1 mt-2 flex justify-center items-center px-3 cursor-pointer"
          style={{ borderRadius: '0.25rem' }}
          onClick={() => {
            setAddDashboardDialog(true)
            setIsOpen(false)
          }}
        >
          <IconSvg name="ADD" className="" />
        </div>
      </div>
      {addDashboardDialog && <NewDashboardDialog open={addDashboardDialog} onClose={onClose} />}{' '}
      {isLoading ? (
        <div className="mt-12 flex justify-center">
          <LoaderComponent />
        </div>
      ) : (
        <>
          {filteredItems.length === 0 && (
            <div className="mt-8 flex justify-center">
              <span className="text-basic-700">{t('common.noResult')}</span>
            </div>
          )}
          {filteredItems.map((item) => (
            <DashboardItem
              key={item.id}
              dashboardName={item.name}
              dashboardId={item.id}
              onClick={onItemClick}
              active={item.id === selectedItem}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default DashboardList

import { FC } from 'react'
import Dialog from '../../../atoms/dialog/Dialog'
import useCurrentUser from 'utils/hooks/useCurrentUser'
import { useMutation, useQueryClient } from 'react-query'
import { QUERY_KEYS } from 'utils/constants/ReactQueryKeys'
import { savedReportsService } from 'services/savedReportsService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import DialogTitle from '../../../atoms/dialog/DialogTitle'
import DialogBody from '../../../atoms/dialog/DialogBody'
import Button from '../../../atoms/button/Button'
import { useHistory } from 'react-router-dom'
import { PATHS } from 'utils/constants/routes/Paths'
import { generatePath } from 'utils/routeUtils'
import { SavedReportList } from 'types/SavedReportsTypes'
import Loader from '../../../atoms/loader/Loader'
import { useQuery } from 'react-query'

type Props = {
  savedReportId: number
  savedReportName: string
  open: boolean
  onClose: () => void
  onDeleteSuccess: () => void
}

const ConfirmationDialog: FC<Props> = (props) => {
  const { savedReportId, savedReportName, open, onClose, onDeleteSuccess } = props
  const { t } = useTranslation()
  const history = useHistory()
  const { clientCode = '' } = useCurrentUser()
  const queryClient = useQueryClient()
  const { data } = useQuery<any>({
    queryKey: QUERY_KEYS.DASHBOARDS.GET_AUTHORIZED_DASHBOARD(clientCode, savedReportId),
    queryFn: () => savedReportsService.getAuthorizedDashboard(clientCode, savedReportId),

    onError: () => {
      toast.error(t('api.unknown'))
    },
    enabled: !!savedReportId,
  })

  const { mutate, isLoading } = useMutation({
    mutationKey: QUERY_KEYS.SAVED_REPORTS.DELETE(savedReportId),
    mutationFn: (clientCode: string) => savedReportsService.delete(clientCode, savedReportId),
    onSuccess: (resData, clientCode) => {
      toast.success(t('savedReports.singleReport.delete.success', { name: savedReportName }))

      // We update the cache to remove the deleted element from the list
      const prevSavedReportsList = queryClient.getQueryData<SavedReportList>(
        QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode),
      )
      const filteredSavedReportsList = prevSavedReportsList?.result.filter(
        (savedReport) => savedReport.id !== savedReportId,
      )
      queryClient.setQueryData(QUERY_KEYS.SAVED_REPORTS.GET_ALL(clientCode), { result: filteredSavedReportsList })

      onDeleteSuccess()
      onClose()
      // We redirect to remove the id of the selected saved report in the URL, as it is now deleted.
      history.push(generatePath(PATHS.SAVED_REPORTS.SINGLE_REPORT, { url: PATHS.SAVED_REPORTS.HOME }))
    },
    onError: () => {
      toast.error(t('api.unknown'))
    },
  })

  const onConfirmationButtonClick = () => {
    if (clientCode) {
      mutate(clientCode)
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <p>{t('common.confirmation')}</p>
      </DialogTitle>
      <DialogBody>
        {data && data.result.length > 0 ? (
          <div>
            {' '}
            <p>{t('savedReports.singleReport.delete.fromDashboard')}</p>
            {data.result.map((item: any) => {
              return <li key={item.id}>{item.name}</li>
            })}
            <p>{t('savedReports.singleReport.delete.confirmDelete')}</p>
          </div>
        ) : (
          <p>{t('savedReports.singleReport.delete.content', { name: savedReportName })}</p>
        )}
        <div className="mt-8 flex justify-end space-x-4">
          {isLoading && <Loader />}
          <Button onClick={onClose} disabled={isLoading}>
            {t('common.cancel')}
          </Button>
          <Button color="red" onClick={onConfirmationButtonClick} disabled={isLoading}>
            {t('common.delete')}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )
}

export default ConfirmationDialog

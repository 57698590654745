import * as React from 'react'
import { BUTTON_BACKGROUND_COLORS, BUTTON_BORDER_COLORS, BUTTON_SIZE, BUTTON_TEXT_COLORS } from 'styles/Buttons'

type Props = {
  className?: string
  type?: 'button' | 'submit' | 'reset'
  mode?: 'full' | 'outlined' | 'flat'
  color?: Exclude<keyof typeof BUTTON_BACKGROUND_COLORS, 'disabled' | 'transparent'>
  disabled?: boolean
  style?: any
  size?: 'sm' | 'md' | 'lg' | 'xl'
  rounded?: 'sm' | 'md' | 'lg' | 'xl'
  title?: string
  block?: boolean
  onClick?: () => void
}

const Button: React.FC<Props> = (props) => {
  const {
    className = '',
    type = 'button',
    mode = 'full',
    color = 'default',
    disabled = false,
    size = 'md',
    rounded = 'md',
    onClick,
    block,
    children,
    title,
  } = props
  let { style } = props

  const { disabled: disabledBG, transparent: transparentBG, ...bgColors } = BUTTON_BACKGROUND_COLORS
  const { disabled: disabledBorder, transparent: transparentBorder, ...borderColors } = BUTTON_BORDER_COLORS
  const { disabled: disabledText, transparent: transparentText, ...textColors } = BUTTON_TEXT_COLORS
  const smallStyle = {
    minWidth: '4rem',
    height: '36px',
    padding: '0 24px',
  }

  style =
    size === 'sm'
      ? {
          ...style,
          borderRadius: '8px',
          fontFamily: 'Roboto',
          ...smallStyle,
        }
      : {
          ...style,
          minWidth: '6rem',
          height: '48px',
          borderRadius: '8px',
          padding: '0 32px 0 32px',
          fontFamily: 'Roboto',
        }

  const buttonBackground = ['flat', 'outlined'].includes(mode) ? transparentBG : disabled ? disabledBG : bgColors[color]
  const buttonBorder = ['full', 'outlined'].includes(mode)
    ? transparentBorder
    : disabled
    ? disabledBorder
    : borderColors[color]
  const buttonText = mode === 'full' ? transparentText : disabled ? disabledText : textColors[color]

  return (
    <button
      className={`mb-4 ${buttonBackground} border-2 ${buttonBorder} ${buttonText}
      ${block ? 'block' : ''} ${disabled ? 'cursor-not-allowed' : ''} ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
      title={title}
      style={style}
    >
      {children}
    </button>
  )
}

export default Button
